import React from "react";
import { motion } from "framer-motion";
import { MdMail, MdPhone } from "react-icons/md";
import { PiMapPinFill } from "react-icons/pi";

import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";

function ContactUs() {
  return (
    <div>
      <Navbar />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="text-5xl font-bold text-[#1d5b79] mb-4">
            Get In Touch
          </h1>
          <p className="text-gray-600 mb-12 max-w-2xl">
            We'd love to hear from you! Whether you have questions about{" "}
            <span className="text-[#1d5b79] font-bold">CinemaPro</span>, need
            support, or want to learn more about our services, our team is here
            to help.
          </p>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.2, duration: 0.5 }}
              className="space-y-2"
            >
              <div className="flex items-center gap-2 mb-3">
                <PiMapPinFill className="h-5 w-5 text-[#1d5b79]" />
                <h2 className="text-lg font-semibold text-[#1d5b79]">
                  Our Address
                </h2>
              </div>
              <p className="text-gray-600">
                518-Solaris Business Hub,Opp. Parshwanath Jain mandir,
                Bhuyangdev Cross Road, Ahmedabad, Gujarat-61
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3, duration: 0.5 }}
              className="space-y-2"
            >
              <div className="flex items-center gap-2 mb-3">
                <MdPhone className="h-5 w-5 text-[#1d5b79]" />
                <h2 className="text-lg font-semibold text-[#1d5b79]">
                  Our Contact
                </h2>
              </div>
              <p className="text-gray-600">+91 8511245350</p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.4, duration: 0.5 }}
              className="space-y-2"
            >
              <div className="flex items-center gap-2 mb-3">
                <MdMail className="h-5 w-5 text-[#1d5b79]" />
                <h2 className="text-lg font-semibold text-[#1d5b79]">
                  Our Email
                </h2>
              </div>
              <p className="text-gray-600">contact@cinemapro.in</p>
            </motion.div>
          </div>
        </motion.div>
      </div>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 0.5 }}
        className="relative w-full h-[600px]"
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4330.697750715671!2d72.5363377753149!3d23.058443379148642!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e851a5d3e4e0f%3A0x249acb663e46af9b!2sSoftCoding%20Solutions!5e1!3m2!1sen!2sin!4v1731048710232!5m2!1sen!2sin"
          width="100%"
          height="600"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
        <div className="absolute bottom-4 left-4">
          <button
            className="bg-[#1d5b79] hover:bg-[#164863] text-white flex flex-row p-4 rounded-2xl items-center justify-center"
            onClick={() =>
              window.open(
                "https://www.google.com/maps/place/SoftCoding+Solutions/@23.0584434,72.5363378,994m/data=!3m2!1e3!4b1!4m6!3m5!1s0x395e851a5d3e4e0f:0x249acb663e46af9b!8m2!3d23.0584434!4d72.5389127!16s%2Fg%2F11ft2n3j_v?entry=ttu&g_ep=EgoyMDI0MTEwNS4wIKXMDSoASAFQAw%3D%3D",
                "_blank"
              )
            }
          >
            Get Directions
            <PiMapPinFill className="ml-2 h-4 w-4" />
          </button>
        </div>
      </motion.div>
      <Footer />
    </div>
  );
}

export default ContactUs;

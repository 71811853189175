import React from "react";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { RxCross1 } from "react-icons/rx";
import Button from "../Button/Button";
import FormikInput from "../Input/FormikInput";
import * as Yup from "yup";
import Multiselect from "multiselect-react-dropdown";
import File from "../Input/File";
import Select from "react-select";
import FormikSelect from "../Input/FormikSelect";
import { Types } from "../Data";

function SceneContinuityForm({
  View,
  setView,
  scene_numbers,
  scene_continuities,
  addSceneContinuity,
}) {
  const handleBackdropClick = () => {
    setView(!View);
  };

  const validationSchema = Yup.object().shape({
    scene_number: Yup.string().required("Scene number is required"),
    continuity_types: Yup.array()
      .of(Yup.string().required("Continuity type is required"))
      .required("Continuity types are required")
      .min(1, "At least one continuity type is required"),
    scene_continuities: Yup.array()
      .of(Yup.object().required("Each scene continuity must be an object"))
      .required("Scene continuities are required")
      .min(1, "At least one scene continuity is required"),
    remark: Yup.string(),
  });

  const handleContinuityChange = (selectedOption, push, remove, values) => {
    const selectedScene = selectedOption.map((option) => {
      const Scene = option.scene_number + option.sub_scene || "";
      return Scene;
    });

    selectedScene.forEach((element) => {
      const sceneAlreadySelected = values.scene_continuities.some(
        (scene) => scene.scene_number == element
      );
      if (!sceneAlreadySelected) {
        push({
          scene_number: element,
        });
      }
    });

    values.scene_continuities.forEach((scene, index) => {
      if (!selectedScene.includes(scene.scene_number)) {
        remove(index);
      }
    });
  };

  const handleTypeChange = (selectedOption, push, remove, values) => {
    const selectedType = selectedOption.map((option) => option.label);

    selectedType.forEach((element) => {
      const typeAlreadySelected = values.continuity_types.some(
        (type) => type == element
      );
      if (!typeAlreadySelected) {
        push(element);
      }
    });

    values.continuity_types.forEach((type, index) => {
      if (!selectedType.includes(type)) {
        remove(index);
      }
    });
  };

  const renderSceneOptions = (scenes) =>
    scenes.map((scene, index) => (
      <option key={index} value={scene.scene_number + (scene.sub_scene || "")}>
        {scene.scene_number}
        {scene.sub_scene || ""}
      </option>
    ));

  

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 overflow-auto no-scrollbar bg-black bg-opacity-50">
      <div className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto ">
        <div className="flex-col bg-white p-4 sm:p-8 rounded shadow-md max-w-xl sm:max-w-xl w-full sm:my-auto my-auto ">
          <div className="flex items-end justify-end">
            <button
              className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full "
              onClick={handleBackdropClick}
            >
              <RxCross1 />
            </button>
          </div>
          <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
            Scene Continuity
          </h2>
          <Formik
            initialValues={{
              scene_number: "",
              continuity_types: [],
              scene_continuities: [],
              remark: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => addSceneContinuity(values)}
          >
            {(formik) => (
              <Form>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                  <div>
                    <label className="block mb-2 text-sm text-gray-600 ">
                      Scene
                    </label>
                    <Field
                      name="scene_number"
                      as="select"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#1d5b79]  focus:ring-[#1d5b79] focus:outline-none focus:ring focus:ring-opacity-40"
                    >
                      <option value="" disabled>
                        Select Scene number
                      </option>
                      {renderSceneOptions(
                        scene_numbers
                          .sort((a, b) =>
                            (
                              a.scene_number + (a.sub_scene || "")
                            ).localeCompare(
                              b.scene_number + (b.sub_scene || "")
                            )
                          )
                          .filter(
                            (data) =>
                              !scene_continuities.find(
                                (e) =>
                                  e.scene_number ===
                                  data.scene_number + (data.sub_scene || "")
                              )
                          )
                      )}
                    </Field>
                    <ErrorMessage
                      name="scene_number"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <FieldArray name="continuity_types">
                    {({ push, remove }) => (
                      <div>
                        <label className="block mb-2 text-sm text-gray-600 ">
                          Continuity types
                        </label>
                        <Select
                          options={Types}
                          isMulti
                          name="continuity_types"
                          isClearable={false}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.label}
                          onChange={(selectedOption) =>
                            handleTypeChange(
                              selectedOption,
                              push,
                              remove,
                              formik.values
                            )
                          }
                        />
                        <ErrorMessage
                          name="continuity_types"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                    )}
                  </FieldArray>
                </div>
                <FieldArray name="scene_continuities">
                  {({ push, remove }) => (
                    <>
                      <div className="sm:mt-4 mt-2">
                        <label className="block mb-2 text-sm text-gray-600">
                          Continuity Scene
                        </label>
                        <Select
                          name="scene_continuities"
                          isMulti
                          isClearable={false}
                          isDisabled={
                            formik.values.scene_number === "" ? true : false
                          }
                          options={scene_numbers.filter(
                            (data) =>
                              !formik.values.scene_continuities.some(
                                (e) =>
                                  e.scene_number ===
                                  data.scene_number + (data.sub_scene || "")
                              ) &&
                              data.scene_number > formik.values.scene_number
                          )}
                          getOptionLabel={(option) => {
                            const Scene =
                              option.scene_number + option.sub_scene || "";
                            return Scene;
                          }}
                          getOptionValue={(option) => {
                            const Scene =
                              option.scene_number + option.sub_scene || "";
                            return Scene;
                          }}
                          onChange={(selectedOption) =>
                            handleContinuityChange(
                              selectedOption,
                              push,
                              remove,
                              formik.values
                            )
                          }
                        />
                        <ErrorMessage
                          name="scene_continuities"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                    </>
                  )}
                </FieldArray>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                  <div>
                    <FormikInput
                      name="remark"
                      placeholder="remark"
                      type="text"
                      label="remark"
                    />
                  </div>
                </div>
                <div className="mt-5">
                  <Button name="submit" type="submit" />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default SceneContinuityForm;

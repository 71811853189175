import React, { useContext, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { IoMenuOutline } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";
import { TbPackages } from "react-icons/tb";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlinePendingActions } from "react-icons/md";
import toast from "react-hot-toast";
import { IoIosLogOut } from "react-icons/io";
import { HiMiniBuildingOffice2 } from "react-icons/hi2";
import { BsCameraReelsFill } from "react-icons/bs";
import { PiCrownDuotone } from "react-icons/pi";
import { SidebarContext, sidebarContext } from "../SidebarContext";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";

function Sidebar() {
  const { view, toggleView } = useContext(SidebarContext);
  const navigate = useNavigate();
  const tabs = [
    {
      id: 1,
      label: "Users",
      path: "Super-Admin",
      icon: <MdOutlinePendingActions />,
    },
    { id: 2, label: "Packages", path: "Packages", icon: <TbPackages /> },
    {
      id: 3,
      label: "Departments",
      path: "Department",
      icon: <HiMiniBuildingOffice2 />,
    },
    {
      id: 4,
      label: "Shot division",
      path: "ShotDivision",
      icon: <BsCameraReelsFill />,
    },
  ];

  const handleTabClick = (tabPath) => {
    if (window.location.href.includes(tabPath)) {
      return `bg-white text-[#1d5b79] rounded-s-3xl rounded-e-3xl sm:activeTab ${
        view && "sm:activeTab-2"
      } relative text-base flex mt-1 items-center gap-x-2 cursor-pointer p-2 w-full sm:w-56 group  duration-100 ease-in`;
    } else {
      return "text-[#fbffff] text-base flex mt-1 items-center gap-x-2 cursor-pointer p-2  duration-100 ease-in  hover:rounded-3xl w-full sm:w-96";
    }
  };

  const handleLogOut = () => {
    toast.success("Logged out! Come again soon.");
    localStorage.clear();
    navigate("/Login");
  };

  return (
    <div className=" ">
      <button
        onClick={toggleView}
        className="fixed flex sm:hidden top-5 left-5 text-[#fbffff] bg-[#1d5b79] text-xl outline-none shadow-lg font-bold p-3 rounded-full hover:shadow-none border-2 border-[#fbffff] duration-200 ease-out sm:text-3xl sm:p-4 z-50"
      >
        {view ? <IoMenuOutline /> : <RxCross1 />}
      </button>
      <nav
        className={`${
          view
            ? "sm:w-20 scale-0 sm:scale-100 origin-left"
            : "sm:w-60 w-11/12 z-40"
        } sm:h-screen h-full bg-[#1d5b79] p-5 duration-100 fixed rounded-br-2xl rounded-tr-2xl overflow-x-hidden overflow-y-auto no-scrollbar`}
      >
        <button
          onClick={toggleView}
          className="bg-white text-[#1d5b79] w-8 h-8 items-center justify-center p-2 top-9 -right-4 border-[#1d5b79]  rounded-full  border-2 hidden sm:flex "
        >
          <span className="text-xl font-bold">
            {view ? <AiOutlineMenuUnfold /> : <AiOutlineMenuFold />}
          </span>
        </button>
        <div
          className={`${
            view && "scale-0 hidden"
          } m-5  flex-col sm:items-center sm:justify-center items-center justify-center mt-20 lg:mt-10`}
        >
          <h1 className="text-[#fbffff] uppercase text-xl sm:text-xl sm:mt-0 mt-4 font-bold">
            Super Admin
          </h1>
        </div>
        <ul className="flex flex-col mt-5">
          {tabs.map((tab) => (
            <li key={tab.id}>
              <Link
                to={`/${tab.path}`}
                className={`${handleTabClick(tab.path)} `}
              >
                <span>{tab.icon}</span>
                <span className={`${view && "hidden"} origin-left duration-75`}>
                  {tab.label}
                </span>
              </Link>
            </li>
          ))}
        </ul>
        <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2">
          <button
            onClick={handleLogOut}
            className="flex items-center justify-center px-4 py-2 bg-[#fbffff] outline-none border-none rounded-3xl  text-[#1d5b79]  shadow-white"
          >
            <span className="mt-1">
              <IoIosLogOut />
            </span>
            <span className={`${view && "hidden"} origin-right duration-300`}>
              Log out
            </span>
          </button>
        </div>
      </nav>
    </div>
  );
}

export default Sidebar;

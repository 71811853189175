import React, { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { FaPlus } from "react-icons/fa";
import Button from "../Button/Button";
import { Field, FieldArray, Form, Formik } from "formik";
import FormikInput from "../Input/FormikInput";
import FormikSelect from "../Input/FormikSelect";
import FormikRadio from "../Input/FormikRadio";
import {
  GenderOption,
  LocationTypeOption,
  crowdAgeOption,
  shiftOption,
  subScene,
} from "../Data";
import { v4 as uuidv4 } from "uuid";
import Select from "react-select";

function SceneForm({
  validationSchema,
  handleBackdropClick,
  locations,
  artists,
  addSceneHandler,
  choreographerData,
  actionDirectorData,
}) {
  const [subLocation, setSubLocation] = useState([]);

  const NewArtistArray = artists
    .sort(
      (a, b) => (a.artist_type === "Main Lead" - b.artist_type) === "Teenager"
    )
    .map((data) => {
      return {
        value: data.artist_id,
        id: data.artist_id,
        label: data.character_name,
        artist_type: data.artist_type,
      };
    });

  const handleShift = (value, setFieldValue) => {
    if (value) {
      setFieldValue("shift", value);
    } else {
      setFieldValue("shift", value);
    }
  };

  const handleArtistChange = (selectedOptions, push, remove, values) => {
    const selectedArtistIds = selectedOptions.map((option) => option.value);

    selectedArtistIds.forEach((selectedArtistId) => {
      const artistAlreadyAdded = values.artists_scenes_attributes.some(
        (artistScene) => artistScene.artist_id === selectedArtistId
      );
      if (!artistAlreadyAdded) {
        push({
          artist_id: selectedArtistId,
          costume: "",
        });
      }
    });

    values.artists_scenes_attributes.forEach((artistScene, index) => {
      if (!selectedArtistIds.includes(artistScene.artist_id)) {
        remove(index);
      }
    });
    if (!selectedOptions.length) {
      values.artists_scenes_attributes.forEach((_, index) => remove(index));
    }
  };

  const handleLocationTYpe = (value, setFieldValue) => {
    if (value) {
      setFieldValue("location_type", value);
    } else {
      setFieldValue("location_type", value);
    }
  };

  const handleLocationNameChange = (value, setFieldValue) => {
    const location = locations.find((location) => location.name === value);

    setFieldValue("location_name", value);
    setFieldValue("location_address", location.address);
    setFieldValue("location_link", location.location_link);
    setSubLocation(location.sublocation.sublocations);
  };
  const handleActionDirectorName = (value, setFieldValue) => {
    setFieldValue("action_director", value);
  };

  const handleActionSequenceChange = (value, setFieldValue) => {
    if (value === true) {
      setFieldValue("action_sequence_required", value);
    } else {
      setFieldValue("action_sequence_required", value);
    }
  };
  const handleDanceChoreographerName = (value, setFieldValue) => {
    setFieldValue("dance_choreographer", value);
  };

  const handleDanceChoreographer = (value, setFieldValue) => {
    if (value === true) {
      setFieldValue("dance_choreographer_required", value);
    } else {
      setFieldValue("dance_choreographer_required", value);
    }
  };

  const handleVFXChange = (value, setFieldValue) => {
    if (value === true) {
      setFieldValue("vfx_required", value);
    } else {
      setFieldValue("vfx_required", value);
    }
  };

  const handleAddLight = (value, setFieldValue) => {
    if (value === true) {
      setFieldValue("additional_lights", value);
    } else {
      setFieldValue("additional_lights", value);
    }
  };

  const handleAddCrowd = (value, setFieldValue) => {
    if (value === true) {
      setFieldValue("crowd_required", value);
    } else {
      setFieldValue("crowd_required", value);
    }
  };

  const handleCrowdAge = (value, setFieldValue, index) => {
    setFieldValue(`crowd_details.details.${index}.crowdAge`, value);
  };

  const handleCrowdGender = (value, setFieldValue, index) => {
    setFieldValue(`crowd_details.details.${index}.crowdType`, value);
  };

  const handleCrowdCostume = (value, setFieldValue, index) => {
    setFieldValue(`crowd_details.details.${index}.crowdCostume`, value);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 overflow-auto  bg-black bg-opacity-50">
      <div className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto ">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-9xl sm:max-w-9xl w-full sm:my-auto  my-auto">
          <div className="flex items-end justify-end">
            <button
              className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full "
              onClick={handleBackdropClick}
            >
              <RxCross1 />
            </button>
          </div>
          <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
            Scene
          </h2>
          <h2 className="text-base sm:text-xl font-semibold mb-2 sm:mb-4 text-[#020002]">
            Scene details
          </h2>
          <Formik
            validationSchema={validationSchema}
            initialValues={{
              scene_number: "",
              sub_scene: "",
              start_page: "",
              end_page: "",
              brief_of_scene: "",
              shift: "",
              location_type: "",
              location_name: "",
              location_link: "",
              sub_location: "",
              location_address: "",
              art_department_property: "",
              production_team_property: "",
              makeup_department_property: "",
              selectedArtist: "",
              artists_scenes_attributes: [],
              action_sequence_required: false,
              action_director: "",
              no_of_action_man: 0,
              action_man_costume: "",
              dance_choreographer_required: false,
              dance_choreographer: "",
              no_of_dancers: 0,
              dancers_costume: "",
              vfx_required: false,
              vfx: "",
              additional_lights: false,
              type_of_light: "",
              crowd_required: false,
              crowd_details: {
                details: [],
              },
            }}
            onSubmit={addSceneHandler}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className="grid grid-cols-1 sm:grid-cols-4 gap-2 sm:gap-4 mt-2 sm:mt-4">
                  <div>
                    <FormikInput
                      label={"Scene number"}
                      placeholder={"Scene number"}
                      name={"scene_number"}
                      type={"number"}
                    />
                  </div>
                  <div>
                    <FormikSelect
                      label={"Subsidiary Scene"}
                      selectedOption={"Sub scene"}
                      name={"sub_scene"}
                      optionData={subScene}
                      onChange={(e) => {
                        setFieldValue("sub_scene", e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <FormikInput
                      label={"Start page"}
                      type={"number"}
                      placeholder={"Start page"}
                      name={"start_page"}
                    />
                  </div>
                  <div>
                    <FormikInput
                      label={"End Page"}
                      type={"number"}
                      placeholder={"End page"}
                      name={"end_page"}
                    />
                  </div>
                  <div>
                    <FormikInput
                      label={"Scene brief"}
                      placeholder={"Scene brief"}
                      type={"text"}
                      name={"brief_of_scene"}
                    />
                  </div>
                  <div>
                    <FormikSelect
                      label={"Shift"}
                      selectedOption={"Shift"}
                      onChange={(e) =>
                        handleShift(e.target.value, setFieldValue)
                      }
                      optionData={shiftOption}
                      name={"shift"}
                    />
                  </div>
                  <div>
                    <FormikSelect
                      label={"Location type"}
                      selectedOption={"Location type"}
                      onChange={(e) =>
                        handleLocationTYpe(e.target.value, setFieldValue)
                      }
                      name={"location_type"}
                      optionData={LocationTypeOption}
                    />
                  </div>
                  <div>
                    <FormikSelect
                      label={"Location name"}
                      selectedOption={"Location name"}
                      optionData={locations}
                      onChange={(e) =>
                        handleLocationNameChange(e.target.value, setFieldValue)
                      }
                      name={"location_name"}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                  <div>
                    <FormikInput
                      label={"Address"}
                      type={"text"}
                      placeholder={"Address"}
                      value={values.location_address}
                      name={"location_address"}
                      readOnly={true}
                    />
                  </div>
                  {subLocation && (
                    <div>
                      <label className="block mb-2 text-sm text-gray-600">
                        Sub location
                      </label>
                      <Field
                        as="select"
                        name="sub_location"
                        className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md focus:border-[#1d5b79] focus:ring-[#1d5b79] focus:outline-none focus:ring focus:ring-opacity-40"
                      >
                        <option selected>Sub location</option>
                        {subLocation.map((sub_location) => (
                          <option>{sub_location}</option>
                        ))}
                      </Field>
                    </div>
                  )}
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 sm:gap-4 mt-2 sm:mt-4">
                  <div>
                    <FormikInput
                      name={"art_department_property"}
                      label={"Art department property"}
                      type={"text"}
                      placeholder={"Art department property"}
                    />
                  </div>
                  <div>
                    <FormikInput
                      name={"production_team_property"}
                      label={"Production department property"}
                      placeholder={"Production department property"}
                      type={"text"}
                    />
                  </div>
                  <div>
                    <FormikInput
                      name={"makeup_department_property"}
                      type={"text"}
                      label={"Make up"}
                      placeholder={"Make up"}
                    />
                  </div>
                </div>

                <h2 className="text-base sm:text-xl font-semibold mb-2 sm:mb-4 mt-2 sm:mt-4 text-[#020002]">
                  Artist details
                </h2>
                <FieldArray name="artists_scenes_attributes">
                  {({ push, remove }) => (
                    <div>
                      <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 sm:gap-4 mt-2 sm:mt-4">
                        <div>
                          <label className="block mb-2 text-sm text-gray-600">
                            Artist
                          </label>
                          <Select
                            name="artists_scenes_attributes"
                            options={NewArtistArray}
                            isMulti
                            isClearable={false}
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.value}
                            onChange={(selectedOptions) =>
                              handleArtistChange(
                                selectedOptions,
                                push,
                                remove,
                                values
                              )
                            }
                          />
                        </div>
                      </div>

                      <div className="grid grid-cols-2 sm:grid-cols-4 gap-2 sm:gap-4 mt-2 sm:mt-4">
                        {values.artists_scenes_attributes.map(
                          (artist, index) => {
                            return (
                              <div key={index}>
                                <label className="block mb-2 text-sm text-gray-600">
                                  {
                                    NewArtistArray.find(
                                      (a) => a.id === artist.artist_id
                                    )?.label
                                  }
                                </label>
                                <Field
                                  name={`artists_scenes_attributes[${index}].costume`}
                                  placeholder="Costume"
                                  type="text"
                                  className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md focus:border-[#1d5b79] focus:ring-[#1d5b79] focus:outline-none focus:ring focus:ring-opacity-40"
                                />
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  )}
                </FieldArray>
                <h2 className="text-base sm:text-xl font-semibold mb-2 sm:mb-4 mt-2 sm:mt-4 text-[#020002]">
                  Extra details
                </h2>
                <div className="mt-2 sm:mt-4">
                  <label className="block mb-2 text-sm text-gray-600 ">
                    Does this scene require action director?
                  </label>
                  <div className="flex flex-row">
                    <div>
                      <FormikRadio
                        id={"yes"}
                        label={"Yes"}
                        value={true}
                        onChange={() =>
                          handleActionSequenceChange(true, setFieldValue)
                        }
                        name={"action_sequence_required"}
                      />
                    </div>
                    <div className="ml-2">
                      <FormikRadio
                        id={"no"}
                        value={false}
                        onChange={() =>
                          handleActionSequenceChange(false, setFieldValue)
                        }
                        label={"No"}
                        name={"action_sequence_required"}
                      />
                    </div>
                  </div>
                </div>

                {values.action_sequence_required ? (
                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 sm:gap-4 mt-2 sm:mt-4">
                    <div>
                      <FormikSelect
                        name="action_director"
                        label={"Action director"}
                        selectedOption={"Action director"}
                        optionData={actionDirectorData}
                        onChange={(e) =>
                          handleActionDirectorName(
                            e.target.value,
                            setFieldValue
                          )
                        }
                      />
                    </div>
                    <div>
                      <FormikInput
                        label={"Number of action man"}
                        placeholder={"Number of action man"}
                        name={"no_of_action_man"}
                        type={"number"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        label={"Action man costume"}
                        placeholder={"Action man costume"}
                        type={"text"}
                        name={"action_man_costume"}
                      />
                    </div>
                  </div>
                ) : null}
                <div className="mt-2 sm:mt-4">
                  <label className="block mb-2 text-sm text-gray-600 ">
                    Does this scene require choreographer?
                  </label>
                  <div className="flex flex-row">
                    <div>
                      <FormikRadio
                        id={"yes"}
                        label={"Yes"}
                        value={true}
                        onChange={() =>
                          handleDanceChoreographer(true, setFieldValue)
                        }
                        name={"dance_choreographer_required"}
                      />
                    </div>
                    <div className="ml-2">
                      <FormikRadio
                        id={"no"}
                        label={"No"}
                        value={false}
                        onChange={() =>
                          handleDanceChoreographer(false, setFieldValue)
                        }
                        name={"dance_choreographer_required"}
                      />
                    </div>
                  </div>
                </div>
                {values.dance_choreographer_required ? (
                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 sm:gap-4 mt-2 sm:mt-4">
                    <div>
                      <FormikSelect
                        label={"Choreographer"}
                        name={"dance_choreographer"}
                        selectedOption={"Choreographer"}
                        optionData={choreographerData}
                        onChange={(e) =>
                          handleDanceChoreographerName(
                            e.target.value,
                            setFieldValue
                          )
                        }
                      />
                    </div>
                    <div>
                      <FormikInput
                        label={"Number of dancer"}
                        type={"number"}
                        placeholder={"Number of dancer"}
                        name={"no_of_dancers"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        label={"Dancer costume"}
                        type={"text"}
                        placeholder={"Dancer costume"}
                        name={"dancers_costume"}
                      />
                    </div>
                  </div>
                ) : null}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                  <div>
                    <label className="block mb-2 text-sm text-gray-600 ">
                      Does this scene require VFX Tools?
                    </label>
                    <div className="flex flex-row">
                      <div>
                        <FormikRadio
                          id={"yes"}
                          label={"Yes"}
                          value={true}
                          onChange={() => handleVFXChange(true, setFieldValue)}
                          name={"vfx_required"}
                        />
                      </div>
                      <div className="ml-2">
                        <FormikRadio
                          id={"no"}
                          value={false}
                          onChange={() => handleVFXChange(false, setFieldValue)}
                          label={"No"}
                          name={"vfx_required"}
                        />
                      </div>
                    </div>
                    {values.vfx_required ? (
                      <div className="mt-2 sm:mt-4">
                        <FormikInput
                          label={"VFX"}
                          name={"vfx"}
                          placeholder={"VFX"}
                          type={"text"}
                        />
                      </div>
                    ) : null}
                  </div>
                  <div>
                    <label className="block mb-2 text-sm text-gray-600 ">
                      Does this scene require extra lights?
                    </label>
                    <div className="flex flex-row">
                      <div>
                        <FormikRadio
                          id={"yes"}
                          label={"Yes"}
                          value={true}
                          onChange={() => handleAddLight(true, setFieldValue)}
                          name={"additional_lights"}
                        />
                      </div>
                      <div className="ml-2">
                        <FormikRadio
                          id={"no"}
                          value={false}
                          onChange={() => handleAddLight(false, setFieldValue)}
                          label={"No"}
                          name={"additional_lights"}
                        />
                      </div>
                    </div>
                    {values.additional_lights ? (
                      <div className="mt-2 sm:mt-4">
                        <FormikInput
                          name={"type_of_light"}
                          placeholder={"Additional lights"}
                          label={"Additional lights"}
                          type={"text"}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="mt-2 sm:mt-4">
                  <label className="block mb-2 text-sm text-gray-600 ">
                    Does this scene require Crowd?
                  </label>
                  <div className="flex flex-row">
                    <div>
                      <FormikRadio
                        id={"yes"}
                        label={"Yes"}
                        value={true}
                        onChange={() => handleAddCrowd(true, setFieldValue)}
                        name={"crowd_required"}
                      />
                    </div>
                    <div className="ml-2">
                      <FormikRadio
                        id={"no"}
                        value={false}
                        onChange={() => handleAddCrowd(false, setFieldValue)}
                        label={"No"}
                        name={"crowd_required"}
                      />
                    </div>
                  </div>
                </div>

                {values.crowd_required ? (
                  <FieldArray name="crowd_details.details">
                    {({ push }) => (
                      <div>
                        {values.crowd_details.details.map((detail, index) => (
                          <div
                            key={index}
                            className="grid grid-cols-1 sm:grid-cols-3 gap-2 sm:gap-4 mt-2 sm:mt-4"
                          >
                            <div>
                              <FormikSelect
                                label={"Crowd age"}
                                selectedOption={"Crowd Age"}
                                onChange={(e) =>
                                  handleCrowdAge(
                                    e.target.value,
                                    setFieldValue,
                                    index
                                  )
                                }
                                optionData={crowdAgeOption}
                                name={`crowd_details.details${index}.crowdAge`}
                              />
                            </div>
                            <div>
                              <FormikSelect
                                label={"Crowd gender"}
                                onChange={(e) =>
                                  handleCrowdGender(
                                    e.target.value,
                                    setFieldValue,
                                    index
                                  )
                                }
                                selectedOption={"Gender"}
                                optionData={GenderOption}
                                name={`crowd_details.details.${index}.crowdType`}
                              />
                            </div>
                            <div>
                              <FormikInput
                                label={"Crowd costume"}
                                placeholder={"Costume"}
                                type={"text"}
                                name={`crowd_details.details.${index}.crowdCostume`}
                              />
                            </div>
                            <div>
                              <FormikInput
                                label={"Number of people"}
                                placeholder={"Number of people"}
                                type={"number"}
                                name={`crowd_details.details.${index}.crowdNumber`}
                              />
                            </div>
                            <div>
                              <FormikInput
                                label={"Description"}
                                placeholder={"Descripiton"}
                                type={"text"}
                                name={`crowd_details.details.${index}.description`}
                              />
                            </div>
                          </div>
                        ))}
                       
                        <div className="flex flex-row">
                          <label className="block mt-10 text-sm text-gray-600 ">
                            Add crowd set:
                          </label>
                          <button
                            type="button"
                            onClick={() =>
                              push({
                                crowd_id: uuidv4(),
                                crowdAge: "",
                                crowdType: "",
                                crowdNumber: "",
                                crowdCostume: "",
                                description: "",
                              })
                            }
                            className="mt-8 ml-2 h-10 flex items-center justify-center w-10 text-xl bg-[#1d5b79] text-[#fbffff] p-2 rounded-full hover:bg-[#a9c8e2] ease-in-out duration-300"
                          >
                            <FaPlus />
                          </button>
                        </div>
                      </div>
                    )}
                  </FieldArray>
                ) : null}
                <div className="mt-2 sm:mt-4">
                  <Button name={"Add"} type="submit" />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default SceneForm;

import axios from "axios";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { MdDelete, MdModeEdit } from "react-icons/md";
import ReactPaginate from "react-paginate";
import toast from "react-hot-toast";
import FormikInput from "../../../../components/Input/FormikInput";
import Button from "../../../../components/Button/Button";
function ShotDivisionTable({
  data,
  CardName,
  tableHead,
  Shottype,
  Shotfocus,
  Shotangle,
  Shotframe,
  Shotmechanism,
  Shotmovements,
  getShotDivisionData,
  validationSchema,
}) {
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(5);
  const [view, setView] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = CardName.toLowerCase().includes("angle")
    ? Shotangle.slice(indexOfFirstItem, indexOfLastItem)
    : CardName.toLowerCase().includes("focus")
    ? Shotfocus.slice(indexOfFirstItem, indexOfLastItem)
    : CardName.toLowerCase().includes("type")
    ? Shottype.slice(indexOfFirstItem, indexOfLastItem)
    : CardName.toLowerCase().includes("frame")
    ? Shotframe.slice(indexOfFirstItem, indexOfLastItem)
    : CardName.toLowerCase().includes("mechanism")
    ? Shotmechanism.slice(indexOfFirstItem, indexOfLastItem)
    : Shotmovements.slice(indexOfFirstItem, indexOfLastItem);
  //page Count
  let pageCount;
  if (CardName.toLowerCase().includes("angle")) {
    pageCount = Math.ceil(Shotangle.length / itemsPerPage);
  } else if (CardName.toLowerCase().includes("focus")) {
    pageCount = Math.ceil(Shotfocus.length / itemsPerPage);
  } else if (CardName.toLowerCase().includes("type")) {
    pageCount = Math.ceil(Shottype.length / itemsPerPage);
  } else if (CardName.toLowerCase().includes("frame")) {
    pageCount = Math.ceil(Shotframe.length / itemsPerPage);
  } else if (CardName.toLowerCase().includes("mechanism")) {
    pageCount = Math.ceil(Shotmechanism.length / itemsPerPage);
  } else {
    pageCount = Math.ceil(Shotmovements.length / itemsPerPage);
  }
  //handleDeleteshot
  const handleDeleteshot = (perticularShot) => {
    const array = Shotangle.includes(perticularShot)
      ? Shotangle
      : Shotfocus.includes(perticularShot)
      ? Shotfocus
      : Shottype.includes(perticularShot)
      ? Shottype
      : Shotframe.includes(perticularShot)
      ? Shotframe
      : Shotmechanism.includes(perticularShot)
      ? Shotmechanism
      : Shotmovements.includes(perticularShot)
      ? Shotmovements
      : null;
    const index = array.indexOf(perticularShot);

    CardName.toLowerCase().includes("angle")
      ? Shotangle.splice(index, 1)
      : CardName.toLowerCase().includes("focus")
      ? Shotfocus.splice(index, 1)
      : CardName.toLowerCase().includes("type")
      ? Shottype.splice(index, 1)
      : CardName.toLowerCase().includes("frame")
      ? Shotframe.splice(index, 1)
      : CardName.toLowerCase().includes("mechanism")
      ? Shotmechanism.splice(index, 1)
      : Shotmovements.splice(index, 1);
    axios
      .put("api/v1/shots/1", {
        shot: {
          add_shot_angle: Shotangle,
          add_shot_focus: Shotfocus,
          add_shot_type: Shottype,
          add_shot_frame: Shotframe,
          add_shot_mechanism: Shotmechanism,
          add_shot_movements: Shotmovements,
        },
      })
      .then((response) => {
        toast.success("deleted successfully...");
        getShotDivisionData();
      })
      .catch((error) => console.log(error));
  };

  const viewUpdateColumn = (perticularShot) => {
    setView(!view);
    const array = Shotangle.includes(perticularShot)
      ? Shotangle
      : Shotfocus.includes(perticularShot)
      ? Shotfocus
      : Shottype.includes(perticularShot)
      ? Shottype
      : Shotframe.includes(perticularShot)
      ? Shotframe
      : Shotmechanism.includes(perticularShot)
      ? Shotmechanism
      : Shotmovements.includes(perticularShot)
      ? Shotmovements
      : null;
    const index = array.indexOf(perticularShot);
    setCurrentIndex(index);
  };
  const UpdateShot = (values) => {
    CardName.toLowerCase().includes("angle")
      ? (Shotangle[currentIndex] = values.shotangle)
      : CardName.toLowerCase().includes("focus")
      ? (Shotfocus[currentIndex] = values.shotfocus)
      : CardName.toLowerCase().includes("type")
      ? (Shottype[currentIndex] = values.shottype)
      : CardName.toLowerCase().includes("frame")
      ? (Shotframe[currentIndex] = values.shotframe)
      : CardName.toLowerCase().includes("mechanism")
      ? (Shotmechanism[currentIndex] = values.shotmechanism)
      : (Shotmovements[currentIndex] = values.shotmovements);
    axios
      .put("api/v1/shots/1", {
        shot: {
          add_shot_angle: Shotangle,
          add_shot_focus: Shotfocus,
          add_shot_type: Shottype,
          add_shot_frame: Shotframe,
          add_shot_mechanism: Shotmechanism,
          add_shot_movements: Shotmovements,
        },
      })
      .then((response) => {
        toast.success("Update successfully...");
        getShotDivisionData();
        setView(!view);
      })
      .catch((error) => console.log(error));
  };
  return (
    <>
      {pageCount > 1 && (
        <div className="mt-2 flex justify-end mb-2">
          <ReactPaginate
            pageCount={pageCount}
            onPageChange={handlePageChange}
            containerClassName={"flex"}
            pageRangeDisplayed={1}
            previousLabel={<FaChevronLeft />}
            nextLabel={<FaChevronRight />}
            activeClassName={"active"}
            previousClassName={
              "bg-[#1d5b79] text-[#fbffff] w-7 h-7 items-center justify-center p-2 top-9 -right-4 text-xl flex rounded"
            }
            nextClassName={
              "bg-[#1d5b79] text-[#fbffff] w-7 h-7 items-center justify-center p-2 top-9 -right-4 text-xl flex rounded"
            }
            pageClassName={"mx-1"}
            pageLinkClassName={
              "bg-gray-200 text-[#020002] w-7 h-7 items-center justify-center p-2 top-9 -right-4 text-xl flex rounded"
            }
            breakClassName={"mx-1"}
            breakLinkClassName={"px-3 py-1 bg-gray-200 text-gray-600 rounded"}
          />
        </div>
      )}
      <div className="flex flex-col w-full rounded-lg 2xl:shadow-lg lg:shadow-lg sm:shadow-lg sm:max-h-full lg:max-h-full  2xl:max-h-full overflow-auto">
        <div className="sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-x-auto bg-[#fbffff]">
              <table className="min-w-full text-left text-sm font-light ">
                <thead className="border-b font-medium bg-[#edf8f8] rounded-s-lg">
                  <tr>
                    <th className="p-4  whitespace-nowrap text-[#020002]">
                      {"Sr No."}
                    </th>
                    <th className="p-4  whitespace-nowrap text-[#020002]">
                      {tableHead}
                    </th>
                    <th
                      colSpan={2}
                      className="p-4 text-center whitespace-nowrap text-[#020002]"
                    >
                      {"Action"}
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-100">
                  {CardName.toLowerCase().includes("angle")
                    ? data.map((details) => {
                        return (
                          <>
                            {currentItems.map((angle, index) => {
                              return (
                                <tr className="p-4 whitespace-nowrap">
                                  <td className="p-4 whitespace-nowrap">
                                    {index + 1}
                                  </td>
                                  {view &&
                                  (currentIndex > 4
                                    ? currentIndex - 5 === index
                                    : currentIndex === index) ? (
                                    <td key={index}>
                                      <Formik
                                        validationSchema={validationSchema}
                                        initialValues={{ shotangle: "" }}
                                        onSubmit={(values) =>
                                          UpdateShot(values)
                                        }
                                      >
                                        <Form>
                                          <div className="flex flex-row ml-2">
                                            <div>
                                              <FormikInput
                                                name={"shotangle"}
                                                type={"text"}
                                                placeholder={"shotangle"}
                                              />
                                            </div>
                                            <div className="mt-2 ml-2">
                                              <Button
                                                name={"Update"}
                                                type={"submit"}
                                              />
                                            </div>
                                          </div>
                                        </Form>
                                      </Formik>
                                    </td>
                                  ) : (
                                    <td className="p-4 whitespace-nowrap">
                                      {angle}
                                    </td>
                                  )}
                                  <td className="p-4 whitespace-nowrap flex">
                                    <div>
                                      <button
                                        onClick={() => viewUpdateColumn(angle)}
                                        className="p-0 mr-4 text-xl text-green-600 border-none hover:shadow-none rounded-full outline-none bg-green-50 shadow-lg duration-300 ease-in"
                                      >
                                        <MdModeEdit />
                                      </button>
                                      <button
                                        onClick={() => handleDeleteshot(angle)}
                                        className=" p-0 text-lg text-red-600 border-none hover:shadow-none rounded-full outline-none bg-red-50 shadow-lg duration-300 ease-in"
                                      >
                                        <MdDelete />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        );
                      })
                    : CardName.toLowerCase().includes("focus")
                    ? data.map((details) => {
                        return (
                          <>
                            {currentItems.map((focus, index) => {
                              return (
                                <tr className="p-4 whitespace-nowrap">
                                  <td className="p-4 whitespace-nowrap">
                                    {index + 1}
                                  </td>
                                  {view &&
                                  (currentIndex > 4
                                    ? currentIndex - 5 === index
                                    : currentIndex === index) ? (
                                    <td key={index}>
                                      <Formik
                                        validationSchema={validationSchema}
                                        initialValues={{ shotfocus: "" }}
                                        onSubmit={(values) =>
                                          UpdateShot(values)
                                        }
                                      >
                                        <Form>
                                          <div className="flex flex-row ml-2">
                                            <div>
                                              <FormikInput
                                                name={"shotfocus"}
                                                type={"text"}
                                                placeholder={"shotfocus"}
                                              />
                                            </div>
                                            <div className="mt-2 ml-2">
                                              <Button
                                                name={"Update"}
                                                type={"submit"}
                                              />
                                            </div>
                                          </div>
                                        </Form>
                                      </Formik>
                                    </td>
                                  ) : (
                                    <td className="p-4 whitespace-nowrap">
                                      {focus}
                                    </td>
                                  )}
                                  <td className="p-4 whitespace-nowrap flex">
                                    <button
                                      onClick={() => viewUpdateColumn(focus)}
                                      className="p-0 mr-4 text-xl text-green-600 border-none hover:shadow-none rounded-full outline-none bg-green-50 shadow-lg duration-300 ease-in"
                                    >
                                      <MdModeEdit />
                                    </button>
                                    <button
                                      onClick={() => handleDeleteshot(focus)}
                                      className=" text-xl text-red-600 border-none hover:shadow-none rounded-full outline-none bg-red-50 shadow-lg duration-300 ease-in"
                                    >
                                      <MdDelete />
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        );
                      })
                    : CardName.toLowerCase().includes("type")
                    ? data.map((details) => {
                        return (
                          <>
                            {currentItems.map((type, index) => {
                              return (
                                <tr className="p-4 whitespace-nowrap">
                                  <td className="p-4 whitespace-nowrap">
                                    {index + 1}
                                  </td>
                                  {view &&
                                  (currentIndex > 4
                                    ? currentIndex - 5 === index
                                    : currentIndex === index) ? (
                                    <td key={index}>
                                      <Formik
                                        validationSchema={validationSchema}
                                        initialValues={{ shottype: "" }}
                                        onSubmit={(values) =>
                                          UpdateShot(values)
                                        }
                                      >
                                        <Form>
                                          <div className="flex flex-row ml-2">
                                            <div>
                                              <FormikInput
                                                name={"shottype"}
                                                type={"text"}
                                                placeholder={"shottype"}
                                              />
                                            </div>
                                            <div className="mt-2 ml-2">
                                              <Button
                                                name={"Update"}
                                                type={"submit"}
                                              />
                                            </div>
                                          </div>
                                        </Form>
                                      </Formik>
                                    </td>
                                  ) : (
                                    <td className="p-4 whitespace-nowrap">
                                      {type}
                                    </td>
                                  )}

                                  <td className="p-4 whitespace-nowrap flex">
                                    <button
                                      onClick={() => viewUpdateColumn(type)}
                                      className="p-0 mr-4 text-xl text-green-600 border-none hover:shadow-none rounded-full outline-none bg-green-50 shadow-lg duration-300 ease-in"
                                    >
                                      <MdModeEdit />
                                    </button>
                                    <button
                                      onClick={() => handleDeleteshot(type)}
                                      className=" text-xl text-red-600 border-none hover:shadow-none rounded-full outline-none bg-red-50 shadow-lg duration-300 ease-in"
                                    >
                                      <MdDelete />
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        );
                      })
                    : CardName.toLowerCase().includes("frame")
                    ? data.map((details) => {
                        return (
                          <>
                            {currentItems.map((frame, index) => {
                              return (
                                <tr className="p-4 whitespace-nowrap">
                                  {frame === null ? null : (
                                    <>
                                      <td className="p-4 whitespace-nowrap">
                                        {index + 1}
                                      </td>
                                      {view &&
                                      (currentIndex > 4
                                        ? currentIndex - 5 === index
                                        : currentIndex === index) ? (
                                        <td key={index}>
                                          <Formik
                                            validationSchema={validationSchema}
                                            initialValues={{ shotframe: "" }}
                                            onSubmit={(values) =>
                                              UpdateShot(values)
                                            }
                                          >
                                            <Form>
                                              <div className="flex flex-row ml-2">
                                                <div>
                                                  <FormikInput
                                                    name={"shotframe"}
                                                    type={"text"}
                                                    placeholder={"shotframe"}
                                                  />
                                                </div>
                                                <div className="mt-2 ml-2">
                                                  <Button
                                                    name={"Update"}
                                                    type={"submit"}
                                                  />
                                                </div>
                                              </div>
                                            </Form>
                                          </Formik>
                                        </td>
                                      ) : (
                                        <td className="p-4 whitespace-nowrap">
                                          {frame}
                                        </td>
                                      )}

                                      <td className="p-4 whitespace-nowrap flex">
                                        <button
                                          onClick={() =>
                                            viewUpdateColumn(frame)
                                          }
                                          className="p-0 mr-4 text-xl text-green-600 border-none hover:shadow-none rounded-full outline-none bg-green-50 shadow-lg duration-300 ease-in"
                                        >
                                          <MdModeEdit />
                                        </button>
                                        <button
                                          onClick={() =>
                                            handleDeleteshot(frame)
                                          }
                                          className=" text-xl text-red-600 border-none hover:shadow-none rounded-full outline-none bg-red-50 shadow-lg duration-300 ease-in"
                                        >
                                          <MdDelete />
                                        </button>
                                      </td>
                                    </>
                                  )}
                                </tr>
                              );
                            })}
                          </>
                        );
                      })
                    : CardName.toLowerCase().includes("mechanism")
                    ? data.map((details) => {
                        return (
                          <>
                            {currentItems.map((mechanism, index) => {
                              return (
                                <tr className="p-4 whitespace-nowrap">
                                  {mechanism === null ? null : (
                                    <>
                                      <td className="p-4 whitespace-nowrap">
                                        {index + 1}
                                      </td>
                                      {view &&
                                      (currentIndex > 4
                                        ? currentIndex - 5 === index
                                        : currentIndex === index) ? (
                                        <td key={index}>
                                          <Formik
                                            validationSchema={validationSchema}
                                            initialValues={{
                                              shotmechanism: "",
                                            }}
                                            onSubmit={(values) =>
                                              UpdateShot(values)
                                            }
                                          >
                                            <Form>
                                              <div className="flex flex-row ml-2">
                                                <div>
                                                  <FormikInput
                                                    name={"shotmechanism"}
                                                    type={"text"}
                                                    placeholder={
                                                      "shotmechanism"
                                                    }
                                                  />
                                                </div>
                                                <div className="mt-2 ml-2">
                                                  <Button
                                                    name={"Update"}
                                                    type={"submit"}
                                                  />
                                                </div>
                                              </div>
                                            </Form>
                                          </Formik>
                                        </td>
                                      ) : (
                                        <td className="p-4 whitespace-nowrap">
                                          {mechanism}
                                        </td>
                                      )}

                                      <td className="p-4 whitespace-nowrap flex">
                                        <button
                                          onClick={() =>
                                            viewUpdateColumn(mechanism)
                                          }
                                          className="p-0 mr-4 text-xl text-green-600 border-none hover:shadow-none rounded-full outline-none bg-green-50 shadow-lg duration-300 ease-in"
                                        >
                                          <MdModeEdit />
                                        </button>
                                        <button
                                          onClick={() =>
                                            handleDeleteshot(mechanism)
                                          }
                                          className=" text-xl text-red-600 border-none hover:shadow-none rounded-full outline-none bg-red-50 shadow-lg duration-300 ease-in"
                                        >
                                          <MdDelete />
                                        </button>
                                      </td>
                                    </>
                                  )}
                                </tr>
                              );
                            })}
                          </>
                        );
                      })
                    : data.map((details) => {
                        return (
                          <>
                            {currentItems.map((movements, index) => {
                              return (
                                <tr className="p-4 whitespace-nowrap">
                                  {movements === null ? null : (
                                    <>
                                      <td className="p-4 whitespace-nowrap">
                                        {index + 1}
                                      </td>
                                      {view &&
                                      (currentIndex > 4
                                        ? currentIndex - 5 === index
                                        : currentIndex === index) ? (
                                        <td key={index}>
                                          <Formik
                                            validationSchema={validationSchema}
                                            initialValues={{
                                              shotmovements: "",
                                            }}
                                            onSubmit={(values) =>
                                              UpdateShot(values)
                                            }
                                          >
                                            <Form>
                                              <div className="flex flex-row ml-2">
                                                <div>
                                                  <FormikInput
                                                    name={"shotmovements"}
                                                    type={"text"}
                                                    placeholder={
                                                      "shotmovements"
                                                    }
                                                  />
                                                </div>
                                                <div className="mt-2 ml-2">
                                                  <Button
                                                    name={"Update"}
                                                    type={"submit"}
                                                  />
                                                </div>
                                              </div>
                                            </Form>
                                          </Formik>
                                        </td>
                                      ) : (
                                        <td className="p-4 whitespace-nowrap">
                                          {movements}
                                        </td>
                                      )}

                                      <td className="p-4 whitespace-nowrap flex">
                                        <button
                                          onClick={() =>
                                            viewUpdateColumn(movements)
                                          }
                                          className="p-0 mr-4 text-xl text-green-600 border-none hover:shadow-none rounded-full outline-none bg-green-50 shadow-lg duration-300 ease-in"
                                        >
                                          <MdModeEdit />
                                        </button>
                                        <button
                                          onClick={() =>
                                            handleDeleteshot(movements)
                                          }
                                          className=" text-xl text-red-600 border-none hover:shadow-none rounded-full outline-none bg-red-50 shadow-lg duration-300 ease-in"
                                        >
                                          <MdDelete />
                                        </button>
                                      </td>
                                    </>
                                  )}
                                </tr>
                              );
                            })}
                          </>
                        );
                      })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ShotDivisionTable;

import React from "react";
import { RxCross1 } from "react-icons/rx";
import FormikInput from "../Input/FormikInput";
import { Form, Formik } from "formik";
import Button from "../Button/Button";
import FormikSelect from "../Input/FormikSelect";
import { vendorType } from "../Data";

function VendorUpdateForm({
  setUpdateVendor,
  selectedVendor,
  updateVendorHandler,
}) {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 overflow-auto bg-black bg-opacity-50 ">
      <div className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto ">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-5xl w-full mx-auto ">
          <div className="flex items-end justify-end">
            <button
              onClick={() => setUpdateVendor(false)}
              className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full "
            >
              <RxCross1 />
            </button>
          </div>
          <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
            {selectedVendor.name}
          </h2>
          <div>
            <Formik
              initialValues={{
                vendor_name: "" || selectedVendor.name,
                vendor_type: "" || selectedVendor.vendor_type,
                vendor_phone_number: "" || selectedVendor.phone_number,
                vendor_email: "" || selectedVendor.email,
                vendor_pan_number: "" || selectedVendor.pan_card_number,
                vendor_ifsc: "" || selectedVendor.ifsc_code,
                vendor_gst_number: "" || selectedVendor.gst_number,
                vendor_gst_per: "" || selectedVendor.gst_percentage,
                vendor_tds: "" || selectedVendor.tds_percentage,
                address: "" || selectedVendor.address,
                bank_account_number: "" || selectedVendor.bank_account_number,
                bank_name: "" || selectedVendor.bank_name,
                bank_branch: "" || selectedVendor.bank_branch,
              }}
              onSubmit={updateVendorHandler}
            >
              {({ setFieldValue }) => (
                <Form>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                    <div>
                      <FormikInput
                        name={"vendor_name"}
                        label={"Vendor name"}
                        placeholder={selectedVendor.name}
                        type={"text"}
                      />
                    </div>
                    <div>
                      <FormikSelect
                        name={"vendor_type"}
                        label={"Vendor type"}
                        selectedOption={"Vendor type"}
                        optionData={vendorType}
                        onChange={(e) =>
                          setFieldValue("vendor_type", e.target.value)
                        }
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"vendor_phone_number"}
                        label={"Vendor contact number"}
                        placeholder={selectedVendor.phone_number}
                        type={"tel"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"vendor_email"}
                        label={"Vendor email"}
                        placeholder={selectedVendor.email}
                        type={"email"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"address"}
                        label={"Vendor Address"}
                        placeholder={selectedVendor.address}
                        type={"text"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"bank_account_number"}
                        label={"Bank account no."}
                        placeholder={"0112345678"}
                        type={"number"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"bank_name"}
                        label={"Bank name"}
                        placeholder={"MoneyZone"}
                        type={"text"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"bank_branch"}
                        label={"Bank branch"}
                        placeholder={"Ahmedabad"}
                        type={"text"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"vendor_ifsc"}
                        label={"Vendor IFSC"}
                        placeholder={selectedVendor.ifsc_code}
                        type={"text"}
                      />
                    </div>

                    <FormikInput
                      name={"vendor_pan_number"}
                      label={"Vendor PAN number"}
                      placeholder={selectedVendor.pan_card_number}
                      type={"text"}
                    />
                    <div>
                      <FormikInput
                        name={"vendor_gst_number"}
                        label={"GST number"}
                        placeholder={selectedVendor.gst_number}
                        type={"text"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"vendor_gst_per"}
                        label={"GST (%)"}
                        placeholder={selectedVendor.gst_percentage}
                        type={"number"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"vendor_tds"}
                        label={"Vendor TDS(%)"}
                        placeholder={selectedVendor.tds_percentage}
                        type={"number"}
                      />
                    </div>
                  </div>
                  <div className="mt-2 sm:mt-4">
                    <Button name={"Update"} type={"submit"} />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VendorUpdateForm;

import { Form, Formik } from "formik";
import React from "react";
import { RxCross1 } from "react-icons/rx";
import * as yup from "yup";
import FormikSelect from "../../../../components/Input/FormikSelect";
import Button from "../../../../components/Button/Button";

function RequestModal({ openModal, selectedUser, packageUpgradeHandler }) {
  const validationSchema = yup.object().shape({
    package: yup.string().required("Select a package"),
  });
  const optionData = [
    { name: "Silver" },
    { name: "Gold" },
    { name: "Platinum" },
  ];

  const handlePackageNameHandler = (value, setFieldValue) => {
    setFieldValue("package", value);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black overflow-auto bg-opacity-50">
      <div className="flex items-center justify-center z-50">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-lg w-full mx-auto">
          <div className="flex items-end justify-end">
            <button
              className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full "
              onClick={openModal}
            >
              <RxCross1 />
            </button>
          </div>
          <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
            Upgrade user package
          </h2>
          <h2 className="text-lg sm:text-xl font-semibold mb-2 sm:mb-4 text-[#020002]">
            {selectedUser.company_name}'s current package :{" "}
            <span className="font-normal text-[#1d5b79]">
              {selectedUser.package.name}
            </span>
          </h2>

          <Formik
            initialValues={{
              package: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) =>
              packageUpgradeHandler(selectedUser.id, values, {
                setSubmitting,
                resetForm,
              })
            }
          >
            {({ setFieldValue }) => (
              <Form>
                <div className="mt-2 sm:mt-4">
                  <FormikSelect
                    selectedOption={"Package"}
                    label={"Package"}
                    name={"package"}
                    optionData={optionData}
                    onChange={(e) =>
                      handlePackageNameHandler(e.target.value, setFieldValue)
                    }
                  />
                </div>
                <div className="mt-2 sm:mt-4">
                  <Button name={"Upgrade user"} type={"submit"} />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default RequestModal;

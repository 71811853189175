import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.minimal.css";
import { injectStyle } from "react-toastify/dist/inject-style";
import { HashRouter } from "react-router-dom";
import axios from "axios";
import SecureLS from "secure-ls";

injectStyle();
const ls = new SecureLS();
axios.defaults.baseURL = "https://api.cinemapro.in";
axios.defaults.headers.common["ngrok-skip-browser-warning"] = true;

axios.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = `Bearer ${ls.get("access_token")}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HashRouter>
    <App />
    <Toaster position="top-center" />
  </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

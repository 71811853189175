import React, { useContext } from "react";
import { RxCross1 } from "react-icons/rx";
import SubRoleContext from "../SubRoleContext";

function ViewArtist({ detail, onClose }) {
  const subRole = useContext(SubRoleContext);

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black overflow-auto bg-opacity-50">
      <div className="flex items-center justify-center z-50">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-sm sm:max-w-6xl w-full mx-auto">
          <div className="flex items-end justify-end">
            <button
              className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full "
              onClick={onClose}
            >
              <RxCross1 />
            </button>
          </div>
          <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
            Artist associated with Scene
          </h2>
          <div className="flex items-center justify-center">
            <div className=" w-full sm:mt-5 mt-5">
              <div className="flex flex-col overflow-x-auto rounded-lg 2xl:shadow-lg lg:shadow-lg sm:shadow-lg sm:max-h-full lg:max-h-full  2xl:max-h-full">
                <div className="sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="overflow-x-auto bg-[#fbffff]">
                      <table className="min-w-full text-left text-sm font-light">
                        <thead className="border-b font-medium bg-[#edf8f8] rounded-s-lg">
                          <tr>
                            <th className="p-4 whitespace-nowrap text-[#020002]">
                              Artist name.
                            </th>
                            <th className="p-4 whitespace-nowrap text-[#020002]">
                              Artist Type.
                            </th>
                            <th className="p-4 whitespace-nowrap text-[#020002]">
                              Gender
                            </th>

                            <th className="p-4 whitespace-nowrap text-[#020002]">
                              Character Name
                            </th>
                            <th className="p-4 whitespace-nowrap text-[#020002]">
                              Costume
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {detail &&
                            detail.map((details) =>
                              details.project_artists.map((data) => (
                                <tr key={details.id}>
                                  <td className="p-4 whitespace-nowrap">
                                    {data.artist_name}
                                  </td>
                                  <td className="p-4 whitespace-nowrap">
                                    {data.artist_type}
                                  </td>
                                  <td className="p-4 whitespace-nowrap">
                                    {details.artist.gender}
                                  </td>
                                  <td className="p-4 whitespace-nowrap">
                                    {data.character_name}
                                  </td>
                                  <td className="p-4 whitespace-nowrap">
                                    {details.artist_scene.costume}
                                  </td>
                                </tr>
                              ))
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewArtist;

import React from "react";
import Navbar from "../LandingPages/Components/Navbar";
import Footer from "../LandingPages/Components/Footer";

function RefundPolicy() {
  const refundPolicy = [
    {
      title: "Overview",
      content:
        "We strive to provide valuable services and transparency. This policy outlines when refunds may be issued.",
    },
    {
      title: "Subscription Cancellations",
      content:
        "You can cancel your subscription anytime through your account settings. Access remains until the current billing cycle ends, and there will be no further charges.",
    },
    {
      title: "Refund Eligibility",
      content:
        "Refunds may be issued for billing errors or for features not functioning as expected, provided you notify us within 7 days of the transaction.",
    },
    {
      title: "Non-Refundable Situations",
      content:
        "Refunds will not be issued for partial use of a subscription period, unsatisfactory experience, or user error.",
    },
    {
      title: "Requesting a Refund",
      content:
        "To request a refund, email us at support@softcodingsolutions.com with your account details and a description of the issue.",
    },
    {
      title: "Contact Us",
      content:
        "For refund questions, please contact us at support@softcodingsolutions.com.",
    },
  ];

  return (
    <div>
      <Navbar />
      <section className="bg-[#1d5b79] py-20 text-white">
        <div className="container mx-auto px-4 md:px-8">
          <div className="max-w-3xl text-center">
            <h1 className="text-4xl font-bold mb-4">Refund & Cancellation Policy</h1>
          </div>
        </div>
      </section>
      <section>
        <div className="container mx-auto py-10 items-center justify-center flex md:px-0 px-5">
          <div className="max-w-3xl text-start">
            {refundPolicy.map((data, index) => (
              <div>
                <h2 className="text-2xl font-bold mb-4">
                  {index + 1}. {data.title}
                </h2>
                <p className="text-lg mb-8">{data.content}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default RefundPolicy;

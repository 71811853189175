import React from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import ReactPaginate from "react-paginate";

function Pagination({ currentPage, totalPages, handlePageChange }) {
  return (
    <div className="flex flex-row">
      {totalPages > 1 && (
        <>
          <ReactPaginate
            pageCount={totalPages}
            onPageChange={handlePageChange}
            containerClassName={"flex items-center space-x-4"}
            previousLabel={<FaChevronLeft />}
            nextLabel={<FaChevronRight />}
            activeClassName={"active"}
            previousClassName={
              "bg-[#1d5b79] text-[#fbffff] w-7 h-7 items-center justify-center p-2 text-xl flex rounded"
            }
            nextClassName={
              "bg-[#1d5b79] text-[#fbffff] w-7 h-7 items-center justify-center p-2 text-xl flex rounded"
            }
            pageClassName={"hidden"}
            pageLinkClassName={"hidden"}
            breakClassName={"hidden"}
            renderOnZeroPageCount={null}
            breakLinkClassName={"px-8 py-1 bg-gray-200 text-gray-600 rounded"}
          />
          <div className="mx-2">
            Page {currentPage + 1} of {totalPages}
          </div>
        </>
      )}
    </div>
  );
}

export default Pagination;

import { TextField } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ErrorMessage } from "formik";
import React from "react";
import dayjs from "dayjs";

function Date({ field, form, ...props }) {
  const currentError = form.errors[field.name];
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <label className="block mb-2 text-sm text-gray-600 ">{props.label}</label>
      <TimePicker
        {...field}
        {...props}
        value={field.value ? dayjs(field.value) : null}
        onChange={(value) => form.setFieldValue(field.name, value)}
        renderInput={(params) => (
          <TextField
            {...params}
            error={Boolean(currentError)}
            helperText={currentError}
          />
        )}
      />
      <ErrorMessage
        name={field.name}
        className="text-red-500 text-sm mt-1"
        component="div"
      />
    </LocalizationProvider>
  );
}

export default Date;

import React from "react";

function File({ label, onChangeFunction, accept, multiple ,readOnly}) {
  return (
    <div>
      <label className="block mb-2 text-sm text-gray-600 ">{label}</label>
      <input
        type={"file"}
        onChange={onChangeFunction}
        accept={accept}
        multiple={multiple}
        disabled={readOnly}
        className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#1d5b79]  focus:ring-[#1d5b79] focus:outline-none focus:ring focus:ring-opacity-40
        file:mr-5 file:p-1
        file:rounded-full file:border-0
        file:text-sm file:font-medium
        file:bg-[#1d5b79] file:text-[#fbffff] hover:cursor-pointer
        "
      />
    </div>
  );
}

export default File;

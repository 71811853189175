import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Button from "../components/Button/Button";
import Input from "../components/Input/Input";
import axios from "axios";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import FormikInput from "../components/Input/FormikInput";
import { MdOutlinePayment } from "react-icons/md";
import { FaArrowRight } from "react-icons/fa6";
import useRazorpay from "react-razorpay";
import Password from "../components/Input/Password";

function Signup() {
  const [secure, setSecure] = useState(true);
  const [secureConfirmPassword, setSecureConfirmPassword] = useState(true);
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Please enter a valid email")
      .email("Invalid email format"),
    first_name: Yup.string()
      .min(2, "First name must have at least 2 characters")
      .required("Please enter a first name"),
    last_name: Yup.string()
      .min(2, "Last name must have at least 2 characters")
      .required("Please enter a last name"),
    company_name: Yup.string()
      .min(3, "Company name must have at least 3 characters")
      .required("Please enter a company name"),
    phone_number: Yup.string()
      .matches(/^[0-9]{10}$/, "Contact number must have exactly 10 digits")
      .required("Please enter Contact Number"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/\d/, "Password must contain at least one number")
      .matches(
        /[@$!%*?&#]/,
        "Password must contain at least one special character"
      )
      .required("Password is required"),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords does not match")
      .required("Please enter confirm password"),
  });

  const [visible, setVisibile] = useState(false);
  const [viewButton, setViewButton] = useState(false);
  const navigate = useNavigate();
  const [Razorpay] = useRazorpay();

  const fetchClientDetails = () => {
    axios
      .get("api/v1/users/send_client_id_secret")
      .then((response) => {
        setClientId(response.data.client_id);
        setClientSecret(response.data.client_secret);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchClientDetails();
  }, []);

  const signupHandler = (value, { setSubmitting, resetForm }) => {
    const loader = toast.loading("Registering...", {
      autoClose: false,
    });
    axios
      .post(`api/v1/users/sign_up?premium_request=false`, {
        user: {
          email: value.email,
          first_name: value.first_name,
          last_name: value.last_name,
          phone_number: value.phone_number,
          company_name: value.company_name,
          password: value.password,
          password_confirmation: value.password_confirmation,
        },
        client_id: clientId,
        client_secret: clientSecret,
      })
      .then((response) => {
        if (response.status === 201) {
          toast.dismiss(loader);
          toast.success("User created successfully 🎉");

          localStorage.setItem("User_id", response.data.user.user_id);
          if (value.package === "Silver") {
            navigate("/Login");
          } else if (value.package === "Gold") {
            setVisibile(true);
            setViewButton(true);
          } else if (value.package === "Platinum") {
            setVisibile(true);
            setViewButton(false);
          } else {
            toast.error("Try Again");
          }
        } else {
          toast.error("Something went wrong! Please try again");
          resetForm();
        }
      })
      .catch((err) => {
        if (err) {
          toast.error("Something went wrong! Please try again");
          toast.dismiss(loader);
        }
        console.log(err);
      });
    setSubmitting(false);
  };

  const ContactSales = (Package) => {
    const getUserId = localStorage.getItem("User_id");
    axios
      .post(`api/v1/packages/contact_sales`, {
        package_name: Package,
        user_id: getUserId,
      })
      .then((response) => {
        if (response.status === 200) {
          toast.info(response.data.message);
          navigate("/Login");
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const handlePayment = (Package) => {
    const userId = localStorage.getItem("User_id");
    axios
      .post(`api/v1/payments`, {
        payment: {
          user_id: userId,
          package_name: Package,
        },
      })
      .then((response) => {
        if (response.data.data.payment.status_type === "pending") {
          const options = {
            key: "rzp_test_PFzBSO4bIxTNTc",
            currency: "INR",
            order_id: response.data.data.payment.razorpay_order_id,
            handler: (response) => {
              axios
                .post(`api/v1/payments/callback`, {
                  order_id: response.razorpay_order_id,
                  payment_id: response.razorpay_payment_id,
                })
                .then((response) => {
                  if (response.status === 200) {
                    toast.success(
                      "Payment done successfully!! Redirecting to Login page"
                    );
                    navigate("/Login");
                    localStorage.clear();
                  }
                })
                .catch((error) => {
                  console.log(error.message);
                });
            },
            theme: {
              color: "#1d5b79",
            },
          };
          const razorpay = new Razorpay(options);
          razorpay.open();
          razorpay.on("Payment Success", (res) => {});
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (
    <>
      <section className="bg-[#fbffff]  ">
        <div className="flex justify-center min-h-screen">
          <div
            className="hidden bg-cover lg:block lg:w-3/5"
            style={{
              backgroundImage: `url('https://images.unsplash.com/photo-1577190651915-bf62d54d5b36?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')`,
            }}
          ></div>

          <div className="flex items-center w-full max-w-3xl p-8 mx-auto lg:px-12 lg:w-3/5">
            {!visible ? (
              <div className="w-full">
                <h2 className="text-4xl font-bold  tracking-widest text-[#1d5b79] ">
                  CinemaPro
                </h2>
                <h1 className="text-2xl font-semibold tracking-wider text-[#1d5b79] capitalize ">
                  Create Your Account.🎉
                </h1>
                <Formik
                  initialValues={{
                    email: "",
                    first_name: "",
                    last_name: "",
                    company_name: "",
                    phone_number: "",
                    password: "",
                    password_confirmation: "",
                    package: "Silver",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={signupHandler}
                >
                  {({ values }) => (
                    <Form>
                      <div className="grid grid-cols-1 gap-6 mt-8 md:grid-cols-2">
                        <div>
                          <FormikInput
                            label={"First name"}
                            type={"text"}
                            name={"first_name"}
                            placeholder={"John"}
                          />
                        </div>
                        <div>
                          <FormikInput
                            label={"Last name"}
                            type={"text"}
                            name={"last_name"}
                            placeholder={"Smith"}
                          />
                        </div>
                        <div>
                          <FormikInput
                            label={"Production house name"}
                            type={"text"}
                            name={"company_name"}
                            placeholder={"T-series"}
                          />
                        </div>
                        <div>
                          <FormikInput
                            label={"Contact number"}
                            type={"tel"}
                            name={"phone_number"}
                            placeholder={"XXX-XX-XXXX-XXX"}
                          />
                        </div>

                        <div>
                          <FormikInput
                            label={"Email"}
                            type={"email"}
                            name={"email"}
                            placeholder={"johnsmith@example.com"}
                          />
                        </div>
                        <div>
                          <Password
                            label={"Password"}
                            name={"password"}
                            setSecure={setSecure}
                            secure={secure}
                            placeholder={"******"}
                          />
                        </div>
                        <div>
                          <Password
                            label={"Confirm password"}
                            setSecure={setSecureConfirmPassword}
                            secure={secureConfirmPassword}
                            name={"password_confirmation"}
                            placeholder={"******"}
                          />
                        </div>
                      </div>
                      <div className="mt-2">
                        <label className="block mb-2 text-sm text-gray-600 ">
                          Package
                        </label>
                        <div className="grid grid-cols-1  md:grid-cols-3">
                          <div className="flex">
                            <label className="block mb-2 text-lg text-gray-600 mt-1 mr-2">
                              Silver
                            </label>
                            <Field
                              type={"radio"}
                              name={"package"}
                              value={"Silver"}
                            />
                          </div>
                          <div className="flex">
                            <label className="block mb-2 text-lg text-gray-600 mt-1 mr-2">
                              Gold
                            </label>
                            <Field
                              type={"radio"}
                              name={"package"}
                              value={"Gold"}
                            />
                          </div>
                          <div className="flex">
                            <label className="block mb-2 text-lg text-gray-600 mt-1 mr-2">
                              Platinum
                            </label>
                            <Field
                              type={"radio"}
                              name={"package"}
                              value={"Platinum"}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="flex justify-center mt-5">
                        <Button
                          name={
                            values.package === "Silver" ? "Sign Up" : "Pay Now"
                          }
                          type={"submit"}
                        ></Button>
                      </div>
                    </Form>
                  )}
                </Formik>
                <p className="mt-6 text-sm text-center text-gray-400">
                  Already have an account?
                  <Link
                    to="/Login"
                    className="text-[#1d5b79] focus:outline-none focus:underline hover:underline"
                  >
                    Log in
                  </Link>
                  .
                </p>
              </div>
            ) : viewButton ? (
              <div className="w-full">
                <h2 className="text-4xl font-bold  tracking-widest text-[#1d5b79] ">
                  CinemaPro
                </h2>
                <div className="flex mt-16">
                  <button
                    onClick={() => handlePayment("Gold")}
                    className="inline-flex items-center px-8 py-3 text-white transition bg-[#1d5b79] rounded-full shadow-lg focus:outline-none   hover:bg-[#a9c8e2]"
                  >
                    <span className="text-sm font-medium mr-2">Pay</span>
                    <MdOutlinePayment />
                  </button>
                  <button
                    onClick={() => ContactSales("Gold")}
                    className="ml-5 inline-flex items-center px-8 py-3 text-white transition bg-[#1d5b79] rounded-full shadow-lg focus:outline-none   hover:bg-[#a9c8e2]"
                  >
                    <span className="text-sm font-medium mr-2">
                      Contact Sales
                    </span>
                    <FaArrowRight />
                  </button>
                </div>
              </div>
            ) : (
              <div className="w-full">
                <h2 className="text-4xl font-bold  tracking-widest text-[#1d5b79] ">
                  CinemaPro
                </h2>
                <div className="flex mt-16">
                  <button
                    onClick={() => handlePayment("Platinum")}
                    className="inline-flex items-center px-8 py-3 text-white transition bg-[#1d5b79] rounded-full shadow-lg focus:outline-none   hover:bg-[#a9c8e2]"
                  >
                    <span className="text-sm font-medium mr-2">Pay</span>
                    <MdOutlinePayment />
                  </button>
                  <button
                    onClick={() => ContactSales("Platinum")}
                    className="ml-5 inline-flex items-center px-8 py-3 text-white transition bg-[#1d5b79] rounded-full shadow-lg focus:outline-none   hover:bg-[#a9c8e2]"
                  >
                    <span className="text-sm font-medium mr-2">
                      Contact Sales
                    </span>
                    <FaArrowRight />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default Signup;

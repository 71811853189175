import axios from "axios";
import { Field, Formik, Form, FieldArray, ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import toast from "react-hot-toast";
import FormikInput from "../Input/FormikInput";
import Button from "../Button/Button";
import { useParams } from "react-router-dom";

function ShotDivisionUpdateForm({
  fetchShotDetails,
  updateview,
  setUpdateView,
  selectedShotDivision,
}) {
  const [Shotangle, setShotangle] = useState([]);
  const [Shotfocus, setShotfocus] = useState([]);
  const [Shottype, setShottype] = useState([]);
  const [Shotframe, setShotframe] = useState([]);
  const [Shotmechanism, setShotmechanism] = useState([]);
  const [Shotmovements, setShotmovements] = useState([]);
  const { projectId } = useParams();

  const getShotDivisionData = () => {
    axios
      .get(`api/v1/shots`)
      .then((response) => {
        const angles = response.data
          .map((e) => {
            return e?.add_shot_angle;
          })
          .flat();
        setShotangle(angles);
        const focuses = response.data
          .map((e) => {
            return e?.add_shot_focus;
          })
          .flat();
        setShotfocus(focuses);
        const types = response.data
          .map((e) => {
            return e?.add_shot_type;
          })
          .flat();

        setShottype(types);
        const frames = response.data
          .map((e) => {
            return e?.add_shot_frame;
          })
          .flat();
        setShotframe(frames);
        const mechanisms = response.data
          .map((e) => {
            return e?.add_shot_mechanism;
          })
          .flat();
        setShotmechanism(mechanisms);
        const movements = response.data
          .map((e) => {
            return e?.add_shot_movements;
          })
          .flat();
        setShotmovements(movements);
      })
      .catch((error) => console.log(error.message));
  };
  useEffect(() => {
    getShotDivisionData();
  }, []);
  const handleBackdropClick = () => {
    setUpdateView(!updateview);
  };
  const UpdateShotDivision = (values, { resetForm }) => {
    const loader = toast.loading("Updating Shot", {
      autoClose: false,
    });

    axios
      .put(
        `api/v1/projects/${projectId}/shot_details/${selectedShotDivision.id}`,
        {
          shot_detail: {
            shot_number: `${values.shot_details.details[0].shotnumber}`,
            shot_type: `${values.shot_details.details[0].shot_type}`,
            shot_focus: `${values.shot_details.details[0].shot_focus}`,
            shot_angle: `${values.shot_details.details[0].shot_angle}`,
            shot_frame: `${values.shot_details.details[0].shot_frame}`,
            shot_mechanism: `${values.shot_details.details[0].shot_mechanism}`,
            shot_movements: `${values.shot_details.details[0].shot_movements}`,
            remark: `${
              values.shot_details.details[0].remark
                ? values.shot_details.details[0]?.remark
                : ""
            }`,
            brief: `${
              values.shot_details.details[0]?.brief
                ? values.shot_details.details[0]?.brief
                : ""
            }`,
            scene_id: parseInt(values.scene_number),
          },
        }
      )
      .then((res) => {
        toast.success("Updated successfully...");
        toast.dismiss(loader);
        fetchShotDetails();
        resetForm();
        setUpdateView(!updateview);
      })
      .catch((err) => {
        toast.dismiss(loader);
        toast.error(err.response.data.message || err.message || err.response.data.error);
        console.log(err);
      });
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 overflow-auto no-scrollbar bg-black bg-opacity-50">
      <div className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto ">
        <div className="flex-col bg-white p-4 sm:p-8 rounded shadow-md max-w-6xl sm:max-w-6xl w-full sm:my-auto my-auto ">
          <div className="flex items-end justify-end">
            <button
              className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full "
              onClick={handleBackdropClick}
            >
              <RxCross1 />
            </button>
          </div>
          <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
            Update Shot Divison
          </h2>
          <Formik
            initialValues={{
              scene_number:
                selectedShotDivision.scene.scene_number +
                selectedShotDivision.scene.sub_scene,

              shot_details: {
                details: Array.from({ length: 1 }, () => ({
                  shotnumber: selectedShotDivision.shot_number || "",
                  shot_type: selectedShotDivision.shot_type || "",
                  shot_focus: selectedShotDivision.shot_focus || "",
                  shot_angle: selectedShotDivision.shot_angle || "",
                  shot_frame: selectedShotDivision.shot_frame || "",
                  shot_mechanism: selectedShotDivision.shot_mechanism || "",
                  shot_movements: selectedShotDivision.shot_movements || "",
                  remark: selectedShotDivision.remark || "",
                  brief: selectedShotDivision.brief || "",
                })),
              },
            }}
            onSubmit={UpdateShotDivision}
          >
            {(formik) => (
              <Form>
                <div className="grid grid-cols-1 sm:grid-cols-4 gap-2 sm:gap-4 mt-2 sm:mt-4">
                  <div>
                    <FormikInput
                      name={"scene_number"}
                      label={"Selected scene"}
                      readOnly={true}
                      type={"text"}
                      value={formik.values.scene_number}
                    />
                  </div>
                </div>
                <div className="grid-cols-1 sm:grid-cols-4 gap-2 sm:gap-4 mt-2 sm:mt-4">
                  <FieldArray name="shot_details.details">
                    {({ push }) => (
                      <div>
                        {formik.values?.shot_details?.details.map(
                          (detail, index) => (
                            <div
                              key={index}
                              className="grid grid-cols-1 sm:grid-cols-4 gap-2 sm:gap-4 mt-2 sm:mt-4 "
                            >
                              <div>
                                <FormikInput
                                  name={`shot_details.details[0].shotnumber`}
                                  readOnly={true}
                                  type={"text"}
                                  label={"Shot number"}
                                />
                              </div>
                              <div>
                                <label className="block mb-2 text-sm text-gray-600 ">
                                  {"Shot type"}
                                </label>
                                <Field
                                  name={`shot_details.details[0].shot_type`}
                                  as="select"
                                  className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#1d5b79]  focus:ring-[#1d5b79] focus:outline-none focus:ring focus:ring-opacity-40"
                                >
                                  <option selected>Shot type</option>
                                  {Shottype.sort().map((types, index) => (
                                    <option key={index}>{types}</option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  name={`shot_details.details.shot_type`}
                                  component={"div"}
                                  className="text-red-600 text-sm"
                                />
                              </div>
                              <div>
                                <label className="block  mb-2 text-sm text-gray-600 ">
                                  {"Shot focus"}
                                </label>
                                <Field
                                  name={`shot_details.details[0].shot_focus`}
                                  as="select"
                                  className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#1d5b79]  focus:ring-[#1d5b79] focus:outline-none focus:ring focus:ring-opacity-40"
                                >
                                  <option selected>Shot focus</option>
                                  {Shotfocus.sort().map((focus, index) => (
                                    <option key={index}>{focus}</option>
                                  ))}
                                </Field>
                              </div>
                              <div>
                                <label className="block mb-2 text-sm text-gray-600 ">
                                  {"Shot angle"}
                                </label>
                                <Field
                                  name={`shot_details.details[0].shot_angle`}
                                  as="select"
                                  className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#1d5b79]  focus:ring-[#1d5b79] focus:outline-none focus:ring focus:ring-opacity-40"
                                >
                                  <option selected>Shot angle</option>
                                  {Shotangle.sort().map((angles, index) => (
                                    <option key={index}>{angles}</option>
                                  ))}
                                </Field>
                              </div>
                              <div>
                                <label className="block mb-2 text-sm text-gray-600 ">
                                  {"Shot Frame"}
                                </label>
                                <Field
                                  name={`shot_details.details[0].shot_frame`}
                                  as="select"
                                  className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#1d5b79]  focus:ring-[#1d5b79] focus:outline-none focus:ring focus:ring-opacity-40"
                                >
                                  <option selected>Shot frame</option>
                                  {Shotframe.sort().map((frames, index) => (
                                    <option key={index}>{frames}</option>
                                  ))}
                                </Field>
                              </div>
                              <div>
                                <label className="block mb-2 text-sm text-gray-600 ">
                                  {"Shot mechanism"}
                                </label>
                                <Field
                                  name={`shot_details.details[0].shot_mechanism`}
                                  as="select"
                                  className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#1d5b79]  focus:ring-[#1d5b79] focus:outline-none focus:ring focus:ring-opacity-40"
                                >
                                  <option selected>Shot mechanism</option>
                                  {Shotmechanism.sort().map(
                                    (mechanisms, index) => (
                                      <option key={index}>{mechanisms}</option>
                                    )
                                  )}
                                </Field>
                              </div>
                              <div>
                                <label className="block mb-2 text-sm text-gray-600 ">
                                  {"Shot movements"}
                                </label>
                                <Field
                                  name={`shot_details.details[0].shot_movements`}
                                  as="select"
                                  className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#1d5b79]  focus:ring-[#1d5b79] focus:outline-none focus:ring focus:ring-opacity-40"
                                >
                                  <option selected>Shot movement</option>
                                  {Shotmovements.sort().map(
                                    (movements, index) => (
                                      <option key={index}>{movements}</option>
                                    )
                                  )}
                                </Field>
                              </div>
                              <div>
                                <FormikInput
                                  name={`shot_details.details[0].remark`}
                                  placeholder={
                                    formik.values.shot_details.details[0].remark
                                      ? formik.values.shot_details.details[0]
                                          .remark
                                      : "Remark"
                                  }
                                  type={"text"}
                                  label={"Remark"}
                                />
                              </div>
                              <div>
                                <FormikInput
                                  name={`shot_details.details[0].brief`}
                                  placeholder={
                                    formik.values.shot_details.details[0].brief
                                      ? formik.values.shot_details.details[0]
                                          .brief
                                      : "Brief"
                                  }
                                  type={"text"}
                                  label={"Brief"}
                                />
                              </div>
                              <div className="ml-3 mt-8">
                                <Button
                                  key={index}
                                  type={"submit"}
                                  name={"Update"}
                                />
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </FieldArray>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default ShotDivisionUpdateForm;

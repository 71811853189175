import React from "react";

function DownloadButton({ type, name, onClick }) {
  return (
    <div>
      <button
        type={type}
        onClick={onClick}
        className="div px-2 py-1 text-gray-800 font-medium sm:font-semibold m-auto relative group bg-gray-300 border-b-2 border-[#1d5b79] hover:text-white z-0 overflow-hidden"
      >
        {name}
        <div className="h-full w-full bg-[#1d5b79] absolute left-0 bottom-full z-[-1] group-hover:translate-y-full duration-[200ms]" />
      </button>
    </div>
  );
}

export default DownloadButton;

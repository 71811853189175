import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { BsFillCalendarDateFill } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import TimelineForm from "../../components/Forms/TimelineForm";
import Button from "../../components/Button/Button";
import * as Yup from "yup";
import toast from "react-hot-toast";
import SubRoleContext from "../../SubRoleContext";
import Loader from "../../Loader/loader";
import ErrorPage from "../ErrorPage";

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);
function Timeline() {
  const validationSchema = Yup.object().shape({
    start: Yup.string().required("Select start date"),
    end: Yup.string().required("Select end date"),
    title: Yup.string()
      .min(3, "Title must have atleast 3 characters")
      .required("Enter title"),
  });
  const subRole = useContext(SubRoleContext);
  const [view, setView] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [eventData, setEventData] = useState("");
  const onView = useCallback((newView) => setView(newView), [setView]);

  const { projectId } = useParams();

  const viewHandler = () => {
    setView(false);
  };

  const onClose = () => {
    setView(true);
  };
  const stopClose = (e) => {
    e.stopPropagation();
  };

  const handleBackdropClick = () => {
    onClose();
  };

  const addEvent = (value, { setSubmitting, resetForm }) => {
    const loaderToast = toast.loading("Adding Event...", {
      autoClose: false,
    });
    axios
      .post(`api/v1/projects/${projectId}/calendar_events`, {
        calendar_event: {
          title: value.title,
          start: value.start,
          end: value.end,
        },
      })
      .then((resp) => {
        console.log(resp);
        handleBackdropClick();
        if (resp.status === 201) {
          toast.dismiss(loaderToast);
          toast.success("Event added successfully!!!!");
          getEvent();
          resetForm();
        }
      })
      .catch((err) => {
        if (err) {
          toast.dismiss(loaderToast);
          toast.error(
            err.response.data.message || err.message || err.response.data.error
          );
        }
      });
    setSubmitting(false);
  };

  const getEvent = () => {
    axios
      .get(`api/v1/projects/${projectId}/calendar_events`)
      .then((res) => {
        const eventsFromAPI = res.data.calendar_events.map((event) => ({
          start: new Date(event.start),
          end: new Date(event.end),
          title: event.title,
          color: event.color,
        }));
        setLoading(true);
        setEventData(eventsFromAPI);
      })
      .catch((err) => {
        setError(true);
        console.log(err);
      });
  };

  useEffect(() => {
    getEvent();
  }, []);

  const eventStyleGetter = (event) => {
    return {
      style: {
        backgroundColor: event.color,
        fontSize: "15px",
        borderRadius: "15px",
        fontWeight: "bold",
        border: "none",
      },
    };
  };

  return (
    <div>
      {error ? (
        <ErrorPage />
      ) : (
        <div>
          {!loading ? (
            <Loader />
          ) : (
            <div className="flex flex-col sm:flex sm:flex-col p-5">
              <div className="flex sm:items-start items-center justify-center sm:justify-start xl:ml-20 2xl:ml-20 sm:ml-20 ml-0 sm:mt-0 mt-5">
                {subRole === "user" ? (
                  <div></div>
                ) : (
                  <Button clickFunction={viewHandler} name={"Add Event"} />
                )}
              </div>
              <div className="flex items-center justify-center xl:ml-20 2xl:ml-20 lg:ml-20 md:ml-20 sm:ml-20 ml-0">
                <div className="sm:mt-5 mt-5  bg-[#fbffff] p-2 shadow-lg rounded-lg">
                  <div className="calendar-container">
                    <DnDCalendar
                      localizer={localizer}
                      events={eventData}
                      startAccessor="start"
                      endAccessor="end"
                      style={{ height: 602 }}
                      showMultiDayTimes
                      eventPropGetter={eventStyleGetter}
                      draggableAccessor={(event) => true}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {!view && (
            <TimelineForm
              validationSchema={validationSchema}
              handleBackdropClick={handleBackdropClick}
              stopClose={stopClose}
              addEvent={addEvent}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default Timeline;

import React, { useState, useEffect, useContext } from "react";
import Sidebar from "./superAdminComponent/Sidebar";
import axios from "axios";
import CardViewForShot from "./superAdminComponent/CardView/CardViewForShot";
import { SidebarContext } from "./SidebarContext";

function ShotDivision() {
  const { view } = useContext(SidebarContext);
  const [ShotDivisiondata, setShotDivisiondata] = useState([]);
  const [Shotangle, setShotangle] = useState([]);
  const [Shotfocus, setShotfocus] = useState([]);
  const [Shottype, setShottype] = useState([]);
  const [Shotframe, setShotframe] = useState([]);
  const [Shotmechanism, setShotmechanism] = useState([]);
  const [Shotmovements, setShotmovements] = useState([]);
  const getShotDivisionData = () => {
    axios
      .get(`api/v1/shots`)
      .then((response) => {
        console.log(response.data);
        setShotDivisiondata(response?.data);
        const angles = response.data
          .map((e) => {
            return e?.add_shot_angle;
          })
          .flat();
        setShotangle(angles);
        const focuses = response.data
          .map((e) => {
            return e?.add_shot_focus;
          })
          .flat();
        setShotfocus(focuses);
        const types = response.data
          .map((e) => {
            return e?.add_shot_type;
          })
          .flat();
        setShottype(types);
        const frames = response.data
          .map((e) => {
            return e?.add_shot_frame;
          })
          .flat();
        setShotframe(frames);
        const mechanisms = response.data
          .map((e) => {
            return e?.add_shot_mechanism;
          })
          .flat();
        setShotmechanism(mechanisms);
        const movements = response.data
          .map((e) => {
            return e?.add_shot_movements;
          })
          .flat();
        setShotmovements(movements);
      })
      .catch((error) => console.log(error.message));
  };
  useEffect(() => {
    getShotDivisionData();
  }, []);

  return (
    <div>
      {" "}
      <Sidebar />
      <div
        className={`p-7 flex-1 h-screen ${
          !view ? "ml-36 duration-100 ease-in" : "ml-0 duration-100 ease-in"
        }`}
      >
        <div className="grid grid-cols-1 xl:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 mt-14 sm:ml-32 ml-0 gap-2 md:gap-4">
          <CardViewForShot
            CardName={"Shot angle"}
            ShotDivisiondata={ShotDivisiondata}
            Shotangle={Shotangle}
            Shotfocus={Shotfocus}
            Shottype={Shottype}
            Shotframe={Shotframe}
            Shotmechanism={Shotmechanism}
            Shotmovements={Shotmovements}
            getShotDivisionData={getShotDivisionData}
          />
          <CardViewForShot
            CardName={"Shot focus"}
            ShotDivisiondata={ShotDivisiondata}
            Shotangle={Shotangle}
            Shotfocus={Shotfocus}
            Shottype={Shottype}
            Shotframe={Shotframe}
            Shotmechanism={Shotmechanism}
            Shotmovements={Shotmovements}
            getShotDivisionData={getShotDivisionData}
          />
          <CardViewForShot
            CardName={"Shot type"}
            ShotDivisiondata={ShotDivisiondata}
            Shotangle={Shotangle}
            Shotfocus={Shotfocus}
            Shottype={Shottype}
            Shotframe={Shotframe}
            Shotmechanism={Shotmechanism}
            Shotmovements={Shotmovements}
            getShotDivisionData={getShotDivisionData}
          />
          <CardViewForShot
            CardName={"Shot frame"}
            ShotDivisiondata={ShotDivisiondata}
            Shotangle={Shotangle}
            Shotfocus={Shotfocus}
            Shottype={Shottype}
            Shotframe={Shotframe}
            Shotmechanism={Shotmechanism}
            Shotmovements={Shotmovements}
            getShotDivisionData={getShotDivisionData}
          />
          <CardViewForShot
            CardName={"Shot mechanism"}
            ShotDivisiondata={ShotDivisiondata}
            Shotangle={Shotangle}
            Shotfocus={Shotfocus}
            Shottype={Shottype}
            Shotframe={Shotframe}
            Shotmechanism={Shotmechanism}
            Shotmovements={Shotmovements}
            getShotDivisionData={getShotDivisionData}
          />
          <CardViewForShot
            CardName={"Shot movements"}
            ShotDivisiondata={ShotDivisiondata}
            Shotangle={Shotangle}
            Shotfocus={Shotfocus}
            Shottype={Shottype}
            Shotframe={Shotframe}
            Shotmechanism={Shotmechanism}
            Shotmovements={Shotmovements}
            getShotDivisionData={getShotDivisionData}
          />
        </div>
      </div>
    </div>
  );
}

export default ShotDivision;

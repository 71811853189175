import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { IoMenuOutline } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";
import Button from "../../../components/Button/Button";
import Modak from "../../../assets/image/Modak.png";

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrollTo, setScrollTo] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleNavigation = (section) => {
    if (location.pathname !== "/") {
      setScrollTo(section);
      navigate("/");
    } else {
      scrollToSection(section);
    }
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (scrollTo && location.pathname === "/") {
      scrollToSection(scrollTo);
      setScrollTo(null);
    }
  }, [scrollTo, location.pathname]);

  return (
    <div className="bg-[#1d5b79] text-white">
      <header className="container mx-auto py-2 flex justify-between items-center">
        <Link to="/">
          <img
            src={Modak}
            alt="logo"
            className="h-10 w-10 cursor-pointer m-2"
          />
        </Link>
        {window.innerWidth < 768 ? (
          <div className="md:hidden absolute right-2">
            <button
              className="text-[#a9c8e2] outline-none text-4xl font-bold"
              onClick={toggleMenu}
            >
              <IoMenuOutline />
            </button>
          </div>
        ) : (
          <>
            <ul className="flex gap-6">
              <p
                onClick={() => navigate("/Features")}
                className="hover:underline duration-300 ease-out cursor-pointer"
              >
                Features
              </p>
              <h1
                onClick={() => handleNavigation("Pricing")}
                className="hover:underline duration-300 ease-out cursor-pointer"
              >
                Pricing
              </h1>
              <h1
                onClick={() => handleNavigation("WhyUs")}
                className="hover:underline duration-300 ease-out cursor-pointer"
              >
                Why Us
              </h1>
              <p
                onClick={() => navigate("/ContactUs")}
                className="hover:underline duration-300 ease-out cursor-pointer"
              >
                Contact Us
              </p>
            </ul>
            <Button name={"Log in"} clickFunction={() => navigate("/Login")} />
          </>
        )}
      </header>
      {menuOpen && (
        <div className="md:hidden fixed top-0 left-0 w-full h-1/2 bg-[#a9c8e2] shadow-lg z-50 origin-top scale-100 ease-out duration-300 ">
          <button
            onClick={toggleMenu}
            className="fixed top-5 right-5 text-[#a9c8e2] bg-[#1d5b79] text-xl font-bold p-4 rounded-full"
          >
            <RxCross1 />
          </button>
          <ul className="flex flex-col items-center justify-center h-full">
            <Link className="text-[#1d5b79] outline-none text-xl font-bold mb-4">
              About
            </Link>
            <Link className="text-[#1d5b79] outline-none text-xl font-bold">
              CinemaPro Features
            </Link>
            <div className="mt-5">
              <Button
                name={"Log in"}
                clickFunction={() => navigate("/Login")}
              />
            </div>
          </ul>
        </div>
      )}
    </div>
  );
}

export default Navbar;

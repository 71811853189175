import React from "react";

function Select({
  label,
  value,
  onChangeFunction,
  optionData,
  selectedOption,
  propertyLabel = "name",
  propertyValue = "name",
}) {
  return (
    <div>
      <label className="block mb-2 text-sm text-gray-600 ">{label}</label>
      <select
        value={value}
        onChange={onChangeFunction}
        className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#1d5b79]  focus:ring-[#1d5b79] focus:outline-none focus:ring focus:ring-opacity-40"
      >
        <option selected value="" disabled>
          {selectedOption}
        </option>
        {optionData.map((option, index) => (
          <option key={index} value={option[propertyValue]}>
            {option[propertyLabel]}
          </option>
        ))}
      </select>
    </div>
  );
}

export default Select;

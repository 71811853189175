import React, { useState } from "react";
import Button from "../Button/Button";
import { FieldArray, Form, Formik } from "formik";
import FormikInput from "../Input/FormikInput";
import FormikSelect from "../Input/FormikSelect";
import File from "../Input/File";
import Select from "react-select";
import { FaTrash } from "react-icons/fa";

function ProjectForm({
  validationSchema,
  handleBackdropClick,
  stopClose,
  handleProject,
  setImage,
  partners,
}) {
  const [selectedPartners, setSelectedPartners] = useState([]);

  const Options = [
    { id: 1, name: "Movie" },
    { id: 2, name: "WebSeries" },
    { id: 3, name: "Short Film" },
    { id: 4, name: "Documentary" },
  ];

  const handleCategoryChange = (value, setFieldValue) => {
    setFieldValue("cinema_type", value || "");
  };

  const handlePartnerChange = (selectedOptions, push, remove, values) => {
    setSelectedPartners(selectedOptions);

    const selectedPartnerIds = selectedOptions.map((option) => option.id);

    selectedPartnerIds.forEach((partnerId) => {
      const partnerAlreadyAdded = values.partner_attributes.some(
        (partner) => partner.partner_id === partnerId
      );

      if (!partnerAlreadyAdded) {
        const partner = selectedOptions.find(
          (option) => option.id === partnerId
        );
        push({
          partner_id: partner.id,
          partner_name: partner.name,
          amount: "",
          percentage: "",
        });
      }
    });

    values.partner_attributes.forEach((partner, index) => {
      if (!selectedPartnerIds.includes(partner.partner_id)) {
        remove(index);
      }
    });
  };

  const handleRemovePartner = (index, remove, values) => {
    remove(index);

    const updatedSelectedPartners = selectedPartners.filter(
      (partner) => partner.id !== values.partner_attributes[index].partner_id
    );
    setSelectedPartners(updatedSelectedPartners);
  };

  return (
    <div>
      <div
        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 overflow-y-auto my-auto"
        onClick={handleBackdropClick}
      >
        <div
          className="flex items-center justify-center z-50 my-auto"
          onClick={stopClose}
        >
          <div className="bg-white p-8 rounded shadow-md max-w-lg w-full mx-auto">
            <h2 className="text-2xl font-semibold mb-4 text-[#1d5b79] ">
              Create Project
            </h2>
            <div>
              <Formik
                initialValues={{
                  project_name: "",
                  cinema_type: "",
                  partner_attributes: [],
                  image: "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleProject}
              >
                {({ setFieldValue, values }) => (
                  <Form>
                    <div className="grid sm:grid-cols-2 grid-cols-1 gap-4">
                      <div>
                        <FormikInput
                          name={"project_name"}
                          type={"text"}
                          label={"Project Name"}
                          placeholder={"Sholay"}
                        />
                      </div>
                      <div>
                        <FormikSelect
                          name={"cinema_type"}
                          label={"Category"}
                          optionData={Options}
                          onChange={(e) =>
                            handleCategoryChange(e.target.value, setFieldValue)
                          }
                          selectedOption={"Category"}
                        />
                      </div>
                      <div>
                        <File
                          label={"Poster"}
                          accept={"image/*,.heic"}
                          onChangeFunction={(e) => setImage(e.target.files[0])}
                        />
                      </div>
                      <FieldArray name="partner_attributes">
                        {({ push, remove }) => (
                          <div>
                            <label className="block mb-2 text-sm text-gray-600 ">
                              Partners
                            </label>
                            <Select
                              name="partner_attributes"
                              options={partners}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              isMulti
                              isClearable={false}
                              value={selectedPartners}
                              onChange={(selectedOption) =>
                                handlePartnerChange(
                                  selectedOption,
                                  push,
                                  remove,
                                  values
                                )
                              }
                            />
                          </div>
                        )}
                      </FieldArray>
                    </div>
                    <FieldArray name="partner_attributes">
                      {({ remove }) => (
                        <div className="grid grid-cols-1 gap-4 mt-4">
                          {values.partner_attributes.map((data, index) => (
                            <div
                              key={index}
                              className="flex flex-col items-start justify-start w-full"
                            >
                              <div className="flex justify-between w-full">
                                <h2 className="mt-2">{data.partner_name}</h2>
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleRemovePartner(index, remove, values)
                                  }
                                  className="bg-red-100 text-red-500 p-2 rounded-full outline-none"
                                >
                                  <FaTrash />
                                </button>
                              </div>
                              <div className="grid grid-cols-2 gap-4">
                                <div>
                                  <FormikInput
                                    name={`partner_attributes[${index}].amount`}
                                    type={"number"}
                                    placeholder={"Invested Amount"}
                                  />
                                </div>
                                <div>
                                  <FormikInput
                                    name={`partner_attributes[${index}].percentage`}
                                    type={"number"}
                                    placeholder={"Percentage"}
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </FieldArray>
                    <div className="mt-4">
                      <Button name={"Create"} type={"submit"} />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectForm;

import React, { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const HiddenContext = createContext();

export const HiddenProvider = ({ children }) => {
  const location = useLocation();
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    if (location.pathname.includes("Accounts")) {
      setHidden(true);
    } else {
      setHidden(false);
    }
  }, []);

  return (
    <HiddenContext.Provider value={{ hidden: hidden }}>
      {children}
    </HiddenContext.Provider>
  );
};

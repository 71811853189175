import React from "react";
import Button from "../Button/Button";
import { Form, Formik } from "formik";
import FormikInput from "../Input/FormikInput";
import * as Yup from "yup";

function OTPform({

  verifyOTP,
}) {
  const otpValidationSchema = Yup.object().shape({
    otp: Yup.number().required("Enter OTP you've received in email"),
  });
  return (
    <div>
      <div className="flex flex-row items-center justify-center">
        <Formik
          initialValues={{
            otp: "",
          }}
          validationSchema={otpValidationSchema}
          onSubmit={verifyOTP}
        >
          <Form>
            <FormikInput
              length={4}
              name={"otp"}
              placeholder={"XXXX"}
              label={"OTP"}
              type={"text"}
            />
            <div className="mt-6 flex items-center justify-center">
              <Button name={"Verfiy OTP"} type={"submit"} />
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
}

export default OTPform;

import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import FormikInput from "../Input/FormikInput";
import IconButton from "../Button/IconButton";
import { CiLocationArrow1 } from "react-icons/ci";

function EnterSmtp({ onSubmit }) {
  const validationSchema = Yup.object().shape({
    smtp: Yup.string().required("SMTP is required"),
  });

  return (
    <div>
      <Formik
        initialValues={{
          smtp: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <div>
            <FormikInput
              name={"smtp"}
              label={"SMTP token"}
              type={"text"}
              placeholder={"SMTP token"}
            />
          </div>
          <div className="mt-4">
            <IconButton
              name={"Submit SMTP token"}
              icon={<CiLocationArrow1 />}
              type={"submit"}
            />
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default EnterSmtp;

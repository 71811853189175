import React, { useState } from "react";
import usePagination from "../Pagination/usePagination";
import Pagination from "../Pagination/Pagination";

function ExpenseTable({ data, handleViewBill }) {
  const { currentItems, currentPage, handlePageChange, totalPages } =
    usePagination(data, 10);
  return (
    <>
      <div className="flex flex-col h-full">
        <div className="w-full mx-auto bg-white  rounded-sm border border-gray-200">
          <header className="flex sm:flex-row flex-col  justify-between px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold text-gray-800">Expenses</h2>
            <Pagination
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              totalPages={totalPages}
            />
          </header>
          <div className="p-3">
            <div className="overflow-x-auto">
              <table className="table-auto w-full">
                <thead className="text-xs font-semibold uppercase text-gray-600 bg-[#eceff1]">
                  <tr>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Department</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Type</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Description</div>
                    </th>

                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-center">Duration</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-center">Date</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-center">Amount</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-center">Bill</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        No bill reason
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-center">Status</div>
                    </th>
                  </tr>
                </thead>
                <tbody className="text-sm divide-y divide-gray-100">
                  {currentItems.map((expense) => (
                    <tr>
                      <td className="p-2 whitespace-nowrap">
                        {expense.department}
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">{expense.expense_type}</div>
                      </td>
                      <td className="p-2">
                        <div
                          className="text-left"
                          style={{
                            width: "300px",
                            textAlign: "justify",
                          }}
                        >
                          {expense.description}
                        </div>
                      </td>

                      <td className="p-2 whitespace-nowrap">
                        <div className="text-center">{expense.duration}</div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-center">{expense.date}</div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-center">{expense.amount}</div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-center">
                          <button
                            onClick={() =>
                              handleViewBill(expense.document_expense)
                            }
                            target="_blank"
                            className={`hover:text-bold hover:underline hover:text-black duration-200 ease-in ${
                              expense.document_expense === null
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                            }`}
                            disabled={expense.document_expense === null}
                          >
                            View{" "}
                          </button>
                        </div>
                      </td>

                      <td className="p-2">
                        <div
                          className="text-center"
                          style={{
                            width: "300px",
                            textAlign: "justify",
                          }}
                        >
                          {expense.no_bill_reason}
                        </div>
                      </td>
                      {expense.approval_status === "pending" ? (
                        <td className="p-2 whitespace-nowrap text-orange-600">
                          Pending
                        </td>
                      ) : expense.approval_status === "approved" ? (
                        <td className="p-2 whitespace-nowrap text-green-600">
                          Approved
                        </td>
                      ) : expense.approval_status === "rejected" ? (
                        <td className="p-2 whitespace-nowrap text-red-600">
                          Rejected
                        </td>
                      ) : (
                        <td className="p-2 whitespace-nowrap"></td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ExpenseTable;

import { Field, Form, Formik } from "formik";
import React from "react";
import { RxCross1 } from "react-icons/rx";
import Button from "../Button/Button";

function AccessManager({
  setOpen,
  assignedRoleCrew,
  assignAccess,
  revokeAccess,
}) {
  return (
    <div className="fixed inset-0 flex items-center justify-center overflow-auto bg-black bg-opacity-50 z-50">
      <div className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto ">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-5xl w-full mx-auto">
          <div className="flex items-center justify-between">
            <div className="flex items-start justify-start">
              <h2 className="text-lg sm:text-2xl font-semibold  text-[#1d5b79]">
                Access manager
              </h2>
            </div>
            <div className="flex items-end justify-end">
              <button
                className="bg-[#1d5b79] p-2 sm:-mt-6 sm:-mr-4 -mt-3 -mr-2 text-lg text-[#fbffff] rounded-full "
                onClick={() => setOpen(false)}
              >
                <RxCross1 />
              </button>
            </div>
          </div>
          <h2 className="text-base sm:text-lg font-semibold mb-2 sm:mb-4 mt-4 text-[#1d5b79]">
            What access do you want to give to {assignedRoleCrew.full_name}?
          </h2>
          <div>
            <Formik
              initialValues={{
                script: false || assignedRoleCrew.access.script,
                callSheet: false || assignedRoleCrew.access.callsheet,
                expense: false || assignedRoleCrew.access.expense,
                account: false || assignedRoleCrew.access.accounts,
              }}
              onSubmit={assignAccess}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <div className="flex flex-row">
                    <div className="flex flex-row">
                      <Field
                        type="checkbox"
                        checked={values.script}
                        onChange={() => setFieldValue("script", !values.script)}
                        name="script"
                        className="form-checkbox text-custom-color"
                      />
                      <label className="block ml-2 text-base text-gray-600 ">
                        Script
                      </label>
                    </div>
                    <div className="flex flex-row ml-5">
                      <Field
                        type="checkbox"
                        checked={values.callSheet}
                        onChange={() =>
                          setFieldValue("callSheet", !values.callSheet)
                        }
                        name="callSheet"
                      />
                      <label className="block ml-2 text-base text-gray-600 ">
                        Call Sheet
                      </label>
                    </div>
                    <div className="flex flex-row ml-5">
                      <Field
                        type="checkbox"
                        checked={values.expense}
                        onChange={() =>
                          setFieldValue("expense", !values.expense)
                        }
                        name="expense"
                      />
                      <label className="block ml-2 text-base text-gray-600 ">
                        Expense
                      </label>
                    </div>
                    <div className="flex flex-row ml-5">
                      <Field
                        type="checkbox"
                        checked={values.account}
                        onChange={() =>
                          setFieldValue("account", !values.account)
                        }
                        name="account"
                      />
                      <label className="block ml-2 text-base text-gray-600 ">
                        Account
                      </label>
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-start justify-start mt-2 sm:mt-4">
                      <Button name={"Assign"} type={"submit"} />
                    </div>
                    <div className="flex items-end justify-end mt-2 sm:mt-4">
                      <Button
                        name={"Revoke access"}
                        type={"button"}
                        clickFunction={revokeAccess}
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccessManager;

import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { BiSolidTrashAlt } from "react-icons/bi";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";
import usePagination from "../Pagination/usePagination";
import Pagination from "../Pagination/Pagination";

function CatererTable({ caterer, updateView, deleteView }) {
  const [expandedCaterer, setExpandedCaterer] = useState({});

  const toggleView = (id) => {
    setExpandedCaterer((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const itemVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
  };

  const { currentItems, currentPage, handlePageChange, totalPages } =
    usePagination(caterer, 5);
  return (
    <div>
      <div className="flex flex-col h-full">
        <div className="w-full mx-auto bg-white  rounded-sm border border-gray-200">
          <header className="flex sm:flex-row flex-col  justify-between px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold text-gray-800 mr-5">Caterer</h2>
            <Pagination
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              totalPages={totalPages}
            />
          </header>
          <div className="p-3">
            <div className="overflow-x-auto">
              <table className="table-auto w-full">
                <thead className="text-xs font-semibold uppercase text-gray-600 bg-[#eceff1]">
                  <tr>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Caterer name
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Number of days
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        More details
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody className="text-sm divide-y divide-gray-100">
                  {currentItems.map((caterer, index) => (
                    <React.Fragment key={index}>
                      <tr>
                        <td className="p-2 whitespace-nowrap">
                          {caterer.vendor.name}
                        </td>
                        <td className="p-2">
                          <div className="text-left">
                            {caterer.number_of_days}
                          </div>
                        </td>

                        <td className="p-2">
                          <button
                            onClick={() => toggleView(caterer.id)}
                            className="text-xl border-none outline-none text-[#fbffff] bg-[#1d5b79] rounded-full p-1"
                          >
                            {expandedCaterer[caterer.id] ? (
                              <FaChevronUp size={15} />
                            ) : (
                              <FaChevronDown size={15} />
                            )}
                          </button>
                        </td>
                        <td className="p-4 whitespace-nowrap flex">
                          <button
                            onClick={() => updateView(caterer)}
                            className="p-4 text-xl text-green-600 border-none hover:shadow-none rounded-full outline-none bg-green-50  duration-300 ease-in"
                          >
                            <MdModeEdit />
                          </button>
                          <button
                            onClick={() => deleteView(caterer)}
                            className="ml-4 p-4 text-xl text-red-600 bg-red-50 hover:shadow-none rounded-full  duration-300 ease-in outline-none"
                          >
                            <BiSolidTrashAlt />
                          </button>
                        </td>
                      </tr>
                      <AnimatePresence>
                        {expandedCaterer[caterer.id] && (
                          <motion.tr
                            key={caterer.id}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            variants={itemVariants}
                            className="bg-gray-50"
                            transition={{ duration: 0.3, delay: 0.1 }}
                          >
                            <td colSpan={6} className="p-4">
                              {caterer.c_type === "Package" ? (
                                <div>
                                  <div className="flex flex-row">
                                    <h2 className="text-sm font-semibold text-[#1d5b79]">
                                      Package amount:
                                    </h2>
                                    <h2 className="text-sm ml-1">
                                      {caterer.package_amount}
                                    </h2>
                                  </div>
                                </div>
                              ) : (
                                <div className="grid sm:grid-cols-4 grid-cols-1 gap-2 sm:gap-4">
                                  <div className="flex flex-row">
                                    <h2 className="text-sm font-semibold text-[#1d5b79]">
                                      Breakfast amount:
                                    </h2>
                                    <h2 className="text-sm ml-1">
                                      {caterer.breakfast_amount}
                                    </h2>
                                  </div>
                                  <div className="flex flex-row">
                                    <h2 className="text-sm font-semibold text-[#1d5b79]">
                                      Lunch amount:
                                    </h2>
                                    <h2 className="text-sm ml-1">
                                      {caterer.lunch_amount}
                                    </h2>
                                  </div>
                                  <div className="flex flex-row">
                                    <h2 className="text-sm font-semibold text-[#1d5b79]">
                                      Hightea amount:
                                    </h2>
                                    <h2 className="text-sm ml-1">
                                      {caterer.high_tea_amount}
                                    </h2>
                                  </div>
                                  <div className="flex flex-row">
                                    <h2 className="text-sm font-semibold text-[#1d5b79]">
                                      Dinner amount:
                                    </h2>
                                    <h2 className="text-sm ml-1">
                                      {caterer.dinner_amount}
                                    </h2>
                                  </div>
                                  {caterer.extra_snack_amount && (
                                    <div className="flex flex-row">
                                      <h2 className="text-sm font-semibold text-[#1d5b79]">
                                        Extra snack amount:
                                      </h2>
                                      <h2 className="text-sm ml-1">
                                        {caterer.extra_snack_amount}
                                      </h2>
                                    </div>
                                  )}
                                </div>
                              )}
                            </td>
                          </motion.tr>
                        )}
                      </AnimatePresence>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CatererTable;

import React from "react";
import { RxCross1 } from "react-icons/rx";
import { TiTick } from "react-icons/ti";

function PackageFeature({ feature, closeFeature }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="flex items-center justify-center z-50">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-md w-96 mx-auto">
          <div className="flex items-end justify-end">
            <button
              className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full "
              onClick={closeFeature}
            >
              <RxCross1 />
            </button>
          </div>
          <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
            Features
          </h2>
          <div
          >
            <ul>
              {feature.package_details.details.map((detail, index) => (
                <li key={index} className="text-lg m-3 flex">
                  <span className="mt-1 mr-2 text-[#1d5b79]">
                    <TiTick />
                  </span>
                  {detail}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PackageFeature;

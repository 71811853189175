import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Button from "../components/Button/Button";
import { SidebarContext } from "./SuperAdmin/SidebarContext";
import { ErrorMessage, Field, Form, Formik } from "formik";
import FormikInput from "../components/Input/FormikInput";
import axios from "axios";
import * as Yup from "yup";
import toast from "react-hot-toast";
import ReactPaginate from "react-paginate";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";
import { BiSolidTrashAlt } from "react-icons/bi";
import DeleteModal from "../Modal/DeleteModal";
import Loader from "../Loader/loader";

function ExpenseType() {
  const { view } = useContext(SidebarContext);
  const [expenseTypeData, setExpenseTypeData] = useState([]);
  const [updateView, setUpdateView] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState("");
  const [deleteExpense, setDeleteExpense] = useState("");
  const [Delete, setDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);

  const addExpenseType = (value, { resetForm }) => {
    const loader = toast.loading("Adding Expense type ...", {
      autoClose: false,
    });
    axios
      .post(`api/v1/expense_types`, {
        expense_type: {
          name: value.expense_type,
        },
      })
      .then((res) => {
        getExpenseType();
        toast.dismiss(loader);
        toast.success(res.data.message);
        resetForm();
      })
      .catch((err) => {
        console.log(err);
        toast.dismiss(loader);
        toast.error(
          err.response.data.message || err.message || err.response.data.error
        );
      });
  };

  const updateExpenseType = (value, { resetForm }) => {
    const loader = toast.loading("Updating Expense type ... ", {
      autoClose: false,
    });
    axios
      .put(`api/v1/expense_types/${selectedExpense.id}`, {
        expense_type: {
          name: value.expense_type,
        },
      })
      .then((res) => {
        getExpenseType();
        toast.dismiss(loader);
        toast.success(res.data.message);
        resetForm();
        setUpdateView(false);
        setSelectedExpense("");
      })
      .catch((err) => {
        toast.dismiss(loader);
        console.log(err);
        toast.error(
          err.response.data.message || err.message || err.response.data.error
        );
      });
  };

  const deleteExpenseType = () => {
    const loader = toast.loading("Deleting Expense Type ...", {
      autoClose: false,
    });
    axios
      .delete(`api/v1/expense_types/${deleteExpense.id}`)
      .then((response) => {
        toast.success(response.data.message);
        toast.dismiss(loader);
        setDelete(false);
        setDeleteExpense("");
        getExpenseType();
      })
      .catch((err) => {
        console.log(err);
        toast.dismiss(loader);
        toast.error(
          err.response.data.message || err.message || err.response.data.error
        );
      });
  };

  const getExpenseType = () => {
    axios
      .get(`api/v1/expense_types/index_expense_types`)
      .then((res) => {
        setExpenseTypeData(res.data.expense_types);
        setLoading(true);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getExpenseType();
  }, []);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleUpdate = (id) => {
    const selected_expense = expenseTypeData.find(
      (expense) => expense.id === id
    );
    setUpdateView(true);
    setSelectedExpense(selected_expense);
  };

  const handleDeleteModal = (id) => {
    const selected_expense = expenseTypeData.find(
      (expense) => expense.id === id
    );
    setDelete(true);
    setDeleteExpense(selected_expense);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = expenseTypeData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(expenseTypeData.length / itemsPerPage);

  return (
    <div className=" ">
      <Sidebar />
      <div
        className={`h-screen p-7 ${
          !view ? "ml-40 duration-100 ease-in" : "ml-0 duration-100 ease-in"
        }`}
      >
        {!loading ? (
          <Loader />
        ) : (
          <div className="flex flex-col sm:flex sm:flex-col p-5">
            <div className="flex sm:items-start items-center justify-center sm:justify-start xl:ml-20 2xl:ml-20 md:ml-20 sm:ml-20 ml-0 sm:mt-0 mt-8">
              <Formik
                initialValues={{ expense_type: "" }}
                validationSchema={Yup.object().shape({
                  expense_type: Yup.string().required("Please enter the field"),
                })}
                onSubmit={addExpenseType}
              >
                <Form>
                  <div className="flex flex-row">
                    <div className="flex flex-col">
                      <label className="block mb-2 text-sm text-gray-600 ">
                        Expense type
                      </label>
                      <Field
                        name="expense_type"
                        placeholder="Expense Type"
                        type="text"
                        className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#1d5b79]  focus:ring-[#1d5b79] focus:outline-none focus:ring focus:ring-opacity-40"
                      />
                      <ErrorMessage
                        className="text-red-500 text-sm mt-1"
                        component="div"
                        name="expense_type"
                      />
                    </div>
                    <div className="ml-2 mt-9">
                      <Button name={"Add"} type={"submit"} />
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
            <div className="flex items-center justify-center  xl:ml-20 2xl:ml-20 md:ml-20 lg:ml-20 sm:ml-20 ml-0">
              <div className="w-full mt-5">
                <div className="flex flex-col h-full">
                  <div className="w-full mx-auto bg-white  rounded-sm border border-gray-200">
                    <header className="flex sm:flex-row flex-col  justify-between px-5 py-4 border-b border-gray-100">
                      <h2 className="font-semibold text-gray-800">
                        Expense type
                      </h2>
                      {totalPages > 1 && (
                        <div className="flex items-start justify-start sm:justify-end sm:items-center sm:mt-0 mt-2 relative">
                          <ReactPaginate
                            pageCount={totalPages}
                            onPageChange={handlePageChange}
                            containerClassName={"flex items-center space-x-4"}
                            previousLabel={<FaChevronLeft />}
                            nextLabel={<FaChevronRight />}
                            activeClassName={"active"}
                            previousClassName={
                              "bg-[#1d5b79] text-[#fbffff] w-7 h-7 items-center justify-center p-2 text-xl flex rounded"
                            }
                            nextClassName={
                              "bg-[#1d5b79] text-[#fbffff] w-7 h-7 items-center justify-center p-2 text-xl flex rounded"
                            }
                            pageClassName={"hidden"}
                            pageLinkClassName={"hidden"}
                            breakClassName={"hidden"}
                            renderOnZeroPageCount={null}
                            breakLinkClassName={
                              "px-8 py-1 bg-gray-200 text-gray-600 rounded"
                            }
                          />
                          <div className="mx-2">
                            Page {currentPage + 1} of {totalPages}
                          </div>
                        </div>
                      )}
                    </header>
                    <div className="p-3">
                      <div className="overflow-x-auto">
                        <table className="table-auto w-full">
                          <thead className="text-xs font-semibold uppercase text-gray-600 bg-[#eceff1]">
                            <tr>
                              <th className="px-4 py-2 whitespace-nowrap">
                                <div className="font-semibold text-left">
                                  Name
                                </div>
                              </th>
                              <th className="px-4 py-2 whitespace-nowrap">
                                <div className="font-semibold text-left">
                                  Action
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="text-sm divide-y divide-gray-100">
                            {currentItems.map((expense) => (
                              <tr>
                                <td className="p-2 whitespace-nowrap">
                                  {selectedExpense.id === expense.id &&
                                  updateView ? (
                                    <div className="font-semibold text-left">
                                      <Formik
                                        initialValues={{ expense_type: "" }}
                                        onSubmit={updateExpenseType}
                                      >
                                        <Form>
                                          <div className="flex flex-row">
                                            <div>
                                              <FormikInput
                                                name={"expense_type"}
                                                placeholder={
                                                  selectedExpense.name
                                                }
                                                label={"Expense type"}
                                                type={"text"}
                                              />
                                            </div>
                                            <div className="mt-7 ml-2">
                                              <Button
                                                name={"Update"}
                                                type={"submit"}
                                              />
                                            </div>
                                            <div className="mt-7 ml-2">
                                              <Button
                                                name={"Cancel"}
                                                type={"button"}
                                                clickFunction={() => {
                                                  setSelectedExpense("");
                                                  setUpdateView(false);
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </Form>
                                      </Formik>
                                    </div>
                                  ) : (
                                    <div className="font-semibold text-left">
                                      {expense.name}
                                    </div>
                                  )}
                                </td>
                                <td className="p-2 whitespace-nowrap flex">
                                  <button
                                    onClick={() => handleUpdate(expense.id)}
                                    className="p-4 text-xl text-green-600 border-none hover:shadow-none rounded-full outline-none bg-green-50  duration-300 ease-in"
                                  >
                                    <MdModeEdit />
                                  </button>
                                  <button
                                    onClick={() =>
                                      handleDeleteModal(expense.id)
                                    }
                                    className="ml-4 p-4 text-xl text-red-600 bg-red-50 hover:shadow-none rounded-full  duration-300 ease-in outline-none"
                                  >
                                    <BiSolidTrashAlt />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {Delete && (
        <DeleteModal
          title={"Remove Expense type"}
          message={`Do you want to remove ${deleteExpense.name} ?`}
          onClose={() => {
            setDelete(false);
            setDeleteExpense("");
          }}
          onDelete={deleteExpenseType}
        />
      )}
    </div>
  );
}

export default ExpenseType;

import React, { useState } from "react";
import { IoMdArrowDropdownCircle, IoMdArrowDropupCircle } from "react-icons/io";
import { AnimatePresence, motion } from "framer-motion";
import {
  FaChevronCircleDown,
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa";

function AccountSummaryTable({ summary }) {
  const [view, setView] = useState(false);

  const Expenses = Object.keys(summary.duration_wise_expenses);

  const newDurationExpenseArray = Expenses.map((key) => {
    return {
      name: key,
      amount: summary.duration_wise_expenses[key],
    };
  });

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
  };

  return (
    <div className="flex flex-col h-full">
      <div className="w-full mx-auto bg-white  rounded-sm border border-gray-200">
        <header className="flex sm:flex-row flex-col  justify-between px-5 py-4 border-b border-gray-100">
          <h2 className="font-semibold text-gray-800">Expense summary</h2>
        </header>
        <div className="p-3">
          <div className="overflow-x-auto">
            {summary && (
              <table className="table-auto w-full">
                <thead className="text-xs font-semibold uppercase text-gray-600 bg-[#eceff1]">
                  <tr>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Expense</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Amount</div>
                    </th>
                  </tr>
                </thead>
                <tbody className="text-sm divide-y divide-gray-100">
                  <tr>
                    <td className="p-2 whitespace-nowrap text-xl">Artist</td>
                    <td className="p-2">
                      <div className="text-left text-lg">
                        ₹{summary.artist_sum}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="p-2 whitespace-nowrap text-xl">Crew</td>
                    <td className="p-2">
                      <div className="text-left text-lg">
                        ₹{summary.crew_sum}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="p-2 whitespace-nowrap text-xl">Location</td>
                    <td className="p-2">
                      <div className="text-left text-lg">
                        ₹{summary.location_sum}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="p-2 whitespace-nowrap text-xl">Vendor</td>
                    <td className="p-2">
                      <div className="text-left text-lg">
                        ₹{summary.vendor_sum}
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td className="p-2 whitespace-nowrap text-xl">
                      <div className="flex flex-row items-start justify-start">
                        Expense
                        <div className="ml-3 ">
                          <button
                            onClick={() => setView(!view)}
                            className="text-xl border-none outline-none text-[#fbffff] bg-[#1d5b79] rounded-full p-1"
                          >
                            {view ? (
                              <FaChevronUp size={15} />
                            ) : (
                              <FaChevronDown size={15} />
                            )}
                          </button>
                        </div>
                      </div>
                    </td>
                    <td className="p-2">
                      <div className="text-left text-lg">
                        ₹{summary.expense_sum}
                      </div>
                    </td>
                  </tr>
                  <AnimatePresence>
                    {view &&
                      newDurationExpenseArray.map((data, index) => (
                        <motion.tr
                          key={data.name} // Ensure to provide a unique key for each item
                          initial="hidden"
                          animate="visible"
                          exit="exit"
                          variants={itemVariants}
                          transition={{ duration: 0.3, delay: index * 0.1 }}
                        >
                          <td className="p-2 whitespace-nowrap text-lg">
                            {data.name}
                          </td>
                          <td className="p-2">
                            <div className="text-left text-base">
                              ₹{data.amount}
                            </div>
                          </td>
                        </motion.tr>
                      ))}
                  </AnimatePresence>
                  <tr>
                    <td className="p-2 whitespace-nowrap text-2xl font-extrabold">
                      Total
                    </td>
                    <td className="p-2">
                      <div className="text-left text-xl font-bold">
                        ₹{summary.total_sum}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountSummaryTable;

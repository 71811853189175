import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";

function ActorScript() {
  const { artistId } = useParams();
  const [sceneData, setSceneData] = useState([]);
  const fetchScript = () => {
    axios
      .get(`api/v1/projects/${artistId}/artists/artist_scene_script`)
      .then((response) => {
        setSceneData(response.data.artist_scene);
      })
      .catch((err) => {
        toast.error(err.response.data.message || err.message || err.response.data.error);
      });
  };

  const openScriptUrl = (url) => {
    window.open(url);
  };

  useEffect(() => {
    fetchScript();
  }, []);
  return (
    <div>
      <div className="flex flex-col sm:flex sm:flex-col p-5">
        <div className="flex items-start justify-start xl:ml-20 2xl:ml-20 sm:ml-20 ml-24">
          <h1 className="m-5 text-3xl font-semibold tracking-widest">Script</h1>
        </div>
      </div>
      <div className="flex items-center justify-center  xl:ml-20 2xl:ml-20 lg:ml-20 md:ml-20 sm:ml-20 ml-0">
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-6 px-2">
          {sceneData &&
            sceneData
              .sort((a, b) => a.scene_number - b.scene_number)
              .map((scene) => (
                <div
                  onClick={() => openScriptUrl(scene.document_url)}
                  className="relative flex flex-col shadow-md rounded-xl overflow-hidden hover:shadow-lg hover:-translate-y-1 transition-all duration-300 max-w-sm border-2 border-[#1d5b79] bg-white "
                >
                  <div className="bg-white py-4 px-3 w-96">
                    <h2 className="text-base mb-2 font-medium">
                      Scene: {scene.scene_number}
                    </h2>
                  </div>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
}

export default ActorScript;

import React, { useState } from "react";
import { BiSolidTrashAlt } from "react-icons/bi";
import { MdModeEdit } from "react-icons/md";
import moment from "moment";
import Pagination from "../Pagination/Pagination";
import usePagination from "../Pagination/usePagination";

function ShootingAttendanceTable({ data, updateView, deleteView }) {
  const { currentItems, currentPage, handlePageChange, totalPages } =
    usePagination(data, 10);

  return (
    <div>
      <div className="flex flex-col h-full">
        <div className="w-full mx-auto bg-white  rounded-sm border border-gray-200">
          <header className="flex sm:flex-row flex-col  justify-between px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold text-gray-800">Shooting Attendance</h2>
            <Pagination
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              totalPages={totalPages}
            />
          </header>
          <div className="p-3">
            <div className="overflow-x-auto">
              <table className="table-auto w-full">
                <thead className="text-xs font-semibold uppercase text-gray-600 bg-[#eceff1]">
                  <tr>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Day</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Date</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Actual call time
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Roll time</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Pack up time
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Difference hours
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody className="text-sm divide-y divide-gray-100">
                  {currentItems.map((schedule, index) => {
                    return (
                      <tr key={index}>
                        <td className="p-2 whitespace-nowrap">
                          {schedule?.schedule?.title}
                        </td>
                        <td className="p-2 whitespace-nowrap">
                          {moment(schedule?.schedule?.schedule_date).format(
                            "DD MMMM YYYY"
                          )}
                        </td>
                        <td>
                          <div className="text-left mx-10">
                            {moment(schedule?.call_time).format("hh:mm a")}
                          </div>
                        </td>
                        <td>
                          <div className="text-left mx-4">
                            {moment(schedule?.roll_time).format("hh:mm a")}
                          </div>
                        </td>
                        <td>
                          <div className="text-left">
                            {moment(schedule?.packup_time).format(
                              "DD-MM-YYYY hh:mm a"
                            )}
                          </div>
                        </td>
                        <td>
                          <div className="text-left mx-10">
                            {schedule?.overtime_hours} Hrs
                          </div>
                        </td>

                        <td className="p-2 whitespace-nowrap flex">
                          <div className="text-center">
                            <button
                              onClick={() => updateView(schedule)}
                              className="p-4 text-xl text-green-600 border-none hover:shadow-none rounded-full outline-none bg-green-50  duration-300 ease-in"
                            >
                              <MdModeEdit />
                            </button>
                            <button
                              onClick={() => deleteView(schedule)}
                              className="ml-4 p-4 text-xl text-red-600 bg-red-50 hover:shadow-none rounded-full  duration-300 ease-in outline-none"
                            >
                              <BiSolidTrashAlt />
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShootingAttendanceTable;

import React, { useContext, useState } from "react";
import { HiddenContext } from "../../Context/HiddenContent";
import usePagination from "../Pagination/usePagination";
import Pagination from "../Pagination/Pagination";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import moment from "moment-timezone";
import "moment/locale/en-in";
import { AnimatePresence, motion } from "framer-motion";

function AccommodationAccountTable({ data, handleAccommodationStatus }) {
  const [expandedView, setExpandedView] = useState({});
  const { hidden } = useContext(HiddenContext);
  const { currentItems, currentPage, handlePageChange, totalPages } =
    usePagination(data, 10);

  const toggleView = (id) => {
    setExpandedView((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };
  const convertToIST = (utcDateStr) => {
    return moment.utc(utcDateStr).tz("Asia/Kolkata").format("DD-MM-YYYY");
  };

  const DetailRow = ({ label, value }) => (
    <div className="flex flex-row">
      <h2 className="text-sm font-semibold text-[#1d5b79]">{label}</h2>
      <h2 className="text-sm ml-1">{value}</h2>
    </div>
  );

  const itemVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
  };

  const totalAmount = (rent, days) => {
    return rent * days;
  };

  return (
    <div>
      <div className="flex flex-col h-full">
        <div className="w-full mx-auto bg-white rounded-sm border border-gray-200">
          <header className="flex sm:flex-row flex-col justify-between px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold text-gray-800 mr-5">Accommodation</h2>
            <Pagination
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              totalPages={totalPages}
            />
          </header>
          <div className="p-3">
            <div className="overflow-x-auto">
              <table className="table-auto w-full">
                <thead className="text-xs font-semibold uppercase text-gray-600 bg-[#eceff1]">
                  <tr>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Accommodation name
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Accommodation type
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Start date</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">End date</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Total Amount
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Number of days
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        More details
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-center">Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody className="text-sm divide-y divide-gray-100">
                  {currentItems.map((data, index) => {
                    const meals = data?.room?.meals ? data?.room?.meals : [];
                    const rent = !data.accommodation.rent_amount
                      ? data.room.rate
                      : data.accommodation.rent_amount;
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td className="p-2 whitespace-nowrap">
                            {data.accommodation.name}
                          </td>
                          <td className="p-2">
                            <div className="text-left">
                              {data.accommodation.accommodation_type}
                            </div>
                          </td>
                          <td className="p-2">
                            <div className="text-left">
                              {convertToIST(data.start_date)}
                            </div>
                          </td>
                          <td className="p-2">
                            <div className="text-left">
                              {convertToIST(data.end_date)}
                            </div>
                          </td>
                          <td className="p-2">
                            <div className="text-left">
                              {totalAmount(rent, data.total_days)}
                            </div>
                          </td>
                          <td className="p-2">
                            <div className="text-left">
                              {data.total_days} days
                            </div>
                          </td>
                          <td className="p-2 whitespace-nowrap">
                            <button
                              onClick={() => toggleView(data.id)}
                              className="text-xl border-none outline-none text-[#fbffff] bg-[#1d5b79] rounded-full p-1"
                            >
                              {expandedView[data.id] ? (
                                <FaChevronUp size={15} />
                              ) : (
                                <FaChevronDown size={15} />
                              )}
                            </button>
                          </td>
                          {data.status === "pending" ? (
                            <React.Fragment>
                              {hidden ? (
                                <React.Fragment>
                                  <td className="p-2 whitespace-nowrap text-orange-600">
                                    Pending
                                  </td>
                                </React.Fragment>
                              ) : (
                                <td className="p-2 whitespace-nowrap">
                                  <div className="text-center">
                                    <button
                                      onClick={() =>
                                        handleAccommodationStatus(
                                          "approved",
                                          data.id,
                                          totalAmount(rent, data.total_days)
                                        )
                                      }
                                      className="p-4  text-green-600 border-none hover:shadow-none rounded-full outline-none bg-green-50 shadow-lg duration-300 ease-in"
                                    >
                                      Approve
                                    </button>
                                    <button
                                      onClick={() =>
                                        handleAccommodationStatus(
                                          "rejected",
                                          data.id
                                        )
                                      }
                                      className="ml-4 p-4  text-red-600 bg-red-50 hover:shadow-none rounded-full shadow-lg duration-300 ease-in outline-none"
                                    >
                                      Reject
                                    </button>
                                  </div>
                                </td>
                              )}
                            </React.Fragment>
                          ) : data.status === "approved" ? (
                            <td className="p-2 whitespace-nowrap  text-green-800">
                              <div className="text-center">Approved</div>
                            </td>
                          ) : data.status === "rejected" ? (
                            <td className="p-2 whitespace-nowrap  text-red-800">
                              <div className="text-center">Rejected</div>
                            </td>
                          ) : (
                            <td className="p-2 whitespace-nowrap flex"></td>
                          )}
                        </tr>
                        <AnimatePresence>
                          {expandedView[data.id] && (
                            <motion.tr
                              key={data.id}
                              initial="hidden"
                              animate="visible"
                              exit="exit"
                              variants={itemVariants}
                              className="bg-gray-50"
                              transition={{ duration: 0.3, delay: 0.1 }}
                            >
                              <td colSpan={9} className="p-4">
                                <div className="flex flex-col space-y-6">
                                  {data?.room && (
                                    <div>
                                      <div className="mb-4">
                                        <h2 className="text-lg font-semibold text-[#1d5b79] uppercase">
                                          Room
                                        </h2>
                                        <div className="grid grid-cols-6 gap-4">
                                          <DetailRow
                                            label="Room number:"
                                            value={data.room.room_number}
                                          />
                                          {data.room.room_type && (
                                            <DetailRow
                                              label="Room type:"
                                              value={data.room.room_type}
                                            />
                                          )}
                                          {meals.length > 0 && (
                                            <DetailRow
                                              label="Meals:"
                                              value={meals?.join(", ")}
                                            />
                                          )}
                                          {data.room.bhk && (
                                            <DetailRow
                                              label="BHK:"
                                              value={data.room.bhk}
                                            />
                                          )}
                                          {data.room.rate && (
                                            <DetailRow
                                              label="Rent:"
                                              value={`₹${data.room.rate}`}
                                            />
                                          )}
                                          <DetailRow
                                            label="Occupancy:"
                                            value={data.room.occupancy}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  <div>
                                    <h2 className="text-lg font-semibold text-[#1d5b79] uppercase">
                                      Room Mates
                                    </h2>
                                    <div className="grid grid-cols-5 gap-4">
                                      {Object.values(data.assigned_details).map(
                                        (mate, index) => (
                                          <div
                                            className="p-3 bg-white shadow rounded"
                                            key={index}
                                          >
                                            <h2 className="text-sm font-semibold text-[#1d5b79]">
                                              {mate.name}
                                            </h2>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </motion.tr>
                          )}
                        </AnimatePresence>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccommodationAccountTable;

import React, { useContext, useState } from "react";
import { MdModeEdit } from "react-icons/md";
import { BiSolidTrashAlt } from "react-icons/bi";
import SubRoleContext from "../../SubRoleContext";
import axios from "axios";
import { useParams } from "react-router-dom";
import usePagination from "../Pagination/usePagination";
import Pagination from "../Pagination/Pagination";

function SceneTable({
  filteredScenes,
  openArtistModal,
  ExtrasModalHandler,
  updViewHandler,
  openModal,
}) {
  const subRole = useContext(SubRoleContext);
  const { projectId } = useParams();

  const { currentItems, currentPage, handlePageChange, totalPages } =
    usePagination(filteredScenes, 10);

  const handleViewScript = (id) => {
    axios
      .get(`api/v1/projects/${projectId}/scenes/view_script?id=${id}`)
      .then((response) => {
        window.open(response.data.document_url);
      })
      .catch((error) => console.log(error));
  };
  return (
    <>
      <div className="flex flex-col h-full">
        <div className="w-full mx-auto bg-white  rounded-sm border border-gray-200">
          <header className="flex sm:flex-row flex-col  justify-between px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold text-gray-800">Scene</h2>
            <Pagination
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              totalPages={totalPages}
            />
          </header>
          <div className="p-3">
            <div className="overflow-x-auto">
              <table className="table-auto w-full">
                <thead className="text-xs font-semibold uppercase text-gray-600 bg-[#eceff1]">
                  <tr>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Scene no</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Location name
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Location address
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-center">
                        Location type
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-center">Shift</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Scene Brief</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Art Department Property
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Producer Team Property
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Make Up</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-center">Artist</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-center">Extras</div>
                    </th>
                    {subRole === "user" ? (
                      <></>
                    ) : (
                      <>
                        <th className="px-4 py-2 whitespace-nowrap">
                          <div className="font-semibold text-center">
                            View script
                          </div>
                        </th>

                        <th className="px-4 py-2 whitespace-nowrap">
                          <div className="font-semibold text-center">
                            Action
                          </div>
                        </th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody className="text-sm divide-y divide-gray-100">
                  {currentItems
                    .sort((a, b) => a.scene_number - b.scene_number)
                    .map((scene) => (
                      <tr>
                        <td className="p-2 whitespace-nowrap font-semibold text-xl">
                          {scene.scene_number}
                          {scene.sub_scene}
                        </td>
                        <td className="p-2">
                          <div className="text-left">{scene.location_name}</div>
                        </td>
                        <td className="p-2">
                          <div
                            className="text-left"
                            style={{ width: "300px", textAlign: "justify" }}
                          >
                            {scene.location_address}
                          </div>
                        </td>

                        <td className="p-2 whitespace-nowrap">
                          <div className="text-center">
                            {scene.location_type}
                          </div>
                        </td>
                        <td className="p-2 whitespace-nowrap">
                          <div className="text-center">{scene.shift}</div>
                        </td>
                        <td className="p-2">
                          <div
                            className="text-center"
                            style={{ width: "300px", textAlign: "justify" }}
                          >
                            {scene.brief_of_scene}
                          </div>
                        </td>
                        <td className="p-2">
                          <div
                            className="text-center"
                            style={{ width: "300px", textAlign: "justify" }}
                          >
                            {scene.art_department_property}
                          </div>
                        </td>
                        <td className="p-2">
                          <div
                            className="text-center"
                            style={{ width: "300px", textAlign: "justify" }}
                          >
                            {scene.production_team_property}
                          </div>
                        </td>
                        <td className="p-2">
                          <div
                            className="text-center"
                            style={{ width: "300px", textAlign: "justify" }}
                          >
                            {scene.makeup_department_property}
                          </div>
                        </td>
                        <td className="p-2 whitespace-nowrap">
                          <div className="text-center">
                            <button
                              className="hover:underline font-semibold hover:text-gray-800 duration-500 "
                              onClick={() => openArtistModal(scene.artists)}
                            >
                              View Artist
                            </button>
                          </div>
                        </td>
                        <td className="p-2 whitespace-nowrap">
                          <div className="text-center">
                            <button
                              onClick={() => ExtrasModalHandler(scene.id)}
                              className="hover:underline font-semibold hover:text-gray-800 duration-500 "
                            >
                              View
                            </button>
                          </div>
                        </td>
                        {subRole === "user" ? (
                          <> </>
                        ) : (
                          <>
                            <td className="p-2 whitespace-nowrap">
                              <div className="text-center">
                                <button
                                  onClick={() => handleViewScript(scene.id)}
                                  className="hover:underline font-semibold hover:text-gray-800 duration-500 "
                                >
                                  View Script
                                </button>
                              </div>
                            </td>

                            <td className="p-4 whitespace-nowrap flex">
                              <button
                                onClick={() => updViewHandler(scene)}
                                className="p-4 text-xl text-green-600 border-none hover:shadow-none rounded-full outline-none bg-green-50  duration-300 ease-in"
                              >
                                <MdModeEdit />
                              </button>
                              <button
                                onClick={() => openModal(scene.id)}
                                className="ml-4 p-4 text-xl text-red-600 bg-red-50 hover:shadow-none rounded-full  duration-300 ease-in outline-none"
                              >
                                <BiSolidTrashAlt />
                              </button>
                            </td>
                          </>
                        )}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SceneTable;

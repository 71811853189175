import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import VendorAccountTable from "../../components/AccountComponents/VendorAccountTable";
import axios from "axios";
import ErrorPage from "../ErrorPage";
import Loader from "../../Loader/loader";
import DownloadButton from "../../components/Button/DownloadButton";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { uid } from "uid";

function VendorAccount() {
  const [view, setView] = useState(false);
  const [vendor, setVendor] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { projectId } = useParams();
  const UID = uid();

  const fetchVendors = () => {
    axios
      .get(`api/v1/projects/${projectId}/project_vendors`)
      .then((response) => {
        setVendor(response.data);
        setLoading(true);
      })
      .catch((error) => {
        console.log(error.message);
        setError(true);
      });
  };
  useEffect(() => {
    fetchVendors();
  }, []);

  const handleDownloadInstallmentExcel = () => {
    const workbook = XLSX.utils.book_new();

    const iNames = new Set();
    vendor.forEach((data) =>
      data.installments.forEach((detail) => iNames.add(detail.i_name))
    );

    const data = vendor.map((vendor) => {
      const row = {
        "Vendor name": vendor.vendor.name,
        Amount: vendor.total_amount,
        GST: 0,
        TDS: 0,
      };

      iNames.forEach((iName) => {
        row[iName] = 0;
      });

      vendor.installments.forEach((detail) => {
        row[detail.i_name] += detail.amount;
        row.GST += detail.gst_amount;
        row.TDS += detail.tds_amount;
      });

      return row;
    });

    const worksheet = XLSX.utils.json_to_sheet(data);

    let cell_width = Array(Object.keys(data[0]).length);
    let remainig_cell_width = cell_width.length - 4;
    const colWidths = [
      { wpx: 85 },
      { wpx: 85 },
      { wpx: 50 },
      { wpx: 50 },
    ].concat(Array(remainig_cell_width).fill({ wpx: 85 }));

    worksheet["!cols"] = colWidths;

    const rowHeights = Array(data.length + 1).fill({ hpx: 20 });
    worksheet["!rows"] = rowHeights;

    XLSX.utils.book_append_sheet(workbook, worksheet, "Requests");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blobData = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(blobData, `Vendor_Installments_${UID}.xlsx`);
  };


  const handleDOwnloadVendorAccount = () =>{
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(vendor.map((data) => {
      return{
        "Vendor name": data.vendor.name,
        "Vendor type": data.vendor.vendor_type,
        "Payment type": data.amount_type,
        "Number of days": data.number_of_days,
        TDS: data.tds_amount,
        GST: data.gst_amount,
        Amount: data.calculated_amount
      }
    }));
    const colWidths = Array(Object.keys(vendor[0]).length).fill({
      wpx: 85,
    });
    worksheet["!cols"] = colWidths;

    const rowHeights = Array(vendor.length + 1).fill({ hpx: 20 });
    worksheet["!rows"] = rowHeights;
    XLSX.utils.book_append_sheet(workbook, worksheet, "Requests");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blobData = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });

    saveAs(blobData, `Vendor_Accounts_${UID}.xlsx`);
  }

  return (
    <div>
      {error ? (
        <ErrorPage />
      ) : (
        <div>
          {!loading ? (
            <Loader />
          ) : (
            <div className="flex flex-col sm:flex sm:flex-col p-5">
              <div className="flex sm:flex-row flex-col items-center justify-between">
                <div className="flex sm:items-start items-center justify-center sm:justify-start xl:ml-20 2xl:ml-20 md:ml-20 sm:ml-20 ml-0 sm:mt-0 mt-8">
                  <h1 className="text-3xl font-semibold sm:tracking-widest tracking-tight">
                    Vendor Account
                  </h1>
                </div>
                <div className="flex flex-row items-end justify-end sm:mt-0 mt-2">
                  <div>
                    <DownloadButton
                      onClick={handleDOwnloadVendorAccount}
                      name={"Download Accounts"}
                    />
                  </div>
                  <div className="ml-4">
                    <DownloadButton
                      name={"Installment Details"}
                      onClick={handleDownloadInstallmentExcel}
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center  xl:ml-20 2xl:ml-20 md:ml-20 lg:ml-20 sm:ml-20 ml-0">
                <div className="w-full mt-5">
                  <VendorAccountTable
                    view={view}
                    setView={setView}
                    projectId={projectId}
                    getVendorData={fetchVendors}
                    data={vendor}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default VendorAccount;

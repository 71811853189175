import React, { useState } from "react";
import usePagination from "../Pagination/usePagination";
import Pagination from "../Pagination/Pagination";
import { MdModeEdit } from "react-icons/md";
import { BiSolidTrashAlt } from "react-icons/bi";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { AnimatePresence, motion } from "framer-motion";
import moment from "moment-timezone";
import "moment/locale/en-in";

function AssignedAccomodationTable({ data, updateView, deleteView }) {
  const [expandedView, setExpandedView] = useState({});
  const { currentItems, currentPage, handlePageChange, totalPages } =
    usePagination(data, 10);

  const itemVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
  };

  const toggleView = (id) => {
    setExpandedView((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const convertToIST = (utcDateStr) => {
    return moment.utc(utcDateStr).tz("Asia/Kolkata").format("DD-MM-YYYY");
  };

  const DetailRow = ({ label, value }) => (
    <div className="flex flex-row">
      <h2 className="text-sm font-semibold text-[#1d5b79]">{label}</h2>
      <h2 className="text-sm ml-1">{value}</h2>
    </div>
  );

  return (
    <div>
      <div>
        <div className="flex flex-col h-full">
          <div className="w-full mx-auto bg-white  rounded-sm border border-gray-200">
            <header className="flex sm:flex-row flex-col  justify-between px-5 py-4 border-b border-gray-100">
              <h2 className="font-semibold text-gray-800">
                Assigned Accommodation
              </h2>
              <Pagination
                currentPage={currentPage}
                handlePageChange={handlePageChange}
                totalPages={totalPages}
              />
            </header>
            <div className="p-3">
              <div className="overflow-x-auto">
                <table className="table-auto w-full">
                  <thead className="text-xs font-semibold uppercase text-gray-600 bg-[#eceff1]">
                    <tr>
                      <th className="px-4 py-2 whitespace-nowrap">
                        <div className="font-semibold text-left">
                          Accomodation name
                        </div>
                      </th>
                      <th className="px-4 py-2 whitespace-nowrap">
                        <div className="font-semibold text-left">
                          Accommodation type
                        </div>
                      </th>
                      <th className="px-4 py-2 whitespace-nowrap">
                        <div className="font-semibold text-left">
                          Start date
                        </div>
                      </th>
                      <th className="px-4 py-2 whitespace-nowrap">
                        <div className="font-semibold text-left">End date</div>
                      </th>
                      <th className="px-4 py-2 whitespace-nowrap">
                        <div className="font-semibold text-left">
                          Room mates
                        </div>
                      </th>
                      <th className="px-4 py-2 whitespace-nowrap">
                        <div className="font-semibold text-left">Action</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-sm divide-y divide-gray-100">
                    {currentItems.map((accommodation, index) => {
                      const meals = accommodation?.room?.meals
                        ? accommodation?.room?.meals
                        : [];
                      return (
                        <React.Fragment key={index}>
                          <tr>
                            <td className="p-2">
                              <div className="text-left capitalize">
                                {accommodation.accommodation.name}
                              </div>
                            </td>
                            <td className="p-2">
                              <div className="text-left">
                                {accommodation.accommodation.accommodation_type}
                              </div>
                            </td>
                            <td className="p-2">
                              <div className="text-left">
                                {convertToIST(accommodation.start_date)}
                              </div>
                            </td>
                            <td className="p-2">
                              <div className="text-left capitalize">
                                {convertToIST(accommodation.end_date)}
                              </div>
                            </td>
                            <td className="p-2">
                              <div className="">
                                <button
                                  onClick={() => toggleView(accommodation.id)}
                                  className="text-xl border-none outline-none text-[#fbffff] bg-[#1d5b79] rounded-full p-1"
                                >
                                  {expandedView[accommodation.id] ? (
                                    <FaChevronUp size={15} />
                                  ) : (
                                    <FaChevronDown size={15} />
                                  )}
                                </button>
                              </div>
                            </td>
                            <td className="p-2 whitespace-nowrap flex">
                              <div>
                                <button
                                  onClick={() => updateView(accommodation)}
                                  className="p-4 text-xl text-green-600 border-none hover:shadow-none rounded-full outline-none bg-green-50  duration-300 ease-in"
                                >
                                  <MdModeEdit />
                                </button>
                                <button
                                  onClick={() => deleteView(accommodation)}
                                  className="ml-4 p-4 text-xl text-red-600 bg-red-50 hover:shadow-none rounded-full  duration-300 ease-in outline-none"
                                >
                                  <BiSolidTrashAlt />
                                </button>
                              </div>
                            </td>
                          </tr>
                          <AnimatePresence>
                            {expandedView[accommodation.id] && (
                              <motion.tr
                                key={accommodation.id}
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                variants={itemVariants}
                                className="bg-gray-50"
                                transition={{ duration: 0.3, delay: 0.1 }}
                              >
                                <td colSpan={9} className="p-4">
                                  <div className="flex flex-col space-y-6">
                                    {accommodation.accommodation
                                      .rent_amount && (
                                      <DetailRow
                                        label={"Rent:"}
                                        value={`₹${accommodation.accommodation.rent_amount}
                                        `}
                                      />
                                    )}
                                  </div>
                                  <div className="flex flex-col space-y-6">
                                    {accommodation?.room && (
                                      <div>
                                        <div className="mb-4">
                                          <h2 className="text-lg font-semibold text-[#1d5b79] uppercase">
                                            Room
                                          </h2>
                                          <div className="grid grid-cols-6 gap-4">
                                            <DetailRow
                                              label="Room number:"
                                              value={
                                                accommodation.room.room_number
                                              }
                                            />
                                            {accommodation.room.room_type && (
                                              <DetailRow
                                                label="Room type:"
                                                value={
                                                  accommodation.room.room_type
                                                }
                                              />
                                            )}
                                            {meals.length > 0 && (
                                              <DetailRow
                                                label="Meals:"
                                                value={meals.join(", ")}
                                              />
                                            )}
                                            {accommodation.room.bhk && (
                                              <DetailRow
                                                label="BHK:"
                                                value={accommodation.room.bhk}
                                              />
                                            )}
                                            {accommodation.room.rate && (
                                              <DetailRow
                                                label="Rent:"
                                                value={`₹${accommodation.room.rate}`}
                                              />
                                            )}
                                            <DetailRow
                                              label="Occupancy:"
                                              value={
                                                accommodation.room.occupancy
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                    <div>
                                      <h2 className="text-lg font-semibold text-[#1d5b79] uppercase">
                                        Room Mates
                                      </h2>
                                      <div className="grid grid-cols-5 gap-4">
                                        {Object.values(
                                          accommodation.assigned_details
                                        ).map((data, index) => (
                                          <div
                                            className="p-3 bg-white shadow rounded"
                                            key={index}
                                          >
                                            <h2 className="text-sm font-semibold text-[#1d5b79]">
                                              {data.name}
                                            </h2>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </motion.tr>
                            )}
                          </AnimatePresence>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssignedAccomodationTable;

import React, { useContext, useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import UploadScriptForm from "../../components/Forms/UploadScriptForm";
import axios from "axios";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { Document, Page, pdfjs } from "react-pdf";
import SubRoleContext from "../../SubRoleContext";
import Loader from "../../Loader/loader";
import Lock from "../../assets/image/Lock.png";
import View from "../../assets/image/View Script.png";
import { MdOutlineNavigateBefore, MdOutlineNavigateNext } from "react-icons/md";
import ErrorPage from "../ErrorPage";

// Set the workerSrc property
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function UploadScript({ activeTab }) {
  const subRole = useContext(SubRoleContext);
  const [error, setError] = useState(false);
  const [view, setView] = useState(false);
  const [script, setScript] = useState("");
  const [scriptUrl, setScriptUrl] = useState("");
  const [url, setUrl] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // State to manage current page number
  const [showError, setShowError] = useState(false);
  const [pageInput, setPageInput] = useState(""); // State for page input field value
  const { projectId } = useParams();

  const handleScriptChange = (e) => {
    setScript(e.target.files[0]);
  };

  const handleViewChange = () => {
    setView(!view);
  };

  const handleUploadScriptChange = (value, { resetForm }) => {
    const loaderToast = toast.loading("Uploading script...", {
      autoClose: false,
    });

    const formData = new FormData();
    formData.append("original_pdf", value.file);
    axios
      .post(`api/v1/projects/${projectId}/scripts`, formData)
      .then((response) => {
        if (response.status === 200) {
          toast.dismiss(loaderToast);
          toast.success(response.data.message);
          resetForm();
          setView(false);
        }
      })
      .catch((err) => {
        if (err) {
          toast.dismiss(loaderToast);
          getScript();
          toast.error(
            err.response.data.message || err.message || err.response.data.error
          );
        }
        console.log(err);
      });
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const getScript = async () => {
    const loader = toast.loading("Fetching script ...", { autoClose: false });
    try {
      const response = await axios.get(`api/v1/projects/${projectId}/scripts`);
      const pdfUrl = response.data.script;
      setUrl(pdfUrl);

      // Fetch the PDF file content from the URL
      const pdfResponse = await axios.get(pdfUrl, { responseType: "blob" });
      const pdfBlob = new Blob([pdfResponse.data], { type: "application/pdf" });
      if (response.status === 200) {
        if (!response.data.message) {
          toast.dismiss(loader);
          toast.success("Script rendered successfully.");
        } else {
          toast.dismiss(loader);
          toast.success(response.data.message);
        }
      }
      // Create a blob URL for the PDF content
      const url = URL.createObjectURL(pdfBlob);
      setScriptUrl(url);
    } catch (error) {
      toast.dismiss(loader);
      if (error.response) {
        if (error.response.status === 422) {
          setShowError(true);
          toast.error(error.response.data.error);
        } else {
          setError(true);
        }
      } else {
        setError(true);
      }
      console.error("Error fetching PDF:", error);
    }
  };

  const gotoNextPage = () => {
    if (currentPage < numPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const gotoPrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const gotoPage = (value) => {
    setPageInput(value);
    const pageNumber = parseInt(value);
    if (pageNumber >= 1 && pageNumber <= numPages) {
      setCurrentPage(pageNumber);
    } else {
      toast.error(`Page number must be between 1 and ${numPages}.`);
    }
  };

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  }, []);

  useEffect(() => {
    setPageInput(currentPage.toString());
  }, [currentPage]);

  return (
    <div>
      {error ? (
        <ErrorPage />
      ) : (
        <div>
          <div className="flex flex-col sm:flex sm:flex-col p-5">
            <div className="flex sm:items-start items-center justify-center sm:justify-start xl:ml-20 2xl:ml-20 md:ml-20 sm:ml-20 ml-0 sm:mt-0 mt-8">
              {subRole ? (
                <div></div>
              ) : (
                <div>
                  <Button
                    clickFunction={handleViewChange}
                    name={"Upload Script"}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-center mt-5 xl:ml-20 2xl:ml-20 lg:ml-20 md:ml-20 sm:ml-20 ml-0">
            {url ? (
              <>
                {showError ? (
                  <div className="flex flex-col items-center justify-center mt-5 xl:ml-20 2xl:ml-20 lg:ml-20 md:ml-20 sm:ml-20 ml-0">
                    <img src={Lock} alt="lock" />
                    <h1 className="text-3xl text-[#1d5b79] font-semibold">
                      You don't have access to this page
                    </h1>
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center mt-5 xl:ml-20 2xl:ml-20 lg:ml-20 md:ml-20 sm:ml-20 ml-0">
                    <div className="flex items-center justify-center mb-3">
                      <button
                        disabled={currentPage <= 1}
                        onClick={gotoPrevPage}
                        className="bg-[#1d5b79]  text-white p-1 shadow-md rounded-full hover:shadow-none ease-in duration-300"
                      >
                        <MdOutlineNavigateBefore />
                      </button>
                      <input
                        type="number"
                        className="mx-4 w-16 text-center border border-gray-300 rounded-md px-2 py-1 focus:border-[#1d5b79]  focus:ring-[#1d5b79] focus:outline-none focus:ring focus:ring-opacity-40"
                        value={pageInput}
                        onChange={(e) => {
                          gotoPage(e.target.value);
                        }}
                      />
                      <span className="mr-2">{`of ${numPages}`}</span>
                      <button
                        disabled={currentPage >= numPages}
                        onClick={gotoNextPage}
                        className="bg-[#1d5b79]  text-white p-1 shadow-md rounded-full hover:shadow-none ease-in duration-300"
                      >
                        <MdOutlineNavigateNext />
                      </button>
                    </div>
                    <div className="shadow-lg p-2 bg-white rounded-lg">
                      <Document
                        file={scriptUrl}
                        onLoadSuccess={onDocumentLoadSuccess}
                        className="flex justify-center"
                      >
                        <Page
                          pageNumber={currentPage}
                          renderTextLayer={false}
                          className="h-[297mm] w-auto border border-gray-300 shadow-md"
                        />
                      </Document>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="flex flex-col items-center justify-center">
                <img src={View} alt="view" />
                <div className="mt-2">
                  <Button name={"View script"} clickFunction={getScript} />
                </div>
              </div>
            )}
          </div>
          {view && (
            <UploadScriptForm
              handleViewChange={handleViewChange}
              handleScriptChange={handleScriptChange}
              handleUploadScriptChange={handleUploadScriptChange}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default UploadScript;

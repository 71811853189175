import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { RxCross1 } from "react-icons/rx";
import FormikSelect from "../Input/FormikSelect";
import FormikInput from "../Input/FormikInput";
import Button from "../Button/Button";

function AddSpenderForm({ setView, spender, onSubmit }) {
  const Spender = spender.map((data) => {
    return {
      id: data.id,
      name: data.full_name,
    };
  });
  return (
    <div className="fixed inset-0 flex items-center justify-center overflow-auto bg-black bg-opacity-50 z-50">
      <div className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto ">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-md w-full mx-auto">
          <div className="flex items-center justify-between">
            <div className="flex items-start justify-start mr-5">
              <h2 className="text-lg sm:text-2xl font-semibold  text-[#1d5b79]">
                Spender
              </h2>
            </div>
            <div className="flex items-end justify-end">
              <button
                className="bg-[#1d5b79] p-2 sm:-mt-6 sm:-mr-4 -mt-3 -mr-2 text-lg text-[#fbffff] rounded-full "
                onClick={() => setView(false)}
              >
                <RxCross1 />
              </button>
            </div>
          </div>
          <Formik initialValues={{ crew: "", amount: "" }} onSubmit={onSubmit}>
            {({ setFieldValue }) => (
              <Form>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                  <div>
                    <label className="block mb-2 text-sm text-gray-600 ">
                      Spender
                    </label>
                    <Field
                      name="crew"
                      as="select"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#1d5b79]  focus:ring-[#1d5b79] focus:outline-none focus:ring focus:ring-opacity-40"
                    >
                      <option selected value="">
                        Spender
                      </option>
                      {Spender.map((option, index) => (
                        <option value={option.id}>{option.name}</option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="crew"
                      className="text-red-500 text-sm mt-1"
                      component="div"
                    />
                  </div>
                  <div>
                    <FormikInput
                      label={"Amount"}
                      placeholder={"12***"}
                      type={"number"}
                      name={"amount"}
                    />
                  </div>
                  <div className="sm:mt-4 mt-2">
                    <Button name={"Assign"} type={"submit"} />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default AddSpenderForm;

import React from "react";
import Button from "../components/Button/Button";
import Input from "../components/Input/Input";
import { Form, Formik } from "formik";
import FormikInput from "../components/Input/FormikInput";
import FormikSelect from "../components/Input/FormikSelect";
import * as Yup from "yup";
import { paymentMode } from "../components/Data";

function PaymentModal({
  data,
  closePaymentModal,
  stopClose,
  name,
  paymentHandler,
  location,
}) {
  const validationSchema = Yup.object().shape({
    installment_name: Yup.string().required("Installment name is required"),
    amount: Yup.number()
      .required("Amount is required")
      .min(0, "Amount must be at least 0"),
    tds: Yup.number(),
    gst: Yup.number(),
    payment_mode: Yup.string()
      .oneOf(["Cheque", "Net banking", "Cash"], "Invalid payment mode")
      .required("Payment mode is required"),
    payment_reference: Yup.string().when("payment_mode", {
      is: (value) => ["Cheque", "Net banking"].includes(value),
      then: (schema) => schema.required("Payment reference is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  const initialInstallmentName = location ? "Deposit money" : "Signing Amount";

  const i_name =
    data.installments.length === 0
      ? initialInstallmentName
      : data.installments.some(
          (installment) => installment.i_name === initialInstallmentName
        )
      ? `Installment ${data.installments.length}`
      : "";

  return (
    <div>
      <div
        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
        onClick={closePaymentModal}
      >
        <div
          className="flex items-center justify-center z-50"
          onClick={stopClose}
        >
          <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-md w-full mx-auto">
            <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
              {name}'s Payment
            </h2>
            <Formik
              initialValues={{
                installment_name: i_name,
                amount: "",
                tds: 0,
                gst: 0,
                payment_mode: "",
                payment_reference: "",
                transaction_date: "",
                bill: "",
              }}
              validationSchema={validationSchema}
              onSubmit={paymentHandler}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                    <div>
                      <FormikInput
                        label={"Installment name"}
                        name={"installment_name"}
                        type={"text"}
                        disable={true}
                        placeholder={"Enter installment name"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        label={"Amount"}
                        name={"amount"}
                        type={"number"}
                        placeholder={"25***"}
                        readOnly={!values.installment_name}
                      />
                    </div>
                    <div>
                      <FormikInput
                        label={"GST amount"}
                        name={"gst"}
                        type={"number"}
                        placeholder={"25*"}
                        disable={!data.gst_allowed}
                        readOnly={!values.installment_name}
                      />
                    </div>
                    <div>
                      <FormikInput
                        label={"TDS Amount"}
                        name={"tds"}
                        type={"number"}
                        placeholder={"25*"}
                        disable={!data.tds_allowed}
                        readOnly={!values.installment_name}
                      />
                    </div>
                    <div>
                      <FormikSelect
                        name={"payment_mode"}
                        selectedOption={"Payment mode"}
                        label={"Payment mode"}
                        optionData={paymentMode}
                        onChange={(e) =>
                          setFieldValue("payment_mode", e.target.value)
                        }
                      />
                    </div>
                    {(values.payment_mode === "Cheque" ||
                      values.payment_mode === "Net banking") && (
                      <>
                        <div>
                          <FormikInput
                            name={"payment_reference"}
                            label={
                              values.payment_mode === "Cheque"
                                ? "Cheque number"
                                : "Payment ID"
                            }
                            placeholder={
                              values.payment_mode === "Cheque"
                                ? "Cheque number"
                                : "Payment ID"
                            }
                            type={"text"}
                          />
                        </div>
                        <div>
                          <FormikInput
                            name={"transaction_date"}
                            type={"date"}
                            label={
                              values.payment_mode === "Cheque"
                                ? "Cheque Issue date"
                                : "Transaction date"
                            }
                          />
                        </div>
                      </>
                    )}
                    <div>
                      <label className="block mb-2 text-sm text-gray-600 ">
                        Bill(s)
                      </label>
                      <input
                        name="bill"
                      type="file"
                        accept=".pdf,image/*"
                        onChange={(event) => {
                          setFieldValue("bill", event.currentTarget.files);
                        }}
                        className="cursor-pointer block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#24b7aa]  focus:ring-[#24b7aa] focus:outline-none focus:ring focus:ring-opacity-40"
                      />
                    </div>
                  </div>
                  <div className="mt-2 sm:mt-4">
                    <Button name={"Pay"} type={"submit"} />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentModal;

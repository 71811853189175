import React, { useContext, useEffect, useState } from "react";
import { LiaUsersSolid } from "react-icons/lia";
import {
  FaChevronDown,
  FaHome,
  FaRegHandshake,
  FaTheaterMasks,
} from "react-icons/fa";
import { IoMenuOutline } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { SidebarContext } from "../Pages/SuperAdmin/SidebarContext";
import { VscOrganization } from "react-icons/vsc";
import { FcMoneyTransfer } from "react-icons/fc";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { AiOutlineMenuFold } from "react-icons/ai";
import { FaUser } from "react-icons/fa6";
import {
  MdAccountBalance,
  MdHomeFilled,
  MdOutlineMarkEmailRead,
} from "react-icons/md";
import { AnimatePresence, motion } from "framer-motion";
import { HiMiniBuildingLibrary } from "react-icons/hi2";
import { FaPeopleGroup } from "react-icons/fa6";

function Sidebar() {
  const { view, toggleView } = useContext(SidebarContext);
  const [isToggle, setIsToggle] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { projectId, title } = useParams();

  const tabs = [
    {
      id: 1,
      label: "Profile",
      path: "Profile",
      icon: <FaUser />,
    },
    {
      id: 2,
      label: "Artist",
      path: "Artist",
      icon: <FaTheaterMasks />,
    },
    {
      id: 3,
      label: "Crew",
      path: "Crew",
      icon: <LiaUsersSolid />,
    },
    {
      id: 4,
      label: "Vendors",
      path: "Vendor",
      icon: <VscOrganization />,
    },
    {
      id: 5,
      label: "Partner",
      path: "Partner",
      icon: <FaRegHandshake />,
    },
    {
      id: 6,
      label: "Accounts",
      icon: <MdAccountBalance />,
      dropIcon: true,
      subTab: [
        {
          id: 6.1,
          label: "Production account",
          icon: <HiMiniBuildingLibrary />,
          path: `Accounts/${projectId}/${title}`,
        },
        {
          id: 6.2,
          label: "Team account",
          icon: <FaPeopleGroup />,
          path: "TeamAccounts",
        },
      ],
    },
    {
      id: 7,
      label: "Expense Types",
      path: "Expense-Type",
      icon: <FcMoneyTransfer />,
    },
    {
      id: 8,
      label: "Email Configuration",
      icon: <MdOutlineMarkEmailRead />,
      path: "EmailAuth",
    },
  ];

  const userDetail = localStorage.getItem("User_Details");
  const user_details = JSON.parse(userDetail);
  const handleTabClick = (tab) => {
    if (tab.id === 6) {
      setIsToggle(!isToggle);
    } else {
      navigate(`/${tab.path}`);
    }
  };

  const toggleTrue = () => {
    const findTab = tabs.find((tab) => tab.id === 6);

    const matchSubTab = findTab.subTab.some((sub) =>
      location.pathname.includes(sub.path)
    );
    if (matchSubTab) {
      setIsToggle(true);
    } else {
      setIsToggle(false);
    }
  };

  useEffect(() => {
    toggleTrue();
  }, []);
  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
  };

  return (
    <div className=" ">
      <button
        onClick={toggleView}
        className="fixed flex sm:hidden top-5 left-5 text-[#fbffff] bg-[#1d5b79] text-xl outline-none shadow-lg font-bold p-3 rounded-full hover:shadow-none border-2 border-[#fbffff] duration-200 ease-out sm:text-3xl sm:p-4 z-50"
      >
        {view ? <IoMenuOutline /> : <RxCross1 />}
      </button>
      <nav
        className={`${
          view
            ? "sm:w-20 scale-0 sm:scale-100 origin-left"
            : "sm:w-60 w-11/12 z-40"
        } sm:h-screen h-full bg-[#1d5b79] p-5 duration-100 fixed  rounded-br-2xl rounded-tr-2xl overflow-x-hidden overflow-y-auto no-scrollbar`}
      >
        <button
          onClick={toggleView}
          className="bg-white text-[#1d5b79] w-8 h-8 items-center justify-center p-2 top-9 -right-4 border-[#1d5b79]  rounded-full  border-2 hidden sm:flex "
        >
          <span className="text-xl font-bold">
            {view ? <AiOutlineMenuUnfold /> : <AiOutlineMenuFold />}
          </span>
        </button>
        <div
          className={`${
            view && "scale-0 hidden"
          } m-5  flex-col sm:items-center sm:justify-center items-center justify-center mt-16 md:mt-10`}
        >
          <h1 className="text-[#fbffff] uppercase text-xl sm:text-xl sm:mt-0 mt-4 font-bold">
            {user_details.company_name}
          </h1>
          <h1 className="text-[#fbffff] uppercase text-sm sm:text-sm  font-bold">
            By {user_details.name}
          </h1>
        </div>
        <ul className="flex flex-col mt-5">
          {tabs.map((tab) => (
            <li key={tab.id}>
              <p
                onClick={() => handleTabClick(tab)}
                className={`relative text-base flex mt-1 gap-x-2 items-center cursor-pointer p-2 w-full sm:w-56 group ${
                  location.pathname === `/${tab.path}`
                    ? `${
                        view ? "sm:activeTab-2" : ""
                      } bg-white text-[#1d5b79] rounded-s-3xl rounded-e-3xl sm:activeTab shadow-md `
                    : "text-[#fbffff]"
                }`}
              >
                <span>{tab.icon}</span>
                <span className={`${view && "hidden"} origin-left duration-75`}>
                  {tab.label}
                </span>
                {tab.dropIcon ? (
                  <span className={`${isToggle ? "" : "-rotate-90"}`}>
                    <FaChevronDown />
                  </span>
                ) : null}
              </p>
              <AnimatePresence>
                {isToggle &&
                  tab.subTab &&
                  tab.subTab.map((subtab, index) => (
                    <motion.p
                      key={subtab.id}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      variants={itemVariants}
                      transition={{ duration: 0.3, delay: index * 0.1 }}
                      onClick={() => handleTabClick(subtab)}
                      className={`relative text-base flex mt-1 items-center cursor-pointer p-2 w-full sm:w-56 group ${
                        location.pathname === `/${subtab.path}`
                          ? `${
                              view ? "sm:activeTab-2 gap-x-1" : "gap-x-2"
                            } bg-white text-[#1d5b79] rounded-s-3xl rounded-e-3xl sm:activeTab shadow-md  scale-100`
                          : "text-[#fbffff] ml-5 scale-95"
                      }`}
                    >
                      <span className="mr-2 text-xl">{subtab.icon}</span>
                      <span
                        className={`${
                          view && "hidden"
                        } duration-300 ease-in-out`}
                      >
                        {subtab.label}
                      </span>
                    </motion.p>
                  ))}
              </AnimatePresence>
            </li>
          ))}
        </ul>
      </nav>
      <div className="flex flex-row items-end justify-end w-full z-20 relative">
        <div>
          <button
            onClick={() => {
              localStorage.removeItem("Project_details");
              navigate("/project");
            }}
            className="text-2xl bg-[#a9c8e2] text-[#1d5b79] fixed right-5 top-5 p-2 shadow rounded-full"
          >
            <MdHomeFilled />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Location from "./Location";
import CrewDetails from "./CrewDetails";
import Scene from "./Scene";
import SceneManager from "./SceneManager";
import Timeline from "./Timeline";
import AllContact from "./AllContact";
import ArtistDetail from "./ArtistDetail";
import Expenses from "./Expenses";
import Accounts from "./Accounts";
import { IoMenuOutline } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";
import UploadScript from "./UploadScript";
import {
  FaCarSide,
  FaChevronDown,
  FaHotel,
  FaRegCalendar,
} from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { FaMasksTheater } from "react-icons/fa6";
import { FaUserAlt } from "react-icons/fa";
import { RiMovie2Fill, RiTeamLine } from "react-icons/ri";
import { BiSolidCameraMovie } from "react-icons/bi";
import { PiFilmScriptFill } from "react-icons/pi";
import { FaRupeeSign } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import { MdAccountBalance, MdFastfood, MdManageAccounts } from "react-icons/md";
import { PiOfficeChairFill } from "react-icons/pi";
import Roles from "./Roles";
import toast from "react-hot-toast";
import { IoIosLogOut } from "react-icons/io";
import { MdCameraRoll } from "react-icons/md";
import ShotDivisionUser from "./ShotDivisionUser";
import SceneContinuity from "./SceneContinuity";
import { MdHomeFilled } from "react-icons/md";
import { FaLink } from "react-icons/fa";
import { VscOrganization } from "react-icons/vsc";
import ProjectVendor from "./ProjectVendor";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { AiOutlineMenuFold } from "react-icons/ai";
import Catering from "./Catering";
import { FaClapperboard } from "react-icons/fa6";
import SecureLS from "secure-ls";
import { motion, AnimatePresence } from "framer-motion";
import { HiOutlineClipboardDocumentCheck } from "react-icons/hi2";
import ShootingAttendance from "./ShootingAttendance";
import TravelTab from "./TravelTab";
import HotelTab from "./HotelTab";
import axios from "axios";

function ProjectOverview() {
  const { projectId } = useParams();
  const [view, setView] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [projectDetails, setProjectDetails] = useState({});
  const ls = new SecureLS();
  const [showTab, setShowTab] = useState({});
  const navigate = useNavigate();
  const userRole = ls.get("sub_role");

  const toggleView = () => {
    setView(!view);
  };
  const tabs = [
    { id: 1, label: "Script", icon: <PiFilmScriptFill /> },
    { id: 2, label: "Project Timeline", icon: <FaRegCalendar /> },
    { id: 3, label: "Location", icon: <FaLocationDot /> },
    {
      id: 4,
      label: "Team",
      icon: <RiTeamLine />,
      showContent: false,
      dropIcon: true,
      subTab:
        userRole === "manager"
          ? [
              { id: 4.1, label: "Artist Details", icon: <FaMasksTheater /> },
              { id: 4.2, label: "Crew Details", icon: <FaUserAlt /> },
            ]
          : [
              { id: 4.1, label: "Artist Details", icon: <FaMasksTheater /> },
              { id: 4.2, label: "Crew Details", icon: <FaUserAlt /> },
              { id: 4.3, label: "Vendors", icon: <VscOrganization /> },
            ],
    },
    {
      id: 5,
      label: "Essentials",
      icon: <MdManageAccounts />,
      showContent: false,
      dropIcon: true,
      subTab: [
        { id: 5.1, label: "Catering", icon: <MdFastfood /> },
        { id: 5.2, label: "Travel", icon: <FaCarSide /> },
        { id: 5.3, label: "Accommodation  ", icon: <FaHotel /> },
      ],
    },
    {
      id: 6,
      label: "Scene manager",
      icon: <FaClapperboard />,
      showContent: false,
      dropIcon: true,
      subTab: [
        { id: 6.1, label: "Scene", icon: <RiMovie2Fill /> },
        { id: 6.2, label: "Shot division", icon: <BiSolidCameraMovie /> },
        { id: 6.3, label: "Continuity Chain", icon: <FaLink /> },
        { id: 6.4, label: "Schedule Scene", icon: <MdCameraRoll /> },
        {
          id: 6.5,
          label: "Shooting Attendance",
          icon: <HiOutlineClipboardDocumentCheck />,
        },
      ],
    },
    { id: 7, label: "Expenses", icon: <FaRupeeSign /> },
    { id: 8, label: "Contacts", icon: <IoCall /> },
    { id: 9, label: "Accounts", icon: <MdAccountBalance /> },
    { id: 10, label: "Assign Role", icon: <PiOfficeChairFill /> },
  ];

  const renderContent = () => {
    switch (activeTab) {
      case 1:
        return <UploadScript />;
      case 2:
        return <Timeline />;
      case 3:
        return <Location />;
      case 4.1:
        return <ArtistDetail />;
      case 4.2:
        return <CrewDetails />;
      case 4.3:
        return <ProjectVendor />;
      case 5.1:
        return <Catering />;
      case 5.2:
        return <TravelTab />;
      case 5.3:
        return <HotelTab />;
      case 6.1:
        return <Scene />;
      case 6.2:
        return <ShotDivisionUser />;
      case 6.3:
        return <SceneContinuity />;
      case 6.4:
        return <SceneManager />;
      case 6.5:
        return <ShootingAttendance />;
      case 7:
        return <Expenses />;
      case 8:
        return <AllContact />;
      case 9:
        return <Accounts />;
      case 10:
        return <Roles />;
      default:
        return activeTab ? activeTab : <UploadScript />;
    }
  };

  const handleLogOut = () => {
    toast.success("Logged out! Come again soon.");
    localStorage.clear();
    navigate("/Login");
  };

  useEffect(() => {
    const storedActiveTab = ls.get("activeTab");

    if (storedActiveTab) {
      setActiveTab(parseFloat(storedActiveTab));
    } else {
      setActiveTab(1);
    }
  }, []);

  const handleTabClick = (tabId) => {
    if (tabId === 6 || tabId === 4 || tabId === 5) {
      const updateTab = tabs.find((tab) => tab.id === tabId);
      updateTab.showContent = !updateTab.showContent;
      setShowTab((prev) => ({
        ...prev,
        [tabId]: !prev[tabId],
      }));
    } else {
      setActiveTab(tabId);
      ls.set("activeTab", tabId);
    }
  };
  const userDetail = JSON.parse(localStorage.getItem("Crew_detail"));

  const fethcProject = () => {
    const projectDetails = JSON.parse(localStorage.getItem("Project_details"));
    setProjectDetails(projectDetails);
  };

  const fetchProjectDetails = () => {
    axios
      .get(`api/v1/projects/${projectId}`)
      .then((res) => {
        const project = res.data.project;
        localStorage.setItem(
          "Project_details",
          JSON.stringify({
            title: project.title,
            cinema_type: project.cinema_type,
          })
        );
        fethcProject();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fethcProject();
    const project = JSON.parse(localStorage.getItem("Project_details"));
    if (!project) {
      fetchProjectDetails();
    }
  }, []);

  const canViewTab = (tabId) => {
    if (userRole === "admin" && tabId >= 10) {
      return false;
    }
    if (
      userRole === "accountant" &&
      tabId !== 5 &&
      tabId !== 7 &&
      tabId !== 9
    ) {
      return false;
    } else if (userRole === "manager" && (tabId >= 8 || tabId === 5)) {
      return false;
    }
    return true;
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
  };

  return (
    <div className=" ">
      <button
        onClick={toggleView}
        className="fixed flex sm:hidden top-5 left-5 text-[#fbffff] bg-[#1d5b79] text-xl outline-none shadow-lg font-bold p-3 rounded-full hover:shadow-none border-2 border-[#fbffff] duration-200 ease-out sm:text-3xl sm:p-4 z-50"
      >
        {view ? <IoMenuOutline /> : <RxCross1 />}
      </button>
      <nav
        className={`${
          view
            ? "sm:w-20 scale-0 sm:scale-100 origin-left z-40"
            : "sm:w-60 w-11/12 z-40"
        } sm:h-screen h-full bg-[#1d5b79] p-5 duration-100 fixed rounded-br-2xl rounded-tr-2xl overflow-x-hidden overflow-y-auto no-scrollbar`}
      >
        <div>
          <button
            onClick={toggleView}
            className="bg-white text-[#1d5b79] w-8 h-8 items-center justify-center p-2 top-9 -right-4 border-[#1d5b79]  rounded-full  border-2 hidden sm:flex "
          >
            <span className="text-xl font-bold">
              {view ? <AiOutlineMenuUnfold /> : <AiOutlineMenuFold />}
            </span>
          </button>
          {projectDetails && (
            <div
              className={`${
                view && "scale-0 hidden"
              } m-5  flex-col sm:items-center sm:justify-center items-center justify-center mt-16 md:mt-10`}
            >
              <h1 className="text-[#fbffff] text-3xl sm:text-xl sm:mt-0 mt-4 font-bold">
                {projectDetails.title}
              </h1>
              <h1 className="text-[#fbffff] text-xl sm:text-lg sm:mt-1 mt-1 font-semibold">
                {projectDetails.cinema_type}
              </h1>
              {userRole ? (
                <div>
                  <h1 className="text-[#fbffff] text-sm sm:text-sm sm:mt-1 mt-1 font-semibold">
                    {userDetail.role} {userDetail.name}
                  </h1>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          )}
        </div>
        <ul className={`flex  flex-col mt-5`}>
          {tabs.map((tab) => {
            if (canViewTab(tab.id)) {
              return (
                <li key={tab.id}>
                  <p
                    onClick={() => handleTabClick(tab.id)}
                    className={`relative text-base flex mt-1 items-center cursor-pointer p-2 w-full sm:w-56 group ease-out ${
                      activeTab === tab.id
                        ? `${
                            view ? "sm:activeTab-2 gap-x-1" : "gap-x-2"
                          } bg-white text-[#1d5b79] rounded-s-3xl rounded-e-3xl sm:activeTab shadow-md `
                        : "text-[#FFFFFF]"
                    }`}
                  >
                    <span className="mr-2 text-xl">{tab.icon}</span>
                    <span
                      className={`${view && "hidden"} duration-300 ease-in-out`}
                    >
                      {tab.label}
                    </span>
                    {tab.dropIcon ? (
                      <span
                        className={`${view ? "ml-0" : "ml-1"} ${
                          activeTab === tab.id
                            ? "text-[#1d5b79]"
                            : "text-white rounded-full"
                        } ${showTab[tab.id] ? "rotate-180" : "-rotate-90"}`}
                      >
                        <FaChevronDown />
                      </span>
                    ) : null}
                  </p>
                  <AnimatePresence>
                    {showTab[tab.id] &&
                      tab.subTab &&
                      tab.subTab.map((subtab, index) => (
                        <motion.p
                          key={subtab.id}
                          initial="hidden"
                          animate="visible"
                          exit="exit"
                          variants={itemVariants}
                          transition={{ duration: 0.3, delay: index * 0.1 }}
                          onClick={() => handleTabClick(subtab.id)}
                          className={`relative text-base flex mt-1 items-center gap-x-2 cursor-pointer p-2  w-full sm:w-56 group ease-out ${
                            activeTab === subtab.id
                              ? `${
                                  view ? "sm:activeTab-2 ml-0" : "ml-0"
                                } bg-white text-[#1d5b79] rounded-s-3xl rounded-e-3xl sm:activeTab shadow-md origin-right scale-100 `
                              : "text-[#FFFFFF] ml-5 scale-95"
                          }`}
                        >
                          <span className="mr-2 text-xl">{subtab.icon}</span>
                          <span
                            className={`${
                              view && "hidden"
                            } duration-300 ease-in-out`}
                          >
                            {subtab.label}
                          </span>
                        </motion.p>
                      ))}
                  </AnimatePresence>
                </li>
              );
            }
          })}
        </ul>
      </nav>

      <div className="flex flex-row items-end justify-end w-full z-20 relative">
        {userRole ? (
          <></>
        ) : (
          <div>
            <button
              onClick={() => {
                localStorage.removeItem("Project_details");
                navigate("/project");
              }}
              className="text-2xl bg-[#a9c8e2] text-[#1d5b79] fixed right-16 top-5 p-2 shadow rounded-full"
            >
              <MdHomeFilled />
            </button>
          </div>
        )}
        <div>
          <button
            onClick={handleLogOut}
            className="text-2xl bg-[#a9c8e2] text-[#1d5b79] fixed right-5 top-5 p-2 shadow rounded-full"
          >
            <IoIosLogOut />
          </button>
        </div>
      </div>

      <div
        className={`h-screen p-7  ${
          !view
            ? "sm:ml-40 ml-0 duration-100 ease-in"
            : "ml-0 duration-100 ease-in"
        }`}
      >
        {renderContent()}
      </div>
    </div>
  );
}

export default ProjectOverview;

import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import Select from "react-select";
import * as Yup from "yup";
import moment from "moment-timezone";
import "moment/locale/en-in";
import FormikInput from "../Input/FormikInput";
import Date from "../Input/Date";
import Button from "../Button/Button";

function ShootingAttendanceForm({ schedules, onClose, onSubmit }) {
  const validationSchema = Yup.object().shape({
    schedule: Yup.object().required("Schedule is required"),
    call_time: Yup.string().required("Call time is required"),
    shoot_time: Yup.string().required("Shoot time is required"),
    actual_call_time: Yup.string().required("Actual call time is required"),
    roll_time: Yup.string().required("Roll time is required"),
    pack_up_time: Yup.string().required("Pack up time is required"),
    remarks: Yup.string().optional(),
  });
  const [selectedSchedule, setSelectedSchedule] = useState("");
  const convertToIST = (utcDateStr) => {
    return moment.utc(utcDateStr).tz("Asia/Kolkata").format("DD/MM/YYYY");
  };
  const convertToISTime = (utcDateStr) => {
    return moment.utc(utcDateStr).tz("Asia/Kolkata").format("hh:mm A");
  };
  const handleScheduleChange = (value, setFieldValue) => {
    setSelectedSchedule(value);
    setFieldValue("schedule", value);
  };

  const handleRollTimeChange = (value, setFieldValue) => {
    const formattedDate = value.format("ddd MMM DD YYYY HH:mm:ss [GMT]Z (z)");
    setFieldValue("roll_time", formattedDate);
  };

  return (
    <div>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center overflow-auto z-50">
        <div
          onClick={(e) => e.stopPropagation()}
          className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto"
        >
          <div className="bg-white p-4 sm:p-8 shadow-md max-w-5xl w-full mx-auto rounded">
            <div className="flex items-end justify-end">
              <button
                className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full "
                onClick={onClose}
              >
                <RxCross1 />
              </button>
            </div>
            <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
              Shooting Attendance
            </h2>
            <Formik
              initialValues={{
                schedule: selectedSchedule || "",
                call_time: selectedSchedule.call_time || "",
                shoot_time: selectedSchedule.shoot_time || "",
                actual_call_time: "",
                roll_time: "",
                pack_up_time: "",
                pack_up_date: "",
                remarks: "",
              }}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 sm:gap-4 mt-2 sm:mt-4">
                    <div>
                      <label className="block mb-2 text-sm text-gray-600 ">
                        Schedules
                      </label>
                      <Select
                        name="schedule"
                        options={schedules}
                        getOptionLabel={(option) =>
                          convertToIST(option.schedule_date) +
                          " " +
                          `(${option.title})`
                        }
                        getOptionValue={(option) => option.id}
                        onChange={(selectedOption) =>
                          handleScheduleChange(selectedOption, setFieldValue)
                        }
                      />
                      <ErrorMessage
                        component="div"
                        className="mt-1 text-red-500 text-sm"
                        name="schedule"
                      />
                    </div>
                    <div>
                      <Field
                        component={Date}
                        name="call_time"
                        label="Call time"
                        readOnly
                      />
                    </div>

                    <div>
                      <Field
                        component={Date}
                        name="shoot_time"
                        label="Shoot time"
                        readOnly
                      />
                    </div>
                    <div>
                      <Field
                        component={Date}
                        name="actual_call_time"
                        label="Actual call time"
                      />
                    </div>
                    <div>
                      <Field
                        component={Date}
                        name="roll_time"
                        label="Roll time"
                      />
                    </div>
                    <div>
                      <Field
                        component={Date}
                        name="pack_up_time"
                        label="Pack up time"
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"remarks"}
                        label={"Remarks"}
                        type={"text"}
                        placeholder={"Remarks"}
                      />
                    </div>
                  </div>
                  <div className="sm:mt-4 mt-2">
                    <Button name="Add" type={"submit"} />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShootingAttendanceForm;

import React, { useEffect, useRef, useState } from "react";
import OtpVerification from "../../components/Forms/OtpVerification";
import toast from "react-hot-toast";
import ArtistAccount from "../AccountPages/ArtistAccount";
import ExpenseAccount from "../AccountPages/ExpenseAccount";
import CrewAccount from "../AccountPages/CrewAccount";
import AccountSummary from "../AccountPages/AccountSummary";
import axios from "axios";
import VendorAccount from "../AccountPages/VendorAccount";
import BudgetPlan from "../AccountPages/BudgetPlan";
import SecureLS from "secure-ls";
import Spender from "../AccountPages/Spender";
import TaxSummary from "../AccountPages/TaxSummary";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useParams } from "react-router-dom";
import LocationAccounts from "../AccountPages/LocationAccounts";
import { HiddenProvider } from "../../Context/HiddenContent";
import EssentialAccount from "../AccountPages/EssentialAccount";
import { IoWarning } from "react-icons/io5";

function Accounts() {
  const [activeTab, setActiveTab] = useState(1);
  const [timer, setTimer] = useState(0);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [access, setAccess] = useState();
  const tabRef = useRef(null);
  const { projectId } = useParams();
  const ls = new SecureLS();

  const scrollLeft = () => {
    if (tabRef.current) {
      tabRef.current.scrollBy({ left: -100, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (tabRef.current) {
      tabRef.current.scrollBy({ left: 100, behavior: "smooth" });
    }
  };

  const tabs = [
    { id: 1, label: "Expense Accounts" },
    { id: 2, label: "Artist Accounts" },
    { id: 3, label: "Crew Accounts" },
    { id: 4, label: "Vendor Accounts" },
    { id: 5, label: "Location Accounts" },
    { id: 6, label: "Essentials Accounts" },
    { id: 7, label: "Budget Plan" },
    { id: 8, label: "Spender Accounts" },
    { id: 9, label: "Tax Summary" },
    { id: 10, label: "Accounts Summary" },
  ];

  const renderContent = () => {
    switch (activeTab) {
      case 1:
        return (
          <HiddenProvider>
            <ExpenseAccount />
          </HiddenProvider>
        );
      case 2:
        return (
          <HiddenProvider>
            <ArtistAccount />
          </HiddenProvider>
        );
      case 3:
        return (
          <HiddenProvider>
            <CrewAccount />
          </HiddenProvider>
        );
      case 4:
        return (
          <HiddenProvider>
            <VendorAccount />
          </HiddenProvider>
        );
      case 5:
        return (
          <HiddenProvider>
            <LocationAccounts />
          </HiddenProvider>
        );
      case 6:
        return (
          <HiddenProvider>
            <EssentialAccount />
          </HiddenProvider>
        );
      case 7:
        return (
          <HiddenProvider>
            <BudgetPlan />
          </HiddenProvider>
        );
      case 8:
        return (
          <HiddenProvider>
            <Spender />
          </HiddenProvider>
        );
      case 9:
        return (
          <HiddenProvider>
            <TaxSummary />
          </HiddenProvider>
        );
      case 10:
        return (
          <HiddenProvider>
            <AccountSummary />
          </HiddenProvider>
        );
      default:
        return (
          <HiddenProvider>
            <AccountSummary />
          </HiddenProvider>
        );
    }
  };

  const getAccess = () => {
    axios
      .get(`api/v1/projects/${projectId}/check_accounts_page_access`)
      .then((res) => {
        const access = res.data.accounts_access;
        setAccess(access);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAccess();
    const storedActiveTab = localStorage.getItem("AccountActiveTab");

    if (storedActiveTab) {
      setActiveTab(parseInt(storedActiveTab));
    } else {
      setActiveTab(1);
    }
  }, []);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    localStorage.setItem("AccountActiveTab", tabId);
  };

  return (
    <div>
      {!access && (
        <div className="flex items-center justify-center ">
          <div className="max-w-xl w-full text-yellow-500 border-2 border-dashed border-yellow-500 bg-yellow-100 rounded-2xl shadow-xl p-3 flex items-center justify-center flex-col">
            <div className="w-full flex justify-center mb-4">
              <div className="w-16 h-16 rounded-full bg-yellow-200 flex items-center justify-center">
                <IoWarning className="h-8 w-8 text-yellow-600" />
              </div>
            </div>
            <h1 className="text-2xl font-bold text-center text-yellow-800">
              Warning
            </h1>
            <div className="flex items-start justify-start px-3 w-full mt-5">
              <p className="text-yellow-700 text-center">
                You don't have access to this page. Please contact production
                house for access.
              </p>
            </div>
          </div>
        </div>
      )}
      {access && (
        <div>
          <nav
            ref={tabRef}
            className="bg-white w-auto border-b border-gray-300 flex flex-row items-start justify-start xl:ml-24 2xl:ml-24 md:ml-24 sm:ml-24 ml-0 xl:mt-14 mt-14 overflow-hidden overflow-x-auto no-scrollbar"
          >
            {tabs.map((tab, index) => (
              <h1
                key={index}
                onClick={() => handleTabClick(tab.id)}
                className={`mx-3 py-2 px-3 cursor-pointer group relative ${
                  activeTab === tab.id ? "border-[#1d5b79] border-b-[3px]" : ""
                }`}
              >
                {tab.label}
                <span className="absolute -bottom-1 left-0 w-0 h-[6px] bg-[#1d5b79] transition-all duration-300 group-hover:w-full" />
              </h1>
            ))}
            <div className="absolute opacity-100 sm:left-24 left-5 top-24">
              <button
                onClick={scrollLeft}
                className="bg-white bg-opacity-20 text-black p-2 rounded-full backdrop-blur-md shadow-lg"
              >
                <FaChevronLeft />
              </button>
            </div>
            <div className="absolute opacity-100 right-5 top-24">
              <button
                onClick={scrollRight}
                className="bg-white bg-opacity-20 text-black p-2 rounded-full backdrop-blur-md shadow-lg"
              >
                <FaChevronRight />
              </button>
            </div>
          </nav>
          <div>
            <div>{renderContent()}</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Accounts;

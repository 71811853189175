import React, { useContext, useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import { uid } from "uid";
import jsPDF from "jspdf";
import { BsDownload } from "react-icons/bs";
import axios from "axios";
import { IoSend } from "react-icons/io5";
import toast from "react-hot-toast";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import { FaEye } from "react-icons/fa6";
import SubRoleContext from "../SubRoleContext";
import logo from "../assets/image/Modak.png";
import moment from "moment-timezone";
import "moment/locale/en-in";
import { MdEdit } from "react-icons/md";
import { FaTrash } from "react-icons/fa";

function Sheet({
  close,
  sheetData,
  stopClose,
  onUpdate,
  onDelete,
  callSheetContact,
}) {
  const subRole = useContext(SubRoleContext);
  const [user, setUser] = useState("");
  const [crew, setCrew] = useState([]);
  const printedArtists = new Set();
  const UID = uid();
  const sceneRefs = useRef(null);
  const { projectId } = useParams();
  const formattedDateTime = format(
    new Date(sheetData.schedule_date),
    "MMMM dd, yyyy"
  );

  const openCombinedPdf = (e) => {
    e.stopPropagation();
    axios
      .get(`api/v1/schedules/${sheetData.id}/schedule_scene_script`)
      .then((response) => {
        window.open(response.data.url);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const downloadPdf = async () => {
    html2canvas(sceneRefs.current, { scale: 2 }).then((canvas) => {
      const pdf = new jsPDF("portrait", "mm", "a4");
      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", 0, 0, 210, 297);

      pdf.save(`Schedule_${UID}.pdf`);
    });
    //   .sort((a, b) => a.scene_number - b.scene_number)
    //   .map((scene) => {
    //     return new Promise((resolve) => {
    //       html2canvas(sceneRefs).then((canvas) => {
    //         const pdf = new jsPDF("portrait", "mm", "a4");
    //         const imgData = canvas.toDataURL("image/png");
    //         pdf.addImage(imgData, "PNG", 0, 0, 210, 297);

    //         pdf.setFontSize(10);
    //         pdf.setTextColor(0, 0, 255);
    //         pdf.textWithLink("Location Link", 175, 40, {
    //           url: `${sheetData.location.location_link}`,
    //         });
    //         pdf.textWithLink("Script Link", 175, 45, {
    //           url: `${scene.scene_url}`,
    //         });

    //         const sceneFilename = `Scene_${scene.scene_number}_Shoot_Schedule_${UID}.pdf`;
    //         pdf.save(sceneFilename);

    //         resolve();
    //       });
    //     });
    //   });

    // await Promise.all(capturePromises);
  };

  const fetchUser = () => {
    const userDetails = localStorage.getItem("Project_details");
    const OwnerDetail = JSON.parse(userDetails);
    setUser(OwnerDetail);
  };

  const sendMail = (id) => {
    const loadingToastId = toast.loading("Sending email...", {
      autoClose: false,
    });

    html2canvas(sceneRefs.current, { scale: 2 })
      .then((canvas) => {
        const pdf = new jsPDF("portrait", "mm", "a4");
        const imgData = canvas.toDataURL("image/png");
        pdf.addImage(imgData, "PNG", 0, 0, 210, 297);

        const pdfBlob = pdf.output("blob");

        const formData = new FormData();
        formData.append("schedule_id", id);
        formData.append("pdf", pdfBlob, "Schedule.pdf");
        formData.append("Content-Type", "application/pdf");

        axios
          .post(`api/v1/schedules/${id}/send_schedule_email`, formData)
          .then((response) => {
            toast.dismiss(loadingToastId);
            toast.success("Email sent successfully!");
          })
          .catch((error) => {
            console.error("Error sending PDF:", error);

            toast.dismiss(loadingToastId);

            toast.error("Failed to send email. Please try again.");
          });
      })
      .catch((error) => console.error(error));
  };

  const userDetail = localStorage.getItem("User_Details");
  const user_details = JSON.parse(userDetail);

  const fetchCrewDetails = () => {
    axios
      .get(`api/v1/projects/${projectId}/project_crews`)
      .then((response) => {
        setCrew(response.data.project_crews);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchCrewDetails();
    fetchUser();
  }, []);

  const convertToIST = (utcDateStr) => {
    return moment.utc(utcDateStr).tz("Asia/Kolkata").format("hh:mm A");
  };

  const artistScenesMap = sheetData.artists_details.details.reduce(
    (acc, artist) => {
      const scene = sheetData.scenes.find((s) => s.id === artist.scene_id);
      const sceneInfo = `${scene.scene_number}${scene.sub_scene}`;
      const costumeInfo = artist.artist_costume;

      if (!acc[artist.artist_id]) {
        acc[artist.artist_id] = { artist, scenes: [], costumes: [] };
      }
      acc[artist.artist_id].scenes.push(sceneInfo);
      acc[artist.artist_id].costumes.push(costumeInfo);

      return acc;
    },
    {}
  );
  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 overflow-y-auto"
      onClick={close}
    >
      <div className="flex flex-col items-center justify-center my-auto z-50 ">
        <div className="flex flex-row mt-5">
          {subRole === "user" ? (
            <></>
          ) : (
            <div className="flex justify-start items-start">
              <button
                onClick={downloadPdf}
                className=" bg-[#1d5b79]  text-white px-3 py-2 shadow-2xl rounded-full hover:bg-[#a9c8e2] ease-in-out duration-300 hover:shadow-none flex items-center"
              >
                <span className="text-xl">
                  <BsDownload />
                </span>
                <span className="font-semibold ml-2">Download Sheet</span>
              </button>

              <button
                onClick={() => sendMail(sheetData.id)}
                className=" bg-[#1d5b79] text-white px-3 py-2 ml-5 shadow-2xl rounded-full hover:bg-[#a9c8e2] ease-in-out duration-300 hover:shadow-none flex items-center"
              >
                <span className="text-xl mr-1">
                  <IoSend />
                </span>
                <span className="font-semibold">Mail Sheet</span>
              </button>
              <button
                onClick={openCombinedPdf}
                className=" bg-[#1d5b79] text-white px-3 py-2 ml-5 shadow-2xl rounded-full hover:bg-[#a9c8e2] ease-in-out duration-300 hover:shadow-none flex items-center"
              >
                <span className="text-xl mr-1">
                  <FaEye />
                </span>
                <span className="font-semibold ml-2">View Script</span>
              </button>
              <button
                onClick={() => onUpdate(sheetData)}
                className=" bg-[#1d5b79] text-white px-3 py-2 ml-5 shadow-2xl rounded-full hover:bg-[#a9c8e2] ease-in-out duration-300 hover:shadow-none flex items-center"
              >
                <span className="text-xl mr-1">
                  <MdEdit />
                </span>
                <span className="font-semibold ml-2">Update</span>
              </button>
              <button
                onClick={() => onDelete(sheetData)}
                className=" bg-[#1d5b79] text-white px-3 py-2 ml-5 shadow-2xl rounded-full hover:bg-[#a9c8e2] ease-in-out duration-300 hover:shadow-none flex items-center"
              >
                <span className="text-xl mr-1">
                  <FaTrash />
                </span>
                <span className="font-semibold ml-2">Delete</span>
              </button>
            </div>
          )}
        </div>
        <div
          ref={sceneRefs}
          onClick={stopClose}
          className="bg-white p-24 rounded shadow-md  min-h-[297mm] w-[210mm] mb-10 my-5 mr-5 text-[8px] font-sans"
        >
          <div className="border-black border">
            <section className="flex border-b border-gray-200">
              <div className="flex flex-col p-2 items-start justify-start border-r border-gray-200  h-auto w-1/3">
                <h1 className="font-bold  text-sm">{user.title}</h1>
                <h1 className="font-semibold text-gray-600 text-xs">
                  {user.cinema_type}
                </h1>
                <h1 className="font-bold text-sm">
                  {user_details.company_name}
                </h1>
              </div>
              <div className="flex flex-col items-start justify-start border-r border-gray-200 h-auto w-1/3">
                <div className="h-1/2 w-full flex items-center justify-center border-b">
                  <h1 className="font-bold text-xs">{sheetData.title}</h1>
                </div>
                <table className="w-full h-full">
                  <tr>
                    <th className="border-r border-b">Min temp</th>
                    <th className="border-r border-b">Max temp</th>
                    <th className="border-r border-b">Sunrise</th>
                    <th className="border-b">Sunset</th>
                  </tr>
                  <tbody className="divide-y divide-gray-100">
                    <tr>
                      <td className="border-r">
                        <div className="text-center">
                          {sheetData.min_temperature}
                        </div>
                      </td>
                      <td className="border-r">
                        <div className="text-center">
                          {sheetData.max_temperature}
                        </div>
                      </td>
                      <td className="border-r">
                        <div className="text-center">
                          {convertToIST(sheetData.sunrise)}
                        </div>
                      </td>
                      <td className="">
                        <div className="text-center">
                          {convertToIST(sheetData.sunset)}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col w-1/3">
                <div className="h-1/2 w-full flex items-center justify-center border-b">
                  <h1 className="font-bold text-xs">{formattedDateTime}</h1>
                </div>
                <table className="w-full h-full">
                  <tr>
                    <th className="border-r border-b">Unit call time</th>
                    <th className="border-r border-b">Call time</th>
                    <th className="border-r border-b">Shoot time</th>
                  </tr>
                  <tbody className="divide-y divide-gray-100">
                    <tr>
                      <td className="border-r">
                        <div className="text-center">
                          {convertToIST(sheetData.unit_call_time)}
                        </div>
                      </td>
                      <td className="border-r">
                        <div className="text-center">
                          {convertToIST(sheetData.call_time)}
                        </div>
                      </td>
                      <td>
                        <div className="text-center">
                          {convertToIST(sheetData.shoot_time)}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
            <section className="w-full">
              <h1 className="flex items-center justify-center border-y border-black bg-red-200 font-bold p-1 text-xs">
                Location
              </h1>
              <table className="w-full">
                <tr>
                  <th className="p-1 border-r border-b">Scene number</th>
                  <th className="p-1 border-r border-b">Location name</th>
                  <th className="p-1 border-r border-b">Location Address</th>
                  <th className="p-1 border-r border-b">
                    Location sub-location
                  </th>
                  <th className="p-1 border-r border-b">Location Brief</th>
                  <th className="p-1 border-b">Location Link</th>
                </tr>
                <tbody className="divide-y divide-gray-100">
                  {sheetData.scenes
                    .sort((a, b) => a.scene_number - b.scene_number)
                    .map((location) => (
                      <tr>
                        <td className="border-r p-1">
                          {location.scene_number}
                          {location.sub_scene}
                        </td>
                        <td className="border-r p-1">
                          {location.location_name}
                        </td>
                        <td className="border-r p-1">
                          <div
                            className="text-center"
                            style={{ width: "280px", textAlign: "justify" }}
                          >
                            {location.location_address}
                          </div>
                        </td>
                        <td className="border-r p-1">
                          {location.sub_location}
                        </td>
                        <td className="border-r p-1">
                          {location.location_type} , {location.shift}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </section>
            <section className="w-full">
              <h1 className="flex items-center justify-center border-y border-black text-xs bg-fuchsia-200 font-bold p-1">
                Breaks
              </h1>
              <table className="w-full">
                <tr>
                  <th className="p-1 border-r border-b">Breakfast</th>
                  <th className="p-1 border-r border-b">Lunch</th>
                  <th className="p-1 border-r border-b">High tea</th>
                  <th className="p-1 border-b">Dinner</th>
                </tr>
                <tbody className="divide-y divide-gray-100">
                  <tr>
                    <td className="border-r p-1">
                      <div className="text-center">
                        {convertToIST(sheetData.breakfast_time)}
                      </div>
                    </td>
                    <td className="border-r p-1">
                      <div className="text-center">
                        {convertToIST(sheetData.lunch_time)}
                      </div>
                    </td>
                    <td className="border-r p-1">
                      <div className="text-center">
                        {convertToIST(sheetData.snack_time)}
                      </div>
                    </td>
                    <td className="p-1">
                      <div className="text-center">
                        {convertToIST(sheetData.dinner_time)}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
            <section className="w-full">
              <h1 className="flex items-center justify-center border-y border-black bg-sky-200 text-xs font-bold p-1">
                Artists
              </h1>
              <table className="w-full">
                <tr>
                  <th className="p-1 border-r border-b">Scene no.</th>
                  <th className="p-1 border-r border-b">Artist</th>
                  <th className="p-1 border-r border-b">Character</th>
                  <th className="p-1 border-r border-b">Artist Role</th>
                  <th className="p-1 border-b border-r">Costume</th>
                  <th className="p-1 border-b border-r">Call time</th>
                  <th className="p-1 border-b border-r">Shoot time</th>
                  <th className="p-1 border-b border-r">Transportation</th>
                </tr>
                <tbody className="divide-y divide-gray-100">
                  {Object.values(artistScenesMap).map(
                    ({ artist, scenes, costumes }, index) => {
                      if (printedArtists.has(artist.artist_id)) {
                        return null; // Skip this artist if already printed
                      }
                      printedArtists.add(artist.artist_id);

                      return (
                        <tr key={index}>
                          <td className="border p-1">
                            <div
                              className="text-center"
                              style={{ width: "100px", textAlign: "justify" }}
                            >
                              {scenes.join(", ")}
                            </div>
                          </td>
                          <td className="border p-1">{artist.artist_name}</td>
                          <td className="border p-1">
                            {artist.character_name}
                          </td>
                          <td className="border p-1">{artist.artist_type}</td>
                          <td className="border p-1">
                            <div
                              className="text-center"
                              style={{ width: "100px", textAlign: "justify" }}
                            >
                              {costumes.join(", ")}
                            </div>
                          </td>
                          <td className="border p-1">
                            {convertToIST(artist.call_time)}
                          </td>
                          <td className="border p-1">
                            {convertToIST(artist.shoot_time)}
                          </td>
                          <td className="border p-1">
                            {artist.transportation}
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </section>

            <section className="w-full">
              <h1 className="flex items-center justify-center border-y text-xs border-black bg-lime-200 font-bold p-1">
                Crowd
              </h1>
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="p-1 border-r border-b">Scene no.</th>
                    <th className="p-1 border-r border-b">Crowd set</th>
                    <th className="p-1 border-r border-b">Crowd type</th>
                    <th className="p-1 border-r border-b">Crowd age</th>
                    <th className="p-1 border-r border-b">Number of crowd</th>
                    <th className="p-1 border-r border-b">Crowd costume</th>
                    <th className="p-1 border-r border-b">Call time</th>
                    <th className="p-1 border-r border-b">Shoot time</th>
                    <th className="p-1 border-b">Description</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-100">
                  {sheetData.crowd_details.details.map((detail, index) => {
                    const scene = sheetData.scenes.find(
                      (s) => s.id === detail.scene_id
                    );
                    return (
                      <tr>
                        <td className="border-r p-1">
                          {scene.scene_number} {scene.sub_scene}
                        </td>
                        <td className="border-r p-1">{index + 1}</td>
                        <td className="border-r p-1">{detail.crowdType}</td>
                        <td className="border-r p-1">{detail.crowdAge}</td>
                        <td className="border-r p-1">{detail.crowdNumber}</td>
                        <td className="border-r p-1">{detail.crowdCostume}</td>
                        <td className="border-r p-1">
                          {convertToIST(detail.call_time)}
                        </td>
                        <td className="border-r p-1">
                          {convertToIST(detail.shoot_time)}
                        </td>
                        <td className="p-1">
                          <div
                            style={{
                              width: "150px",
                              textAlign: "justify",
                            }}
                          >
                            {detail.description}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </section>

            <section className="w-full">
              <div className="flex flex-col">
                <div className="flex flex-row">
                  <div className="w-1/2">
                    <h1 className="flex items-center justify-center border-y border-black text-xs bg-rose-200 font-bold p-1">
                      Action sequence
                    </h1>
                    <table className="w-full">
                      <thead>
                        <tr>
                          <th className="p-1 border-r border-b">Scene no.</th>
                          <th className="p-1 border-r border-b">
                            Action director
                          </th>
                          <th className="p-1 border-r border-b">Action man</th>
                          <th className="p-1 border-r border-b">Costume</th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-100">
                        {sheetData.scenes
                          .sort((a, b) => a.scene_number - b.scene_number)
                          .map((scene) => (
                            <>
                              {scene.action_director && (
                                <tr>
                                  <td className="p-1 border-r">
                                    {scene.scene_number} {scene.sub_scene}
                                  </td>
                                  <td className="p-1 border-r">
                                    {scene.action_director}
                                  </td>
                                  <td className="p-1 border-r">
                                    {scene.no_of_action_man}
                                  </td>
                                  <td className="p-1 border-r">
                                    {scene.action_man_costume}
                                  </td>
                                </tr>
                              )}
                            </>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="w-1/2">
                    <h1 className="flex items-center justify-center border-y border-black text-xs bg-blue-200 font-bold p-1">
                      Dance sequence
                    </h1>
                    <table className="w-full">
                      <thead>
                        <tr>
                          <th className="p-1 border-r border-b">Scene no.</th>
                          <th className="p-1 border-r border-b">
                            Choreographer
                          </th>
                          <th className="p-1 border-r border-b">Dancers</th>
                          <th className="p-1 border-r border-b">Costume</th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-100">
                        {sheetData.scenes
                          .sort((a, b) => a.scene_number - b.scene_number)
                          .map((scene) => (
                            <>
                              {scene.dance_choreographer && (
                                <tr>
                                  <td className="p-1 border-r">
                                    {scene.scene_number} {scene.sub_scene}
                                  </td>
                                  <td className="p-1 border-r">
                                    {scene.dance_choreographer}
                                  </td>
                                  <td className="p-1 border-r">
                                    {scene.no_of_dancers}
                                  </td>
                                  <td className="p-1 border-r">
                                    {scene.dancers_costume}
                                  </td>
                                </tr>
                              )}
                            </>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </section>
            <section className="w-full">
              <h1 className="flex items-center justify-center border-y border-black bg-yellow-200 text-xs font-bold p-1">
                Extras
              </h1>
              <table className="w-full">
                <tr>
                  <th className="p-1 border-r border-b">Lights</th>
                  <td className="p-1 border-b">
                    {sheetData.scenes
                      .flatMap((data) => data.type_of_light)
                      .join(", ")}
                  </td>
                </tr>
                <tr>
                  <th className="p-1 border-r border-b">VFX</th>
                  <td className="p-1 border-b">
                    {sheetData.scenes.flatMap((data) => data.vfx).join(", ")}
                  </td>
                </tr>
                <tr>
                  <th className="p-1 border-r border-b">Production property</th>
                  <td className="p-1 border-b">
                    {sheetData.scenes
                      .flatMap((data) => data.production_team_property)
                      .join(", ")}
                  </td>
                </tr>
                <tr>
                  <th className="p-1 border-r border-b">Art property</th>
                  <td className="p-1 border-b">
                    {sheetData.scenes
                      .flatMap((data) => data.art_department_property)
                      .join(", ")}
                  </td>
                </tr>
                <tr>
                  <th className="p-1 border-r border-b">Make up property</th>
                  <td className="p-1 border-b">
                    {sheetData.scenes
                      .flatMap((data) => data.makeup_department_property)
                      .join(", ")}
                  </td>
                </tr>
                {sheetData.dolly_panther && (
                  <tr>
                    <th className="p-1 border-r border-b">Dolly Panther</th>
                    <td className="p-1 border-b">{sheetData.dolly_panther}</td>
                  </tr>
                )}
                {sheetData.drone && (
                  <tr>
                    <th className="p-1 border-r border-b">Drone</th>
                    <td className="p-1 border-b">{sheetData.drone}</td>
                  </tr>
                )}
                {sheetData.extra_camera_setup && (
                  <tr>
                    <th className="p-1 border-r border-b">
                      Extra camera setup
                    </th>
                    <td className="p-1 border-b">
                      {sheetData.extra_camera_setup}
                    </td>
                  </tr>
                )}
                {sheetData.sound && (
                  <tr>
                    <th className="p-1 border-r border-b">Sound</th>
                    <td className="p-1 border-b">{sheetData.sound}</td>
                  </tr>
                )}
                {sheetData.vehicle && (
                  <tr>
                    <th className="p-1 border-r border-b">Vehicle</th>
                    <td className="p-1 border-b">{sheetData.vehicle}</td>
                  </tr>
                )}
                {sheetData.vanity_van && (
                  <tr>
                    <th className="p-1 border-r border-b">Vanity van</th>
                    <td className="p-1 border-b">{sheetData.vanity_van}</td>
                  </tr>
                )}
                {sheetData.extra_equipment && (
                  <tr>
                    <th className="p-1 border-r border-b">Extra equipment</th>
                    <td className="p-1 border-b">
                      {sheetData.extra_equipment}
                    </td>
                  </tr>
                )}
              </table>
            </section>
            <section className="w-full">
              {sheetData.remarks && (
                <h1 className="flex items-center justify-center border-b bg-amber-100 font-bold p-1">
                  {sheetData.remarks}
                </h1>
              )}
            </section>
            <section className="w-full">
              <h1 className="flex items-center justify-center border-y border-black bg-violet-200 text-xs font-bold p-1">
                Contacts
              </h1>
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="p-1 border-r border-b">Name</th>
                    <th className="p-1 border-r border-b">Department</th>
                    <th className="p-1 border-r border-b">Contact</th>
                  </tr>
                </thead>
                {callSheetContact.map((data, index) => (
                  <tr key={index}>
                    <td className="p-1 border-r border-b">{data.full_name}</td>
                    <td className="p-1 border-r border-b">{data.category}</td>
                    <td className="p-1 border-r border-b">
                      {data.contact_number}
                    </td>
                  </tr>
                ))}
              </table>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sheet;

import React, { useContext } from "react";
import { AiFillEnvironment } from "react-icons/ai";
import Input from "../Input/Input";
import Button from "../Button/Button";
import { RxCross1 } from "react-icons/rx";
import { FieldArray, Form, Formik } from "formik";
import FormikInput from "../Input/FormikInput";
import { MdDelete } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";
import SubRoleContext from "../../SubRoleContext";
import FormikRadio from "../Input/FormikRadio";

function LocationUpdateForm({
  handleUpdateBackdropClick,
  selectedLocation,
  updateLocation,
  validationSchema,
}) {
  const subRole = useContext(SubRoleContext);
  return (
    <div className="fixed inset-0 flex items-center justify-center overflow-auto bg-black bg-opacity-50 z-50">
      <div className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto ">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-4xl w-full mx-auto">
          <div className="flex items-end justify-end">
            <button
              className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full "
              onClick={handleUpdateBackdropClick}
            >
              <RxCross1 />
            </button>
          </div>
          <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
            Update Location
          </h2>

          <div>
            <div>
              <Formik
                initialValues={{
                  name: selectedLocation.name || "",
                  address: selectedLocation.address || "",
                  location_link: selectedLocation.location_link || "",
                  owner_number: selectedLocation.owner_number || "",
                  owner_name: selectedLocation.owner_name || "",
                  owner_bank_account: selectedLocation.owner_bank_account || "",
                  owner_bank_name: selectedLocation.owner_bank_name || "",
                  owner_bank_branch: selectedLocation.owner_bank_branch || "",
                  number_of_days: selectedLocation.number_of_days || "",
                  ifsc_code: selectedLocation.ifsc_code || "",
                  rent: selectedLocation.rent || "",
                  remarks: selectedLocation.remarks || "",
                  gst_allowed: selectedLocation.gst_allowed || false,
                  tds_allowed: selectedLocation.tds_allowed || false,
                  gst_percentage: selectedLocation.gst_percentage || "",
                  tds_percentage: selectedLocation.tds_percentage || "",
                  gst_number: selectedLocation.gst_number || "",
                  pan_number: selectedLocation.pan_number || "",
                  sublocation: {
                    sublocations:
                      selectedLocation.sublocation.sublocations || [],
                  },
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm }) => {
                  updateLocation(selectedLocation.id, values, { resetForm });
                }}
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 sm:gap-4 mt-2 sm:mt-4">
                      <div>
                        <FormikInput
                          label={"Location name"}
                          type={"text"}
                          placeholder={selectedLocation.name}
                          name={"name"}
                        />
                      </div>
                      <div>
                        <FormikInput
                          label={"Location address"}
                          placeholder={selectedLocation.address}
                          type={"text"}
                          name={"address"}
                        />
                      </div>
                      <div>
                        <FormikInput
                          label={"Google map location link"}
                          placeholder={selectedLocation.location_link}
                          type={"text"}
                          name={"location_link"}
                        />
                      </div>
                    </div>

                    {subRole === "manager" ? (
                      <></>
                    ) : (
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                        <div>
                          <FormikInput
                            type={"text"}
                            name={"owner_name"}
                            label={"Owner name"}
                            placeholder={"Jack"}
                          />
                        </div>
                        <div>
                          <FormikInput
                            type={"tel"}
                            name={"owner_number"}
                            label={"Owner contact number"}
                            placeholder={"9854xxxxxx"}
                          />
                        </div>
                        <div>
                          <FormikInput
                            type={"number"}
                            name={"owner_bank_account"}
                            label={"Owner bank account"}
                            min={0}
                            placeholder={"05465xxxx"}
                          />
                        </div>
                        <div>
                          <FormikInput
                            type={"text"}
                            name={"owner_bank_name"}
                            label={"Owner bank name"}
                            placeholder={"MoneyZone"}
                          />
                        </div>
                        <div>
                          <FormikInput
                            type={"text"}
                            name={"owner_bank_branch"}
                            label={"Owner bank branch"}
                            placeholder={"Ahmedabad"}
                          />
                        </div>
                        <div>
                          <FormikInput
                            type={"text"}
                            name={"ifsc_code"}
                            label={"IFSC code"}
                            placeholder={"SBIN0011513"}
                          />
                        </div>
                        <div>
                          <FormikInput
                            type={"number"}
                            name={"rent"}
                            min={0}
                            label={"Location rent"}
                            placeholder={"12xxxx"}
                          />
                        </div>
                        <div>
                          <FormikInput
                            type={"number"}
                            name={"number_of_days"}
                            label={"Number of days"}
                            min={0}
                            placeholder={"12"}
                          />
                        </div>
                        <div>
                          <FormikInput
                            type={"text"}
                            name={"pan_number"}
                            label={"PAN number"}
                            placeholder={"ABCDE5974F"}
                          />
                        </div>
                        <div className="flex flex-col">
                          <div>
                            <label className="block mb-2 text-sm text-gray-600 ">
                              Does this location require GST ?
                            </label>
                            <div className="flex flex-row">
                              <div>
                                <FormikRadio
                                  id={"yes"}
                                  label={"Yes"}
                                  value={true}
                                  onChange={() =>
                                    setFieldValue("gst_allowed", true)
                                  }
                                  name={"gst_allowed"}
                                />
                              </div>
                              <div className="ml-2">
                                <FormikRadio
                                  id={"no"}
                                  value={false}
                                  onChange={() =>
                                    setFieldValue("gst_allowed", false)
                                  }
                                  label={"No"}
                                  name={"gst_allowed"}
                                />
                              </div>
                            </div>
                          </div>
                          {values.gst_allowed ? (
                            <>
                              <div className="mt-2">
                                <FormikInput
                                  name={"gst_number"}
                                  label={"GST number"}
                                  placeholder={"22AAAAA0000A1Z5"}
                                  type={"text"}
                                />
                              </div>
                            </>
                          ) : (
                            <div></div>
                          )}
                        </div>
                        <div className="flex flex-col">
                          <label className="block mb-2 text-sm text-gray-600 ">
                            TDS ?
                          </label>
                          <div className="flex flex-row">
                            <div>
                              <FormikRadio
                                id={"yes"}
                                label={"Yes"}
                                value={true}
                                onChange={() =>
                                  setFieldValue("tds_allowed", true)
                                }
                                name={"tds_allowed"}
                              />
                            </div>
                            <div className="ml-2">
                              <FormikRadio
                                id={"no"}
                                value={false}
                                onChange={() => {
                                  setFieldValue("tds_allowed", false);
                                }}
                                label={"No"}
                                name={"tds_allowed"}
                              />
                            </div>
                          </div>
                          {values.tds_allowed ? (
                            <>
                              <div className="mt-2">
                                <FormikInput
                                  name={"tds_percentage"}
                                  label={"TDS(%)"}
                                  type={"number"}
                                  placeholder={"TDS"}
                                  min={0}
                                />
                              </div>
                            </>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="mt-2 sm:mt-4">
                      <FormikInput
                        type={"text"}
                        name={"remarks"}
                        label={"Remarks"}
                        placeholder={selectedLocation.remarks}
                      />
                    </div>
                    <FieldArray name="sublocation.sublocations">
                      {({ push, remove }) => (
                        <div>
                          <label className="block mb-2 text-sm text-gray-600 mt-2">
                            Sub location
                          </label>
                          <div>
                            <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 sm:gap-4 mt-2 sm:mt-4">
                              {values.sublocation.sublocations.map(
                                (detail, index) => (
                                  <div key={index} className="flex flex-row">
                                    <div className="w-full">
                                      <FormikInput
                                        type={"text"}
                                        name={`sublocation.sublocations.${index}`}
                                        placeholder={
                                          detail ? detail : "Sub Location"
                                        }
                                      />
                                    </div>
                                    <div className="ml-2 mt-1">
                                      <button
                                        type="button"
                                        onClick={() => remove(index)}
                                        className="bg-[#e70a18] p-2 text-lg text-[#fbffff] rounded-full mt-2"
                                      >
                                        <MdDelete />
                                      </button>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                          <button
                            type="button"
                            className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full mt-5"
                            onClick={() => push("")}
                          >
                            <FaPlus />
                          </button>
                        </div>
                      )}
                    </FieldArray>
                    <div className="mt-2 sm:mt-4">
                      <Button name={"Update Location"} type={"submit"} />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocationUpdateForm;

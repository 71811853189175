import React, {
  Suspense,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import DeleteModal from "../../Modal/DeleteModal";
import LocationForm from "../../components/Forms/LocationForm";
import LocationUpdateForm from "../../components/UpdateForm/LocationUpdateForm";
import Button from "../../components/Button/Button";
import * as Yup from "yup";
import LocationTable from "../../components/Table/LocationTable";
import SubRoleContext from "../../SubRoleContext";
import Loader from "../../Loader/loader";
import ErrorPage from "../ErrorPage";
import DownloadButton from "../../components/Button/DownloadButton";
import { useReactToPrint } from "react-to-print";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { uid } from "uid";

function Location() {
  const subRole = useContext(SubRoleContext);
  const validationSchema =
    subRole === "manager"
      ? Yup.object().shape({
          name: Yup.string()
            .min(3, "Location name must have at least 3 characters")
            .required("location name is required"),
          address: Yup.string().required("Location address is required"),
          location_link: Yup.string().required("Location link is required"),
        })
      : Yup.object().shape({
          name: Yup.string()
            .min(3, "Location name must have at least 3 characters")
            .required("location name is required"),
          address: Yup.string().required("Location address is required"),
          location_link: Yup.string().required("Location link is required"),
          owner_name: Yup.string().required("Owner name is required"),
          owner_number: Yup.string()
            .matches(
              /^[0-9]{10}$/,
              "Contact number must have exactly 10 digits"
            )
            .required("Owner number is required"),
          owner_bank_account: Yup.string()
            .matches(/^[0-9]+$/, "Bank account must be only digits")
            .required("Bank account number is required"),
          owner_bank_name: Yup.string().required("Bank name is required"),
          owner_bank_branch: Yup.string().required("Bank branch is required"),
          number_of_days: Yup.number()
            .positive("Number of days must be a positive number")
            .integer("Number of days must be an integer")
            .required("Number of days is required"),
          ifsc_code: Yup.string()
            .matches(
              /^[A-Za-z]{4}[0][A-Za-z0-9]{6}$/,
              "Invalid IFSC code. Format should be XXXX0YYYYYY"
            )
            .required("IFSC code is required"),
          pan_number: Yup.string()
            .required("PAN number is required")
            .matches(
              /^[A-Z]{5}[0-9]{4}[A-Z]$/,
              "Invalid PAN number. Format should be ABCDE1234F"
            ),
          gst_allowed: Yup.boolean().required("Select if GST is required"),
          gst_number: Yup.string().when("gst_allowed", {
            is: true,
            then: (schema) =>
              schema
                .matches(
                  /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Z]{1}[0-9A-Za-z]{1}$/,
                  "Invalid GST number"
                )
                .required("GST number is required"),
            otherwise: (schema) => schema.notRequired(),
          }),
          tds_allowed: Yup.boolean().required("Select if TDS is required"),
          tds_percentage: Yup.number().when("tds_allowed", {
            is: true,
            then: (schema) =>
              schema
                .typeError("TDS must be a number")
                .positive("TDS must be a positive number")
                .required("TDS is required"),
            otherwise: (schema) => schema.notRequired(),
          }),
        });
  const [view, setView] = useState(true);
  const [isModal, setIsModal] = useState(false);
  const [updateView, setUpdateView] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState([]);
  const [locationtoDelete, setLocationToDelete] = useState(null);
  const [error, setError] = useState(false);
  const contentToPrint = useRef();
  const UID = uid();
  const { projectId } = useParams();

  const openModal = (id) => {
    const locationDetails = location.find((location) => location.id == id);

    setLocationToDelete(locationDetails);
    setIsModal(true);
  };

  const closeModal = () => {
    setLocationToDelete(null);
    setIsModal(false);
  };

  const viewHandler = () => {
    setView(false);
  };

  const onClose = () => {
    setView(true);
  };
  const stopClose = (e) => {
    e.stopPropagation();
  };

  const handleBackdropClick = () => {
    onClose();
  };

  const updatViewHandler = (locations) => {
    setSelectedLocation(locations);
    setUpdateView(false);
  };
  const onUpdateClose = () => {
    setUpdateView(true);
  };
  const handleUpdateBackdropClick = () => {
    onUpdateClose();
  };
  const fetchLocationDetails = () => {
    axios
      .get(`api/v1/projects/${projectId}/locations`)
      .then((response) => {
        setLoading(true);
        setLocation(response.data.locations);
      })
      .catch((err) => {
        setError(true);
        console.log(err);
      });
  };

  useEffect(() => {
    fetchLocationDetails();
  }, []);

  const addLocationHandler = (value, { setSubmitting, resetForm }) => {
    const loaderToast = toast.loading("Adding location...", {
      autoClose: false,
    });
    axios
      .post(`api/v1/projects/${projectId}/locations`, {
        location: {
          name: value.name,
          address: value.address,
          location_link: value.location_link,
          owner_name: value.owner_name,
          owner_bank_account: value.owner_bank_account,
          owner_bank_name: value.owner_bank_name,
          owner_bank_branch: value.owner_bank_branch,
          ifsc_code: value.ifsc_code,
          number_of_days: value.number_of_days,
          owner_number: value.owner_number,
          rent: value.rent,
          remarks: value.remarks,
          sublocation: value.sublocation,
          tds_allowed: value.tds_allowed,
          gst_allowed: value.gst_allowed,
          gst_percentage: 18,
          gst_number: value.gst_number,
          pan_number: value.pan_number,
          tds_percentage: value.tds_percentage,
        },
      })
      .then((response) => {
        handleBackdropClick();
        if (response.status === 200) {
          toast.dismiss(loaderToast);
          toast.success("Location Added successfully!!!!");
          fetchLocationDetails();
          resetForm();
        }
      })
      .catch((err) => {
        toast.dismiss(loaderToast);
        toast.error(
          err.response.data.message || err.message || err.response.data.error
        );
        handleBackdropClick();
        console.log(err);
      });
    setSubmitting(false);
  };

  const updateLocation = (id, values, { resetForm }) => {
    const loaderToast = toast.loading("Updating location...", {
      autoClose: false,
    });
    axios
      .put(`api/v1/locations/${id}`, {
        location: {
          name: values.name,
          address: values.address,
          location_link: values.location_link,
          owner_number: values.owner_number,
          owner_name: values.owner_name,
          owner_bank_account: values.owner_bank_account,
          owner_bank_name: values.owner_bank_name,
          owner_bank_branch: values.owner_bank_branch,
          ifsc_code: values.ifsc_code,
          number_of_days: values.number_of_days,
          rent: values.rent,
          remarks: values.remarks,
          sublocation: values.sublocation,
          tds_allowed: values.tds_allowed,
          gst_allowed: values.gst_allowed,
          gst_percentage: values.gst_percentage,
          gst_number: values.gst_number,
          pan_number: values.pan_number,
          tds_percentage: values.tds_percentage,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          toast.dismiss(loaderToast);
          toast.success("Location updated succesfully!!");
          resetForm();
          handleUpdateBackdropClick();
          fetchLocationDetails();
        }
      })
      .catch((error) => {
        toast.dismiss(loaderToast);
        toast.error(error.message);
        console.log(error);
      });
  };

  const deleteScene = (id) => {
    const loaderToast = toast.loading("Deleting location...", {
      autoClose: false,
    });
    axios
      .delete(`api/v1/locations/${id}`)
      .then((response) => {
        toast.success("Location deleted succesfully!!");
        toast.dismiss(loaderToast);
        fetchLocationDetails();
      })
      .catch((err) => {
        console.log(err);
        toast.dismiss(loaderToast);
        toast.error(
          err.response.data.message || err.message || err.response.data.error
        );
      });
  };

  const downloadPdf = () => {
    handlePrint();
  };
  const handlePrint = useReactToPrint({
    documentTitle: "Print This Document",
    content: () => contentToPrint.current,
  });

  const handleDownloadExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(
      location.map((data) => {
        return {
          "Location name": data.name,
          "Location address": data.address,
          "Owner name": data.owner_name,
          "Owner contact number": data.owner_number,
          "Owner bank A/C": data.owner_bank_account,
          "Owner bank name": data.owner_bank_name,
          "Owner bank branch": data.owner_bank_branch,
          "Owner bank IFSC": data.ifsc_code,
          "Owner GST number": data.gst_number,
          "Owner PAN number": data.pan_number,
          Rent: data.rent,
          "No of days": data.number_of_days,
        };
      })
    );
    const colWidths = Array(Object.keys(location[0]).length).fill({
      wpx: 90,
    });
    worksheet["!cols"] = colWidths;
    const rowHeights = Array(location.length + 1).fill({ hpx: 20 });
    worksheet["!rows"] = rowHeights;
    XLSX.utils.book_append_sheet(workbook, worksheet, "Requests");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blobData = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });

    saveAs(blobData, `Location_${UID}.xlsx`);
  };

  return (
    <div>
      {error ? (
        <ErrorPage />
      ) : (
        <div>
          {!loading ? (
            <Loader />
          ) : (
            <div className="flex flex-col sm:flex sm:flex-col p-5">
              <div className="flex sm:flex-row flex-col items-center justify-between sm:mt-8 mt-0">
                <div className="flex sm:items-start items-center justify-center sm:justify-start xl:ml-20 2xl:ml-20 sm:ml-20 ml-0 sm:mt-0 mt-8">
                  <Button clickFunction={viewHandler} name={"Add Location"} />
                </div>
                <div className="flex flex-row items-end justify-end sm:mt-0 mt-2">
                  <div>
                    <DownloadButton
                      onClick={downloadPdf}
                      name={"Download PDF"}
                    />
                  </div>
                  <div className="ml-4">
                    {subRole === "manager" ? (
                      <></>
                    ) : (
                      <DownloadButton
                        name={"Download excel"}
                        onClick={handleDownloadExcel}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-center xl:ml-20 2xl:ml-20 lg:ml-20 md:ml-20 sm:ml-20 ml-0">
                <div className="w-full mt-5">
                  <LocationTable
                    location={location}
                    updatViewHandler={updatViewHandler}
                    openModal={openModal}
                    contentToPrint={contentToPrint}
                  />
                </div>
              </div>
            </div>
          )}

          {!view && (
            <LocationForm
              validationSchema={validationSchema}
              handleBackdropClick={handleBackdropClick}
              stopClose={stopClose}
              addLocationHandler={addLocationHandler}
            />
          )}

          {!updateView && selectedLocation && (
            <LocationUpdateForm
              handleUpdateBackdropClick={handleUpdateBackdropClick}
              stopClose={stopClose}
              updateLocation={updateLocation}
              selectedLocation={selectedLocation}
              validationSchema={validationSchema}
            />
          )}
          {isModal && (
            <DeleteModal
              title={"Remove Location"}
              message={`Do you want to remove ${locationtoDelete.name}`}
              address={`Address: ${locationtoDelete.address}`}
              onDelete={() => {
                deleteScene(locationtoDelete.id);
                closeModal();
              }}
              onClose={closeModal}
              icon={true}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default Location;

import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import * as Yup from "yup";
import { FaPlus } from "react-icons/fa";
import axios from "axios";
import { TiTick } from "react-icons/ti";
import { MdDelete, MdModeEdit } from "react-icons/md";
import FormikInput from "../../../../components/Input/FormikInput";
import Button from "../../../../components/Button/Button";
import toast from "react-hot-toast";

function DepartmentModal({ setOpen, selectedDepartment }) {
  const validationSchema = Yup.object().shape({
    subDepartment: Yup.string().min(3, "Sub department is required"),
  });
  const [view, setView] = useState(false);
  const [subDepartment, setSubDepartment] = useState([]);
  const [updView, setUpdView] = useState(false);
  const [deleteView, setDeleteView] = useState(false);
  const [selectedSubDepartment, setSelectedSubDepartment] = useState("");

  const handleUpdateModal = (id) => {
    const selectedField = subDepartment.find((detail) => detail.id === id);
    setUpdView(!updView);
    setSelectedSubDepartment(selectedField);
  };

  const handleDeleteView = (id) => {
    const selectedField = subDepartment.find((detail) => detail.id === id);
    setDeleteView(!deleteView);
    setSelectedSubDepartment(selectedField);
  };

  const DeleteSubDepartment = () => {
    axios
      .delete(
        `api/v1/departments/${selectedDepartment.id}/subdepartments/${selectedSubDepartment.id}`
      )
      .then((response) => {
        if (response.status === 200) {
          getSubDepartment();
          toast.success(response.data.message);
          console.log(response);
        }
      })
      .catch((error) => console.log(error.message));
  };

  const updateSubDepartment = (values, { setSubmitting, resetForm }) => {
    axios
      .put(
        `api/v1/departments/${selectedDepartment.id}/subdepartments/${selectedSubDepartment.id}`,
        {
          name: values.subDepartment || selectedSubDepartment.name,
        }
      )
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setUpdView(false);
          resetForm();
          getSubDepartment();
          toast.success(response.data.message);
        }
      })
      .catch((error) => console.log(error.message));
    setSubmitting(false);
  };

  const getSubDepartment = () => {
    axios
      .get(`api/v1/departments/${selectedDepartment.id}/subdepartments`)
      .then((response) => {
        console.log(response);
        setSubDepartment(response.data.subdepartments);
      })
      .catch((err) => console.log(err.message));
  };
  const addSubDepartmenthandler = (values, { setSubmitting, resetForm }) => {
    axios
      .post(`api/v1/departments/${selectedDepartment.id}/subdepartments`, {
        name: values.subDepartment,
      })
      .then((response) => {
        console.log(response);
        getSubDepartment();
        setView(false);
        resetForm();
      })
      .catch((error) => console.log(error.message));
    setSubmitting(false);
  };

  useEffect(() => {
    getSubDepartment();
  }, []);

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black overflow-auto bg-opacity-50">
      <div className="flex items-center justify-center z-50">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-2xl w-full mx-auto">
          <div className="flex items-end justify-end">
            <button
              className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full "
              onClick={() => setOpen(false)}
            >
              <RxCross1 />
            </button>
          </div>
          <h2 className=" text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
            {selectedDepartment.name}'s Sub Department
          </h2>
          <div>
            <ul>
              {subDepartment.map((detail, index) => (
                <li key={index} className="text-lg m-3 flex">
                  <span className="mt-1 mr-2 text-[#1d5b79]">
                    <TiTick />
                  </span>
                  {updView && detail.id === selectedSubDepartment.id ? (
                    <span className="flex flex-row mr-2">
                      <Formik
                        initialValues={{
                          subDepartment: "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={updateSubDepartment}
                      >
                        <Form>
                          <FormikInput
                            placeholder={selectedSubDepartment.name}
                            type={"text"}
                            name={"subDepartment"}
                          />
                          <div className="mt-4">
                            <Button name={"Update"} type={"submit"} />
                          </div>
                        </Form>
                      </Formik>
                    </span>
                  ) : (
                    <span>{detail.name}</span>
                  )}
                  {deleteView && detail.id === selectedSubDepartment.id ? (
                    <span className="ml-5">
                      <button
                        onClick={() => setDeleteView(false)}
                        className="p-1 mr-4 text-lg text-green-600 border-none hover:shadow-none rounded-full outline-none bg-green-50 shadow-lg duration-300 ease-in"
                      >
                        <RxCross1 />
                      </button>
                      <button
                        onClick={DeleteSubDepartment}
                        className="p-1 mr-4 text-lg text-red-600 border-none hover:shadow-none rounded-full outline-none bg-red-50 shadow-lg duration-300 ease-in"
                      >
                        <TiTick />
                      </button>
                    </span>
                  ) : (
                    <span className="ml-5">
                      <button
                        onClick={() => handleUpdateModal(detail.id)}
                        className="p-1 mr-4 text-lg text-green-600 border-none hover:shadow-none rounded-full outline-none bg-green-50 shadow-lg duration-300 ease-in"
                      >
                        <MdModeEdit />
                      </button>
                      <button
                        onClick={() => handleDeleteView(detail.id)}
                        className="p-1 mr-4 text-lg text-red-600 border-none hover:shadow-none rounded-full outline-none bg-red-50 shadow-lg duration-300 ease-in"
                      >
                        <MdDelete />
                      </button>
                    </span>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className="flex flex-row">
            <h2 className="mt-1 mr-5 ">Add Sub Department:</h2>
            <button
              onClick={() => setView(true)}
              className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full "
            >
              <FaPlus />
            </button>
          </div>
          {view && (
            <div className="flex flex-col mt-5">
              <Formik
                initialValues={{
                  subDepartment: "",
                }}
                validationSchema={validationSchema}
                onSubmit={addSubDepartmenthandler}
              >
                <Form>
                  <FormikInput
                    label={"Sub Department"}
                    placeholder={"Sub Department"}
                    type={"text"}
                    name={"subDepartment"}
                  />
                  <div className="mt-4">
                    <Button name={"Add"} type={"submit"} />
                  </div>
                </Form>
              </Formik>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DepartmentModal;

import React from "react";
import Navbar from "../LandingPages/Components/Navbar";
import Footer from "../LandingPages/Components/Footer";

function TermsAndCondition() {
  const termsAndConditions = [
    {
      title: "Introduction",
      content:
        "Welcome to CinemaPro! These Terms and Conditions govern your use of our subscription-based film production management app. By accessing or using our service, you agree to be bound by these terms.",
    },
    {
      title: "Subscription Terms",
      content:
        "Our app offers an annual subscription. Payment is due at the start of each subscription period, and the subscription will automatically renew unless cancelled. You can manage or cancel your subscription at any time through your account settings.",
    },
    {
      title: "User Responsibilities",
      content:
        "You agree to use the app responsibly, not sharing your account or login credentials with others, and adhering to any data protection laws applicable in your region.",
    },
    {
      title: "Account and Security",
      content:
        "Users are responsible for securing their account and password. We will not be liable for any loss resulting from unauthorized account access.",
    },
    {
      title: "Modifications to Service",
      content:
        "We reserve the right to modify or discontinue any feature without prior notice. We are not liable for any modifications or temporary unavailability of services.",
    },
    {
      title: "Limitation of Liability",
      content:
        "We are not liable for any data loss, interruptions, or damages resulting from your use of the app, to the fullest extent permitted by law.",
    },
    {
      title: "Intellectual Property",
      content:
        "All content, logos, and designs are owned by CinemaPro. You agree not to duplicate or distribute any part of the app without permission.",
    },
    {
      title: "Termination of Access",
      content:
        "We reserve the right to suspend or terminate accounts for any breach of these terms or non-payment.",
    },
    {
      title: "Contact Us",
      content:
        "If you have questions, please contact us at support@softcodingsolutions.com.",
    },
  ];

  return (
    <div>
      <Navbar />
      <section className="bg-[#1d5b79] py-20 text-white">
        <div className="container mx-auto px-4 md:px-8">
          <div className="max-w-3xl text-center">
            <h1 className="text-4xl font-bold mb-4">Terms & Conditions</h1>
          </div>
        </div>
      </section>
      <section>
        <div className="container mx-auto py-10 items-center justify-center flex md:px-0 px-5">
          <div className="max-w-3xl text-start">
            {termsAndConditions.map((data, index) => (
              <div>
                <h2 className="text-2xl font-bold mb-4">
                  {index + 1}. {data.title}
                </h2>
                <p className="text-lg mb-8">{data.content}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default TermsAndCondition;

import React, { useEffect, useState } from "react";
import { FaCalendar, FaChevronDown, FaChevronUp, FaEye } from "react-icons/fa";
import { AnimatePresence, motion } from "framer-motion";
import moment from "moment-timezone";
import "moment/locale/en-in";
import InstallmentDetails from "../../Modal/InstallmentDetails";
import SecureLS from "secure-ls";
import NoDue from "../NoDue";
import axios from "axios";
import usePagination from "../Pagination/usePagination";
import Pagination from "../Pagination/Pagination";

function TeamArtistTable({ detail }) {
  const [selectedArtist, setSelectedArtist] = useState("");
  const [open, setOpen] = useState(false);
  const [expandedArtist, setExpandedArtist] = useState({});
  const [user, setUser] = useState("");
  const [viewNoDue, setViewNoDue] = useState(false);
  const ls = new SecureLS();
  const user_id = ls.get("User id");

  const itemVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
  };

  const toggleView = (id) => {
    setExpandedArtist((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };
  const convertToIST = (utcDateStr) => {
    return moment.utc(utcDateStr).tz("Asia/Kolkata").format("DD-MM-YYYY");
  };
  const handleOpenInstallmentModal = (installment) => {
    setSelectedArtist({ ...installment });
    setOpen(true);
  };

  const fetchUserDetails = () => {
    axios
      .get(`api/v1/users/find_user?user_id=${user_id}`)
      .then((response) => {
        setUser(response.data.user);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const handleOpenNoDue = (data) => {
    setViewNoDue(true);
    setSelectedArtist({ ...data });
  };

  const { currentItems, currentPage, handlePageChange, totalPages } =
    usePagination(detail, 10);
  return (
    <div>
      <div className="flex flex-col h-full">
        <div className="w-full mx-auto bg-white rounded-sm border border-gray-200">
          <header className="flex sm:flex-row flex-col justify-between px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold text-gray-800">Artists</h2>
            <div className="flex items-start justify-start sm:justify-end sm:items-center sm:mt-0 mt-2 relative">
              <Pagination
                currentPage={currentPage}
                handlePageChange={handlePageChange}
                totalPages={totalPages}
              />
            </div>
          </header>
          <div className="p-3">
            <div className="overflow-x-auto">
              <table className="table-auto w-full">
                <thead className="text-xs font-semibold uppercase text-gray-600 bg-[#eceff1]">
                  <tr>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Project name
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Artist name</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Payment cycle
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Gender</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Amount paid</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Amount Due</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Installments
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        More details
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className="text-sm divide-y divide-gray-300">
                  {currentItems.map((artist, index) => (
                    <React.Fragment key={index}>
                      <tr>
                        <td className="p-2 whitespace-nowrap">
                          {artist.project.title}
                        </td>
                        <td className="p-2 whitespace-nowrap">
                          {artist.artist.artist_name}
                        </td>
                        <td className="p-2 whitespace-nowrap">
                          {artist.amount_type}
                        </td>
                        <td className="p-2">
                          <div className="text-left">
                            {artist.artist.gender}
                          </div>
                        </td>

                        <td className="p-2">
                          <div className="text-left">{artist.amount_paid}</div>
                        </td>
                        <td className="p-2">
                          <div className="text-left">{artist.amount_due}</div>
                        </td>
                        <td className="p-2 whitespace-nowrap">
                          <div className="text-center">
                            <button
                              onClick={() => handleOpenInstallmentModal(artist)}
                              className="font-semibold hover:underline"
                            >
                              View
                            </button>
                          </div>
                        </td>
                        <td className="p-2">
                          <button
                            onClick={() => toggleView(artist.id)}
                            className="text-xl border-none outline-none text-[#fbffff] bg-[#1d5b79] rounded-full p-1"
                          >
                            {expandedArtist[artist.id] ? (
                              <FaChevronUp size={15} />
                            ) : (
                              <FaChevronDown size={15} />
                            )}
                          </button>
                        </td>
                      </tr>
                      <AnimatePresence>
                        {expandedArtist[artist.id] && (
                          <motion.tr
                            key={artist.id}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            variants={itemVariants}
                            className="bg-gray-50"
                            transition={{ duration: 0.3, delay: 0.1 }}
                          >
                            <td colSpan={8} className="p-4">
                              <div className="grid sm:grid-cols-4 grid-cols-1 gap-2 sm:gap-4">
                                <div className="flex flex-row">
                                  <h2 className="mt-1 text-sm font-semibold text-[#1d5b79]">
                                    Agreement:
                                  </h2>
                                  <button
                                    onClick={() =>
                                      window.open(artist.agreement_doc_url)
                                    }
                                    className="ml-1  text-lg bg-blue-100 text-blue-600 p-2 rounded-full cursor-pointer"
                                  >
                                    <FaEye />
                                  </button>
                                </div>
                                <div className="flex flex-row">
                                  <h2 className="mt-1 text-sm font-semibold text-[#1d5b79]">
                                    NDA:
                                  </h2>
                                  <button
                                    onClick={() =>
                                      window.open(artist.nda_doc_url)
                                    }
                                    className="ml-1  text-lg bg-blue-100 text-blue-600 p-2 rounded-full cursor-pointer"
                                  >
                                    <FaEye />
                                  </button>
                                </div>
                                {parseInt(artist.amount_due) === 0 && (
                                  <div className="flex flex-row">
                                    <h2 className="mt-1 text-sm font-semibold text-[#1d5b79]">
                                      No Due certificate:
                                    </h2>
                                    <button
                                      onClick={() => handleOpenNoDue(artist)}
                                      className="ml-1  text-lg bg-blue-100 text-blue-600 p-2 rounded-full cursor-pointer"
                                    >
                                      <FaEye />
                                    </button>
                                  </div>
                                )}
                              </div>
                              <div className="flex flex-col mt-4">
                                <div className="flex items-start justify-start">
                                  <h2 className="text-base font-semibold text-[#1d5b79] uppercase">
                                    Artist Attendance
                                  </h2>
                                </div>
                                {artist.amount_type === "Per Day" ? (
                                  <div>
                                    <div className="grid sm:grid-cols-6 grid-cols-3 gap-2 sm:gap-4 mt-2">
                                      {artist.attendances[0] &&
                                        artist.attendances[0].number_of_dates.map(
                                          (date, index) => (
                                            <h2
                                              key={index}
                                              className="flex flex-row text-sm text-[#1d5b79]"
                                            >
                                              <FaCalendar className="mr-2 mt-1" />{" "}
                                              {date}
                                            </h2>
                                          )
                                        )}
                                    </div>
                                  </div>
                                ) : (
                                  <div>
                                    {artist.attendances[0] && (
                                      <div className="grid sm:grid-cols-2 grid-cols-2 gap-2 sm:gap-4 mt-2">
                                        <div className="flex flex-row">
                                          <h2 className="text-sm font-semibold text-[#1d5b79]">
                                            Start date:
                                          </h2>
                                          <h2 className="text-sm ml-1">
                                            {convertToIST(
                                              artist.attendances[0].start_date
                                            )}
                                          </h2>
                                        </div>
                                        <div className="flex flex-row">
                                          <h2 className="text-sm font-semibold text-[#1d5b79]">
                                            End date:
                                          </h2>
                                          <h2 className="text-sm ml-1">
                                            {convertToIST(
                                              artist.attendances[0].end_date
                                            )}
                                          </h2>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </td>
                          </motion.tr>
                        )}
                      </AnimatePresence>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {open && (
        <InstallmentDetails
          setOpen={setOpen}
          data={selectedArtist}
          name={selectedArtist.artist.artist_name}
        />
      )}
      {viewNoDue && (
        <NoDue
          data={selectedArtist}
          name={selectedArtist.artist.artist_name}
          onClose={() => setViewNoDue(false)}
          role={"Artist"}
          user={user}
        />
      )}
    </div>
  );
}

export default TeamArtistTable;

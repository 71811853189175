import React, { useContext } from "react";
import { AiFillEnvironment } from "react-icons/ai";
import Input from "../Input/Input";
import Button from "../Button/Button";
import { RxCross1 } from "react-icons/rx";
import { FieldArray, Form, Formik } from "formik";
import FormikInput from "../Input/FormikInput";
import { MdDelete } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";
import SubRoleContext from "../../SubRoleContext";
import FormikRadio from "../Input/FormikRadio";

function LocationForm({
  validationSchema,
  handleBackdropClick,
  addLocationHandler,
}) {
  const subRole = useContext(SubRoleContext);
  return (
    <div>
      <div className="fixed inset-0 flex items-center justify-center overflow-auto bg-black bg-opacity-50 z-50">
        <div className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto ">
          <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-4xl w-full mx-auto">
            <div className="flex items-end justify-end">
              <button
                className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full "
                onClick={handleBackdropClick}
              >
                <RxCross1 />
              </button>
            </div>
            <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
              Location
            </h2>

            <div>
              <Formik
                validationSchema={validationSchema}
                initialValues={{
                  name: "",
                  address: "",
                  location_link: "",
                  owner_name: "",
                  owner_number: "",
                  owner_bank_account: "",
                  owner_bank_name: "",
                  owner_bank_branch: "",
                  number_of_days: "",
                  ifsc_code: "",
                  rent: "",
                  remarks: "",
                  gst_allowed: false,
                  tds_allowed: false,
                  gst_percentage: "",
                  tds_percentage: "",
                  gst_number: "",
                  pan_number: "",
                  sublocation: {
                    sublocations: [],
                  },
                }}
                onSubmit={addLocationHandler}
              >
                {({ values, setFieldValue, errors }) => (
                  <Form>
                    {console.log(errors)}
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 sm:gap-4 mt-2 sm:mt-4">
                      <div>
                        <FormikInput
                          type={"text"}
                          label={"Location name"}
                          name={"name"}
                          placeholder={"Statue of Unity"}
                        />
                      </div>
                      <div>
                        <FormikInput
                          type={"text"}
                          name={"address"}
                          label={"Location address"}
                          placeholder={
                            "Sardar Sarovar Dam, Statue of Unity Rd, Kevadia, Gujarat 393155"
                          }
                        />
                      </div>
                      <div>
                        <FormikInput
                          type={"text"}
                          name={"location_link"}
                          label={"Google map location link"}
                          placeholder={
                            "https://maps.app.goo.gl/sEV6PrjuYEBXjH389"
                          }
                        />
                      </div>
                    </div>
                    {subRole === "manager" ? (
                      <></>
                    ) : (
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                        <div>
                          <FormikInput
                            type={"text"}
                            name={"owner_name"}
                            label={"Owner name"}
                            placeholder={"Jack"}
                          />
                        </div>
                        <div>
                          <FormikInput
                            type={"tel"}
                            name={"owner_number"}
                            label={"Owner contact number"}
                            placeholder={"9854xxxxxx"}
                          />
                        </div>
                        <div>
                          <FormikInput
                            type={"number"}
                            name={"owner_bank_account"}
                            label={"Owner bank account"}
                            min={0}
                            placeholder={"05465xxxx"}
                          />
                        </div>
                        <div>
                          <FormikInput
                            type={"text"}
                            name={"owner_bank_name"}
                            label={"Owner bank name"}
                            placeholder={"MoneyZone"}
                          />
                        </div>
                        <div>
                          <FormikInput
                            type={"text"}
                            name={"owner_bank_branch"}
                            label={"Owner bank branch"}
                            placeholder={"Ahmedabad"}
                          />
                        </div>
                        <div>
                          <FormikInput
                            type={"text"}
                            name={"ifsc_code"}
                            label={"IFSC code"}
                            placeholder={"SBIN0011513"}
                          />
                        </div>
                        <div>
                          <FormikInput
                            type={"number"}
                            name={"rent"}
                            min={0}
                            label={"Location rent"}
                            placeholder={"12xxxx"}
                          />
                        </div>
                        <div>
                          <FormikInput
                            type={"number"}
                            name={"number_of_days"}
                            label={"Number of days"}
                            min={0}
                            placeholder={"12"}
                          />
                        </div>
                        <div>
                          <FormikInput
                            type={"text"}
                            name={"pan_number"}
                            label={"PAN number"}
                            placeholder={"ABCDE5974F"}
                          />
                        </div>
                        <div className="flex flex-col">
                          <div>
                            <label className="block mb-2 text-sm text-gray-600 ">
                              Does this location require GST ?
                            </label>
                            <div className="flex flex-row">
                              <div>
                                <FormikRadio
                                  id={"yes"}
                                  label={"Yes"}
                                  value={true}
                                  onChange={() =>
                                    setFieldValue("gst_allowed", true)
                                  }
                                  name={"gst_allowed"}
                                />
                              </div>
                              <div className="ml-2">
                                <FormikRadio
                                  id={"no"}
                                  value={false}
                                  onChange={() =>
                                    setFieldValue("gst_allowed", false)
                                  }
                                  label={"No"}
                                  name={"gst_allowed"}
                                />
                              </div>
                            </div>
                          </div>
                          {values.gst_allowed ? (
                            <>
                              <div className="mt-2">
                                <FormikInput
                                  name={"gst_number"}
                                  label={"GST number"}
                                  placeholder={"22AAAAA0000A1Z5"}
                                  type={"text"}
                                />
                              </div>
                            </>
                          ) : (
                            <div></div>
                          )}
                        </div>
                        <div className="flex flex-col">
                          <label className="block mb-2 text-sm text-gray-600 ">
                            TDS ?
                          </label>
                          <div className="flex flex-row">
                            <div>
                              <FormikRadio
                                id={"yes"}
                                label={"Yes"}
                                value={true}
                                onChange={() =>
                                  setFieldValue("tds_allowed", true)
                                }
                                name={"tds_allowed"}
                              />
                            </div>
                            <div className="ml-2">
                              <FormikRadio
                                id={"no"}
                                value={false}
                                onChange={() => {
                                  setFieldValue("tds_allowed", false);
                                }}
                                label={"No"}
                                name={"tds_allowed"}
                              />
                            </div>
                          </div>
                          {values.tds_allowed ? (
                            <>
                              <div className="mt-2">
                                <FormikInput
                                  name={"tds_percentage"}
                                  label={"TDS(%)"}
                                  type={"number"}
                                  min={0}
                                  placeholder={"TDS"}
                                />
                              </div>
                            </>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="mt-2 sm:mt-4">
                      <FormikInput
                        type={"text"}
                        name={"remarks"}
                        label={"Remarks"}
                        placeholder={"Stay careful with the walls."}
                      />
                    </div>
                    <FieldArray name="sublocation.sublocations">
                      {({ push, remove }) => (
                        <div>
                          <label className="block mb-2 text-sm text-gray-600 mt-2">
                            Sub location
                          </label>
                          <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 sm:gap-4 mt-2 sm:mt-4">
                            {values.sublocation.sublocations.map(
                              (detail, index) => (
                                <div key={index} className="flex flex-row">
                                  <div className="w-full">
                                    <FormikInput
                                      type={"text"}
                                      name={`sublocation.sublocations.${index}`}
                                      placeholder={"Sub location"}
                                    />
                                  </div>
                                  <div className="ml-2 mt-1">
                                    <button
                                      type="button"
                                      onClick={() => remove(index)}
                                      className="bg-[#e70a18] p-2 text-lg text-[#fbffff] rounded-full mt-2"
                                    >
                                      <MdDelete />
                                    </button>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                          <button
                            type="button"
                            className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full mt-5"
                            onClick={() => push("")}
                          >
                            <FaPlus />
                          </button>
                        </div>
                      )}
                    </FieldArray>

                    <div className="mt-2 sm:mt-4">
                      <Button type={"submit"} name={"Add Location"} />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocationForm;

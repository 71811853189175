import React, { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import ShootingAttendanceForm from "../../components/Forms/ShootingAttendanceForm";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import ShootingAttendanceTable from "../../components/Table/ShootingAttendanceTable";
import toast from "react-hot-toast";
import ErrorPage from "../ErrorPage";
import Loader from "../../Loader/loader";
import UpdateShootingAttendanceForm from "../../components/UpdateForm/UpdateShootingAttendanceForm";
import DeleteModal from "../../Modal/DeleteModal";

function ShootingAttendance() {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(false);
  const [update, setUpdate] = useState(false);
  const [Delete, setDelete] = useState(false);
  const [selectedAttendance, setSelectedAttendance] = useState({});
  const [schedules, setSchedules] = useState([]);
  const [shootAttendance, setShootAttendance] = useState([]);
  const { projectId } = useParams();

  const getShootDetails = () => {
    axios
      .get(`api/v1/projects/${projectId}/get_schedules`)
      .then((response) => {
        setSchedules(response.data.schedules);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getShootDetails();
  }, []);

  const handleAddShootingAttendance = (values, { resetForm }) => {
    const loader = toast.loading("Adding attendance ...");
    let actual_call_time = moment(values.actual_call_time.$d);
    let roll_time = moment(values.roll_time.$d);
    let pack_up_time = moment(values.pack_up_time.$d);

    const schedule_date = moment(values.schedule.schedule_date);

    actual_call_time = schedule_date.clone().set({
      hours: actual_call_time.hours(),
      minutes: actual_call_time.minutes(),
      seconds: actual_call_time.seconds(),
      milliseconds: 0,
    });

    roll_time = schedule_date.clone().set({
      hours: roll_time.hours(),
      minutes: roll_time.minutes(),
      seconds: roll_time.seconds(),
      milliseconds: 0,
    });

    if (values.schedule && values.schedule.schedule_date) {
      let scheduleDate = moment(values.schedule.schedule_date);

      if (pack_up_time.hours() < 12) {
        scheduleDate = scheduleDate.add(1, "days");
      }

      pack_up_time = scheduleDate.set({
        hours: pack_up_time.hours(),
        minutes: pack_up_time.minutes(),
        seconds: pack_up_time.seconds(),
        milliseconds: 0,
      });
    } else {
      toast.dismiss(loader);
      toast.error("Issue with schedule date");
      console.error("schedules.date is undefined");
      return;
    }
    axios
      .post(`/api/v1/schedule_attendances`, {
        schedule_attendance: {
          schedule_id: parseInt(values.schedule.id),
          project_id: parseInt(projectId),
          call_time: actual_call_time.format("DD MMMM YYYY HH:mm"),
          roll_time: roll_time.format("DD MMMM YYYY HH:mm"),
          packup_time: pack_up_time.format("DD MMMM YYYY HH:mm"),
          remarks: values.remarks,
        },
      })
      .then((res) => {
        toast.success("Schedule Attendance done.");
        fetchShootingAttendance();
        resetForm();
        setView(false);
      })
      .catch((Err) => {
        toast.error(Err.response.data.message || Err.message);
        console.log(Err);
      })
      .finally(toast.dismiss(loader));
  };

  const fetchShootingAttendance = () => {
    axios
      .get(`/api/v1/schedule_attendances`, {
        params: {
          project_id: parseInt(projectId),
        },
      })
      .then((res) => {
        setShootAttendance(res.data);
      })
      .catch((Err) => {
        console.log(Err);
        setError(true);
      })
      .finally(setLoading(true));
  };

  useEffect(() => {
    fetchShootingAttendance();
  }, []);

  const updateShootingAttendance = (values, { resetForm }) => {
    const loader = toast.loading("Updating attendance ...");
    let actual_call_time = moment(
      values.actual_call_time.$d
        ? values.actual_call_time.$d
        : values.actual_call_time
    );
    let roll_time = moment(
      values.roll_time.$d ? values.roll_time.$d : values.roll_time
    );
    let pack_up_time = moment(
      values.pack_up_time.$d ? values.pack_up_time.$d : values.pack_up_time
    );

    const schedule_date = moment(values.schedule.schedule_date);

    actual_call_time = schedule_date.clone().set({
      hours: actual_call_time.hours(),
      minutes: actual_call_time.minutes(),
      seconds: actual_call_time.seconds(),
      milliseconds: 0,
    });

    roll_time = schedule_date.clone().set({
      hours: roll_time.hours(),
      minutes: roll_time.minutes(),
      seconds: roll_time.seconds(),
      milliseconds: 0,
    });

    if (values.schedule && values.schedule.schedule_date) {
      let scheduleDate = moment(values.schedule.schedule_date);

      if (pack_up_time.hours() < 12) {
        scheduleDate = scheduleDate.add(1, "days");
      }

      pack_up_time = scheduleDate.set({
        hours: pack_up_time.hours(),
        minutes: pack_up_time.minutes(),
        seconds: pack_up_time.seconds(),
        milliseconds: 0,
      });
    } else {
      toast.dismiss(loader);
      toast.error("Issue with schedule date");
      console.error("schedules.date is undefined");
      return;
    }

    axios
      .put(`api/v1/schedule_attendances/${selectedAttendance.id}`, {
        schedule_attendance: {
          schedule_id: parseInt(selectedAttendance?.schedule?.id),
          project_id: parseInt(projectId),
          call_time: actual_call_time.format("DD MMMM YYYY HH:mm"),
          roll_time: roll_time.format("DD MMMM YYYY HH:mm"),
          packup_time: pack_up_time.format("DD MMMM YYYY HH:mm"),
          remarks: values.remarks,
        },
      })
      .then((res) => {
        setUpdate(false);
        fetchShootingAttendance();
        resetForm();
        toast.success("Schedule Updated Succesfully.");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message || err.message);
      })
      .finally(toast.dismiss(loader));
  };

  const handleDeleteShoot = () => {
    const loader = toast.loading("Delete Attendance ...");
    axios
      .delete(`api/v1/schedule_attendances/${selectedAttendance.id}`)
      .then((res) => {
        toast.success("Shooting Attendance deleted succesfully.");
        setDelete(false);
        fetchShootingAttendance();
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message || err.message);
      })
      .finally(toast.dismiss(loader));
  };

  const handleUpdateView = (data) => {
    setUpdate(true);
    setSelectedAttendance({ ...data });
  };

  const handleDeleteView = (data) => {
    setDelete(true);
    setSelectedAttendance({ ...data });
  };

  const filteredSchedules = schedules.filter(
    (data) => !shootAttendance.some((det) => data.id === det?.schedule?.id)
  );

  return (
    <div>
      {error ? (
        <ErrorPage />
      ) : (
        <div>
          {!loading ? (
            <Loader />
          ) : (
            <div className="flex flex-col sm:flex sm:flex-col p-5">
              <div className="flex sm:flex-row flex-col sm:items-start items-center justify-center sm:justify-start xl:ml-20 2xl:ml-20 md:ml-20 sm:ml-20 ml-0 sm:mt-0 mt-8">
                <Button
                  name={"Add Attendance"}
                  clickFunction={() => setView(true)}
                />
              </div>
              <div className="flex items-center justify-center  xl:ml-20 2xl:ml-20 lg:ml-20 md:ml-20 sm:ml-20 ml-0">
                <div className="w-full mt-5">
                  <ShootingAttendanceTable
                    data={shootAttendance}
                    updateView={handleUpdateView}
                    deleteView={handleDeleteView}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {view && (
        <ShootingAttendanceForm
          schedules={filteredSchedules}
          onClose={() => setView(false)}
          onSubmit={handleAddShootingAttendance}
        />
      )}
      {update && (
        <UpdateShootingAttendanceForm
          onClose={() => setUpdate(false)}
          selectedAttendance={selectedAttendance}
          onSubmit={updateShootingAttendance}
        />
      )}
      {Delete && (
        <DeleteModal
          title={"Remove Attendance"}
          icon={true}
          message={`Remove attendance of ${selectedAttendance?.schedule?.title} ?`}
          onClose={() => setDelete(false)}
          onDelete={handleDeleteShoot}
        />
      )}
    </div>
  );
}

export default ShootingAttendance;

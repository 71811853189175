import React, { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import HotelForm from "../../components/Forms/HotelForm";
import axios from "axios";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import ErrorPage from "../ErrorPage";
import Loader from "../../Loader/loader";
import AccomodationTable from "./AccomodationTable";
import AccommodationUpateForm from "../../components/UpdateForm/AccommodationUpateForm";
import DeleteModal from "../../Modal/DeleteModal";

function Hotel() {
  const [view, setView] = useState(false);
  const [accommodations, setAccomodations] = useState([]);
  const [selectedAccommodation, setSelectedAccommodation] = useState({});
  const [update, setUpdate] = useState(false);
  const [Delete, setDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { projectId } = useParams();

  const handleAddAccommodation = (value, { resetForm }) => {
    const loader = toast.loading("Adding accommodation...");
    axios
      .post(`api/v1/projects/${projectId}/accommodations`, {
        accommodation: {
          name: value.name,
          accommodation_type: value.accommodation_type,
          address: value.address,
          country: value.country,
          state: value.state,
          city: value.city,
          rent_amount: value.rent,
          location_link: value.location_link,
          ...(value.accommodation_type === "Flat"
            ? { rooms_attributes: value.flats }
            : { rooms_attributes: value.rooms }),
        },
      })
      .then((res) => {
        if (res.status === 201) {
          toast.dismiss(loader);
          toast.success(res.data.message);
          resetForm();
          setView(false);
          fetchAccommodationDetails();
        }
      })
      .catch((err) => {
        console.log(err);
        toast.dismiss(loader);
        toast.error(err.message);
      });
  };

  const handleUpdateAccommodation = (value, { resetForm }) => {
    const loader = toast.loading("Upating Accommodation ....");
    axios
      .put(
        `api/v1/projects/${projectId}/accommodations/${selectedAccommodation.id}`,
        {
          accommodation: {
            name: value.name,
            address: value.address,
            country: value.country,
            state: value.state,
            city: value.city,
            rent_amount: value.rent,
            location_link: value.location_link,
            ...(value.accommodation_type === "Flat"
              ? { rooms_attributes: value.flats }
              : { rooms_attributes: value.rooms }),
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setUpdate(false);
          fetchAccommodationDetails();
          resetForm();
          toast.dismiss(loader);
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.dismiss(loader);
        toast.error(err.message);
      });
  };

  const handleAccommodationDelete = () => {
    const loader = toast.loading("Deleting Accommodation ...");
    axios
      .delete(
        `api/v1/projects/${projectId}/accommodations/${selectedAccommodation.id}`
      )
      .then((res) => {
        if (res.status === 200) {
          toast.dismiss(loader);
          toast.success(res.data.message);
          setDelete(false);
          fetchAccommodationDetails();
        }
      })
      .catch((err) => {
        console.log(err);
        toast.dismiss(loader);
        toast.error(err.message);
      });
  };

  const fetchAccommodationDetails = () => {
    axios
      .get(`api/v1/projects/${projectId}/accommodations`)
      .then((res) => {
        setAccomodations(res.data.accommodations);
        setLoading(true);
      })
      .catch((err) => {
        setError(true);
        console.log(err);
      });
  };

  useEffect(() => {
    fetchAccommodationDetails();
  }, []);

  const handleUpdateAccommodationView = (data) => {
    setSelectedAccommodation({ ...data });
    setUpdate(true);
  };

  const handleDeleteAccommodationView = (data) => {
    setSelectedAccommodation({ ...data });
    setDelete(true);
  };

  return (
    <div>
      {error ? (
        <ErrorPage />
      ) : (
        <div>
          {!loading ? (
            <Loader />
          ) : (
            <React.Fragment>
              <div className="flex flex-col sm:flex sm:flex-col p-5">
                <div className="flex sm:items-start items-center justify-center sm:justify-start xl:ml-20 2xl:ml-20 sm:ml-20 ml-0 sm:mt-0 mt-5">
                  <div>
                    <Button
                      name={"Add Accommodation"}
                      clickFunction={() => setView(true)}
                    />
                  </div>
                </div>
                <div className="flex items-center justify-center  xl:ml-20 2xl:ml-20 lg:ml-20 md:ml-20 sm:ml-20 ml-0">
                  <div className="w-full mt-5">
                    <AccomodationTable
                      data={accommodations}
                      deleteView={handleDeleteAccommodationView}
                      fetchData={fetchAccommodationDetails}
                      updateView={handleUpdateAccommodationView}
                    />
                  </div>
                </div>
              </div>
              {view && (
                <HotelForm
                  onClose={() => setView(false)}
                  onSubmit={handleAddAccommodation}
                />
              )}
              {update && (
                <AccommodationUpateForm
                  data={selectedAccommodation}
                  onClose={() => setUpdate(false)}
                  onSubmit={handleUpdateAccommodation}
                />
              )}
              {Delete && (
                <DeleteModal
                  icon={true}
                  title={"Remove Accomodation"}
                  message={`Do you wanna remove ${selectedAccommodation.name} ?`}
                  onClose={() => setDelete(false)}
                  onDelete={handleAccommodationDelete}
                />
              )}
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  );
}

export default Hotel;

import React, { useEffect, useState } from "react";
import { AiOutlineFileSearch } from "react-icons/ai";
import { BiSolidTrashAlt } from "react-icons/bi";
import ExpenseAccountTable from "../../components/AccountComponents/ExpenseAccountTable";
import { useParams } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import Loader from "../../Loader/loader";
import ErrorPage from "../ErrorPage";
import SecureLS from "secure-ls";

function ExpenseAccount() {
  const [data, setData] = useState([]);
  const [rejectionReason, setRejectionReason] = useState("");
  const [loading, setLoading] = useState(false);
  const [openRejectModal, setRejectModal] = useState(false);
  const [error, setError] = useState(false);
  const { projectId } = useParams();
  const ls = new SecureLS();
  const project_crew_id = ls.get("project_crew_id");

  const handleViewBill = (billUrl) => {
    window.open(billUrl);
  };

  const handleApproveExpense = (id) => {
    const loader = toast.loading("Approving...", { autoClose: false });
    axios
      .post(`api/v1/expenses/${id}/approval`, {
        project_id: projectId,
      })
      .then((response) => {
        toast.dismiss(loader);
        toast.success("Aprroved");
        getExpensesData();
      })
      .catch((error) => {
        toast.dismiss(loader);
        toast.error(error.message);
      });
  };

  const handleRejectionExpense = (id) => {
    const loader = toast.loading("Rejecting...", { autoClose: false });
    axios
      .post(`api/v1/expenses/${id}/rejection_approval`, {
        rejection_reason: rejectionReason,
      })
      .then((response) => {
        toast.success("Rejected");
        toast.dismiss(loader);
        setRejectModal(false);
        getExpensesData();
      })
      .catch((error) => {
        toast.dismiss(loader);
        toast.error(error.message);
      });
  };

  const getExpensesData = () => {
    axios
      .get(
        `api/v1/projects/${projectId}/expenses/project_expenses?project_crew_id=${project_crew_id}`
      )
      .then((response) => {
        setLoading(true);
        setData(response.data.expenses);
      })
      .catch((error) => {
        setError(true);
        toast.error(error.message);
      });
  };

  useEffect(() => {
    getExpensesData();
  }, []);

  return (
    <>
      {error ? (
        <ErrorPage />
      ) : (
        <div>
          {!loading ? (
            <Loader />
          ) : (
            <div className="flex flex-col sm:flex sm:flex-col p-5">
              <div className="flex sm:items-start items-center justify-center sm:justify-start xl:ml-20 2xl:ml-20 md:ml-20 sm:ml-20 ml-0 sm:mt-0 mt-8">
                <h1 className="text-3xl font-semibold sm:tracking-widest tracking-tight">
                  Expense Account
                </h1>
              </div>
              <div className="flex items-center justify-center xl:ml-20 2xl:ml-20 lg:ml-20 md:ml-20 sm:ml-20 ml-0">
                <div className="w-full mt-5">
                  <ExpenseAccountTable
                    data={data}
                    handleViewBill={handleViewBill}
                    handleApproveExpense={handleApproveExpense}
                    handleRejectionExpense={handleRejectionExpense}
                    setRejectionReason={setRejectionReason}
                    openRejectModal={openRejectModal}
                    setRejectModal={setRejectModal}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default ExpenseAccount;

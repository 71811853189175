import React, { useEffect, useState } from "react";
import CateringAccount from "./CateringAccount";
import AccommodationAccount from "./AccommodationAccount";
import { motion } from "framer-motion";
import TravelAccount from "./TravelAccount";

function EssentialAccount() {
  const [activeTab, setActiveTab] = useState(1);
  const tabs = [
    { id: 1, name: "Catering" },
    { id: 2, name: "Accommodation" },
    { id: 3, name: "Travel" },
  ];

  const renderContent = () => {
    switch (activeTab) {
      case 1:
        return <CateringAccount />;
      case 2:
        return <AccommodationAccount />;
      case 3:
        return <TravelAccount />;
      default:
        return <CateringAccount />;
    }
  };

  useEffect(() => {
    const storedActiveTab = localStorage.getItem("EssentialAccountActiveTab");

    if (storedActiveTab) {
      setActiveTab(parseInt(storedActiveTab));
    } else {
      setActiveTab(1);
    }
  }, []);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    localStorage.setItem("EssentialAccountActiveTab", tabId);
  };
  return (
    <div className="mt-5">
      <div className="space-x-4 sm:ml-20 ml-0 flex flex-row ">
        {tabs.map((tab, index) => (
          <motion.div
            initial={{ opacity: 0, scale: 0.8, y: -20 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            transition={{
              duration: 0.1,
              delay: index * 0.1,
              ease: "easeIn",
              easings: 0.1,
            }}
            key={index}
            onClick={() => handleTabClick(tab.id)}
            className={`${
              activeTab === tab.id
                ? "bg-[#93cfec] text-gray-100"
                : "bg-gray-100 text-[#1d5b79]"
            }  p-3 rounded-3xl cursor-pointer  shadow-md font-medium ease-in duration-300`}
          >
            {tab.name}
          </motion.div>
        ))}
      </div>
      <div className="mt-5">{renderContent()}</div>
    </div>
  );
}

export default EssentialAccount;

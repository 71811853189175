import { ErrorMessage, Form, Formik } from "formik";
import React from "react";
import { motion, useMotionValue, useSpring } from "framer-motion";
import FormikInput from "../Input/FormikInput";
import Button from "../Button/Button";
import Select from "react-select";

function AddDishForm({ setOpen, caterers, onSubmit }) {
  return (
    <div>
      <div
        onClick={() => setOpen(false)}
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center overflow-auto z-50"
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto"
        >
          <div className="bg-white p-4 sm:p-8 shadow-md max-w-5xl w-full mx-auto rounded">
            <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
              Dishes
            </h2>
            <Formik
              initialValues={{
                catering: "",
                date: "",
                no_of_dishes: "",
                breakfast: "",
                lunch: "",
                dinner: "",
                hightea: "",
                extra: "",
              }}
              onSubmit={onSubmit}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="grid sm:grid-cols-2 grid-cols-1 gap-2 sm:gap-4 sm:mt-4 mt-2">
                    <div>
                      <label className="block mb-2 text-sm text-gray-600 ">
                        Caterers
                      </label>
                      <Select
                        name="catering"
                        options={caterers}
                        getOptionLabel={(option) => option.vendor.name}
                        getOptionValue={(option) => option.id}
                        onChange={(selectedOption) => {
                          setFieldValue("catering", selectedOption);
                        }}
                      />
                      <ErrorMessage
                        component={"div"}
                        name="catering"
                        className="text-red-500 mt-1 text-sm"
                      />
                    </div>
                    <div>
                      <FormikInput name={"date"} type={"date"} label={"Date"} />
                    </div>
                  </div>
                  <div className="grid sm:grid-cols-2 grid-cols-1 gap-2 sm:gap-4 sm:mt-4 mt-2">
                    {values.catering.c_type === "Package" && (
                      <div>
                        <FormikInput
                          name={"no_of_dishes"}
                          type={"number"}
                          label={"No of Dishes"}
                          min={1}
                          placeholder={"60"}
                        />
                      </div>
                    )}
                    {values.catering.c_type === "Individual" && (
                      <>
                        <div>
                          <FormikInput
                            name={"breakfast"}
                            type={"number"}
                            label={"Breakfast dish"}
                            placeholder={"60"}
                            min={1}
                          />
                        </div>
                        <div>
                          <FormikInput
                            name={"lunch"}
                            type={"number"}
                            label={"Lunch dish"}
                            placeholder={"60"}
                            min={1}
                          />
                        </div>
                        <div>
                          <FormikInput
                            name={"hightea"}
                            type={"number"}
                            label={"High tea dish"}
                            placeholder={"60"}
                            min={1}
                          />
                        </div>
                        <div>
                          <FormikInput
                            name={"dinner"}
                            type={"number"}
                            label={"Dinner dish"}
                            placeholder={"60"}
                            min={1}
                          />
                        </div>
                        <div>
                          <FormikInput
                            name={"extra"}
                            type={"number"}
                            label={"Extra dish"}
                            placeholder={"60"}
                            min={1}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="sm:mt-4 mt-2">
                    <Button name={"Add"} type={"submit"} />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddDishForm;

import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../Modal/DeleteModal";
import ViewArtist from "../../Modal/ViewArtist";
import ModalExtras from "../../Modal/ModalExtras";
import SceneTable from "../../components/Table/SceneTable";
import Button from "../../components/Button/Button";
import Select from "../../components/Input/Select";
import SceneForm from "../../components/Forms/SceneForm";
import SceneUpdateForm from "../../components/UpdateForm/SceneUpdateForm";
import toast from "react-hot-toast";
import * as Yup from "yup";
import SubRoleContext from "../../SubRoleContext";
import Loader from "../../Loader/loader";
import ErrorPage from "../ErrorPage";

function Scene() {
  const subRole = useContext(SubRoleContext);
  const validationSchema = Yup.object().shape({
    scene_number: Yup.number().required("Scene number is required"),
    start_page: Yup.number(),
    end_page: Yup.number(),
    brief_of_scene: Yup.string().required("Brief of scene is required"),
    shift: Yup.string().required("Select shift is required"),
    location_type: Yup.string().required("Select location type"),
    location_name: Yup.string().required("Select location name"),
    location_address: Yup.string().required("Location address is empty"),
    art_department_property: Yup.string().required(
      "Art department property required"
    ),
    production_team_property: Yup.string().required(
      "Production team property required"
    ),
    makeup_department_property: Yup.string().required(
      "Makeup department property required"
    ),
    artists_scenes_attributes: Yup.array().of(
      Yup.object().shape({
        artist_id: Yup.string(),
        costume: Yup.string(),
      })
    ),
    action_sequence_required: Yup.boolean().required("Select Option"),
    action_director: Yup.string(),
    no_of_action_man: Yup.number(),
    action_man_costume: Yup.string(),
    dance_choreographer_required: Yup.boolean().required("Select Option"),
    dance_choreographer: Yup.string(),
    no_of_dancers: Yup.number(),
    dancers_costume: Yup.string(),
    vfx_required: Yup.boolean().required("Select Option"),
    vfx: Yup.string(),
    additional_lights: Yup.boolean().required("Select Option"),
    type_of_light: Yup.string(),
    crowd_required: Yup.boolean().required("Select Option"),
    crowd_details: Yup.object().shape({
      details: Yup.array().of(
        Yup.object().shape({
          crowdAge: Yup.string(),
          crowdType: Yup.string(),
          crowdCostume: Yup.string(),
          crowdNumber: Yup.number(),
          description: Yup.string(),
        })
      ),
    }),
  });

  const [view, setView] = useState(true);
  const [updView, setUpdView] = useState(true);
  const [isModal, setIsModal] = useState(false);
  const [artists, setArtists] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedArtists, setSelectedArtists] = useState([]);
  const [sceneDetail, setSceneDetail] = useState([]);
  const [sceneToDelete, setSceneToDelete] = useState(null);
  const [viewArtistModal, setViewArtistModal] = useState(false);
  const [viewArtists, setViewArtists] = useState(null);
  const [selectedScene, setSelectedScene] = useState(null);
  const [openExtraModal, setOpenExtraModal] = useState(false);
  const [ExtraData, setExtraData] = useState(null);
  const [choreographerData, setChoreographerData] = useState([]);
  const [actionDirectorData, setActionDirectorData] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { projectId } = useParams();

  const ExtrasModalHandler = (id) => {
    const Scene = sceneDetail.find((view) => view.id == id);

    setExtraData(Scene);
    setOpenExtraModal(true);
  };

  const closeExtrasMadalHandler = () => {
    setExtraData(null);
    setOpenExtraModal(false);
  };

  const openArtistModal = (artists) => {
    const viewArtistDetails = sceneDetail.find(
      (view) => view.artists == artists
    );

    setViewArtists(viewArtistDetails.artists);
    setViewArtistModal(true);
  };

  const closeArtistModal = () => {
    setViewArtists(null);
    setViewArtistModal(false);
  };

  const openModal = (id) => {
    const sceneDetails = sceneDetail.find((scene) => scene.id == id);

    setSceneToDelete(sceneDetails);
    setIsModal(true);
  };

  const closeModal = () => {
    setSceneToDelete(null);
    setIsModal(false);
  };

  const viewHandler = () => {
    setView(false);
  };

  const updViewHandler = (scene) => {
    setSelectedScene(scene);

    setUpdView(false);
  };

  const handleUpdateBackdropClick = () => {
    setUpdView(true);
    setSelectedArtists([]);
  };

  const filteredScenes = sceneDetail.filter((scene) => {
    if (!selectedLocation) {
      return true;
    }
    if (selectedLocation === "All") {
      return true;
    }
    return scene.location_name === selectedLocation;
  });

  const stopClose = (e) => {
    e.stopPropagation();
  };

  const handleBackdropClick = () => {
    setView(true);
  };

  const getChoreographer = () => {
    axios
      .get(`api/v1/roles/dancers_and_action_names`, {
        params: { project_id: projectId, name: "Choreographer" },
      })
      .then((response) => {
        setChoreographerData(response.data.names);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 422) {
            console.log(error);
          }
        } else {
          console.log(error);
        }
      });
  };
  const getActionDirector = () => {
    axios
      .get(`api/v1/roles/dancers_and_action_names`, {
        params: { project_id: projectId, name: "Action Director" },
      })
      .then((response) => {
        setActionDirectorData(response.data.names);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const fetchLocationDetails = () => {
    axios
      .get(`api/v1/projects/${projectId}/locations`)
      .then((response) => {
        setLocations(response.data.locations);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const fetchArtistDetails = () => {
    axios
      .get(`api/v1/projects/${projectId}/project_artists`)
      .then((response) => {
        setArtists(response.data.project_artists);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const addSceneHandler = (values, { setSubmitting, resetForm }) => {
    const loaderToast = toast.loading("Adding Scene ...", {
      autoClose: false,
    });

    axios
      .post(`api/v1/projects/${projectId}/scenes`, {
        scene: {
          scene_number: values.scene_number,
          sub_scene: values.sub_scene,
          start_page: values.start_page,
          end_page: values.end_page,
          brief_of_scene: values.brief_of_scene,
          shift: values.shift,
          location_type: values.location_type,
          location_name: values.location_name,
          location_link: values.location_link,
          location_address: values.location_address,
          sub_location: values.sub_location,
          art_department_property: values.art_department_property,
          production_team_property: values.production_team_property,
          makeup_department_property: values.makeup_department_property,
          artists_scenes_attributes: values.artists_scenes_attributes,
          crowd_required: values.crowd_required,
          crowd_details: values.crowd_details,
          action_sequence_required: values.action_sequence_required,
          action_director: values.action_director,
          no_of_action_man: values.no_of_action_man,
          action_man_costume: values.action_man_costume,
          dance_choreographer_required: values.dance_choreographer_required,
          dance_choreographer: values.dance_choreographer,
          no_of_dancers: values.no_of_dancers,
          dancers_costume: values.dancers_costume,
          vfx_required: values.vfx_required,
          vfx: values.vfx,
          additional_lights: values.additional_lights,
          type_of_light: values.type_of_light,
        },
      })
      .then((response) => {
        if (response.status === 201) {
          handleBackdropClick();
          toast.dismiss(loaderToast);
          toast.success("Scene added successfully");
          fetchSceneDetails();
          resetForm();
        }
      })
      .catch((err) => {
        toast.dismiss(loaderToast);
        toast.error(
          err.response.data.message || err.message || err.response.data.error
        );
        console.log(err);
        handleBackdropClick();
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const fetchSceneDetails = () => {
    axios
      .get(`api/v1/projects/${projectId}/scenes`)
      .then((response) => {
        setLoading(true);
        setSceneDetail(response.data.scenes);
      })
      .catch((err) => {
        if (err) {
          setError(true);
          console.log(err.message);
        }
      });
  };

  const deleteScene = (id) => {
    const loader = toast.loading("Deleting Scene ...", { autoClose: false });
    axios
      .delete(`api/v1/projects/${projectId}/scenes/${id}`)
      .then((response) => {
        toast.dismiss(loader);
        toast.success("Scene Deleted Succesfully");
        fetchSceneDetails();
      })
      .catch((err) => {
        toast.dismiss(loader);
        toast.error(
          err.response.data.message || err.message || err.response.data.error
        );
        console.log(err);
      });
  };

  const updateSceneHandler = (id, values, { resetForm }) => {
    const loader = toast.loading("Updating Scene ...", { autoClose: false });
    axios
      .put(`api/v1/projects/${projectId}/scenes/${id}`, {
        scene: {
          scene_number: values.scene_number || selectedScene.scene_number,
          sub_scene: values.sub_scene || selectedScene.sub_scene,
          start_page: values.start_page || selectedScene.start_page,
          end_page: values.end_page || selectedScene.end_page,
          brief_of_scene: values.brief_of_scene || selectedScene.brief_of_scene,
          shift: values.shift || selectedScene.shift,
          location_type: values.location_type || selectedScene.location_type,
          location_name: values.location_name || selectedScene.location_name,
          location_address:
            values.location_address || selectedScene.location_address,
          sub_location: values.sub_location || selectedScene.sub_location,
          art_department_property:
            values.art_department_property ||
            selectedScene.art_department_property,
          production_team_property:
            values.production_team_property ||
            selectedScene.production_team_property,
          makeup_department_property:
            values.makeup_department_property ||
            selectedScene.makeup_department_property,
          artists_scenes_attributes: values.artists_scenes_attributes.map(
            (value) => ({
              id: parseInt(value.artist_scene_id),
              artist_id: parseInt(value.artist_id),
              costume: value.costume,
            })
          ),

          crowd_required: values.crowd_required || selectedScene.crowd_required,
          crowd_details: values.crowd_details,
          action_sequence_required:
            values.action_sequence_required ||
            selectedScene.action_sequence_required,
          action_director:
            values.action_director || selectedScene.action_director,
          no_of_action_man:
            values.no_of_action_man || selectedScene.no_of_action_man,
          action_man_costume:
            values.action_man_costume || selectedScene.action_man_costume,
          dance_choreographer_required:
            values.dance_choreographer_required ||
            selectedScene.dance_choreographer_required,
          dance_choreographer:
            values.dance_choreographer || selectedScene.dance_choreographer,
          no_of_dancers: values.no_of_dancers || selectedScene.no_of_dancers,
          dancers_costume:
            values.dancers_costume || selectedScene.dancers_costume,
          vfx_required: values.vfx_required || selectedScene.vfx_required,
          vfx: values.vfx || selectedScene.vfx,
          additional_lights:
            values.additional_lights || selectedScene.additional_lights,
          type_of_light: values.type_of_light || selectedScene.type_of_light,
        },
        artist_scenes_ids: values.artists_scenes_attributes.map((value) =>
          parseInt(value.artist_scene_id)
        ),
      })
      .then((response) => {
        fetchSceneDetails();
        handleUpdateBackdropClick();
        setSelectedArtists([]);
        resetForm();
        toast.dismiss(loader);
        toast.success("Scene updated succesfully");
      })
      .catch((err) => {
        toast.error(
          err.response.data.message || err.message || err.response.data.error
        );
        toast.dismiss(loader);
        console.log(err);
      });
  };

  useEffect(() => {
    getActionDirector();
    getChoreographer();
    fetchLocationDetails();
    fetchArtistDetails();
    fetchSceneDetails();
  }, []);
  return (
    <div>
      {error ? (
        <ErrorPage />
      ) : (
        <div>
          {!loading ? (
            <Loader />
          ) : (
            <>
              <div className="flex flex-row p-5">
                <div className="flex sm:flex-row flex-col items-center justify-between w-full xl:ml-20 2xl:ml-20 md:ml-20 sm:ml-20 ml-0 sm:mt-2 mt-8">
                  {subRole !== "user" && (
                    <div className="mt-2">
                      <Button clickFunction={viewHandler} name={"Add Scene"} />
                    </div>
                  )}
                  <div className="flex items-end justify-end">
                    <Select
                      onChangeFunction={(e) =>
                        setSelectedLocation(e.target.value)
                      }
                      selectedOption={"Location"}
                      optionData={locations}
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center  xl:ml-24 2xl:ml-24 lg:ml-24 md:ml-24 sm:ml-24 ml-0">
                <div className="w-full mt-5">
                  <SceneTable
                    filteredScenes={filteredScenes}
                    openArtistModal={openArtistModal}
                    ExtrasModalHandler={ExtrasModalHandler}
                    updViewHandler={updViewHandler}
                    openModal={openModal}
                  />
                </div>
              </div>
            </>
          )}
          {!view && (
            <SceneForm
              validationSchema={validationSchema}
              handleBackdropClick={handleBackdropClick}
              locations={locations}
              artists={artists}
              selectedArtist={selectedArtists}
              addSceneHandler={addSceneHandler}
              choreographerData={choreographerData}
              actionDirectorData={actionDirectorData}
            />
          )}

          {!updView && selectedScene && (
            <SceneUpdateForm
              handleUpdateBackdropClick={handleUpdateBackdropClick}
              selectedScene={selectedScene}
              choreographerData={choreographerData}
              actionDirectorData={actionDirectorData}
              locations={locations}
              artists={artists}
              updateSceneHandler={updateSceneHandler}
            />
          )}
          {isModal && (
            <DeleteModal
              title={"Delete Scene."}
              message={`Do you want to delete Scene ${sceneToDelete.scene_number}`}
              onDelete={() => {
                deleteScene(sceneToDelete.id);
                closeModal();
              }}
              onClose={closeModal}
              icon={true}
            />
          )}
          {viewArtistModal && (
            <ViewArtist
              detail={viewArtists}
              onClose={closeArtistModal}
              stopclose={stopClose}
            />
          )}
          {openExtraModal && (
            <ModalExtras
              detail={ExtraData}
              onClose={closeExtrasMadalHandler}
              stopClose={stopClose}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default Scene;

import jsPDF from "jspdf";
import React, { useRef } from "react";
import { ToWords } from "to-words";
import moment from "moment-timezone";
import "moment/locale/en-in";
import { uid } from "uid";
import { BsDownload } from "react-icons/bs";
import html2canvas from "html2canvas";

function ExpenseVoucher({ onClose, expense, user }) {
  const voucherRef = useRef();
  const UID = uid();
  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });
  const DownloadVoucher = async () => {
    html2canvas(voucherRef.current, { scale: 2 }).then((canvas) => {
      const pdf = new jsPDF("portrait", "mm", [128.59, 139.96]);
      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", 0, 0, 128.59, 139.96);
      pdf.save(`voucher_${expense.expenser_name}_${expense.i_name}_${UID}.pdf`);
    });
  };

  const convertToIST = (utcDateStr) => {
    return moment.utc(utcDateStr).tz("Asia/Kolkata").format("DD-MM-YYYY");
  };
  return (
    <div>
      <div>
        <div
          onClick={onClose}
          className="fixed inset-0 flex items-center justify-center z-50 overflow-hidden no-scrollbar bg-black bg-opacity-50"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="flex flex-col items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto "
          >
            <div className="flex flex-row mb-4">
              <div>
                <button
                  onClick={DownloadVoucher}
                  className=" bg-[#1d5b79]  text-white px-3 py-2 shadow-2xl rounded-full hover:bg-[#a9c8e2] ease-in-out duration-300 hover:shadow-none flex items-center"
                >
                  <span className="text-xl">
                    <BsDownload />
                  </span>
                  <span className="font-semibold ml-2">Download Voucher</span>
                </button>
              </div>
              <div></div>
            </div>
            <div
              ref={voucherRef}
              className="bg-white p-4 sm:p-8 shadow-md max-w-5xl w-full sm:my-auto  my-auto"
            >
              <div className="border border-gray-900 ">
                <div className="flex flex-col items-center justify-center mx-5 p-2">
                  <h2 className="text-xl font-semibold text-[#1d5b79] w-full text-center">
                    {user.company_name}
                  </h2>
                  <h3 className="text-sm w-full text-center text-gray-700">
                    {user.address}
                  </h3>
                  <h3 className="text-sm w-full text-center text-gray-700">
                    {user.phone_number}
                  </h3>
                </div>
                <div className="text-center w-full">
                  <h2 className="text-[#1d5b79] text-lg font-semibold">
                    EXPENSE VOUCHER
                  </h2>
                </div>
                <div className="border-t mt-4 border-gray-900">
                  <div className="flex flex-col items-end justify-end mx-3">
                    {expense.voucher_serial_number && (
                      <h2 className="text-sm font-semibold">
                        Voucher no: {expense.voucher_serial_number}
                      </h2>
                    )}
                    <h2 className="text-sm font-semibold mt-1">
                      Date: {convertToIST(expense.created_at)}
                    </h2>
                  </div>

                  <div className="mt-2 flex flex-col items-start justify-start mx-3">
                    <div className="flex flex-row">
                      <h2 className="text-semibold text-sm">
                        Expense done by:
                      </h2>
                      <h2 className="ml-1 text-semiblod text-gray-900 text-sm font-bold">
                        {expense.expenser_name}
                      </h2>
                    </div>
                  </div>
                  <div className="mt-2 flex flex-col items-start justify-start mx-3">
                    <div className="flex flex-row">
                      <h2 className="text-semibold text-sm">Amount:</h2>
                      <h2 className="ml-1 text-semiblod text-gray-900 text-sm font-bold">
                        ₹ {expense.amount}
                      </h2>
                    </div>
                  </div>
                  <div className="mt-2 flex flex-col items-start justify-start mx-3">
                    <div className="flex flex-row">
                      <h2 className="text-semibold text-sm">
                        Amount in words:
                      </h2>
                      <h2 className="ml-1 text-semiblod text-gray-900 text-sm font-bold">
                        {toWords.convert(expense.amount)}
                      </h2>
                    </div>
                  </div>
                  <div className="mt-2 flex flex-col items-start justify-start mx-3">
                    <div className="flex flex-row">
                      <h2 className="text-semibold text-sm">Department:</h2>
                      <h2 className="ml-1 text-semiblod text-gray-900 text-sm font-bold">
                        {expense.department}
                      </h2>
                    </div>
                  </div>

                  <div className="mt-2 flex flex-col items-start justify-start mx-3">
                    <div className="flex flex-row">
                      <h2 className="text-semibold text-sm">Duration:</h2>
                      <h2 className="ml-1 text-semiblod text-gray-900 text-sm font-bold">
                        {expense.duration}
                      </h2>
                    </div>
                  </div>

                  <div className="mt-14 mb-5 flex items-center justify-between mx-3">
                    <div>
                      <h2 className="border-t border-gray-800">
                        Authorized by
                      </h2>
                    </div>
                    <div>
                      <h2 className="border-t border-gray-800">Received by</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExpenseVoucher;

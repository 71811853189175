import React from "react";
import poster from "../assets/image/post_error_2.png";
import { useNavigate } from "react-router-dom";

function ErrorPage() {
  const navigate = useNavigate();

  const navigateToLogin = () => {
    navigate("/Login");
    localStorage.clear();
  };

  const refershPage = () => {
    window.location.reload();
  };

  return (
    <div className="md:mt-5 mt-16 flex flex-col items-center justify-center sm:ml-20 ml-0 font-Bebas">
      <div className="max-w-md w-full text-center">
        <img
          src={poster}
          alt="poster"
          className="w-full h-auto rounded-lg shadow-lg mb-6 transform hover:scale-105 transition-transform duration-300"
        />
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-4 drop-shadow-md">
          OOPS!!! Something went wrong.
        </h2>
        <p className="mb-8 text-base sm:text-lg">
          It seems our reel got tangled. No worries, our crew is on it! In the
          meantime, try one of the options below.
        </p>
        <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
          <button
            onClick={navigateToLogin}
            className="bg-black text-white py-2 px-4 rounded-full shadow-md hover:bg-white hover:text-black transition-colors duration-300"
          >
            Redirect to Login Page
          </button>
          <button
            onClick={refershPage}
            className="bg-white text-black py-2 px-4 rounded-full shadow-md hover:bg-black hover:text-white transition-colors duration-300"
          >
            Reload
          </button>
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;

import axios from "axios";
import React, { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { TiTick } from "react-icons/ti";
import toast from "react-hot-toast";
import AccessManager from "../Forms/AccessManager";
import { useParams } from "react-router-dom";
import usePagination from "../Pagination/usePagination";
import Pagination from "../Pagination/Pagination";

function RoleTable({ assignedCrew, getAssignedCrew }) {
  const [view, setView] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [open, setOpen] = useState(false);
  const [assignedRoleCrew, setAssignedRoleCrew] = useState("");
  const { projectId } = useParams();

  const handleOpenAccessManager = (crewId) => {
    const selectedCrew = assignedCrew.find((data) => data.id === crewId);
    setAssignedRoleCrew(selectedCrew);
    setOpen(true);
  };

  const handleViewToggle = (id) => {
    setSelectedId(id);
    setView(true);
  };

  const revokeRole = (role) => {
    axios
      .delete(`api/v1/projects/${projectId}/crews/${selectedId}/remove_role`, {
        params: { role_name: role },
      })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          getAssignedCrew();
          toast.success(response.data.message);
        }
      })
      .catch((error) => console.log(error.message));
  };

  const assignAccess = (values, { resetForm }) => {
    let access;

    if (
      values.script === true &&
      values.callSheet === true &&
      values.expense === true &&
      values.account === true
    ) {
      access = "all";
    } else if (values.callSheet === true) {
      access = "callsheet";
    } else if (values.script === true) {
      access = "script";
    } else if (values.expense === true) {
      access = "expense";
    } else if (values.account === true) {
      access = "account";
    } else {
      access = "";
    }
    const loader = toast.loading("Assigning...", {
      autoClose: false,
    });

    axios
      .put(`api/v1/projects/${projectId}/roles/grant_access`, {
        access_type: access,
        id: assignedRoleCrew.crew_id,
      })
      .then((response) => {
        toast.dismiss(loader);
        toast.success(response.data.message);
        setOpen(false);
        getAssignedCrew();
      })
      .catch((error) => {
        console.log(error);
        toast.dismiss(loader);
        toast.error(error.message);
      });
  };

  const revokeAccess = () => {
    const loader = toast.loading("Revoking access...", {
      autoClose: false,
    });

    axios
      .put(`api/v1/projects/${projectId}/roles/revoke_access`, {
        access_type: "all",
        id: assignedRoleCrew.crew_id,
      })
      .then((response) => {
        console.log(response);
        toast.dismiss(loader);
        toast.success(response.data.message);
        setOpen(false);
        getAssignedCrew();
      })
      .catch((error) => {
        console.log(error);
        toast.dismiss(loader);
        toast.error(error.message);
      });
  };

  const { currentItems, currentPage, handlePageChange, totalPages } =
    usePagination(assignedCrew, 10);
  return (
    <>
      <div className="flex flex-col h-full">
        <div className="w-full mx-auto bg-white  rounded-sm border border-gray-200">
          <header className="flex sm:flex-row flex-col  justify-between px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold text-gray-800">Role</h2>
            <Pagination
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              totalPages={totalPages}
            />
          </header>
          <div className="p-3">
            <div className="overflow-x-auto">
              <table className="table-auto w-full">
                <thead className="text-xs font-semibold uppercase text-gray-600 bg-[#eceff1]">
                  <tr>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Name</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Role</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Action</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Access</div>
                    </th>
                  </tr>
                </thead>
                <tbody className="text-sm divide-y divide-gray-100">
                  {currentItems.map((role) => (
                    <tr>
                      <td className="p-2 whitespace-nowrap">
                        {role.full_name}
                      </td>
                      <td className="p-2">
                        <div className="text-left uppercase">
                          {role.role_name}
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        {view && selectedId === role.id ? (
                          <div className="text-left">
                            <button
                              onClick={() => setView(false)}
                              className="p-2 mr-4 text-xl text-green-600 border-none hover:shadow-none rounded-full outline-none bg-green-50 shadow-lg duration-300 ease-in"
                            >
                              <RxCross1 />
                            </button>
                            <button
                              onClick={() => revokeRole(role.role_name)}
                              className="p-2 mr-4 text-xl text-red-600 border-none hover:shadow-none rounded-full outline-none bg-red-50 shadow-lg duration-300 ease-in"
                            >
                              <TiTick />
                            </button>
                          </div>
                        ) : (
                          <div className="text-left">
                            <button
                              onClick={() => handleViewToggle(role.id)}
                              className="bg-red-50 text-red-500 font-bold px-3 py-2  duration-300 ease-out hover:shadow-none rounded-2xl"
                            >
                              Revoke role
                            </button>
                          </div>
                        )}
                      </td>
                      <td className="p-2">
                        <div className="text-left uppercase">
                          <button
                            onClick={() => handleOpenAccessManager(role.id)}
                            className="text-bold hover:underline ease-in duration-300"
                          >
                            Open access manager
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {open && (
        <>
          <AccessManager
            setOpen={setOpen}
            assignedRoleCrew={assignedRoleCrew}
            assignAccess={assignAccess}
            revokeAccess={revokeAccess}
          />
        </>
      )}
    </>
  );
}
export default RoleTable;

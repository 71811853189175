import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import SecureLS from "secure-ls";
import moment from "moment-timezone";
import "moment/locale/en-in";
import { GoDotFill } from "react-icons/go";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { uid } from "uid";
import toast from "react-hot-toast";
import { BsDownload } from "react-icons/bs";

function NoDue({ data, name, onClose, role, user }) {
  const [date, setDate] = useState("");
  const ls = new SecureLS();
  const sheetRef = useRef();
  const UID = uid();

  const user_id = ls.get("User id");
  const fetchProject = localStorage.getItem("Project_details");
  const parseProject = JSON.parse(fetchProject);

  const downloadPdf = async () => {
    html2canvas(sheetRef.current, { scale: 2 })
      .then((canvas) => {
        const pdf = new jsPDF("portrait", "mm", "a4");
        const imgData = canvas.toDataURL("image/png");
        pdf.addImage(imgData, "PNG", 0, 0, 210, 297);
        pdf.save(`${name}'s_NO_DUE_CERTIFICATE_${UID}.pdf`);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!!!");
      });
  };

  const fetchDate = () => {
    const fetchInstallmentLength = data.installments.length;
    const fetchLastInstallment = data.installments[fetchInstallmentLength - 1];
    const dateOfFinalPayment = convertToIST(fetchLastInstallment.created_at);
    setDate(dateOfFinalPayment);
  };

  const convertToIST = (utcDateStr) => {
    return moment.utc(utcDateStr).tz("Asia/Kolkata").format("DD-MM-YYYY");
  };

  useEffect(() => {
    fetchDate();
  }, []);
  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 overflow-y-auto"
      onClick={onClose}
    >
      <div className="flex flex-col items-center justify-center my-auto z-50 ">
        <div className="flex justify-start items-start mt-5">
          <button
            onClick={downloadPdf}
            className=" bg-[#1d5b79]  text-white px-3 py-2 shadow-2xl rounded-full hover:bg-[#a9c8e2] ease-in-out duration-300 hover:shadow-none flex items-center"
          >
            <span className="text-xl">
              <BsDownload />
            </span>
            <span className="font-semibold ml-2">Download Sheet</span>
          </button>
        </div>
        <div
          ref={sheetRef}
          onClick={(e) => e.stopPropagation()}
          className="bg-white rounded shadow-md  min-h-[297mm] w-[210mm] mb-10 mt-10"
        >
          <div className="border-b border-gray-500 p-5">
            <div className="flex flex-col items-center justify-center">
              <h1 className="text-2xl font-bold text-[#1d5b79]">
                {user.company_name}
              </h1>
              <h2 className="text-lg font-bold">
                Address:
                <span className="font-normal ml-2">{user.address}</span>
              </h2>
              <div className="grid grid-cols-2 sm:grid-cols-2 gap-2 sm:gap-4">
                <h2 className="font-bold text-base">
                  GST:
                  <span className="font-normal ml-2">{user.gst_number}</span>
                </h2>
                <h2 className="font-bold text-base">
                  PAN number:
                  <span className="font-normal ml-2">{user.pan_number}</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center mt-5">
            <h2 className="text-xl font-bold">NO DUE CERTIFICATE</h2>
          </div>
          <div className="flex items-start justify-start mx-16">
            <h2 className="font-bold text-base">
              Date:
              <span className="font-normal ml-2">{date}</span>
            </h2>
          </div>
          <div className="flex items-start justify-center mx-16 mt-5 flex-col">
            <h2 className="text-lg font-bold">To Whom It May Concern,</h2>
            <p className="mt-8 text-lg">
              This is to certify that <span className="font-bold">{name}</span>,
              who was associated with the project titled{" "}
              <span className="font-bold">
                "{parseProject ? parseProject.title : data.project.title}"
              </span>
              , produced by{" "}
              <span className="font-bold">{user.company_name}</span>, has
              cleared all financial dues and obligations.
            </p>
            <p className="mt-8 text-lg">
              As of the date of this certificate, there are no pending payments
              or outstanding dues from the production house{" "}
              <span className="font-bold">{user.company_name}</span> towards{" "}
              <span className="font-bold">{role}</span> for services provided
              during the production of{" "}
              <span className="font-bold">
                {" "}
                "{parseProject ? parseProject.title : data.project.title}"
              </span>
              .
            </p>
            <h2 className="text-lg font-bold mt-3">Details: </h2>
            <ul className="mt-2">
              <li className="mt-1 flex flex-row">
                <GoDotFill className="mt-1" />
                <h2 className="font-bold text-base ml-2">
                  Name:
                  <span className="font-normal ml-2">{name}</span>
                </h2>
              </li>
              <li className="mt-1 flex flex-row">
                <GoDotFill className="mt-1" />
                <h2 className="font-bold text-base ml-2">
                  Role/Service provided:
                  <span className="font-normal ml-2">{role}</span>
                </h2>
              </li>
              <li className="mt-1 flex flex-row">
                <GoDotFill className="mt-1" />
                <h2 className="font-bold text-base ml-2">
                  Project name:
                  <span className="font-normal ml-2">
                    {parseProject ? parseProject.title : data.project.title}
                  </span>
                </h2>
              </li>
              <li className="mt-1 flex flex-row">
                <GoDotFill className="mt-1" />
                <h2 className="font-bold text-base ml-2">
                  Production house:
                  <span className="font-normal ml-2">{user.company_name}</span>
                </h2>
              </li>
              <li className="mt-1 flex flex-row">
                <GoDotFill className="mt-1" />
                <h2 className="font-bold text-base ml-2">
                  Payment status:
                  <span className="font-normal ml-2">
                    All payments have been made and no dues are remaining.
                  </span>
                </h2>
              </li>
            </ul>
            <p className="mt-5 text-lg">
              We, <span className="font-bold">{user.company_name}</span>, hereby
              declare that <span className="font-bold">{name}</span> has
              fulfilled all responsibilities as per the agreed contract, and we
              acknowledge the completion of all transactions related to this
              project.
            </p>
            <h2 className="mt-5 text-lg font-bold">
              Signature of Authorized Signatory
            </h2>
            <h3 className="mt-5 text-lg">{role} Sign: </h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoDue;

import React, { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import CatererForm from "../../components/Forms/CartrerForm";
import axios from "axios";
import { FaPlus } from "react-icons/fa";
import IconButton from "../../components/Button/IconButton";
import AddDishForm from "../../components/Forms/AddDishForm";
import { AnimatePresence } from "framer-motion";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import ErrorPage from "../ErrorPage";
import Loader from "../../Loader/loader";
import CatererTable from "../../components/Table/CatererTable";
import CatererUpdateForm from "../../components/UpdateForm/CatererUpdateForm";
import DeleteModal from "../../Modal/DeleteModal";
import DishesTable from "../../components/Table/DishesTable";
import UpdateDishForm from "../../components/UpdateForm/UpdateDishForm";

function Catering() {
  const [view, setView] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [update, setUpdate] = useState(false);
  const [updateDishes, setUpdateDishes] = useState(false);
  const [deleteDish, setDeleteDish] = useState(false);
  const [selectedDish, setSelectedDish] = useState("");
  const [Delete, setDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [caterers, setCaterers] = useState([]);
  const [projectCaterers, setProjectCatrers] = useState([]);
  const [selectedProjectCatrer, setSelectedProjectCatrer] = useState("");
  const { projectId } = useParams();

  const getVendor = () => {
    axios
      .get(`api/v1/vendors/index_for_users`)
      .then((response) => {
        const cateringVendor = response.data.vendors.filter(
          (vendor) => vendor.vendor_type === "Catering"
        );
        setCaterers(cateringVendor);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleViewUpdateProjectCatrer = (catrer) => {
    setUpdate(true);
    setView(false);
    setSelectedProjectCatrer({ ...catrer });
  };

  const handleViewDeleteProjectCatrer = (catrer) => {
    setDelete(true);
    setView(false);
    setSelectedProjectCatrer({ ...catrer });
  };

  const handleViewUpdateDish = (dish) => {
    setSelectedDish({ ...dish });
    setUpdateDishes(true);
  };
  const handleViewDeleteDish = (dish) => {
    setSelectedDish({ ...dish });
    setDeleteDish(true);
  };

  const getCaterer = () => {
    axios
      .get(`api/v1/projects/${projectId}/catering_details`)
      .then((res) => {
        setProjectCatrers(res.data.catering_details);
        setLoading(true);
      })
      .catch((err) => {
        setError(true);
        console.log(err);
      });
  };

  const addCatrerHandler = (value, { resetForm }) => {
    const loader = toast.loading("Adding catrer...");
    axios
      .post(`api/v1/projects/${projectId}/catering_details`, {
        catering_detail: {
          vendor_id: value.vendor_catrer_id,
          c_type: value.catering_type,
          number_of_days: value.no_of_days,
          ...(value.catering_type === "Individual" && {
            breakfast_amount: value.breakfast,
            lunch_amount: value.lunch,
            high_tea_amount: value.hightea,
            dinner_amount: value.dinner,
            extra_snack_amount: value.extra,
          }),
          ...(value.catering_type === "Package" && {
            package_amount: value.amount,
          }),
          gst_attributes: {
            gst_allowed: value.gst_required,
            tds_allowed: value.tds_required,
          },
        },
      })
      .then((res) => {
        if (res.status === 201) {
          resetForm();
          toast.dismiss(loader);
          getCaterer();
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        toast.dismiss(loader);
        toast.error(err.message);
        console.log(err);
      });
  };

  const updateCatrerHandler = (value, { resetForm }) => {
    const loader = toast.loading("Updating catrer...");
    axios
      .put(
        `api/v1/projects/${projectId}/catering_details/${selectedProjectCatrer.id}`,
        {
          catering_detail: {
            vendor_id: value.vendor_catrer_id,
            c_type: value.catering_type,
            number_of_days: value.no_of_days,
            ...(value.catering_type === "Individual" && {
              breakfast_amount: value.breakfast,
              lunch_amount: value.lunch,
              high_tea_amount: value.hightea,
              dinner_amount: value.dinner,
              extra_snack_amount: value.extra,
            }),
            ...(value.catering_type === "Package" && {
              package_amount: value.amount,
            }),
            gst_attributes: {
              gst_allowed: value.gst_required,
              tds_allowed: value.tds_required,
            },
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          resetForm();
          toast.dismiss(loader);
          getCaterer();
          toast.success(res.data.message);
          setUpdate(false);
          setView(true);
        }
      })
      .catch((err) => {
        toast.dismiss(loader);
        toast.error(err.message);
        console.log(err);
      });
  };

  const handleDeleteCatrer = () => {
    const loader = toast.loading("Removing catrer...");
    axios
      .delete(
        `api/v1/projects/${projectId}/catering_details/${selectedProjectCatrer.id}`
      )
      .then((res) => {
        if (res.status === 200) {
          getCaterer();
          setDelete(false);
          setView(true);
          toast.dismiss(loader);
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.dismiss(loader);
        toast.error(err.message);
      });
  };

  const handleAddDishes = (value, { resetForm }) => {
    const loader = toast.loading("Adding dishes...");
    axios
      .post(
        `api/v1/projects/${projectId}/catering_details/${value.catering.id}/dishes`,
        {
          dish: {
            c_date: value.date,
            ...(value.catering.c_type === "Package"
              ? {
                  number_of_dishes: value.no_of_dishes,
                }
              : {
                  breakfast_dishes: value.breakfast,
                  lunch_dishes: value.lunch,
                  dinner_dishes: value.dinner,
                  high_tea_dishes: value.hightea,
                  extra_dishes: value.extra,
                }),
          },
        }
      )
      .then((res) => {
        if (res.status === 201) {
          toast.success(res.data.message);
          toast.dismiss(loader);
          resetForm();
          getCaterer();
          setOpen(false);
        }
      })
      .catch((err) => {
        toast.dismiss(loader);
        toast.error(err.message);
        console.log(err);
      });
  };

  const handleUpdateDishes = (value, { resetForm }) => {
    const loader = toast.loading("Updating dishes ...");
    axios
      .put(
        `api/v1/projects/${projectId}/catering_details/${selectedDish.id}/dishes/${selectedDish.dishDetails.id}`,
        {
          dish: {
            c_date: value.date,
            ...(selectedDish.c_type === "Package"
              ? {
                  number_of_dishes: value.no_of_dishes,
                }
              : {
                  breakfast_dishes: value.breakfast,
                  lunch_dishes: value.lunch,
                  dinner_dishes: value.dinner,
                  high_tea_dishes: value.hightea,
                  extra_dishes: value.extra,
                }),
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          toast.dismiss(loader);
          toast.success(res.data.message);
          resetForm();
          getCaterer();
          setUpdateDishes(false);
        }
      })
      .catch((Err) => {
        console.log(Err);
        toast.dismiss(loader);
        toast.error(Err.message);
      });
  };
  const handleDeleteDish = () => {
    const loader = toast.loading("Removing Dishes ....");
    axios
      .delete(
        `api/v1/projects/${projectId}/catering_details/${selectedDish.id}/dishes/${selectedDish.dishDetails.id}`
      )
      .then((res) => {
        if (res.status === 200) {
          toast.dismiss(loader);
          toast.success(res.data.message);
          getCaterer();
          setDeleteDish(false);
        }
      })
      .catch((err) => {
        toast.dismiss(loader);
        toast.error(err.message);
        console.log(err);
      });
  };
  useEffect(() => {
    getCaterer();
    getVendor();
  }, []);
  return (
    <div>
      {error ? (
        <ErrorPage />
      ) : (
        <div>
          {!loading ? (
            <Loader />
          ) : (
            <div className="flex flex-col sm:flex sm:flex-col p-5">
              <div className="flex sm:flex-row flex-col-reverse sm:items-center items-center justify-center sm:justify-between xl:ml-20 2xl:ml-20 md:ml-20 sm:ml-20 ml-0 sm:mt-8 mt-8">
                <div className="sm:mt-0 mt-2">
                  <IconButton
                    icon={<FaPlus />}
                    name={"Add Dishes"}
                    clickFunction={() => setOpen(true)}
                  />
                </div>
                <div className="flex sm:items-end sm:justify-end items-center justify-center">
                  <Button
                    name={"Add Caterer"}
                    clickFunction={() => setView(true)}
                  />
                </div>
              </div>
              <div className="flex items-center justify-center  xl:ml-20 2xl:ml-20 lg:ml-20 md:ml-20 sm:ml-20 ml-0">
                <div className="mt-5 w-full">
                  <DishesTable
                    caterer={projectCaterers}
                    deleteView={handleViewDeleteDish}
                    updateView={handleViewUpdateDish}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <AnimatePresence>
        {open && (
          <AddDishForm
            setOpen={setOpen}
            caterers={projectCaterers}
            onSubmit={handleAddDishes}
          />
        )}
      </AnimatePresence>
      {view && (
        <CatererForm
          onClose={() => setView(false)}
          caterers={caterers}
          onSubmit={addCatrerHandler}
          projectCaterers={projectCaterers}
          deleteView={handleViewDeleteProjectCatrer}
          updateView={handleViewUpdateProjectCatrer}
        />
      )}
      {update && (
        <CatererUpdateForm
          onClose={() => {
            setView(true);
            setUpdate(false);
          }}
          selectedCatrer={selectedProjectCatrer}
          onSubmit={updateCatrerHandler}
        />
      )}
      {updateDishes && (
        <UpdateDishForm
          setUpdate={setUpdateDishes}
          data={selectedDish}
          onSubmit={handleUpdateDishes}
        />
      )}
      {Delete && (
        <DeleteModal
          title={"Remove catrer"}
          message={`Do you want to remove ${selectedProjectCatrer.vendor.name}?`}
          onClose={() => {
            setView(true);
            setDelete(false);
          }}
          onDelete={handleDeleteCatrer}
        />
      )}
      {deleteDish && (
        <DeleteModal
          title={"Remove Dishes"}
          message={`Do you want to remove from date ${selectedDish.dishDetails.c_date}?`}
          onClose={() => {
            setDeleteDish(false);
          }}
          onDelete={handleDeleteDish}
          icon={true}
        />
      )}
    </div>
  );
}

export default Catering;

import React, { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import TravelForm from "../../components/Forms/TravelForm";
import axios from "axios";
import toast from "react-hot-toast";
import Loader from "../../Loader/loader";
import ErrorPage from "../ErrorPage";
import { useParams } from "react-router-dom";
import TravelAgencyTable from "../../components/Table/TravelAgencyTable";
import VehicleUpdateForm from "../../components/UpdateForm/VehicleUpdateForm";
import DeleteModal from "../../Modal/DeleteModal";

function Travel() {
  const [view, setView] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [update, setUpdate] = useState(false);
  const [Delete, setDelete] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState({});
  const { projectId } = useParams();

  const fetchVendors = () => {
    axios
      .get(`api/v1/vendors/index_for_users`)
      .then((res) => {
        const filteredVendor = res.data.vendors.filter(
          (vendor) => vendor.vendor_type === "Travel"
        );
        setVendors(filteredVendor);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAddTravelAgency = (values, { resetForm }) => {
    const loader = toast.loading("Adding travel agency...");
    axios
      .post(`api/v1/projects/${projectId}/vehicles`, {
        vehicles: values.vehicles,
      })
      .then((res) => {
        if (res.status === 201) {
          toast.dismiss(loader);
          toast.success(res.data.message);
          setView(false);
          resetForm();
          handlefetchTravelAgency();
        }
      })
      .catch((err) => {
        toast.dismiss(loader);
        toast.error(err.response.data.message || err.message);
        console.log(err);
      });
  };

  const handlefetchTravelAgency = () => {
    axios
      .get(`api/v1/projects/${projectId}/vehicles`)
      .then((res) => {
        setVehicles(res.data.vehicles);
        setLoading(true);
      })
      .catch((err) => {
        setError(true);
        console.log(err);
      });
  };

  const handleUpdateVehicle = (value, { resetForm }) => {
    const Loader = toast.loading("Updating Vehicle...");
    axios
      .put(`api/v1/projects/${projectId}/vehicles/${selectedVehicle.id}`, {
        vehicle: {
          vehicle_type: value.vehicle_type,
          capacity: value.capacity,
          number_plate: value.number_plate,
          driver_name: value.driver_name,
          driver_number: value.driver_number,
          name: value.name,
          rate: value.rate,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          toast.dismiss(Loader);
          toast.success(res.data.message);
          handlefetchTravelAgency();
          setUpdate(false);
          resetForm();
        }
      })
      .catch((err) => {
        console.log(err);
        toast.dismiss(Loader);
        toast.error(err.message);
      });
  };

  const handleDeleteVehicle = () => {
    const loader = toast.loading("Deleting vehicle ...");
    axios
      .delete(`api/v1/projects/${projectId}/vehicles/${selectedVehicle.id}`)
      .then((res) => {
        toast.dismiss(loader);
        toast.success(res.data.message);
        handlefetchTravelAgency();
        setDelete(false);
      })
      .catch((err) => {
        toast.dismiss(loader);
        toast.error(err.message);
        console.log(err);
      });
  };

  const handleVehicleUpdateView = (vehicle) => {
    setSelectedVehicle({ ...vehicle });
    setUpdate(true);
  };
  const handleDeleteVehicleView = (vehicle) => {
    setSelectedVehicle({ ...vehicle });
    setDelete(true);
  };

  useEffect(() => {
    handlefetchTravelAgency();
    fetchVendors();
  }, []);

  return (
    <div>
      {error ? (
        <ErrorPage />
      ) : (
        <div>
          {!loading ? (
            <Loader />
          ) : (
            <React.Fragment>
              <div className="flex flex-col sm:flex sm:flex-col p-5">
                <div className="flex sm:items-start items-center justify-center sm:justify-start xl:ml-20 2xl:ml-20 sm:ml-20 ml-0 sm:mt-0 mt-5">
                  <div>
                    <Button
                      name={"Add Travel Agency"}
                      clickFunction={() => setView(true)}
                    />
                  </div>
                </div>
                <div className="flex items-center justify-center  xl:ml-20 2xl:ml-20 lg:ml-20 md:ml-20 sm:ml-20 ml-0">
                  <div className="w-full mt-5">
                    <TravelAgencyTable
                      data={vehicles}
                      updateView={handleVehicleUpdateView}
                      deleteView={handleDeleteVehicleView}
                    />
                  </div>
                </div>
              </div>
              {view && (
                <TravelForm
                  onClose={() => setView(false)}
                  vendors={vendors}
                  onSubmit={handleAddTravelAgency}
                />
              )}
              {update && (
                <VehicleUpdateForm
                  onClose={() => setUpdate(false)}
                  data={selectedVehicle}
                  onSubmit={handleUpdateVehicle}
                />
              )}
              {Delete && (
                <DeleteModal
                  title={"Remove vehicle"}
                  message={`Are you sure you want to remove ${selectedVehicle.name} with number ${selectedVehicle.number_plate}?`}
                  onClose={() => setDelete(false)}
                  onDelete={handleDeleteVehicle}
                  icon={true}
                />
              )}
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  );
}

export default Travel;

import { ErrorMessage, Form, Formik } from "formik";
import React from "react";
import { RxCross1 } from "react-icons/rx";
import * as Yup from "yup";
import FormikInput from "../Input/FormikInput";
import FormikSelect from "../Input/FormikSelect";
import { GenderOption, option } from "../Data";
import Button from "../Button/Button";

function SettingArtistForm({ onClose, onSubmit }) {
  const validationSchema = Yup.object().shape({
    artist_name: Yup.string()
      .min(2, "Artist name should have at least 2 Characters")
      .required("Artist name is required")
      .trim("Artist name cannot start or end with spaces"),
    gender: Yup.string().required("Select gender of artist"),
    phone_number: Yup.string()
      .required("Contact number is required")
      .matches(/^[0-9]{10}$/, "Contact number must have exactly 10 digits"),
    email: Yup.string()
      .required("Please enter a valid email")
      .email("Invalid email format"),
    pan_card_number: Yup.string()
      .required("PAN number is required")
      .matches(
        /^[A-Z]{5}[0-9]{4}[A-Z]$/,
        "Invalid PAN number. Format should be ABCDE1234F"
      ),
    bank_account_number: Yup.string()
      .required("Bank account number is required")
      .matches(/^\d+$/, "Bank account number must contain only digits")
      .min(9, "Bank account number is too short")
      .max(18, "Bank account number is too long"),
    bank_name: Yup.string().required("Bank name is required"),
    ifsc: Yup.string()
      .required("IFSC code is required")
      .matches(
        /^[A-Za-z]{4}[0][A-Za-z0-9]{6}$/,
        "Invalid IFSC code. Format should be XXXX0YYYYYY"
      ),
    bank_branch: Yup.string().required("Bank branch name required"),
    gst_number: Yup.string()
      .matches(
        /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Z]{1}[0-9A-Za-z]{1}$/,
        "Invalid GST number"
      )
      .required("GST number is required"),
    aadhar_card: Yup.mixed().required("Please select PDF of aadhar card"),
    pan_card: Yup.mixed().required("Please select PDF of PAN card"),
    cancel_cheque: Yup.mixed().required("Please select cancel cheque"),
  });

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 overflow-auto bg-black bg-opacity-50 ">
      <div className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto ">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-5xl w-full mx-auto ">
          <div className="flex items-end justify-end">
            <button
              className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full "
              onClick={onClose}
            >
              <RxCross1 />
            </button>
          </div>
          <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
            Artist
          </h2>
          <div>
            <Formik
              initialValues={{
                artist_name: "",
                gender: "",
                phone_number: "",
                email: "",
                pan_card_number: "",
                bank_account_number: "",
                bank_branch: "",
                bank_name: "",
                ifsc: "",
                gst_number: "",
                aadhar_card: "",
                pan_card: "",
                cancel_cheque: "",
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ values, setFieldValue, errors }) => (
                <Form>
                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 sm:gap-4 mt-2 sm:mt-4">
                    <div>
                      <FormikInput
                        name={"artist_name"}
                        label={"Artist Name"}
                        type={"text"}
                        placeholder={"Willem Dafoe"}
                      />
                    </div>
                    <div>
                      <FormikSelect
                        name={"gender"}
                        selectedOption={"Gender"}
                        label={"Gender"}
                        optionData={GenderOption}
                        onChange={(e) =>
                          setFieldValue("gender", e.target.value)
                        }
                      />
                    </div>
                    <div>
                      <FormikInput
                        type={"tel"}
                        name={"phone_number"}
                        label={"Contact number"}
                        placeholder={"9584******"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"email"}
                        type={"email"}
                        label={"Email"}
                        placeholder={"willemdafoe@example.com"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"pan_card_number"}
                        type={"text"}
                        label={"PAN number"}
                        placeholder={"ABCTY1234D"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"bank_account_number"}
                        min={0}
                        type={"number"}
                        label={"Bank account number"}
                        placeholder={"0112345678"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"bank_branch"}
                        type={"text"}
                        label={"Bank Branch"}
                        placeholder={"Ahmedabad"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"bank_name"}
                        type={"text"}
                        label={"Bank Name"}
                        placeholder={"MoneyZone"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"ifsc"}
                        type={"text"}
                        label={"IFSC Code"}
                        placeholder={"SBIN0011513"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"gst_number"}
                        label={"GST number"}
                        placeholder={"22AAAAA0000A1Z5"}
                        type={"text"}
                      />
                    </div>
                    <div>
                      <label className="block mb-2 text-sm text-gray-600 ">
                        Aadhar card
                      </label>
                      <input
                        name="aadhar_card"
                        type="file"
                        accept=".pdf,image/*"
                        onChange={(event) => {
                          setFieldValue(
                            "aadhar_card",
                            event.currentTarget.files[0]
                          );
                        }}
                        className="cursor-pointer block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#24b7aa]  focus:ring-[#24b7aa] focus:outline-none focus:ring focus:ring-opacity-40"
                      />
                      <ErrorMessage
                        name={"aadhar_card"}
                        className="text-red-500 text-sm mt-1"
                        component="div"
                      />
                    </div>
                    <div>
                      <label className="block mb-2 text-sm text-gray-600 ">
                        PAN card
                      </label>
                      <input
                        name="pan_card"
                        type="file"
                        accept=".pdf,image/*"
                        onChange={(event) => {
                          setFieldValue(
                            "pan_card",
                            event.currentTarget.files[0]
                          );
                        }}
                        className="cursor-pointer block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#24b7aa]  focus:ring-[#24b7aa] focus:outline-none focus:ring focus:ring-opacity-40"
                      />
                      <ErrorMessage
                        name={"pan_card"}
                        className="text-red-500 text-sm mt-1"
                        component="div"
                      />
                    </div>
                    <div>
                      <label className="block mb-2 text-sm text-gray-600 ">
                        Cancel cheque
                      </label>
                      <input
                        name="cancel_cheque"
                        type="file"
                        accept=".pdf,image/*"
                        onChange={(event) => {
                          setFieldValue(
                            "cancel_cheque",
                            event.currentTarget.files[0]
                          );
                        }}
                        className="cursor-pointer block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#24b7aa]  focus:ring-[#24b7aa] focus:outline-none focus:ring focus:ring-opacity-40"
                      />
                      <ErrorMessage
                        name={"cancel_cheque"}
                        className="text-red-500 text-sm mt-1"
                        component="div"
                      />
                    </div>
                  </div>
                  <div className="sm:mt-4 mt-2">
                    <Button name={"Add"} type={"submit"} />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SettingArtistForm;

import React, { useEffect, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import moment from "moment-timezone";
import "moment/locale/en-in";
import Voucher from "./Voucher";
import axios from "axios";
import SecureLS from "secure-ls";
import { RxCross1 } from "react-icons/rx";

function InstallmentDetails({ setOpen, data, name }) {
  const [viewVoucher, setViewVoucher] = useState(false);
  const [selectedInstallment, setSelectedInstallment] = useState("");
  const [user, setUser] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(5);
  const ls = new SecureLS();

  const user_id = ls.get("User id");

  const handleOpenVoucher = (installment) => {
    setViewVoucher(true);
    setSelectedInstallment({ ...installment });
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const fetchUserDetails = () => {
    axios
      .get(`api/v1/users/find_user?user_id=${user_id}`)
      .then((response) => {
        setUser(response.data.user);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  useEffect(() => {
    fetchUserDetails();
  }, []);

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.installments.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(data.installments.length / itemsPerPage);

  const convertToIST = (utcDateStr) => {
    return moment.utc(utcDateStr).tz("Asia/Kolkata").format("DD-MM-YYYY");
  };

  return (
    <div>
      <div
        onClick={() => setOpen(false)}
        className={`${
          viewVoucher
            ? "hidden fixed"
            : "flex fixed inset-0 items-center justify-center z-50 overflow-auto no-scrollbar bg-black bg-opacity-50"
        }`}
      >
        <div className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto ">
          <div
            onClick={(e) => e.stopPropagation()}
            className="bg-white p-4 sm:p-8 rounded shadow-md w-2/5 sm:w-4/5 sm:my-auto  my-auto"
          >
            <div className="flex items-end justify-end">
              <button
                className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full "
                onClick={() => setOpen(false)}
              >
                <RxCross1 />
              </button>
            </div>
            <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
              {name}
            </h2>
            <div className="w-full mx-auto bg-white  rounded-sm border border-gray-200">
              <header className="flex sm:flex-row flex-col  justify-between px-5 py-4 border-b border-gray-100">
                <h2 className="font-semibold text-gray-800 mr-5">
                  {name}'s installments
                </h2>
                {totalPages > 1 && (
                  <div className="flex items-start justify-start sm:justify-end sm:items-center sm:mt-0 mt-2 relative">
                  <ReactPaginate
                    pageCount={totalPages}
                    onPageChange={handlePageChange}
                    containerClassName={"flex items-center space-x-4"}
                    previousLabel={<FaChevronLeft />}
                    nextLabel={<FaChevronRight />}
                    activeClassName={"active"}
                    previousClassName={
                      "bg-[#1d5b79] text-[#fbffff] w-7 h-7 items-center justify-center p-2 text-xl flex rounded"
                    }
                    nextClassName={
                      "bg-[#1d5b79] text-[#fbffff] w-7 h-7 items-center justify-center p-2 text-xl flex rounded"
                    }
                    pageClassName={"hidden"}
                    pageLinkClassName={"hidden"}
                    breakClassName={"hidden"}
                    renderOnZeroPageCount={null}
                    breakLinkClassName={
                      "px-8 py-1 bg-gray-200 text-gray-600 rounded"
                    }
                  />
                  <div className="mx-2">
                    Page {currentPage + 1} of {totalPages}
                  </div>
                </div>
                )}
              </header>
              <div className="p-3">
                <div className="overflow-x-auto">
                  <table className="table-auto w-full">
                    <thead className="text-xs font-semibold uppercase text-gray-600 bg-[#eceff1]">
                      <tr>
                        <th className="px-4 py-2 whitespace-nowrap">
                          <div className="font-semibold text-left">
                            Installment name
                          </div>
                        </th>
                        <th className="px-4 py-2 whitespace-nowrap">
                          <div className="font-semibold text-left">Date</div>
                        </th>
                        <th className="px-4 py-2 whitespace-nowrap">
                          <div className="font-semibold text-left">Amount</div>
                        </th>
                        <th className="px-4 py-2 whitespace-nowrap">
                          <div className="font-semibold text-left">
                            TDS amount
                          </div>
                        </th>
                        <th className="px-4 py-2 whitespace-nowrap">
                          <div className="font-semibold text-left">
                            GST amount
                          </div>
                        </th>
                        <th className="px-4 py-2 whitespace-nowrap">
                          <div className="font-semibold text-left">
                            Payment mode
                          </div>
                        </th>
                        <th className="px-4 py-2 whitespace-nowrap">
                          <div className="font-semibold text-left">
                            Reference number
                          </div>
                        </th>
                        <th className="px-4 py-2 whitespace-nowrap">
                          <div className="font-semibold text-left">Voucher</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-sm divide-y divide-gray-100">
                      {currentItems.map((installment) => {
                        return (
                          <tr>
                            <td className="p-2 whitespace-nowrap">
                              {installment.i_name}
                            </td>
                            <td className="p-2 whitespace-nowrap">
                              {convertToIST(installment.created_at)}
                            </td>
                            <td className="p-2">
                              <div className="text-left">
                                ₹{installment.amount}
                              </div>
                            </td>
                            <td className="p-2">
                              <div className="text-left">
                                ₹{installment.tds_amount}
                              </div>
                            </td>
                            <td className="p-2">
                              <div className="text-left">
                                ₹{installment.gst_amount}
                              </div>
                            </td>
                            <td className="p-2">
                              <div className="text-left">
                                {installment.payment_mode}
                              </div>
                            </td>
                            <td className="p-2">
                              <div className="text-left">
                                {installment.payment_reference}
                              </div>
                            </td>
                            <td className="p-2">
                              <div className="text-left">
                                <button
                                  onClick={() => handleOpenVoucher(installment)}
                                  className="font-semibold hover:underline"
                                >
                                  View
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {viewVoucher && (
        <Voucher
          installment={selectedInstallment}
          onClose={() => setViewVoucher(false)}
          user={user}
          name={name}
        />
      )}
    </div>
  );
}

export default InstallmentDetails;

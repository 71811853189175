import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { AnimatePresence, motion } from "framer-motion";
import { MdModeEdit } from "react-icons/md";
import { BiSolidTrashAlt } from "react-icons/bi";
import usePagination from "../Pagination/usePagination";
import Pagination from "../Pagination/Pagination";

function DishesTable({ caterer, updateView, deleteView }) {
  const [expandedDishes, setexpandedDishes] = useState({});

  const toggleView = (id) => {
    setexpandedDishes((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const transformResponse = (catererArray) => {
    let uid = 1;
    return catererArray.flatMap((response, index) => {
      const { dishes, vendor, id, c_type } = response;

      if (!Array.isArray(dishes)) {
        return [];
      }

      return dishes.map((dishObj) => {
        return {
          id,
          vendor,
          c_type,
          uid: uid++,
          dishDetails: dishObj,
        };
      });
    });
  };

  const transformedData = transformResponse(caterer);

  const { currentItems, currentPage, handlePageChange, totalPages } =
    usePagination(transformedData, 10);

  const itemVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
  };

  return (
    <div>
      <div className="flex flex-col h-full">
        <div className="w-full mx-auto bg-white rounded-sm border border-gray-200">
          <header className="flex sm:flex-row flex-col justify-between px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold text-gray-800 mr-5">Dishes</h2>
            <Pagination
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              totalPages={totalPages}
            />
          </header>
          <div className="p-3">
            <div className="overflow-x-auto">
              <table className="table-auto w-full">
                <thead className="text-xs font-semibold uppercase text-gray-600 bg-[#eceff1]">
                  <tr>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Vendor name</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Date</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        More details
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-center">Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody className="text-sm divide-y divide-gray-100">
                  {currentItems.map((item, index) => (
                    <React.Fragment key={index}>
                      <tr>
                        <td className="p-2 whitespace-nowrap">
                          {item.vendor.name}
                        </td>
                        <td className="p-2">
                          <div className="text-left">
                            {item.dishDetails.c_date}
                          </div>
                        </td>
                        <td className="p-2 whitespace-nowrap">
                          <button
                            onClick={() => toggleView(item.uid)}
                            className="text-xl border-none outline-none text-[#fbffff] bg-[#1d5b79] rounded-full p-1"
                          >
                            {expandedDishes[item.uid] ? (
                              <FaChevronUp size={15} />
                            ) : (
                              <FaChevronDown size={15} />
                            )}
                          </button>
                        </td>
                        <td className="p-2">
                          <div>
                            <button
                              onClick={() => updateView(item)}
                              className="p-4 text-xl text-green-600 border-none hover:shadow-none rounded-full outline-none bg-green-50  duration-300 ease-in"
                            >
                              <MdModeEdit />
                            </button>
                            <button
                              onClick={() => deleteView(item)}
                              className="ml-4 p-4 text-xl text-red-600 bg-red-50 hover:shadow-none rounded-full  duration-300 ease-in outline-none"
                            >
                              <BiSolidTrashAlt />
                            </button>
                          </div>
                        </td>
                      </tr>

                      <AnimatePresence>
                        {expandedDishes[item.uid] && (
                          <motion.tr
                            key={item.uid}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            variants={itemVariants}
                            className="bg-gray-50"
                            transition={{ duration: 0.3, delay: 0.1 }}
                          >
                            <td colSpan={6} className="p-4">
                              {item.c_type === "Package" ? (
                                <div>
                                  <div className="grid sm:grid-cols-4 grid-cols-1 gap-2 sm:gap-4">
                                    <div className="flex flex-row">
                                      <h2 className="text-sm font-semibold text-[#1d5b79]">
                                        No of Dishes:
                                      </h2>
                                      <h2 className="text-sm ml-1">
                                        {item.dishDetails.number_of_dishes}
                                      </h2>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  <div className="grid sm:grid-cols-4 grid-cols-1 gap-2 sm:gap-4">
                                    <div className="flex flex-row">
                                      <h2 className="text-sm font-semibold text-[#1d5b79]">
                                        Breakfast dishes:
                                      </h2>
                                      <h2 className="text-sm ml-1">
                                        {item.dishDetails.breakfast_dishes}
                                      </h2>
                                    </div>
                                    <div className="flex flex-row">
                                      <h2 className="text-sm font-semibold text-[#1d5b79]">
                                        Lunch dishes:
                                      </h2>
                                      <h2 className="text-sm ml-1">
                                        {item.dishDetails.lunch_dishes}
                                      </h2>
                                    </div>
                                    <div className="flex flex-row">
                                      <h2 className="text-sm font-semibold text-[#1d5b79]">
                                        High tea dishes:
                                      </h2>
                                      <h2 className="text-sm ml-1">
                                        {item.dishDetails.high_tea_dishes}
                                      </h2>
                                    </div>
                                    <div className="flex flex-row">
                                      <h2 className="text-sm font-semibold text-[#1d5b79]">
                                        Dinner dishes:
                                      </h2>
                                      <h2 className="text-sm ml-1">
                                        {item.dishDetails.dinner_dishes}
                                      </h2>
                                    </div>
                                    <div className="flex flex-row">
                                      <h2 className="text-sm font-semibold text-[#1d5b79]">
                                        Extra snacks:
                                      </h2>
                                      <h2 className="text-sm ml-1">
                                        {item.dishDetails.extra_dishes}
                                      </h2>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </td>
                          </motion.tr>
                        )}
                      </AnimatePresence>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DishesTable;

import React, { useEffect, useState } from "react";
import SecureLS from "secure-ls";
import Hotel from "./Hotel";
import AssignAccommodation from "./AssignAccommodation";

function HotelTab() {
  const [activeTab, setActiveTab] = useState(1);
  const ls = new SecureLS();
  const tabs = [
    { id: 1, label: "Add Accommodation" },
    { id: 2, label: "Assign Accommodation" },
  ];
  const renderContent = () => {
    switch (activeTab) {
      case 1:
        return <Hotel />;
      case 2:
        return <AssignAccommodation />;
      default:
        return <Hotel />;
    }
  };

  useEffect(() => {
    const storedTab = ls.get("hotelActiveTab");
    if (storedTab) {
      setActiveTab(parseInt(storedTab));
    } else {
      setActiveTab(1);
    }
  }, []);

  const handleTabClick = (tabId) => {
    ls.set("hotelActiveTab", tabId);
    setActiveTab(tabId);
  };
  return (
    <div>
      <nav className="bg-white w-auto border-b border-gray-300 flex flex-row items-start justify-start xl:ml-24 2xl:ml-24 md:ml-24 sm:ml-24 ml-0 xl:mt-10 mt-14 overflow-hidden overflow-x-auto no-scrollbar">
        {tabs.map((tab, index) => (
          <h1
            key={index}
            onClick={() => handleTabClick(tab.id)}
            className={`mx-3 py-2 px-3 cursor-pointer group relative ${
              activeTab === tab.id ? "border-[#1d5b79] border-b-[3px]" : ""
            }`}
          >
            {tab.label}
            <span className="absolute -bottom-1 left-0 w-0 h-[6px] bg-[#1d5b79] transition-all duration-300 group-hover:w-full" />
          </h1>
        ))}
      </nav>
      <div>{renderContent()}</div>
    </div>
  );
}

export default HotelTab;

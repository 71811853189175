import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Select from "react-select";
import { SidebarContext } from "./SuperAdmin/SidebarContext";
import { role } from "../components/Data";
import axios from "axios";
import TeamArtistAccount from "./AccountPages/TeamArtistAccount";
import TeamCrewAccount from "./AccountPages/TeamCrewAccount";
import TeamVendorAccount from "./AccountPages/TeamVendorAccount";

function TeamAccount() {
  const [activeTab, setActiveTab] = useState(1);
  const { view } = useContext(SidebarContext);

  const tabs = [
    { id: 1, label: "Artists" },
    { id: 2, label: "Crew" },
    { id: 3, label: "Vendors" },
  ];

  const renderContent = () => {
    switch (activeTab) {
      case 1:
        return <TeamArtistAccount />;
      case 2:
        return <TeamCrewAccount />;
      case 3:
        return <TeamVendorAccount />;
      default:
        return <TeamArtistAccount />;
    }
  };

  useEffect(() => {
    const storedTeamTab = localStorage.getItem("TeamAccountTab");
    if (storedTeamTab) {
      setActiveTab(parseInt(storedTeamTab));
    } else {
      setActiveTab(1);
    }
  }, []);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    localStorage.setItem("TeamAccountTab", tabId);
  };

  return (
    <div>
      <Sidebar />
      <div
        className={`h-screen p-7 ${
          !view ? "ml-40 duration-100 ease-in" : "ml-0 duration-100 ease-in"
        }`}
      >
        <div className="flex flex-col items-start justify-start sm:ml-20 ml-0">
          <nav className="bg-white w-full border-b border-gray-300 flex flex-row items-start justify-start   ml-0 xl:mt-10 mt-14 overflow-hidden overflow-x-auto no-scrollbar">
            {tabs.map((tab, index) => (
              <h1
                key={index}
                onClick={() => handleTabClick(tab.id)}
                className={`mx-3 py-2 px-3 cursor-pointer group relative ${
                  activeTab === tab.id ? "border-[#1d5b79] border-b-[3px]" : ""
                }`}
              >
                {tab.label}
                <span className="absolute -bottom-1 left-0 w-0 h-[6px] bg-[#1d5b79] transition-all duration-300 group-hover:w-full" />
              </h1>
            ))}
          </nav>
          <div className="w-full">{renderContent()}</div>
        </div>
      </div>
    </div>
  );
}

export default TeamAccount;

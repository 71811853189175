import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import { SidebarContext } from "./SuperAdmin/SidebarContext";
import Button from "../components/Button/Button";
import axios from "axios";
import PartnerForm from "../components/Forms/PartnerForm";
import * as Yup from "yup";
import ErrorPage from "./ErrorPage";
import Loader from "../Loader/loader";
import PartnerTable from "../components/Table/PartnerTable";
import toast from "react-hot-toast";
import UpdatePartnerForm from "../components/UpdateForm/UpdatePartnerForm";
import DeleteModal from "../Modal/DeleteModal";

function Partner() {
  const validationSchema = Yup.object().shape({
    partner_type: Yup.string().required("Partner type is required"),
    name: Yup.string()
      .required("Name is required")
      .min(2, "Name should be at least 2 characters long"),
    contact_number: Yup.string()
      .required("Contact number is required")
      .matches(/^\d{10}$/, "Contact number must be exactly 10 digits"),
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email format"),
    gst_number: Yup.string()
      .required("GST number is required")
      .matches(
        /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Z]{1}[0-9A-Za-z]{1}$/,
        "Invalid GST number"
      ),
    pan_number: Yup.string()
      .required("PAN number is required")
      .matches(
        /^[A-Z]{5}[0-9]{4}[A-Z]$/,
        "Invalid PAN number. Format should be ABCDE1234F"
      ),
  });
  const { view } = useContext(SidebarContext);
  const [open, setOpen] = useState(false);
  const [update, setUpdate] = useState(false);
  const [Delete, setDelete] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState("");
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleUpdateView = (partner) => {
    setUpdate(true);
    setSelectedPartner({ ...partner });
  };
  const handleDeleteView = (partner) => {
    setDelete(true);
    setSelectedPartner({ ...partner });
  };

  const fetchPartners = () => {
    axios
      .get(`api/v1/partners`)
      .then((res) => {
        setPartners(res.data.partners);
        setLoading(true);
      })
      .catch((err) => {
        setError(true);
        console.log(err);
      });
  };

  const handleAddPartner = (value, { resetForm }) => {
    const loader = toast.loading("Adding partner...");
    axios
      .post(`api/v1/partners`, {
        partner: {
          partner_type: value.partner_type,
          name: value.name,
          contact_number: value.contact_number,
          email: value.email,
          gst_number: value.gst_number,
          pan_number: value.pan_number,
        },
      })
      .then((res) => {
        if (res.status === 201) {
          resetForm();
          setOpen(false);
          fetchPartners();
          toast.dismiss(loader);
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        toast.dismiss(loader);
        toast.error(err.message);
        console.log(err);
      });
  };

  const handleUpdatePartner = (value, { resetForm }) => {
    const loader = toast.loading("Updating partner...");
    axios
      .put(`api/v1/partners/${selectedPartner.id}`, {
        partner: {
          partner_type: value.partner_type,
          name: value.name,
          contact_number: value.contact_number,
          email: value.email,
          gst_number: value.gst_number,
          pan_number: value.pan_number,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          resetForm();
          setUpdate(false);
          fetchPartners();
          toast.dismiss(loader);
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        toast.dismiss(loader);
        toast.error(err.message);
        console.log(err);
      });
  };

  const handleDeletePartner = () => {
    const loader = toast.loading("Removing partner ...");
    axios
      .delete(`api/v1/partners/${selectedPartner.id}`)
      .then((res) => {
        if (res.status === 200) {
          toast.dismiss(loader);
          toast.success(res.data.message);
          setDelete(false);
          fetchPartners();
        }
      })
      .catch((err) => {
        toast.dismiss(loader);
        toast.error(err.message);
        console.log(err);
      });
  };

  useEffect(() => {
    fetchPartners();
  }, []);
  return (
    <div>
      <Sidebar />
      <div
        className={`h-screen p-7 ${
          !view ? "ml-40 duration-100 ease-in" : "ml-0 duration-100 ease-in"
        }`}
      >
        {error ? (
          <div>
            <ErrorPage />
          </div>
        ) : (
          <div>
            {!loading ? (
              <Loader />
            ) : (
              <div className="flex flex-col sm:flex sm:flex-col p-5">
                <div className="flex sm:items-start items-center justify-center sm:justify-start xl:ml-20 2xl:ml-20 md:ml-20 sm:ml-20 ml-0 sm:mt-0 mt-8">
                  <Button
                    name={"Add Partner"}
                    clickFunction={() => setOpen(true)}
                  />
                </div>
                <div className="flex items-center justify-center  xl:ml-20 2xl:ml-20 lg:ml-20 md:ml-20 sm:ml-20 ml-0">
                  <div className="w-full mt-5">
                    <PartnerTable
                      partners={partners}
                      updateView={handleUpdateView}
                      deleteView={handleDeleteView}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {open && (
        <PartnerForm
          onClose={() => setOpen(false)}
          validationSchema={validationSchema}
          onSubmit={handleAddPartner}
        />
      )}
      {update && (
        <UpdatePartnerForm
          onClose={() => setUpdate(false)}
          validationSchema={validationSchema}
          onSubmit={handleUpdatePartner}
          data={selectedPartner}
        />
      )}
      {Delete && (
        <DeleteModal
          title={"Remove Partner"}
          message={`Do you want to remove ${selectedPartner.name} ?`}
          onClose={() => setDelete(false)}
          onDelete={handleDeletePartner}
          icon={true}
        />
      )}
    </div>
  );
}

export default Partner;

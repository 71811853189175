import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import FormikSelect from "../Input/FormikSelect";
import FormikInput from "../Input/FormikInput";
import Button from "../Button/Button";
import * as Yup from "yup";
import { amountTypeOption, vendorType } from "../Data";

function ProjectVendorForm({
  setOpen,
  vendorData,
  addProjectVendor,
  projectVendordata,
}) {
  const [vendor, setVendor] = useState(vendorData);
  const validationSchema = Yup.object().shape({
    vendor: Yup.string().required("Vendor is required"),
    amount_type: Yup.string().required("Amount type is required"),
    amount: Yup.number()
      .typeError("Amount must be a number")
      .positive("Amount must be a positive number")
      .required("Amount is required"),
    quantity: Yup.number()
      .min(1, "Quantity must be at least 1")
      .required("Quantity is required")
      .notOneOf([0], "Quantity cannot be 0")
      .typeError("Quantity must be a valid number")
      .integer("Quantity must be a whole number"),
    number_of_days: Yup.number()
      .typeError("Number of days must be a number")
      .positive("Number of days must be a positive number")
      .required("Number of days is required"),
  });

  const handleVendorChange = (value, setFieldValue) => {
    const vendors = vendorData.filter((vend) => vend.vendor_type === value);
    if (value === "Type") {
      setVendor(vendorData);
      setFieldValue("type", value);
    } else {
      setVendor(vendors);
      setFieldValue("type", value);
    }
  };

  const filteredVendorType = vendorType.filter(
    (vendor) => vendor.name !== "Travel" && vendor.name !== "Catering"
  );
  const filterVendor = vendor.filter(
    (vendor) => vendor.vendor_type !== "Travel" && vendor.vendor_type !== "Catering"
  );

  return (
    <div className="fixed inset-0 flex items-center justify-center overflow-auto bg-black bg-opacity-50 z-50">
      <div className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto ">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-xl w-full mx-auto">
          <div className="flex items-end justify-end">
            <button
              onClick={() => setOpen(false)}
              className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full "
            >
              <RxCross1 />
            </button>
          </div>
          <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
            Vendor
          </h2>
          <Formik
            initialValues={{
              name: "",
              type: "",
              vendor: "",
              amount_type: "",
              amount: "",
              quantity: 1,
              number_of_days: "",
              nda_doc: "",
              agreement_doc: "",
            }}
            validationSchema={validationSchema}
            onSubmit={addProjectVendor}
          >
            {({ setFieldValue }) => (
              <Form>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                  <div>
                    <FormikSelect
                      name={"type"}
                      optionData={filteredVendorType}
                      selectedOption={"Type"}
                      label={"Type"}
                      onChange={(e) =>
                        handleVendorChange(e.target.value, setFieldValue)
                      }
                    />
                  </div>
                  <div>
                    <FormikSelect
                      optionData={filterVendor.filter(
                        (vendor) =>
                          !projectVendordata.some(
                            (projectvendor) =>
                              projectvendor.vendor_id === vendor.id
                          )
                      )}
                      name={"vendor"}
                      selectedOption={"Vendor"}
                      label={"Vendor"}
                      labelProperty="name"
                      valueProperty="id"
                      onChange={(e) => setFieldValue("vendor", e.target.value)}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                  <div>
                    <FormikSelect
                      name={"amount_type"}
                      label={"Payment type"}
                      onChange={(e) =>
                        setFieldValue("amount_type", e.target.value)
                      }
                      selectedOption={"Payment type"}
                      optionData={amountTypeOption}
                    />
                  </div>
                  <div>
                    <FormikInput
                      name={"amount"}
                      label={"Amount"}
                      placeholder={"12xxxx"}
                      type={"text"}
                    />
                  </div>
                  <div>
                    <FormikInput
                      name={"number_of_days"}
                      label={"Number of Days"}
                      placeholder={"12"}
                      type={"text"}
                    />
                  </div>
                  <div>
                    <FormikInput
                      name={"quantity"}
                      label={"Quantity"}
                      placeholder={"12"}
                      type={"number"}
                    />
                  </div>
                  <div>
                    <label className="block mb-2 text-sm text-gray-600 ">
                      NDA
                    </label>
                    <input
                      name="nda_doc"
                      type="file"
                      accept=".pdf,image/*"
                      onChange={(event) => {
                        setFieldValue("nda_doc", event.currentTarget.files[0]);
                      }}
                      className="cursor-pointer block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#24b7aa]  focus:ring-[#24b7aa] focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                  </div>
                  <div>
                    <label className="block mb-2 text-sm text-gray-600 ">
                      Agreement
                    </label>
                    <input
                      name="agreement_doc"
                      type="file"
                      accept=".pdf,image/*"
                      onChange={(event) => {
                        setFieldValue(
                          "agreement_doc",
                          event.currentTarget.files[0]
                        );
                      }}
                      className="cursor-pointer block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#24b7aa]  focus:ring-[#24b7aa] focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                  </div>
                </div>
                <div className="mt-2 sm:mt-4">
                  <Button name={"Add"} type={"submit"} />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default ProjectVendorForm;

import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useRef } from "react";
import { RxCross1 } from "react-icons/rx";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import "react-multi-date-picker/styles/colors/red.css";
import Button from "../components/Button/Button";
import FormikRadio from "../components/Input/FormikRadio";
import FormikSelect from "../components/Input/FormikSelect";
import { FaPlus } from "react-icons/fa";
import moment from "moment-timezone";
import "moment/locale/en-in";
import FormikInput from "../components/Input/FormikInput";

function AttendanceModal({ onClose, name, data, onSubmit }) {
  const shiftType = [
    { name: "Half", value: "half" },
    { name: "Full", value: "full" },
  ];
  const convertToIST = (utcDateStr) => {
    return moment.utc(utcDateStr).tz("Asia/Kolkata").format("DD/MM/YYYY");
  };

  const startDate =
    data.attendance.length > 0
      ? convertToIST(data.attendance[0].start_date)
      : "";
  const endDate =
    data.attendance.length > 0 ? convertToIST(data.attendance[0].end_date) : "";
  const combinedArray = [startDate, endDate];

  const numberOfDates =
    data.attendance.length > 0 ? data.attendance[0].number_of_dates : [];

  return (
    <div>
      <div className="fixed inset-0 flex items-center justify-center overflow-auto z-50 bg-black bg-opacity-50">
        <div className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto">
          <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-5xl w-full mx-auto">
            <div className="flex items-end justify-end">
              <button
                className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full"
                onClick={onClose}
              >
                <RxCross1 />
              </button>
            </div>
            <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
              {name}'s Attendance
            </h2>
            <Formik
              initialValues={{
                date:
                  data.amount_type === "Per Day"
                    ? numberOfDates
                    : combinedArray || ["", ""],
                extra: false,
                extra_shift_info: [],
              }}
              enableReinitialize
              onSubmit={onSubmit}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="mt-2 sm:mt-4">
                    <label className="block mb-2 text-sm text-gray-600 ">
                      Dates
                    </label>
                    {data.amount_type === "Per Day" ? (
                      <DatePicker
                        multiple
                        name="date"
                        value={values.date}
                        placeholder="Date"
                        plugins={[<DatePanel />]}
                        format="DD/MM/YYYY"
                        sort
                        onChange={(value) => {
                          const formattedDates = value.map((date) =>
                            date.format("DD/MM/YYYY")
                          );
                          setFieldValue("date", formattedDates);
                        }}
                        style={{ height: 38, width: "100%" }}
                      />
                    ) : (
                      <DatePicker
                        range
                        name="date"
                        value={values.date}
                        plugins={[<DatePanel />]}
                        placeholder="Date"
                        format="DD/MM/YYYY"
                        sort
                        onChange={(value) => {
                          const formattedDates = value.map((date) =>
                            date.format("DD/MM/YYYY")
                          );
                          setFieldValue("date", formattedDates);
                        }}
                        style={{ height: 38, width: "100%" }}
                      />
                    )}
                  </div>

                  <div className="flex flex-col sm:mt-4 mt-2">
                    <div>
                      <label className="block mb-2 text-sm text-gray-600 ">
                        Extra shift?
                      </label>
                      <div className="flex flex-row">
                        <div>
                          <FormikRadio
                            id={"yes"}
                            label={"Yes"}
                            value={true}
                            onChange={() => setFieldValue("extra", true)}
                            name={"extra"}
                          />
                        </div>
                        <div className="ml-2">
                          <FormikRadio
                            id={"no"}
                            value={false}
                            onChange={() => setFieldValue("extra", false)}
                            label={"No"}
                            name={"extra"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {values.extra === true ? (
                    <FieldArray name="extra_shift_info">
                      {({ push, remove }) => (
                        <div>
                          {values.extra_shift_info.map((_, index) => (
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                              <div>
                                <FormikSelect
                                  name={`extra_shift_info[${index}].shift_type`}
                                  optionData={shiftType}
                                  label={"Shift type"}
                                  valueProperty="value"
                                  onChange={(e) =>
                                    setFieldValue(
                                      `extra_shift_info[${index}].shift_type`,
                                      e.target.value
                                    )
                                  }
                                  selectedOption={"Shift type"}
                                />
                              </div>
                              <div>
                                <FormikInput
                                  name={`extra_shift_info[${index}].shift_date`}
                                  label={"Shift date"}
                                  type={"date"}
                                />
                              </div>
                            </div>
                          ))}
                          <div className="flex flex-row">
                            <label className="block mt-10 text-sm text-gray-600 ">
                              Add shift:
                            </label>
                            <button
                              type="button"
                              onClick={() =>
                                push({
                                  shift_type: "",
                                  shift_date: "",
                                })
                              }
                              className="mt-8 ml-2 h-10 flex items-center justify-center w-10 text-xl bg-[#1d5b79] text-[#fbffff] p-2 rounded-full hover:bg-[#a9c8e2] ease-in-out duration-300"
                            >
                              <FaPlus />
                            </button>
                          </div>
                        </div>
                      )}
                    </FieldArray>
                  ) : (
                    <div></div>
                  )}
                  <div className="sm:mt-4 mt-2">
                    <Button type={"submit"} name={"Add"} />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AttendanceModal;

import React, { useContext, useEffect, useState } from "react";
import { MdModeEdit } from "react-icons/md";
import PaymentModal from "../../Modal/PaymentModal";
import axios from "axios";
import toast from "react-hot-toast";
import { FaCalendar, FaChevronDown, FaChevronUp } from "react-icons/fa";
import InstallmentDetails from "../../Modal/InstallmentDetails";
import SecureLS from "secure-ls";
import { HiddenContext } from "../../Context/HiddenContent";
import { AnimatePresence, motion } from "framer-motion";
import moment from "moment-timezone";
import "moment/locale/en-in";
import { IoDocument } from "react-icons/io5";
import NoDue from "../NoDue";
import usePagination from "../Pagination/usePagination";
import Pagination from "../Pagination/Pagination";

function ArtistAccountTable({
  view,
  setView,
  artistData,
  projectId,
  getArtistData,
}) {
  const [open, setOpen] = useState(false);
  const [selectedArtist, setSelectedArtist] = useState("");
  const [expandedInfo, setExpandedInfo] = useState({});

  const [user, setUser] = useState("");
  const [viewNoDue, setViewNoDue] = useState(false);
  const ls = new SecureLS();
  const user_id = ls.get("User id");
  const { hidden } = useContext(HiddenContext);

  const { currentItems, currentPage, handlePageChange, totalPages } =
    usePagination(artistData, 10);

  const openPaymentModal = (id) => {
    const artistSelected = artistData.find((artist) => artist.artist_id === id);
    setSelectedArtist(artistSelected);
    setView(true);
  };
  const closePaymentModal = () => {
    setView(false);
  };
  const stopClose = (e) => {
    e.stopPropagation();
  };

  const toggleView = (id) => {
    setExpandedInfo((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const itemVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
  };

  const paymentHandler = (value, { resetForm }) => {
    const loader = toast.loading("Adding Installment...", { autoClose: false });
    const amount = parseInt(value.amount) + parseInt(value.gst);
    const formData = new FormData();

    formData.append(
      "installment[project_artist_id]",
      selectedArtist.project_artist_id
    );
    formData.append("installment[i_name]", value.installment_name);
    formData.append("installment[amount]", value.amount);
    formData.append("installment[payment_mode]", value.payment_mode);
    formData.append("installment[payment_reference]", value.payment_reference);
    formData.append("installment[tds_amount]", value.tds);
    formData.append("installment[gst_amount]", value.gst);
    formData.append("installment[user_id]", user_id);
    formData.append("installment[transaction_date]", value.transaction_date);

    [...value.bill].forEach((bill, index) => {
      formData.append(`installment[bills][]`, bill);
    });

    axios
      .post(`api/v1/installments`, formData)
      .then((response) => {
        setView(false);
        toast.dismiss(loader);
        toast.success(response.data.message);
        getArtistData();
        resetForm();
      })
      .catch((error) => {
        toast.dismiss(loader);
        toast.error(error.message);
      });
  };

  const handleOpenInstallmentModal = (installment) => {
    setSelectedArtist({ ...installment });
    setOpen(true);
  };

  const handleOpenNoDue = (installment) => {
    setViewNoDue(true);
    setSelectedArtist({ ...installment });
  };

  const fetchUserDetails = () => {
    axios
      .get(`api/v1/users/find_user?user_id=${user_id}`)
      .then((response) => {
        setUser(response.data.user);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const convertToIST = (utcDateStr) => {
    return moment.utc(utcDateStr).tz("Asia/Kolkata").format("DD-MM-YYYY");
  };
  return (
    <>
      <div className="flex flex-col h-full">
        <div className="w-full mx-auto bg-white  rounded-sm border border-gray-200">
          <header className="flex sm:flex-row flex-col  justify-between px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold text-gray-800">Artist account</h2>
            <Pagination
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              totalPages={totalPages}
            />
          </header>
          <div className="p-3">
            <div className="overflow-x-auto">
              <table className="table-auto w-full">
                <thead className="text-xs font-semibold uppercase text-gray-600 bg-[#eceff1]">
                  <tr>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Name</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Amount</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Payment Type
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">No of days</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-center">
                        Installment Detail
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-center">TDS Due</div>
                    </th>

                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-center">GST Due</div>
                    </th>

                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-center">
                        Paid amount
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-center">
                        Amount due
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-center">View More</div>
                    </th>
                    {hidden ? (
                      <></>
                    ) : (
                      <th className="px-4 py-2 whitespace-nowrap">
                        <div className="font-semibold text-center">Action</div>
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody className="text-sm divide-y divide-gray-100">
                  {currentItems.map((data, index) => {
                    const amountDue =
                      data.amount_type === "Per Day"
                        ? parseInt(data.amount) *
                            parseInt(data.number_of_days) +
                          parseInt(data.gst_due) -
                          parseInt(data.tds_amount) -
                          parseInt(data.amount_paid)
                        : parseInt(data.amount) +
                          parseInt(data.gst_due) -
                          parseInt(data.tds_amount) -
                          parseInt(data.amount_paid);
                    const amountPaid =
                      parseInt(data.amount_paid) +
                      parseInt(data.gst_paid) +
                      parseInt(data.tds_paid);
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td className="p-2 whitespace-nowrap">
                            {data.artist_name}
                          </td>
                          <td className="p-2">
                            <div className="text-left">
                              ₹{data.total_amount}
                            </div>
                          </td>
                          <td className="p-2">
                            <div className="text-left">{data.amount_type}</div>
                          </td>
                          <td className="p-2 whitespace-nowrap">
                            <div className="text-center">
                              {data.attendance[0] === undefined
                                ? data.number_of_days
                                : data.attendance[0].total_number_of_days}
                            </div>
                          </td>
                          <td className="p-2 whitespace-nowrap">
                            <div className="text-center">
                              <button
                                onClick={() => handleOpenInstallmentModal(data)}
                                className="font-semibold hover:underline"
                              >
                                View
                              </button>
                            </div>
                          </td>
                          <td className="p-2 whitespace-nowrap">
                            <div className="text-center">₹{data.tds_due}</div>
                          </td>
                          <td className="p-2 whitespace-nowrap">
                            <div className="text-center">₹{data.gst_due}</div>
                          </td>

                          <td className="p-2 whitespace-nowrap">
                            <div className="text-center">₹{amountPaid}</div>
                          </td>
                          <td className="p-2 whitespace-nowrap">
                            <div className="text-center">₹{amountDue}</div>
                          </td>
                          <td className="p-2">
                            <button
                              onClick={() => toggleView(data.artist_id)}
                              className="text-xl border-none outline-none text-[#fbffff] bg-[#1d5b79] rounded-full p-1"
                            >
                              {expandedInfo[data.artist_id] ? (
                                <FaChevronUp size={15} />
                              ) : (
                                <FaChevronDown size={15} />
                              )}
                            </button>
                          </td>
                          {hidden ? (
                            <></>
                          ) : (
                            <td className="p-2 whitespace-nowrap">
                              <button
                                onClick={() => openPaymentModal(data.artist_id)}
                                className={`${
                                  parseInt(amountDue) === 0
                                    ? "cursor-not-allowed"
                                    : "cursor-pointer"
                                } p-4 text-xl text-green-600 border-none hover:shadow-none rounded-full outline-none bg-green-50  duration-300 ease-in`}
                                disabled={parseInt(amountDue) === 0}
                              >
                                <MdModeEdit />
                              </button>
                              {parseInt(amountDue) === 0 && (
                                <button
                                  onClick={() => handleOpenNoDue(data)}
                                  className="p-4 text-xl text-blue-600 border-none hover:shadow-none rounded-full outline-none bg-blue-50  duration-300 ease-in"
                                >
                                  <IoDocument />
                                </button>
                              )}
                            </td>
                          )}
                        </tr>
                        <AnimatePresence>
                          {expandedInfo[data.artist_id] && (
                            <motion.tr
                              key={data.artist_id}
                              initial="hidden"
                              animate="visible"
                              exit="exit"
                              variants={itemVariants}
                              className="bg-gray-50"
                              transition={{ duration: 0.3, delay: 0.1 }}
                            >
                              <td colSpan={10} className="p-4">
                                <div className="flex flex-col mt-4 w-full">
                                  <div className="flex items-start justify-start">
                                    <h2 className="text-base font-semibold text-[#1d5b79] uppercase">
                                      Artist Attendance
                                    </h2>
                                  </div>
                                  {data.amount_type === "Per Day" ? (
                                    <div>
                                      <div className="grid sm:grid-cols-6 grid-cols-3 gap-2 sm:gap-4 mt-2">
                                        {data.attendance[0] &&
                                          data.attendance[0].number_of_dates.map(
                                            (date) => (
                                              <h2 className="flex flex-row text-sm text-[#1d5b79]">
                                                <FaCalendar className="mr-2 mt-1" />{" "}
                                                {date}
                                              </h2>
                                            )
                                          )}
                                      </div>
                                    </div>
                                  ) : (
                                    <div>
                                      {data.attendance[0] && (
                                        <div className="grid sm:grid-cols-2 grid-cols-2 gap-2 sm:gap-4 mt-2">
                                          <div className="flex flex-row">
                                            <h2 className="text-sm font-semibold text-[#1d5b79]">
                                              Start date:
                                            </h2>
                                            <h2 className="text-sm ml-1">
                                              {convertToIST(
                                                data.attendance[0].start_date
                                              )}
                                            </h2>
                                          </div>
                                          <div className="flex flex-row">
                                            <h2 className="text-sm font-semibold text-[#1d5b79]">
                                              End date:
                                            </h2>
                                            <h2 className="text-sm ml-1">
                                              {convertToIST(
                                                data.attendance[0].end_date
                                              )}
                                            </h2>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </td>
                            </motion.tr>
                          )}
                        </AnimatePresence>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {view && (
        <PaymentModal
          data={selectedArtist}
          closePaymentModal={closePaymentModal}
          stopClose={stopClose}
          paymentHandler={paymentHandler}
          name={selectedArtist.artist_name}
        />
      )}
      {open && (
        <InstallmentDetails
          setOpen={setOpen}
          data={selectedArtist}
          name={selectedArtist.artist_name}
        />
      )}
      {viewNoDue && (
        <NoDue
          data={selectedArtist}
          name={selectedArtist.artist_name}
          onClose={() => setViewNoDue(false)}
          role={"Artist"}
          user={user}
        />
      )}
    </>
  );
}

export default ArtistAccountTable;

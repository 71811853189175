import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../../components/Button/Button";
import * as Yup from "yup";
import ShotDivisionForm from "../../components/Forms/ShotDivisionForm";
import ShotDivisionUserTable from "../../components/Table/ShotDivisionUserTable";
import ShotDivisionUpdateForm from "../../components/Forms/ShotDivisionUpdateForm";
import { useReactToPrint } from "react-to-print";
import { BsDownload } from "react-icons/bs";
import SubRoleContext from "../../SubRoleContext";
import Loader from "../../Loader/loader";
import ErrorPage from "../ErrorPage";
import DownloadButton from "../../components/Button/DownloadButton";

function ShotDivisionUser() {
  const subRole = useContext(SubRoleContext);
  const { projectId } = useParams();
  const [scenes, setScenes] = useState([]);
  const [shotDetails, setShotDetails] = useState([]);
  const [selectedShotDivision, setSelectedShotDivision] = useState([]);
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(true);
  const [updateview, setUpdateView] = useState(true);
  const [IdforUpdate, setIdforUpdate] = useState(null);
  const [error, setError] = useState(false);
  useEffect(() => {
    fetchSceneDetails();
    fetchShotDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchShotDetails = () => {
    axios
      .get(`api/v1/projects/${projectId}/shot_details`)
      .then((res) => {
        const sortedSceneDetails = res.data.shot_details.sort(
          (a, b) => a.scene.scene_number - b.scene.scene_number
        );
        setLoading(true);
        setShotDetails(sortedSceneDetails);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 422) {
            setLoading(true);
          } else {
            setError(true);
          }
        } else {
          setError(true);
          console.log(err);
        }
      });
  };

  const fetchSceneDetails = () => {
    axios
      .get(`api/v1/projects/${projectId}/scenes`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "ngrok-skip-browser-warning": true,
        },
      })
      .then((response) => {
        setScenes(response.data.scenes);
      })
      .catch((err) => console.log(err));
  };

  const validationSchema = Yup.object({
    scene_number: Yup.number().required("Scene number is required"),
    number_of_shot: Yup.number().required("Number of shots is required"),
    shot_details: Yup.object({
      details: Yup.array().of(
        Yup.object().shape({
          shottype: Yup.string().required("Please select shot type."),
          shotfocus: Yup.string(),
          shotangle: Yup.string(),
          shotframe: Yup.string(),
          shotmechanism: Yup.string(),
          shotmovements: Yup.string(),
          brief: Yup.string(),
          remark: Yup.string(),
        })
      ),
    }),
  });

  const openShotmodel = () => {
    setView(!view);
  };
  const contentToPrint = useRef(null);

  const handlePrint = useReactToPrint({
    documentTitle: "Print This Document",
    content: () => contentToPrint.current,
  });
  let prevSceneNumber = null;

  const groupAndSortShotDetails = () => {
    // Group shot details by scene_number + sub_scene
    const groupedShotDetails = {};
    shotDetails.forEach((detail) => {
      const sceneNumber = detail.scene.scene_number + detail.scene.sub_scene;
      if (!groupedShotDetails[sceneNumber]) {
        groupedShotDetails[sceneNumber] = [];
      }
      groupedShotDetails[sceneNumber].push(detail);
    });

    // Sort each group by shot_number
    Object.keys(groupedShotDetails).forEach((sceneNumber) => {
      groupedShotDetails[sceneNumber].sort((a, b) => {
        const shotNumberA = parseInt(a.shot_number);
        const shotNumberB = parseInt(b.shot_number);
        if (shotNumberA === shotNumberB) {
          // If shot numbers are equal, compare by id to ensure consistent sorting
          return a.id - b.id;
        }
        return shotNumberA - shotNumberB;
      });
    });

    // Flatten the grouped and sorted shot details back into an array
    const sortedShotDetails = Object.values(groupedShotDetails).flat();

    // Sort the flattened array by scene_number + sub_scene
    sortedShotDetails.sort((a, b) => {
      const sceneNumberA = a.scene.scene_number + a.scene.sub_scene;
      const sceneNumberB = b.scene.scene_number + b.scene.sub_scene;
      if (sceneNumberA === sceneNumberB) {
        // If scene_numbers are equal, compare by shot_number
        return parseInt(a.shot_number) - parseInt(b.shot_number);
      }
      return sceneNumberA.localeCompare(sceneNumberB);
    });

    return sortedShotDetails;
  };

  const sortedShotDetails = groupAndSortShotDetails();

  return (
    <div>
      {error ? (
        <ErrorPage />
      ) : (
        <div>
          {!loading ? (
            <Loader />
          ) : (
            <>
              <div className="flex flex-row p-5">
                <div className="flex sm:flex-row flex-col items-center justify-between w-full xl:ml-20 2xl:ml-20 md:ml-20 sm:ml-20 ml-0 sm:mt-2 mt-8">
                  <div className="mt-2">
                    <Button
                      clickFunction={openShotmodel}
                      name={"Add Shot Division"}
                    />
                  </div>

                  <div className="flex items-end justify-end mt-2">
                    <DownloadButton
                      onClick={handlePrint}
                      name={"Print sheet"}
                    />
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-center xl:ml-24 2xl:ml-24 md:ml-24 lg:ml-24 sm:ml-24 ml-0">
                <div className="w-full mt-5">
                  <ShotDivisionUserTable
                    shotDetails={shotDetails}
                    fetchShotDetails={fetchShotDetails}
                    setUpdateView={setUpdateView}
                    updateview={updateview}
                    setSelectedShotDivision={setSelectedShotDivision}
                  />
                </div>
              </div>
            </>
          )}
          {!view && (
            <ShotDivisionForm
              setView={setView}
              view={view}
              validationSchema={validationSchema}
              scenes={scenes}
              shotDetails={shotDetails}
              fetchShotDetails={fetchShotDetails}
            />
          )}
          {!updateview && (
            <ShotDivisionUpdateForm
              setUpdateView={setUpdateView}
              updateview={updateview}
              validationSchema={validationSchema}
              scenes={scenes}
              shotDetails={shotDetails}
              fetchShotDetails={fetchShotDetails}
              selectedShotDivision={selectedShotDivision}
            />
          )}

          <div className="hidden flex-col  rounded-lg 2xl:shadow-lg lg:shadow-lg sm:shadow-lg sm:max-h-[full] lg:max-h-full  2xl:max-h-full">
            <div className="sm:-mx-6 lg:-mx-8 ">
              <div className="inline-block min-w-full  sm:px-6 lg:px-8">
                <div
                  className=" bg-[#fbffff]"
                  ref={contentToPrint}
                  style={{ margin: 20 }}
                >
                  <h1>{"Shot Division Sheet"}</h1>
                  <table className="min-w-full text-left text-sm  font-light border-2 border-gray-900">
                    <thead className=" font-medium bg-[#edf8f8] rounded-s-lg">
                      <tr className="border-2 border-gray-900">
                        <th className="p-4 whitespace-nowrap text-[#020002] border-2 border-gray-900">
                          Scene no.
                        </th>
                        <th className="p-4 whitespace-nowrap text-[#020002] border-2 border-gray-900">
                          Shot no.
                        </th>
                        <th className="p-4 whitespace-nowrap text-[#020002] border-2 border-gray-900">
                          Shots
                        </th>
                        <th className="p-4 whitespace-nowrap text-[#020002] border-2 border-gray-900">
                          Scene Brief
                        </th>
                        <th className="p-4 whitespace-nowrap text-[#020002] border-2 border-gray-900">
                          Remark
                        </th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {sortedShotDetails.map((details, index) => {
                        const sceneNumber =
                          details.scene.scene_number + details.scene.sub_scene;
                        const isFirstOfScene = prevSceneNumber !== sceneNumber;
                        prevSceneNumber = sceneNumber;

                        return (
                          <tr
                            key={details.id}
                            className={`text-sm border border-gray-900 text-left `}
                          >
                            <td className="p-4  font-extrabold border-2 border-gray-900 ">
                              {isFirstOfScene ? sceneNumber : ""}
                            </td>
                            <td className="p-4  border-2 border-gray-900 ">
                              {details.shot_number}
                            </td>
                            <td
                              className={`p-4  ${
                                index === 0 ? `border-none` : `border-t`
                              }  border-gray-900 flex flex-col`}
                            >
                              <p className=" p-px ">
                                {"shottype : " +
                                  (details.shot_type !== ""
                                    ? details.shot_type
                                    : " --- ")}
                              </p>
                              <p>
                                {"shotfocus : " +
                                  (details.shot_focus !== ""
                                    ? details.shot_focus
                                    : " --- ")}
                              </p>
                              <p>
                                {"shotangle : " +
                                  (details.shot_angle !== ""
                                    ? details.shot_angle
                                    : " --- ")}
                              </p>
                              <p>
                                {"shotframe :" +
                                  (details.shot_frame
                                    ? details.shot_frame
                                    : " --- ")}
                              </p>
                              <p>
                                {"shotmechanism :" +
                                  (details.shot_mechanism
                                    ? details.shot_mechanism
                                    : " --- ")}
                              </p>
                              <p>
                                {"shotmovements :" +
                                  (details.shot_movements
                                    ? details.shot_movements
                                    : " --- ")}
                              </p>
                            </td>
                            <td className="p-4 border-2 border-gray-900 ">
                              <p
                                style={{
                                  textAlign: "justify",
                                }}
                              >
                                {details.brief}
                              </p>
                            </td>
                            <td className="p-4 border-2 border-gray-900 ">
                              <p
                                style={{
                                  textAlign: "justify",
                                }}
                              >
                                {details.remark}
                              </p>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ShotDivisionUser;

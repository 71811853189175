import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import SecureLS from "secure-ls";
import ExpenseVoucher from "./ExpenseVoucher";
import { HiddenContext } from "../../Context/HiddenContent";
import usePagination from "../Pagination/usePagination";
import Pagination from "../Pagination/Pagination";

function ExpenseAccountTable({
  data,
  handleViewBill,
  handleApproveExpense,
  handleRejectionExpense,
}) {
  const [user, setUser] = useState("");
  const [selectedExpense, setSelectedExpense] = useState("");
  const [viewVoucher, setViewVoucher] = useState(false);
  const ls = new SecureLS();
  const { hidden } = useContext(HiddenContext);

  const user_id = ls.get("User id");
  const handleOpenVoucher = (expense) => {
    setViewVoucher(true);
    setSelectedExpense({ ...expense });
  };

  const { currentItems, currentPage, handlePageChange, totalPages } =
    usePagination(data, 10);

  const fetchUserDetails = () => {
    axios
      .get(`api/v1/users/find_user?user_id=${user_id}`)
      .then((response) => {
        setUser(response.data.user);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  useEffect(() => {
    fetchUserDetails();
  }, []);

  const stopClose = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      <div className="flex flex-col h-full">
        <div className="w-full mx-auto bg-white  rounded-sm border border-gray-200">
          <header className="flex sm:flex-row flex-col  justify-between px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold text-gray-800">Expense account</h2>
            <Pagination
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              totalPages={totalPages}
            />
          </header>
          <div className="p-3">
            <div className="overflow-x-auto">
              <table className="table-auto w-full">
                <thead className="text-xs font-semibold uppercase text-gray-600 bg-[#eceff1]">
                  <tr>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Department</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Expenser name
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Type</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Description</div>
                    </th>

                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-center">Duration</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-center">Voucher</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-center">Date</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-center">Amount</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-center">Bill</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-center">
                        No Bill Reason
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-center">Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody className="text-sm divide-y divide-gray-100">
                  {currentItems.map((data) => (
                    <tr>
                      <td className="p-2 whitespace-nowrap">
                        {data.department}
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        {data.expenser_name}
                      </td>
                      <td className="p-2">
                        <div className="text-left">{data.expense_type}</div>
                      </td>
                      <td className="p-2">
                        <div
                          className="text-left"
                          style={{
                            width: "300px",
                            textAlign: "justify",
                          }}
                        >
                          {data.description}
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-center">{data.duration}</div>
                      </td>
                      <td className="p-2">
                        <div className="text-left">
                          <button
                            onClick={() => handleOpenVoucher(data)}
                            disabled={
                              data.approval_status === "pending" ||
                              data.approval_status === "rejected"
                            }
                            className="font-semibold hover:underline"
                          >
                            View
                          </button>
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-center">{data.date}</div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-center">{data.amount}</div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-center">
                          <button
                            onClick={() =>
                              handleViewBill(data.document_expense)
                            }
                            disabled={data.no_bill_reason}
                            target="_blank"
                            className={`hover:text-bold hover:underline hover:text-black duration-200 ease-in ${
                              data.no_bill_reason
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                            }`}
                          >
                            View
                          </button>
                        </div>
                      </td>
                      <td className="p-2">
                        <div
                          className="text-center"
                          style={{
                            width: "300px",
                            textAlign: "justify",
                          }}
                        >
                          {data.no_bill_reason}
                        </div>
                      </td>
                      {data.approval_status === "pending" ? (
                        <React.Fragment>
                          {hidden ? (
                            <React.Fragment>
                              <td className="p-2 whitespace-nowrap text-orange-600">
                                Pending
                              </td>
                            </React.Fragment>
                          ) : (
                            <td className="p-2 whitespace-nowrap">
                              <button
                                onClick={() => handleApproveExpense(data.id)}
                                className="p-4  text-green-600 border-none hover:shadow-none rounded-full outline-none bg-green-50 shadow-lg duration-300 ease-in"
                              >
                                Approve
                              </button>
                              <button
                                onClick={() => handleRejectionExpense(data.id)}
                                className="ml-4 p-4  text-red-600 bg-red-50 hover:shadow-none rounded-full shadow-lg duration-300 ease-in outline-none"
                              >
                                Reject
                              </button>
                            </td>
                          )}
                        </React.Fragment>
                      ) : data.approval_status === "approved" ? (
                        <td className="p-2 whitespace-nowrap  text-green-800">
                          <div className="text-center">Approved</div>
                        </td>
                      ) : data.approval_status === "rejected" ? (
                        <td className="p-2 whitespace-nowrap  text-red-800">
                          <div className="text-center">Rejected</div>
                        </td>
                      ) : (
                        <td className="p-2 whitespace-nowrap flex"></td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {viewVoucher && (
        <ExpenseVoucher
          onClose={() => setViewVoucher(false)}
          user={user}
          expense={selectedExpense}
        />
      )}
    </>
  );
}

export default ExpenseAccountTable;

import { FieldArray, Form, Formik } from "formik";
import React from "react";
import { RxCross1 } from "react-icons/rx";
import { FaPlus } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import FormikInput from "../../../../components/Input/FormikInput";
import Button from "../../../../components/Button/Button";
import FormikRadio from "../../../../components/Input/FormikRadio";

function PackageModal({ openModal, validationSchema, addPackageHandler }) {
  return (
    <div>
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-black overflow-auto bg-opacity-50">
        <div className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto ">
          <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-lg w-full mx-auto my-auto">
            <div className="flex items-end justify-end">
              <button
                className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full "
                onClick={openModal}
              >
                <RxCross1 />
              </button>
            </div>
            <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
              Packages
            </h2>
            <div>
              <Formik
                validationSchema={validationSchema}
                initialValues={{
                  name: "",
                  price: "",
                  number_of_projects: "",
                  number_of_locations: "",
                  number_of_crews: "",
                  number_of_artists: "",
                  number_of_scenes: "",
                  number_of_callsheet: "",
                  expense_allowed: false,
                  shot_division: false,
                  continuity: false,
                  accounts: false,
                  assign_role: false,
                  package_details: {
                    details: [],
                  },
                }}
                onSubmit={addPackageHandler}
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                      <div>
                        <FormikInput
                          label={"Package name"}
                          name={"name"}
                          type={"text"}
                          placeholder={"Premium"}
                        />
                      </div>
                      <div>
                        <FormikInput
                          label={"Package price"}
                          name={"price"}
                          type={"number"}
                          placeholder={"15***"}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 sm:gap-4 mt-2 sm:mt-4">
                      <div>
                        <FormikInput
                          label={"Number of projects"}
                          name={"number_of_projects"}
                          type={"number"}
                          placeholder={"2"}
                        />
                      </div>
                      <div>
                        <FormikInput
                          label={"Number of locations"}
                          name={"number_of_locations"}
                          type={"number"}
                          placeholder={"2"}
                        />
                      </div>
                      <div>
                        <FormikInput
                          label={"Number of crews"}
                          name={"number_of_crews"}
                          type={"number"}
                          placeholder={"2"}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 sm:gap-4 mt-2 sm:mt-4">
                      <div>
                        <FormikInput
                          label={"Number of artists"}
                          name={"number_of_artists"}
                          type={"number"}
                          placeholder={"2"}
                        />
                      </div>
                      <div>
                        <FormikInput
                          label={"Number of Scenes"}
                          name={"number_of_scenes"}
                          type={"number"}
                          placeholder={"2"}
                        />
                      </div>
                      <div>
                        <FormikInput
                          label={"Number of Schedules"}
                          name={"number_of_callsheet"}
                          type={"number"}
                          placeholder={"2"}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                      <div>
                        <label className="block mb-2 text-sm text-gray-600 ">
                          Add Expense allowed ?
                        </label>
                        <div className="flex flex-row">
                          <div>
                            <FormikRadio
                              id={"yes"}
                              label={"Yes"}
                              value={true}
                              name={"shot_division"}
                              onChange={() =>
                                setFieldValue(
                                  "shot_division",
                                  !values.shot_division
                                )
                              }
                            />
                          </div>
                          <div className="ml-2">
                            <FormikRadio
                              id={"no"}
                              value={false}
                              label={"No"}
                              name={"shot_division"}
                              onChange={() =>
                                setFieldValue(
                                  "shot_division",
                                  !values.shot_division
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <label className="block mb-2 text-sm text-gray-600 ">
                          Shot division allowed ?
                        </label>
                        <div className="flex flex-row">
                          <div>
                            <FormikRadio
                              id={"yes"}
                              label={"Yes"}
                              value={true}
                              name={"expense_allowed"}
                              onChange={() =>
                                setFieldValue(
                                  "expense_allowed",
                                  !values.expense_allowed
                                )
                              }
                            />
                          </div>
                          <div className="ml-2">
                            <FormikRadio
                              id={"no"}
                              value={false}
                              label={"No"}
                              name={"expense_allowed"}
                              onChange={() =>
                                setFieldValue(
                                  "expense_allowed",
                                  !values.expense_allowed
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                      <div>
                        <label className="block mb-2 text-sm text-gray-600 ">
                          Scene continuity allowed ?
                        </label>
                        <div className="flex flex-row">
                          <div>
                            <FormikRadio
                              id={"yes"}
                              label={"Yes"}
                              value={true}
                              name={"continuity"}
                              onChange={() =>
                                setFieldValue("continuity", !values.continuity)
                              }
                            />
                          </div>
                          <div className="ml-2">
                            <FormikRadio
                              id={"no"}
                              value={false}
                              label={"No"}
                              name={"continuity"}
                              onChange={() =>
                                setFieldValue("continuity", !values.continuity)
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <label className="block mb-2 text-sm text-gray-600 ">
                          Access to accounts page ?
                        </label>
                        <div className="flex flex-row">
                          <div>
                            <FormikRadio
                              id={"yes"}
                              label={"Yes"}
                              value={true}
                              name={"accounts"}
                              onChange={() =>
                                setFieldValue("accounts", !values.accounts)
                              }
                            />
                          </div>
                          <div className="ml-2">
                            <FormikRadio
                              id={"no"}
                              value={false}
                              label={"No"}
                              name={"accounts"}
                              onChange={() =>
                                setFieldValue("accounts", !values.accounts)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                      <div>
                        <label className="block mb-2 text-sm text-gray-600 ">
                          Assign role access?
                        </label>
                        <div className="flex flex-row">
                          <div>
                            <FormikRadio
                              id={"yes"}
                              label={"Yes"}
                              value={true}
                              name={"assign_role"}
                              onChange={() =>
                                setFieldValue(
                                  "assign_role",
                                  !values.assign_role
                                )
                              }
                            />
                          </div>
                          <div className="ml-2">
                            <FormikRadio
                              id={"no"}
                              value={false}
                              label={"No"}
                              name={"assign_role"}
                              onChange={() =>
                                setFieldValue(
                                  "assign_role",
                                  !values.assign_role
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <FieldArray name="package_details.details">
                      {({ push, remove }) => (
                        <div>
                          <label className="block mb-2 text-sm text-gray-600 mt-2">
                            Features
                          </label>
                          <div>
                            {values.package_details.details.map(
                              (detail, index) => (
                                <div
                                  key={index}
                                  className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4"
                                >
                                  <div>
                                    <FormikInput
                                      type={"text"}
                                      name={`package_details.details.${index}`}
                                      placeholder={"Enter Feature"}
                                    />
                                  </div>
                                  <div>
                                    <button
                                      type="button"
                                      onClick={() => remove(index)}
                                      className="bg-[#e70a18] p-2 text-lg text-[#fbffff] rounded-full mt-2"
                                    >
                                      <MdDelete />
                                    </button>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                          <button
                            type="button"
                            className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full mt-5"
                            onClick={() => push("")}
                          >
                            <FaPlus />
                          </button>
                        </div>
                      )}
                    </FieldArray>
                    <div className="mt-2 sm:mt-4">
                      <Button name={"Add package"} type={"submit"} />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PackageModal;

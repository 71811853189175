import React, { useState } from "react";
import { BiSolidTrashAlt } from "react-icons/bi";
import { MdModeEdit } from "react-icons/md";
import usePagination from "../Pagination/usePagination";
import Pagination from "../Pagination/Pagination";

function PartnerTable({ partners, updateView, deleteView }) {
  const { currentItems, currentPage, handlePageChange, totalPages } =
    usePagination(partners, 10);
  return (
    <div>
      <div className="flex flex-col h-full">
        <div className="w-full mx-auto bg-white  rounded-sm border border-gray-200">
          <header className="flex sm:flex-row flex-col  justify-between px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold text-gray-800">Partners</h2>
            <div className="flex items-start justify-start sm:justify-end sm:items-center sm:mt-0 mt-2 relative">
              <Pagination
                currentPage={currentPage}
                handlePageChange={handlePageChange}
                totalPages={totalPages}
              />
            </div>
          </header>
          <div className="p-3">
            <div className="overflow-x-auto">
              <table className="table-auto w-full">
                <thead className="text-xs font-semibold uppercase text-gray-600 bg-[#eceff1]">
                  <tr>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Name</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Type</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Contact number
                      </div>
                    </th>
                    <th className="px-4 py-2">
                      <div className="font-semibold text-left">Email</div>
                    </th>
                    <th className="px-4 py-2">
                      <div className="font-semibold text-left">GST number</div>
                    </th>

                    <th className="px-4 py-2">
                      <div className="font-semibold text-left">PAN number</div>
                    </th>

                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody className="text-sm divide-y divide-gray-100">
                  {currentItems.map((partner, index) => (
                    <tr key={index}>
                      <td className="p-2 whitespace-nowrap">{partner.name}</td>
                      <td className="p-2">
                        <div className="text-left">{partner.partner_type}</div>
                      </td>
                      <td className="p-2">
                        <div className="text-left">
                          {partner.contact_number}
                        </div>
                      </td>

                      <td className="p-2">
                        <div className="text-left">{partner.email}</div>
                      </td>
                      <td className="p-2">
                        <div className="text-left">{partner.gst_number}</div>
                      </td>
                      <td className="p-2">
                        <div className="text-left">{partner.pan_number}</div>
                      </td>

                      <td className="p-2 whitespace-nowrap flex">
                        <div className="text-center">
                          <button
                            onClick={() => updateView(partner)}
                            className="ml-4 p-4 text-xl text-green-600 border-none hover:shadow-none rounded-full outline-none bg-green-50  duration-300 ease-in"
                          >
                            <MdModeEdit />
                          </button>
                          <button
                            onClick={() => deleteView(partner)}
                            className="ml-4 p-4 text-xl text-red-600 bg-red-50 hover:shadow-none rounded-full  duration-300 ease-in outline-none"
                          >
                            <BiSolidTrashAlt />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartnerTable;

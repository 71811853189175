import React from "react";
import Button from "../Button/Button";
import { RxCross1 } from "react-icons/rx";
import { Form, Formik } from "formik";
import FormikInput from "../Input/FormikInput";

function TimelineForm({
  validationSchema,
  handleBackdropClick,
  stopClose,
  addEvent,
}) {
  return (
    <div>
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 no-scrollbar overflow-scroll z-50">
        <div
          className="flex items-center justify-center z-50"
          onClick={stopClose}
        >
          <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-md w-full mx-auto">
            <div className="flex items-end justify-end">
              <button
                className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full "
                onClick={handleBackdropClick}
              >
                <RxCross1 />
              </button>
            </div>
            <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
              Add Event
            </h2>
            <div>
              <Formik
                initialValues={{
                  start: "",
                  end: "",
                  title: "",
                }}
                validationSchema={validationSchema}
                onSubmit={addEvent}
              >
                <Form>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                    <div>
                      <FormikInput
                        name={"start"}
                        label={"Start date"}
                        type={"datetime-local"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"end"}
                        label={"End date"}
                        type={"datetime-local"}
                      />
                    </div>
                  </div>
                  <div className="mt-2 sm:mt-4">
                    <FormikInput
                      name={"title"}
                      label={"Event"}
                      placeholder={"Event"}
                      type={"text"}
                    />
                  </div>
                  <div className="mt-2 sm:mt-4">
                    <Button name={"Add Event"} type={"submit"}/>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TimelineForm;

import React, { useState } from "react";
import Input from "../components/Input/Input";
import { Link, useNavigate } from "react-router-dom";
import Button from "../components/Button/Button";
import toast from "react-hot-toast";
import OTPform from "../components/ResetPasswordComponents/OTPform";
import ChangePassword from "../components/ResetPasswordComponents/ChangePassword";
import axios from "axios";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormikInput from "../components/Input/FormikInput";

function ResetPassword() {
  const emailValidation = Yup.object().shape({
    email: Yup.string().required("Please enter a valid email or username"),
  });
  const [active, setActive] = useState(0);
  const navigate = useNavigate();

  const sendOtp = (values, { resetForm }) => {
    const loader = toast.loading("Sending OTP...", { autoClose: false });
    localStorage.setItem("email", values.email);
    axios
      .post(`api/v1/users/reset_password_code`, {
        email: values.email,
      })
      .then((response) => {
        if (response.data) {
          setActive(active + 1);
          toast.dismiss(loader);
          resetForm();
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        if (error) {
          toast.dismiss(loader);
          toast.error("Something went wrong! Please try again later.");
        }
        console.log(error.message);
      });
  };
  const verifyOTP = (values, { resetForm }) => {
    const loader = toast.loading("Verifying...", {
      autoClose: false,
    });

    const email = localStorage.getItem("email");
    axios
      .post(`api/v1/users/verify_reset_password_otp`, {
        otp: values.otp,
        email: email,
      })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          toast.dismiss(loader);
          resetForm();
          toast.success(response.data.message);
          setActive(active + 1);
        }
      })
      .catch((error) => {
        if (error) {
          toast.dismiss(loader);
          toast.error(error.message);
        }
        console.log(error.message);
      });
  };

  const changePasswordHandler = (values, { resetForm }) => {
    const loading = toast.loading("Changing password...", {
      autoClose: true,
    });
    const email = localStorage.getItem("email");
    axios
      .post(`api/v1/users/reset_password`, {
        new_password: values.password,
        confirm_password: values.confirm_password,
        email: email,
      })
      .then((response) => {
        if (response.status === 200) {
          localStorage.removeItem("email");
          navigate("/Login");
          toast.dismiss(loading);
          toast.success(response.data.message);
          resetForm();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        if (error) {
          toast.dismiss(loading);
          toast.error(error.message);
        }
        console.log(error.message);
      });
  };

  return (
    <div>
      <body className=" ">
        <div className="flex justify-center h-screen">
          <div
            className="hidden bg-cover lg:block lg:w-2/5"
            style={{
              backgroundImage: `url('https://images.unsplash.com/photo-1509281373149-e957c6296406?q=80&w=1928&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')`,
            }}
          ></div>

          <div className="flex items-center w-full max-w-md px-5 mx-auto lg:w-2/5">
            <div className="flex-1">
              <div className="text-center">
                <h3 className="pt-4 mb-2 text-5xl font-semibold">
                  Reset Your Password?
                </h3>
                {active === 0 && (
                  <p className="mb-4 text-lg text-gray-700">
                    Just enter your email address below and we'll send you a OTP
                    to reset your password!
                  </p>
                )}
                {active === 1 && (
                  <p className="mb-4 text-lg text-gray-700">Enter OTP</p>
                )}
                {active === 2 && (
                  <p className="mb-4 text-lg text-gray-700">
                    Enter New Password
                  </p>
                )}
              </div>
              <div className="mt-8">
                {active === 0 && (
                  <div>
                    <Formik
                      initialValues={{ email: "" }}
                      validationSchema={emailValidation}
                      onSubmit={sendOtp}
                    >
                      <Form>
                        <FormikInput
                          name={"email"}
                          type={"text"}
                          placeholder={"example@example.com"}
                          label={"Email / Username"}
                        />
                        <div className="mt-6">
                          <Button name={"Send OTP"} type={"submit"} />
                        </div>
                      </Form>
                    </Formik>
                    <div className="text-center mt-10">
                      <h1>
                        Don't have an account ?
                        <Link
                          className="inline-block text-sm text-[#1d5b79] align-baseline hover:underline"
                          to="/signup"
                        >
                          Create an Account!
                        </Link>
                      </h1>
                    </div>
                    <div className="text-center">
                      <h1>
                        Already have an account?
                        <Link
                          className="inline-block text-sm text-[#1d5b79] align-baseline hover:underline"
                          to="/Login"
                        >
                          Login!
                        </Link>
                      </h1>
                    </div>
                  </div>
                )}
                {active === 1 && (
                  <div>
                    <OTPform verifyOTP={verifyOTP} />
                  </div>
                )}
                {active === 2 && (
                  <ChangePassword
                    changePasswordHandler={changePasswordHandler}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </body>
    </div>
  );
}

export default ResetPassword;

import React, { useContext, useEffect, useRef, useState } from "react";
import Button from "../../components/Button/Button";
import SceneContinuityForm from "../../components/Forms/SceneContinuityForm";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import SceneContinuityTable from "../../components/Table/SceneContinuityTable";
import SceneContinuityUpdateForm from "../../components/Forms/SceneContinuityUpdateForm";
import DeleteModal from "../../Modal/DeleteModal";
import { BsDownload } from "react-icons/bs";
import { useReactToPrint } from "react-to-print";
import SubRoleContext from "../../SubRoleContext";
import Loader from "../../Loader/loader";
import ErrorPage from "../ErrorPage";
import DownloadButton from "../../components/Button/DownloadButton";

function SceneContinuity() {
  const subRole = useContext(SubRoleContext);
  const [view, setView] = useState(true);
  const [OpenDeleteModel, setOpenDeleteModel] = useState(true);
  const [updateview, setupdateView] = useState(true);
  const [scene_numbers, setScene_numbers] = useState([]);
  const [scene_continuities, setScene_continuities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedSceneContinuity, setSelectedSceneContinuity] = useState("");
  const [images, setImages] = useState([]);
  const [error, setError] = useState(false);
  const openShotmodel = () => {
    setView(!view);
  };
  const { projectId } = useParams();

  useEffect(() => {
    fetchSceneDetails();
    fetchSceneContinuityDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchSceneDetails = () => {
    axios
      .get(`api/v1/projects/${projectId}/scenes`)
      .then((response) => {
        setScene_numbers(response?.data?.scenes);
      })
      .catch((err) => console.log(err));
  };
  const fetchSceneContinuityDetails = () => {
    axios
      .get(`api/v1/projects/${projectId}/scene_continuities`)
      .then((response) => {
        setLoading(true);
        setScene_continuities(
          response?.data?.scene_cs.sort(
            (a, b) => a.scene_number - b.scene_number
          )
        );
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 422) {
            setLoading(true);
          } else {
            setError(true);
          }
        } else {
          console.log("err", err);
          setError(true);
        }
      });
  };
  const addSceneContinuity = (values) => {
    const loaderToast = toast.loading("Adding...", {
      autoClose: false,
    });

    const data = new FormData();
    data.append("scene_c[scene_number]", values.scene_number);
    values.continuity_types.forEach((type) => {
      data.append(`scene_c[continuity_types][]`, type);
    });
    values.scene_continuities.forEach((scene, index) => {
      data.append(
        `scene_c[c_scenes][${index}][scene_number]`,
        scene.scene_number
      );
    });
    data.append("scene_c[remarks]", values.remark);
    if (images) {
      [...images].forEach((file, i) => {
        data.append(`scene_c[images][]`, file);
      });
    }
    const headers = {
      "Content-Type": "multipart/form-data",
    };

    axios
      .post(`api/v1/projects/${projectId}/scene_continuities`, data, {
        headers,
      })
      .then((res) => {
        if (res.status === 200) {
          toast.dismiss(loaderToast);
          toast.success("Scene Continuity added..");
          fetchSceneContinuityDetails();
          setView(!view);
          setImages([]);
        }
      })
      .catch((err) => {
        toast.dismiss(loaderToast);
        setView(!view);
        toast.error(
          err.response.data.message || err.message || err.response.data.error
        );

        console.log(err);
      });
  };

  const updateSceneContinuity = (values) => {
    const loaderToast = toast.loading("Updating...", {
      autoClose: false,
    });

    const data = new FormData();
    data.append("scene_c[scene_number]", values.scene_number);
    values.continuity_types.forEach((type) => {
      data.append(`scene_c[continuity_types][]`, type);
    });
    values.scene_continuities.forEach((scene, index) => {
      data.append(`scene_c[c_scenes][][scene_number]`, scene.scene_number);
    });
    data.append("scene_c[remarks]", values.remark);
    if (images) {
      [...images].forEach((file, i) => {
        data.append(`scene_c[images][]`, file);
      });
    }

    const headers = {
      "Content-Type": "multipart/form-data",
    };

    axios
      .put(
        `api/v1/projects/${projectId}/scene_continuities/${selectedSceneContinuity.id}`,
        data,
        { headers }
      )
      .then((res) => {
        toast.success("Scene Continuity Updated..");
        toast.dismiss(loaderToast);
        fetchSceneContinuityDetails();
        setupdateView(!updateview);
        setImages([]);
      })
      .catch((err) => {
        console.log(err);
        toast.dismiss(loaderToast)
        toast.error("opps ! error occurs...");
      });
  };
  const openModal = (c_scene) => {
    setSelectedSceneContinuity({ ...c_scene });
    setOpenDeleteModel(!OpenDeleteModel);
  };

  const handleDelete = () => {
    const loaderToast = toast.loading("Deleting...", {
      autoClose: false,
    });
    axios
      .delete(
        `api/v1/projects/${projectId}/scene_continuities/${selectedSceneContinuity.id}`
      )
      .then((res) => {
        toast.dismiss(loaderToast);
        toast.success("scene Continuity deleted successfully.");
        fetchSceneContinuityDetails();
        setOpenDeleteModel(!OpenDeleteModel);
      })
      .catch((err) => {
        console.log(err);
        toast.dismiss(loaderToast);
        toast.error(
          err.response.data.message || err.message || err.response.data.error
        );
      });
  };
  const contentToPrint = useRef(null);
  const downloadPdf = () => {
    handlePrint();
  };
  const handlePrint = useReactToPrint({
    documentTitle: "Print This Document",
    content: () => contentToPrint.current,
  });
  return (
    <div>
      {error ? (
        <ErrorPage />
      ) : (
        <div>
          {!loading ? (
            <Loader />
          ) : (
            <>
              <div className="flex flex-row p-5">
                <div className="flex sm:flex-row flex-col items-center justify-between w-full xl:ml-20 2xl:ml-20 md:ml-20 sm:ml-20 ml-0 sm:mt-2 mt-8">
                  <div className="mt-2">
                    <Button
                      clickFunction={openShotmodel}
                      name={"Add Scene Continuity"}
                    />
                  </div>

                  <div className="flex items-end justify-end mt-2">
                    <DownloadButton
                      onClick={downloadPdf}
                      name={"Print Sheet"}
                    />
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-center xl:ml-24 2xl:ml-24 lg:ml-24 md:ml-24 sm:ml-24 ml-0">
                <div className="w-full mt-5">
                  <SceneContinuityTable
                    scene_continuities={scene_continuities}
                    fetchData={fetchSceneContinuityDetails}
                    setupdateView={setupdateView}
                    updateview={updateview}
                    handleDelete={handleDelete}
                    setSelectedSceneContinuity={setSelectedSceneContinuity}
                    setOpenDeleteModel={setOpenDeleteModel}
                    OpenDeleteModel={OpenDeleteModel}
                    openModal={openModal}
                  />
                </div>
              </div>
            </>
          )}
          {!view && (
            <SceneContinuityForm
              setView={setView}
              view={view}
              scene_numbers={scene_numbers}
              scene_continuities={scene_continuities}
              addSceneContinuity={addSceneContinuity}
              setImages={setImages}
            />
          )}
          {!updateview && (
            <SceneContinuityUpdateForm
              setupdateView={setupdateView}
              updateview={updateview}
              scene_numbers={scene_numbers}
              scene_continuities={scene_continuities}
              updateSceneContinuity={updateSceneContinuity}
              selectedSceneContinuity={selectedSceneContinuity}
              setSelectedSceneContinuity={setSelectedSceneContinuity}
              setImages={setImages}
            />
          )}
          {!OpenDeleteModel ? (
            <DeleteModal
              message={`Are you sure want you to delete scene continuity of scene ${selectedSceneContinuity.scene_number}?`}
              title={"Delete Scene Continuity"}
              onClose={() => setOpenDeleteModel(!OpenDeleteModel)}
              onDelete={() => handleDelete()}
              icon={true}
            />
          ) : null}
          <div className="hidden absolute top-0 left-8 xl:absolute xl:top-0 xl:left-20 2xl:relative sm:relative  sm:ml-0  flex-col items-center justify-center my-auto z-50 ">
            <div className=" m-10">
              <div ref={contentToPrint} style={{ margin: 20 }} className="">
                <h1>{"Scene Continuity Sheet"}</h1>
                <table className="min-w-full text-center text-sm  font-light ">
                  <thead className="border-b font-medium rounded-s-lg border-2 border-gray-900  bg-[#edf8f8]  ">
                    <tr className="border-2 border-gray-900">
                      <th className="p-4 whitespace-nowrap text-[#020002] border-2 border-gray-900">
                        Scene no.
                      </th>
                      <th className=" p-4 whitespace-nowrap text-[#020002] border-2 border-gray-900 ">
                        Continuity scene
                      </th>
                      <th className="p-4 whitespace-nowrap text-[#020002] border-2 border-gray-900 ">
                        Continuity Types
                      </th>
                      <th className="p-4 whitespace-nowrap text-[#020002] border-2 border-gray-900 ">
                        Remark
                      </th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {scene_continuities.map((details) => {
                      const c_scenesArray = Object.values(details.c_scenes);
                      const scenes = c_scenesArray
                        .map((scene) => scene.scene_number)
                        .join(",");

                      return (
                        <tr
                          className="text-sm mt-4 border border-gray-900 "
                          key={details.id}
                        >
                          <td className="p-4 mt-4 whitespace-nowrap text-xl font-extrabold border-2 border-gray-900 ">
                            {details?.scene_number}
                          </td>
                          <td className="p-2 ">
                            <div
                              style={{ width: "200px", textAlign: "justify" }}
                            >
                              {scenes}
                            </div>
                          </td>
                          <td className="p-2">
                            <div
                              className="text-center"
                              style={{ width: "200px", textAlign: "justify" }}
                            >
                              {details.continuity_types.join(", ")}
                            </div>
                          </td>
                          <td className="p-4 border-2 border-gray-900 border-collapse ">
                            <p
                              style={{
                                textAlign: "justify",
                              }}
                            >
                              {details?.remarks}
                            </p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default SceneContinuity;

import React, { useRef, useState } from "react";
import { MdModeEdit } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import RequestModal from "../Modal/RequestModal";
import axios from "axios";
import toast from "react-hot-toast";
import DeleteModal from "../../../../Modal/DeleteModal";
import usePagination from "../../../../components/Pagination/usePagination";
import Pagination from "../../../../components/Pagination/Pagination";

function RequestTable({ userData, getUserData, ref }) {
  const [open, setOpen] = useState(false);
  const [view, setView] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const { currentItems, currentPage, handlePageChange, totalPages } =
    usePagination(userData, 10);

  const openModal = (id) => {
    const getSelectedUser = userData.find((user) => user.id === id);
    setSelectedUser(getSelectedUser);
    setOpen(!open);
  };

  const openMailModal = (id) => {
    const getSelectedUser = userData.find((user) => user.id === id);
    setSelectedUser(getSelectedUser);
    setView(!view);
  };

  const packageUpgradeHandler = (id, values, { setSubmitting, resetForm }) => {
    const loadingToastId = toast.loading("Upgrading ...", {
      autoClose: false,
    });
    axios
      .post(`api/v1/packages/upgrade_package_request/${id}`, {
        package_name: values.package,
      })
      .then((response) => {
        setOpen(false);
        if (response.status === 200) {
          toast.dismiss(loadingToastId);
          getUserData();
          toast.success(response.data.message);
          resetForm();
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    setSubmitting(false);
  };

  const sendMailHandler = (id) => {
    const loadingToastId = toast.loading("Sending Mail ...", {
      autoClose: false,
    });
    axios
      .post(`api/v1/packages/package_user_email/${id}`)
      .then((response) => {
        if (response.status === 200) {
          toast.dismiss(loadingToastId);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (
    <>
      <div className="flex flex-col h-full">
        <div className="w-full mx-auto bg-white  rounded-sm border border-gray-200">
          <header className="flex sm:flex-row flex-col  justify-between px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold text-gray-800">Production Houses</h2>
            <Pagination
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              totalPages={totalPages}
            />
          </header>
          <div className="p-3">
            <div className="overflow-x-auto">
              <table className="table-auto w-full" ref={ref}>
                <thead className="text-xs font-semibold uppercase text-gray-600 bg-[#eceff1]">
                  <tr>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Production house name
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Owner name</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Owner contact number
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Owner email</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Package</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Domain</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Database url
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Slug</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody className="text-sm divide-y divide-gray-100">
                  {currentItems.map((user) => (
                    <tr>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">{user.company_name}</div>
                      </td>
                      <td className="p-2">
                        <div className="text-left">
                          {user.first_name} {user.last_name}
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">{user.phone_number}</div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">{user.email}</div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        {user.package && (
                          <div className="text-left">{user.package.name}</div>
                        )}
                      </td>
                      <td className="p-2">
                        {user.domain_name && (
                          <div
                            className="text-left"
                            style={{ width: "300px", textAlign: "justify" }}
                          >
                            {user.domain_name}
                          </div>
                        )}
                      </td>
                      <td className="p-2">
                        {user.database_url && (
                          <div
                            className="text-left"
                            style={{ width: "300px", textAlign: "justify" }}
                          >
                            {user.database_url}
                          </div>
                        )}
                      </td>
                      <td className="p-2">
                        {user.slug && (
                          <div
                            className="text-left"
                            style={{ width: "300px", textAlign: "justify" }}
                          >
                            {user.slug}
                          </div>
                        )}
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">
                          <button
                            onClick={() => openModal(user.id)}
                            className="p-4 text-xl text-green-600 border-none hover:shadow-none rounded-full outline-none bg-green-50 duration-300 ease-in"
                          >
                            <MdModeEdit />
                          </button>

                          <button
                            onClick={() => openMailModal(user.id)}
                            className="ml-5 p-4 text-xl text-sky-600 border-none hover:shadow-none rounded-full outline-none bg-sky-50 duration-300 ease-in"
                          >
                            <MdEmail />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {open && (
        <RequestModal
          openModal={openModal}
          selectedUser={selectedUser}
          packageUpgradeHandler={packageUpgradeHandler}
        />
      )}
      {view && (
        <DeleteModal
          onClose={openMailModal}
          title={"Send Mail"}
          message={`Do you want to send mail to ${selectedUser.company_name} ?`}
          onDelete={() => {
            sendMailHandler(selectedUser.id);
            openMailModal();
          }}
          icon={false}
        />
      )}
    </>
  );
}

export default RequestTable;

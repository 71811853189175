import React, { useEffect, useState } from "react";
import { IoMenuOutline } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import ActorSchedule from "./ActorSchedule";
import ActorScript from "./ActorScript";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { FaRegCalendar } from "react-icons/fa";
import { PiFilmScriptFill } from "react-icons/pi";
import { IoIosLogOut } from "react-icons/io";
import toast from "react-hot-toast";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";

function ActorDashboard() {
  const [view, setView] = useState(false);
  const [actor, setActor] = useState("");
  const [activeTab, setActiveTab] = useState(1);
  const navigate = useNavigate();
  const handleViewChange = () => {
    setView(!view);
  };

  const fetchUser = () => {
    const userDetails = localStorage.getItem("User_Details");
    const actorDetail = JSON.parse(userDetails);
    setActor(actorDetail);
  };

  const handleLogOut = () => {
    toast.success("Logged out! Come again soon.");
    localStorage.clear();
    navigate("/Login");
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const tabs = [
    { id: 1, label: "Your Schedules", icon: <FaRegCalendar /> },
    { id: 2, label: "Script", icon: <PiFilmScriptFill /> },
  ];

  const renderContent = () => {
    switch (activeTab) {
      case 1:
        return <ActorSchedule />;
      case 2:
        return <ActorScript />;
      default:
        return <ActorSchedule />;
    }
  };

  useEffect(() => {
    const storedActiveTab = localStorage.getItem("activeTab");

    if (storedActiveTab) {
      setActiveTab(parseInt(storedActiveTab));
    } else {
      setActiveTab(1);
    }
  }, []);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);

    localStorage.setItem("activeTab", tabId);
  };

  return (
    <div className=" ">
      <button
        onClick={handleViewChange}
        className="fixed flex sm:hidden top-5 left-5 text-[#fbffff] bg-[#1d5b79] text-xl outline-none shadow-lg font-bold p-3 rounded-full hover:shadow-none border-2 border-[#fbffff] duration-200 ease-out sm:text-3xl sm:p-4 z-50"
      >
        {view ? <IoMenuOutline /> : <RxCross1 />}
      </button>
      <nav
        className={`${
          view
            ? "sm:w-20 scale-0 sm:scale-100 origin-left"
            : "sm:w-60 w-11/12 z-40"
        } sm:h-screen h-full bg-[#1d5b79] p-5 duration-100 fixed rounded-br-2xl rounded-tr-2xl overflow-x-hidden overflow-y-auto no-scrollbar`}
      >
       <button
            onClick={handleViewChange}
            className="bg-white text-[#1d5b79] w-8 h-8 items-center justify-center p-2 top-9 -right-4 border-[#1d5b79]  rounded-full  border-2 hidden sm:flex "
          >
            <span className="text-xl font-bold">
              {view ? <AiOutlineMenuUnfold /> : <AiOutlineMenuFold />}
            </span>
          </button>
        {actor && (
          <div
            className={`${
              view && "scale-0 hidden"
            } m-5  flex-col sm:items-center sm:justify-center items-center justify-center mt-20 lg:mt-10`}
          >
            <h1 className="text-[#fbffff] uppercase text-3xl sm:text-4xl sm:mt-0 mt-4 font-bold">
              {actor.name}
            </h1>
          </div>
        )}
        <ul className="flex flex-col mt-5">
          {tabs.map((tab) => (
            <li key={tab.id}>
              <p
                onClick={() => handleTabClick(tab.id)}
                className={`relative text-base flex mt-1 items-center gap-x-2 cursor-pointer p-2 w-full sm:w-56 group ${
                  activeTab === tab.id
                    ? `${
                        view && "sm:activeTab-2"
                      } bg-white text-[#1d5b79] rounded-s-3xl rounded-e-3xl sm:activeTab`
                    : "text-[#fbffff]"
                }`}
              >
                <span>{tab.icon}</span>
                <span
                  className={`${view && "hidden"} origin-left duration-200`}
                >
                  {tab.label}
                </span>
              </p>
            </li>
          ))}
        </ul>
        <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2">
          <button
            onClick={handleLogOut}
            className="flex items-center justify-center px-4 py-2 bg-[#fbffff] outline-none border-none rounded-3xl  text-[#1d5b79]  shadow-white"
          >
            <span className="mt-1">
              <IoIosLogOut />
            </span>
            <span className={`${view && "hidden"} origin-left duration-200`}>
              Log out
            </span>
          </button>
        </div>
      </nav>

      <div
        className={`p-7 flex-1 h-screen ${
          !view ? "ml-40 duration-100 ease-in" : "ml-0 duration-100 ease-in"
        }`}
      >
        {renderContent()}
      </div>
    </div>
  );
}

export default ActorDashboard;

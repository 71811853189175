import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import * as Yup from "yup";
import FormikInput from "../../../../components/Input/FormikInput";
import axios from "axios";
import FormikSelect from "../../../../components/Input/FormikSelect";
import Button from "../../../../components/Button/Button";

function PremiumUserForm({ setOpen, onSubmit }) {
  const [packageData, setPackageData] = useState([]);
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Please enter a valid email")
      .email("Invalid email format"),
    first_name: Yup.string()
      .min(2, "First name must have at least 2 characters")
      .required("Please enter a first name"),
    last_name: Yup.string()
      .min(2, "Last name must have at least 2 characters")
      .required("Please enter a last name"),
    company_name: Yup.string()
      .min(3, "Company name must have at least 3 characters")
      .required("Please enter a company name"),
    phone_number: Yup.string()
      .matches(/^[0-9]{10}$/, "Contact number must have exactly 10 digits")
      .required("Please enter Contact Number"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Enter password"),
    pan_number: Yup.string()
      .required("PAN number is required")
      .matches(
        /^[A-Z]{5}[0-9]{4}[A-Z]$/,
        "Invalid PAN number. Format should be ABCDE1234F"
      ),
    domain: Yup.string().required("Domain name is required."),
    database_url: Yup.string().required("Database URL is required."),
    tan_number: Yup.string()
      .required("TAN number is required")
      .matches(
        /^[A-Z]{4}[0-9]{5}[A-Z]$/,
        "Invalid TAN number. Format should be ABCD12345E"
      ),
    gst_number: Yup.string().required("GST number is required"),
  });
  const getPackage = () => {
    axios
      .get(`api/v1/packages`)
      .then((response) => {
        setPackageData(response.data.packages);
      })
      .catch((err) => console.log(err.message));
  };

  useEffect(() => {
    getPackage();
  }, []);

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black overflow-auto bg-opacity-50">
      <div className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto ">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-lg w-full mx-auto my-auto">
          <div className="flex items-end justify-end">
            <button
              className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full "
              onClick={() => setOpen(false)}
            >
              <RxCross1 />
            </button>
          </div>
          <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
            Premium User
          </h2>
          <div>
            <Formik
              initialValues={{
                email: "",
                first_name: "",
                last_name: "",
                company_name: "",
                phone_number: "",
                password: "",
                domain: "",
                database_url: "",
                pan_number: "",
                tan_number: "",
                gst_number: "",
                is_premium_user: true,
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <div className="grid grid-cols-1 gap-6 mt-8 md:grid-cols-2">
                    <div>
                      <FormikInput
                        label={"First name"}
                        type={"text"}
                        name={"first_name"}
                        placeholder={"John"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        label={"Last name"}
                        type={"text"}
                        name={"last_name"}
                        placeholder={"Smith"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        label={"Production house name"}
                        type={"text"}
                        name={"company_name"}
                        placeholder={"T-series"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        label={"Contact number"}
                        type={"tel"}
                        name={"phone_number"}
                        placeholder={"XXX-XX-XXXX-XXX"}
                      />
                    </div>

                    <div>
                      <FormikInput
                        label={"Email"}
                        type={"email"}
                        name={"email"}
                        placeholder={"johnsmith@example.com"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        label={"Password"}
                        type={"text"}
                        name={"password"}
                        placeholder={"******"}
                      />
                    </div>

                    <div>
                      <FormikInput
                        name={"domain"}
                        label={"Domain name"}
                        placeholder={"www.Dharma.cinemapro.in"}
                        type={"text"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"database_url"}
                        label={"Database URL"}
                        placeholder={"aws"}
                        type={"text"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"pan_number"}
                        placeholder={"ABCTY1234D"}
                        label={"PAN number"}
                        type={"text"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"tan_number"}
                        placeholder={"PDES03028F"}
                        label={"TAN number"}
                        type={"text"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"gst_number"}
                        placeholder={"22AAAAA0000A1Z5"}
                        label={"GST number"}
                        type={"text"}
                      />
                    </div>
                  </div>
                  <div className="mt-2 sm:mt-4">
                    <Button name={"Add"} type={"Submit"} />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PremiumUserForm;

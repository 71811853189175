import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import FormikInput from "../Input/FormikInput";
import Button from "../Button/Button";
import axios from "axios";

function UpdateBudgetForm({ onClose, onSubmit, data }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center overflow-auto bg-black bg-opacity-50 z-50">
      <div className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto ">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-md w-full mx-auto">
          <div className="flex items-center justify-between">
            <div className="flex items-start justify-start mr-5">
              <h2 className="text-lg sm:text-2xl font-semibold  text-[#1d5b79]">
                {data.department}'s Budget
              </h2>
            </div>
            <div className="flex items-end justify-end mt-4">
              <button
                className="bg-[#1d5b79] p-2 sm:-mt-6 sm:-mr-4 -mt-3 -mr-2 text-lg text-[#fbffff] rounded-full "
                onClick={onClose}
              >
                <RxCross1 />
              </button>
            </div>
          </div>
          <Formik
            initialValues={{
              annual_budget: data.budget_amount || "",
            }}
            onSubmit={onSubmit}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className="mt-2 sm:mt-4">
                  <div>
                    <FormikInput
                      label={"Department budget"}
                      placeholder={"12xxxxx"}
                      name={"annual_budget"}
                      type={"tel"}
                    />
                  </div>
                  <div className="mt-2 sm:mt-4">
                    <Button type={"submit"} name={"Update"} />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default UpdateBudgetForm;

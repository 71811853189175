import React from "react";
import Button from "../Button/Button";
import FormikInput from "../Input/FormikInput";
import Date from "../Input/Date";
import { RxCross1 } from "react-icons/rx";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

function UpdateShootingAttendanceForm({
  onClose,
  onSubmit,
  selectedAttendance,
}) {
  const validationSchema = Yup.object().shape({
    actual_call_time: Yup.string().required("Actual call time is required"),
    roll_time: Yup.string().required("Roll time is required"),
    pack_up_time: Yup.string().required("Pack up time is required"),
    remarks: Yup.string().optional(),
  });
  return (
    <div>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center overflow-auto z-50">
        <div
          onClick={(e) => e.stopPropagation()}
          className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto"
        >
          <div className="bg-white p-4 sm:p-8 shadow-md max-w-5xl w-full mx-auto rounded">
            <div className="flex items-end justify-end">
              <button
                className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full "
                onClick={onClose}
              >
                <RxCross1 />
              </button>
            </div>
            <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
              {selectedAttendance?.schedule?.title}'s Shooting Attendance
            </h2>
            <Formik
              initialValues={{
                schedule: selectedAttendance.schedule || "",
                actual_call_time: selectedAttendance.call_time || "",
                roll_time: selectedAttendance.roll_time || "",
                pack_up_time: selectedAttendance.packup_time || "",
                remarks: selectedAttendance.remarks || "",
              }}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 sm:gap-4 mt-2 sm:mt-4">
                    <div>
                      <Field
                        component={Date}
                        name="actual_call_time"
                        label="Actual call time"
                      />
                    </div>
                    <div>
                      <Field
                        component={Date}
                        name="roll_time"
                        label="Roll time"
                      />
                    </div>
                    <div>
                      <Field
                        component={Date}
                        name="pack_up_time"
                        label="Pack up time"
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"remarks"}
                        label={"Remarks"}
                        type={"text"}
                        placeholder={"Remarks"}
                      />
                    </div>
                  </div>
                  <div className="sm:mt-4 mt-2">
                    <Button name="Update" type={"submit"} />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateShootingAttendanceForm;

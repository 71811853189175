import React, { useContext, useState } from "react";
import { MdDelete, MdModeEdit } from "react-icons/md";
import axios from "axios";
import toast from "react-hot-toast";
import SubRoleContext from "../../SubRoleContext";
import { useParams } from "react-router-dom";
import { BiSolidTrashAlt } from "react-icons/bi";
import Pagination from "../Pagination/Pagination";

function ShotDivisionUserTable({
  shotDetails,
  fetchShotDetails,
  setUpdateView,
  updateview,
  setSelectedShotDivision,
}) {
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);
  const subRole = useContext(SubRoleContext);
  const { projectId } = useParams();

  const groupAndSortShotDetails = () => {
    // Group shot details by scene_number + sub_scene
    const groupedShotDetails = {};
    shotDetails.forEach((detail) => {
      const sceneNumber = detail.scene.scene_number + detail.scene.sub_scene;
      if (!groupedShotDetails[sceneNumber]) {
        groupedShotDetails[sceneNumber] = [];
      }
      groupedShotDetails[sceneNumber].push(detail);
    });

    // Sort each group by shot_number
    Object.keys(groupedShotDetails).forEach((sceneNumber) => {
      groupedShotDetails[sceneNumber].sort((a, b) => {
        const shotNumberA = parseInt(a.shot_number);
        const shotNumberB = parseInt(b.shot_number);
        if (shotNumberA === shotNumberB) {
          // If shot numbers are equal, compare by id to ensure consistent sorting
          return a.id - b.id;
        }
        return shotNumberA - shotNumberB;
      });
    });

    // Flatten the grouped and sorted shot details back into an array
    const sortedShotDetails = Object.values(groupedShotDetails).flat();

    // Sort the flattened array by scene_number + sub_scene
    sortedShotDetails.sort((a, b) => {
      const sceneNumberA = a.scene.scene_number + a.scene.sub_scene;
      const sceneNumberB = b.scene.scene_number + b.scene.sub_scene;
      if (sceneNumberA === sceneNumberB) {
        // If scene_numbers are equal, compare by shot_number
        return parseInt(a.shot_number) - parseInt(b.shot_number);
      }
      return sceneNumberA.localeCompare(sceneNumberB);
    });

    return sortedShotDetails;
  };

  const sortedShotDetails = groupAndSortShotDetails();

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedShotDetails.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleDeleteshot_Detail = (id) => {
    const loader = toast.loading("Deleting ...", { autoClose: false });
    axios
      .delete(`api/v1/projects/${projectId}/shot_details/${id}`)
      .then((res) => {
        toast.dismiss(loader);
        toast.success("Deleted successfully...");
        fetchShotDetails();
      })
      .catch((err) => {
        toast.dismiss(loader);
        toast.error(
          err.response.data.message || err.message || err.response.data.error
        );
        console.log(err);
      });
  };

  const viewUpdateColumn = (id) => {
    setUpdateView(!updateview);
    const filtereddata = shotDetails.find((data) => data.id === id);
    setSelectedShotDivision(filtereddata);
  };

  let prevSceneNumber = null;
  const totalPages = Math.ceil(sortedShotDetails.length / itemsPerPage);

  return (
    <>
      <div className="flex flex-col h-full">
        <div className="w-full mx-auto bg-white  rounded-sm border border-gray-200">
          <header className="flex sm:flex-row flex-col  justify-between px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold text-gray-800">Shot details</h2>
            <Pagination
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              totalPages={totalPages}
            />
          </header>
          <div className="p-3">
            <div className="overflow-x-auto">
              <table className="table-auto w-full">
                <thead className="text-xs font-semibold uppercase text-gray-600 bg-[#eceff1]">
                  <tr>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Scene no</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Shot no</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Shot type</div>
                    </th>

                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-center">
                        Shot focus
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-center">
                        Shot angle
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-center">
                        Shot frame
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-center">
                        Shot mechanism
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-center">
                        Shot movements
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left ">Brief</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left ">Remark</div>
                    </th>
                    {subRole === "user" ? (
                      <></>
                    ) : (
                      <th className="px-4 py-2 whitespace-nowrap">
                        <div className="font-semibold text-center">Action</div>
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody className="text-sm divide-y divide-gray-100">
                  {currentItems.map((details, index) => {
                    const sceneNumber =
                      details.scene.scene_number + details.scene.sub_scene;
                    const isFirstOfScene = prevSceneNumber !== sceneNumber;
                    prevSceneNumber = sceneNumber;

                    return (
                      <tr key={index}>
                        <td className="p-2 whitespace-nowrap">
                          {isFirstOfScene ? sceneNumber : ""}
                        </td>
                        <td className="p-2">
                          <div className="text-left">{details.shot_number}</div>
                        </td>

                        <td className="p-2 whitespace-nowrap">
                          <div className="text-left">{details.shot_type}</div>
                        </td>
                        <td className="p-2 whitespace-nowrap">
                          <div className="text-center">
                            {details.shot_focus}
                          </div>
                        </td>

                        <td className="p-2 whitespace-nowrap">
                          <div className="text-center">
                            {details.shot_angle}
                          </div>
                        </td>
                        <td className="p-2 whitespace-nowrap">
                          <div className="text-center">
                            {details.shot_frame}
                          </div>
                        </td>
                        <td className="p-2 whitespace-nowrap">
                          <div className="text-center">
                            {details.shot_mechanism}
                          </div>
                        </td>
                        <td className="p-2 whitespace-nowrap">
                          <div className="text-center">
                            {details.shot_movements}
                          </div>
                        </td>
                        <td className="p-2 whitespace-nowrap">
                          <div
                            className="text-center"
                            style={{
                              width: "270px",
                              textAlign: "justify",
                            }}
                          >
                            {details.brief}
                          </div>
                        </td>
                        <td className="p-2 whitespace-nowrap">
                          <div
                            className="text-center"
                            style={{
                              width: "270px",
                              textAlign: "justify",
                            }}
                          >
                            {details.remark}
                          </div>
                        </td>
                        {subRole === "user" ? (
                          <> </>
                        ) : (
                          <td className="p-4 whitespace-nowrap flex">
                            <button
                              onClick={() => viewUpdateColumn(details.id)}
                              className="p-4 text-xl text-green-600 border-none hover:shadow-none rounded-full outline-none bg-green-50  duration-300 ease-in"
                            >
                              <MdModeEdit />
                            </button>
                            <button
                              onClick={() =>
                                handleDeleteshot_Detail(details.id)
                              }
                              className="ml-4 p-4 text-xl text-red-600 bg-red-50 hover:shadow-none rounded-full  duration-300 ease-in outline-none"
                            >
                              <BiSolidTrashAlt />
                            </button>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ShotDivisionUserTable;

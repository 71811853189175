import React, { useContext, useState } from "react";
import usePagination from "../Pagination/usePagination";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { AnimatePresence, motion } from "framer-motion";
import moment from "moment-timezone";
import "moment/locale/en-in";
import Pagination from "../Pagination/Pagination";
import { HiddenContext } from "../../Context/HiddenContent";

function TravelAccountTable({ data, handleTravelStatus }) {
  const [expandedView, setExpandedView] = useState({});
  const { hidden } = useContext(HiddenContext);
  const { currentItems, currentPage, handlePageChange, totalPages } =
    usePagination(data, 10);

  const itemVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
  };

  const toggleView = (id) => {
    setExpandedView((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const convertToIST = (utcDateStr) => {
    return moment.utc(utcDateStr).tz("Asia/Kolkata").format("DD-MM-YYYY");
  };

  const totalAmount = (rent, days) => {
    return rent * days;
  };
  return (
    <div>
      <div>
        <div>
          <div className="flex flex-col h-full">
            <div className="w-full mx-auto bg-white  rounded-sm border border-gray-200">
              <header className="flex sm:flex-row flex-col  justify-between px-5 py-4 border-b border-gray-100">
                <h2 className="font-semibold text-gray-800">Travel</h2>
                <Pagination
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                  totalPages={totalPages}
                />
              </header>
              <div className="p-3">
                <div className="overflow-x-auto">
                  <table className="table-auto w-full">
                    <thead className="text-xs font-semibold uppercase text-gray-600 bg-[#eceff1]">
                      <tr>
                        <th className="px-4 py-2 whitespace-nowrap">
                          <div className="font-semibold text-left">
                            Vehicle name
                          </div>
                        </th>
                        <th className="px-4 py-2 whitespace-nowrap">
                          <div className="font-semibold text-left">
                            Vehicle type
                          </div>
                        </th>
                        <th className="px-4 py-2 whitespace-nowrap">
                          <div className="font-semibold text-left">
                            Vehicle number plate
                          </div>
                        </th>
                        <th className="px-4 py-2 whitespace-nowrap">
                          <div className="font-semibold text-left">
                            Driver name
                          </div>
                        </th>
                        <th className="px-4 py-2 whitespace-nowrap">
                          <div className="font-semibold text-left">
                            Driver number
                          </div>
                        </th>
                        <th className="px-4 py-2 whitespace-nowrap">
                          <div className="font-semibold text-left">
                            Start date
                          </div>
                        </th>
                        <th className="px-4 py-2 whitespace-nowrap">
                          <div className="font-semibold text-left">
                            End date
                          </div>
                        </th>
                        <th className="px-4 py-2 whitespace-nowrap">
                          <div className="font-semibold text-left">Rent</div>
                        </th>
                        <th className="px-4 py-2 whitespace-nowrap">
                          <div className="font-semibold text-left">
                            Passengers
                          </div>
                        </th>
                        <th className="px-4 py-2 whitespace-nowrap">
                          <div className="font-semibold text-left">Status</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-sm divide-y divide-gray-100">
                      {currentItems.map((vehicle, index) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td className="p-2">
                                <div className="text-left capitalize">
                                  {vehicle.vehicle.name}
                                </div>
                              </td>
                              <td className="p-2">
                                <div className="text-left">
                                  {vehicle.vehicle.vehicle_type}
                                </div>
                              </td>
                              <td className="p-2">
                                <div className="text-left">
                                  {vehicle.vehicle.number_plate}
                                </div>
                              </td>
                              <td className="p-2">
                                <div className="text-left capitalize">
                                  {vehicle.vehicle.driver_name}
                                </div>
                              </td>
                              <td className="p-2">
                                <div className="text-left">
                                  {vehicle.vehicle.driver_number}
                                </div>
                              </td>
                              <td className="p-2">
                                <div className="text-left">
                                  {convertToIST(vehicle.start_date)}
                                </div>
                              </td>
                              <td className="p-2">
                                <div className="text-left">
                                  {convertToIST(vehicle.end_date)}
                                </div>
                              </td>
                              <td className="p-2">
                                <div className="text-left">
                                  {totalAmount(
                                    vehicle.vehicle.rate,
                                    vehicle.total_days
                                  )}
                                </div>
                              </td>
                              <td className="p-2">
                                <button
                                  onClick={() => toggleView(vehicle.id)}
                                  className="text-xl border-none outline-none text-[#fbffff] bg-[#1d5b79] rounded-full p-1"
                                >
                                  {expandedView[vehicle.id] ? (
                                    <FaChevronUp size={15} />
                                  ) : (
                                    <FaChevronDown size={15} />
                                  )}
                                </button>
                              </td>

                              {vehicle.status === "pending" ? (
                                <React.Fragment>
                                  {hidden ? (
                                    <React.Fragment>
                                      <td className="p-2 whitespace-nowrap text-orange-600">
                                        Pending
                                      </td>
                                    </React.Fragment>
                                  ) : (
                                    <td className="p-2 whitespace-nowrap">
                                      <div className="text-center">
                                        <button
                                          onClick={() =>
                                            handleTravelStatus(
                                              "approved",
                                              vehicle.id,
                                              totalAmount(
                                                vehicle.vehicle.rate,
                                                vehicle.total_days
                                              )
                                            )
                                          }
                                          className="p-4  text-green-600 border-none hover:shadow-none rounded-full outline-none bg-green-50 shadow-lg duration-300 ease-in"
                                        >
                                          Approve
                                        </button>
                                        <button
                                          onClick={() =>
                                            handleTravelStatus(
                                              "rejected",
                                              vehicle.id
                                            )
                                          }
                                          className="ml-4 p-4  text-red-600 bg-red-50 hover:shadow-none rounded-full shadow-lg duration-300 ease-in outline-none"
                                        >
                                          Reject
                                        </button>
                                      </div>
                                    </td>
                                  )}
                                </React.Fragment>
                              ) : vehicle.status === "approved" ? (
                                <td className="p-2 whitespace-nowrap  text-green-800">
                                  <div className="text-center">Approved</div>
                                </td>
                              ) : vehicle.status === "rejected" ? (
                                <td className="p-2 whitespace-nowrap  text-red-800">
                                  <div className="text-center">Rejected</div>
                                </td>
                              ) : (
                                <td className="p-2 whitespace-nowrap flex"></td>
                              )}
                            </tr>
                            <AnimatePresence>
                              {expandedView[vehicle.id] && (
                                <motion.tr
                                  key={vehicle.id}
                                  initial="hidden"
                                  animate="visible"
                                  exit="exit"
                                  variants={itemVariants}
                                  className="bg-gray-50"
                                  transition={{ duration: 0.3, delay: 0.1 }}
                                >
                                  <td colSpan={9} className="p-4">
                                    <div className="flex flex-col">
                                      <div className="flex items-start justify-start">
                                        <h2 className="text-base font-semibold text-[#1d5b79] uppercase">
                                          Passengers
                                        </h2>
                                      </div>
                                      <div className="grid grid-cols-4 gap-4">
                                        {Object.values(
                                          vehicle.assigned_details
                                        ).map((data, index) => (
                                          <div className="p-3" key={index}>
                                            <h2 className="text-sm font-semibold text-[#1d5b79]">
                                              {data.name}:
                                              <span className="font-none text-gray-500 ml-1">
                                                {data.pickup_address}
                                              </span>
                                            </h2>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </td>
                                </motion.tr>
                              )}
                            </AnimatePresence>
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TravelAccountTable;

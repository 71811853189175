const vendorType = [
  { name: "Travel" },
  { name: "Food" },
  { name: "Camera and Light equipment" },
  { name: "Light Equipment" },
  { name: "Crowd" },
  { name: "Casting agency" },
  { name: "Spot" },
  { name: "Makeup" },
  { name: "Costume" },
  { name: "Catering" },
  { name: "Legal" },
  { name: "Insurance" },
  { name: "VFX" },
  { name: "Marketing" },
  { name: "Distribution" },
  { name: "Publicity design" },
  { name: "Post production services" },
  { name: "Location services" },
  { name: "Account" },
  { name: "PR agency" },
  { name: "Digital Marketing" },
  { name: "Hoarding Agency" },
  { name: "Event Planning" },
];
const option = [
  { id: 1, name: "Main Lead" },
  { id: 2, name: "Supporting" },
  { id: 3, name: "Character Artist" },
  { id: 4, name: "Child" },
  { id: 5, name: "Teenager" },
];
const GenderOption = [
  { id: 1, name: "Male" },
  { id: 2, name: "Female" },
];
const amountTypeOption = [
  { id: 1, name: "Per Day" },
  { id: 2, name: "Package" },
];

const subScene = [
  { name: "A" },
  { name: "B" },
  { name: "C" },
  { name: "D" },
  { name: "E" },
];
const shiftOption = [{ name: "Day" }, { name: "Night" }];
const LocationTypeOption = [{ name: "Outdoor" }, { name: "Indoor" }];
const crowdAgeOption = [
  { name: "Adult" },
  { name: "Old" },
  { name: "Teenage" },
  { name: "Kid" },
];
const role = [
  { id: 1, name: "Artist" },
  { id: 2, name: "Crew" },
  { id: 3, name: "Vendor" },
  { id: 4, name: "Location owner" },
];

const month = [
  { id: 1, name: "January" },
  { id: 2, name: "February" },
  { id: 3, name: "March" },
  { id: 4, name: "April" },
  { id: 5, name: "May" },
  { id: 6, name: "June" },
  { id: 7, name: "July" },
  { id: 8, name: "August" },
  { id: 9, name: "September" },
  { id: 10, name: "October" },
  { id: 11, name: "November" },
  { id: 12, name: "December" },
];

const paymentMode = [
  { name: "Cash" },
  { name: "Cheque" },
  { name: "Net banking" },
];
const Dialogue = [
  { dialogue: "Mogambo Khush Hua" },
  { dialogue: "Main apni favorite hoon" },
  { dialogue: "Mere paas Maa hai." },
  { dialogue: "Jaa Simran, jaa. Jeele apni zindagi" },
  { dialogue: "Kutte kaminey main tera khoon pee jaunga." },
  {
    dialogue:
      "Aaya hoon, kuch to loot kar jaunga, khandani chor hoon main, khandani, Mogambo ka bhatija, Gogo",
  },
  { dialogue: "Paisa hi Paisa Hoga!" },
  { dialogue: "Sadak se utha kar star banaunga" },
  { dialogue: "Tum kab aaye? ….Sabse pehle mein hi aaya" },
  { dialogue: "Control Uday... Control" },
  {
    dialogue: "Meri ek taang nakli hai, main hockey ka bohat bada khiladi tha",
  },
  { dialogue: "Dekho ye Zinda Hain" },
  { dialogue: "Yeh raaz bhi usi ke saath chala gaya" },
  { dialogue: "Bhai Sahab, ye kis line mei aa gaye aap? " },
  {
    dialogue: "Bhagwan ka dia sab kuch hai…daulat hai…shohrat hai, izzat hai",
  },
  { dialogue: "Tension lene ka nahi, sirf dene ka." },
  { dialogue: "Basanti in kutto ke samne mat nachna" },
  { dialogue: "Kitne aadmi the?" },
  { dialogue: "Ek chutki sindoor ki keemat tum kya jaano Ramesh Babu" },
  { dialogue: "Kya tum mantally challenged ho my buoy?" },
  { dialogue: "Picture abhi baaki hai mere dost." },
  { dialogue: "Our Business Is Our Business None of Your Business!" },
  { dialogue: "Uska toh na bad luck hi kharab hai" },
  { dialogue: "Aap Purush Hi Nahi, Mahapurush Hai" },
  { dialogue: "Utha le re baba." },
  { dialogue: "All izz well" },
  {
    dialogue:
      "Parampara. Pratishtha. Anushasan. Yeh is gurukul ke teen stambh hai.",
  },
  { dialogue: "Tussi ja rahe ho? Tussi na jao!" },
  { dialogue: "Kuch kuch hota hai, Anjali, tum nahi samjhogi." },
  { dialogue: "Don’t underestimate the power of a common man" },
  {
    dialogue:
      "Ek Baar Jo Maine Commitment Kar Di Toh Mai apne aap Ki bhi nhi Sunta.",
  },
  { dialogue: "Tumse naa ho payega" },
  {
    dialogue:
      "Tareekh pe tareekh, tareekh pe tareekh, tareekh pe tareekh milti gayi My Lord, par insaaf nahi mila",
  },
  { dialogue: "Mere Karan Arjun Aayenge" },
  {
    dialogue:
      "Bade bade deshon mein aisi choti-choti baatein hoti rehti hai, Senorita",
  },
  {
    dialogue: "Bhai apka knowledge toh kamal ka hein",
  },
];
const Types = [
  { label: "Make up" },
  { label: "Property" },
  { label: "Costume" },
  { label: "Location" },
  { label: "Time" },
];
const partnerType = [{ name: "Individual" }, { name: "Production house" }];

const carteringType = [{ type: "Package" }, { type: "Individual" }];

const vehicleType = [
  { type: "Hatchback" },
  { type: "Sedan" },
  { type: "SUV" },
  { type: "Pickup truck" },
];
const accomodationType = [
  { type: "Hotel" },
  { type: "Resort" },
  { type: "Flat" },
  { type: "Bungalow" },
  { type: "Farm House" },
];

const roomType = [
  { type: "Standard" },
  { type: "Deluxe" },
  { type: "Executive" },
  { type: "Suite" },
];

const durationOptions = [
  { name: "Pre-production" },
  { name: "Production" },
  { name: "Post-production" },
  { name: "Marketing" },
  { name: "Release" },
];

const meals = [{ type: "Breakfast" }, { type: "Lunch" }, { type: "Dinner" }];

const smtpTokenSteps = [
  {
    stepNumber: 1,
    description:
      "Set up a Google account or another email account with the provider you want to use for SMTP.",
  },
  {
    stepNumber: 2,
    description:
      "Enable 2-Step Verification in your Google Account (or the equivalent for your provider).",
  },
  {
    stepNumber: 3,
    description:
      "Go to your account’s security settings and locate the App Passwords section.",
  },
  {
    stepNumber: 4,
    description:
      "In the App Passwords section, select the type of app (e.g., Mail) and device (e.g., Custom name) for which you want to generate the token.",
  },
  {
    stepNumber: 5,
    description:
      "Click Generate to create an app-specific password (this will act as your SMTP token).",
  },
  {
    stepNumber: 6,
    description:
      "Copy the generated app password/token and save it securely, as it will only be shown once.",
  },
  {
    stepNumber: 7,
    description:
      "Use this token as the password in your SMTP configuration for secure email sending.",
  },
];

export {
  vendorType,
  option,
  GenderOption,
  amountTypeOption,
  subScene,
  shiftOption,
  LocationTypeOption,
  crowdAgeOption,
  role,
  month,
  paymentMode,
  Dialogue,
  Types,
  carteringType,
  partnerType,
  vehicleType,
  accomodationType,
  roomType,
  meals,
  durationOptions,
  smtpTokenSteps
};

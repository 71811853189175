import React, { useContext, useState } from "react";
import Button from "../Button/Button";
import { RxCross1 } from "react-icons/rx";
import { ErrorMessage, Field, Form, Formik } from "formik";
import FormikInput from "../Input/FormikInput";
import FormikSelect from "../Input/FormikSelect";
import SubRoleContext from "../../SubRoleContext";
import * as Yup from "yup";
import FormikRadio from "../Input/FormikRadio";
import { GenderOption, amountTypeOption, option } from "../Data";
import Select from "react-select";

function ArtistDetailForm({ handleBackdropClick, addArtistHandler, artists }) {
  const [artist, setArtist] = useState("");

  const subRole = useContext(SubRoleContext);
  const validationSchema =
    subRole === "manager"
      ? Yup.object().shape({
          artist_id: Yup.string().required("Select an artist."),
          artist_type: Yup.string().required("Artist type required"),
          character_name: Yup.string()
            .min(2, "Character name should have at least 2 letters")
            .required("Character name required"),
          number_of_days: Yup.number().required("Number of days required"),
          gender: Yup.string().required("Select gender of artist"),
        })
      : Yup.object().shape({
          artist_id: Yup.string().required("Select an artist."),
          artist_type: Yup.string().required("Artist type required"),
          amount_type: Yup.string().required("Amount type required"),
          amount: Yup.number().required("Amount is required"),
          gender: Yup.string().required("Select gender of artist"),
          character_name: Yup.string()
            .min(2, "Character name should have at least 2 letters")
            .required("Character name required"),
          number_of_days: Yup.number().required("Number of days required"),

          gst_required: Yup.boolean().required("Select if GST is required"),
          gst_number: Yup.string().when("gst_required", {
            is: true,
            then: (schema) =>
              schema
                .matches(
                  /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Z]{1}[0-9A-Za-z]{1}$/,
                  "Invalid GST number"
                )
                .required("GST number is required"),
            otherwise: (schema) => schema.notRequired(),
          }),
          tds_required: Yup.boolean().required("Select if TDS is required"),
          tds: Yup.number().when("tds_required", {
            is: true,
            then: (schema) =>
              schema
                .typeError("TDS must be a number")
                .positive("TDS must be a positive number")
                .required("TDS is required"),
            otherwise: (schema) => schema.notRequired(),
          }),
        });

  const handleArtistTypeOption = (value, setFieldValue) => {
    if (value) {
      setFieldValue("artist_type", value);
    } else {
      setFieldValue("artist_type", "");
    }
  };

  const handleArtistGenderChange = (value, setFieldValue) => {
    if (value) {
      setFieldValue("gender", value);
    } else {
      setFieldValue("gender", "");
    }
  };

  const handleArtistPackage = (response, setFieldValue, values) => {
    if (response) {
      setFieldValue("amount_type", response);
    } else {
      setFieldValue("amount_type", "");
    }
    if (values.gst_required === true) {
      if (response === "Per Day") {
        const amount =
          parseInt(values.amount) * parseInt(values.number_of_days);
        const gstAmount = (parseInt(amount) * 18) / 100;
        setFieldValue("gst_amount", gstAmount);
      } else {
        const amount = parseInt(values.amount);
        const gstAmount = (amount * 18) / 100;
        setFieldValue("gst_amount", gstAmount);
      }
    } else {
      setFieldValue("gst_amount", 0);
    }
    if (values.tds_required === true) {
      if (response === "Per Day") {
        const amount =
          parseInt(values.amount) * parseInt(values.number_of_days);
        const tdsAmount = (parseInt(amount) * parseInt(values.tds)) / 100;
        setFieldValue("tds_amount", tdsAmount);
      } else {
        const amount = parseInt(values.amount);
        const tdsAmount = (parseInt(amount) * parseInt(values.tds)) / 100;
        setFieldValue("tds_amount", tdsAmount);
      }
    } else {
      setFieldValue("tds_amount", 0);
      setFieldValue("tds", 0);
    }
  };

  const handleGst = (setFieldValue, response, values) => {
    setFieldValue("gst_required", response);
    if (response === true) {
      if (values.amount_type === "Per Day") {
        const amount =
          parseInt(values.amount) * parseInt(values.number_of_days);
        const gstAmount = (parseInt(amount) * 18) / 100;
        setFieldValue("gst_amount", gstAmount);
      } else {
        const amount = parseInt(values.amount);
        const gstAmount = (amount * 18) / 100;
        setFieldValue("gst_amount", gstAmount);
      }
    } else {
      setFieldValue("gst_amount", 0);
    }
  };

  const handleTDSChange = (setFieldValue, response, values) => {
    setFieldValue("tds", response);
    if (values.tds_required === true) {
      if (values.amount_type === "Per Day") {
        const amount =
          parseInt(values.amount) * parseInt(values.number_of_days);
        const tdsAmount = (parseInt(amount) * parseInt(response)) / 100;
        setFieldValue("tds_amount", tdsAmount);
      } else {
        const amount = parseInt(values.amount);
        const tdsAmount = (parseInt(amount) * parseInt(response)) / 100;
        setFieldValue("tds_amount", tdsAmount);
      }
    } else {
      setFieldValue("tds_amount", 0);
      setFieldValue("tds", 0);
    }
  };

  const handleAmountChange = (setFieldValue, response, values) => {
    setFieldValue("amount", response);
    if (values.gst_required === true) {
      if (values.amount_type === "Per Day") {
        const amount = parseInt(response) * parseInt(values.number_of_days);
        const gstAmount = (parseInt(amount) * 18) / 100;
        setFieldValue("gst_amount", gstAmount);
      } else {
        const amount = parseInt(response);
        const gstAmount = (amount * 18) / 100;
        setFieldValue("gst_amount", gstAmount);
      }
    } else {
      setFieldValue("gst_amount", 0);
    }
    if (values.tds_required === true) {
      if (values.amount_type === "Per Day") {
        const amount = parseInt(response) * parseInt(values.number_of_days);
        const tdsAmount = (parseInt(amount) * parseInt(values.tds)) / 100;
        setFieldValue("tds_amount", tdsAmount);
      } else {
        const amount = parseInt(response);
        const tdsAmount = (parseInt(amount) * parseInt(values.tds)) / 100;
        setFieldValue("tds_amount", tdsAmount);
      }
    } else {
      setFieldValue("tds_amount", 0);
      setFieldValue("tds", 0);
    }
  };

  const handleNumberOfDaysChange = (setFieldValue, response, values) => {
    setFieldValue("number_of_days", response);
    if (values.gst_required === true) {
      if (values.amount_type === "Per Day") {
        const amount = parseInt(values.amount) * parseInt(response);
        console.log(amount);
        const gstAmount = (parseInt(amount) * 18) / 100;
        setFieldValue("gst_amount", gstAmount);
      } else {
        const amount = parseInt(values.amount);
        const gstAmount = (amount * 18) / 100;
        setFieldValue("gst_amount", gstAmount);
      }
    } else {
      setFieldValue("gst_amount", 0);
    }
    if (values.tds_required === true) {
      if (values.amount_type === "Per Day") {
        const amount = parseInt(values.amount) * parseInt(response);
        const tdsAmount = (parseInt(amount) * parseInt(values.tds)) / 100;
        setFieldValue("tds_amount", tdsAmount);
      } else {
        const amount = parseInt(values.amount);
        const tdsAmount = (parseInt(amount) * parseInt(values.tds)) / 100;
        setFieldValue("tds_amount", tdsAmount);
      }
    } else {
      setFieldValue("tds_amount", 0);
      setFieldValue("tds", 0);
    }
  };

  const handleArtistChange = (value, setFieldValue) => {
    setArtist({ ...value });
    setFieldValue("artist_name", value.id);
  };

  return (
    <div>
      <div className="fixed inset-0 flex items-center justify-center z-50 overflow-auto bg-black bg-opacity-50 ">
        <div className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto ">
          <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-5xl w-full mx-auto ">
            <div className="flex items-end justify-end">
              <button
                className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full "
                onClick={handleBackdropClick}
              >
                <RxCross1 />
              </button>
            </div>
            <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
              Artist
            </h2>
            <div>
              <Formik
                validationSchema={validationSchema}
                initialValues={{
                  artist_id: "" || artist.id,
                  artist_type: "",
                  amount_type: "",
                  character_name: "",
                  amount: "",
                  gender: "" || artist.gender,
                  number_of_days: "",
                  gst_required: false,
                  gst_number: "" || artist.gst_number,
                  gst_per: 18,
                  tds_required: false,
                  tds: "",
                  gst_amount: 0,
                  tds_amount: 0,
                  nda_doc: "",
                  agreement_doc: "",
                }}
                enableReinitialize
                onSubmit={addArtistHandler}
              >
                {({ values, setFieldValue, errors }) => (
                  <Form>
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 sm:gap-4 mt-2 sm:mt-4">
                      <div>
                        <label className="block mb-2 text-sm text-gray-600 ">
                          Artist
                        </label>
                        <Select
                          name="artist_id"
                          getOptionLabel={(option) => option.artist_name}
                          getOptionValue={(option) => option.id}
                          options={artists}
                          onChange={(selectedOption) =>
                            handleArtistChange(selectedOption, setFieldValue)
                          }
                        />
                        <ErrorMessage
                          name="artist_name"
                          component="div"
                          className="text-red-500 text-sm mt-1"
                        />
                      </div>
                      <div>
                        <FormikSelect
                          name={"artist_type"}
                          selectedOption={"Artist type"}
                          optionData={option}
                          onChange={(e) =>
                            handleArtistTypeOption(
                              e.target.value,
                              setFieldValue
                            )
                          }
                          label={"Artist type"}
                        />
                      </div>
                      <div>
                        <FormikSelect
                          name={"gender"}
                          selectedOption={"Gender"}
                          disable={true}
                          onChange={(e) =>
                            handleArtistGenderChange(
                              e.target.value,
                              setFieldValue
                            )
                          }
                          optionData={GenderOption}
                          label={"Gender"}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                      <div>
                        <label className="block mb-2 text-sm text-gray-600 ">
                          Number of days
                        </label>
                        <Field
                          name="number_of_days"
                          placeholder="55"
                          type="number"
                          onChange={(e) =>
                            handleNumberOfDaysChange(
                              setFieldValue,
                              e.target.value,
                              values
                            )
                          }
                          className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#1d5b79]  focus:ring-[#1d5b79] focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        <ErrorMessage
                          name="number_of_days"
                          className="text-red-500 text-sm mt-1"
                          component="div"
                        />
                      </div>
                      <div>
                        <FormikInput
                          name={"character_name"}
                          label={"Character name"}
                          type={"text"}
                          placeholder={"Norman Osborn"}
                        />
                      </div>
                    </div>
                    {subRole === "manager" || subRole === "user" ? (
                      <></>
                    ) : (
                      <>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                          <div>
                            <FormikSelect
                              name={"amount_type"}
                              label={"Payment type"}
                              onChange={(e) =>
                                handleArtistPackage(
                                  e.target.value,
                                  setFieldValue,
                                  values
                                )
                              }
                              selectedOption={"Payment type"}
                              optionData={amountTypeOption}
                            />
                          </div>
                          <div>
                            <label className="block mb-2 text-sm text-gray-600 ">
                              Amount
                            </label>
                            <Field
                              name="amount"
                              placeholder="125****"
                              type="number"
                              onChange={(e) =>
                                handleAmountChange(
                                  setFieldValue,
                                  e.target.value,
                                  values
                                )
                              }
                              className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#1d5b79]  focus:ring-[#1d5b79] focus:outline-none focus:ring focus:ring-opacity-40"
                            />
                            <ErrorMessage
                              name="amount"
                              className="text-red-500 text-sm mt-1"
                              component="div"
                            />
                          </div>
                          <div>
                            <label className="block mb-2 text-sm text-gray-600 ">
                              NDA
                            </label>
                            <input
                              name="nda_doc"
                              type="file"
                              accept=".pdf,image/*"
                              onChange={(event) => {
                                setFieldValue(
                                  "nda_doc",
                                  event.currentTarget.files[0]
                                );
                              }}
                              className="cursor-pointer block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#24b7aa]  focus:ring-[#24b7aa] focus:outline-none focus:ring focus:ring-opacity-40"
                            />
                          </div>
                          <div>
                            <label className="block mb-2 text-sm text-gray-600 ">
                              Agreement
                            </label>
                            <input
                              name="agreement_doc"
                              type="file"
                              accept=".pdf,image/*"
                              onChange={(event) => {
                                setFieldValue(
                                  "agreement_doc",
                                  event.currentTarget.files[0]
                                );
                              }}
                              className="cursor-pointer block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#24b7aa]  focus:ring-[#24b7aa] focus:outline-none focus:ring focus:ring-opacity-40"
                            />
                          </div>
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                          <div className="flex flex-col">
                            <div>
                              <label className="block mb-2 text-sm text-gray-600 ">
                                Does this artist charge GST ?
                              </label>
                              <div className="flex flex-row">
                                <div>
                                  <FormikRadio
                                    id={"yes"}
                                    label={"Yes"}
                                    value={true}
                                    onChange={() =>
                                      handleGst(setFieldValue, true, values)
                                    }
                                    name={"gst_required"}
                                  />
                                </div>
                                <div className="ml-2">
                                  <FormikRadio
                                    id={"no"}
                                    value={false}
                                    onChange={() =>
                                      handleGst(setFieldValue, false, values)
                                    }
                                    label={"No"}
                                    name={"gst_required"}
                                  />
                                </div>
                              </div>
                            </div>
                            {values.gst_required ? (
                              <>
                                <div className="mt-2">
                                  <FormikInput
                                    name={"gst_number"}
                                    label={"GST number"}
                                    placeholder={"22AAAAA0000A1Z5"}
                                    type={"text"}
                                    readOnly={true}
                                  />
                                </div>
                                <div className="mt-2">
                                  <FormikInput
                                    label={"GST Amount"}
                                    name={"gst_amount"}
                                    type={"number"}
                                    readOnly={true}
                                  />
                                </div>
                              </>
                            ) : (
                              <div></div>
                            )}
                          </div>

                          <div className="flex flex-col">
                            <label className="block mb-2 text-sm text-gray-600 ">
                              TDS ?
                            </label>
                            <div className="flex flex-row">
                              <div>
                                <FormikRadio
                                  id={"yes"}
                                  label={"Yes"}
                                  value={true}
                                  onChange={() =>
                                    setFieldValue("tds_required", true)
                                  }
                                  name={"tds_required"}
                                />
                              </div>
                              <div className="ml-2">
                                <FormikRadio
                                  id={"no"}
                                  value={false}
                                  onChange={() => {
                                    setFieldValue("tds_required", false);
                                    setFieldValue("tds", 0);
                                    setFieldValue("tds_amount", 0);
                                  }}
                                  label={"No"}
                                  name={"tds_required"}
                                />
                              </div>
                            </div>
                            {values.tds_required ? (
                              <>
                                <div className="mt-2">
                                  <label className="block mb-2 text-sm text-gray-600 ">
                                    TDS(%)
                                  </label>
                                  <Field
                                    name="tds"
                                    placeholder={"TDS"}
                                    type="number"
                                    onChange={(e) =>
                                      handleTDSChange(
                                        setFieldValue,
                                        e.target.value,
                                        values
                                      )
                                    }
                                    className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#1d5b79]  focus:ring-[#1d5b79] focus:outline-none focus:ring focus:ring-opacity-40"
                                  />
                                  <ErrorMessage
                                    name="tds"
                                    className="text-red-500 text-sm mt-1"
                                    component="div"
                                  />
                                </div>
                                <div className="mt-2">
                                  <FormikInput
                                    label={"TDS amount"}
                                    name={"tds_amount"}
                                    type={"number"}
                                    readOnly={true}
                                  />
                                </div>
                              </>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    <div className="mt-2 sm:mt-4">
                      <Button name={"Add Artist"} type={"submit"} />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArtistDetailForm;

import React, { useContext, useEffect, useState } from "react";
import { SidebarContext } from "./SuperAdmin/SidebarContext";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import ErrorPage from "./ErrorPage";
import Loader from "../Loader/loader";
import Button from "../components/Button/Button";
import SettingCrewForm from "../components/Forms/SettingCrewForm";
import toast from "react-hot-toast";
import CrewSettingTable from "../components/Table/CrewSettingTable";
import UpdateSettingCrewForm from "../components/UpdateForm/UpdateSettingCrewForm";
import DeleteModal from "../Modal/DeleteModal";

function Crew() {
  const { view } = useContext(SidebarContext);
  const [open, setOpen] = useState(false);
  const [crews, setCrews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCrew, setSelectedCrew] = useState("");
  const [update, setUpdate] = useState(false);
  const [Delete, setDelete] = useState(false);
  const [error, setError] = useState(false);

  const fetchCrew = () => {
    axios
      .get(`api/v1/crews`)
      .then((res) => {
        if (res.status === 200) {
          setLoading(true);
          setCrews(res.data.crews);
        }
      })
      .catch((err) => {
        setError(true);
        console.log(err);
      });
  };

  const handleUpdateCrewView = (crew) => {
    setSelectedCrew({ ...crew });
    setUpdate(true);
  };

  const handleDeleteCrewView = (crew) => {
    setSelectedCrew({ ...crew });
    setDelete(true);
  };

  const handleAddCrew = (value, { resetForm }) => {
    const loader = toast.loading("Adding ...", { autoClose: false });

    const formData = new FormData();
    formData.append("crew[full_name]", value.full_name);
    formData.append("crew[phone_number]", value.phone_number);
    formData.append("crew[email]", value.email);
    formData.append("crew[pan_card_number]", value.pan_card_number);
    formData.append("crew[bank_account_number]", value.bank_account_number);
    formData.append("crew[bank_name]", value.bank_name);
    formData.append("crew[bank_branch]", value.bank_branch);
    formData.append("crew[ifsc]", value.ifsc);
    formData.append("crew[gst_number]", value.gst_number);
    formData.append("crew[aadhar_card]", value.aadhar_card);
    formData.append("crew[pan_card]", value.pan_card);
    formData.append("crew[cancel_cheque]", value.cancel_cheque);

    axios
      .post(`api/v1/crews`, formData)
      .then((res) => {
        if (res.status === 201) {
          toast.dismiss(loader);
          toast.success(res.data.message);
          fetchCrew();
          setOpen(false);
          resetForm();
        }
      })
      .catch((err) => {
        toast.dismiss(loader);
        toast.error(err.message);
        console.log(err);
      });
  };
  const handleUpdateCrew = (value, { resetForm }) => {
    const loader = toast.loading(`Updating ${selectedCrew.full_name}  ...`, {
      autoClose: false,
    });

    const formData = new FormData();
    formData.append("crew[full_name]", value.full_name);
    formData.append("crew[phone_number]", value.phone_number);
    formData.append("crew[email]", value.email);
    formData.append("crew[pan_card_number]", value.pan_card_number);
    formData.append("crew[bank_account_number]", value.bank_account_number);
    formData.append("crew[bank_name]", value.bank_name);
    formData.append("crew[bank_branch]", value.bank_branch);
    formData.append("crew[ifsc]", value.ifsc);
    formData.append("crew[gst_number]", value.gst_number);
    if (value.aadhar_card) {
      formData.append("crew[aadhar_card]", value.aadhar_card);
    }
    if (value.pan_card) {
      formData.append("crew[pan_card]", value.pan_card);
    }
    if (value.cancel_cheque) {
      formData.append("crew[cancel_cheque]", value.cancel_cheque);
    }

    axios
      .put(`api/v1/crews/${selectedCrew.id}`, formData)
      .then((res) => {
        if (res.status === 200) {
          toast.dismiss(loader);
          toast.success(res.data.message);
          fetchCrew();
          setUpdate(false);
          resetForm();
        }
      })
      .catch((err) => {
        toast.dismiss(loader);
        toast.error(err.message);
        console.log(err);
      });
  };

  const handleDeleteCrew = () => {
    const loader = toast.loading(`Removing ${selectedCrew.full_name} ...`, {
      autoClose: false,
    });

    axios
      .delete(`api/v1/crews/${selectedCrew.id}`)
      .then((res) => {
        if (res.status === 200) {
          toast.dismiss(loader);
          toast.success(res.data.message);
          setDelete(false);
          fetchCrew();
        }
      })
      .catch((err) => {
        toast.dismiss(loader);
        toast.error(err.message);
        console.log(err);
      });
  };

  useEffect(() => {
    fetchCrew();
  }, []);

  return (
    <div>
      <Sidebar />
      <div
        className={`h-screen p-7 ${
          !view ? "ml-40 duration-100 ease-in" : "ml-0 duration-100 ease-in"
        }`}
      >
        {error ? (
          <div>
            <ErrorPage />
          </div>
        ) : (
          <div>
            {!loading ? (
              <Loader />
            ) : (
              <div className="flex flex-col sm:flex sm:flex-col p-5">
                <div className="flex sm:items-start items-center justify-center sm:justify-start xl:ml-20 2xl:ml-20 md:ml-20 sm:ml-20 ml-0 sm:mt-0 mt-8">
                  <Button
                    name={"Add Crew"}
                    clickFunction={() => setOpen(true)}
                  />
                </div>
                <div className="flex items-center justify-center  xl:ml-20 2xl:ml-20 lg:ml-20 md:ml-20 sm:ml-20 ml-0">
                  <div className="w-full mt-5">
                    <CrewSettingTable
                      detail={crews}
                      deleteView={handleDeleteCrewView}
                      updateView={handleUpdateCrewView}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {open && (
        <SettingCrewForm
          onClose={() => setOpen(false)}
          onSubmit={handleAddCrew}
        />
      )}
      {update && (
        <UpdateSettingCrewForm
          onClose={() => setUpdate(false)}
          details={selectedCrew}
          onSubmit={handleUpdateCrew}
        />
      )}
      {Delete && (
        <DeleteModal
          title={"Remove Crew"}
          message={`Are you sure you want to remove ${selectedCrew.full_name}?`}
          onClose={() => setDelete(false)}
          onDelete={handleDeleteCrew}
        />
      )}
    </div>
  );
}

export default Crew;

import React from "react";
import { RiFilmFill } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();
  return (
    <footer className="bg-[#1d5b79] text-white py-8">
      <div className="container px-4 md:px-6 flex flex-col md:flex-row items-center justify-between">
        <div className="flex items-center gap-2">
          <RiFilmFill className="h-7 w-7 text-[#a9c8e2] cursor-pointer m-2" />
          <span className="text-lg font-semibold">CinemaPro</span>
        </div>
        <nav className="flex items-center gap-6 mt-4 md:mt-0">
          <p
            onClick={() => navigate("/PrivacyPolicy")}
            className="hover:underline cursor-pointer"
          >
            Privacy Policy
          </p>
          <p
            onClick={() => navigate("/RefundPolicy")}
            className="hover:underline cursor-pointer"
          >
            Refund & Cancellation Policy
          </p>
          <p
            onClick={() => navigate("/TermsAndConditions")}
            className="hover:underline cursor-pointer"
          >
            Terms & Conditions
          </p>
          <p
            onClick={() => navigate("/ContactUs")}
            className="hover:underline cursor-pointer"
          >
            Contact Us
          </p>
        </nav>
      </div>
    </footer>
  );
}

export default Footer;

import React, { useState } from "react";
import { BiSolidTrashAlt } from "react-icons/bi";
import { MdModeEdit } from "react-icons/md";
import usePagination from "../Pagination/usePagination";
import Pagination from "../Pagination/Pagination";

function TravelAgencyTable({ data, updateView, deleteView }) {
  const { currentItems, currentPage, handlePageChange, totalPages } =
    usePagination(data, 10);
  return (
    <div>
      <div className="flex flex-col h-full">
        <div className="w-full mx-auto bg-white  rounded-sm border border-gray-200">
          <header className="flex sm:flex-row flex-col  justify-between px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold text-gray-800">Travel</h2>
            <Pagination
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              totalPages={totalPages}
            />
          </header>
          <div className="p-3">
            <div className="overflow-x-auto">
              <table className="table-auto w-full">
                <thead className="text-xs font-semibold uppercase text-gray-600 bg-[#eceff1]">
                  <tr>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Vendor name</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Vehicle name
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Vehicle type
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Vehicle number plate
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Driver name</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Driver number
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Rate</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody className="text-sm divide-y divide-gray-100">
                  {currentItems.map((vehicle, index) => {
                    return (
                      <tr key={index}>
                        <td className="p-2 whitespace-nowrap">
                          {vehicle.vendor.name}
                        </td>
                        <td className="p-2">
                          <div className="text-left">{vehicle.name}</div>
                        </td>
                        <td className="p-2">
                          <div className="text-left">
                            {vehicle.vehicle_type}
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="text-left">
                            {vehicle.number_plate}
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="text-left">{vehicle.driver_name}</div>
                        </td>
                        <td className="p-2">
                          <div className="text-left">
                            {vehicle.driver_number}
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="text-left">₹{vehicle.rate}</div>
                        </td>
                        <td className="p-2 whitespace-nowrap flex">
                          <div className="text-center">
                            <button
                              onClick={() => updateView(vehicle)}
                              className="p-4 text-xl text-green-600 border-none hover:shadow-none rounded-full outline-none bg-green-50  duration-300 ease-in"
                            >
                              <MdModeEdit />
                            </button>
                            <button
                              onClick={() => deleteView(vehicle)}
                              className="ml-4 p-4 text-xl text-red-600 bg-red-50 hover:shadow-none rounded-full  duration-300 ease-in outline-none"
                            >
                              <BiSolidTrashAlt />
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TravelAgencyTable;

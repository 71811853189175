import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import FormikSelect from "../Input/FormikSelect";
import { accomodationType, meals, roomType } from "../Data";
import FormikInput from "../Input/FormikInput";
import { City, Country, State } from "country-state-city";
import IconButton from "../Button/IconButton";
import { FaPlus, FaTrash } from "react-icons/fa";
import Select from "react-select";
import Button from "../Button/Button";
import { MdDelete } from "react-icons/md";

function AccommodationUpateForm({ onClose, onSubmit, data }) {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const fetchCountries = () => {
    const countries = Country.getAllCountries();
    const simplifiedCountries = countries.flat(Infinity);
    setCountries(simplifiedCountries);
  };

  const handleChangeCountry = (value, setFieldValue) => {
    const selectedCountry = countries.find((data) => data.name === value);
    setFieldValue("country", value);
    fetchStates(selectedCountry.isoCode);
  };
  const fetchStates = (code) => {
    const states = State.getStatesOfCountry(code);
    setStates(states);
  };
  const handleStateChange = (value, setFieldValue) => {
    const selectedState = states.find((data) => data.name === value);
    setFieldValue("state", value);
    fetchCities(selectedState);
  };
  const fetchCities = (state) => {
    const cities = City.getCitiesOfState(state.countryCode, state.isoCode);
    setCities(cities);
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  const newRoomArray = data.rooms.map((data) => ({
    ...data,
    is_old: true,
  }));

  const newFlatArray = data.rooms.map((data) => ({
    ...data,
    room_number: data.room_number,
    is_old: true,
  }));

  return (
    <div className="fixed inset-0 flex items-center justify-center overflow-auto z-50 bg-black bg-opacity-50 ">
      <div className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto ">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-4xl w-full mx-auto ">
          <div className="flex items-end justify-end">
            <button
              className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full "
              onClick={onClose}
            >
              <RxCross1 />
            </button>
          </div>
          <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
            {data.name}
          </h2>
          <Formik
            initialValues={{
              accommodation_type: data.accommodation_type || "",
              name: data.name || "",
              address: data.address || "",
              country: data.country || "",
              state: data.state || "",
              city: data.city || "",
              location_link: data.location_link || "",
              rent: data.rent_amount || "",
              rooms: newRoomArray,
              flats: newFlatArray,
            }}
            onSubmit={onSubmit}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                  <div>
                    <FormikInput
                      name={"name"}
                      label={"Accommodation name"}
                      type={"text"}
                      placeholder={"La Grande"}
                    />
                  </div>
                  <div>
                    <FormikInput
                      name={"address"}
                      type={"text"}
                      label={"Address"}
                      placeholder={"36th Street"}
                    />
                  </div>
                  <div>
                    <FormikSelect
                      name={"country"}
                      optionData={countries}
                      selectedOption={values.country}
                      label={"Country"}
                      labelProperty={"name"}
                      valueProperty={"name"}
                      onChange={(e) =>
                        handleChangeCountry(e.target.value, setFieldValue)
                      }
                    />
                  </div>
                  <div>
                    <FormikSelect
                      name={"state"}
                      optionData={states}
                      selectedOption={values.state}
                      label={"State"}
                      labelProperty={"name"}
                      valueProperty={"name"}
                      onChange={(e) =>
                        handleStateChange(e.target.value, setFieldValue)
                      }
                    />
                  </div>
                  <div>
                    <FormikSelect
                      name={"city"}
                      optionData={cities}
                      selectedOption={values.city}
                      label={"City"}
                      labelProperty={"name"}
                      valueProperty={"name"}
                      onChange={(e) => setFieldValue("city", e.target.value)}
                    />
                  </div>
                  <div>
                    <FormikInput
                      name={"location_link"}
                      placeholder={"Google map link"}
                      label={"Location Link"}
                      type={"text"}
                    />
                  </div>
                </div>
                {(values.accommodation_type === "Bungalow" ||
                  values.accommodation_type === "Farm House") && (
                  <div className="sm:mt-4 mt-2">
                    <FormikInput
                      name={"rent"}
                      label={"Rent"}
                      placeholder={"12***"}
                      type={"number"}
                      min={0}
                    />
                  </div>
                )}
                {values.accommodation_type === "Flat" && (
                  <FieldArray name="flats">
                    {({ push, remove }) => (
                      <div>
                        <div className="flex items-start justify-start flex-row mt-4">
                          <h2 className="text-[#1d5b79] mt-1 font-semibold text-lg">
                            Add Flat :
                          </h2>
                          <div className="ml-4">
                            <IconButton
                              icon={<FaPlus />}
                              name={"Add"}
                              type={"button"}
                              clickFunction={() =>
                                push({
                                  bhk: "",
                                  occupancy: "",
                                  room_number: "",
                                  rate: "",
                                })
                              }
                            />
                          </div>
                        </div>
                        {values.flats.map((_, index) => (
                          <React.Fragment>
                            <div className="relative">
                              <div className="flex justify-between items-center mt-5">
                                <h2 className="text-base sm:text-xl font-semibold mb-2 sm:mb-4 mt-2 sm:mt-4 text-[#020002]">
                                  Flat {index + 1}
                                </h2>

                                {values.flats[index].is_old ? (
                                  <></>
                                ) : (
                                  <button
                                    type="button"
                                    onClick={() => remove(index)}
                                    className="bg-[#e70a18] p-2 text-lg text-[#fbffff] rounded-full mt-2"
                                  >
                                    <MdDelete />
                                  </button>
                                )}
                              </div>
                              <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 sm:gap-4 mt-2 sm:mt-4">
                                <div>
                                  <FormikInput
                                    name={`flats.${index}.bhk`}
                                    placeholder={"BHK"}
                                    label={"BHK"}
                                    type={"number"}
                                    min={1}
                                  />
                                </div>
                                <div>
                                  <FormikInput
                                    name={`flats.${index}.occupancy`}
                                    placeholder={"5"}
                                    label={"Occupancy"}
                                    type={"number"}
                                    min={1}
                                  />
                                </div>
                                <div>
                                  <FormikInput
                                    name={`flats.${index}.room_number`}
                                    placeholder={"1401"}
                                    label={"Flat Number"}
                                    min={1}
                                    type={"text"}
                                  />
                                </div>
                                <div>
                                  <FormikInput
                                    name={`flats.${index}.rate`}
                                    placeholder={"140**"}
                                    label={"Rent"}
                                    min={1}
                                    type={"number"}
                                  />
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        ))}
                      </div>
                    )}
                  </FieldArray>
                )}
                {(values.accommodation_type === "Hotel" ||
                  values.accommodation_type === "Resort") && (
                  <FieldArray name="rooms">
                    {({ push, remove }) => (
                      <div>
                        <div className="flex items-start justify-start flex-row mt-4">
                          <h2 className="text-[#1d5b79] mt-1 font-semibold text-lg">
                            Add Room :
                          </h2>
                          <div className="ml-4">
                            <IconButton
                              icon={<FaPlus />}
                              name={"Add"}
                              type={"button"}
                              clickFunction={() =>
                                push({
                                  room_type: "",
                                  meals: [],
                                  room_number: "",
                                  occupancy: "",
                                  rate: "",
                                })
                              }
                            />
                          </div>
                        </div>
                        {values.rooms.map((_, index) => (
                          <div className="relative">
                            <div className="flex justify-between items-center mt-5">
                              <h2 className="text-base sm:text-xl font-semibold mb-2 sm:mb-4 mt-2 sm:mt-4 text-[#020002]">
                                Room {index + 1}
                              </h2>

                              {values.rooms[index].is_old ? (
                                <></>
                              ) : (
                                <button
                                  type="button"
                                  onClick={() => remove(index)}
                                  className="bg-[#e70a18] p-2 text-lg text-[#fbffff] rounded-full mt-2"
                                >
                                  <MdDelete />
                                </button>
                              )}
                            </div>
                            <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 sm:gap-4 mt-2 sm:mt-4">
                              <div>
                                <FormikSelect
                                  name={`rooms.${index}.room_type`}
                                  label={"Room type"}
                                  selectedOption={"Room type"}
                                  optionData={roomType}
                                  labelProperty={"type"}
                                  valueProperty={"type"}
                                  onChange={(e) =>
                                    setFieldValue(
                                      `rooms.${index}.room_type`,
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                              <div>
                                <label className="block mb-2 text-sm text-gray-600">
                                  Meals
                                </label>
                                <Select
                                  name={`rooms.${index}.meals`}
                                  options={meals}
                                  isMulti
                                  defaultValue={values.rooms[index]?.meals?.map(
                                    (type) => ({
                                      type: type,
                                    })
                                  )}
                                  getOptionLabel={(option) => option.type}
                                  getOptionValue={(option) => option.type}
                                  onChange={(selectedOptions) =>
                                    setFieldValue(
                                      `rooms.${index}.meals`,
                                      selectedOptions.map(
                                        (option) => option.type
                                      )
                                    )
                                  }
                                />
                              </div>
                              <div>
                                <FormikInput
                                  name={`rooms.${index}.room_number`}
                                  label={"Room number"}
                                  placeholder={"1402"}
                                  type={"number"}
                                  min={1}
                                />
                              </div>
                              <div>
                                <FormikInput
                                  name={`rooms.${index}.occupancy`}
                                  label={"Occupancy"}
                                  placeholder={"2"}
                                  type={"number"}
                                  min={1}
                                />
                              </div>
                              <div>
                                <FormikInput
                                  name={`rooms.${index}.rate`}
                                  label={"Rate"}
                                  placeholder={"12***"}
                                  type={"number"}
                                  min={1}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </FieldArray>
                )}
                <div className="sm:mt-4 mt-2">
                  <Button type={"submit"} name={"Update"} />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default AccommodationUpateForm;

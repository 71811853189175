import React, { useState } from "react";
import { BiSolidTrashAlt } from "react-icons/bi";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";
import { AnimatePresence, motion } from "framer-motion";
import usePagination from "../Pagination/usePagination";
import Pagination from "../Pagination/Pagination";
function SettingsVendorTable({
  vendor,
  handleUpdateVendorView,
  handleDeleteVendorView,
}) {
  const [expandedVendor, setExpandedVendor] = useState({});

  const itemVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
  };

  const toggleView = (id) => {
    setExpandedVendor((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const { currentItems, currentPage, handlePageChange, totalPages } =
    usePagination(vendor, 10);

  return (
    <div className="flex flex-col h-full">
      <div className="w-full mx-auto bg-white  rounded-sm border border-gray-200">
        <header className="flex sm:flex-row flex-col  justify-between px-5 py-4 border-b border-gray-100">
          <h2 className="font-semibold text-gray-800">Vendor</h2>
          <Pagination
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            totalPages={totalPages}
          />
        </header>
        <div className="p-3">
          <div className="overflow-x-auto">
            <table className="table-auto w-full">
              <thead className="text-xs font-semibold uppercase text-gray-600 bg-[#eceff1]">
                <tr>
                  <th className="px-4 py-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Vendor name</div>
                  </th>
                  <th className="px-4 py-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Vendor type</div>
                  </th>
                  <th className="px-4 py-2 whitespace-nowrap">
                    <div className="font-semibold text-left">
                      Vendor Contact number
                    </div>
                  </th>
                  <th className="px-4 py-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Vendor email</div>
                  </th>
                  <th className="px-4 py-2 whitespace-nowrap">
                    <div className="font-semibold text-left">More details</div>
                  </th>
                  <th className="px-4 py-2 whitespace-nowrap">
                    <div className="font-semibold text-center">Action</div>
                  </th>
                </tr>
              </thead>
              <tbody className="text-sm divide-y divide-gray-100">
                {currentItems.map((vendor, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td className="p-2 whitespace-nowrap">{vendor.name}</td>
                      <td className="p-2">
                        <div className="text-left">{vendor.vendor_type}</div>
                      </td>
                      <td className="p-2">
                        <div className="text-left">{vendor.phone_number}</div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">{vendor.email}</div>
                      </td>
                      <td className="p-2">
                        <button
                          onClick={() => toggleView(vendor.id)}
                          className="text-xl border-none outline-none text-[#fbffff] bg-[#1d5b79] rounded-full p-1"
                        >
                          {expandedVendor[vendor.id] ? (
                            <FaChevronUp size={15} />
                          ) : (
                            <FaChevronDown size={15} />
                          )}
                        </button>
                      </td>
                      <td className="p-4 whitespace-nowrap flex">
                        <button
                          onClick={() => handleUpdateVendorView(vendor.id)}
                          className="p-4 text-xl text-green-600 border-none hover:shadow-none rounded-full outline-none bg-green-50  duration-300 ease-in"
                        >
                          <MdModeEdit />
                        </button>
                        <button
                          onClick={() => handleDeleteVendorView(vendor.id)}
                          className="ml-4 p-4 text-xl text-red-600 bg-red-50 hover:shadow-none rounded-full  duration-300 ease-in outline-none"
                        >
                          <BiSolidTrashAlt />
                        </button>
                      </td>
                    </tr>
                    <AnimatePresence>
                      {expandedVendor[vendor.id] && (
                        <motion.tr
                          key={vendor.id}
                          initial="hidden"
                          animate="visible"
                          exit="exit"
                          variants={itemVariants}
                          className="bg-gray-50"
                          transition={{ duration: 0.3, delay: 0.1 }}
                        >
                          <td colSpan={6} className="p-4">
                            <div className="grid sm:grid-cols-3 grid-cols-1 gap-2 sm:gap-4">
                              <div className="flex flex-row">
                                <h2 className="text-sm font-semibold text-[#1d5b79]">
                                  Address:
                                </h2>
                                <h2 className="text-sm ml-1">
                                  {vendor.address}
                                </h2>
                              </div>
                              <div className="flex flex-row">
                                <h2 className="text-sm font-semibold text-[#1d5b79]">
                                  PAN number:
                                </h2>
                                <h2 className="text-sm ml-1">
                                  {vendor.pan_card_number}
                                </h2>
                              </div>
                              <div className="flex flex-row">
                                <h2 className="text-sm font-semibold text-[#1d5b79]">
                                  Bank a/c number:
                                </h2>
                                <h2 className="text-sm ml-1">
                                  {vendor.bank_account_number}
                                </h2>
                              </div>
                              <div className="flex flex-row">
                                <h2 className="text-sm font-semibold text-[#1d5b79]">
                                  Bank name:
                                </h2>
                                <h2 className="text-sm ml-1">
                                  {vendor.bank_name}
                                </h2>
                              </div>
                              <div className="flex flex-row">
                                <h2 className="text-sm font-semibold text-[#1d5b79]">
                                  Bank branch:
                                </h2>
                                <h2 className="text-sm ml-1">
                                  {vendor.bank_branch}
                                </h2>
                              </div>
                              <div className="flex flex-row">
                                <h2 className="text-sm font-semibold text-[#1d5b79]">
                                  IFSC code:
                                </h2>
                                <h2 className="text-sm ml-1">
                                  {vendor.ifsc_code}
                                </h2>
                              </div>
                              {vendor.gst_number && (
                                <div className="flex flex-row">
                                  <h2 className="text-sm font-semibold text-[#1d5b79]">
                                    GST:
                                  </h2>
                                  <h2 className="text-sm ml-1">
                                    {vendor.gst_number}
                                  </h2>
                                </div>
                              )}
                              <div className="flex flex-row">
                                <h2 className="text-sm font-semibold text-[#1d5b79]">
                                  GST(%):
                                </h2>
                                <h2 className="text-sm ml-1">
                                  {vendor.gst_percentage}
                                </h2>
                              </div>
                              <div className="flex flex-row">
                                <h2 className="text-sm font-semibold text-[#1d5b79]">
                                  TDS(%):
                                </h2>
                                <h2 className="text-sm ml-1">
                                  {vendor.tds_percentage}
                                </h2>
                              </div>
                            </div>
                          </td>
                        </motion.tr>
                      )}
                    </AnimatePresence>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SettingsVendorTable;

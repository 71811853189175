import React, { useRef } from "react";
import moment from "moment-timezone";
import "moment/locale/en-in";
import { ToWords } from "to-words";
import { BsDownload } from "react-icons/bs";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { uid } from "uid";

function Voucher({ installment, onClose, name, user }) {
  const voucherRef = useRef();
  const UID = uid();
  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });

  const DownloadVoucher = async () => {
    html2canvas(voucherRef.current, { scale: 2 }).then((canvas) => {
      const pdf = new jsPDF("portrait", "mm", [128.59, 139.96]);
      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", 0, 0, 128.59, 139.96);
      pdf.save(`voucher_${name}_${installment.i_name}_${UID}.pdf`);
    });
  };

  const convertToIST = (utcDateStr) => {
    return moment.utc(utcDateStr).tz("Asia/Kolkata").format("DD-MM-YYYY");
  };
  return (
    <div>
      <div
        onClick={onClose}
        className="fixed inset-0 flex items-center justify-center z-50 overflow-hidden no-scrollbar bg-black bg-opacity-50"
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="flex flex-col items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto "
        >
          <div className="flex flex-row mb-4">
            <div>
              <button
                onClick={DownloadVoucher}
                className=" bg-[#1d5b79]  text-white px-3 py-2 shadow-2xl rounded-full hover:bg-[#a9c8e2] ease-in-out duration-300 hover:shadow-none flex items-center"
              >
                <span className="text-xl">
                  <BsDownload />
                </span>
                <span className="font-semibold ml-2">Download Voucher</span>
              </button>
            </div>
            <div>
              
            </div>
          </div>
          <div
            ref={voucherRef}
            className="bg-white p-4 sm:p-8 shadow-md max-w-5xl w-full sm:my-auto  my-auto"
          >
            <div className="border border-gray-900 ">
              <div className="flex flex-col items-center justify-center mx-5 p-2">
                <h2 className="text-xl font-semibold text-[#1d5b79] w-full text-center">
                  {user.company_name}
                </h2>
                <h3 className="text-sm w-full text-center text-gray-700">
                  {user.address}
                </h3>
                <h3 className="text-sm w-full text-center text-gray-700">
                  {user.phone_number}
                </h3>
              </div>
              <div className="text-center w-full">
                <h2 className="text-[#1d5b79] text-lg font-semibold">
                  VOUCHER
                </h2>
              </div>
              <div className="border-t mt-4 border-gray-900">
                <div className="flex flex-col items-end justify-end mx-3">
                  {installment.voucher_serial_number && (
                    <h2 className="text-sm font-semibold">
                      Voucher no: {installment.voucher_serial_number}
                    </h2>
                  )}
                  <h2 className="text-sm font-semibold mt-1">
                    Date: {convertToIST(installment.created_at)}
                  </h2>
                </div>
                <div className="flex items-start justify-start mx-3 mt-2">
                  <h2 className="text-sm font-semibold">
                    Amount: ₹{installment.amount}
                  </h2>
                </div>
                <div className="mt-2 flex flex-col items-start justify-start mx-3">
                  <div className="flex flex-row">
                    <h2 className="text-semibold text-sm">Payment method:</h2>
                    <h2 className="ml-1 text-semiblod text-gray-900 text-sm font-bold">
                      {installment.payment_mode}
                    </h2>
                  </div>
                </div>
                {(installment.payment_mode === "Cheque" ||
                  installment.payment_mode === "Net banking") && (
                  <>
                    <div className="mt-2 flex flex-col items-start justify-start mx-3">
                      <div className="flex flex-row">
                        {installment.payment_mode === "Cheque" ? (
                          <h2 className="text-semibold text-sm">
                            Cheque number:
                          </h2>
                        ) : (
                          <h2 className="text-semibold text-sm">
                            Transaction number:
                          </h2>
                        )}
                        <h2 className="ml-1 text-semiblod text-gray-900 text-sm font-bold">
                          {installment.payment_reference}
                        </h2>
                      </div>
                    </div>
                    {installment.transaction_date && (
                      <div className="mt-2 flex flex-col items-start justify-start mx-3">
                        <div className="flex flex-row">
                          {installment.payment_mode === "Cheque" ? (
                            <h2 className="text-semibold text-sm">
                              Cheque Issue date:
                            </h2>
                          ) : (
                            <h2 className="text-semibold text-sm">
                              Transaction Date:
                            </h2>
                          )}
                          <h2 className="ml-1 text-semiblod text-gray-900 text-sm font-bold">
                            {convertToIST(installment.transaction_date)}
                          </h2>
                        </div>
                      </div>
                    )}
                  </>
                )}
                <div className="mt-2 flex flex-col items-start justify-start mx-3">
                  <div className="flex flex-row">
                    <h2 className="text-semibold text-sm">Amount paid to:</h2>
                    <h2 className="ml-1 text-semiblod text-gray-900 text-sm font-bold">
                      {name}
                    </h2>
                  </div>
                </div>
                <div className="mt-2 flex flex-col items-start justify-start mx-3">
                  <div className="flex flex-row">
                    <h2 className="text-semibold text-sm">Amount in words:</h2>
                    <h2 className="ml-1 text-semiblod text-gray-900 text-sm font-bold">
                      {toWords.convert(installment.amount)}
                    </h2>
                  </div>
                </div>
                <div className="mt-14 mb-5 flex items-center justify-between mx-3">
                  <div>
                    <h2 className="border-t border-gray-800">Authorized by</h2>
                  </div>
                  <div>
                    <h2 className="border-t border-gray-800">Received by</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Voucher;

import React, { useContext, useState } from "react";
import { MdModeEdit } from "react-icons/md";
import SecureLS from "secure-ls";
import PaymentModal from "../../Modal/PaymentModal";
import toast from "react-hot-toast";
import axios from "axios";
import InstallmentDetails from "../../Modal/InstallmentDetails";
import { HiddenContext } from "../../Context/HiddenContent";
import usePagination from "../Pagination/usePagination";
import Pagination from "../Pagination/Pagination";

function LocationAccountTable({ data, fetchData }) {
  const [selectedLocation, setSelectedLocation] = useState("");
  const [open, setOpen] = useState(false);
  const [view, setView] = useState(false);
  const ls = new SecureLS();
  const user_id = ls.get("User id");
  const { hidden } = useContext(HiddenContext);

  const openPaymentModal = (location) => {
    setView(true);
    setSelectedLocation({ ...location });
  };

  const paymentHandler = (value, { resetForm }) => {
    const loader = toast.loading("Adding Installment...", { autoClose: false });
    const amount = parseInt(value.amount) + parseInt(value.gst);

    const formData = new FormData();

    formData.append("installment[location_id]", selectedLocation.id);
    formData.append("installment[i_name]", value.installment_name);
    formData.append("installment[amount]", amount);
    formData.append("installment[payment_mode]", value.payment_mode);
    formData.append("installment[payment_reference]", value.payment_reference);
    formData.append("installment[tds_amount]", value.tds);
    formData.append("installment[gst_amount]", value.gst);
    formData.append("installment[user_id]", user_id);
    formData.append("installment[transaction_date]", value.transaction_date);

    [...value.bill].forEach((bill, index) => {
      formData.append(`installment[bills][]`, bill);
    });

    axios
      .post(`api/v1/installments`, formData)
      .then((response) => {
        setView(false);
        toast.dismiss(loader);
        toast.success(response.data.message);
        fetchData();
        resetForm();
      })
      .catch((error) => {
        toast.dismiss(loader);
        toast.error(error.message);
      });
  };

  const { currentItems, currentPage, handlePageChange, totalPages } =
    usePagination(data, 10);
    
  const handleOpenInstallmentModal = (installment) => {
    setSelectedLocation({ ...installment });
    setOpen(true);
  };

  return (
    <div>
      <div className="flex flex-col h-full">
        <div className="w-full mx-auto bg-white  rounded-sm border border-gray-200">
          <header className="flex sm:flex-row flex-col  justify-between px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold text-gray-800">Location account</h2>
            <Pagination
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              totalPages={totalPages}
            />
          </header>
          <div className="p-3">
            <div className="overflow-x-auto">
              <table className="table-auto w-full">
                <thead className="text-xs font-semibold uppercase text-gray-600 bg-[#eceff1]">
                  <tr>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Location name
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Location owner name
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Amount</div>
                    </th>

                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-center">
                        Installment Detail
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-center">TDS Due</div>
                    </th>

                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-center">GST Due</div>
                    </th>

                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-center">
                        Paid amount
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-center">
                        Amount due
                      </div>
                    </th>
                    {hidden ? (
                      <></>
                    ) : (
                      <th className="px-4 py-2 whitespace-nowrap">
                        <div className="font-semibold text-center">Action</div>
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody className="text-sm divide-y divide-gray-100">
                  {currentItems.map((data) => {
                    return (
                      <tr>
                        <td className="p-2 whitespace-nowrap">{data.name}</td>
                        <td className="p-2 whitespace-nowrap">
                          {data.owner_name}
                        </td>
                        <td className="p-2">
                          <div className="text-left">₹ {data.total_amount}</div>
                        </td>
                        <td className="p-2 whitespace-nowrap">
                          <div className="text-center">
                            <button
                              onClick={() => handleOpenInstallmentModal(data)}
                              className="font-semibold hover:underline"
                            >
                              View
                            </button>
                          </div>
                        </td>
                        <td className="p-2 whitespace-nowrap">
                          <div className="text-center">₹{data.tds_due}</div>
                        </td>
                        <td className="p-2 whitespace-nowrap">
                          <div className="text-center">₹{data.gst_due}</div>
                        </td>
                        <td className="p-2 whitespace-nowrap">
                          <div className="text-center">₹{data.amount_paid}</div>
                        </td>
                        <td className="p-2 whitespace-nowrap">
                          <div className="text-center">₹{data.amount_due}</div>
                        </td>
                        {hidden ? (
                          <></>
                        ) : (
                          <td className="p-2 whitespace-nowrap">
                            <button
                              onClick={() => openPaymentModal(data)}
                              className={`${
                                parseInt(data.amount_paid) ===
                                parseInt(data.calculated_amount)
                                  ? "cursor-not-allowed"
                                  : "cursor-pointer"
                              } p-4 text-xl text-green-600 border-none hover:shadow-none rounded-full outline-none bg-green-50  duration-300 ease-in`}
                              disabled={
                                parseInt(data.amount_paid) ===
                                parseInt(data.calculated_amount)
                              }
                            >
                              <MdModeEdit />
                            </button>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {view && (
        <PaymentModal
          location={true}
          data={selectedLocation}
          name={selectedLocation.owner_name}
          closePaymentModal={() => setView(false)}
          paymentHandler={paymentHandler}
          stopClose={(e) => e.stopPropagation()}
        />
      )}
      {open && (
        <InstallmentDetails
          setOpen={setOpen}
          data={selectedLocation}
          name={selectedLocation.owner_name}
        />
      )}
    </div>
  );
}

export default LocationAccountTable;

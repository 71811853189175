import { Form, Formik } from "formik";
import React from "react";
import { RxCross1 } from "react-icons/rx";
import FormikInput from "../Input/FormikInput";
import Button from "../Button/Button";
import * as Yup from "yup";
import FormikSelect from "../Input/FormikSelect";
import { vendorType } from "../Data";

function VendorForm({ setOpen, addVendor }) {
  const validationSchema = Yup.object().shape({
    vendor_name: Yup.string().required("Vendor name is required"),
    vendor_type: Yup.string().required("Vendor type is required"),
    vendor_phone_number: Yup.string()
      .matches(/^[0-9]+$/, "Phone number must contain only digits")
      .required("Vendor contact number is required"),
    vendor_email: Yup.string()
      .email("Invalid email format")
      .required("Vendor email is required"),
    vendor_pan_number: Yup.string()
      .matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, "Invalid PAN number")
      .required("Vendor PAN number is required"),
    vendor_ifsc: Yup.string()
      .matches(
        /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/,
        "Invalid IFSC code. Example format: SBIN0123456"
      )
      .required("Vendor IFSC is required"),
    vendor_gst_number: Yup.string()
      .matches(
        /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Z]{1}[0-9A-Za-z]{1}$/,
        "Invalid GST number"
      )
      .required("GST number is required"),
    vendor_gst_per: Yup.number().required("GST percentage is required"),
    address: Yup.string().required("Please fill up address"),
    vendor_tds: Yup.number().required("Vendor TDS percentage is required"),
    bank_account_number: Yup.string()
      .required("Bank account number is required")
      .matches(/^\d+$/, "Bank account number must contain only digits")
      .min(9, "Bank account number is too short")
      .max(18, "Bank account number is too long"),
    bank_name: Yup.string().required("Bank name is required"),
  });

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 overflow-auto bg-black bg-opacity-50 ">
      <div className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto ">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-5xl w-full mx-auto ">
          <div className="flex items-end justify-end">
            <button
              onClick={() => setOpen(false)}
              className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full "
            >
              <RxCross1 />
            </button>
          </div>
          <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
            VENDOR
          </h2>
          <div>
            <Formik
              initialValues={{
                vendor_name: "",
                vendor_type: "",
                vendor_phone_number: "",
                vendor_email: "",
                vendor_ifsc: "",
                vendor_pan_number: "",
                vendor_gst_number: "",
                vendor_gst_per: "",
                vendor_tds: "",
                address: "",
                bank_account_number: "",
                bank_name: "",
                bank_branch: "",
              }}
              validationSchema={validationSchema}
              onSubmit={addVendor}
            >
              {({ setFieldValue }) => (
                <Form>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                    <div>
                      <FormikInput
                        name={"vendor_name"}
                        label={"Vendor name"}
                        placeholder={"Aaryansh Motors"}
                        type={"text"}
                      />
                    </div>
                    <div>
                      <FormikSelect
                        name={"vendor_type"}
                        label={"Vendor type"}
                        selectedOption={"Vendor type"}
                        optionData={vendorType}
                        onChange={(e) =>
                          setFieldValue("vendor_type", e.target.value)
                        }
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"vendor_phone_number"}
                        label={"Vendor contact number"}
                        placeholder={"9998887775"}
                        type={"tel"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"vendor_email"}
                        label={"Vendor email"}
                        placeholder={"aaryansh@example.com"}
                        type={"email"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"address"}
                        label={"Vendor Address"}
                        placeholder={
                          "Sardar Sarovar Dam, Statue of Unity Rd, Kevadia, Gujarat 393155"
                        }
                        type={"text"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"bank_account_number"}
                        label={"Bank account no."}
                        min={0}
                        placeholder={"0112345678"}
                        type={"number"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"bank_name"}
                        label={"Bank name"}
                        placeholder={"MoneyZone"}
                        type={"text"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"bank_branch"}
                        label={"Bank branch"}
                        placeholder={"Ahmedabad"}
                        type={"text"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"vendor_ifsc"}
                        label={"Vendor IFSC"}
                        placeholder={"SBIN0011513"}
                        type={"text"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"vendor_pan_number"}
                        label={"Vendor PAN number"}
                        placeholder={"ABCTY1234D"}
                        type={"text"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"vendor_gst_number"}
                        label={"GST number"}
                        placeholder={"22AAAAA0000A1Z5"}
                        type={"text"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"vendor_gst_per"}
                        label={"GST (%)"}
                        placeholder={"18"}
                        min={0}
                        type={"number"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"vendor_tds"}
                        label={"Vendor TDS(%)"}
                        placeholder={"5"}
                        min={0}
                        type={"number"}
                      />
                    </div>
                  </div>
                  <div className="mt-2 sm:mt-4">
                    <Button name={"Add"} type={"submit"} />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VendorForm;

import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import logo from "../assets/image/Cinemapro (3).png";
import { useNavigate } from "react-router-dom";
import { IoIosLogOut } from "react-icons/io";
import toast from "react-hot-toast";
import axios from "axios";
import { FaGear } from "react-icons/fa6";
const generateVibrantColor = () => {
  // Generate random RGB values within the range [0, 255]
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);

  return `rgb(${r},${g},${b})`;
};

function TopNavbar({ user, setView }) {
  const [Visible, setVisible] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const navigate = useNavigate();

  const logOutHandler = () => {
    toast.success("Logged out! Come again soon.");
    localStorage.clear();
    axios
      .delete("api/v1/logout", {})
      .then((response) => {
        console.log(response);
      })
      .catch((error) => console.log(error));
    navigate("/Login");
  };

  const toggleVisible = () => {
    setVisible(!Visible);
  };
  const userDetail = localStorage.getItem("User_Details");
  const user_details = JSON.parse(userDetail);

  const fetchUser = () => {
    const canvas = document.createElement("canvas");
    canvas.width = 200;
    canvas.height = 200;
    const ctx = canvas.getContext("2d");

    const bgColor = generateVibrantColor();
    ctx.fillStyle = bgColor;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    if (user_details.company_name) {
      const title = user_details.company_name;
      const abbreviatedTitle = title.substring(0, 1).toUpperCase();

      ctx.font = "80px Arial";
      ctx.fillStyle = "white";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";

      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;

      ctx.fillText(abbreviatedTitle, centerX, centerY);

      const imageSrc = canvas.toDataURL("image/png");
      setImageUrl(imageSrc);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <div>
      <div>
        <nav
          className={`bg-[#fbffff] shadow-lg p-2 flex justify-between items-center top-0 left-0 right-0 z-50 h-20 fixed w-full transition-all duration-300 `}
        >
          <img
            src={logo}
            alt="logo"
            className="h-14 w-14 object-fill rounded-full cursor-pointer"
          />

          <div className="text-[#1d5b79] text-xl relative">
            <div
              onClick={toggleVisible}
              className="px-3 py-3 text-[#1d5b79] text-md font-semibold flex flex-row cursor-pointer hover:bg-[#020002] hover:bg-opacity-10 hover:rounded-3xl duration-500"
            >
              {imageUrl && (
                <img
                  src={user.logo ? user.logo : imageUrl}
                  alt="productionhouse"
                  className="h-11 w-11 rounded-full mr-4"
                />
              )}
              <h2 className="mt-1">{user_details.company_name}</h2>
            </div>
            <AnimatePresence>
              {Visible && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  transition={{ duration: 0.3 }}
                  className="absolute top-12 right-0 bg-[#fbffff] p-2 rounded-lg shadow-md h-auto  w-72 mt-8"
                >
                  <div className="container mx-auto ">
                    <div className="flex flex-wrap items-center ">
                      {imageUrl && (
                        <img
                          src={user.logo ? user.logo : imageUrl}
                          alt="productionhouse"
                          className="h-11 w-11 rounded-full mr-4"
                        />
                      )}
                      <div>
                        <h2 className="mt-1 text-black font-semibold">
                          {user_details.company_name}
                        </h2>
                        <h3 className="text-gray-400 font-medium text-sm">
                          By {user_details.name}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5">
                    <div className="bg-[#1d5b79] h-20 w-full rounded-lg shadow-md p-2">
                      <div className="flex justify-between items-center">
                        <h2 className="text-[#fbffff] font-base  ">
                          Active Package
                        </h2>
                        {user && (
                          <h2 className="text-[#fbffff] font-base  ">
                            {user.package ? user.package.name : "unknown"}
                          </h2>
                        )}
                      </div>
                      <button
                        disabled={user && user.package.name === "Platinum"}
                        onClick={() => setView(true)}
                        className="w-full mt-1 bg-[#fbffff] h-8 rounded-lg hover:bg-[#a9c8e2] hover:text-[#fbffff] text-xl duration-300 ease-out outline-none"
                      >
                        Upgrade
                      </button>
                    </div>
                    <div className="flex flex-col items-center justify-center mt-5">
                      <button
                        onClick={() => navigate("/Profile")}
                        className="flex flex-row items-center justify-center py-2 w-full hover: hover:bg-gray-200 duration-150 ease-in border-b border-gray-400"
                      >
                        <span className="mr-2">
                          <FaGear />
                        </span>
                        Settings
                      </button>
                      <button
                        onClick={logOutHandler}
                        className="flex flex-row items-center justify-center py-2 w-full hover: hover:bg-gray-200 duration-150 ease-in "
                      >
                        <span className="mr-2">
                          <IoIosLogOut />
                        </span>
                        Log out
                      </button>
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default TopNavbar;

import { Form, Formik } from "formik";
import React from "react";
import { RxCross1 } from "react-icons/rx";
import FormikSelect from "../Input/FormikSelect";
import FormikInput from "../Input/FormikInput";
import Button from "../Button/Button";
import { amountTypeOption } from "../Data";

function ProjectVendorUpdateForm({
  setOpenUpdate,
  selectedvendor,
  updateProjectVendor,
}) {
  return (
    <div className="fixed inset-0 flex items-center justify-center overflow-auto bg-black bg-opacity-50 z-50">
      <div className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto ">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-xl w-full mx-auto">
          <div className="flex items-end justify-end">
            <button
              onClick={() => setOpenUpdate(false)}
              className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full "
            >
              <RxCross1 />
            </button>
          </div>
          <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
            {selectedvendor.vendor.name}
          </h2>
          <Formik
            initialValues={{
              amount_type: "" || selectedvendor.amount_type,
              amount: "" || selectedvendor.amount,
              number_of_days: "" || selectedvendor.number_of_days,
              quantity: "" || selectedvendor.quantity,
              nda_doc: "",
              agreement_doc: "",
            }}
            onSubmit={updateProjectVendor}
          >
            {({ setFieldValue }) => (
              <Form>
                <div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                    <div>
                      <FormikSelect
                        name={"amount_type"}
                        label={"Payment type"}
                        onChange={(e) =>
                          setFieldValue("amount_type", e.target.value)
                        }
                        selectedOption={"Payment type"}
                        optionData={amountTypeOption}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"amount"}
                        label={"Amount"}
                        placeholder={selectedvendor.amount}
                        type={"text"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"number_of_days"}
                        label={"Number of Days"}
                        placeholder={selectedvendor.number_of_days}
                        type={"text"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"quantity"}
                        label={"Quantity"}
                        placeholder={"12"}
                        type={"number"}
                      />
                    </div>
                    <div>
                      <label className="block mb-2 text-sm text-gray-600 ">
                        NDA
                      </label>
                      <input
                        name="nda_doc"
                        type="file"
                        accept=".pdf,image/*"
                        onChange={(event) => {
                          setFieldValue(
                            "nda_doc",
                            event.currentTarget.files[0]
                          );
                        }}
                        className="cursor-pointer block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#24b7aa]  focus:ring-[#24b7aa] focus:outline-none focus:ring focus:ring-opacity-40"
                      />
                    </div>
                    <div>
                      <label className="block mb-2 text-sm text-gray-600 ">
                        Agreement
                      </label>
                      <input
                        name="agreement_doc"
                        type="file"
                        accept=".pdf,image/*"
                        onChange={(event) => {
                          setFieldValue(
                            "agreement_doc",
                            event.currentTarget.files[0]
                          );
                        }}
                        className="cursor-pointer block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#24b7aa]  focus:ring-[#24b7aa] focus:outline-none focus:ring focus:ring-opacity-40"
                      />
                    </div>
                  </div>
                  <div className="mt-2 sm:mt-4">
                    <Button name={"Update"} type={"submit"} />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default ProjectVendorUpdateForm;

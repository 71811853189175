import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";
import * as Yup from "yup";
import logo from "../assets/image/Modak.png";
import Button from "../components/Button/Button";
import { Form, Formik } from "formik";
import FormikInput from "../components/Input/FormikInput";
import Password from "../components/Input/Password";
import SecureLS from "secure-ls";
import bcrypt from "bcryptjs";
import { RxCross2 } from "react-icons/rx";
import { AnimatePresence, motion } from "framer-motion";

function Login() {
  const [secure, setSecure] = useState(true);
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [error, setError] = useState("");
  const ls = new SecureLS();
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Please enter a valid email or username"),
    password: Yup.string()
      .required("Please enter a valid password")
      .transform((value) => value.trim()),
  });

  const fetchClientDetails = () => {
    axios
      .get("api/v1/users/send_client_id_secret")
      .then((response) => {
        setClientId(response.data.client_id);
        setClientSecret(response.data.client_secret);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchClientDetails();
  }, []);

  const handleSubmit = async (value, { setSubmitting, resetForm }) => {
    const toastLoader = toast.loading("Logging in....", { autoClose: false });
    // const salt = await bcrypt.genSalt(10);
    // const hashedPasword = await bcrypt.hash(value.password, salt);

    await axios
      .post(`api/v1/login`, {
        email: value.email,
        password: value.password,
        grant_type: "password",
        client_id: clientId,
        client_secret: clientSecret,
      })
      .then((response) => {
        toast.dismiss(toastLoader);
        toast.success(response.data.message);
        resetForm();
        const role = response.data.role;
        if (role === "artist") {
          ls.set("access_token", response.data.artist.access_token);
          ls.set("role", role);
          localStorage.setItem(
            "User_Details",
            JSON.stringify({
              name: response.data.artist.name,
              projectName: response.data.artist.project,
              projectType: response.data.artist.cinema_type,
            })
          );
          localStorage.setItem("project_id", response.data.artist.project_id);
          localStorage.setItem(
            "company_name",
            response.data.artist.company_name
          );
          const artistId = response.data.artist.artist_id;
          ls.set("isAuthenticated", true);
          navigate(`/actorDashboard/${response.data.artist.project_id}`);
        } else if (
          role === "user" &&
          response.data.user.super_admin === false
        ) {
          ls.set("access_token", response.data.user.access_token);
          ls.set("isAuthenticated", true);
          ls.set("role", role);
          ls.set("User id", response.data.user.user_id);
          localStorage.setItem(
            "User_Details",
            JSON.stringify({
              company_name: response.data.user.company_name,
              name: response.data.user.name,
            })
          );
          navigate("/project");
        } else if (role === "crew") {
          const subRole = response.data.crew.roles[0];
          if (subRole == "accountant") {
            ls.set("activeTab", 5);
          } else {
            ls.set("activeTab", 1);
          }
          ls.set("User id", response.data.crew.production_house_id);
          ls.set("access_token", response.data.crew.access_token);
          ls.set("sub_role", response.data.crew.roles[0]);
          ls.set("project_crew_id", response.data.crew.project_crew_id);
          localStorage.setItem(
            "Project_details",
            JSON.stringify({
              title: response.data.crew.project,
              cinema_type: response.data.crew.cinema_type,
            })
          );
          localStorage.setItem(
            "User_Details",
            JSON.stringify({
              company_name: response.data.crew.company_name,
              name: response.data.crew.name,
            })
          );
          ls.set("isAuthenticated", true);
          localStorage.setItem("project_id", response.data.crew.project_id);
          ls.set("role", role);
          localStorage.setItem(
            "Crew_detail",
            JSON.stringify({
              role: response.data.crew.crew_department,
              name: response.data.crew.name,
            })
          );
          const projectId = response.data.crew.project_id;
          navigate(`/projectoverview/${projectId}`);
        } else if (role === "user" && response.data.user.super_admin === true) {
          ls.set("access_token", response.data.user.access_token);
          ls.set("isAuthenticated", true);
          ls.set("role", "superAdmin");
          navigate("/Super-Admin");
        } else {
          navigate("/");
          toast.error("Something went wrong. Please try again");
        }
      })
      .catch((err) => {
        toast.dismiss(toastLoader);
        console.log(err);
        setError(
          err.response.data.error || err.message || err.response.data.message
        );
        toast.error(
          err.response.data.error || err.message || err.response.data.message
        );
      });
    setSubmitting(false);
  };

  useEffect(() => {
    const access_token = localStorage.getItem("access_token");
    const userRole = localStorage.getItem("role");
    const projectId = localStorage.getItem("project_id");
    if (access_token && userRole === "artist") {
      navigate(`/actorDashboard/${projectId}`);
    } else if (userRole === "user" && access_token) {
      navigate("/project");
    } else if (userRole === "crew" && access_token) {
      navigate(`/projectoverview/${projectId}`);
    } else if (userRole === "superAdmin") {
      navigate("/Super-Admin");
    } else {
      navigate("/Login");
    }
  }, []);

  return (
    <>
      <div className="bg-[#fbffff]  ">
        <div className="flex justify-center h-screen">
          <div
            className="hidden bg-cover lg:block lg:w-2/3"
            style={{
              backgroundImage: `url(https://images.unsplash.com/photo-1577190651915-bf62d54d5b36?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`,
            }}
          >
            <div className="flex items-center h-full px-20 bg-gray-900 bg-opacity-50">
              <div>
                <img
                  src={logo}
                  alt="logo"
                  className="h-40 w-40 rounded-full cursor-pointer"
                />

                <p className="max-w-xl mt-1 font-medium text-gray-100">
                  Welcome to the CinemaPro! Our film management platform is your
                  ticket to effortless movie organization. Dive into a world of
                  cinematic wonders where you can curate your personal film
                  collection with style and ease.
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/6">
            <div className="flex-1">
              <div className="text-center">
                <h2 className="text-4xl font-bold text-center tracking-widest text-[#1d5b79] ">
                  CinemaPro
                </h2>

                <p className="mt-3 text-[#1d5b79] ">
                  Log in to access your account
                </p>
              </div>

              <div className="mt-8">
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  <Form>
                    <div>
                      <FormikInput
                        label={"E-mail/Username"}
                        type={"text"}
                        name={"email"}
                        placeholder={"johnsmith@example.com"}
                      />
                    </div>

                    <div className="mt-6">
                      <Password
                        label={"Password"}
                        secure={secure}
                        setSecure={setSecure}
                        name={"password"}
                        placeholder={"password"}
                      />
                    </div>
                    <Link
                      to="/ResetPassword"
                      className="text-sm text-gray-400 focus:text-[#1d5b79] hover:text-[#1d5b79] hover:underline"
                    >
                      Reset password?
                    </Link>
                    <AnimatePresence>
                      {error && (
                        <motion.div
                          initial={{ opacity: 0, scale: 0.9 }}
                          animate={{ opacity: 1, scale: 1 }}
                          exit={{ opacity: 0, scale: 0.1 }}
                          transition={{ duration: 0.5 }}
                          className="w-full border-dashed border-red-500 bg-red-100 border rounded-2xl p-3 my-4"
                        >
                          <div className="flex items-start justify-between">
                            <h2 className="text-red-500 font-semibold">
                              Error
                            </h2>
                            <button
                              type="button"
                              onClick={() => setError("")}
                              className="outline-none text-red-500"
                            >
                              <RxCross2 />
                            </button>
                          </div>
                          <h2 className="text-red-500 text-sm">{error}</h2>
                        </motion.div>
                      )}
                    </AnimatePresence>
                    <div className="flex items-center justify-center mt-6">
                      <Button name={"Log In"} type={"submit"}></Button>
                    </div>
                  </Form>
                </Formik>
                <p className="mt-6 text-sm text-center text-gray-400">
                  Don't have an account yet?{" "}
                  <Link
                    to="/signup"
                    className="text-[#1d5b79] focus:outline-none focus:underline hover:underline"
                  >
                    Sign up
                  </Link>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;

import React, { useEffect, useState } from "react";
import FormikInput from "../Input/FormikInput";
import FormikSelect from "../Input/FormikSelect";
import { FieldArray, Form, Formik } from "formik";
import { RxCross1 } from "react-icons/rx";
import { durationOptions } from "../Data";
import toast from "react-hot-toast";
import { MdDelete } from "react-icons/md";
import Button from "../Button/Button";
import * as Yup from "yup";

function UpdateAssignedVehicle({
  onClose,
  selectedAssignedVehicle,
  team,
  locations,
  onSubmit,
}) {
  const validationSchema = Yup.object().shape({
    capacity: Yup.number()
      .required("Capacity is required")
      .positive("Capacity must be greater than 0")
      .integer("Capacity must be a whole number"),
    duration: Yup.string()
      .required("Duration is required")
      .oneOf(
        [
          "Production",
          "Post-production",
          "Pre-production",
          "Marketing",
          "Release",
        ],
        "Invalid duration selected"
      ),
    passengers: Yup.array().of(
      Yup.object().shape({
        uid: Yup.number().required("Passenger ID is required"),
        id: Yup.number().required("Passenger ID is required"),
        name: Yup.string().required("Passenger name is required"),
        type: Yup.string().required("Passenger type is required"),
        pickup_address: Yup.string().required("Pickup address is required"),
      })
    ),
    start_date: Yup.date().required("Start Date is required"),
    end_date: Yup.date()
      .required("End Date is required")
      .min(Yup.ref("start_date"), "End Date cannot be before Start Date"),
    drop_address: Yup.string().when("duration", {
      is: (val) =>
        [
          "Production",
          "Post-production",
          "Pre-production",
          "Marketing",
          "Release",
        ].includes(val),
      then: () => Yup.string().required("Drop Address is required"),
      otherwise: () => Yup.string(),
    }),
    location: Yup.string().when("duration", {
      is: (val) => val === "Production",
      then: () => Yup.string().required("Location is required"),
      otherwise: () => Yup.string(),
    }),
  });
  const [locationId, setLocationId] = useState("");
  const handleTeamChange = (data, isChecked, values, setFieldValue) => {
    const teamData = values?.passengers || [];
    const vehicleCapacity = values.capacity || 0;

    if (isChecked && teamData.length >= vehicleCapacity) {
      toast.error("Cannot add more passengers than the vehicle capacity");
      return;
    }

    if (isChecked) {
      setFieldValue("passengers", [
        ...teamData,
        {
          uid: data.uid,
          id: data.id,
          name: data.name,
          type: data.team_type,
          pickup_address: "",
        },
      ]);
    } else {
      setFieldValue(
        "passengers",
        teamData.filter((team) => team.uid !== data.uid)
      );
    }
  };

  const handleDurationChange = (value, setFieldValue) => {
    setFieldValue("duration", value);
    setFieldValue("drop_address", "");
    setFieldValue("location", "");
  };
  const handleLocationChange = (value, setFieldValue) => {
    const location = locations.find(
      (data) => parseInt(data.id) === parseInt(value)
    );

    setFieldValue("location", location.id);
    setFieldValue("drop_address", location.address);
  };

  const fetchLocationId = () => {
    const location = locations.find(
      (data) => data.address === selectedAssignedVehicle.drop_address
    );
    if (!location) {
      setLocationId(null);
    } else {
      setLocationId(location.id);
    }
  };
  useEffect(() => {
    fetchLocationId();
  }, []);

  return (
    <div className="fixed inset-0 flex items-center justify-center overflow-auto z-50 bg-black bg-opacity-50 ">
      <div className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto ">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md w-4/5 sm:my-auto  my-auto">
          <div className="flex items-end justify-end">
            <button
              className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full "
              onClick={onClose}
            >
              <RxCross1 />
            </button>
          </div>
          <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79] capitalize">
            {selectedAssignedVehicle.vehicle.name}
          </h2>
          <Formik
            initialValues={{
              capacity: selectedAssignedVehicle.vehicle.capacity || "",
              duration: selectedAssignedVehicle.duration || "",
              passengers:
                Object.values(selectedAssignedVehicle.assigned_details) || [],
              start_date: selectedAssignedVehicle.start_date || "",
              end_date: selectedAssignedVehicle.end_date || "",
              drop_address: selectedAssignedVehicle.drop_address || "",
              location: parseInt(locationId) || "",
            }}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ setFieldValue, values, setFieldTouched }) => (
              <Form>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                  <div>
                    <FormikInput
                      name={"capacity"}
                      label={"Capacity"}
                      placeholder={"5"}
                      disable={true}
                    />
                  </div>
                  <div>
                    <FormikSelect
                      name={"duration"}
                      label={"Duration"}
                      selectedOption={"Duration"}
                      labelProperty="name"
                      valueProperty="name"
                      optionData={durationOptions}
                      onChange={(e) =>
                        handleDurationChange(e.target.value, setFieldValue)
                      }
                    />
                  </div>
                </div>
                {values.duration === "" ? (
                  <></>
                ) : values.duration === "Production" ? (
                  <div className="grid grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                    <div>
                      <FormikSelect
                        name={"location"}
                        label={"Location"}
                        selectedOption={"Location"}
                        labelProperty="name"
                        valueProperty="id"
                        optionData={locations}
                        onChange={(e) =>
                          handleLocationChange(e.target.value, setFieldValue)
                        }
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"drop_address"}
                        label={"Drop Address"}
                        disable={true}
                        type={"text"}
                        placeholder={"Drop address"}
                      />
                    </div>
                  </div>
                ) : [
                    "Post-production",
                    "Pre-production",
                    "Marketing",
                    "Release",
                  ].includes(values.duration) ? (
                  <div className="mt-2 sm:mt-4">
                    <FormikInput
                      name={"drop_address"}
                      label={"Drop Address"}
                      type={"text"}
                      placeholder={"Drop address"}
                    />
                  </div>
                ) : null}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                  <div>
                    <FormikInput
                      type={"date"}
                      name={"start_date"}
                      label={"Start Date"}
                    />
                  </div>
                  <div>
                    <FormikInput
                      type={"date"}
                      name={"end_date"}
                      label={"End Date"}
                    />
                  </div>
                </div>
                <FieldArray name="passengers">
                  {({ push, remove }) => (
                    <div>
                      <div className="sm:mt-4 mt-2">
                        <label className="block mb-2 text-sm text-gray-600">
                          Team
                        </label>
                        <div className="w-full h-auto max-h-40 rounded-3xl border-[#1d5b79] border-2 p-4  bg-white shadow-lg overflow-y-auto no-scrollbar">
                          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                            {team.map((data) => (
                              <label
                                key={data.uid}
                                className="flex items-center border-[#1d5b79] border h-auto sm:w-full rounded-xl shadow-lg p-3 cursor-pointer transition-all duration-300 transform hover:scale-105 hover:shadow-2xl bg-gray-50"
                              >
                                <input
                                  value={data.id}
                                  type="checkbox"
                                  className="h-5 w-5 border-[#1d5b79] accent-[#1d5b79] cursor-pointer mr-3"
                                  onChange={(e) =>
                                    handleTeamChange(
                                      data,
                                      e.target.checked,
                                      values,
                                      setFieldValue
                                    )
                                  }
                                  checked={values.passengers.some(
                                    (team) => team.uid === data.uid
                                  )}
                                />
                                <div className="flex flex-col">
                                  <h1 className="text-[#1d5b79] capitalize font-medium text-base">
                                    {data.name}
                                  </h1>
                                  <h2 className="text-xs font-medium text-gray-500">
                                    {data.team_type}
                                  </h2>
                                </div>
                              </label>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="mt-4">
                        <label className="block mb-2 text-sm text-gray-600">
                          Passengers
                        </label>
                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 sm:gap-4 mt-4">
                          {values.passengers.map((_, index) => (
                            <div className="flex flex-row relative" key={index}>
                              <FormikInput
                                label={values.passengers[index].name}
                                name={`passengers.${index}.pickup_address`}
                                type={"text"}
                                placeholder={"Pick up address"}
                              />
                              <button
                                type="button"
                                onClick={() => {
                                  remove(index);
                                  const updatedPassengers =
                                    values.passengers.filter(
                                      (_, i) => i !== index
                                    );
                                  setFieldValue(
                                    "passengers",
                                    updatedPassengers
                                  );
                                  setFieldTouched("passengers", true);
                                }}
                                className="bg-[#e70a18] ml-4 p-1 absolute right-2 -top-1 text-lg text-[#fbffff] rounded-full"
                              >
                                <MdDelete />
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </FieldArray>
                <div className="sm:mt-4 mt-2">
                  <Button type={"submit"} name={"Re-assign"} />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default UpdateAssignedVehicle;

import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import SecureLS from "secure-ls";

function PrivateRoute({ children, allowedRole }) {
  const ls = new SecureLS();
  const isAuthenticated = ls.get("isAuthenticated");
  const userRole = ls.get("role");

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (allowedRole.includes(userRole)) {
    return children;
  } else {
    // Redirect to unauthorized page or handle as per your application logic
    return <Navigate to={"/Login"} />;
  }
}

PrivateRoute.propTypes = {
  allowedRole: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PrivateRoute;

import React, { useState } from "react";
import { MdDelete, MdModeEdit } from "react-icons/md";
import FormikInput from "../../../../components/Input/FormikInput";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Button from "../../../../components/Button/Button";
import axios from "axios";
import toast from "react-hot-toast";
import DeleteModal from "../../../../Modal/DeleteModal";
import usePagination from "../../../../components/Pagination/usePagination";
import Pagination from "../../../../components/Pagination/Pagination";

function DepartmentTable({ data, getDepartmentData, openSubModal }) {
  const validationSchema = Yup.object({}).shape({
    department: "",
  });
  const [view, setView] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const { currentItems, currentPage, handlePageChange, totalPages } =
    usePagination(data, 10);

  const viewUpdateColumn = (id) => {
    const departmentdetail = data.find((Data) => Data.id === id);
    setView(true);
    setSelectedDepartment(departmentdetail);
  };

  const openDeleteModal = (id) => {
    const departmentdetail = data.find((Data) => Data.id === id);
    setOpen(!open);
    setSelectedDepartment(departmentdetail);
  };

  const UpdDepartment = (values, { setSubmiting, resetForm }) => {
    const loaderToast = toast.loading("Updating ...", {
      autoClose: false,
    });
    axios
      .put(`api/v1/departments/${selectedDepartment.id}`, {
        name: values.department,
      })
      .then((response) => {
        if (response.status === 200) {
          toast.dismiss(loaderToast);
          toast.success(response.data.message);
          setView(false);
          getDepartmentData();
          resetForm();
        }
      })
      .catch((error) => console.log(error.message));
    setSubmiting(false);
  };

  const deleteDepartment = () => {
    axios
      .delete(`/api/v1/departments/${selectedDepartment.id}`)
      .then((response) => {
        if (response.status === 200) {
          getDepartmentData();
          setOpen(false);
          toast.success(response.data.message);
        }
      })
      .catch((error) => console.log(error.message));
  };

  return (
    <>
      <div className="flex flex-col h-full">
        <div className="w-full mx-auto bg-white  rounded-sm border border-gray-200">
          <header className="flex sm:flex-row flex-col  justify-between px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold text-gray-800">Department</h2>
            <Pagination
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              totalPages={totalPages}
            />
          </header>
          <div className="p-3">
            <div className="overflow-x-auto">
              <table className="table-auto w-full">
                <thead className="text-xs font-semibold uppercase text-gray-600 bg-[#eceff1]">
                  <tr>
                    <th className="px-2 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Department</div>
                    </th>
                    <th className="px-2 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Subsidary department
                      </div>
                    </th>
                    <th className="px-2 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody className="text-sm divide-y divide-gray-100">
                  {currentItems.map((department) => (
                    <tr>
                      <td className="p-2 whitespace-nowrap">
                        {view && selectedDepartment.id === department.id ? (
                          <div className="text-left">
                            <Formik
                              validationSchema={validationSchema}
                              initialValues={{
                                department: "" || selectedDepartment.name,
                              }}
                              onSubmit={UpdDepartment}
                            >
                              <Form>
                                <div className="flex flex-row ml-2">
                                  <div>
                                    <FormikInput
                                      name={"department"}
                                      type={"text"}
                                      placeholder={selectedDepartment.name}
                                    />
                                  </div>
                                  <div className="mt-2 ml-2">
                                    <Button name={"Update"} type={"submit"} />
                                  </div>
                                  <div className="mt-2 ml-2">
                                    <Button
                                      name={"Cancel"}
                                      type={"button"}
                                      clickFunction={() => setView(false)}
                                    />
                                  </div>
                                </div>
                              </Form>
                            </Formik>
                          </div>
                        ) : (
                          <div className="text-left">{department.name}</div>
                        )}
                      </td>
                      <td className="px-2 py-2">
                        <div className="text-left ml-10">
                          <button
                            onClick={() => openSubModal(department.id)}
                            className="font-bold hover:underline duration-200 ease-in"
                          >
                            View
                          </button>
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">
                          <button
                            onClick={() => viewUpdateColumn(department.id)}
                            className="p-4 mr-4 text-xl text-green-600 border-none hover:shadow-none rounded-full outline-none bg-green-50 duration-300 ease-in"
                          >
                            <MdModeEdit />
                          </button>
                          <button
                            onClick={() => openDeleteModal(department.id)}
                            className=" p-4 text-xl text-red-600 border-none hover:shadow-none rounded-full outline-none bg-red-50 duration-300 ease-in"
                          >
                            <MdDelete />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {open && (
        <DeleteModal
          title={"Remove Department"}
          message={`Do you want to remove ${selectedDepartment.name}`}
          onDelete={deleteDepartment}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  );
}

export default DepartmentTable;

import heic2any from "heic2any";
import toast from "react-hot-toast";

export const handleImageConversion = async (file) => {
  if (
    file.type === "image/heic" ||
    file.type === "" ||
    file.type === "image/heif"
  ) {
    try {
      const convertedBlob = await heic2any({
        blob: file,
        toType: "image/jpeg",
        quality: 0.9,
      });
      return new File([convertedBlob], file.name.replace(/\.heic$/, ".jpg"), {
        type: "image/jpeg",
      });
    } catch (err) {
      console.error("HEIC to JPG conversion error: ", err);
      toast.error("Image conversion failed. Please try again.");
    }
  }
  return file;
};

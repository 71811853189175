import React, { useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import moment from "moment-timezone";
import "moment/locale/en-in";

function SpenderModal({ setOpen, data }) {
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(5);
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.expense_history.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(data.expense_history.length / itemsPerPage);
  const convertToIST = (utcDateStr) => {
    return moment.utc(utcDateStr).tz("Asia/Kolkata").format("DD-MM-YYYY");
  };
  return (
    <div>
      <div
        onClick={() => setOpen(false)}
        className="fixed inset-0 flex items-center justify-center z-50 overflow-auto no-scrollbar bg-black bg-opacity-50"
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto "
        >
          <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-5xl sm:max-w-5xl w-full sm:my-auto  my-auto">
            <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
              {data.full_name}
            </h2>
            <div className="w-full mx-auto bg-white  rounded-sm border border-gray-200">
              <header className="flex sm:flex-row flex-col  justify-between px-5 py-4 border-b border-gray-100">
                <h2 className="font-semibold text-gray-800 mr-5">
                  {data.full_name}'s Details
                </h2>
                {totalPages > 1 && (
                  <div className="flex justify-end">
                    <ReactPaginate
                      pageCount={totalPages}
                      onPageChange={handlePageChange}
                      containerClassName={"flex"}
                      pageRangeDisplayed={1}
                      previousLabel={<FaChevronLeft />}
                      nextLabel={<FaChevronRight />}
                      activeClassName={"active"}
                      previousClassName={
                        "bg-[#1d5b79] text-[#fbffff] w-7 h-7 items-center justify-center p-2 top-9 -right-4 text-xl flex rounded"
                      }
                      nextClassName={
                        "bg-[#1d5b79] text-[#fbffff] w-7 h-7 items-center justify-center p-2 top-9 -right-4 text-xl flex rounded"
                      }
                      pageClassName={"mx-1"}
                      pageLinkClassName={
                        "bg-gray-200 text-[#020002] w-7 h-7 items-center justify-center p-2 top-9 -right-4 text-xl flex rounded"
                      }
                      breakClassName={"mx-1"}
                      breakLinkClassName={
                        "px-3 py-1 bg-gray-200 text-gray-600 rounded"
                      }
                    />
                  </div>
                )}
              </header>
              <div className="p-3">
                <div className="overflow-x-auto">
                  <table className="table-auto w-full">
                    <thead className="text-xs font-semibold uppercase text-gray-600 bg-[#eceff1]">
                      <tr>
                        <th className="px-4 py-2 whitespace-nowrap">
                          <div className="font-semibold text-left">Amount</div>
                        </th>
                        <th className="px-4 py-2 whitespace-nowrap">
                          <div className="font-semibold text-left">Date</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-sm divide-y divide-gray-100">
                      {currentItems.map((spender) => {
                        return (
                          <tr>
                            <td className="p-2 whitespace-nowrap">
                              ₹ {spender.assigned_amount}
                            </td>
                            <td className="p-2">
                              <div className="text-left">
                                {convertToIST(spender.data_and_time)}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SpenderModal;

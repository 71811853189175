import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Sheet from "../../components/Sheet";
import "moment-timezone/data/packed/latest";
import SceneScheduleForm from "../../components/Forms/SceneScheduleForm";
import Button from "../../components/Button/Button";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { format } from "date-fns";
import toast from "react-hot-toast";
import SubRoleContext from "../../SubRoleContext";
import Loader from "../../Loader/loader";
import Lock from "../../assets/image/Lock.png";
import moment from "moment-timezone";
import { v4 as uuidv4 } from "uuid";
import SceneScheduleUpdateForm from "../../components/UpdateForm/SceneScheduleUpdateForm";
import DeleteModal from "../../Modal/DeleteModal";
import ContactModal from "../../Modal/ContactModal";
import ErrorPage from "../ErrorPage";

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

function SceneManager() {
  const subRole = useContext(SubRoleContext);
  const [locations, setLocations] = useState("");
  const [locationScene, setLocationScene] = useState([]);
  const [scheduledSceneNumbers, setScheduledSceneNumbers] = useState([]);
  const [shootDetails, setShootDetails] = useState("");
  const [view, setView] = useState(true);
  const [open, setOpen] = useState(false);
  const [sheetData, setSheetData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(false);
  const [update, setUpdate] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState("");
  const [sceneDetail, setSceneDetail] = useState([]);
  const [selectedSceneDetails, setSelectedSceneDetails] = useState([]);
  const [CallSheetContact, setCallSheetContact] = useState([]);
  const [Delete, setDelete] = useState(false);
  const [contactView, setContactView] = useState(false);
  const { projectId } = useParams();

  const openSheet = (sheetId) => {
    const Datasheet = shootDetails.find((sheet) => sheet.id == sheetId);
    setSheetData(Datasheet);
    setOpen(true);
  };

  const updateScheduleView = (sheet) => {
    setUpdate(true);
    setSelectedSchedule({ ...sheet });
    setOpen(false);
  };
  const DeleteScheduleView = (sheet) => {
    setSelectedSchedule({ ...sheet });
    setDelete(true);
  };

  const views = { month: true, day: false, week: false };

  const closeSheet = () => {
    setSheetData(null);
    setOpen(false);
  };

  const viewHandler = () => {
    setView(false);
  };

  const onClose = () => {
    setView(true);
  };
  const stopClose = (e) => {
    e.stopPropagation();
  };

  const handleBackdropClick = () => {
    onClose();
    setLocationScene([]);
    setSelectedSceneDetails([]);
  };

  const fetchLocationDetails = () => {
    axios
      .get(`api/v1/projects/${projectId}/locations`)
      .then((response) => {
        setLocations(response.data.locations);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchLocation = (locationName) => {
    axios
      .get(
        `api/v1/projects/${projectId}/scenes/search?location_name=${locationName}`
      )
      .then((response) => {
        setLocationScene(response.data.scene);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchSceneDetails = () => {
    axios
      .get(`api/v1/projects/${projectId}/scenes`)
      .then((response) => {
        setSceneDetail(response.data.scenes);
      })
      .catch((err) => console.log(err));
  };

  const fetchContactDetails = () => {
    axios
      .get(`api/v1/projects/${projectId}/project_crews/callsheet_members`)
      .then((res) => {
        if (res.status === 200) {
          setCallSheetContact(res.data.crews);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchContactDetails();
    fetchLocationDetails();
    getShootDetails();
    fetchSceneDetails();
  }, []);

  const handleSceneNumberClick = async (sceneNumber, setFieldValue, form) => {
    const isSceneSelected = selectedSceneDetails.some(
      (scene) => scene.id === sceneNumber
    );

    if (isSceneSelected) {
      setSelectedSceneDetails((prevDetails) =>
        prevDetails.filter((scene) => scene.id !== sceneNumber)
      );
      setScheduledSceneNumbers((prevDetails) =>
        prevDetails.filter((scene) => scene !== sceneNumber)
      );

      const updatedArtistsDetails = form.values.artists_details.details.filter(
        (artist) => artist.scene_id !== sceneNumber
      );
      const updatedCrowdDetails = form.values.crowd_details.details.filter(
        (crowd) => crowd.scene_id !== sceneNumber
      );

      setFieldValue("artists_details.details", updatedArtistsDetails);
      setFieldValue("crowd_details.details", updatedCrowdDetails);

      const updatedSceneIds = form.values.scene_id.filter(
        (id) => id !== sceneNumber
      );
      setFieldValue("scene_id", updatedSceneIds);
    } else {
      const selectedScene = sceneDetail.find(
        (scene) => scene.id === sceneNumber
      );
      setSelectedSceneDetails((prevDetails) => [...prevDetails, selectedScene]);

      const newArtistDetails = selectedScene.artists.flatMap((artist) =>
        artist.project_artists.map((data) => ({
          artist_uid: uuidv4(),
          scene_id: sceneNumber,
          artist_id: data.artist_id,
          artist_name: artist.artist.artist_name,
          character_name: data.character_name,
          artist_type: data.artist_type,
          artist_costume: artist.artist_scene.costume,
          call_time: "",
          shoot_time: "",
          transportation: "Self",
        }))
      );

      const newCrowdDetails = selectedScene.crowd_details.details.map(
        (data, index) => ({
          scene_id: sceneNumber,
          crowd_set: index + 1,
          crowd_id: data.crowd_id,
          crowdType: data.crowdType,
          crowdAge: data.crowdAge,
          crowdNumber: data.crowdNumber,
          crowdCostume: data.crowdCostume,
          description: data.description,
          call_time: "",
          shoot_time: "",
        })
      );

      setFieldValue("artists_details.details", [
        ...form.values.artists_details.details,
        ...newArtistDetails,
      ]);
      setFieldValue("crowd_details.details", [
        ...form.values.crowd_details.details,
        ...newCrowdDetails,
      ]);

      const updatedSceneIds = [...form.values.scene_id, sceneNumber];
      setFieldValue("scene_id", updatedSceneIds);
    }
  };

  const scheduleShoot = (value, { resetForm }) => {
    const loaderToast = toast.loading("Scheduling...", {
      autoClose: false,
    });

    axios
      .post(`api/v1/schedules?project_id=${projectId}`, {
        schedule: {
          title: `Day ${value.title}`,
          schedule_date: value.date,
          location_id: value.location_id,
          scene_ids: value.scene_id,
          call_time: value.call_time,
          shoot_time: value.shoot_time,
          breakfast_time: value.breakfast,
          lunch_time: value.lunch,
          snack_time: value.high_tea,
          dinner_time: value.dinner,
          dolly_panther: value.dolly_panther,
          vanity_van: value.vanity_van,
          drone: value.drone,
          vehicle: value.picture_vehicle,
          extra_camera_setup: value.extra_camera_setup,
          unit_call_time: value.unit_call_time,
          min_temperature: value.min_temp,
          max_temperature: value.max_temp,
          sunrise: value.sunrise,
          sunset: value.sunset,
          sound: value.sound,
          extra_equipment: value.extra_equipment,
          remarks: value.remarks,
          artists_details: value.artists_details,
          crowd_details: value.crowd_details,
        },
      })
      .then((response) => {
        if (response.status === 201) {
          toast.dismiss(loaderToast);
          toast.success("Scene Scheduled");
          setSelectedSceneDetails();
          getShootDetails();
          handleBackdropClick();
          resetForm();
        }
      })
      .catch((error) => {
        toast.dismiss(loaderToast);
        toast.error(error.response.data.error);
        handleBackdropClick();
        console.log(error);
      });
  };

  const updateScheduleHandler = (value, { resetForm }) => {
    axios
      .put(`api/v1/schedules/${selectedSchedule.id}`, {
        schedule: {
          title: `Day ${value.title}`,
          schedule_date: value.date,
          location_id: value.location_id,
          scene_ids: value.scene_id,
          call_time: value.call_time,
          shoot_time: value.shoot_time,
          breakfast_time: value.breakfast,
          lunch_time: value.lunch,
          snack_time: value.high_tea,
          dinner_time: value.dinner,
          dolly_panther: value.dolly_panther,
          vanity_van: value.vanity_van,
          drone: value.drone,
          vehicle: value.picture_vehicle,
          extra_camera_setup: value.extra_camera_setup,
          unit_call_time: value.unit_call_time,
          min_temperature: value.min_temp,
          max_temperature: value.max_temp,
          sunrise: value.sunrise,
          sunset: value.sunset,
          sound: value.sound,
          extra_equipment: value.extra_equipment,
          remarks: value.remarks,
          artists_details: value.artists_details,
          crowd_details: value.crowd_details,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          resetForm();
          setUpdate(false);
          toast.success("Schedule updated successfully!!");
          setLocationScene([]);
          setSelectedSceneDetails([]);
          getShootDetails();
        }
      })
      .catch((err) => {
        toast.error(
          err.response.data.message || err.message || err.response.data.error
        );
        console.log(err);
      });
  };

  const deleteShoot = (scheduleId) => {
    axios
      .delete(`api/v1/schedules/${selectedSchedule.id}`)
      .then((response) => {
        getShootDetails();
        setDelete(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getShootDetails = () => {
    axios
      .get(`api/v1/projects/${projectId}/get_schedules`)
      .then((response) => {
        const sceneIds = response.data.schedules.flatMap((schedule) =>
          schedule.scenes.map((scene) => scene.id)
        );
        setLoading(true);
        setScheduledSceneNumbers(sceneIds);
        setShootDetails(response.data.schedules);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 422) {
            setLoading(true);
            setShowError(true);
          } else {
            setLoading(true);
            setError(true);
          }
        } else {
          setError(true);
          console.log(err);
        }
      });
  };

  const eventStyleGetter = (event) => {
    return {
      style: {
        backgroundColor: "#9ADE7B",
        fontSize: "15px",
        borderRadius: "15px",
        fontWeight: "bold",
        border: "none",
      },
    };
  };
  return (
    <>
      {error ? (
        <ErrorPage />
      ) : (
        <div>
          {!loading ? (
            <Loader />
          ) : (
            <>
              {showError ? (
                <div className="flex flex-col items-center justify-center mt-5  xl:ml-20 2xl:ml-20 lg:ml-20 md:ml-20 sm:ml-20 ml-0">
                  <img src={Lock} />
                  <h1 className="text-3xl text-[#1d5b79] font-semibold">
                    You don't have access to this page
                  </h1>
                </div>
              ) : (
                <>
                  <div className="flex flex-col sm:flex sm:flex-col p-5">
                    {subRole === "user" ? null : (
                      <div className="flex sm:flex-row flex-col sm:items-start items-center justify-center sm:justify-start xl:ml-20 2xl:ml-20 md:ml-20 sm:ml-20 ml-0 sm:mt-0 mt-8">
                        <div>
                          <Button
                            clickFunction={viewHandler}
                            name={"Schedule Scene"}
                          />
                        </div>
                        <div className="sm:ml-3 ml-0 sm:mt-0 mt-3">
                          <Button
                            clickFunction={() => setContactView(true)}
                            name={"Add contact"}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="flex items-center justify-center xl:ml-20 2xl:ml-20 md:ml-20 lg:ml-20 sm:ml-20 ml-5">
                    <div className="sm:mt-5 mt-5  bg-[#fbffff] p-2 shadow-lg rounded-lg">
                      <div className="calendar-container">
                        <DnDCalendar
                          localizer={localizer}
                          startAccessor="schedule_date"
                          events={shootDetails}
                          endAccessor="schedule_date"
                          style={{ height: 602, width: "100%" }}
                          showMultiDayTimes
                          views={views}
                          eventPropGetter={eventStyleGetter}
                          draggableAccessor={(event) => true}
                          onSelectEvent={(event) => openSheet(event.id)}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}

          {!view && (
            <SceneScheduleForm
              handleBackdropClick={handleBackdropClick}
              locations={locations}
              locationScene={locationScene}
              scheduledSceneNumbers={scheduledSceneNumbers}
              handleSceneNumberClick={handleSceneNumberClick}
              scheduleShoot={scheduleShoot}
              selectedSceneDetails={selectedSceneDetails}
              fetchLocation={fetchLocation}
            />
          )}
          {open && (
            <Sheet
              sheetData={sheetData}
              close={closeSheet}
              stopClose={stopClose}
              onUpdate={updateScheduleView}
              onDelete={DeleteScheduleView}
              callSheetContact={CallSheetContact}
            />
          )}
          {update && (
            <SceneScheduleUpdateForm
              onClose={() => {
                setUpdate(false);
                setLocationScene([]);
              }}
              locations={locations}
              locationScene={locationScene}
              scheduledSceneNumbers={scheduledSceneNumbers}
              handleSceneNumberClick={handleSceneNumberClick}
              scheduleShoot={scheduleShoot}
              selectedSceneDetails={selectedSceneDetails}
              fetchLocation={fetchLocation}
              data={selectedSchedule}
              setSelectedSceneDetails={setSelectedSceneDetails}
              sceneDetails={sceneDetail}
              onUpdate={updateScheduleHandler}
            />
          )}
          {Delete && (
            <DeleteModal
              icon={true}
              title={"Remove Schedule"}
              message={`Are you sure you want to delete ${selectedSchedule.title}?`}
              onDelete={deleteShoot}
              onClose={() => {
                setSelectedSchedule("");
                setDelete(false);
              }}
            />
          )}
          {contactView && (
            <ContactModal onClose={() => setContactView(false)} />
          )}
        </div>
      )}
    </>
  );
}

export default SceneManager;

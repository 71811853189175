import React, { useContext, useState } from "react";
import { MdModeEdit } from "react-icons/md";
import { BiSolidTrashAlt } from "react-icons/bi";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import SubRoleContext from "../../SubRoleContext";
import { AnimatePresence, motion } from "framer-motion";
import { FaMapLocationDot } from "react-icons/fa6";
import usePagination from "../Pagination/usePagination";
import Pagination from "../Pagination/Pagination";

function LocationTable({
  location,
  openModal,
  updatViewHandler,
  contentToPrint,
}) {
  const subRole = useContext(SubRoleContext);

  const [expandedLocations, setExpandedLocations] = useState({});

  const toggleView = (id) => {
    setExpandedLocations((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const itemVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
  };

  const { currentItems, currentPage, handlePageChange, totalPages } =
    usePagination(location, 10);

  const projectDetails = localStorage.getItem("Project_details");
  const OwnerDetail = JSON.parse(projectDetails);

  const userDetail = localStorage.getItem("User_Details");
  const user_details = JSON.parse(userDetail);

  return (
    <>
      <div className="flex flex-col h-full">
        <div className="w-full mx-auto bg-white  rounded-sm border border-gray-200">
          <header className="flex sm:flex-row flex-col  justify-between px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold text-gray-800 mr-5">Location</h2>
            <Pagination
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              totalPages={totalPages}
            />
          </header>
          <div className="p-3">
            <div className="overflow-x-auto">
              <table className="table-auto w-full">
                <thead className="text-xs font-semibold uppercase text-gray-600 bg-[#eceff1]">
                  <tr>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Location name
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Address</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Map link</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        More details
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-center">Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody className="text-sm divide-y divide-gray-100">
                  {currentItems.map((location, index) => (
                    <React.Fragment key={index}>
                      <tr>
                        <td className="p-2 whitespace-nowrap">
                          {location.name}
                        </td>
                        <td className="p-2">
                          <div
                            className="text-left"
                            style={{
                              width: "400px",
                              textAlign: "justify",
                            }}
                          >
                            {location.address}
                          </div>
                        </td>
                        <td className="p-2 whitespace-nowrap">
                          <div
                            className="text-left font-semibold hover:underline cursor-pointer"
                            onClick={() => window.open(location.location_link)}
                          >
                            View
                          </div>
                        </td>
                        <td className="p-2">
                          <button
                            onClick={() => toggleView(location.id)}
                            className="text-xl border-none outline-none text-[#fbffff] bg-[#1d5b79] rounded-full p-1"
                          >
                            {expandedLocations[location.id] ? (
                              <FaChevronUp size={15} />
                            ) : (
                              <FaChevronDown size={15} />
                            )}
                          </button>
                        </td>
                        <td className="p-4 whitespace-nowrap flex">
                          <button
                            onClick={() => updatViewHandler(location)}
                            className="p-4 text-xl text-green-600 border-none hover:shadow-none rounded-full outline-none bg-green-50  duration-300 ease-in"
                          >
                            <MdModeEdit />
                          </button>
                          <button
                            onClick={() => openModal(location.id)}
                            className="ml-4 p-4 text-xl text-red-600 bg-red-50 hover:shadow-none rounded-full  duration-300 ease-in outline-none"
                          >
                            <BiSolidTrashAlt />
                          </button>
                        </td>
                      </tr>
                      <AnimatePresence>
                        {expandedLocations[location.id] && (
                          <motion.tr
                            key={location.id}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            variants={itemVariants}
                            className="bg-gray-50"
                            transition={{ duration: 0.3, delay: 0.1 }}
                          >
                            <td colSpan={6} className="p-4">
                              {subRole === "manager" ? (
                                <></>
                              ) : (
                                <div className="grid sm:grid-cols-4 grid-cols-1 gap-2 sm:gap-4">
                                  <div className="flex flex-row">
                                    <h2 className="text-sm font-semibold text-[#1d5b79]">
                                      Owner name:
                                    </h2>
                                    <h2 className="text-sm ml-1">
                                      {location.owner_name}
                                    </h2>
                                  </div>
                                  <div className="flex flex-row">
                                    <h2 className="text-sm font-semibold text-[#1d5b79]">
                                      Contact number:
                                    </h2>
                                    <h2 className="text-sm ml-1">
                                      {location.owner_number}
                                    </h2>
                                  </div>
                                  <div className="flex flex-row">
                                    <h2 className="text-sm font-semibold text-[#1d5b79]">
                                      Bank A/C:
                                    </h2>
                                    <h2 className="text-sm ml-1">
                                      {location.owner_bank_account}
                                    </h2>
                                  </div>
                                  <div className="flex flex-row">
                                    <h2 className="text-sm font-semibold text-[#1d5b79]">
                                      Bank name:
                                    </h2>
                                    <h2 className="text-sm ml-1">
                                      {location.owner_bank_name}
                                    </h2>
                                  </div>
                                  <div className="flex flex-row">
                                    <h2 className="text-sm font-semibold text-[#1d5b79]">
                                      Bank branch:
                                    </h2>
                                    <h2 className="text-sm ml-1">
                                      {location.owner_bank_branch}
                                    </h2>
                                  </div>
                                  <div className="flex flex-row">
                                    <h2 className="text-sm font-semibold text-[#1d5b79]">
                                      IFSC:
                                    </h2>
                                    <h2 className="text-sm ml-1">
                                      {location.ifsc_code}
                                    </h2>
                                  </div>
                                  <div className="flex flex-row mt-2 sm:mt-4">
                                    <h2 className="text-sm font-semibold text-[#1d5b79]">
                                      PAN number:
                                    </h2>
                                    <h2 className="text-sm ml-1">
                                      {location.pan_number}
                                    </h2>
                                  </div>
                                  <div className="flex flex-row mt-2 sm:mt-4">
                                    <h2 className="text-sm font-semibold text-[#1d5b79]">
                                      GST number:
                                    </h2>
                                    <h2 className="text-sm ml-1">
                                      {location.gst_number}
                                    </h2>
                                  </div>
                                  <div className="flex flex-row">
                                    <h2 className="text-sm font-semibold text-[#1d5b79]">
                                      Location on rent for:
                                    </h2>
                                    <h2 className="text-sm ml-1">
                                      {location.number_of_days} days
                                    </h2>
                                  </div>
                                  <div className="flex flex-row">
                                    <h2 className="text-sm font-semibold text-[#1d5b79]">
                                      Rent:
                                    </h2>
                                    <h2 className="text-sm ml-1">
                                      {location.rent}
                                    </h2>
                                  </div>
                                  <div className="flex flex-row mt-2 sm:mt-4">
                                    <h2 className="text-sm font-semibold text-[#1d5b79]">
                                      Remarks:
                                    </h2>
                                    <h2 className="text-sm ml-1">
                                      {location.remarks}
                                    </h2>
                                  </div>
                                </div>
                              )}
                              <div className="mt-2">
                                <h2 className="text-sm font-semibold text-[#1d5b79] uppercase">
                                  sub locations
                                </h2>
                                <div className="grid sm:grid-cols-5 grid-cols-1 gap-2 sm:gap-4">
                                  {location.sublocation.sublocations.map(
                                    (sublocation, index) => (
                                      <li
                                        key={index}
                                        className="text-sm m-3 flex"
                                      >
                                        <span className="mt-1 mr-2 text-[#1d5b79]">
                                          <FaMapLocationDot />
                                        </span>
                                        {sublocation}
                                      </li>
                                    )
                                  )}
                                </div>
                              </div>
                            </td>
                          </motion.tr>
                        )}
                      </AnimatePresence>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden absolute top-0 left-8 xl:absolute xl:top-0 xl:left-20 2xl:relative sm:relative  sm:ml-0  flex-col items-center justify-center my-auto z-50 ">
        <div ref={contentToPrint} className="flex flex-col h-full">
          <h2>{user_details.company_name}</h2>
          <h2 className="mb-1 mt-1">{OwnerDetail.title}</h2>
          <div className="flex flex-col h-full">
            <div className="w-full mx-auto bg-white  rounded-sm border border-gray-200">
              <header className="flex sm:flex-row flex-col  justify-between px-5 py-4 border-b border-gray-100">
                <h2 className="font-semibold text-gray-800 mr-5">Location</h2>
              </header>
              <div className="p-3">
                <div className="overflow-x-auto">
                  <table className="table-auto w-full">
                    <thead className="text-xs font-semibold uppercase text-gray-600 bg-[#eceff1]">
                      <tr>
                        <th className="px-4 py-2 whitespace-nowrap">
                          <div className="font-semibold text-left">Sr no.</div>
                        </th>
                        <th className="px-4 py-2 whitespace-nowrap">
                          <div className="font-semibold text-left">
                            Location name
                          </div>
                        </th>
                        <th className="px-4 py-2 whitespace-nowrap">
                          <div className="font-semibold text-left">Address</div>
                        </th>
                        <th className="px-4 py-2 whitespace-nowrap">
                          <div className="font-semibold text-left">
                            Map link
                          </div>
                        </th>
                        <th className="px-4 py-2 whitespace-nowrap">
                          <div className="font-semibold text-center">
                            Sub Locations
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-sm divide-y divide-gray-100">
                      {location.map((location, index) => (
                        <tr key={index}>
                          <td className="p-2 whitespace-nowrap">{index + 1}</td>
                          <td className="p-2 whitespace-nowrap">
                            {location.name}
                          </td>
                          <td className="p-2">
                            <div
                              className="text-left"
                              style={{
                                width: "100px",
                                textAlign: "justify",
                              }}
                            >
                              {location.address}
                            </div>
                          </td>
                          <td className="p-2">
                            <div
                              className="text-left"
                              style={{
                                width: "100px",
                                textAlign: "justify",
                                wordBreak: "break-word",
                              }}
                            >
                              {location.location_link}
                            </div>
                          </td>

                          <td className="p-2">
                            <div
                              className="text-left"
                              style={{
                                width: "100px",
                                textAlign: "justify",
                              }}
                            >
                              {location.sublocation.sublocations.join(", ")}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LocationTable;

import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import { SidebarContext } from "./SuperAdmin/SidebarContext";
import Button from "../components/Button/Button";
import SettingArtistForm from "../components/Forms/SettingArtistForm";
import axios from "axios";
import ArtistSettingTable from "../components/Table/ArtistSettingTable";
import toast from "react-hot-toast";
import Loader from "../Loader/loader";
import ErrorPage from "../Pages/ErrorPage";
import SettingArtistUpdateForm from "../components/UpdateForm/SettingArtistUpdateForm";
import DeleteModal from "../Modal/DeleteModal";

function Artist() {
  const [open, setOpen] = useState(false);
  const [update, setUpdate] = useState(false);
  const [Delete, setDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [artists, setArtists] = useState([]);
  const [selectedArtist, setSelectedArtist] = useState("");
  const { view } = useContext(SidebarContext);

  const handleUpdateArtistView = (artist) => {
    setUpdate(true);
    setSelectedArtist({ ...artist });
  };

  const handleDeleteArtistView = (artist) => {
    setDelete(true);
    setSelectedArtist({ ...artist });
  };

  const fetchArtists = () => {
    axios
      .get(`api/v1/artists`)
      .then((res) => {
        if (res.status === 200) {
          setArtists(res.data.artists);
          setLoading(true);
        }
      })
      .catch((Err) => {
        setError(true);
        console.log(Err);
      });
  };

  const handleAddArtist = (value, { resetForm }) => {
    const loader = toast.loading("Adding ...", { autoClose: false });

    const formData = new FormData();
    formData.append("artist[artist_name]", value.artist_name);
    formData.append("artist[gender]", value.gender);
    formData.append("artist[phone_number]", value.phone_number);
    formData.append("artist[email]", value.email);
    formData.append("artist[pan_card_number]", value.pan_card_number);
    formData.append("artist[bank_account_number]", value.bank_account_number);
    formData.append("artist[bank_name]", value.bank_name);
    formData.append("artist[bank_branch]", value.bank_branch);
    formData.append("artist[ifsc]", value.ifsc);
    formData.append("artist[gst_number]", value.gst_number);
    formData.append("artist[aadhar_card]", value.aadhar_card);
    formData.append("artist[pan_card]", value.pan_card);
    formData.append("artist[cancel_cheque]", value.cancel_cheque);

    axios
      .post(`api/v1/artists`, formData)
      .then((res) => {
        if (res.status === 201) {
          toast.dismiss(loader);
          toast.success(res.data.message);
          fetchArtists();
          setOpen(false);
          resetForm();
        }
      })
      .catch((err) => {
        toast.dismiss(loader);
        toast.error(err.message);
        console.log(err);
      });
  };

  const handleUpdateArtist = (value, { resetForm }) => {
    const loader = toast.loading(`Updating ${selectedArtist.artist_name} ...`, {
      autoClose: false,
    });

    const formData = new FormData();
    formData.append("artist[artist_name]", value.artist_name);
    formData.append("artist[gender]", value.gender);
    formData.append("artist[phone_number]", value.phone_number);
    formData.append("artist[email]", value.email);
    formData.append("artist[pan_card_number]", value.pan_card_number);
    formData.append("artist[bank_account_number]", value.bank_account_number);
    formData.append("artist[bank_name]", value.bank_name);
    formData.append("artist[bank_branch]", value.bank_branch);
    formData.append("artist[ifsc]", value.ifsc);
    formData.append("artist[gst_number]", value.gst_number);
    if (value.aadhar_card) {
      formData.append("artist[aadhar_card]", value.aadhar_card);
    }
    if (value.pan_card) {
      formData.append("artist[pan_card]", value.pan_card);
    }
    if (value.cancel_cheque) {
      formData.append("artist[cancel_cheque]", value.cancel_cheque);
    }

    axios
      .put(`api/v1/artists/${selectedArtist.id}`, formData)
      .then((res) => {
        if (res.status === 200) {
          toast.dismiss(loader);
          toast.success(res.data.message);
          fetchArtists();
          setUpdate(false);
          resetForm();
        }
      })
      .catch((err) => {
        toast.dismiss(loader);
        toast.error(err.message);
        console.log(err);
      });
  };

  const handleDeleteArtist = () => {
    const loader = toast.loading(`Removing ${selectedArtist.artist_name} ...`, {
      autoClose: false,
    });
    axios
      .delete(`api/v1/artists/${selectedArtist.id}`)
      .then((res) => {
        if (res.status === 200) {
          setDelete(false);
          toast.dismiss(loader);
          toast.success(res.data.message);
          fetchArtists();
        }
      })
      .catch((err) => {
        toast.dismiss(loader);
        toast.error(err.message);
        console.log(err);
      });
  };

  useEffect(() => {
    fetchArtists();
  }, []);

  return (
    <div>
      <Sidebar />
      <div
        className={`h-screen p-7 ${
          !view ? "ml-40 duration-100 ease-in" : "ml-0 duration-100 ease-in"
        }`}
      >
        {error ? (
          <div>
            <ErrorPage />
          </div>
        ) : (
          <div>
            {!loading ? (
              <Loader />
            ) : (
              <div className="flex flex-col sm:flex sm:flex-col p-5">
                <div className="flex sm:items-start items-center justify-center sm:justify-start xl:ml-20 2xl:ml-20 md:ml-20 sm:ml-20 ml-0 sm:mt-0 mt-8">
                  <Button
                    name={"Add Artist"}
                    clickFunction={() => setOpen(true)}
                  />
                </div>
                <div className="flex items-center justify-center  xl:ml-20 2xl:ml-20 lg:ml-20 md:ml-20 sm:ml-20 ml-0">
                  <div className="w-full mt-5">
                    <ArtistSettingTable
                      detail={artists}
                      updateView={handleUpdateArtistView}
                      deleteView={handleDeleteArtistView}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {open && (
        <SettingArtistForm
          onClose={() => setOpen(false)}
          onSubmit={handleAddArtist}
        />
      )}
      {update && (
        <SettingArtistUpdateForm
          onClose={() => setUpdate(false)}
          selectedArtist={selectedArtist}
          onSubmit={handleUpdateArtist}
        />
      )}
      {Delete && (
        <DeleteModal
          title={"Remove Artist"}
          message={`Are you sure you want to remove ${selectedArtist.artist_name}?`}
          onClose={() => setDelete(false)}
          onDelete={handleDeleteArtist}
        />
      )}
    </div>
  );
}

export default Artist;

import { ErrorMessage, Form, Formik } from "formik";
import React from "react";
import { RxCross1 } from "react-icons/rx";
import FormikInput from "../Input/FormikInput";
import FormikSelect from "../Input/FormikSelect";
import { carteringType } from "../Data";
import Button from "../Button/Button";
import Select from "react-select";
import * as Yup from "yup";
import FormikRadio from "../Input/FormikRadio";
import CatererTable from "../Table/CatererTable";

function CatererForm({
  onClose,
  onSubmit,
  caterers,
  projectCaterers,
  deleteView,
  updateView,
}) {
  const validationSchema = Yup.object().shape({
    vendor_catrer_id: Yup.string().required("Select catrer"),
    catering_type: Yup.string().required("Select catering type"),
    no_of_days: Yup.number()
      .required("Number of days are required.")
      .positive("Not valid number of days"),
    amount: Yup.number().when("catering_type", {
      is: "Package",
      then: (schema) =>
        schema.required("Enter amount").positive("Amount must be positive."),
      otherwise: (schema) => schema.notRequired(),
    }),
    breakfast: Yup.number().when("catering_type", {
      is: "Individual",
      then: (schema) =>
        schema
          .required("Enter breakfast amount")
          .positive("Amount must be positive"),
      otherwise: (schema) => schema.notRequired(),
    }),
    lunch: Yup.number().when("catering_type", {
      is: "Individual",
      then: (schema) =>
        schema
          .required("Enter lunch amount")
          .positive("Amount must be positive"),
      otherwise: (schema) => schema.notRequired(),
    }),
    hightea: Yup.number().when("catering_type", {
      is: "Individual",
      then: (schema) =>
        schema
          .required("Enter higtea amount")
          .positive("Amount must be positive"),
      otherwise: (schema) => schema.notRequired(),
    }),
    dinner: Yup.number().when("catering_type", {
      is: "Individual",
      then: (schema) =>
        schema
          .required("Enter dinner amount")
          .positive("Amount must be positive"),
      otherwise: (schema) => schema.notRequired(),
    }),
    extra: Yup.number().positive("Amount must be positive"),
  });

  return (
    <div>
      <div className="fixed inset-0 flex items-center justify-center overflow-auto z-50 bg-black bg-opacity-50 ">
        <div className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto ">
          <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-5xl w-full mx-auto ">
            <div className="flex items-end justify-end">
              <button
                className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full "
                onClick={onClose}
              >
                <RxCross1 />
              </button>
            </div>
            <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
              Caterer
            </h2>
            <Formik
              initialValues={{
                vendor_catrer_id: "",
                catering_type: "",
                amount: "",
                breakfast: "",
                lunch: "",
                dinner: "",
                hightea: "",
                extra: "",
                no_of_days: "",
                gst_required: false,
                tds_required: false,
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                    <div>
                      <label className="block mb-2 text-sm text-gray-600 ">
                        Caterers
                      </label>
                      <Select
                        options={caterers}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        onChange={(selectedOption) =>
                          setFieldValue("vendor_catrer_id", selectedOption.id)
                        }
                        name="vendor_catrer_id"
                      />
                      <ErrorMessage
                        component={"div"}
                        name="vendor_catrer_id"
                        className="text-red-500 mt-1 text-sm"
                      />
                    </div>
                    <div>
                      <FormikSelect
                        name={"catering_type"}
                        label={"Catering type"}
                        optionData={carteringType}
                        labelProperty={"type"}
                        valueProperty={"type"}
                        selectedOption={"Catering type"}
                        onChange={(e) =>
                          setFieldValue("catering_type", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  {values.catering_type === "Individual" && (
                    <div>
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                        <div>
                          <FormikInput
                            label={"Breakfast amount"}
                            name={"breakfast"}
                            type={"number"}
                            placeholder={"12*"}
                            min={0}
                          />
                        </div>
                        <div>
                          <FormikInput
                            label={"Lunch amount"}
                            name={"lunch"}
                            type={"number"}
                            placeholder={"52*"}
                            min={0}
                          />
                        </div>
                        <div>
                          <FormikInput
                            name={"hightea"}
                            label={"High Tea amount"}
                            type={"number"}
                            placeholder={"12*"}
                            min={0}
                          />
                        </div>
                        <div>
                          <FormikInput
                            name={"dinner"}
                            label={"Dinner amount"}
                            type={"number"}
                            placeholder={"52*"}
                            min={0}
                          />
                        </div>
                        <div>
                          <FormikInput
                            name={"extra"}
                            label={"Extra snack amount"}
                            type={"number"}
                            min={0}
                            placeholder={"11*"}
                          />
                        </div>
                        <div>
                          <FormikInput
                            name={"no_of_days"}
                            label={"Number of days"}
                            min={1}
                            placeholder={"45"}
                            type={"number"}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {values.catering_type === "Package" && (
                    <div>
                      <div className="sm:mt-4 mt-2">
                        <FormikInput
                          name={"amount"}
                          label={"Amount"}
                          type={"number"}
                          placeholder={"12**"}
                          min={0}
                        />
                      </div>
                      <div className="sm:mt-4 mt-2">
                        <FormikInput
                          name={"no_of_days"}
                          label={"Number of days"}
                          min={1}
                          placeholder={"45"}
                          type={"number"}
                        />
                      </div>
                    </div>
                  )}
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                    <div className="flex flex-col">
                      <div>
                        <label className="block mb-2 text-sm text-gray-600 ">
                          GST required?
                        </label>
                        <div className="flex flex-row">
                          <div>
                            <FormikRadio
                              id={"yes"}
                              label={"Yes"}
                              value={true}
                              onChange={() =>
                                setFieldValue("gst_required", true)
                              }
                              name={"gst_required"}
                            />
                          </div>
                          <div className="ml-2">
                            <FormikRadio
                              id={"no"}
                              value={false}
                              onChange={() =>
                                setFieldValue("gst_required", false)
                              }
                              label={"No"}
                              name={"gst_required"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <div>
                        <label className="block mb-2 text-sm text-gray-600 ">
                          TDS required?
                        </label>
                        <div className="flex flex-row">
                          <div>
                            <FormikRadio
                              id={"yes"}
                              label={"Yes"}
                              value={true}
                              onChange={() =>
                                setFieldValue("tds_required", true)
                              }
                              name={"tds_required"}
                            />
                          </div>
                          <div className="ml-2">
                            <FormikRadio
                              id={"no"}
                              value={false}
                              onChange={() =>
                                setFieldValue("tds_required", false)
                              }
                              label={"No"}
                              name={"tds_required"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sm:mt-4 mt-2">
                    <Button name={"Add"} type={"submit"} />
                  </div>
                </Form>
              )}
            </Formik>
            <div className="w-full mt-5">
              <CatererTable
                caterer={projectCaterers}
                deleteView={deleteView}
                updateView={updateView}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CatererForm;

import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import FormikInput from "../Input/FormikInput";
import IconButton from "../Button/IconButton";
import { CiLocationArrow1 } from "react-icons/ci";

function Email({ onSubmit }) {
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Invalid Email format"),
  });
  return (
    <div>
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <div>
            <FormikInput
              name={"email"}
              label={"Email"}
              type={"email"}
              placeholder={"JohnDoe@example.com"}
            />
          </div>
          <div className="mt-4">
            <IconButton
              name={"Send Otp"}
              icon={<CiLocationArrow1 />}
              type={"submit"}
            />
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default Email;

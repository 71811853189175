import React from "react";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { RxCross1 } from "react-icons/rx";
import FormikInput from "../Input/FormikInput";
import Button from "../Button/Button";
import * as Yup from "yup";
import File from "../Input/File";
import Select from "react-select";
import { Types } from "../Data";

function SceneContinuityUpdateForm({
  updateview,
  setupdateView,
  scene_numbers,
  updateSceneContinuity,
  selectedSceneContinuity,
}) {
  const handleContinuityChange = (selectedOption, push, remove, values) => {
    const selectedScene = selectedOption.map((option) => {
      const Scene = option.value;
      return Scene;
    });

    selectedScene.forEach((element) => {
      const sceneAlreadySelected = values.scene_continuities.some(
        (scene) => scene.scene_number == element
      );
      if (!sceneAlreadySelected) {
        push({
          scene_number: element,
        });
      }
    });

    values.scene_continuities.forEach((scene, index) => {
      if (!selectedScene.includes(scene.scene_number)) {
        remove(index);
      }
    });
  };

  const handleTypeChange = (selectedOption, push, remove, values) => {
    const selectedType = selectedOption.map((option) => option.label);

    selectedType.forEach((element) => {
      const typeAlreadySelected = values.continuity_types.some(
        (type) => type == element
      );
      if (!typeAlreadySelected) {
        push(element);
      }
    });

    values.continuity_types.forEach((type, index) => {
      if (!selectedType.includes(type)) {
        remove(index);
      }
    });
  };

  const existingTypeArray = selectedSceneContinuity.continuity_types.flatMap(
    (type) => ({
      label: type,
    })
  );

  const ObjectTOArray = Object.values(selectedSceneContinuity.c_scenes);

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 overflow-auto no-scrollbar bg-black bg-opacity-50">
      <div className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto">
        <div className="flex-col bg-white p-4 sm:p-8 rounded shadow-md max-w-xl sm:max-w-xl w-full sm:my-auto my-auto">
          <div className="flex items-end justify-end">
            <button
              className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full"
              onClick={() => setupdateView(!updateview)}
            >
              <RxCross1 />
            </button>
          </div>
          <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
            Update Scene Continuity
          </h2>
          <Formik
            initialValues={{
              scene_number: selectedSceneContinuity.scene_number || "",
              continuity_types: selectedSceneContinuity.continuity_types || [],
              scene_continuities: ObjectTOArray || [],
              remark: selectedSceneContinuity.remarks || "",
            }}
            onSubmit={updateSceneContinuity}
          >
            {(formik) => (
              <Form>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                  <div>
                    <FormikInput
                      name="scene_number"
                      label="Scene Number"
                      readOnly
                      type="text"
                      value={formik.values.scene_number}
                    />
                    <ErrorMessage
                      name="scene_number"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div>
                    <FieldArray name="continuity_types">
                      {({ push, remove }) => (
                        <div>
                          <label className="block mb-2 text-sm text-gray-600 ">
                            Continuity types
                          </label>
                          <Select
                            options={Types}
                            isMulti
                            name="continuity_types"
                            isClearable={false}
                            defaultValue={existingTypeArray}
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.label}
                            onChange={(selectedOption) =>
                              handleTypeChange(
                                selectedOption,
                                push,
                                remove,
                                formik.values
                              )
                            }
                          />
                        </div>
                      )}
                    </FieldArray>
                  </div>
                </div>

                <FieldArray name="scene_continuities">
                  {({ push, remove }) => (
                    <>
                      <div className="sm:mt-4 mt-2">
                        <label className="block mb-2 text-sm text-gray-600">
                          Continuity Scene
                        </label>
                        <Select
                          name="scene_continuities"
                          isMulti
                          isClearable={false}
                          defaultValue={formik.values.scene_continuities.map(
                            (scene) => ({
                              label: `${scene.scene_number}${
                                scene.sub_scene || ""
                              }`,
                              value: `${scene.scene_number}${
                                scene.sub_scene || ""
                              }`,
                            })
                          )}
                          isDisabled={
                            formik.values.scene_number === "" ? true : false
                          }
                          options={scene_numbers
                            .filter(
                              (data) =>
                                !formik.values.scene_continuities.some(
                                  (e) =>
                                    e.scene_number + (e.sub_scene || "") ===
                                    data.scene_number + (data.sub_scene || "")
                                ) &&
                                data.scene_number > formik.values.scene_number
                            )
                            .map((data) => ({
                              label: `${data.scene_number}${
                                data.sub_scene || ""
                              }`,
                              value: `${data.scene_number}${
                                data.sub_scene || ""
                              }`,
                            }))}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                          onChange={(selectedOption) =>
                            handleContinuityChange(
                              selectedOption,
                              push,
                              remove,
                              formik.values
                            )
                          }
                        />
                      </div>
                    </>
                  )}
                </FieldArray>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                  <div>
                    <FormikInput
                      name="remark"
                      placeholder="Remark"
                      type="text"
                      label="Remark"
                      value={formik.values.remark}
                    />
                  </div>
                </div>
                <div className="mt-2 sm:mt-4">
                  <Button name="Update" type="submit" />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default SceneContinuityUpdateForm;

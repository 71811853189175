import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { BiSolidTrashAlt } from "react-icons/bi";
import {
  FaChevronDown,
  FaChevronLeft,
  FaChevronRight,
  FaChevronUp,
} from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";
import ReactPaginate from "react-paginate";
import ViewRoom from "../../Modal/ViewRoom";

function AccomodationTable({ data, updateView, deleteView, fetchData }) {
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);
  const [viewRoom, setViewRoom] = useState(false);
  const [selectedAccommodation, setSelectedAccommodation] = useState({});

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleOpenRoomModal = (accommodation) => {
    setSelectedAccommodation({ ...accommodation });
    setViewRoom(true);
  };

  return (
    <div>
      <div className="flex flex-col h-full">
        <div className="w-full mx-auto bg-white rounded-sm border border-gray-200">
          <header className="flex sm:flex-row flex-col justify-between px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold text-gray-800">Accommodation</h2>
            <div className="flex items-start justify-start sm:justify-end sm:items-center sm:mt-0 mt-2 relative">
              {totalPages > 1 && (
                <>
                  <ReactPaginate
                    pageCount={totalPages}
                    onPageChange={handlePageChange}
                    containerClassName={"flex items-center space-x-4"}
                    previousLabel={<FaChevronLeft />}
                    nextLabel={<FaChevronRight />}
                    activeClassName={"active"}
                    previousClassName={
                      "bg-[#1d5b79] text-[#fbffff] w-7 h-7 items-center justify-center p-2 text-xl flex rounded"
                    }
                    nextClassName={
                      "bg-[#1d5b79] text-[#fbffff] w-7 h-7 items-center justify-center p-2 text-xl flex rounded"
                    }
                    pageClassName={"hidden"}
                    pageLinkClassName={"hidden"}
                    breakClassName={"hidden"}
                    renderOnZeroPageCount={null}
                    breakLinkClassName={
                      "px-8 py-1 bg-gray-200 text-gray-600 rounded"
                    }
                  />
                  <div className="mx-2">
                    Page {currentPage + 1} of {totalPages}
                  </div>
                </>
              )}
            </div>
          </header>
          <div className="p-3">
            <div className="overflow-x-auto">
              <table className="table-auto w-full">
                <thead className="text-xs font-semibold uppercase text-gray-600 bg-[#eceff1]">
                  <tr>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Accommodation name
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Accommodation type
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Address</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">City</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">State</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Country</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        View rooms/flats
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody className="text-sm divide-y divide-gray-100">
                  {currentItems.map((accommodation, index) => (
                    <React.Fragment key={index}>
                      <tr>
                        <td className="p-2 whitespace-nowrap">
                          {accommodation.name}
                        </td>
                        <td className="p-2">
                          <div className="text-left">
                            {accommodation.accommodation_type}
                          </div>
                        </td>
                        <td className="p-2">
                          <div
                            className="text-left"
                            style={{ width: "300px", textAlign: "justify" }}
                          >
                            {accommodation.address}
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="text-left">{accommodation.city}</div>
                        </td>
                        <td className="p-2">
                          <div className="text-left">{accommodation.state}</div>
                        </td>
                        <td className="p-2">
                          <div className="text-left">
                            {accommodation.country}
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="text-left">
                            <button
                              disabled={
                                (accommodation.accommodation_type ===
                                  "Bungalow" ||
                                  accommodation.accommodation_type ===
                                    "Farm House") &&
                                true
                              }
                              className={`${
                                accommodation.accommodation_type ===
                                  "Bungalow" ||
                                accommodation.accommodation_type ===
                                  "Farm House"
                                  ? "cursor-not-allowed"
                                  : "cursor-pointer"
                              }hover:underline font-semibold hover:text-gray-800 duration-500`}
                              onClick={() => handleOpenRoomModal(accommodation)}
                            >
                              View
                            </button>
                          </div>
                        </td>
                        <td className="p-2 whitespace-nowrap flex">
                          <div className="text-center">
                            <button
                              onClick={() => updateView(accommodation)}
                              className="p-4 text-xl text-green-600 border-none hover:shadow-none rounded-full outline-none bg-green-50  duration-300 ease-in"
                            >
                              <MdModeEdit />
                            </button>
                            <button
                              onClick={() => deleteView(accommodation)}
                              className="ml-4 p-4 text-xl text-red-600 bg-red-50 hover:shadow-none rounded-full  duration-300 ease-in outline-none"
                            >
                              <BiSolidTrashAlt />
                            </button>
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {viewRoom && (
        <ViewRoom
          onClose={() => setViewRoom(false)}
          data={selectedAccommodation}
          fetchData={fetchData}
        />
      )}
    </div>
  );
}

export default AccomodationTable;

import React from "react";
import Input from "../Input/Input";
import Button from "../Button/Button";
import { Form, Formik } from "formik";
import FormikInput from "../Input/FormikInput";
import * as Yup from "yup";

function ChangePassword({ changePasswordHandler }) {
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/\d/, "Password must contain at least one number")
      .matches(
        /[@$!%*?&#]/,
        "Password must contain at least one special character"
      )
      .required("Password is required"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords does not match")
      .required("Please enter confirm password"),
  });

  return (
    <div>
      <Formik
        initialValues={{
          password: "",
          confirm_password: "",
        }}
        validationSchema={validationSchema}
        onSubmit={changePasswordHandler}
      >
        <Form>
          <FormikInput
            name={"password"}
            type={"text"}
            placeholder={"XXXXXX"}
            label={"Password"}
          />
          <div className="mt-4">
            <FormikInput
              name={"confirm_password"}
              type={"text"}
              placeholder={"XXXXXX"}
              label={"Confirm Password"}
            />
          </div>
          <div className="mt-6 flex items-end justify-end">
            <Button name={"Change password"} type={"submit"} />
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default ChangePassword;

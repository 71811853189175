import React, { useRef, useState } from "react";
import { Camera } from "react-camera-pro";
import { RxCross1 } from "react-icons/rx";
import { FaCamera, FaFolder, FaTrash } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import axios from "axios";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { handleImageConversion } from "../components/ImageConversion";

function ReferenceImages({ setOpen, continuityImage, fetchData }) {
  const [image, setImage] = useState([]);
  const [cameraView, setCameraView] = useState(false);
  const camera = useRef(null);
  const { projectId } = useParams();

  const handleCaptureImage = () => {
    const newImage = camera.current.takePhoto();
    setImage([...image, newImage]);
  };
  const dataURLToBlob = (dataURL) => {
    const binary = atob(dataURL.split(",")[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
  };

  const handleAddImage = async () => {
    const loaderToast = toast.loading("Adding Images...", {
      autoClose: false,
    });
    const formData = new FormData();
    if (image) {
      [...image].forEach((image, index) => {
        const blob = dataURLToBlob(image);
        formData.append(
          `scene_c[images][]`,
          blob,
          `captured-photo-${index}.jpg`
        );
      });
    } else {
      toast.dismiss(loaderToast);
      toast.error("No image selected");
    }

    const headers = {
      "Content-Type": "multipart/form-data",
    };
    axios
      .put(
        `api/v1/projects/${projectId}/scene_continuities/${continuityImage.id}`,
        formData,
        { headers }
      )
      .then((res) => {
        toast.success("Scene Continuity Updated..");
        toast.dismiss(loaderToast);
        fetchData();
        setOpen(false);
        setImage([]);
      })
      .catch((err) => {
        toast.dismiss(loaderToast);
        toast.error(err.message);
        console.log(err);
      });
  };

  const handleUploadSelectedImage = async (files) => {
    const loaderToast = toast.loading("Adding Images...", {
      autoClose: false,
    });

    const formData = new FormData();
    if (files && files.length) {
      const otherImages = await Promise.all(
        [...files].map((image) => handleImageConversion(image))
      );
      otherImages.forEach((image) => {
        formData.append("scene_c[images][]", image);
      });
    } else {
      toast.dismiss(loaderToast);
      toast.error("No image selected");
      return;
    }

    const headers = {
      "Content-Type": "multipart/form-data",
    };

    axios
      .put(
        `api/v1/projects/${projectId}/scene_continuities/${continuityImage.id}`,
        formData,
        { headers }
      )
      .then(() => {
        toast.success("Scene Continuity Updated.");
        toast.dismiss(loaderToast);
        fetchData();
        setOpen(false);
        setImage([]);
      })
      .catch((err) => {
        toast.dismiss(loaderToast);
        toast.error(err.message);
        console.log(err);
      });
  };

  const handleRemove = (indx) => {
    const newImageArray = image.filter((_, index) => index !== indx);
    setImage(newImageArray);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center overflow-auto bg-black bg-opacity-50 z-50">
      <div className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto ">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-5xl w-full mx-auto my-auto">
          <div className="flex items-center justify-between">
            <div className="flex items-start justify-start">
              <h2 className="text-lg sm:text-2xl font-semibold  text-[#1d5b79]">
                Reference images for scene continuity
              </h2>
            </div>
            <div className="flex items-end justify-end">
              <button
                className="bg-[#1d5b79] p-2 sm:-mt-6 sm:-mr-4 -mt-3 -mr-2 text-lg text-[#fbffff] rounded-full "
                onClick={() => setOpen(false)}
              >
                <RxCross1 />
              </button>
            </div>
          </div>
          {cameraView ? (
            <div className="flex flex-col items-center mt-2 relative">
              {/* Camera Container */}
              <div className="relative" style={{ height: 480, width: 350 }}>
                <Camera
                  ref={camera}
                  aspectRatio={3 / 4}
                  facingMode="environment"
                  className="rounded-lg shadow-md"
                />
              </div>

              {/* Button Container */}
              <div className="absolute bottom-44 w-full px-4 flex justify-between items-center">
                <button
                  onClick={handleCaptureImage}
                  className="relative bg-[#1d5b79] p-4 rounded-full shadow-lg outline-none mx-auto ml-24"
                >
                  <span className="block bg-white h-8 w-8 rounded-full"></span>
                </button>
                <button
                  onClick={() => setCameraView(false)}
                  className="border border-[#1d5b79] bg-white text-[#1d5b79] outline-none rounded-lg shadow-lg px-3 py-2"
                >
                  Close
                </button>
              </div>

              {/* Captured Images Container */}
              <div className="h-40 flex flex-row w-11/12 overflow-x-auto no-scrollbar mt-24">
                {image.map((img, index) => (
                  <div key={index} className="mx-2 flex-shrink-0 relative">
                    <button
                      onClick={() => handleRemove(index)}
                      className="bg-[#e70a18] p-2 text-lg text-[#fbffff] rounded-full absolute top-0 right-0"
                    >
                      <FaTrash />
                    </button>
                    <img
                      src={img}
                      className="h-24 w-24 object-cover rounded-lg shadow-md"
                    />
                  </div>
                ))}
              </div>

              {/* Add Image Button */}
              <div className="absolute bottom-0 right-0  mr-3 mt-32">
                <button
                  onClick={handleAddImage}
                  className="bg-[#1d5b79] p-2 text-xl text-[#fbffff] rounded-full"
                >
                  <TiTick />
                </button>
              </div>
            </div>
          ) : (
            <div>
              <div className="grid grid-cols-1 sm:grid-cols-4 gap-2 sm:gap-4 mt-2 sm:mt-4">
                {continuityImage.image_urls.map((image, index) => (
                  <>
                    <img
                      src={image}
                      key={index}
                      className="h-60 w-full rounded"
                    />
                  </>
                ))}
              </div>
              <div className="flex flex-row items-center justify-center mt-5">
                <div>
                  <label
                    htmlFor="imageDoc"
                    className="flex items-center justify-center p-4 w-16 h-16 rounded-3xl border-2 border-[#1d5b79] border-dashed bg-opacity-20 bg-[#1d5b79] cursor-pointer"
                  >
                    <h2 className="text-xl text-[#1d5b79]">
                      <FaFolder />
                    </h2>
                    <input
                      type="file"
                      accept="image/*,.heic"
                      hidden
                      id="imageDoc"
                      multiple
                      onChange={(e) =>
                        handleUploadSelectedImage(e.target.files)
                      }
                    />
                  </label>
                </div>
                <div className="ml-3">
                  <button
                    onClick={() => setCameraView(true)}
                    className="flex items-center justify-center p-4 w-16 h-16 rounded-3xl border-2 border-[#1d5b79] border-dashed bg-opacity-20 bg-[#1d5b79] cursor-pointer"
                  >
                    <h2 className="text-xl text-[#1d5b79]">
                      <FaCamera />
                    </h2>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ReferenceImages;

import React, { useContext, useEffect, useState } from "react";
import { SidebarContext } from "./SuperAdmin/SidebarContext";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import { Form, Formik } from "formik";
import FormikInput from "../components/Input/FormikInput";
import { motion } from "framer-motion";
import File from "../components/Input/File";
import * as Yup from "yup";
import toast from "react-hot-toast";
import Loader from "../Loader/loader";
import { City, State } from "country-state-city";
import FormikSelect from "../components/Input/FormikSelect";
import { MdEdit } from "react-icons/md";
import IconButton from "../components/Button/IconButton";
import Button from "../components/Button/Button";
import ErrorPage from "./ErrorPage";
const generateVibrantColor = () => {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);

  return `rgb(${r * 0.9},${g * 0.9},${b * 0.9})`;
};
function Profile() {
  const [imageUrl, setImageUrl] = useState(null);
  const [edit, setEdit] = useState(true);
  const [user, setUser] = useState("");
  const [logo, setLogo] = useState("");
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { view } = useContext(SidebarContext);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Please enter a valid email")
      .email("Invalid email format"),
    first_name: Yup.string()
      .min(2, "First name must have at least 2 characters")
      .required("Please enter a first name"),
    last_name: Yup.string()
      .min(2, "Last name must have at least 2 characters")
      .required("Please enter a last name"),
    company_name: Yup.string()
      .min(3, "Company name must have at least 3 characters")
      .required("Please enter a company name"),
    phone_number: Yup.string()
      .matches(/^[0-9]{10}$/, "Contact number must have exactly 10 digits")
      .required("Please enter Contact Number"),
    state: Yup.string().required("Please select state."),
    city: Yup.string().required("Please select city."),
    address: Yup.string().required("Please fill up address"),
    pincode: Yup.string()
      .matches(
        /^[1-9][0-9]{5}$/,
        "Pincode must be exactly 6 digits and cannot start with 0"
      )
      .required("Pincode is required"),
    pan_number: Yup.string()
      .required("PAN number is required")
      .matches(
        /^[A-Z]{5}[0-9]{4}[A-Z]$/,
        "Invalid PAN number. Format should be ABCDE1234F"
      ),
  });

  const userDetail = localStorage.getItem("User_Details");
  const user_details = JSON.parse(userDetail);

  const fetchUser = () => {
    const canvas = document.createElement("canvas");
    canvas.width = 200;
    canvas.height = 200;
    const ctx = canvas.getContext("2d");

    const bgColor = generateVibrantColor();
    ctx.fillStyle = bgColor;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    if (user_details.company_name) {
      const title = user_details.company_name;
      const abbreviatedTitle = title.substring(0, 1).toUpperCase();

      ctx.font = "80px Arial";
      ctx.fillStyle = "white";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";

      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;

      ctx.fillText(abbreviatedTitle, centerX, centerY);

      const imageSrc = canvas.toDataURL("image/png");
      setImageUrl(imageSrc);
    }
  };
  const fetchUserDetails = () => {
    axios
      .get(`api/v1/users/find_user`)
      .then((response) => {
        setLoading(true);
        setUser(response.data.user);
        localStorage.setItem(
          "User_Details",
          JSON.stringify({
            company_name: response.data.user.company_name,
            name:
              response.data.user.first_name +
              " " +
              response.data.user.last_name,
          })
        );
      })
      .catch((error) => {
        setError(true);
        console.log(error.message);
      });
  };
  useEffect(() => {
    fetchUserDetails();
    fetchUser();
    fetchStates();
  }, []);

  const updateUser = (values, { resetForm }) => {
    const loader = toast.loading("Updating Profile ...", { autoClose: false });
    const formData = new FormData();
    formData.append("user[email]", values.email);
    formData.append("user[first_name]", values.first_name);
    formData.append("user[last_name]", values.last_name);
    formData.append("user[company_name]", values.company_name);
    formData.append("user[phone_number]", values.phone_number);
    formData.append("user[pan_number]", values.pan_number);
    formData.append("user[tan_number]", values.tan_number);
    formData.append("user[gst_number]", values.gst_number);
    formData.append("user[address]", values.address);
    formData.append("user[city]", values.city);
    formData.append("user[state]", values.state);
    formData.append("user[pincode", values.pincode);
    if (logo) {
      formData.append("user[logo]", logo);
    }
    const headers = {
      "Content-Type": "multipart/form-data",
    };

    axios
      .put(`api/v1/users/${user.id}`, formData, { headers })
      .then((response) => {
        setEdit(true);
        resetForm();
        toast.dismiss(loader);
        toast.success(response.data.message);
        fetchUserDetails();
        fetchUser();
      })
      .catch((error) => {
        console.log(error);
        toast.dismiss(loader);
        toast.error(error.message);
      });
  };

  const fetchStates = () => {
    const state = State.getStatesOfCountry("IN");
    setState(state);
  };

  const fetchCity = (code) => {
    const cities = City.getCitiesOfState("IN", code);

    setCity(cities);
  };

  const handleStateChange = (value, setFieldValue) => {
    const selectedState = state.find((data) => data.name === value);

    fetchCity(selectedState.isoCode);
    setFieldValue("state", selectedState.name);
  };

  return (
    <div>
      <Sidebar />
      <div
        className={`h-screen p-7 ${
          !view ? "ml-40 duration-100 ease-in" : "ml-0 duration-100 ease-in"
        }`}
      >
        {!loading ? (
          <Loader />
        ) : (
          <div>
            {error ? (
              <ErrorPage />
            ) : (
              <div className="flex flex-col  xl:ml-20 2xl:ml-20 lg:ml-20 md:ml-20 sm:ml-20 ml-0">
                <motion.div
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5 }}
                  className="bg-transparent rounded-3xl shadow-2xl w-full mx-auto mb-5"
                >
                  <div className="bg-gradient-to-r from-[#D3E7EE]  to-[#1d5b79] sm:h-40 h-32 rounded-t-3xl" />
                  <div className="flex md:flex-row flex-col md:items-start md:justify-start items-center justify-center">
                    <img
                      src={user.logo ? user.logo : imageUrl}
                      className="md:h-32 h-28 md:w-32 w-28 rounded-full -mt-14 md:ml-5 ml-0"
                      alt={user_details.company_name}
                    />
                    <div className="flex md:items-start items-center justify-center md:justify-start flex-col md:ml-5 ml-0">
                      <h1 className="font-Bebas font-bold tracking-wider text-4xl md:-mt-9 mt-2 text-[#1d5b79] md:text-opacity-50 text-opacity-70 md:mx-0 mx-3">
                        {user.company_name}
                      </h1>
                      <h2 className="font-Bebas font-bold tracking-wider md:text-xl text-lg">
                        By {user.first_name} {user.last_name}
                      </h2>
                    </div>
                  </div>
                  <div>
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        email: user.email,
                        first_name: user.first_name,
                        last_name: user.last_name,
                        company_name: user.company_name,
                        phone_number: user.phone_number,
                        pan_number: user.pan_number,
                        gst_number: user.gst_number || "",
                        tan_number: user.tan_number || "",
                        address: user.address,
                        state: user.state,
                        city: user.city,
                        pincode: user.pincode,
                      }}
                      validationSchema={edit ? null : validationSchema}
                      onSubmit={updateUser}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 sm:gap-4 mt-2  px-10 py-5">
                            <div>
                              <FormikInput
                                label={"First name"}
                                type={"text"}
                                name={"first_name"}
                                placeholder={"John"}
                                value={values.first_name}
                                readOnly={edit}
                              />
                            </div>
                            <div>
                              <FormikInput
                                label={"Last name"}
                                type={"text"}
                                name={"last_name"}
                                placeholder={"Smith"}
                                value={values.last_name}
                                readOnly={edit}
                              />
                            </div>
                            <div>
                              <FormikInput
                                name={"company_name"}
                                label={"Production house name"}
                                type={"text"}
                                placeholder={"T-series"}
                                value={values.company_name}
                                readOnly={edit}
                              />
                            </div>
                            <div>
                              <FormikInput
                                label={"Contact number"}
                                type={"tel"}
                                name={"phone_number"}
                                placeholder={"XXX-XX-XXXX-XXX"}
                                value={values.phone_number}
                                readOnly={edit}
                              />
                            </div>
                            <div>
                              <FormikInput
                                label={"Email"}
                                type={"email"}
                                name={"email"}
                                value={values.email}
                                placeholder={"johnsmith@example.com"}
                                readOnly={edit}
                              />
                            </div>
                            <div>
                              <FormikInput
                                label={"Address"}
                                type={"text"}
                                name={"address"}
                                placeholder={
                                  "Sardar Sarovar Dam, Statue of Unity Rd, Kevadia, Gujarat 393155"
                                }
                                value={values.address}
                                readOnly={edit}
                              />
                            </div>
                            <div>
                              <FormikSelect
                                label={"State"}
                                name={"state"}
                                selectedOption={"state"}
                                optionData={state}
                                valueProperty="name"
                                disable={edit}
                                onChange={(e) =>
                                  handleStateChange(
                                    e.target.value,
                                    setFieldValue
                                  )
                                }
                              />
                            </div>
                            <div>
                              <FormikSelect
                                label={"City"}
                                name={"City"}
                                selectedOption={"City"}
                                optionData={city}
                                valueProperty="name"
                                disable={edit}
                                onChange={(e) =>
                                  setFieldValue("city", e.target.value)
                                }
                              />
                            </div>
                            <div>
                              <FormikInput
                                name={"pincode"}
                                label={"Pincode"}
                                type={"text"}
                                readOnly={edit}
                                placeholder={"380058"}
                              />
                            </div>
                            <div>
                              <FormikInput
                                label={"PAN number"}
                                type={"text"}
                                name={"pan_number"}
                                placeholder={"ABCTY1234D"}
                                value={values.pan_number}
                                readOnly={edit}
                              />
                            </div>
                            <div>
                              <FormikInput
                                name={"tan_number"}
                                placeholder={"PDES03028F"}
                                label={"TAN number"}
                                value={values.tan_number}
                                readOnly={edit}
                                type={"text"}
                              />
                            </div>
                            <div>
                              <FormikInput
                                name={"gst_number"}
                                label={"GST number"}
                                placeholder={"22AAAAA0000A1Z5"}
                                type={"text"}
                                value={values.gst_number}
                                readOnly={edit}
                              />
                            </div>
                            <div>
                              <File
                                label={"Logo"}
                                accept={".jpg,.png,.jpeg"}
                                readOnly={edit}
                                onChangeFunction={(e) =>
                                  setLogo(e.target.files[0])
                                }
                              />
                            </div>
                          </div>
                          <div className="px-5 pb-3">
                            {edit ? (
                              <div className="flex items-end justify-end flex-row">
                                <IconButton
                                  clickFunction={() => setEdit(false)}
                                  type={"button"}
                                  icon={<MdEdit />}
                                  name={"Edit"}
                                />
                              </div>
                            ) : (
                              <div className="flex items-end justify-end flex-row">
                                <div className="mr-2">
                                  <Button
                                    name={"Cancel"}
                                    type={"button"}
                                    clickFunction={() => setEdit(true)}
                                  />
                                </div>
                                <div>
                                  <Button name={"Update"} type={"submit"} />
                                </div>
                              </div>
                            )}
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </motion.div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Profile;

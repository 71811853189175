import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Input from "../../components/Input/Input";
import Loader from "../../Loader/loader";
import Select from "../../components/Input/Select";
import ErrorPage from "../ErrorPage";

function AllContact() {
  const [crew, setCrew] = useState("");
  const [actor, setActor] = useState("");
  const [projectVendordata, setProjectVendorData] = useState([]);
  const [search, setSearch] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { projectId } = useParams();

  const columns = [
    {
      field: "srNo",
      headerName: "Sr NO.",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Name",
      headerName: "Name",
      width: 300,
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "Role",
      headerName: "Role",
      width: 300,
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      width: 400,
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "Contact",
      headerName: "Contact No",
      width: 400,
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
  ];

  const fetchData = () => {
    let isError = false; // Flag to track if any request failed

    const crewPromise = axios
      .get(`api/v1/projects/${projectId}/project_crews`)
      .then((res) => {
        return res.data.project_crews.map((crews) => ({
          Name: crews.full_name,
          Role: crews.sub_category,
          email: crews.email,
          type: "Crew",
          Contact: crews.phone_number,
        }));
      })
      .catch((err) => {
        console.log(err);
        isError = true;
        return [];
      });

    const artistPromise = axios
      .get(`api/v1/projects/${projectId}/project_artists`)
      .then((res) => {
        return res.data.project_artists.map((artist) => ({
          Name: artist.artist_name,
          Role: artist.artist_type,
          email: artist.email,
          type: "Artist",
          Contact: artist.phone_number,
        }));
      })
      .catch((err) => {
        console.log(err);
        isError = true;
        return [];
      });

    const vendorPromise = axios
      .get(`api/v1/projects/${projectId}/project_vendors`)
      .then((response) => {
        return response.data.map((vendor) => ({
          Name: vendor.vendor.name,
          Role: vendor.vendor.vendor_type,
          email: vendor.vendor.email,
          type: "Vendor",
          Contact: vendor.vendor.phone_number,
        }));
      })
      .catch((error) => {
        console.log(error.message);
        isError = true;
        return [];
      });

    Promise.all([crewPromise, artistPromise, vendorPromise]).then(
      ([crewDetails, artistDetails, vendorDetails]) => {
        setCrew(crewDetails);
        setActor(artistDetails);
        setProjectVendorData(vendorDetails);

        if (isError) {
          setError(true);
        } else {
          setLoading(true);
        }
      }
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  const rows = [...actor, ...crew, ...projectVendordata].filter((row) =>
    row.Name.toLowerCase().includes(search.toLowerCase())
  );

  const filteredRow = rows.filter((row) =>
    row.type.toLowerCase().includes(typeFilter.toLowerCase())
  );

  const option = [
    { id: 1, name: "Crew" },
    { id: 2, name: "Artist" },
    { id: 3, name: "Vendor" },
  ];

  return (
    <div>
      {error ? (
        <ErrorPage />
      ) : (
        <div>
          {!loading ? (
            <Loader />
          ) : (
            <div className="flex flex-col sm:flex sm:flex-col p-5">
              <div className="flex sm:flex-row flex-col items-start justify-start xl:ml-20 2xl:ml-20 sm:ml-20 ml-0 sm:mt-0 mt-5">
                <div>
                  <Select
                    selectedOption={"Contact Type"}
                    onChangeFunction={(e) => setTypeFilter(e.target.value)}
                    optionData={option}
                    label={"TYPE"}
                  />
                </div>
                <div className="sm:ml-3 ml-0">
                  <Input
                    type={"text"}
                    placeholderValue={"Search ..."}
                    onChangeFunction={(e) => setSearch(e.target.value)}
                    inputValue={search}
                    label={"Search"}
                  />
                </div>
              </div>
              <div className="flex items-center justify-center md:ml-20 xl:ml-20 2xl:ml-20 lg:ml-20 sm:ml-20 ml-0">
                <div className="w-full mt-5">
                  <Box
                    sx={{
                      "& .super-app-theme--header": {
                        backgroundColor: "#F3F4F6",
                        fontSize: "17px",
                        fontWeight: "bold",
                        color: "#333",
                        border: "none",
                        borderBottom: "none",
                      },
                      "& .MuiDataGrid-columnSeparator": {
                        display: "none",
                      },
                      "& .MuiDataGrid-row": {
                        "&:nth-of-type(odd)": {
                          backgroundColor: "#FAFAFA",
                        },
                        "&:hover": {
                          backgroundColor: "#EEEEEE",
                        },
                      },
                      "& .super-app-theme--cell": {
                        border: "none",
                        backgroundColor: "#F9FAFB",
                        borderBottom: "1px solid #E6E6E6",
                      },
                    }}
                  >
                    <DataGrid
                      rows={filteredRow.map((row, index) => ({
                        ...row,
                        srNo: index + 1,
                        id: index + 1,
                      }))}
                      columns={columns}
                      initialState={{
                        ...filteredRow.initialState,
                        pagination: { paginationModel: { pageSize: 10 } },
                      }}
                      pageSizeOptions={[10]}
                      hideFooterSelectedRowCount={true}
                      rowSelection={false}
                      density="compact"
                    />
                  </Box>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default AllContact;

import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { BiSolidTrashAlt } from "react-icons/bi";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router-dom";

function ViewRoom({ data, onClose, fetchData }) {
  const [currentPage, setCurrentPage] = useState(0);
  const [rooms, setRooms] = useState(data.rooms);
  const [itemsPerPage] = useState(5);
  const { projectId } = useParams();

  useEffect(() => {
    setRooms(data.rooms);
  }, [data]);

  const handleDelete = (id) => {
    const loader = toast.loading(
      data.accommodation_type === "Flat"
        ? `Removing ${data.name}'s Flat`
        : `Removing ${data.name}'s Room`
    );
    axios
      .delete(`api/v1/projects/${projectId}/delete_room/${id}`)
      .then((res) => {
        if (res.status === 200) {
          toast.dismiss(loader);
          toast.success(res.data.message);
          fetchData();
          setRooms(rooms.filter((prev) => prev.id !== id));
        }
      })
      .catch((err) => {
        console.log(err);
        toast.dismiss(loader);
        toast.error(err.message);
      });
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = rooms.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(rooms.length / itemsPerPage);

  return (
    <div className="fixed inset-0 flex items-center justify-center overflow-auto z-50 bg-black bg-opacity-50 overflow-x-hidden">
      <div className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md w-1/2 sm:w-full sm:my-auto  my-auto">
          <div className="flex items-end justify-end">
            <button
              className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full"
              onClick={onClose}
            >
              <RxCross1 />
            </button>
          </div>
          <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
            {data.accommodation_type === "Flat"
              ? `${data.name}'s Flats`
              : `${data.name}'s Rooms`}
          </h2>
          <div className="flex items-center justify-center">
            <div className="w-full mt-5">
              <div className="flex flex-col h-full">
                <div className="w-full mx-auto bg-white rounded-sm border border-gray-200">
                  <header className="flex sm:flex-row flex-col justify-between px-5 py-4 border-b border-gray-100">
                    <h2 className="font-semibold text-gray-800 mr-5">
                      {data.accommodation_type === "Flat" ? "Flat" : "Rooms"}
                    </h2>
                    {totalPages > 1 && (
                      <div className="flex items-start justify-start sm:justify-end sm:items-center sm:mt-0 mt-2 relative">
                        <ReactPaginate
                          pageCount={totalPages}
                          onPageChange={handlePageChange}
                          containerClassName={"flex items-center space-x-4"}
                          previousLabel={<FaChevronLeft />}
                          nextLabel={<FaChevronRight />}
                          activeClassName={"active"}
                          previousClassName={
                            "bg-[#1d5b79] text-[#fbffff] w-7 h-7 items-center justify-center p-2 text-xl flex rounded"
                          }
                          nextClassName={
                            "bg-[#1d5b79] text-[#fbffff] w-7 h-7 items-center justify-center p-2 text-xl flex rounded"
                          }
                          pageClassName={"hidden"}
                          pageLinkClassName={"hidden"}
                          breakClassName={"hidden"}
                          renderOnZeroPageCount={null}
                          breakLinkClassName={
                            "px-8 py-1 bg-gray-200 text-gray-600 rounded"
                          }
                        />
                        <div className="mx-2">
                          Page {currentPage + 1} of {totalPages}
                        </div>
                      </div>
                    )}
                  </header>
                  <div className="p-3">
                    <div className="overflow-x-auto">
                      <table className="table-auto w-full">
                        <thead className="text-xs font-semibold uppercase text-gray-600 bg-[#eceff1]">
                          <tr>
                            {data.accommodation_type === "Flat" ? (
                              <>
                                <th className="px-4 py-2 whitespace-nowrap">
                                  <div className="font-semibold text-left">
                                    Flat no.
                                  </div>
                                </th>
                                <th className="px-4 py-2 whitespace-nowrap">
                                  <div className="font-semibold text-left">
                                    BHK
                                  </div>
                                </th>
                                <th className="px-4 py-2 whitespace-nowrap">
                                  <div className="font-semibold text-left">
                                    Occupancy
                                  </div>
                                </th>
                                <th className="px-4 py-2 whitespace-nowrap">
                                  <div className="font-semibold text-left">
                                    Rate
                                  </div>
                                </th>
                              </>
                            ) : (
                              <>
                                <th className="px-4 py-2 whitespace-nowrap">
                                  <div className="font-semibold text-left">
                                    Room no.
                                  </div>
                                </th>
                                <th className="px-4 py-2 whitespace-nowrap">
                                  <div className="font-semibold text-left">
                                    Room type
                                  </div>
                                </th>
                                <th className="px-4 py-2 whitespace-nowrap">
                                  <div className="font-semibold text-left">
                                    Occupancy
                                  </div>
                                </th>
                                <th className="px-4 py-2 whitespace-nowrap">
                                  <div className="font-semibold text-left">
                                    Rate
                                  </div>
                                </th>
                                <th className="px-4 py-2 whitespace-nowrap">
                                  <div className="font-semibold text-left">
                                    Meals
                                  </div>
                                </th>
                              </>
                            )}
                            <th className="px-4 py-2 whitespace-nowrap">
                              <div className="font-semibold text-left">
                                Action
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="text-sm divide-y divide-gray-100">
                          {currentItems.map((room, index) => (
                            <tr key={index}>
                              {data.accommodation_type === "Flat" ? (
                                <>
                                  <td className="p-2 whitespace-nowrap">
                                    {room.room_number}
                                  </td>
                                  <td className="p-2 whitespace-nowrap">
                                    {room.bhk}
                                  </td>
                                  <td className="p-2 whitespace-nowrap">
                                    {room.occupancy}
                                  </td>
                                  <td className="p-2 whitespace-nowrap">
                                    ₹{room.rate}
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td className="p-2 whitespace-nowrap">
                                    {room.room_number}
                                  </td>
                                  <td className="p-2 whitespace-nowrap">
                                    {room.room_type}
                                  </td>
                                  <td className="p-2 whitespace-nowrap">
                                    {room.occupancy}
                                  </td>
                                  <td className="p-2 whitespace-nowrap">
                                    ₹{room.rate}
                                  </td>
                                  <td className="p-2 whitespace-nowrap">
                                    {room.meals && room.meals.join(", ")}
                                  </td>
                                </>
                              )}
                              <td className="p-2 whitespace-nowrap">
                                <button
                                  onClick={() => handleDelete(room.id)}
                                  className="ml-4 p-4 text-xl text-red-600 bg-red-50 hover:shadow-none rounded-full  duration-300 ease-in outline-none"
                                >
                                  <BiSolidTrashAlt />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewRoom;

import axios from "axios";
import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { BsDownload } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { uid } from "uid";
import moment from "moment-timezone";
import "moment/locale/en-in";

function ActorSheet({ sheetData, onClose, stopClose, artistScene }) {
  const [user, setUser] = useState("");
  const [crew, setCrew] = useState([]);
  const [scriptUrl, setScriptUrl] = useState("");
  const printedArtists = new Set();
  const sceneRefs = useRef([]);
  const { artistId } = useParams();

  const viewCombinedPdf = (e) => {
    e.stopPropagation();
    axios
      .get(`api/v1/artists/combine_schedule_scene_pdf`, {
        params: { schedule_id: sheetData.id },
      })
      .then((response) => {
        window.open(response.data.data.url);
      })
      .catch((error) => console.error(error.message));
  };

  const UID = uid();
  const formattedDateTime = format(
    new Date(sheetData.schedule_date),
    "MMMM dd, yyyy"
  );

  const fetchUser = () => {
    const userDetails = localStorage.getItem("User_Details");
    const OwnerDetail = JSON.parse(userDetails);
    setUser(OwnerDetail);
  };

  const company_name = localStorage.getItem("company_name");

  const fetchCrewDetails = () => {
    axios
      .get(`api/v1/projects/${artistId}/project_crews`)
      .then((response) => {
        setCrew(response.data.project_crews);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchCrewDetails();
    fetchUser();
  }, []);

  const convertToIST = (utcDateStr) => {
    return moment.utc(utcDateStr).tz("Asia/Kolkata").format("hh:mm A");
  };

  const artistScenesMap = sheetData.artists_details.details.reduce(
    (acc, artist) => {
      const scene = sheetData.scenes.find((s) => s.id === artist.scene_id);
      const sceneInfo = `${scene.scene_number}${scene.sub_scene}`;
      const costumeInfo = artist.artist_costume;

      if (!acc[artist.artist_id]) {
        acc[artist.artist_id] = { artist, scenes: [], costumes: [] };
      }
      acc[artist.artist_id].scenes.push(sceneInfo);
      acc[artist.artist_id].costumes.push(costumeInfo);

      return acc;
    },
    {}
  );
  function convertTo12HourFormat(time) {
    const [hours, minutes] = time.split(":").map(Number);
    const period = hours >= 12 ? "PM" : "AM";
    const adjustedHours = hours % 12 || 12; // Convert 0 (midnight) to 12
    return `${adjustedHours}:${
      minutes < 10 ? "0" + minutes : minutes
    } ${period}`;
  }

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 overflow-scroll "
      onClick={onClose}
    >
      <div className="flex flex-col items-center justify-center my-auto z-50 ">
        <div className="flex flex-row mt-5">
          <div className="flex justify-start items-start">
            <button
              onClick={viewCombinedPdf}
              className=" bg-[#1d5b79]  text-white px-3 py-2 shadow-2xl rounded-full hover:bg-[#6fdddd] ease-in-out duration-300 hover:shadow-none flex items-center"
            >
              <span className="font-semibold ml-2">View Script</span>
            </button>
          </div>
        </div>
        <div
          ref={sceneRefs}
          onClick={stopClose}
          className="bg-white p-24 rounded shadow-md my-5 h-[310mm] w-[210mm] mb-10 sm:mt-5 mt-5 mr-5 text-[8px] font-sans"
        >
          <div className="border border-gray-200">
            <section className="flex border-b border-gray-200">
              <div className="flex flex-col p-2 items-start justify-start border-r border-gray-200  h-auto w-1/3">
                <h1 className="font-bold  text-sm">{user.projectName}</h1>
                <h1 className="font-semibold text-gray-600 text-xs">
                  {user.projectType}
                </h1>
                <h1 className="font-bold text-sm">{company_name}</h1>
              </div>
              <div className="flex flex-col items-start justify-start border-r border-gray-200 h-auto w-1/3">
                <div className="h-1/2 w-full flex items-center justify-center border-b">
                  <h1 className="font-bold text-xs">{sheetData.title}</h1>
                </div>
                <table className="w-full h-full">
                  <tr>
                    <th className="border-r border-b">Min temp</th>
                    <th className="border-r border-b">Max temp</th>
                    <th className="border-r border-b">Sunrise</th>
                    <th className="border-b">Sunset</th>
                  </tr>
                  <tbody className="divide-y divide-gray-100">
                    <tr>
                      <td className="border-r">
                        <div className="text-center">
                          {sheetData.min_temperature}
                        </div>
                      </td>
                      <td className="border-r">
                        <div className="text-center">
                          {sheetData.max_temperature}
                        </div>
                      </td>
                      <td className="border-r">
                        <div className="text-center">
                          {convertToIST(sheetData.sunrise)}
                        </div>
                      </td>
                      <td className="">
                        <div className="text-center">
                          {convertToIST(sheetData.sunset)}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col w-1/3">
                <div className="h-1/2 w-full flex items-center justify-center border-b">
                  <h1 className="font-bold text-xs">{formattedDateTime}</h1>
                </div>
                <table className="w-full h-full">
                  <tr>
                    <th className="border-r border-b">Unit call time</th>
                    <th className="border-r border-b">Call time</th>
                    <th className="border-r border-b">Shoot time</th>
                  </tr>
                  <tbody className="divide-y divide-gray-100">
                    <tr>
                      <td className="border-r">
                        <div className="text-center">
                          {convertToIST(sheetData.unit_call_time)}
                        </div>
                      </td>
                      <td className="border-r">
                        <div className="text-center">
                          {convertToIST(sheetData.call_time)}
                        </div>
                      </td>
                      <td>
                        <div className="text-center">
                          {convertToIST(sheetData.shoot_time)}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
            <section className="w-full">
              <h1 className="flex items-center justify-center border-b bg-red-100 font-bold p-1">
                Location
              </h1>
              <table className="w-full">
                <tr>
                  <th className="p-1 border-r border-b">Location name</th>
                  <th className="p-1 border-r border-b">Location Address</th>
                  <th className="p-1 border-r border-b">
                    Location sub-location
                  </th>
                  <th className="p-1 border-r border-b">Location Brief</th>
                  <th className="p-1 border-b">Location Link</th>
                </tr>
                <tbody className="divide-y divide-gray-100">
                  {sheetData.scenes
                    .sort((a, b) => a.scene_number - b.scene_number)
                    .map((location) => (
                      <tr>
                        <td className="border-r p-1">
                          {location.location_name}
                        </td>
                        <td className="border-r p-1">
                          <div
                            className="text-center"
                            style={{ width: "280px", textAlign: "justify" }}
                          >
                            {location.location_address}
                          </div>
                        </td>
                        <td className="border-r p-1">
                          {location.sub_location}
                        </td>
                        <td className="border-r p-1">
                          {location.location_type} , {location.shift}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </section>
            <section className="w-full">
              <h1 className="flex items-center justify-center border-b bg-fuchsia-100 font-bold p-1">
                Breaks
              </h1>
              <table className="w-full">
                <tr>
                  <th className="p-1 border-r border-b">Breakfast</th>
                  <th className="p-1 border-r border-b">Lunch</th>
                  <th className="p-1 border-r border-b">High tea</th>
                  <th className="p-1 border-b">Dinner</th>
                </tr>
                <tbody className="divide-y divide-gray-100">
                  <tr>
                    <td className="border-r p-1">
                      <div className="text-center">
                        {convertToIST(sheetData.breakfast_time)}
                      </div>
                    </td>
                    <td className="border-r p-1">
                      <div className="text-center">
                        {convertToIST(sheetData.lunch_time)}
                      </div>
                    </td>
                    <td className="border-r p-1">
                      <div className="text-center">
                        {convertToIST(sheetData.snack_time)}
                      </div>
                    </td>
                    <td className="p-1">
                      <div className="text-center">
                        {convertToIST(sheetData.dinner_time)}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
            <section className="w-full">
              <h1 className="flex items-center justify-center border-b bg-sky-100 font-bold p-1">
                Artists
              </h1>
              <table className="w-full">
                <tr>
                  <th className="p-1 border-r border-b">Scene no.</th>
                  <th className="p-1 border-r border-b">Artist</th>
                  <th className="p-1 border-r border-b">Character</th>
                  <th className="p-1 border-r border-b">Artist Role</th>
                  <th className="p-1 border-b border-r">Costume</th>
                  <th className="p-1 border-b border-r">Call time</th>
                  <th className="p-1 border-b border-r">Shoot time</th>
                  <th className="p-1 border-b border-r">Transportation</th>
                </tr>
                <tbody className="divide-y divide-gray-100">
                  {Object.values(artistScenesMap).map(
                    ({ artist, scenes, costumes }, index) => {
                      if (printedArtists.has(artist.artist_id)) {
                        return null; // Skip this artist if already printed
                      }
                      printedArtists.add(artist.artist_id);

                      return (
                        <tr key={index}>
                          <td className="border p-1">
                            <div
                              className="text-center"
                              style={{ width: "100px", textAlign: "justify" }}
                            >
                              {scenes.join(", ")}
                            </div>
                          </td>
                          <td className="border p-1">{artist.artist_name}</td>
                          <td className="border p-1">
                            {artist.character_name}
                          </td>
                          <td className="border p-1">{artist.artist_type}</td>
                          <td className="border p-1">
                            <div
                              className="text-center"
                              style={{ width: "100px", textAlign: "justify" }}
                            >
                              {costumes.join(", ")}
                            </div>
                          </td>
                          <td className="border p-1">
                            {convertTo12HourFormat(artist.call_time)}
                          </td>
                          <td className="border p-1">
                            {convertTo12HourFormat(artist.shoot_time)}
                          </td>
                          <td className="border p-1">
                            {artist.transportation}
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </section>

            <section className="w-full">
              <h1 className="flex items-center justify-center border-b bg-lime-100 font-bold p-1">
                Crowd
              </h1>
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="p-1 border-r border-b">Scene no.</th>
                    <th className="p-1 border-r border-b">Crowd set</th>
                    <th className="p-1 border-r border-b">Crowd type</th>
                    <th className="p-1 border-r border-b">Crowd age</th>
                    <th className="p-1 border-r border-b">Number of crowd</th>
                    <th className="p-1 border-r border-b">Crowd costume</th>
                    <th className="p-1 border-r border-b">Call time</th>
                    <th className="p-1 border-r border-b">Shoot time</th>
                    <th className="p-1 border-b">Description</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-100">
                  {sheetData.crowd_details.details.map((detail, index) => {
                    const scene = sheetData.scenes.find(
                      (s) => s.id === detail.scene_id
                    );
                    return (
                      <tr>
                        <td className="border-r p-1">
                          {scene.scene_number} {scene.sub_scene}
                        </td>
                        <td className="border-r p-1">{index + 1}</td>
                        <td className="border-r p-1">{detail.crowdType}</td>
                        <td className="border-r p-1">{detail.crowdAge}</td>
                        <td className="border-r p-1">{detail.crowdNumber}</td>
                        <td className="border-r p-1">{detail.crowdCostume}</td>
                        <td className="border-r p-1">
                          {convertTo12HourFormat(detail.call_time)}
                        </td>
                        <td className="border-r p-1">
                          {convertTo12HourFormat(detail.shoot_time)}
                        </td>
                        <td className="p-1">
                          <div
                            style={{
                              width: "150px",
                              textAlign: "justify",
                            }}
                          >
                            {detail.description}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </section>

            <section className="w-full">
              <div className="flex flex-col">
                <div className="flex flex-row">
                  <div className="w-1/2">
                    <h1 className="flex items-center justify-center border-b bg-rose-100 font-bold p-1">
                      Action sequence
                    </h1>
                    <table className="w-full">
                      <thead>
                        <tr>
                          <th className="p-1 border-r border-b">Scene no.</th>
                          <th className="p-1 border-r border-b">
                            Action director
                          </th>
                          <th className="p-1 border-r border-b">Action man</th>
                          <th className="p-1 border-r border-b">Costume</th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-100">
                        {sheetData.scenes
                          .sort((a, b) => a.scene_number - b.scene_number)
                          .map((scene) => (
                            <>
                              {scene.action_director && (
                                <tr>
                                  <td className="p-1 border-r">
                                    {scene.scene_number} {scene.sub_scene}
                                  </td>
                                  <td className="p-1 border-r">
                                    {scene.action_director}
                                  </td>
                                  <td className="p-1 border-r">
                                    {scene.no_of_action_man}
                                  </td>
                                  <td className="p-1 border-r">
                                    {scene.action_man_costume}
                                  </td>
                                </tr>
                              )}
                            </>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="w-1/2">
                    <h1 className="flex items-center justify-center border-b bg-blue-100 font-bold p-1">
                      Dance sequence
                    </h1>
                    <table className="w-full">
                      <thead>
                        <tr>
                          <th className="p-1 border-r border-b">Scene no.</th>
                          <th className="p-1 border-r border-b">
                            Choreographer
                          </th>
                          <th className="p-1 border-r border-b">Dancers</th>
                          <th className="p-1 border-r border-b">Costume</th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-100">
                        {sheetData.scenes
                          .sort((a, b) => a.scene_number - b.scene_number)
                          .map((scene) => (
                            <>
                              {scene.dance_choreographer && (
                                <tr>
                                  <td className="p-1 border-r">
                                    {scene.scene_number} {scene.sub_scene}
                                  </td>
                                  <td className="p-1 border-r">
                                    {scene.dance_choreographer}
                                  </td>
                                  <td className="p-1 border-r">
                                    {scene.no_of_dancers}
                                  </td>
                                  <td className="p-1 border-r">
                                    {scene.dancers_costume}
                                  </td>
                                </tr>
                              )}
                            </>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="flex flex-row">
                  <div className="w-1/2">
                    <h1 className="flex items-center justify-center border-b bg-orange-100 font-bold p-1">
                      VFX
                    </h1>
                    <table className="w-full">
                      <thead>
                        <tr>
                          <th className="p-1 border-r border-b">Scene no.</th>
                          <th className="p-1 border-r border-b">VFX</th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-100">
                        {sheetData.scenes
                          .sort((a, b) => a.scene_number - b.scene_number)
                          .map((scene) => (
                            <>
                              {scene.vfx && (
                                <tr>
                                  <td className="p-1 border-r border-b">
                                    {scene.scene_number} {scene.sub_scene}
                                  </td>
                                  <td className="p-1 border-r border-b">
                                    {scene.vfx}
                                  </td>
                                </tr>
                              )}
                            </>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="w-1/2">
                    <h1 className="flex items-center justify-center border-b bg-emerald-100 font-bold p-1">
                      Lights
                    </h1>
                    <table className="w-full">
                      <thead>
                        <tr>
                          <th className="p-1 border-r border-b">Scene no.</th>
                          <th className="p-1 border-r border-b">VFX</th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-100">
                        {sheetData.scenes
                          .sort((a, b) => a.scene_number - b.scene_number)
                          .map((scene) => (
                            <>
                              {scene.type_of_light && (
                                <tr>
                                  <td className="p-1 border-r border-b">
                                    {scene.scene_number} {scene.sub_scene}
                                  </td>
                                  <td className="p-1 border-r border-b">
                                    {scene.type_of_light}
                                  </td>
                                </tr>
                              )}
                            </>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="flex flex-row">
                  <div className="w-full">
                    <h1 className="flex items-center justify-center border-b bg-indigo-100 font-bold p-1">
                      Extra Property
                    </h1>
                    <table className="w-full">
                      <thead>
                        <tr>
                          <th className="p-1 border-r border-b">Scene no.</th>
                          <th className="p-1 border-r border-b">
                            Production property
                          </th>
                          <th className="p-1 border-r border-b">
                            Art property
                          </th>
                          <th className="p-1 border-r border-b">
                            Make up property
                          </th>
                        </tr>
                      </thead>
                      {sheetData.scenes
                        .sort((a, b) => a.scene_number - b.scene_number)
                        .map((scene) => (
                          <>
                            <tr>
                              <td className="p-1 border-r border-b">
                                {scene.scene_number} {scene.sub_scene}
                              </td>
                              <td className="p-1 border-r border-b">
                                <div
                                  style={{
                                    width: "150px",
                                    textAlign: "justify",
                                  }}
                                >
                                  {scene.production_team_property
                                    ? scene.production_team_property
                                    : "N/A"}
                                </div>
                              </td>
                              <td className="p-1 border-r border-b">
                                <div
                                  style={{
                                    width: "150px",
                                    textAlign: "justify",
                                  }}
                                >
                                  {scene.art_department_property
                                    ? scene.art_department_property
                                    : "N/A"}
                                </div>
                              </td>
                              <td className="p-1 border-r border-b">
                                <div
                                  style={{
                                    width: "150px",
                                    textAlign: "justify",
                                  }}
                                >
                                  {scene.makeup_department_property
                                    ? scene.makeup_department_property
                                    : "N/A"}
                                </div>
                              </td>
                            </tr>
                          </>
                        ))}
                    </table>
                  </div>
                </div>
              </div>
            </section>
            <section className="w-full">
              <h1 className="flex items-center justify-center border-b bg-yellow-100 font-bold p-1">
                Extras
              </h1>
              <table className="w-full">
                {sheetData.dolly_panther && (
                  <tr>
                    <th className="p-1 border-r border-b">Dolly Panther</th>
                    <td className="p-1 border-b">{sheetData.dolly_panther}</td>
                  </tr>
                )}
                {sheetData.drone && (
                  <tr>
                    <th className="p-1 border-r border-b">Drone</th>
                    <td className="p-1 border-b">{sheetData.drone}</td>
                  </tr>
                )}
                {sheetData.extra_camera_setup && (
                  <tr>
                    <th className="p-1 border-r border-b">
                      Extra camera setup
                    </th>
                    <td className="p-1 border-b">
                      {sheetData.extra_camera_setup}
                    </td>
                  </tr>
                )}
                {sheetData.sound && (
                  <tr>
                    <th className="p-1 border-r border-b">Sound</th>
                    <td className="p-1 border-b">{sheetData.sound}</td>
                  </tr>
                )}
                {sheetData.vehicle && (
                  <tr>
                    <th className="p-1 border-r border-b">Vehicle</th>
                    <td className="p-1 border-b">{sheetData.vehicle}</td>
                  </tr>
                )}
                {sheetData.vanity_van && (
                  <tr>
                    <th className="p-1 border-r border-b">Vanity van</th>
                    <td className="p-1 border-b">{sheetData.vanity_van}</td>
                  </tr>
                )}
                {sheetData.extra_equipment && (
                  <tr>
                    <th className="p-1 border-r border-b">Extra equipment</th>
                    <td className="p-1 border-b">
                      {sheetData.extra_equipment}
                    </td>
                  </tr>
                )}
              </table>
            </section>
            <section className="w-full">
              {sheetData.remarks && (
                <h1 className="flex items-center justify-center border-b bg-amber-100 font-bold p-1">
                  {sheetData.remarks}
                </h1>
              )}
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActorSheet;

import React, { useState } from "react";
import { MdDelete, MdModeEdit } from "react-icons/md";
import PackageFeature from "../Modal/PackageFeature";

function PackageTable({ packageData, openUpdateModal, OpenDeleteHandler }) {
  const [view, setView] = useState(false);
  const [feature, setFeature] = useState(null);

  const viewFeature = (id) => {
    const getFeature = packageData.find((Package) => Package.id === id);
    setFeature(getFeature);
    setView(true);
  };

  const closeFeature = () => {
    setFeature(null);
    setView(false);
  };

  return (
    <>
      <div className="flex flex-col h-full">
        <div className="w-full mx-auto bg-white  rounded-sm border border-gray-200">
          <header className="flex sm:flex-row flex-col  justify-between px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold text-gray-800">Packages</h2>
          </header>
          <div className="p-3">
            <div className="overflow-x-auto">
              <table className="table-auto w-full">
                <thead className="text-xs font-semibold uppercase text-gray-600 bg-[#eceff1]">
                  <tr>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Package name
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Package price
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Package details
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody className="text-sm divide-y divide-gray-100">
                  {packageData.map((Package) => (
                    <tr>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">{Package.name}</div>
                      </td>
                      <td className="p-2">
                        <div className="text-left">{Package.price}</div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">
                          <button
                            onClick={() => viewFeature(Package.id)}
                            className="font-bold hover:underline duration-200 ease-in"
                          >
                            View
                          </button>
                        </div>
                      </td>

                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">
                          <button
                            onClick={() => openUpdateModal(Package.id)}
                            className="p-4 mr-4 text-xl text-green-600 border-none hover:shadow-none rounded-full outline-none bg-green-50  duration-300 ease-in"
                          >
                            <MdModeEdit />
                          </button>
                          <button
                            onClick={() => OpenDeleteHandler(Package.id)}
                            className=" p-4 text-xl text-red-600 border-none hover:shadow-none rounded-full outline-none bg-red-50  duration-300 ease-in"
                          >
                            <MdDelete />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {view && <PackageFeature feature={feature} closeFeature={closeFeature} />}
    </>
  );
}

export default PackageTable;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ErrorPage from "../ErrorPage";
import Loader from "../../Loader/loader";
import AccommodationAccountTable from "../../components/AccountComponents/AccommodationAccountTable";
import toast from "react-hot-toast";

function AccommodationAccount() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [accommodation, setAccommodation] = useState([]);
  const { projectId } = useParams();

  const getAccommodation = () => {
    axios
      .get(`api/v1/accommodation_assignments`, {
        params: {
          project_id: projectId,
        },
      })
      .then((res) => {
        setAccommodation(res.data.assigned_accommodations);
        setLoading(true);
      })
      .catch((err) => {
        setError(true);
        console.log(err);
      });
  };

  useEffect(() => {
    getAccommodation();
  }, []);

  const handleAccommodationStatus = (status, id, amount) => {
    const loader = toast.loading(`${status.toUpperCase()} ...`);
    axios
      .put(
        `api/v1/accommodation_assignments/${id}/change_accommodation_assignment_status`,
        {
          accommodation_assignment: { status: status, total_amount: amount },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message);
          toast.dismiss(loader);
          getAccommodation();
        }
      })
      .catch((err) => {
        console.log(err);
        toast.dismiss(loader);
        toast.error(
          err.response.data.errors || err.response.data.message || err.message
        );
      });
  };

  return (
    <div>
      {error ? (
        <ErrorPage />
      ) : (
        <div>
          {!loading ? (
            <Loader />
          ) : (
            <div className="flex flex-col sm:flex sm:flex-col p-5">
              <div className="flex sm:items-start items-center justify-center sm:justify-start xl:ml-20 2xl:ml-20 sm:ml-20 ml-0 sm:mt-0 mt-5">
                <h1 className="text-3xl font-semibold sm:tracking-widest tracking-tight">
                  Accommodation Account
                </h1>
              </div>
              <div className="flex items-center justify-center  xl:ml-20 2xl:ml-20 lg:ml-20 md:ml-20 sm:ml-20 ml-0">
                <div className="w-full mt-5">
                  <AccommodationAccountTable
                    data={accommodation}
                    handleAccommodationStatus={handleAccommodationStatus}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default AccommodationAccount;

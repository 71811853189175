import React, { useEffect, useRef, useState } from "react";
import Navbar from "./Components/Navbar";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import PricingComponent from "./Components/PricingComponent";
import axios from "axios";
import { TfiNotepad } from "react-icons/tfi";
import { GiPapers } from "react-icons/gi";
import { FaRupeeSign, FaTools, FaUser, FaUserFriends } from "react-icons/fa";
import { MdTimeline } from "react-icons/md";
import { FaKey, FaLock, FaMoneyBillWave } from "react-icons/fa6";
import { AiFillSchedule } from "react-icons/ai";
import Footer from "./Components/Footer";
import Hero from "../../assets/image/Mock-up.png";
import { IoKeyOutline } from "react-icons/io5";
import SecureLS from "secure-ls";

function Home() {
  const navigate = useNavigate();
  const ls = new SecureLS();
  const [packageData, setPackageData] = useState([]);

  useEffect(() => {
    const access_token = ls.get("access_token");
    const userRole = ls.get("role");
    const projectId = ls.get("project_id");
    if (access_token && userRole === "artist") {
      navigate("/actorDashboard");
    } else if (userRole === "user" && access_token) {
      navigate("/project");
    } else if (userRole === "crew" && access_token) {
      navigate(`/projectoverview/${projectId}`);
    } else if (userRole === "superAdmin") {
      navigate("/Super-Admin");
    } else {
      navigate("/");
    }
  }, []);

  const getPackage = () => {
    axios
      .get(`api/v1/packages`)
      .then((response) => {
        setPackageData(response.data.packages);
      })
      .catch((err) => console.log(err.message));
  };

  const featureData = [
    {
      id: 1,
      name: "Call Sheet Generate",
      icon: <TfiNotepad className="h-8 w-8 text-[#1d5b79]" />,
      description:
        "Easily create detailed call sheets for each day of production.",
    },
    {
      id: 2,
      name: "Tracking Expenses",
      icon: <FaMoneyBillWave className="h-8 w-8 text-[#1d5b79]" />,
      description:
        "Monitor expenses meticulously. This feature allows you to keep a record of all financial transactions related to your film projects",
    },
    {
      id: 3,
      name: "Scene Scheduling",
      icon: <AiFillSchedule className="h-8 w-8 text-[#1d5b79]" />,
      description:
        "Customize scheduling preferences while the software auto-generates shooting days",
    },
    {
      id: 4,
      name: "Script Breakdown",
      icon: <GiPapers className="h-8 w-8 text-[#1d5b79]" />,
      description:
        'Introducing the revolutionary "Script Breakdown" feature in our Film Management software! Get ready to streamline your production process like never before.',
    },
    {
      id: 5,
      name: "Film Crew Management",
      icon: <FaUser className="h-8 w-8 text-[#1d5b79]" />,
      description:
        "Crew management software organizes your production’s contacts in one easy-to-use interface.",
    },
    {
      id: 6,
      name: "Project timeline",
      icon: <MdTimeline className="h-8 w-8 text-[#1d5b79]" />,
      description:
        "Create and manage shooting schedules, rehearsals, and post-production timelines. Avoid conflicts and optimize resource allocation.",
    },
  ];

  const WhyusData = [
    {
      id: 1,
      name: "User-Friendly Interface",
      icon: <FaUserFriends className="h-12 w-12 text-[#1d5b79]" />,
      detail:
        "Intuitive design makes it easy for anyone to navigate and use the software, saving you time and frustration.",
    },
    {
      id: 2,
      name: "Collaborative Tools",
      icon: <FaTools className="h-12 w-12 text-[#1d5b79]" />,
      detail:
        "Foster teamwork and communication with built-in collaboration features. Keep everyone informed and coordinated throughout the production process.",
    },
    {
      id: 3,
      name: "Secure and Reliable",
      icon: <FaLock className="h-12 w-12 text-[#1d5b79]" />,
      detail:
        "Rest assured knowing your data is secure and accessible whenever you need it. Our software is hosted on reliable servers with robust backup systems in place.",
    },
  ];

  useEffect(() => {
    getPackage();
  }, []);
  return (
    <div>
      <Navbar />
      <div className="bg-[#1d5b79] text-white">
        <section className="container mx-auto py-12 md:py-24 grid grid-cols-1 md:grid-cols-2 gap-8 items-center ">
          <div className="space-y-4 px-4">
            <h1 className="text-4xl font-bold">CinemaPro</h1>
            <p className="text-lg text-[#a9c8e2]">
              Welcome to the CinemaPro! Our film management platform is your
              ticket to effortless movie organization. Dive into a world of
              cinematic wonders where you can curate your personal film
              collection with style and ease.
            </p>
            <Button
              name={"Get started"}
              clickFunction={() => navigate("/signup")}
            />
          </div>
          <div className="px-4">
            <img
              alt="Hero Image"
              className="rounded-lg "
              height="400"
              src={Hero}
              width="600"
            />
          </div>
        </section>
      </div>
      <section className="bg-[#a9c8e2] py-12 md:py-24 lg:py-32 flex flex-col items-center justify-center">
        <div className="space-y-4 text-center mb-10">
          <h2 className="text-5xl font-bold text-[#081921] flex ">
            <span className="mr-1">
              <IoKeyOutline />
            </span>
            Key Features
          </h2>
        </div>
        <div className="container px-4 md:px-6">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {featureData.map((feature) => (
              <div className="bg-white rounded-xl shadow-md p-6 space-y-4 hover:shadow-xl duration-300 ease-in-out hover:scale-105 cursor-pointer">
                <div>{feature.icon}</div>
                <h3 className="text-xl font-semibold">{feature.name}</h3>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="bg-[#fbffff] py-12 md:py-24" id="WhyUs">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="space-y-4 px-4">
            <h2 className="text-3xl font-bold text-[#1d5b79]">
              Why Choose CinemaPro?
            </h2>
            <p className="text-gray-700">
              Our film management platform offers a range of features to help
              you streamline your production process and achieve your creative
              vision.
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 px-4">
            {WhyusData.map((data) => (
              <div className="space-y-2">
                <div>{data.icon}</div>
                <h3 className="text-xl font-bold">{data.name}</h3>
                <p className="text-gray-700">{data.detail}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section
        className="container mx-auto py-12 md:py-24 bg-[#fbffff]"
        id="Pricing"
      >
        <div className="space-y-4 text-center flex items-center justify-center flex-col">
          <h2 className="text-5xl font-bold text-[#1d5b79] flex">
            <span className="mr-1">
              <FaRupeeSign />
            </span>
            Pricing
          </h2>
          <p className="text-gray-700">
            Choose the plan that best fits your film production needs.
          </p>
        </div>
        <div className="flex items-center justify-center px-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-8 ">
            {packageData
              .sort((a, b) => a.price - b.price)
              .map((Package) => (
                <PricingComponent
                  name={Package.name}
                  price={Package.price}
                  details={Package.package_details.details}
                />
              ))}
          </div>
        </div>
      </section>
      <div id="contact">
        <Footer />
      </div>
    </div>
  );
}

export default Home;

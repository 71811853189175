import { ErrorMessage, Field } from "formik";
import React from "react";

function FormikSelect({
  label,
  name,
  selectedOption,
  optionData,
  onChange,
  disable,
  valueProperty = "name",
  labelProperty = "name",
  label2propery,
  brackets,
}) {
  return (
    <div>
      <label className="block mb-2 text-sm text-gray-600 ">{label}</label>
      <Field
        name={name}
        as="select"
        onChange={onChange}
        disabled={disable}
        className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#1d5b79]  focus:ring-[#1d5b79] focus:outline-none focus:ring focus:ring-opacity-40"
      >
        <option selected value="" disabled>
          {selectedOption}
        </option>
        {optionData.map((option, index) => (
          <option key={index} value={option[valueProperty]}>
            {option[labelProperty]}
            {label2propery
              ? brackets
                ? ` (${option[label2propery]})`
                : option[label2propery]
              : ""}
          </option>
        ))}
      </Field>
      <ErrorMessage
        name={name}
        className="text-red-500 text-sm mt-1"
        component="div"
      />
    </div>
  );
}

export default FormikSelect;

import { Form, Formik } from "formik";
import React from "react";
import FormikSelect from "../Input/FormikSelect";
import { partnerType } from "../Data";
import FormikInput from "../Input/FormikInput";
import { RxCross1 } from "react-icons/rx";
import Button from "../Button/Button";

function UpdatePartnerForm({ data, onSubmit, onClose, validationSchema }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center overflow-auto bg-black bg-opacity-50 z-50">
      <div className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto ">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-xl w-full mx-auto">
          <div className="flex items-end justify-end">
            <button
              onClick={onClose}
              className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full "
            >
              <RxCross1 />
            </button>
          </div>
          <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
            {data.name}
          </h2>
          <Formik
            initialValues={{
              partner_type: data.partner_type || "",
              name: data.name || "",
              contact_number: data.contact_number || "",
              email: data.email || "",
              gst_number: data.gst_number || "",
              pan_number: data.pan_number || "",
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ values, setFieldValue, errors }) => (
              <Form>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                  <div>
                    <FormikSelect
                      label={"Partner type"}
                      selectedOption={"Partner type"}
                      name={"partner_type"}
                      optionData={partnerType}
                      onChange={(e) =>
                        setFieldValue("partner_type", e.target.value)
                      }
                    />
                  </div>
                  <div>
                    <FormikInput
                      name={"name"}
                      label={"Name"}
                      placeholder={"MovieFlix"}
                      type={"text"}
                    />
                  </div>
                  <div>
                    <FormikInput
                      name={"contact_number"}
                      label={"Contact Number"}
                      placeholder={"987456****"}
                      type={"tel"}
                    />
                  </div>
                  <div>
                    <FormikInput
                      name={"email"}
                      label={"Email"}
                      placeholder={"movieflix@example.com"}
                      type={"email"}
                    />
                  </div>
                  <div>
                    <FormikInput
                      name={"pan_number"}
                      label={"PAN Number"}
                      placeholder={"ABCDE1234F"}
                      type={"text"}
                    />
                  </div>
                  <div>
                    <FormikInput
                      name={"gst_number"}
                      label={"GST Number"}
                      placeholder={"12ABCDE1234F"}
                      type={"text"}
                    />
                  </div>
                </div>
                <div className="sm:mt-4 mt-2">
                  <Button type={"submit"} name={"Update"} />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default UpdatePartnerForm;

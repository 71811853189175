import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import * as yup from "yup";
import FormikSelect from "../Input/FormikSelect";
import axios from "axios";
import FormikInput from "../Input/FormikInput";
import Button from "../Button/Button";

function BudgetModal({ setView ,handleAddBudget}) {
  const [department, setDepartment] = useState([]);
  const validationSchema = yup.object().shape({
    department: yup.string().required("Department is required."),
    annual_budget: yup.number().required("Annual budget is required."),
  });
  const getDepartmentData = () => {
    axios
      .get(`api/v1/departments`)
      .then((response) => {
        setDepartment(response.data.departments);
      })
      .catch((error) => console.log(error.message));
  };
  useEffect(() => {
    getDepartmentData();
  }, []);
  return (
    <div className="fixed inset-0 flex items-center justify-center overflow-auto bg-black bg-opacity-50 z-50">
      <div className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto ">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-md w-full mx-auto">
          <div className="flex items-center justify-between">
            <div className="flex items-start justify-start">
              <h2 className="text-lg sm:text-2xl font-semibold  text-[#1d5b79]">
                Budget
              </h2>
            </div>
            <div className="flex items-end justify-end">
              <button
                className="bg-[#1d5b79] p-2 sm:-mt-6 sm:-mr-4 -mt-3 -mr-2 text-lg text-[#fbffff] rounded-full "
                onClick={() => setView(false)}
              >
                <RxCross1 />
              </button>
            </div>
          </div>
          <Formik
            initialValues={{
              department: "",
              annual_budget: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleAddBudget}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                  <div>
                    <label className="block mb-2 text-sm text-gray-600 ">
                      Department
                    </label>
                    <Field
                      name="department"
                      as="select"
                      onChange={(e) =>
                        setFieldValue("department", e.target.value)
                      }
                      className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#1d5b79]  focus:ring-[#1d5b79] focus:outline-none focus:ring focus:ring-opacity-40"
                    >
                      <option selected value="">
                        Select department
                      </option>
                      {department.map((option, index) => (
                        <option value={option.id}>{option.name}</option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="department"
                      className="text-red-500 text-sm mt-1"
                      component="div"
                    />
                  </div>
                  <div>
                    <FormikInput
                      label={"Department budget"}
                      placeholder={"12xxxxx"}
                      name={"annual_budget"}
                      type={"tel"}
                    />
                  </div>
                  <div className="mt-2 sm:mt-4">
                    <Button type={"submit"} name={"Add"} />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default BudgetModal;

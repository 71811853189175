import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikSelect from "../Input/FormikSelect";
import FormikInput from "../Input/FormikInput";
import { RxCross1 } from "react-icons/rx";
import Button from "../Button/Button";
import File from "../Input/File";
import { durationOptions } from "../Data";

function ExpensesForm({
  Departments,
  expenseTypeData,
  handleBackdropClick,
  stopClose,
  handleAddExpense,
  handleBillHandler,
  visible,
}) {
  const typeOptions = [
    { name: "Transportation" },
    { name: "Generator Diesel" },
    { name: "Location/Reccey" },
    { name: "Art Property" },
    { name: "Production Property" },
    { name: "Snack/Lunch/Dinner" },
    { name: "Other" },
  ];

 

  const handleExpenseType = (name, setFieldValue) => {
    const selectedExpense = expenseTypeData.find(
      (expense) => expense.name === name
    );
    setFieldValue("type", selectedExpense.name)
    setFieldValue("type_id", selectedExpense.id);
  };

  return (
    <div>
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
        <div
          className="flex items-center justify-center z-50"
          onClick={stopClose}
        >
          <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-md w-full mx-auto">
            <div className="flex items-end justify-end">
              <button
                className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full "
                onClick={handleBackdropClick}
              >
                <RxCross1 />
              </button>
            </div>
            <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
              Expense
            </h2>

            <Formik
              initialValues={{
                type: "",
                type_id:"",
                otherType: "",
                description: "",
                duration: "",
                date: "",
                amount: "",
                noBillReason: "",
              }}
              validationSchema={Yup.object({
                type: Yup.string().required("Type is required"),
                description: Yup.string().required("Description is required"),
                duration: Yup.string().required("Duration is required"),
                date: Yup.date().required("Date is required"),
                amount: Yup.number().required("Amount is required"),
              })}
              onSubmit={handleAddExpense}
            >
              {({ values, setFieldValue }) => (
                <Form encType="multipart/form-data">
                  <div className="mt-2 sm:mt-4">
                    <FormikSelect
                      name="type"
                      label="Type"
                      selectedOption="Type"
                      optionData={expenseTypeData}
                      onChange={(e) =>
                        handleExpenseType(e.target.value, setFieldValue)
                      }
                    />
                  </div>

                  {/* Render otherType input if visible is true */}
                  {visible && (
                    <div className="mt-2 sm:mt-4">
                      <FormikInput
                        name="type"
                        label="Other Type"
                        type="text"
                        placeholder="Other Type"
                      />
                    </div>
                  )}

                  <div className="mt-2 sm:mt-4">
                    <FormikInput
                      name="description"
                      label="Description"
                      type="text"
                      placeholder="Description"
                    />
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                    <div>
                      <FormikSelect
                        name="duration"
                        label="Duration"
                        selectedOption="Duration"
                        optionData={durationOptions}
                        onChange={(e) =>
                          setFieldValue("duration", e.target.value)
                        }
                      />
                    </div>
                    <div>
                      <FormikInput name="date" label="Date" type="date" />
                    </div>
                  </div>

                  <div className="mt-2 sm:mt-4">
                    <FormikInput
                      name="amount"
                      label="Amount"
                      type="number"
                      placeholder="Amount"
                    />
                  </div>
                  <div className="mt-2 sm:mt-4">
                    <File
                      label="Attach Bill"
                      onChangeFunction={handleBillHandler}
                    />
                  </div>

                  <div className="mt-2 sm:mt-4">
                    <FormikInput
                      name="noBillReason"
                      label="No Bill Reason"
                      type="text"
                      placeholder="No Bill Reason"
                    />
                  </div>

                  <div className="mt-2 sm:mt-4">
                    <Button type="submit" name="Add Expense" />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExpensesForm;

import React, { useState } from "react";
import usePagination from "../Pagination/usePagination";
import Pagination from "../Pagination/Pagination";

function CallSheetContactTable({ data, revokeAccess }) {
  const { currentItems, currentPage, handlePageChange, totalPages } =
    usePagination(data, 5);
  return (
    <div>
      <div className="flex flex-col h-full">
        <div className="w-full mx-auto bg-white  rounded-sm border border-gray-200">
          <header className="flex sm:flex-row flex-col  justify-between px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold text-gray-800">Call Sheet Contact</h2>
            <Pagination
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              totalPages={totalPages}
            />
          </header>
          <div className="p-3">
            <div className="overflow-x-auto">
              <table className="table-auto w-full">
                <thead className="text-xs font-semibold uppercase text-gray-600 bg-[#eceff1]">
                  <tr>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Name</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Sub department
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Contact number
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody className="text-sm divide-y divide-gray-100">
                  {currentItems.map((crew) => (
                    <tr>
                      <td className="p-2 whitespace-nowrap">
                        {crew.full_name}
                      </td>
                      <td className="p-2">
                        <div className="text-left uppercase">
                          {crew.sub_category}
                        </div>
                      </td>
                      <td className="p-2">
                        {crew.is_contact_visible_to_callsheet === true ? (
                          <div className="text-left uppercase">
                            {crew.contact_number}
                          </div>
                        ) : (
                          <div className="text-left uppercase">N/A</div>
                        )}
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">
                          <button
                            onClick={() => revokeAccess(crew.id)}
                            className="bg-red-50 text-red-500 font-bold px-3 py-2  duration-300 ease-out hover:shadow-none rounded-2xl"
                          >
                            Revoke
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CallSheetContactTable;

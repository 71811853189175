import React from "react";

function IconButton({ type, disable, clickFunction, name, icon }) {
  return (
    <button
      type={type}
      disabled={disable}
      onClick={clickFunction}
      className="bg-white text-[#1d5b79] rounded-lg text-lg font-semibold py-2 px-4 border flex flex-row items-center justify-center border-[#1d5b79] shadow-xl transition-all duration-300 ease-in-out hover:-translate-y-1 hover:-translate-x-0.5 hover:shadow-[2px_5px_0_0_#1d5b79] active:translate-y-0.5 active:translate-x-0.25 active:shadow-none"
    >
      {icon}
      {name && <span className="ml-2">{name}</span>}
    </button>
  );
}

export default IconButton;

import { Field } from "formik";
import React from "react";

function FormikRadio({ label, value, name, id, onChange }) {
  return (
    <div className="flex flex-row">
      <label htmlFor={label} className="text-sm text-gray-800 mr-1 ">
        {label}
      </label>
      <Field
        type="radio"
        name={name}
        value={value}
        id={id}
        onChange={onChange}
      />
    </div>
  );
}

export default FormikRadio;

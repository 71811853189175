import React from "react";
import { TiTick } from "react-icons/ti";

function PricingComponent({ name, price, details, userPackage, selectPlan }) {
  return (
    <div>
      <div className="max-w-xl w-80 rounded-xl bg-[#1d5b79] p-10 overflow-hidden shadow-lg hover:shadow-none ease-in duration-300">
        {name === userPackage && (
          <div className="bg-white -rotate-45 w-40 h-9 -mt-5 -ml-20 ">
            <div className="flex items-center justify-center">
              <h1 className=" text-center text-xs mt-3 text-[#1d5b79] font-semibold">
                Current package
              </h1>
            </div>
          </div>
        )}
        <h3 className="text-2xl font-semibold leading-8 text-[#fbffff] ">{name}</h3>
        <p className="mt-6 flex items-baseline gap-x-1">
          <span className="text-4xl font-bold tracking-wide text-[#fbffff]">
            {price === 0 || price === null ? "FREE" : `₹ ${price}`}
          </span>
        </p>
        <ul className="mt-8 space-y-3 leading-6 h-[290px] overflow-auto text-[#fbffff] ">
          {details &&
            details.map((detail) => (
              <li className="flex gap-x-2 text-base font-semibold">
                <TiTick className="mt-1" />
                {detail}
              </li>
            ))}
        </ul>
      </div>
      
    </div>
  );
}

export default PricingComponent;

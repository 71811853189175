import React, { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import File from "../Input/File";
import Button from "../Button/Button";
import Input from "../Input/Input";
import * as Yup from "yup";
import { ErrorMessage, Form, Formik } from "formik";

function UploadScriptForm({
  handleScriptChange,
  handleViewChange,
  handleUploadScriptChange,
}) {
  const validationSchema = Yup.object().shape({
    file: Yup.mixed().required("Please select a file."),
  });

  return (
    <div>
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="flex items-center justify-center z-50">
          <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-lg w-full mx-auto">
            <div className="flex items-end justify-end">
              <button
                className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full "
                onClick={handleViewChange}
              >
                <RxCross1 />
              </button>
            </div>
            <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
              Upload Script
            </h2>
            <Formik
              initialValues={{
                file: null,
              }}
              validationSchema={validationSchema}
              onSubmit={handleUploadScriptChange}
            >
              {({ setFieldValue }) => (
                <Form>
                  <div>
                    <label className="block mb-2 text-sm text-gray-600 ">
                      Script
                    </label>
                    <input
                      name="file"
                      accept=".pdf"
                      type="file"
                      onChange={(event) => {
                        setFieldValue("file", event.currentTarget.files[0]);
                      }}
                      className="cursor-pointer block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#1d5b79]  focus:ring-[#1d5b79] focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                    <ErrorMessage
                      name={"file"}
                      className="text-red-500 text-sm mt-1"
                      component="div"
                    />
                  </div>
                  <div className="sm:mt-4 mt-2">
                    <Button type={"submit"} name={"Upload"} />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UploadScriptForm;

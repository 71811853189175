import React, { useContext, useState } from "react";
import { BiSolidTrashAlt } from "react-icons/bi";
import { MdModeEdit } from "react-icons/md";
import { HiddenContext } from "../../Context/HiddenContent";
import usePagination from "../Pagination/usePagination";
import Pagination from "../Pagination/Pagination";

function BudgetTable({ detail, updateView, deleteView }) {
  const { hidden } = useContext(HiddenContext);

  const { currentItems, currentPage, handlePageChange, totalPages } =
    usePagination(detail, 10);

  return (
    <div className="flex flex-col h-full">
      <div className="w-full mx-auto bg-white  rounded-sm border border-gray-200">
        <header className="flex sm:flex-row flex-col  justify-between px-5 py-4 border-b border-gray-100">
          <h2 className="font-semibold text-gray-800">Budget</h2>
          <Pagination
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            totalPages={totalPages}
          />
        </header>
        <div className="p-3">
          <div className="overflow-x-auto">
            <table className="table-auto w-full">
              <thead className="text-xs font-semibold uppercase text-gray-600 bg-[#eceff1]">
                <tr>
                  <th className="px-4 py-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Department</div>
                  </th>
                  <th className="px-4 py-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Budget</div>
                  </th>
                  <th className="px-4 py-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Expense done</div>
                  </th>
                  {hidden ? (
                    <></>
                  ) : (
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Action</div>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className="text-sm divide-y divide-gray-100">
                {currentItems.map((budget) => {
                  return (
                    <tr>
                      <td className="p-2 whitespace-nowrap">
                        {budget.department}
                      </td>
                      <td className="p-2">
                        <div className="text-left">{budget.budget_amount}</div>
                      </td>
                      <td className="p-2">
                        <div className="text-left">
                          {budget.total_approved_expenses}
                        </div>
                      </td>
                      {hidden ? (
                        <></>
                      ) : (
                        <td className="p-2 whitespace-nowrap flex">
                          <div className="text-center">
                            <button
                              onClick={() => updateView(budget)}
                              className="p-4 text-xl text-green-600 border-none hover:shadow-none rounded-full outline-none bg-green-50  duration-300 ease-in"
                            >
                              <MdModeEdit />
                            </button>
                            <button
                              onClick={() => deleteView(budget)}
                              className="ml-4 p-4 text-xl text-red-600 bg-red-50 hover:shadow-none rounded-full  duration-300 ease-in outline-none"
                            >
                              <BiSolidTrashAlt />
                            </button>
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BudgetTable;

import React from "react";
import Navbar from "../LandingPages/Components/Navbar";
import Footer from "../LandingPages/Components/Footer";

function Privacy() {
  const privacyPolicy = [
    {
      title: "Data Collection",
      content:
        "We collect personal information like your name, email, and payment details to create accounts, manage subscriptions, and enhance the app experience.",
    },
    {
      title: "Use of Data",
      content:
        "Your data helps us provide and improve our services. We use it for account management, payment processing, and support. Data may also be used for analytics to enhance the app’s functionality.",
    },
    {
      title: "Third-Party Services",
      content:
        "We may share limited data with trusted third-party providers for payment processing, analytics, and cloud storage. All third-party services comply with data protection standards.",
    },
    {
      title: "Data Storage and Security",
      content:
        "We store your data securely and use encryption to protect personal information. Access to data is restricted to authorised personnel.",
    },
    {
      title: "User Rights",
      content:
        "You have the right to access, update, or delete your data. Contact us at support@softcodingsolutions.com if you have any query.",
    },
    {
      title: "Cookies and Tracking",
      content:
        "Our app may use cookies to enhance user experience. Users can manage cookie settings via their browser.",
    },
    {
      title: "Policy Updates",
      content:
        "This policy may be updated periodically. Significant changes will be communicated to users via email or in-app notifications.",
    },
    {
      title: "Contact Us",
      content:
        "For privacy-related questions, please contact us at support@softcodingsolutions.com.",
    },
  ];

  return (
    <div>
      <Navbar />
      <section className="bg-[#1d5b79] py-20 text-white">
        <div className="container mx-auto px-4 md:px-8">
          <div className="max-w-3xl text-center">
            <h1 className="text-4xl font-bold mb-4">Privacy Policy</h1>
          </div>
        </div>
      </section>
      <section>
        <div className="container mx-auto py-10 items-center justify-center flex md:px-0 px-5">
          <div className="max-w-3xl text-start">
            {privacyPolicy.map((data, index) => (
              <div>
                <h2 className="text-2xl font-bold mb-4">
                  {index + 1}. {data.title}
                </h2>
                <p className="text-lg mb-8">{data.content}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Privacy;

import { ErrorMessage, Form, Formik } from "formik";
import React from "react";
import Button from "../Button/Button";
import FormikInput from "../Input/FormikInput";
import FormikSelect from "../Input/FormikSelect";
import { GenderOption } from "../Data";
import { RxCross1 } from "react-icons/rx";

function SettingArtistUpdateForm({ onClose, selectedArtist, onSubmit }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 overflow-auto bg-black bg-opacity-50 ">
      <div className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto ">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-5xl w-full mx-auto ">
          <div className="flex items-end justify-end">
            <button
              className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full "
              onClick={onClose}
            >
              <RxCross1 />
            </button>
          </div>
          <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
            {selectedArtist.artist_name}
          </h2>
          <div>
            <Formik
              initialValues={{
                artist_name: selectedArtist.artist_name || "",
                gender: selectedArtist.gender || "",
                phone_number: selectedArtist.phone_number || "",
                email: selectedArtist.email || "",
                pan_card_number: selectedArtist.pan_card_number || "",
                bank_account_number: selectedArtist.bank_account_number || "",
                bank_branch: selectedArtist.bank_branch || "",
                bank_name: selectedArtist.bank_name || "",
                ifsc: selectedArtist.ifsc || "",
                gst_number: selectedArtist.gst_number || "",
                aadhar_card: selectedArtist.aadhar_card || "",
                pan_card: selectedArtist.pan_card || "",
                cancel_cheque: selectedArtist.cancel_cheque || "",
              }}
              onSubmit={onSubmit}
            >
              {({ values, setFieldValue, errors }) => (
                <Form>
                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 sm:gap-4 mt-2 sm:mt-4">
                    <div>
                      <FormikInput
                        name={"artist_name"}
                        label={"Artist Name"}
                        type={"text"}
                        placeholder={"Willem Dafoe"}
                      />
                    </div>
                    <div>
                      <FormikSelect
                        name={"gender"}
                        selectedOption={"Gender"}
                        label={"Gender"}
                        optionData={GenderOption}
                        onChange={(e) =>
                          setFieldValue("gender", e.target.value)
                        }
                      />
                    </div>
                    <div>
                      <FormikInput
                        type={"tel"}
                        name={"phone_number"}
                        label={"Contact number"}
                        placeholder={"9584******"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"email"}
                        type={"email"}
                        label={"Email"}
                        placeholder={"willemdafoe@example.com"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"pan_card_number"}
                        type={"text"}
                        label={"PAN number"}
                        placeholder={"ABCTY1234D"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"bank_account_number"}
                        type={"number"}
                        label={"Bank account number"}
                        placeholder={"0112345678"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"bank_branch"}
                        type={"text"}
                        label={"Bank Branch"}
                        placeholder={"Ahmedabad"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"bank_name"}
                        type={"text"}
                        label={"Bank Name"}
                        placeholder={"MoneyZone"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"ifsc"}
                        type={"text"}
                        label={"IFSC Code"}
                        placeholder={"SBIN0011513"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        name={"gst_number"}
                        label={"GST number"}
                        placeholder={"22AAAAA0000A1Z5"}
                        type={"text"}
                      />
                    </div>
                    <div>
                      <label className="block mb-2 text-sm text-gray-600 ">
                        Aadhar card
                      </label>
                      <input
                        name="aadhar_card"
                        type="file"
                        accept=".pdf,image/*"
                        onChange={(event) => {
                          setFieldValue(
                            "aadhar_card",
                            event.currentTarget.files[0]
                          );
                        }}
                        className="cursor-pointer block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#24b7aa]  focus:ring-[#24b7aa] focus:outline-none focus:ring focus:ring-opacity-40"
                      />
                      <ErrorMessage
                        name={"aadhar_card"}
                        className="text-red-500 text-sm mt-1"
                        component="div"
                      />
                    </div>
                    <div>
                      <label className="block mb-2 text-sm text-gray-600 ">
                        PAN card
                      </label>
                      <input
                        name="pan_card"
                        type="file"
                        accept=".pdf,image/*"
                        onChange={(event) => {
                          setFieldValue(
                            "pan_card",
                            event.currentTarget.files[0]
                          );
                        }}
                        className="cursor-pointer block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#24b7aa]  focus:ring-[#24b7aa] focus:outline-none focus:ring focus:ring-opacity-40"
                      />
                      <ErrorMessage
                        name={"pan_card"}
                        className="text-red-500 text-sm mt-1"
                        component="div"
                      />
                    </div>
                    <div>
                      <label className="block mb-2 text-sm text-gray-600 ">
                        Cancel cheque
                      </label>
                      <input
                        name="cancel_cheque"
                        type="file"
                        accept=".pdf,image/*"
                        onChange={(event) => {
                          setFieldValue(
                            "cancel_cheque",
                            event.currentTarget.files[0]
                          );
                        }}
                        className="cursor-pointer block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#24b7aa]  focus:ring-[#24b7aa] focus:outline-none focus:ring focus:ring-opacity-40"
                      />
                      <ErrorMessage
                        name={"cancel_cheque"}
                        className="text-red-500 text-sm mt-1"
                        component="div"
                      />
                    </div>
                  </div>
                  <div className="sm:mt-4 mt-2">
                    <Button name={"Update"} type={"submit"} />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SettingArtistUpdateForm;

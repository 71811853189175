import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import React, { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import Select from "react-select";
import { accomodationType } from "../Data";
import FormikInput from "../Input/FormikInput";
import toast from "react-hot-toast";
import Button from "../Button/Button";
import * as Yup from "yup";

function UpdateAssignAccommodation({
  onClose,
  onSubmit,
  team,
  selectedAccommodation,
}) {
  const validationSchema = Yup.object().shape({
    selectedAccommodationType: Yup.string(),
    start_date: Yup.date().required("Start Date is required"),
    end_date: Yup.date()
      .required("End Date is required")
      .min(Yup.ref("start_date"), "End Date cannot be before Start Date"),
    room_mates: Yup.array().of(
      Yup.object().shape({
        uid: Yup.string().required(),
        id: Yup.string().required(),
        name: Yup.string().required(),
        type: Yup.string().required(),
      })
    ),
  });
  const handleTeamChange = (data, isChecked, values, setFieldValue) => {
    const teamData = values?.room_mates || [];
    const occupancy = values.occupancy || Infinity;

    if (isChecked && teamData.length >= occupancy) {
      toast.error("Cannot add more room mates than the occupancy.");
      return;
    }
    if (isChecked) {
      setFieldValue("room_mates", [
        ...teamData,
        {
          uid: data.uid,
          id: data.id,
          name: data.name,
          type: data.team_type,
        },
      ]);
    } else {
      setFieldValue(
        "room_mates",
        teamData.filter((team) => team.uid !== data.uid)
      );
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center overflow-auto z-50 bg-black bg-opacity-50">
      <div className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md w-full max-w-3xl sm:my-auto my-auto">
          <div className="flex items-end justify-end">
            <button
              className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full"
              onClick={onClose}
            >
              <RxCross1 />
            </button>
          </div>
          <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
            {!selectedAccommodation.room
              ? selectedAccommodation.accommodation.name
              : `${selectedAccommodation.accommodation.name}(${
                  selectedAccommodation.room.room_type
                    ? selectedAccommodation.room.room_type
                    : selectedAccommodation.room.bhk + " " + "BHK"
                }-${selectedAccommodation.room.room_number})`}
          </h2>
          <Formik
            initialValues={{
              selectedAccommodationType:
                selectedAccommodation.accommodation.accommodation_type || "",
              room_id: selectedAccommodation?.room?.id || null,
              start_date: selectedAccommodation.start_date || "",
              end_date: selectedAccommodation.end_date || "",
              room_mates:
                Object.values(selectedAccommodation.assigned_details) || [],
              occupancy: selectedAccommodation?.room?.occupancy || "",
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                  <div>
                    <FormikInput
                      label={"Start date"}
                      name={"start_date"}
                      type={"date"}
                    />
                  </div>
                  <div>
                    <FormikInput
                      label={"End date"}
                      name={"end_date"}
                      type={"date"}
                    />
                  </div>
                </div>
                <FieldArray name="room_mates">
                  {({ push, remove }) => (
                    <div>
                      <label className="block mb-2 text-sm text-gray-600">
                        Team
                      </label>
                      <div className="w-full h-auto max-h-40 rounded-3xl border-[#1d5b79] border-2 p-4  bg-white shadow-lg overflow-y-auto no-scrollbar">
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                          {team.map((data) => (
                            <label
                              key={data.uid}
                              className="flex items-center border-[#1d5b79] border h-auto sm:w-full rounded-xl shadow-lg p-3 cursor-pointer transition-all duration-300 transform hover:scale-105 hover:shadow-2xl bg-gray-50"
                            >
                              <input
                                value={data.id}
                                type="checkbox"
                                className="h-5 w-5 border-[#1d5b79] accent-[#1d5b79] cursor-pointer mr-3"
                                onChange={(e) =>
                                  handleTeamChange(
                                    data,
                                    e.target.checked,
                                    values,
                                    setFieldValue
                                  )
                                }
                                checked={values.room_mates.some(
                                  (team) => team.uid === data.uid
                                )}
                              />
                              <div className="flex flex-col">
                                <h1 className="text-[#1d5b79] capitalize font-medium text-base">
                                  {data.name}
                                </h1>
                                <h2 className="text-xs font-medium text-gray-500">
                                  {data.team_type}
                                </h2>
                              </div>
                            </label>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </FieldArray>
                <div className="sm:mt-4 mt-2">
                  <Button name={"Re-assign"} type={"submt"} />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default UpdateAssignAccommodation;

import React, { useEffect, useRef, useState } from "react";
import Button from "../../components/Button/Button";
import ProjectVendorForm from "../../components/Forms/ProjectVendorForm";
import { useParams } from "react-router-dom";
import axios from "axios";
import ProjectVendorTable from "../../components/Table/ProjectVendorTable";
import toast from "react-hot-toast";
import ProjectVendorUpdateForm from "../../components/UpdateForm/ProjectVendorUpdateForm";
import DeleteModal from "../../Modal/DeleteModal";
import Loader from "../../Loader/loader";
import ErrorPage from "../ErrorPage";
import DownloadButton from "../../components/Button/DownloadButton";
import { useReactToPrint } from "react-to-print";
import AttendanceModal from "../../Modal/AttendanceModal";

function ProjectVendor() {
  const [open, setOpen] = useState(false);
  const [projectVendordata, setProjectVendorData] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [calendarView, setCalendarView] = useState(false);
  const [selectedvendor, setSelectedVendor] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const contentToPrint = useRef();
  const { projectId } = useParams();

  const handleUpdateProjectVendor = (id) => {
    const vendorDetail = projectVendordata.find((vendor) => vendor.id === id);
    setSelectedVendor(vendorDetail);
    setOpenUpdate(true);
  };
  const handleDeleteProjectVendor = (id) => {
    const vendorDetail = projectVendordata.find((vendor) => vendor.id === id);
    setSelectedVendor(vendorDetail);
    setOpenDelete(true);
  };

  const handleAttendanceView = (vendor) => {
    setSelectedVendor({ ...vendor });
    setCalendarView(true);
  };

  const fetchVendors = () => {
    axios
      .get(`api/v1/projects/${projectId}/vendors`)
      .then((response) => {
        setVendorData(response.data.vendors);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const fetchProjectVendors = () => {
    axios
      .get(`api/v1/projects/${projectId}/project_vendors`)
      .then((response) => {
        setProjectVendorData(response.data);
        setLoading(true);
      })
      .catch((error) => {
        console.log(error.message);
        setError(true);
      });
  };

  const addProjectVendor = (value, { resetForm }) => {
    const loader = toast.loading("Adding Vendor", {
      autoClose: false,
    });
    const formData = new FormData();
    formData.append("project_vendor[vendor_id]", value.vendor);
    formData.append("project_venfor[amount_type]", value.amount_type);
    formData.append("project_vendor[amount]", value.amount);
    formData.append("project_vendor[number_of_days]", value.number_of_days);
    formData.append("project_vendor[amount_type]", value.amount_type);
    formData.append("project_vendor[quantity]", value.quantity);

    if (value.nda_doc) {
      formData.append("project_vendor[nda_doc]", value.nda_doc);
    }
    if (value.agreement_doc) {
      formData.append("project_vendor[agreement_doc]", value.agreement_doc);
    }

    axios
      .post(`api/v1/projects/${projectId}/project_vendors`, formData)
      .then((response) => {
        if (response.status === 200) {
          setOpen(false);
          toast.dismiss(loader);
          toast.success(response.data.message);
          resetForm();
          fetchProjectVendors();
        }
      })
      .catch((error) => {
        toast.error(error.message);
        toast.dismiss(loader);
        console.log(error.message);
      });
  };

  const updateProjectVendor = (value, { resetForm }) => {
    const loader = toast.loading("Updating Vendor", {
      autoClose: false,
    });
    const formData = new FormData();

    formData.append("project_venfor[amount_type]", value.amount_type);
    formData.append("project_vendor[amount]", value.amount);
    formData.append("project_vendor[amount_type]", value.amount_type);
    formData.append("project_vendor[number_of_days]", value.number_of_days);

    formData.append("project_vendor[quantity]", value.quantity);

    if (value.nda_doc) {
      formData.append("project_vendor[nda_doc]", value.nda_doc);
    }
    if (value.agreement_doc) {
      formData.append("project_vendor[agreement_doc]", value.agreement_doc);
    }

    axios
      .put(
        `api/v1/projects/${projectId}/project_vendors/${selectedvendor.id}`,
        formData
      )
      .then((response) => {
        if (response.status === 200) {
          setOpenUpdate(false);
          fetchProjectVendors();
          resetForm();
          toast.dismiss(loader);
          setSelectedVendor("");
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        toast.dismiss(loader);
        console.log(error.message);
        toast.error(error.message);
      });
  };

  const deleteProjectVendor = () => {
    const loader = toast.loading("Deleting Vendor", {
      autoClose: false,
    });
    axios
      .delete(
        `api/v1/projects/${projectId}/project_vendors/${selectedvendor.id}`
      )
      .then((response) => {
        if (response.status === 200) {
          setOpenDelete(false);
          fetchProjectVendors();
          setSelectedVendor("");
          toast.dismiss(loader);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.dismiss(loader);
        toast.error(error.message);
      });
  };

  const handleAddAttendance = (value, { resetForm }) => {
    const loader = toast.loading("Adding attendance...");
    axios
      .post(
        `api/v1/projects/${projectId}/attendances?project_vendor_id=${selectedvendor.id}`,
        {
          attendance: {
            ...(selectedvendor.amount_type === "Per Day" && {
              number_of_dates: value.date,
            }),
            ...(selectedvendor.amount_type === "Package" && {
              start_date: value.date[0],
              end_date: value.date[1],
            }),
            ...(value.extra === true && {
              extra_shifts_attributes: value.extra_shift_info,
            }),
          },
        }
      )
      .then((res) => {
        if (res.status === 201) {
          resetForm();
          toast.dismiss(loader);
          toast.success(res.data.message);
          setCalendarView(false);
          fetchProjectVendors();
        }
      })
      .catch((err) => {
        toast.dismiss(loader);
        toast.error(err.message || err.response.data.error);
        console.error(err);
      });
  };
  const handleUpdateAttendance = (value, { resetForm }) => {
    const loader = toast.loading("Adding attendance...");
    axios
      .put(
        `api/v1/projects/${projectId}/attendances/${selectedvendor.attendance[0].id}`,
        {
          attendance: {
            ...(selectedvendor.amount_type === "Per Day" && {
              number_of_dates: value.date,
            }),
            ...(selectedvendor.amount_type === "Package" && {
              start_date: value.date[0],
              end_date: value.date[1],
            }),
            ...(value.extra === true && {
              extra_shifts_attributes: value.extra_shift_info,
            }),
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          resetForm();
          toast.dismiss(loader);
          toast.success(res.data.message);
          setCalendarView(false);
          fetchProjectVendors();
        }
      })
      .catch((err) => {
        toast.dismiss(loader);
        toast.error(err.message || err.response.data.error);
        console.error(err);
      });
  };

  useEffect(() => {
    fetchVendors();
    fetchProjectVendors();
  }, []);

  const downloadPdf = () => {
    handlePrint();
  };
  const handlePrint = useReactToPrint({
    documentTitle: "Print This Document",
    content: () => contentToPrint.current,
  });

  return (
    <>
      {error ? (
        <ErrorPage />
      ) : (
        <div>
          {!loading ? (
            <Loader />
          ) : (
            <div className="flex flex-col sm:flex sm:flex-col p-5">
              <div className="flex sm:flex-row flex-col items-center justify-between sm:mt-8 mt-0">
                <div className="flex sm:items-start items-center justify-center sm:justify-start xl:ml-20 2xl:ml-20 md:ml-20 sm:ml-20 ml-0 sm:mt-0 mt-8">
                  <Button
                    name={"Add Vendor"}
                    clickFunction={() => setOpen(true)}
                  />
                </div>
                <div className="flex flex-row items-end justify-end sm:mt-0 mt-2">
                  <DownloadButton onClick={downloadPdf} name={"Download Pdf"} />
                </div>
              </div>
              <div className="flex items-center justify-center xl:ml-20 2xl:ml-20 lg:ml-20 md:ml-20 sm:ml-20 ml-0">
                <div className="w-full mt-5">
                  <ProjectVendorTable
                    projectVendordata={projectVendordata}
                    handleUpdateProjectVendor={handleUpdateProjectVendor}
                    handleDeleteProjectVendor={handleDeleteProjectVendor}
                    contentToPrint={contentToPrint}
                    calendarView={handleAttendanceView}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {open && (
        <ProjectVendorForm
          setOpen={setOpen}
          vendorData={vendorData}
          addProjectVendor={addProjectVendor}
          projectVendordata={projectVendordata}
        />
      )}
      {openUpdate && (
        <ProjectVendorUpdateForm
          setOpenUpdate={setOpenUpdate}
          selectedvendor={selectedvendor}
          updateProjectVendor={updateProjectVendor}
        />
      )}
      {openDelete && (
        <DeleteModal
          title={"Remove Vendor"}
          message={`Do you want to remove ${selectedvendor.vendor.name} ?`}
          onClose={() => {
            setOpenDelete(false);
            setSelectedVendor("");
          }}
          onDelete={deleteProjectVendor}
          icon={true}
        />
      )}
      {calendarView && (
        <AttendanceModal
          name={selectedvendor.vendor.name}
          onClose={() => setCalendarView(false)}
          data={selectedvendor}
          onSubmit={
            selectedvendor.attendance.length > 0
              ? handleUpdateAttendance
              : handleAddAttendance
          }
        />
      )}
    </>
  );
}

export default ProjectVendor;

import React from "react";
import Input from "../Input/Input";
import Select from "../Input/Select";
import Button from "../Button/Button";
import { RxCross1 } from "react-icons/rx";
import RadioButton from "../Input/RadioButton";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import FormikInput from "../Input/FormikInput";
import * as Yup from "yup";
import FormikSelect from "../Input/FormikSelect";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Date from "../Input/Date";

function SceneScheduleForm({
  handleBackdropClick,
  fetchLocation,
  locations,
  scheduledSceneNumbers,
  handleSceneNumberClick,
  scheduleShoot,
  locationScene,
  selectedSceneDetails,
}) {
  const validationSchema = Yup.object().shape({
    title: Yup.number().required("Please enter day"),
    date: Yup.date().required("Please select date"),
    call_time: Yup.string().required("Please enter call time"),
    shoot_time: Yup.string().required("Please enter shoot time"),
    unit_call_time: Yup.string().required("Please enter unit call time"),
    location: Yup.string().required("Please select Location"),
    scene_id: Yup.array()
      .min(1, "Please select at least one scene")
      .of(Yup.string().required("Scene ID is required")),
  });

  const handleLocationChange = (value, setFieldValue) => {
    const selectedLocation = locations.find(
      (location) => location.name === value
    );
    if (selectedLocation) {
      setFieldValue("location_id", selectedLocation.id);
      setFieldValue("location", value);
      fetchLocation(value);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="fixed inset-0 flex items-center justify-center z-50 overflow-auto no-scrollbar bg-black bg-opacity-50">
        <div className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto ">
          <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-9xl sm:max-w-9xl w-full sm:my-auto  my-auto">
            <div className="flex items-end justify-end">
              <button
                className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full "
                onClick={handleBackdropClick}
              >
                <RxCross1 />
              </button>
            </div>
            <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
              Schedule shoot
            </h2>
            <Formik
              initialValues={{
                title: "",
                date: "",
                call_time: "",
                shoot_time: "",
                breakfast: "",
                lunch: "",
                high_tea: "",
                dinner: "",
                dolly_panther: "",
                vanity_van: "",
                drone: "",
                picture_vehicle: "",
                extra_camera_setup: "",
                unit_call_time: "",
                min_temp: "",
                max_temp: "",
                sunrise: "",
                sunset: "",
                location_id: "",
                location: "",
                sound: "",
                extra_equipment: "",
                remarks: "",
                scene_id: [],
                artists_details: { details: [] },
                crowd_details: { details: [] },
              }}
              validationSchema={validationSchema}
              onSubmit={scheduleShoot}
            >
              {({ setFieldValue, errors, values }) => (
                <Form>
                  <div className="grid grid-cols-1 sm:grid-cols-4 gap-2 sm:gap-4 mt-2 sm:mt-4">
                    <div>
                      <FormikInput
                        type={"number"}
                        placeholder={"1"}
                        label={"Day"}
                        name={"title"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        type={"date"}
                        label={"Schedule Date"}
                        name={"date"}
                      />
                    </div>
                    <div>
                      <Field
                        component={Date}
                        name="call_time"
                        label="Call Time"
                      />
                    </div>
                    <div>
                      <Field
                        component={Date}
                        name="shoot_time"
                        label="Shoot time"
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-4 gap-2 sm:gap-4 mt-2 sm:mt-4">
                    <div>
                      <Field
                        component={Date}
                        name="breakfast"
                        label="Breakfast"
                      />
                    </div>
                    <div>
                      <Field component={Date} name="lunch" label="Lunch" />
                    </div>
                    <div>
                      <Field
                        component={Date}
                        name="high_tea"
                        label="High Tea"
                      />
                    </div>
                    <div>
                      <Field component={Date} label="dinner" name="dinner" />
                    </div>
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 sm:gap-4 mt-2 sm:mt-4">
                    <div>
                      <FormikInput
                        type={"text"}
                        label={"Dolly panther"}
                        placeholder={"Dolly panther"}
                        name={"dolly_panther"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        type={"text"}
                        label={"Vanity van"}
                        placeholder={"Vanity van"}
                        name={"vanity_van"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        type={"text"}
                        label={"Drone"}
                        placeholder={"Drone"}
                        name={"drone"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        type={"text"}
                        label={"Picture Vehicle"}
                        placeholder={"Picture Vehicle"}
                        name={"picture_vehicle"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        type={"text"}
                        label={"Extra Camera setup"}
                        placeholder={"Extra Camera setup"}
                        name={"extra_camera_setup"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        type={"text"}
                        label={"Sound"}
                        name={"sound"}
                        placeholder={"Extra mic"}
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-4 gap-2 sm:gap-4 mt-2 sm:mt-4">
                    <div>
                      <FormikInput
                        type={"number"}
                        label={"Min temperature"}
                        placeholder={"36"}
                        name={"min_temp"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        type={"number"}
                        label={"Max temperature"}
                        placeholder={"44"}
                        name={"max_temp"}
                      />
                    </div>
                    <div>
                      <Field component={Date} label="Sunrise" name="sunrise" />
                    </div>
                    <div>
                      <Field component={Date} label="Sunset" name="sunset" />
                    </div>
                    <div>
                      <Field
                        component={Date}
                        label="Unit call time"
                        name="unit_call_time"
                      />
                    </div>
                    <div>
                      <FormikInput
                        type={"text"}
                        label={"Extra equipment"}
                        name={"extra_equipment"}
                        placeholder={"3 cameras"}
                      />
                    </div>
                    <div>
                      <FormikInput
                        type={"text"}
                        label={"Remarks"}
                        name={"remarks"}
                        placeholder={"Please maintain silence."}
                      />
                    </div>
                    <div>
                      <FormikSelect
                        label={"Location"}
                        name={"location"}
                        optionData={locations}
                        selectedOption={"Location"}
                        onChange={(e) =>
                          handleLocationChange(e.target.value, setFieldValue)
                        }
                      />
                    </div>
                  </div>
                  <div className="mt-2 sm:mt-4">
                    <label className="block mb-2 text-sm text-gray-600 ">
                      Scenes of selected location:
                    </label>

                    <FieldArray name="scene_id">
                      {({ push, remove, form }) => (
                        <div className="grid grid-cols-3 sm:grid-cols-4 gap-2 sm:gap-4 bg-[#a9c8e2] p-4 rounded-3xl h-30 overflow-y-scroll sm:no-scrollbar shadow-lg">
                          {locationScene &&
                            locationScene
                              .sort((a, b) => a.scene_number - b.scene_number)
                              .map((scene) => {
                                const isSelected = values.scene_id.includes(
                                  scene.id
                                );
                                const isPreviouslySelected =
                                  scheduledSceneNumbers.includes(scene.id);

                                const sceneClass = `text-lg font-bold h-20 w-30 rounded-2xl text-white ease-out bg-[#1d5b79] duration-300 hover:shadow-lg 
                                        ${
                                          isSelected
                                            ? "bg-[#307ec2] text-white shadow-lg"
                                            : ""
                                        } 
                                         ${
                                           isPreviouslySelected
                                             ? "bg-gradient-to-r from-gray-500 to-gray-600 shadow-lg cursor-not-allowed text-white"
                                             : ""
                                         }`;

                                return (
                                  <button
                                    type="button"
                                    className={sceneClass}
                                    disabled={isPreviouslySelected}
                                    value={scene.id}
                                    key={scene.id}
                                    onClick={() =>
                                      handleSceneNumberClick(
                                        scene.id,
                                        setFieldValue,
                                        form
                                      )
                                    }
                                  >
                                    <div className="text-center">
                                      {scene.scene_number}
                                      {scene.sub_scene}
                                    </div>
                                  </button>
                                );
                              })}
                        </div>
                      )}
                    </FieldArray>
                    <ErrorMessage
                      name="scene_id"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  <div className="mt-4 text-sm">
                    {selectedSceneDetails.map((scene, index) => (
                      <div key={index} className="w-full border mt-2 p-2">
                        <h2 className="text-base sm:text-xl font-semibold mb-2 sm:mb-4 text-[#020002]">
                          Scene: {scene.scene_number}
                          {scene.sub_scene}
                        </h2>
                        <FieldArray name="artists_details.details">
                          {({ remove, push }) => (
                            <table className="w-full border">
                              <thead>
                                <tr>
                                  <th className="p-1 border-r border-b">
                                    Artist name
                                  </th>
                                  <th className="p-1 border-r border-b">
                                    Call time
                                  </th>
                                  <th className="p-1 border-r border-b">
                                    Shoot time
                                  </th>
                                  <th className="p-1 border-b">
                                    Transportation
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-100">
                                {values.artists_details.details.map(
                                  (data, index) =>
                                    data.scene_id === scene.id ? (
                                      <tr key={index}>
                                        <td className="border-l border-r border-b p-1">
                                          {data.artist_name}
                                        </td>
                                        <td className="border-l border-r border-b p-1">
                                          <Field
                                            name={`artists_details.details[${index}].call_time`}
                                            component={Date}
                                          />
                                        </td>
                                        <td className="border-l border-r border-b p-1">
                                          <Field
                                            component={Date}
                                            name={`artists_details.details[${index}].shoot_time`}
                                          />
                                        </td>
                                        <td className="border-l border-r border-b p-1">
                                          <Field
                                            as="select"
                                            name={`artists_details.details[${index}].transportation`}
                                            className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md focus:border-[#1d5b79] focus:ring-[#1d5b79] focus:outline-none focus:ring focus:ring-opacity-40"
                                          >
                                            <option value="Self">Self</option>
                                            <option value="Production">
                                              Production
                                            </option>
                                          </Field>
                                        </td>
                                      </tr>
                                    ) : null
                                )}
                              </tbody>
                            </table>
                          )}
                        </FieldArray>

                        <FieldArray name="crowd_details.details">
                          {({ remove, push }) => (
                            <table className="w-full border">
                              <thead>
                                <tr>
                                  <th className="p-1 border-r border-b">
                                    Crowd set
                                  </th>
                                  <th className="p-1 border-r border-b">
                                    No of people
                                  </th>
                                  <th className="p-1 border-r border-b">
                                    Gender
                                  </th>
                                  <th className="p-1 border-r border-b">
                                    Call time
                                  </th>
                                  <th className="p-1 border-b">Shoot time</th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-100">
                                {values.crowd_details.details.map(
                                  (data, index) =>
                                    scene.id === data.scene_id ? (
                                      <tr key={index}>
                                        <td className=" border-r border-b p-1">
                                          {data.crowd_set}
                                        </td>
                                        <td className="border-l border-r border-b p-1">
                                          {data.crowdNumber}
                                        </td>
                                        <td className="border-l border-r border-b p-1">
                                          {data.crowdType}
                                        </td>
                                        <td className="border-l border-r border-b p-1">
                                          <Field
                                            component={Date}
                                            name={`crowd_details.details[${index}].call_time`}
                                          />
                                        </td>
                                        <td className="border-l border-r border-b p-1">
                                          <Field
                                            component={Date}
                                            name={`crowd_details.details[${index}].shoot_time`}
                                          />
                                        </td>
                                      </tr>
                                    ) : null
                                )}
                              </tbody>
                            </table>
                          )}
                        </FieldArray>
                      </div>
                    ))}
                  </div>
                  <div className="mt-2 sm:mt-4">
                    <Button name={"Schedule shoot"} type={"submit"} />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </LocalizationProvider>
  );
}

export default SceneScheduleForm;

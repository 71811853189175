import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../../components/Button/Button";
import BudgetModal from "../../components/Forms/BudgetModal";
import { BarChart } from "@mui/x-charts";
import BudgetTable from "../../components/Table/BudgetTable";
import UpdateBudgetForm from "../../components/UpdateForm/UpdateBudgetForm";
import toast from "react-hot-toast";
import DeleteModal from "../../Modal/DeleteModal";
import Loader from "../../Loader/loader";
import ErrorPage from "../ErrorPage";
import { HiddenContext } from "../../Context/HiddenContent";

function BudgetPlan() {
  const [budget, setBudget] = useState([]);
  const [view, setView] = useState(false);
  const [update, setUpdate] = useState(false);
  const [Delete, setDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedBudget, setSelectedBudget] = useState("");
  const [error, setError] = useState(false);
  const { projectId } = useParams();
  const { hidden } = useContext(HiddenContext);

  const handleUpdateBudgetView = (budget) => {
    setSelectedBudget({ ...budget });
    setUpdate(true);
  };

  const handleDeleteBudgetView = (budget) => {
    setSelectedBudget({ ...budget });
    setDelete(true);
  };

  const handleAddBudget = (values, { resetForm }) => {
    const loader = toast.loading("Adding ...", {
      autoClose: false,
    });
    axios
      .post(
        `api/v1/projects/${projectId}/departments/${values.department}/annual_budgets`,
        {
          annual_budget: {
            budget_amount: values.annual_budget,
          },
        }
      )
      .then((response) => {
        toast.dismiss(loader);
        toast.success(response.data.message);
        setView(false);
        getBudgets();
        resetForm();
      })
      .catch((err) => {
        toast.dismiss(loader);
        toast.success(err.message);
        console.log(err);
      });
  };

  const handleUpdateBudget = (values, { resetForm }) => {
    const loader = toast.loading("Updating budget ...", { autoClose: false });
    axios
      .put(
        `api/v1/projects/${projectId}/departments/${selectedBudget.department_id}/annual_budgets/${selectedBudget.id}`,
        {
          annual_budget: {
            budget_amount: values.annual_budget,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setUpdate(false);
          setSelectedBudget("");
          getBudgets();
          resetForm();
          toast.dismiss(loader);
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.dismiss(loader);
        toast.error(
          err.response.data.message || err.message || err.response.data.error
        );
      });
  };

  const handleDeleteBudget = () => {
    const loader = toast.loading("Deleting Budget...", { autoClose: false });
    axios
      .delete(
        `api/v1/projects/${projectId}/departments/${selectedBudget.department_id}/annual_budgets/${selectedBudget.id}`
      )
      .then((res) => {
        if (res.status === 200) {
          toast.dismiss(loader);
          toast.success(res.data.message);
          getBudgets();
          setDelete(false);
          setSelectedBudget("");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.dismiss(loader);
        toast.error(
          err.response.data.message || err.message || err.response.data.error
        );
      });
  };

  const getBudgets = () => {
    axios
      .get(`api/v1/projects/${projectId}/annual_budgets`)
      .then((response) => {
        const formattedBudget = response.data.annual_budgets.map((item) => ({
          id: item.id,
          department_id: item.department_id,
          department: item.department.name,
          budget_amount: parseFloat(item.budget_amount),
          total_approved_expenses: parseFloat(item.total_approved_expenses),
        }));
        setBudget(formattedBudget);
        setLoading(true);
      })
      .catch((error) => {
        console.error("Error fetching budget data:", error);
        setError(true);
        setBudget([]);
      });
  };

  useEffect(() => {
    getBudgets();
  }, []);

  return (
    <>
      {error ? (
        <ErrorPage />
      ) : (
        <div>
          {!loading ? (
            <Loader />
          ) : (
            <div className="flex flex-col sm:flex sm:flex-col p-5">
              {hidden ? (
                <></>
              ) : (
                <div className="flex sm:items-start items-center justify-center sm:justify-start xl:ml-20 2xl:ml-20 md:ml-20 sm:ml-20 ml-0 sm:mt-0 mt-8">
                  <Button
                    name={"Add budget"}
                    clickFunction={() => setView(true)}
                  />
                </div>
              )}
              {budget && (
                <>
                  <div className="flex items-center justify-center  xl:ml-20 2xl:ml-20 lg:ml-20 md:ml-20 sm:ml-20 ml-0">
                    <div className="w-full mt-5">
                      <BudgetTable
                        detail={budget}
                        updateView={handleUpdateBudgetView}
                        deleteView={handleDeleteBudgetView}
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-center  xl:ml-20 2xl:ml-20 lg:ml-20 md:ml-20 sm:ml-20 ml-0">
                    <div className="calendar-container flex items-center justify-center p-10">
                      <BarChart
                        xAxis={[
                          {
                            scaleType: "band",
                            data: budget.map((item) => item.department),
                          },
                        ]}
                        series={[
                          {
                            data: budget.map((item) => item.budget_amount),
                            color: "#1d5b79",
                          },
                          {
                            data: budget.map(
                              (item) => item.total_approved_expenses
                            ),
                            color: "#a9c8e2",
                          },
                        ]}
                        width={600}
                        height={400}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
          {view && (
            <BudgetModal setView={setView} handleAddBudget={handleAddBudget} />
          )}
          {update && (
            <UpdateBudgetForm
              onClose={() => {
                setUpdate(false);
                setSelectedBudget("");
              }}
              data={selectedBudget}
              onSubmit={handleUpdateBudget}
            />
          )}
          {Delete && (
            <DeleteModal
              title={"Remove Budget"}
              icon={true}
              message={`Do you wanna remove budget of ${selectedBudget.department}? `}
              onClose={() => {
                setDelete(false);
                setSelectedBudget("");
              }}
              onDelete={handleDeleteBudget}
            />
          )}
        </div>
      )}
    </>
  );
}

export default BudgetPlan;

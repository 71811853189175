import React, { useState } from "react";
import usePagination from "../Pagination/usePagination";
import Pagination from "../Pagination/Pagination";

function TaxSummaryTable({ data }) {
  const { totalPages, currentItems, currentPage, handlePageChange } =
    usePagination(data, 10);
  return (
    <div>
      <div className="flex flex-col h-full">
        <div className="w-full mx-auto bg-white  rounded-sm border border-gray-200">
          <header className="flex sm:flex-row flex-col  justify-between px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold text-gray-800">Tax summary</h2>
            <Pagination
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              totalPages={totalPages}
            />
          </header>
          <div className="p-3">
            <div className="overflow-x-auto">
              <table className="table-auto w-full">
                <thead className="text-xs font-semibold uppercase text-gray-600 bg-[#eceff1]">
                  <tr>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Name</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Gst paid</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Tds paid</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Role</div>
                    </th>
                  </tr>
                </thead>
                <tbody className="text-sm divide-y divide-gray-100">
                  {currentItems.map((detail) => (
                    <tr>
                      <td className="p-2 whitespace-nowrap">{detail.name}</td>
                      <td className="p-2">
                        <div className="text-left uppercase">{detail.gst}</div>
                      </td>
                      <td className="p-2">
                        <div className="text-left uppercase">{detail.gst}</div>
                      </td>
                      <td className="p-2">
                        <div className="text-left uppercase">{detail.role}</div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TaxSummaryTable;

import React, { useContext, useEffect, useState } from "react";
import { SidebarContext } from "./SuperAdmin/SidebarContext";
import Sidebar from "../components/Sidebar";
import { AiTwotoneMail } from "react-icons/ai";
import { motion } from "framer-motion";
import axios from "axios";
import Email from "../components/SMTP/Email";
import VerifyOtp from "../components/SMTP/VerifyOtp";
import EnterSmtp from "../components/SMTP/EnterSmtp";
import { FaRegCheckCircle } from "react-icons/fa";
import { smtpTokenSteps } from "../components/Data";
import toast from "react-hot-toast";
import Loader from "../Loader/loader";
import ErrorPage from "./ErrorPage";
import { IoKeyOutline } from "react-icons/io5";
import { RiEyeLine, RiEyeCloseLine } from "react-icons/ri";

function EmailAuth() {
  const { view } = useContext(SidebarContext);
  const [step, setStep] = useState(0);
  const [timer, setTimer] = useState(0);
  const [email, setEmail] = useState("");
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [user, setUser] = useState({});
  const [show, setShow] = useState(false);

  const startTimer = () => {
    setIsTimerRunning(true);
    setTimer(120);
    const countdown = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 0) {
          clearInterval(countdown);
          setIsTimerRunning(false);
        }
        return prevTimer - 1;
      });
    }, 1000);
  };

  const sendOtp = (values, { resetForm }) => {
    const loader = toast.loading("Sending OTP ....");
    axios
      .post(`api/v1/users/send_smtp_otp`, {
        user: {
          smtp_email: values.email,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setEmail(values.email);
          setStep(1);
          startTimer();
          toast.success(res.data.message);
          resetForm();
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.error || err.message);
      })
      .finally(() => {
        toast.dismiss(loader);
      });
  };

  const ResendOtp = () => {
    const loader = toast.loading("Sending OTP ....");
    axios
      .post(`api/v1/users/send_smtp_otp`, {
        user: {
          smtp_email: email,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          startTimer();
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.error || err.message);
      })
      .finally(() => {
        toast.dismiss(loader);
      });
  };

  const verifyOtp = (values, { resetForm }) => {
    const loader = toast.loading("Verifying OTP ....");

    axios
      .post(`api/v1/users/verify_smtp_otp`, {
        smtp_otp: values.otp,
      })
      .then((res) => {
        if (res.status === 200) {
          setStep(2);
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.error || err.message);
      })
      .finally(() => {
        toast.dismiss(loader);
      });
  };
  const submitSmtp = (values, { resetForm }) => {
    const loader = toast.loading("Uploading SMTP Token ....");
    axios
      .put(`api/v1/users/set_smtp_password`, {
        user: {
          smtp_email: email,
          smtp_password: values.smtp,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message);
          resetForm();
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.error || err.message);
      })
      .finally(() => {
        toast.dismiss(loader);
      });
  };

  const fetchUser = () => {
    axios
      .get(`api/v1/users/find_user`)
      .then((res) => {
        setUser(res.data.user);
        setLoading(true);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      });
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <div>
      <Sidebar />
      <div
        className={`h-screen p-7 ${
          !view ? "ml-40 duration-100 ease-in" : "ml-0 duration-100 ease-in"
        }`}
      >
        {!loading ? (
          <Loader />
        ) : (
          <div>
            {error ? (
              <ErrorPage />
            ) : (
              <div className="flex flex-col sm:flex sm:flex-col p-5">
                <div className="flex sm:items-start items-center justify-center sm:justify-start xl:ml-20 2xl:ml-20 md:ml-20 sm:ml-20 ml-0 sm:mt-0 mt-8">
                  <h2 className="font-bold text-[#1d5b79] sm:text-3xl text-xl">
                    SMTP VERIFICATION
                  </h2>
                </div>
                <div className="flex md:flex-row flex-col items-center justify-center  xl:ml-20 2xl:ml-20 lg:ml-20 md:ml-20 sm:ml-20 ml-0 mt-5">
                  <motion.div
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5 }}
                    className="w-full flex md:flex-row flex-col bg-white rounded-2xl shadow-xl"
                  >
                    <div className="bg-white md:w-1/2 w-full md:rounded-s-2xl rounded-t-2xl md:p-8 p-4">
                      {!user.smtp_password ? (
                        <div>
                          <h2 className="mx-3 text-[#1d5b79] md:text-2xl text-xl font-bold flex items-center">
                            <AiTwotoneMail size={30} color="#1d5b79" />
                            <span className="ml-2">SMTP TOKEN SETUP</span>
                          </h2>
                          <div className="mt-8 px-3 w-full">
                            {step === 0 && <Email onSubmit={sendOtp} />}
                            {step === 1 && (
                              <VerifyOtp
                                onSubmit={verifyOtp}
                                Timer={timer}
                                isTimerRunning={isTimerRunning}
                                resendOtp={ResendOtp}
                              />
                            )}
                            {step === 2 && <EnterSmtp onSubmit={submitSmtp} />}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <h2 className="mx-3 text-[#1d5b79] md:text-2xl text-xl font-bold flex items-center">
                            <AiTwotoneMail size={30} color="#1d5b79" />
                            <span className="ml-2">SMTP TOKEN</span>
                          </h2>
                          <div className="mt-8 px-3 w-full">
                            <div className="flex flex-col w-full mx-3">
                              <h2 className=" text-[#1d5b79] md:text-lg text-base font-bold flex items-center">
                                <AiTwotoneMail size={20} color="#1d5b79" />
                                <span className="ml-2">Email</span>
                              </h2>
                              <h2 className="text-gray-500 md:text-lg text-base flex items-center">
                                {user.smtp_email}
                              </h2>
                            </div>
                            <div className="flex flex-col w-full mx-3 mt-3">
                              <h2 className=" text-[#1d5b79] md:text-lg text-base font-bold flex items-center">
                                <IoKeyOutline size={20} color="#1d5b79" />
                                <span className="ml-2">SMTP Token</span>
                              </h2>

                              <div className="w-full flex flex-row justify-between">
                                <h2 className="text-gray-500 md:text-lg text-base flex items-center">
                                  {!show
                                    ? "••••••••••••••••"
                                    : user.smtp_password}
                                </h2>
                                <button
                                  onClick={() => setShow(!show)}
                                  className="text-[#1d5b79] flex flex-row outline-none bg-gray-200 rounded-xl p-2 duration-300 ease-in"
                                >
                                  {!show ? (
                                    <RiEyeLine size={20} />
                                  ) : (
                                    <RiEyeCloseLine size={20} />
                                  )}
                                  <span className="ml-2">
                                    {!show ? "Show" : "Hide"}
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="bg-[#1d5b79] md:w-1/2 w-full md:rounded-e-2xl md:rounded-bl-none rounded-b-2xl md:p-8 p-4">
                      <h2 className="mx-3 text-white md:text-2xl text-xl font-bold flex items-center">
                        <FaRegCheckCircle size={30} color="white" />
                        <span className="ml-2">Setup Steps</span>
                      </h2>
                      <div className="mt-8 px-3 w-full">
                        <ol className="space-y-8">
                          {smtpTokenSteps.map((data, index) => (
                            <motion.li
                              key={index}
                              className="flex items-start"
                              initial={{ opacity: 0, x: -20 }}
                              animate={{ opacity: 1, x: 0 }}
                              transition={{ duration: 0.5, delay: index * 0.1 }}
                            >
                              <span className="flex items-center justify-center w-6 h-6 rounded-full bg-white text-[#1d5b79] font-bold mr-3 flex-shrink-0">
                                {data.stepNumber}
                              </span>
                              <span className="text-white">
                                {data.description}
                              </span>
                            </motion.li>
                          ))}
                        </ol>
                      </div>
                    </div>
                  </motion.div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default EmailAuth;

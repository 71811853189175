import { Form, Formik } from "formik";
import React from "react";
import { RxCross1 } from "react-icons/rx";
import { vehicleType } from "../Data";
import FormikInput from "../Input/FormikInput";
import FormikSelect from "../Input/FormikSelect";
import Button from "../Button/Button";
import * as Yup from "yup";

function VehicleUpdateForm({ data, onClose, onSubmit }) {
  const validationSchema = Yup.object().shape({
    vehicle_type: Yup.string().required("Vehicle type is required"),
    capacity: Yup.number()
      .required("Vehicle capacity is required")
      .positive("Capacity must be greater than 0")
      .integer("Capacity must be a whole number"),
    number_plate: Yup.string()
      .required("Number plate is required")
      .matches(
        /^[A-Z]{2}[0-9]{2}[A-Z]{1,2}[0-9]{4}$/,
        "Number plate format XX00XX0000"
      ),
    driver_name: Yup.string().required("Driver name is required"),
    driver_number: Yup.string()
      .required("Driver contact number is required")
      .matches(/^[0-9]{10}$/, "Contact number must be 10 digits"),
    name: Yup.string().required("Vehicle name is required"),
    rate: Yup.number()
      .required("Rate is required")
      .positive("Rate must be greater than 0"),
  });
  return (
    <div className="fixed inset-0 flex items-center justify-center overflow-auto z-50 bg-black bg-opacity-50 ">
      <div className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto ">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md  w-full mx-auto ">
          <div className="flex items-end justify-end">
            <button
              className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full "
              onClick={onClose}
            >
              <RxCross1 />
            </button>
          </div>
          <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
            {data.name}
          </h2>
          <Formik
            initialValues={{
              vehicle_type: data.vehicle_type || "",
              capacity: data.capacity || 1,
              number_plate: data.number_plate || "",
              driver_name: data.driver_name || "",
              driver_number: data.driver_number || "",
              name: data.name || "",
              rate: data.rate || 1,
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 sm:gap-4 mt-2 sm:mt-4">
                  <div>
                    <FormikSelect
                      name={`vehicle_type`}
                      optionData={vehicleType}
                      selectedOption={"Vehicle type"}
                      onChange={(e) =>
                        setFieldValue(`vehicle_type`, e.target.value)
                      }
                      label={"Vehicle type"}
                      labelProperty={"type"}
                      valueProperty={"value"}
                    />
                  </div>
                  <div>
                    <FormikInput
                      name={`name`}
                      type={"text"}
                      label={"Vehicle name"}
                      placeholder={"Roadrunner"}
                    />
                  </div>
                  <div>
                    <FormikInput
                      name={`capacity`}
                      type={"number"}
                      label={"Vehicle capacity"}
                      min={1}
                      placeholder={"3"}
                    />
                  </div>
                  <div>
                    <FormikInput
                      name={`number_plate`}
                      label={"Number plate"}
                      placeholder={"GJ XX AA XXX"}
                      type={"text"}
                    />
                  </div>
                  <div>
                    <FormikInput
                      name={`driver_name`}
                      label={"Driver name"}
                      type={"text"}
                      placeholder={"John Doe"}
                    />
                  </div>
                  <div>
                    <FormikInput
                      name={`driver_number`}
                      label={"Driver contact number"}
                      type={"tel"}
                      placeholder={"98454*****"}
                    />
                  </div>
                  <div>
                    <FormikInput
                      name={`rate`}
                      label={"Rate"}
                      type={"number"}
                      placeholder={"100"}
                      min={1}
                    />
                  </div>
                </div>
                <div className="sm:mt-4 mt-2">
                  <Button type={"submit"} name={"Update"} />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default VehicleUpdateForm;

import React, { useContext, useEffect, useState } from "react";
import Sidebar from "./superAdminComponent/Sidebar";
import Button from "../../components/Button/Button";
import DepartmentModal from "./superAdminComponent/Modal/DepartmentModal";
import * as Yup from "yup";
import axios from "axios";
import toast from "react-hot-toast";
import FormikInput from "../../components/Input/FormikInput";
import { Form, Formik } from "formik";
import DepartmentTable from "./superAdminComponent/Table/DepartmentTable";
import { RxCross1 } from "react-icons/rx";
import { SidebarContext } from "./SidebarContext";

function Department() {
  const { view } = useContext(SidebarContext);
  const validationSchema = Yup.object().shape({
    department: Yup.string().required("Department name is required"),
  });

  const [open, setOpen] = useState(false);
  const [Department, setDepartment] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");

  const openSubModal = (id) => {
    const selectedDepartmentDetail = Department.find((data) => data.id === id);
    setSelectedDepartment(selectedDepartmentDetail);
    setOpen(true);
  };

  const getDepartmentData = () => {
    axios
      .get(`api/v1/departments`)
      .then((response) => {
        setDepartment(response.data.departments);
      })
      .catch((error) => console.log(error.message));
  };

  useEffect(() => {
    getDepartmentData();
  }, []);

  const addDepartmentHandler = (values, { setSubmitting, resetForm }) => {
    const loaderToast = toast.loading("Adding ...", {
      autoClose: false,
    });
    axios
      .post(`api/v1/departments`, {
        name: values.department,
      })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          toast.dismiss(loaderToast);
          toast.success(response.data.message);
          getDepartmentData();
          resetForm(); // Resetting the form values
        }
      })
      .catch((error) => {
        console.log(error.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div>
      <Sidebar />
      <div
        className={`h-screen p-7 ${
          !view ? "ml-40 duration-100 ease-in" : "ml-0 duration-100 ease-in"
        }`}
      >
        <div className="flex flex-col sm:flex sm:flex-col p-5">
          <div className="flex items-start justify-start xl:ml-20 2xl:ml-20 md:ml-20 sm:ml-20 ml-24">
            <Formik
              validationSchema={validationSchema}
              initialValues={{ department: "" }}
              onSubmit={addDepartmentHandler}
            >
              <Form>
                <div className="flex flex-row">
                  <div>
                    <FormikInput
                      name={"department"}
                      label={"Department"}
                      type={"text"}
                      placeholder={"Department"}
                    />
                  </div>
                  <div className="mt-7 ml-2">
                    <Button name={"Add "} type={"submit"} />
                  </div>
                </div>
              </Form>
            </Formik>
          </div>

          <div className="flex items-center justify-center  xl:ml-20 2xl:ml-20 lg:ml-20 sm:ml-20 ml-0">
            <div className="w-full mt-5">
              <DepartmentTable
                data={Department}
                openSubModal={openSubModal}
                getDepartmentData={getDepartmentData}
              />
            </div>
          </div>
        </div>
      </div>
      {open && (
        <DepartmentModal
          setOpen={setOpen}
          selectedDepartment={selectedDepartment}
        />
      )}
    </div>
  );
}

export default Department;

import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import TopNavbar from "../components/TopNavbar";
import ProjectForm from "../components/Forms/ProjectForm";
import Loader from "../Loader/loader";
import * as Yup from "yup";
import UpgradePackage from "../Modal/UpgradePackage";
import useRazorpay from "react-razorpay";
import ReactPaginate from "react-paginate";
import { FaChevronLeft, FaChevronRight, FaPlus } from "react-icons/fa6";
import { MdDelete, MdEdit } from "react-icons/md";
import ProjectUpdateForm from "../components/UpdateForm/ProjectUpdateForm";
import DeleteModal from "../Modal/DeleteModal";
import { SlOptionsVertical } from "react-icons/sl";
import ErrorPage from "./ErrorPage";
import SecureLS from "secure-ls";
import { handleImageConversion } from "../components/ImageConversion";
import ProjectCard from "../components/ProjectCard";

const generateVibrantColor = () => {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);

  return `rgb(${r},${g},${b})`;
};

function Project() {
  const validationSchema = Yup.object().shape({
    project_name: Yup.string()
      .min(3, "Project name must have 3 character")
      .required("Project name required"),
    cinema_type: Yup.string().required("Select Project type "),
  });
  const [active, setActive] = useState(true);
  const [projects, setProjects] = useState([]);
  const [user, setUser] = useState("");
  const navigate = useNavigate();
  const [projectImages, setProjectImages] = useState({});
  const [image, setImage] = useState("");
  const [view, setView] = useState(false);
  const [packageData, setPackageData] = useState([]);
  const [selectPlan, setSelectPlan] = useState("");
  const [Visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Razorpay] = useRazorpay();
  const [currentPage, setCurrentPage] = useState(0);
  const [updateView, setUpdateView] = useState(false);
  const [selectedProject, setSelectedProject] = useState("");
  const [deleteView, setDeleteView] = useState(false);
  const [viewOption, setViewOption] = useState({});
  const [partners, setPartners] = useState([]);
  const [error, setError] = useState(false);
  const [itemsPerPage] = useState(7);
  const ls = new SecureLS();

  useEffect(() => {
    if (window.location.pathname === "/") {
      localStorage.removeItem("activeTab");
      localStorage.removeItem("Verified");
      localStorage.removeItem("AccountActiveTab");
    }
  }, []);

  const generateProjectImage = (project) => {
    const canvas = document.createElement("canvas");
    canvas.width = 350;
    canvas.height = 350;
    const ctx = canvas.getContext("2d");

    const backgroundColor = project.backgroundColor || generateVibrantColor();

    project.backgroundColor = backgroundColor;

    ctx.fillStyle = backgroundColor;
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.font = "100px Arial";
    ctx.fillStyle = "#fbffff";

    const text = project.title.slice(0, 2).toUpperCase();
    const textWidth = ctx.measureText(text).width;
    const x = (canvas.width - textWidth) / 2;
    const y = (canvas.height + 24) / 2;

    ctx.fillText(text, x, y);

    return canvas.toDataURL();
  };

  const viewHandler = () => {
    setActive(false);
  };

  const onClose = () => {
    setActive(true);
  };

  const handleBackdropClick = () => {
    onClose();
  };

  const stopClose = (e) => {
    e.stopPropagation();
  };

  const fetchUser = () => {
    axios
      .get(`api/v1/users/find_user`)
      .then((response) => {
        setUser(response.data.user);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const fetchPartners = () => {
    axios
      .get(`api/v1/partners`)
      .then((res) => {
        setPartners(res.data.partners);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchAllProjects = () => {
    axios
      .get(`api/v1/projects`)
      .then((response) => {
        const project = response.data.projects;
        setProjects(project);
        setLoading(true);
        const projectImageMap = {};
        project.forEach((Project) => {
          const imageDataURL = generateProjectImage(Project);
          projectImageMap[Project.id] = imageDataURL;
        });
        setProjectImages(projectImageMap);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      });
  };

  useEffect(() => {
    fetchUser();
    fetchAllProjects();
    getPackage();
    fetchPartners();
  }, []);

  const getPackage = () => {
    axios
      .get(`api/v1/packages`)
      .then((response) => {
        setPackageData(response.data.packages);
      })
      .catch((err) => console.log(err.message));
  };

  const togglePlan = (name) => {
    setSelectPlan(name);
    setVisible(true);
  };

  const ContactSales = (Package) => {
    const loaderToast = toast.loading("Sending mail...", { autoClose: false });

    axios
      .post(`api/v1/packages/contact_sales`, {
        package_name: Package,
        user_id: user.id,
      })
      .then((response) => {
        if (response.status === 200) {
          toast.dismiss(loaderToast);
          toast.success(response.data.message);
          setView(false);
        }
      })
      .catch((error) => {
        toast.dismiss(loaderToast);
        toast.error(error.message);
        console.error(error);
      });
  };

  const handlePayment = (Package) => {
    axios
      .post(`api/v1/payments`, {
        payment: {
          user_id: user.id,
          package_name: Package,
        },
      })
      .then((response) => {
        if (response.data.data.payment.status_type === "pending") {
          const options = {
            key: "rzp_test_PFzBSO4bIxTNTc",
            currency: "INR",
            name: "CinemaPro",
            order_id: response.data.data.payment.razorpay_order_id,
            handler: (response) => {
              axios
                .post(`api/v1/payments/callback`, {
                  order_id: response.razorpay_order_id,
                  payment_id: response.razorpay_payment_id,
                })
                .then((response) => {
                  if (response.status === 200) {
                    toast.success(response.data.message);
                    fetchUser();
                    setView(false);
                  }
                })
                .catch((error) => {
                  console.log(error.message);
                });
            },
            theme: {
              color: "#074173",
            },
          };
          const razorpay = new Razorpay(options);
          razorpay.open();
          razorpay.on("Payment Success", (res) => {});
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const handleProject = async (value, { setSubmitting, resetForm }) => {
    const formData = new FormData();
    const loading = toast.loading("Creating Project ...", {
      autoClose: false,
    });

    formData.append("project[title]", value.project_name);
    formData.append("project[cinema_type]", value.cinema_type);

    value.partner_attributes.forEach((partner, index) => {
      formData.append(
        `project[project_partners_attributes][${index}][partner_id]`,
        partner.partner_id
      );
      formData.append(
        `project[project_partners_attributes][${index}][percentage]`,
        partner.percentage
      );
      formData.append(
        `project[project_partners_attributes][${index}][invested_amount]`,
        partner.amount
      );
    });

    if (image) {
      const convertedImage = await handleImageConversion(image);
      formData.append("project[poster]", convertedImage);
    }

    axios
      .post(`api/v1/projects`, formData)
      .then((response) => {
        if (response.data.success === true) {
          handleBackdropClick();
          fetchAllProjects();
          resetForm();
          toast.dismiss(loading);
          toast.success("Project added successfully !!");
        }
      })
      .catch((err) => {
        toast.dismiss(loading);
        toast.error(
          err.response.data.error || err.message || err.response.data.error
        );
        handleBackdropClick();
        console.log(err);
      });
    setSubmitting(false);
  };

  function projectViewHandler(projectId, title, cinema_type) {
    navigate(`/projectoverview/${projectId}`);
    localStorage.setItem("currentProjectId", projectId);
    ls.set("activeTab", 1);
    localStorage.setItem(
      "Project_details",
      JSON.stringify({
        title: title,
        cinema_type: cinema_type,
      })
    );
  }

  const handleOpenViewSettings = (e, id) => {
    e.stopPropagation();
    setViewOption((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
    const selectedProjectForUpdate = projects.find(
      (project) => project.id === id
    );
    setSelectedProject(selectedProjectForUpdate);
  };

  const openUpdateProjectModal = (e, id) => {
    e.stopPropagation();
    const selectedProjectForUpdate = projects.find(
      (project) => project.id === id
    );
    setUpdateView(true);
    setSelectedProject(selectedProjectForUpdate);
  };

  const openDeleteModal = (e, id) => {
    e.stopPropagation();
    const selectedProjectForDelete = projects.find(
      (project) => project.id === id
    );
    setSelectedProject(selectedProjectForDelete);
    setDeleteView(true);
  };

  const handleDeleteProject = () => {
    const loader = toast.loading("Deleting Project ...", { autoClose: false });
    axios
      .delete(`api/v1/projects/${selectedProject.id}`)
      .then((response) => {
        toast.success(response.data.message);
        toast.dismiss(loader);
        setDeleteView(false);
        fetchAllProjects();
        setViewOption(false);
      })
      .catch((error) => {
        toast.dismiss(loader);
        toast.error(error.message);
        console.log(error);
      });
  };

  const handleUpdateProject = (id, value, { resetForm }) => {
    const loading = toast.loading("Upadting Project ...", {
      autoClose: false,
    });

    const formData = new FormData();
    formData.append("project[title]", value.project_name);
    formData.append("project[cinema_type]", value.cinema_type);
    value.partner_attributes.forEach((partner, index) => {
      formData.append(
        `project[project_partners_attributes][${index}][id]`,
        partner.project_partner_id
      );
      formData.append(
        `project[project_partners_attributes][${index}][partner_id]`,
        partner.partner_id
      );
      formData.append(
        `project[project_partners_attributes][${index}][percentage]`,
        partner.percentage
      );
      formData.append(
        `project[project_partners_attributes][${index}][invested_amount]`,
        partner.amount
      );
      formData.append(
        `project[project_partners_attributes][${index}][_destroy]`,
        partner._destroy
      );
    });
    if (image) {
      formData.append("project[poster]", image);
    }

    axios
      .put(`api/v1/projects/${id}`, formData)
      .then((response) => {
        console.log(response);
        setUpdateView(false);
        toast.dismiss(loading);
        toast.success("Project updated succesfully");
        fetchAllProjects();
        setImage("");
        resetForm();
        setViewOption(false);
      })
      .catch((error) => {
        console.log(error);
        toast.dismiss(loading);
        toast.error(error.message);
      });
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = projects.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(projects.length / itemsPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const userDetail = localStorage.getItem("User_Details");
  const user_details = JSON.parse(userDetail);

  return (
    <div>
      <TopNavbar user={user} setView={setView} />
      {error ? (
        <div className="mt-32">
          <ErrorPage />
        </div>
      ) : (
        <div>
          {!loading ? (
            <Loader />
          ) : (
            <div className="flex justify-center min-h-screen">
              <div
                className="hidden bg-cover lg:block lg:w-1/4"
                style={{
                  backgroundImage: `url("https://images.unsplash.com/photo-1691040986240-fb7c5a2b3f35?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")`,
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div className="flex items-center justify-end h-full px-10 ">
                  <div className="flex flex-col items-end justify-end">
                    <h1 className="text-3xl font-bold max-w-xl  text-[#fbffff]">
                      {user_details.company_name}
                    </h1>
                    <h1 className="max-w-xl text-xl font-bold mt-2 text-[#fbffff]">
                      By {user_details.name}
                    </h1>
                  </div>
                </div>
              </div>
              <div className="flex justify-center items-center w-full mx-10 ml-0 sm:ml-5 lg:w-4/5">
                <div className="flex flex-col w-full">
                  <div className="flex flex-col sm:mt-20 mt-32">
                    <h1 className="text-[#020002] text-4xl sm:text-6xl tracking-widest font-bold mb-5 mt-5">
                      Projects
                    </h1>
                  </div>
                  <div className="w-full grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
                    <div
                      onClick={viewHandler}
                      className={`${
                        projects.length > 0 ? "h-full w-full" : "h-60 w-52"
                      } flex flex-col items-center justify-center p-6 bg-gradient-to-r from-blue-50 to-blue-100 rounded-3xl border-4 border-dashed border-blue-300 cursor-pointer group hover:bg-blue-200 transition-all duration-200 ease-in-out`}
                    >
                      <div className="flex items-center justify-center w-16 h-16 bg-gradient-to-r from-blue-300 to-blue-400 text-white rounded-full group-hover:scale-110 transform group-hover:rotate-180 transition-transform duration-300 ease-out">
                        <FaPlus className="text-4xl" />
                      </div>
                      <h3 className="mt-4 text-lg font-semibold text-blue-600 group-hover:text-blue-800 transition-colors duration-200">
                        New Project
                      </h3>
                    </div>

                    {currentItems.map((project) => (
                      <ProjectCard
                        project={project}
                        key={project.id}
                        handleOpenViewSettings={handleOpenViewSettings}
                        openDeleteModal={openDeleteModal}
                        openUpdateProjectModal={openUpdateProjectModal}
                        projectImages={projectImages}
                        projectViewHandler={projectViewHandler}
                        viewOption={viewOption}
                      />
                    ))}
                  </div>

                  {totalPages > 1 && (
                    <div className="mt-4">
                      <ReactPaginate
                        pageCount={Math.ceil(projects.length / itemsPerPage)}
                        onPageChange={handlePageChange}
                        containerClassName={"flex"}
                        previousLabel={<FaChevronLeft />}
                        nextLabel={<FaChevronRight />}
                        activeClassName={"active"}
                        previousClassName={
                          "bg-[#074173] text-[#fbffff] w-7 h-7 items-center justify-center p-2 top-9 -right-4 text-xl flex rounded"
                        }
                        nextClassName={
                          "bg-[#074173] text-[#fbffff] w-7 h-7 items-center justify-center p-2 top-9 -right-4 text-xl flex rounded"
                        }
                        pageClassName={"mx-1"}
                        pageLinkClassName={
                          "bg-gray-200 text-[#020002] w-7 h-7 items-center justify-center p-2 top-9 -right-4 text-xl flex rounded"
                        }
                        breakClassName={"mx-1"}
                        breakLinkClassName={
                          "px-3 py-1 bg-gray-200 text-gray-600 rounded"
                        }
                      />
                    </div>
                  )}
                </div>
              </div>

              {!active && (
                <ProjectForm
                  validationSchema={validationSchema}
                  handleBackdropClick={handleBackdropClick}
                  stopClose={stopClose}
                  setImage={setImage}
                  handleProject={handleProject}
                  partners={partners}
                />
              )}
              {view && (
                <UpgradePackage
                  setView={setView}
                  user={user}
                  packageData={packageData}
                  handlePayment={handlePayment}
                  setVisible={setVisible}
                  ContactSales={ContactSales}
                  selectPlan={selectPlan}
                  setSelectPlan={setSelectPlan}
                  togglePlan={togglePlan}
                  Visible={Visible}
                />
              )}
              {updateView && (
                <ProjectUpdateForm
                  selectedProject={selectedProject}
                  setUpdateView={setUpdateView}
                  stopClose={stopClose}
                  setImage={setImage}
                  handleUpdateProject={handleUpdateProject}
                  partners={partners}
                />
              )}
              {deleteView && (
                <DeleteModal
                  title={`Do you want to delete this ${selectedProject.title}?`}
                  onClose={() => setDeleteView(false)}
                  onDelete={handleDeleteProject}
                  icon={true}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Project;

import React, { useContext, useState } from "react";
import { BiSolidTrashAlt } from "react-icons/bi";
import { FaCalendar, FaFilter } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";
import VendorDetailModal from "../../Modal/VendorDetailModal";
import { TbFilterOff } from "react-icons/tb";
import { AnimatePresence, motion } from "framer-motion";
import Select from "../Input/Select";
import Input from "../Input/Input";
import { vendorType } from "../Data";
import SubRoleContext from "../../SubRoleContext";
import usePagination from "../Pagination/usePagination";
import Pagination from "../Pagination/Pagination";

function ProjectVendorTable({
  projectVendordata,
  handleUpdateProjectVendor,
  handleDeleteProjectVendor,
  contentToPrint,
  calendarView,
}) {
  const subRole = useContext(SubRoleContext);

  const [filterOpen, setFilterOpen] = useState(false);
  const [type, setType] = useState("");
  const [name, setName] = useState("");

  const typeFilteredData = projectVendordata.filter((vendor) =>
    vendor.vendor.vendor_type.toLowerCase().includes(type.toLocaleLowerCase())
  );
  const nameFilterdData = typeFilteredData.filter((vendor) =>
    vendor.vendor.name.toLowerCase().includes(name.toLocaleLowerCase())
  );

  const { currentItems, currentPage, handlePageChange, totalPages } =
    usePagination(nameFilterdData, 10);

  const projectDetails = localStorage.getItem("Project_details");
  const OwnerDetail = JSON.parse(projectDetails);

  const userDetail = localStorage.getItem("User_Details");
  const user_details = JSON.parse(userDetail);
  const filterVariants = {
    hidden: { opacity: 0, y: -30 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -30 },
  };

  return (
    <div>
      <div className="flex flex-col h-full">
        <div className="w-full mx-auto bg-white  rounded-sm border border-gray-200">
          <header className="flex sm:flex-row flex-col  justify-between px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold text-gray-800">Vendor</h2>
            <div className="flex items-start justify-start sm:justify-end sm:items-center sm:mt-0 mt-2 relative">
              <Pagination
                currentPage={currentPage}
                handlePageChange={handlePageChange}
                totalPages={totalPages}
              />
              <div>
                <button
                  onClick={() => setFilterOpen(!filterOpen)}
                  className="text-lg bg-gray-300 mx-2 p-2 outline-none rounded-full shadow hover:shadow-none duration-300 ease-out"
                >
                  <FaFilter />
                </button>
              </div>
            </div>
          </header>
          <div className="p-3">
            <div className="overflow-x-auto">
              <table className="table-auto w-full">
                <thead className="text-xs font-semibold uppercase text-gray-600 bg-[#eceff1]">
                  <tr>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Vendor name</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Vendor type</div>
                    </th>

                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-center">Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody className="text-sm divide-y divide-gray-100">
                  {currentItems.map((vendor, index) => (
                    <tr key={index}>
                      <td className="p-2 whitespace-nowrap">
                        {vendor.vendor.name}
                      </td>
                      <td className="p-2">
                        <div className="text-left">
                          {vendor.vendor.vendor_type}
                        </div>
                      </td>

                      <td className="p-4 whitespace-nowrap flex">
                        <div className="flex items-center justify-center">
                          {subRole === "Manager" ? (
                            <></>
                          ) : (
                            <button
                              onClick={() => calendarView(vendor)}
                              className="p-4 text-xl text-blue-600 border-none hover:shadow-none rounded-full outline-none bg-blue-50  duration-300 ease-in"
                            >
                              <FaCalendar />
                            </button>
                          )}
                          <button
                            onClick={() => handleUpdateProjectVendor(vendor.id)}
                            className="p-4 ml-4 text-xl text-green-600 border-none hover:shadow-none rounded-full outline-none bg-green-50  duration-300 ease-in"
                          >
                            <MdModeEdit />
                          </button>
                          <button
                            onClick={() => handleDeleteProjectVendor(vendor.id)}
                            className="ml-4 p-4 text-xl text-red-600 bg-red-50 hover:shadow-none rounded-full  duration-300 ease-in outline-none"
                          >
                            <BiSolidTrashAlt />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden absolute top-0 left-8 xl:absolute xl:top-0 xl:left-20 2xl:relative sm:relative  sm:ml-0  flex-col items-center justify-center my-auto z-50 ">
        <div ref={contentToPrint} className="flex flex-col h-full">
          <h2>{user_details.company_name}</h2>
          <h2 className="mb-1 mt-1">{OwnerDetail.title}</h2>
          <div className="w-full mx-auto bg-white  rounded-sm border border-gray-200">
            <header className="flex sm:flex-row flex-col  justify-between px-5 py-4 border-b border-gray-100">
              <h2 className="font-semibold text-gray-800">Vendor</h2>
            </header>
            <div className="p-3">
              <div className="overflow-x-auto">
                <table className="table-auto w-full">
                  <thead className="text-xs font-semibold uppercase text-gray-600 bg-[#eceff1]">
                    <tr>
                      <th className="px-4 py-2 whitespace-nowrap">
                        <div className="font-semibold text-left">Sr no.</div>
                      </th>
                      <th className="px-4 py-2 whitespace-nowrap">
                        <div className="font-semibold text-left">
                          Vendor name
                        </div>
                      </th>
                      <th className="px-4 py-2 whitespace-nowrap">
                        <div className="font-semibold text-left">
                          Vendor type
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-sm divide-y divide-gray-100">
                    {projectVendordata.map((vendor, index) => (
                      <tr key={index}>
                        <td className="p-2 whitespace-nowrap">{index + 1}</td>
                        <td className="p-2 whitespace-nowrap">
                          {vendor.vendor.name}
                        </td>
                        <td className="p-2">
                          <div className="text-left">
                            {vendor.vendor.vendor_type}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {filterOpen && (
          <motion.div
            key="FILTER"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={filterVariants}
            transition={{ duration: 0.3 }}
            className="bg-white shadow-lg p-2 rounded-b-xl sm:w-auto w-auto h-auto flex  items-center justify-center absolute top-0"
          >
            <div className="flex flex-col">
              <div className="flex  items-start justify-start">
                <h2 className="font-bold text-xl text-[#1d5b79]">FILTERS</h2>
              </div>
              <div className="flex sm:flex-row flex-col items-center justify-center">
                <div className="w-72  mx-3">
                  <Select
                    optionData={vendorType}
                    selectedOption={"Vendor type"}
                    onChangeFunction={(e) => setType(e.target.value)}
                  />
                </div>
                <div className="w-72  mx-3">
                  <Input
                    placeholderValue={"Vendor name"}
                    onChangeFunction={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex items-end justify-end my-2 mx-2">
                <button
                  onClick={() => {
                    setName("");
                    setType("");
                    setFilterOpen(false);
                  }}
                  className="bg-[#1d5b79] p-2 text-xl text-white rounded-full"
                >
                  <TbFilterOff />
                </button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default ProjectVendorTable;

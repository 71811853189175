import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "./components/Button/Button";

function NotFound() {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex flex-col items-center justify-center h-[100dvh] bg-gray-100  px-4 md:px-6 overflow-hidden">
        <div className="relative w-full max-w-md">
          <div className="absolute -left-20 -top-20 w-40 h-40 bg-[#c73838] rounded-full blur-[80px]  animate-pulse" />
          <div className="absolute -right-20 -bottom-20 w-40 h-40 bg-[#2d34c0] rounded-full blur-[80px]  animate-pulse" />
          <div className="flex items-center justify-center flex-row">
            <h1 className="sm:text-[250px] text-9xl font-bold bg-clip-text text-transparent bg-gradient-to-br to-[#1d5b79] from-[#a9c8e2]">
              4
            </h1>
            <h1 className="sm:text-[250px] text-9xl font-bold bg-clip-text text-transparent bg-gradient-to-br to-[#1d5b79] from-[#a9c8e2]">
              0
            </h1>
            <h1 className="sm:text-[250px] text-9xl font-bold bg-clip-text text-transparent bg-gradient-to-br from-[#1d5b79] to-[#a9c8e2]">
              4
            </h1>
          </div>
          <div className="mt-10 flex items-center justify-center">
            <p className="sm:text-3xl text-xl sm:ml-0 ml-4 px-4  text-[#1d5b79]">
              Seems like you are alone here.
            </p>
          </div>
          <div className="flex items-center justify-center mt-10">
            <Button name={"Go back"} clickFunction={() => navigate(-1)} />
          </div>
        </div>
      </div>
    </>
  );
}

export default NotFound;

import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import FormikSelect from "../Input/FormikSelect";
import { accomodationType, meals, roomType } from "../Data";
import FormikInput from "../Input/FormikInput";
import { City, Country, State } from "country-state-city";
import IconButton from "../Button/IconButton";
import { FaPlus } from "react-icons/fa";
import Select from "react-select";
import Button from "../Button/Button";
import { MdDelete } from "react-icons/md";
import * as Yup from "yup";

function HotelForm({ onClose, onSubmit }) {
  const validationSchema = Yup.object().shape({
    accommodation_type: Yup.string()
      .oneOf(
        ["Hotel", "Resort", "Flat", "Bungalow", "Farm House"],
        "Select a valid accommodation type"
      )
      .required("Accommodation type is required"),
    name: Yup.string()
      .min(2, "Name must be at least 2 characters")
      .max(100, "Name cannot exceed 100 characters")
      .required("Accommodation name is required"),
    address: Yup.string()
      .min(5, "Address must be at least 5 characters")
      .max(200, "Address cannot exceed 200 characters")
      .required("Address is required"),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    rent: Yup.number().when("accommodation_type", {
      is: (val) => ["Bungalow", "Farm House"].includes(val),
      then: (schema) =>
        schema.required("Rent is required.").min(0, "Rent cannot be negative."),
      otherwise: (schema) => schema.notRequired(),
    }),

    location_link: Yup.string()
      .url("Enter a valid URL")
      .required("Location link is required"),
    flats: Yup.array().when("accommodation_type", {
      is: (val) => ["Flat"].includes(val),
      then: (schema) =>
        Yup.array()
          .of(
            Yup.object().shape({
              bhk: Yup.number()
                .typeError("BHK must be a number")
                .integer("BHK must be an integer")
                .min(1, "BHK must be at least 1")
                .required("BHK is required"),
              occupancy: Yup.number()
                .typeError("Occupancy must be a number")
                .integer("Occupancy must be an integer")
                .min(1, "Occupancy must be at least 1")
                .required("Occupancy is required"),
              room_number: Yup.string()
                .min(1, "Room number is required")
                .max(10, "Room number cannot exceed 10 characters")
                .required("Room number is required"),
              rate: Yup.number()
                .typeError("Rate must be a number")
                .min(0, "Rate cannot be negative")
                .required("Rate is required"),
            })
          )
          .min(1, "At least one flat is required")
          .required("Flats are required"),
      otherwise: (schema) => Yup.array().notRequired(),
    }),
    rooms: Yup.array().when("accommodation_type", {
      is: (val) => ["Hotel", "Resort"].includes(val),
      then: (schema) =>
        Yup.array()
          .of(
            Yup.object().shape({
              room_type: Yup.string().required("Room type is required"),
              meals: Yup.array(),
              room_number: Yup.string()
                .min(1, "Room number is required")
                .max(10, "Room number cannot exceed 10 characters")
                .required("Room number is required"),
              occupancy: Yup.number()
                .typeError("Occupancy must be a number")
                .integer("Occupancy must be an integer")
                .min(1, "Occupancy must be at least 1")
                .required("Occupancy is required"),
              rate: Yup.number()
                .typeError("Rate must be a number")
                .min(0, "Rate cannot be negative")
                .required("Rate is required"),
            })
          )
          .min(1, "At least one room is required")
          .required("Rooms are required"),
      otherwise: (schema) => Yup.array().notRequired(),
    }),
  });

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const fetchCountries = () => {
    const countries = Country.getAllCountries();
    const simplifiedCountries = countries.flat(Infinity);
    setCountries(simplifiedCountries);
  };

  const handleChangeCountry = (value, setFieldValue) => {
    const selectedCountry = countries.find((data) => data.name === value);
    if (selectedCountry) {
      setFieldValue("country", value);
      fetchStates(selectedCountry.isoCode);
    } else {
      setFieldValue("country", "");
      setStates([]);
      setCities([]);
    }
  };

  const fetchStates = (code) => {
    const states = State.getStatesOfCountry(code);
    setStates(states);
  };

  const handleStateChange = (value, setFieldValue) => {
    const selectedState = states.find((data) => data.name === value);
    if (selectedState) {
      setFieldValue("state", value);
      fetchCities(selectedState);
    } else {
      setFieldValue("state", "");
      setCities([]);
    }
  };

  const fetchCities = (state) => {
    if (state) {
      const cities = City.getCitiesOfState(state.countryCode, state.isoCode);
      setCities(cities);
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  return (
    <div className="fixed inset-0 flex items-center justify-center overflow-auto z-50 bg-black bg-opacity-50 ">
      <div className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto ">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-4xl w-full mx-auto ">
          <div className="flex items-end justify-end">
            <button
              className="bg-[#102C57] p-2 text-lg text-[#fbffff] rounded-full "
              onClick={onClose}
            >
              <RxCross1 />
            </button>
          </div>
          <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#102C57]">
            Accommodation
          </h2>
          <Formik
            initialValues={{
              accommodation_type: "",
              name: "",
              address: "",
              country: "",
              state: "",
              city: "",
              location_link: "",
              rooms: [],
              flats: [],
              rent: 0,
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ setFieldValue, values, errors }) => (
              <Form>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                  {console.log(errors)}
                  <div>
                    <FormikSelect
                      name={"accommodation_type"}
                      optionData={accomodationType}
                      selectedOption={"Accommodation"}
                      label={"Accommodation type"}
                      labelProperty={"type"}
                      valueProperty={"type"}
                      onChange={(e) =>
                        setFieldValue("accommodation_type", e.target.value)
                      }
                    />
                  </div>
                  <div>
                    <FormikInput
                      name={"name"}
                      label={"Accommodation name"}
                      type={"text"}
                      placeholder={"La Grande"}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-4 gap-2 sm:gap-4 mt-2 sm:mt-4">
                  <div>
                    <FormikInput
                      name={"address"}
                      type={"text"}
                      label={"Address"}
                      placeholder={"36th Street"}
                    />
                  </div>
                  <div>
                    <FormikSelect
                      name={"country"}
                      optionData={countries}
                      selectedOption={"Country"}
                      label={"Country"}
                      labelProperty={"name"}
                      valueProperty={"name"}
                      onChange={(e) =>
                        handleChangeCountry(e.target.value, setFieldValue)
                      }
                    />
                  </div>
                  <div>
                    <FormikSelect
                      name={"state"}
                      optionData={states}
                      selectedOption={"State"}
                      label={"State"}
                      labelProperty={"name"}
                      valueProperty={"name"}
                      onChange={(e) =>
                        handleStateChange(e.target.value, setFieldValue)
                      }
                    />
                  </div>
                  <div>
                    <FormikSelect
                      name={"city"}
                      optionData={cities}
                      selectedOption={"City"}
                      label={"City"}
                      labelProperty={"name"}
                      valueProperty={"name"}
                      onChange={(e) => setFieldValue("city", e.target.value)}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                  <div>
                    <FormikInput
                      name={"location_link"}
                      placeholder={"Google map link"}
                      label={"Location Link"}
                      type={"text"}
                    />
                  </div>
                  {(values.accommodation_type === "Bungalow" ||
                    values.accommodation_type === "Farm House") && (
                    <div>
                      <FormikInput
                        name={"rent"}
                        label={"Rent"}
                        placeholder={"12***"}
                        type={"number"}
                        min={0}
                      />
                    </div>
                  )}
                </div>
                {values.accommodation_type === "Flat" && (
                  <FieldArray name="flats">
                    {({ push, remove }) => (
                      <div>
                        <div className="flex items-start justify-start flex-row mt-4">
                          <h2 className="text-[#102C57] mt-1 font-semibold text-lg">
                            Add Flat :
                          </h2>
                          <div className="ml-4">
                            <IconButton
                              icon={<FaPlus />}
                              name={"Add"}
                              type={"button"}
                              clickFunction={() =>
                                push({
                                  bhk: "",
                                  occupancy: "",
                                  room_number: "",
                                  rate: "",
                                })
                              }
                            />
                          </div>
                        </div>
                        {values.flats.map((_, index) => (
                          <div key={index}>
                            <div className="flex justify-between items-center mt-5">
                              <h2 className="text-base sm:text-xl font-semibold mb-2 sm:mb-4 mt-2 sm:mt-4 text-[#020002]">
                                Flat {index + 1}
                              </h2>
                              <button
                                type="button"
                                onClick={() => remove(index)}
                                className="bg-[#e70a18] p-2 text-lg text-[#fbffff] rounded-full mt-2"
                              >
                                <MdDelete />
                              </button>
                            </div>
                            <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 sm:gap-4 mt-2 sm:mt-4">
                              <div>
                                <FormikInput
                                  name={`flats.${index}.bhk`}
                                  placeholder={"BHK"}
                                  label={"BHK"}
                                  type={"number"}
                                  min={1}
                                />
                              </div>
                              <div>
                                <FormikInput
                                  name={`flats.${index}.occupancy`}
                                  placeholder={"5"}
                                  label={"Occupancy"}
                                  type={"number"}
                                  min={1}
                                />
                              </div>
                              <div>
                                <FormikInput
                                  name={`flats.${index}.room_number`}
                                  placeholder={"1401"}
                                  label={"Flat Number"}
                                  min={1}
                                  type={"text"}
                                />
                              </div>
                              <div>
                                <FormikInput
                                  name={`flats.${index}.rate`}
                                  placeholder={"140**"}
                                  label={"Rent"}
                                  min={1}
                                  type={"number"}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </FieldArray>
                )}
                {(values.accommodation_type === "Hotel" ||
                  values.accommodation_type === "Resort") && (
                  <FieldArray name="rooms">
                    {({ push, remove }) => (
                      <div>
                        <div className="flex items-start justify-start flex-row mt-4">
                          <h2 className="text-[#102C57] mt-1 font-semibold text-lg">
                            Add Room :
                          </h2>
                          <div className="ml-4">
                            <IconButton
                              icon={<FaPlus />}
                              name={"Add"}
                              type={"button"}
                              clickFunction={() =>
                                push({
                                  room_type: "",
                                  meals: [],
                                  room_number: "",
                                  occupancy: "",
                                  rate: "",
                                })
                              }
                            />
                          </div>
                        </div>
                        {values.rooms.map((_, index) => (
                          <div key={index}>
                            <div className="flex justify-between items-center mt-5">
                              <h2 className="text-base sm:text-xl font-semibold mb-2 sm:mb-4 mt-2 sm:mt-4 text-[#020002]">
                                Room {index + 1}
                              </h2>
                              <button
                                type="button"
                                onClick={() => remove(index)}
                                className="bg-[#e70a18] p-2 text-lg text-[#fbffff] rounded-full mt-2"
                              >
                                <MdDelete />
                              </button>
                            </div>
                            <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 sm:gap-4 mt-2 sm:mt-4">
                              <div>
                                <FormikSelect
                                  name={`rooms.${index}.room_type`}
                                  label={"Room type"}
                                  selectedOption={"Room type"}
                                  optionData={roomType}
                                  labelProperty={"type"}
                                  valueProperty={"type"}
                                  onChange={(e) =>
                                    setFieldValue(
                                      `rooms.${index}.room_type`,
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                              <div>
                                <label className="block mb-2 text-sm text-gray-600">
                                  Meals
                                </label>
                                <Select
                                  name={`rooms.${index}.meals`}
                                  options={meals}
                                  isMulti
                                  getOptionLabel={(option) => option.type}
                                  getOptionValue={(option) => option.type}
                                  onChange={(selectedOptions) =>
                                    setFieldValue(
                                      `rooms.${index}.meals`,
                                      selectedOptions.map(
                                        (option) => option.type
                                      )
                                    )
                                  }
                                />
                              </div>
                              <div>
                                <FormikInput
                                  name={`rooms.${index}.room_number`}
                                  label={"Room number"}
                                  placeholder={"1402"}
                                  type={"number"}
                                  min={1}
                                />
                              </div>
                              <div>
                                <FormikInput
                                  name={`rooms.${index}.occupancy`}
                                  label={"Occupancy"}
                                  placeholder={"2"}
                                  type={"number"}
                                  min={1}
                                />
                              </div>
                              <div>
                                <FormikInput
                                  name={`rooms.${index}.rate`}
                                  label={"Rate"}
                                  placeholder={"12***"}
                                  type={"number"}
                                  min={1}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </FieldArray>
                )}
                <div className="sm:mt-4 mt-2">
                  <Button type={"submit"} name={"Add"} />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default HotelForm;

import React, { useContext, useState } from "react";
import { MdModeEdit } from "react-icons/md";
import { BiSolidTrashAlt } from "react-icons/bi";
import SubRoleContext from "../../SubRoleContext";
import ReferenceImages from "../../Modal/ReferenceImages";
import Pagination from "../Pagination/Pagination";

function SceneContinuityTable({
  setupdateView,
  updateview,
  scene_continuities,
  setIdforUpdate,
  setSelectedSceneContinuity,
  fetchData,
  openModal,
}) {
  const subRole = useContext(SubRoleContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [continuityImage, setContinuityImage] = useState("");

  const handleViewImages = (id) => {
    const selectedContinuity = scene_continuities.find(
      (scene) => scene.id === id
    );

    setOpen(true);
    setContinuityImage(selectedContinuity);
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = scene_continuities
    .sort((a, b) => a.id - b.id)
    .slice(indexOfFirstItem, indexOfLastItem);
  const updViewHandler = (id) => {
    setupdateView(!updateview);
    const datatobeupdated = scene_continuities.find((data) => data.id === id);
    setSelectedSceneContinuity(datatobeupdated);
  };
  const totalPages = Math.ceil(scene_continuities.length / itemsPerPage);

  return (
    <>
      <div className="flex flex-col h-full">
        <div className="w-full mx-auto bg-white  rounded-sm border border-gray-200">
          <header className="flex sm:flex-row flex-col  justify-between px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold text-gray-800">Scene continuity</h2>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
            />
          </header>
          <div className="p-3">
            <div className="overflow-x-auto">
              <table className="table-auto w-full">
                <thead className="text-xs font-semibold uppercase text-gray-600 bg-[#eceff1]">
                  <tr>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Scene no</div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Continuity scene
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">
                        Continuity type
                      </div>
                    </th>
                    <th className="px-4 py-2 whitespace-nowrap">
                      <div className="font-semibold text-left">Remark</div>
                    </th>
                    {subRole === "user" ? (
                      <></>
                    ) : (
                      <>
                        <th className="px-4 py-2 whitespace-nowrap">
                          <div className="font-semibold text-center">
                            View Reference images
                          </div>
                        </th>
                        <th className="px-4 py-2 whitespace-nowrap">
                          <div className="font-semibold text-center">
                            Action
                          </div>
                        </th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody className="text-sm divide-y divide-gray-100">
                  {currentItems.map((c_scene) => {
                    const c_scenesArray = Object.values(c_scene.c_scenes);
                    const scenes = c_scenesArray
                      .map((scene) => scene.scene_number)
                      .join(",");

                    return (
                      <tr>
                        <td className="p-2 whitespace-nowrap">
                          {c_scene.scene_number}
                        </td>
                        <td className="p-2 ">
                          <div style={{ width: "200px", textAlign: "justify" }}>
                            {scenes}
                          </div>
                        </td>
                        <td className="p-2">
                          <div
                            className="text-center"
                            style={{ width: "200px", textAlign: "justify" }}
                          >
                            {c_scene.continuity_types.join(", ")}
                          </div>
                        </td>
                        <td className="p-2 whitespace-nowrap">
                          <div
                            className="text-center"
                            style={{ width: "400px", textAlign: "justify" }}
                          >
                            {c_scene.remarks}
                          </div>
                        </td>

                        {subRole === "user" ? (
                          <> </>
                        ) : (
                          <>
                            <td className="p-2 whitespace-nowrap">
                              <div className="text-center">
                                <button
                                  onClick={() => handleViewImages(c_scene.id)}
                                  className="hover:underline font-semibold hover:text-gray-800 duration-500 "
                                >
                                  View
                                </button>
                              </div>
                            </td>
                            <td className="p-4 whitespace-nowrap flex">
                              <button
                                onClick={() => updViewHandler(c_scene.id)}
                                className="p-4 text-xl text-green-600 border-none hover:shadow-none rounded-full outline-none bg-green-50  duration-300 ease-in"
                              >
                                <MdModeEdit />
                              </button>
                              <button
                                onClick={() => openModal(c_scene)}
                                className="ml-4 p-4 text-xl text-red-600 bg-red-50 hover:shadow-none rounded-full  duration-300 ease-in outline-none"
                              >
                                <BiSolidTrashAlt />
                              </button>
                            </td>
                          </>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {open && (
        <ReferenceImages
          setOpen={setOpen}
          continuityImage={continuityImage}
          fetchData={fetchData}
        />
      )}
    </>
  );
}

export default SceneContinuityTable;

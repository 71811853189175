import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import Signup from "./Pages/Signup";
import Login from "./Pages/Login";
import Project from "./Pages/Project";
import ProjectOverview from "./Pages/ProjectDashboard/ProjectOverview";
import Home from "./Pages/LandingPages/Home";
import ActorDashboard from "./Pages/ActorDashboard/ActorDashboard";
import PrivateRoute from "./PrivateRoute";
import ResetPassword from "./Pages/ResetPassword";
import SuperAdmin from "./Pages/SuperAdmin/SuperAdmin";
import Packages from "./Pages/SuperAdmin/Packages";
import Department from "./Pages/SuperAdmin/Department";
import ShotDivision from "./Pages/SuperAdmin/ShotDivision";
import { SubRoleProvider } from "./SubRoleContext";
import NotFound from "./NotFound";
import { SidebarProvider } from "./Pages/SuperAdmin/SidebarContext";
import Vendor from "./Pages/Vendor";
import ExpenseType from "./Pages/ExpenseType";
import Profile from "./Pages/Profile";
import Features from "./Pages/LandingPages/Features";
import Artist from "./Pages/Artist";
import Crew from "./Pages/Crew";
import Partner from "./Pages/Partner";
import Accounts from "./Pages/ProjectDashboard/Accounts";
import ProductionAccounts from "./Pages/ProductionAccounts";
import TeamAccount from "./Pages/TeamAccount";
import TermsAndCondition from "./Pages/Terms,Document/TermsAndCondition";
import Privacy from "./Pages/Terms,Document/Privacy";
import RefundPolicy from "./Pages/Terms,Document/RefundPolicy";
import ContactUs from "./Pages/LandingPages/ContactUs";
import EmailAuth from "./Pages/EmailAuth";

function App() {
  return (
    <SidebarProvider>
      <div>
        <Routes>
          <Route
            path="/actorDashboard/:artistId"
            element={
              <PrivateRoute allowedRole={["artist"]}>
                <ActorDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/projectoverview/:projectId"
            element={
              <PrivateRoute allowedRole={["user", "crew"]}>
                <SubRoleProvider>
                  <ProjectOverview />
                </SubRoleProvider>
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/project"
            element={
              <PrivateRoute allowedRole={["user"]}>
                <Project />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/Profile"
            element={
              <PrivateRoute allowedRole={["user"]}>
                <Profile />
              </PrivateRoute>
            }
          />
          <Route
            path="/Artist"
            element={
              <PrivateRoute allowedRole={["user"]}>
                <Artist />
              </PrivateRoute>
            }
          />
          <Route
            path="/Crew"
            element={
              <PrivateRoute allowedRole={["user"]}>
                <Crew />
              </PrivateRoute>
            }
          />
          <Route
            path="/Vendor"
            element={
              <PrivateRoute allowedRole={["user"]}>
                <Vendor />
              </PrivateRoute>
            }
          />
          <Route
            path="/Partner"
            element={
              <PrivateRoute allowedRole={["user"]}>
                <Partner />
              </PrivateRoute>
            }
          />
          <Route
            path="/Accounts/:projectId/:title"
            element={
              <PrivateRoute allowedRole={["user"]}>
                <ProductionAccounts />
              </PrivateRoute>
            }
          />
          <Route
            path="/TeamAccounts"
            element={
              <PrivateRoute allowedRole={["user"]}>
                <TeamAccount />
              </PrivateRoute>
            }
          />
          <Route
            path="/Expense-Type"
            element={
              <PrivateRoute allowedRole={["user"]}>
                <ExpenseType />
              </PrivateRoute>
            }
          />
          <Route
            path="/EmailAuth"
            element={
              <PrivateRoute allowedRole={["user"]}>
                <EmailAuth />
              </PrivateRoute>
            }
          />
          <Route
            path="/Super-Admin"
            element={
              <PrivateRoute allowedRole={["superAdmin"]}>
                <SuperAdmin />
              </PrivateRoute>
            }
          />
          <Route
            path="/Packages"
            element={
              <PrivateRoute allowedRole={["superAdmin"]}>
                <Packages />
              </PrivateRoute>
            }
          />
          <Route
            path="/Department"
            element={
              <PrivateRoute allowedRole={["superAdmin"]}>
                <Department />
              </PrivateRoute>
            }
          />
          <Route
            path="/ShotDivision"
            element={
              <PrivateRoute allowedRole={["superAdmin"]}>
                <ShotDivision />
              </PrivateRoute>
            }
          />
          <Route path="/ResetPassword" element={<ResetPassword />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Features" element={<Features />} />
          <Route path="/TermsAndConditions" element={<TermsAndCondition />} />
          <Route path="/PrivacyPolicy" element={<Privacy />} />
          <Route path="/RefundPolicy" element={<RefundPolicy />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/" element={<Home />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </SidebarProvider>
  );
}

export default App;

import React, { useContext, useEffect, useState } from "react";
import Button from "../components/Button/Button";
import VendorForm from "../components/Forms/VendorForm";
import { SidebarContext } from "./SuperAdmin/SidebarContext";
import Sidebar from "../components/Sidebar";
import SettingsVendorTable from "../components/Table/SettingsVendorTable";
import axios from "axios";
import toast from "react-hot-toast";
import VendorUpdateForm from "../components/UpdateForm/VendorUpdateForm";
import DeleteModal from "../Modal/DeleteModal";
import Loader from "../Loader/loader";

function Vendor() {
  const { view } = useContext(SidebarContext);
  const [open, setOpen] = useState(false);
  const [vendor, setVendor] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState([]);
  const [updateVendor, setUpdateVendor] = useState(false);
  const [deleteVendor, setDeleteVendor] = useState(false);
  const [loading, setLoading] = useState(false);
  const getVendor = () => {
    axios
      .get(`api/v1/vendors/index_for_users`)
      .then((response) => {
        setVendor(response.data.vendors);
        setLoading(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getVendor();
  }, []);

  const addVendor = (value, { resetForm }) => {
    const loader = toast.loading("Adding Vendor ...", { autoClose: false });
    axios
      .post(`api/v1/vendors`, {
        vendor: {
          name: value.vendor_name,
          phone_number: value.vendor_phone_number,
          vendor_type: value.vendor_type,
          email: value.vendor_email,
          ifsc_code: value.vendor_ifsc,
          gst_number: value.vendor_gst_number,
          gst_percentage: value.vendor_gst_per,
          tds_percentage: value.vendor_tds,
          pan_card_number: value.vendor_pan_number,
          address: value.address,
          bank_account_number: value.bank_account_number,
          bank_name: value.bank_name,
          bank_branch: value.bank_branch,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setOpen(false);
          getVendor();
          toast.dismiss(loader);
          toast.success(response.data.message);
          resetForm();
        }
      })
      .catch((error) => {
        toast.dismiss(loader);
        toast.error(error.message);
        console.log(error.message);
      });
  };

  const updateVendorHandler = (value, { resetForm }) => {
    const loader = toast.loading("Updating Vendor ...", { autoClose: false });
    axios
      .put(`api/v1/vendors/${selectedVendor.id}`, {
        vendor: {
          name: value.vendor_name,
          phone_number: value.vendor_phone_number,
          vendor_type: value.vendor_type,
          email: value.vendor_email,
          ifsc_code: value.vendor_ifsc,
          gst_number: value.vendor_gst_number,
          gst_percentage: value.vendor_gst_per,
          tds_percentage: value.vendor_tds,
          pan_card_number: value.vendor_pan_number,
          address: value.address,
          bank_account_number: value.bank_account_number,
          bank_name: value.bank_name,
          bank_branch: value.bank_branch,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          toast.dismiss(loader);
          toast.success(response.data.message);
          setSelectedVendor("");
          setUpdateVendor(false);
          getVendor();
          resetForm();
        }
      })
      .catch((error) => {
        toast.dismiss(loader);
        console.log(error.message);
        toast.error(error.message);
      });
  };

  const handleDeleteVendor = () => {
    const loader = toast.loading("Deleting Vendor ...", { autoClose: false });
    axios
      .delete(`api/v1/vendors/${selectedVendor.id}`)
      .then((response) => {
        if (response.status === 200) {
          getVendor();
          setDeleteVendor(false);
          toast.dismiss(loader);
          toast.success(response.data.message);
          setSelectedVendor("");
        }
      })
      .catch((error) => {
        console.log(error);
        toast.dismiss(loader);
        toast.error(error.message);
      });
  };

  const handleUpdateVendorView = (id) => {
    const handleVendor = vendor.find((Ven) => Ven.id === id);
    setSelectedVendor(handleVendor);
    setUpdateVendor(true);
  };
  const handleDeleteVendorView = (id) => {
    const handleVendor = vendor.find((Ven) => Ven.id === id);
    setSelectedVendor(handleVendor);
    setDeleteVendor(true);
  };

  return (
    <div className=" ">
      <Sidebar />
      <div
        className={`h-screen p-7 ${
          !view ? "ml-40 duration-100 ease-in" : "ml-0 duration-100 ease-in"
        }`}
      >
        {!loading ? (
          <Loader />
        ) : (
          <div className="flex flex-col sm:flex sm:flex-col p-5">
            <div className="flex sm:items-start items-center justify-center sm:justify-start xl:ml-20 2xl:ml-20 md:ml-20 sm:ml-20 ml-0 sm:mt-0 mt-8">
              <Button name={"Add Vendor"} clickFunction={() => setOpen(true)} />
            </div>
            <div className="flex items-center justify-center  xl:ml-20 2xl:ml-20 lg:ml-20 md:ml-20 sm:ml-20 ml-0">
              <div className="w-full mt-5">
                <SettingsVendorTable
                  vendor={vendor}
                  handleUpdateVendorView={handleUpdateVendorView}
                  handleDeleteVendorView={handleDeleteVendorView}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {open && <VendorForm setOpen={setOpen} addVendor={addVendor} />}
      {updateVendor && (
        <VendorUpdateForm
          setUpdateVendor={setUpdateVendor}
          selectedVendor={selectedVendor}
          updateVendorHandler={updateVendorHandler}
        />
      )}
      {deleteVendor && (
        <DeleteModal
          title={"Remove vendor"}
          message={`Do you want to remove ${selectedVendor.name} ? `}
          onDelete={handleDeleteVendor}
          onClose={() => {
            setDeleteVendor(false);
            setSelectedVendor("");
          }}
          icon={true}
        />
      )}
    </div>
  );
}

export default Vendor;

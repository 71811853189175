import React, { useState, useEffect } from "react";
import "./loader.css";
import { Dialogue } from "../components/Data";

function Loader() {
  const [randomDialogue, setRandomDialogue] = useState("");

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * Dialogue.length);
    setRandomDialogue(Dialogue[randomIndex].dialogue);
  }, []);

  return (
    <div className="h-screen overflow-hidden flex flex-col items-center justify-center">
      <div className="spinner">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <p className="flex items-center justify-center text-base text-[#1d5b79] font-mono w-[70%] ">
        {randomDialogue}
      </p>
    </div>
  );
}

export default Loader;

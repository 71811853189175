import React from "react";
import { ErrorMessage, Field, Formik } from "formik";
import * as Yup from "yup";
import { Form } from "formik";
import FormikInput from "../../../../components/Input/FormikInput";
import Button from "../../../../components/Button/Button";
import ShotDivisionTable from "../Table/ShotDivisionTable";
import axios from "axios";
import toast from "react-hot-toast";
import { values } from "lodash";
function CardViewForShot({
  CardName,
  ShotDivisiondata,
  Shotangle,
  Shotfocus,
  Shottype,
  Shotframe,
  Shotmechanism,
  Shotmovements,
  getShotDivisionData,
}) {
  const validationSchema = Yup.object().shape({
    shottype: CardName.toLowerCase().includes("type")
      ? Yup.string().required("required")
      : Yup.string(),
    shotfocus: CardName.toLowerCase().includes("focus")
      ? Yup.string().required("required")
      : Yup.string(),
    shotangle: CardName.toLowerCase().includes("angle")
      ? Yup.string().required("required")
      : Yup.string(),
    shotframe: CardName.toLowerCase().includes("frame")
      ? Yup.string().required("required")
      : Yup.string(),
    shotmechanism: CardName.toLowerCase().includes("mechanism")
      ? Yup.string().required("required")
      : Yup.string(),
    shotmovements: CardName.toLowerCase().includes("movements")
      ? Yup.string().required("required")
      : Yup.string(),
  });

  const handleAddShot = (values, { resetForm }) => {
    CardName.toLowerCase().includes("angle")
      ? Shotangle.push(values.shotangle)
      : CardName.toLowerCase().includes("focus")
      ? Shotfocus.push(values.shotfocus)
      : CardName.toLowerCase().includes("type")
      ? Shottype.push(values.shottype)
      : CardName.toLowerCase().includes("frame")
      ? Shotframe.push(values.shotframe)
      : CardName.toLowerCase().includes("mechanism")
      ? Shotmechanism.push(values.shotmechanism)
      : Shotmovements.push(values.shotmovements);
    axios
      .put("api/v1/shots/1", {
        shot: {
          add_shot_angle: Shotangle,
          add_shot_focus: Shotfocus,
          add_shot_type: Shottype,
          add_shot_frame: Shotframe,
          add_shot_mechanism: Shotmechanism,
          add_shot_movements: Shotmovements,
        },
      })
      .then((response) => {
        toast.success("data added....");
        getShotDivisionData();
        console.log(response);

        resetForm();
      })
      .catch((error) => {
        toast.error(error?.response?.data?.add_shot_type[0]);
        console.log(error);
      });
  };
  return (
    <div className="bg-[#eaf2f2] p-4 sm:p-8 rounded shadow-md max-w-lg w-full mx-auto">
      <h2 className="text-lg text-center sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
        {CardName}
      </h2>
      <Formik
        validationSchema={validationSchema}
        initialValues={
          CardName.toLowerCase().includes("angle")
            ? { shotangle: "" }
            : CardName.toLowerCase().includes("focus")
            ? { shotfocus: "" }
            : CardName.toLowerCase().includes("type")
            ? { shottype: "" }
            : CardName.toLowerCase().includes("frame")
            ? { shotframe: "" }
            : CardName.toLowerCase().includes("mechanism")
            ? { shotmechanism: "" }
            : { shotmovements: "" }
        }
        onSubmit={handleAddShot}
      >
        <Form>
          <div className="flex flex-row ">
            <div className="ml-2 w-full">
              <label className="block mb-2 text-sm text-gray-600 ">
                Add {CardName}
              </label>
              <Field
                name={
                  CardName.toLowerCase().includes("angle")
                    ? "shotangle"
                    : CardName.toLowerCase().includes("focus")
                    ? "shotfocus"
                    : CardName.toLowerCase().includes("type")
                    ? "shottype"
                    : CardName.toLowerCase().includes("frame")
                    ? "shotframe"
                    : CardName.toLowerCase().includes("mechanism")
                    ? "shotmechanism"
                    : "shotmovements"
                }
                type="text"
                placeholder={"Add " + CardName}
                className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#1d5b79]  focus:ring-[#1d5b79] focus:outline-none focus:ring focus:ring-opacity-40"
              />
              <ErrorMessage
                name={
                  CardName.toLowerCase().includes("angle")
                    ? "shotangle"
                    : CardName.toLowerCase().includes("focus")
                    ? "shotfocus"
                    : CardName.toLowerCase().includes("type")
                    ? "shottype"
                    : CardName.toLowerCase().includes("frame")
                    ? "shotframe"
                    : CardName.toLowerCase().includes("mechanism")
                    ? "shotmechanism"
                    : "shotmovements"
                }
                className="text-red-500 text-sm mt-1"
                component="div"
              />
            </div>
            <div className="ml-5 mt-8">
              <Button name={"Add"} type={"submit"} />
            </div>
          </div>
        </Form>
      </Formik>
      <div className="mb-10 w-full sm:mt-5 mt-5">
        <ShotDivisionTable
          data={ShotDivisiondata}
          CardName={CardName}
          Shotangle={Shotangle}
          Shotfocus={Shotfocus}
          Shottype={Shottype}
          Shotframe={Shotframe}
          Shotmechanism={Shotmechanism}
          Shotmovements={Shotmovements}
          tableHead={CardName}
          getShotDivisionData={getShotDivisionData}
          validationSchema={validationSchema}
        />
      </div>
    </div>
  );
}

export default CardViewForShot;

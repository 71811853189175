import axios from "axios";
import React, { useEffect, useState } from "react";
import Loader from "../../Loader/loader";
import ErrorPage from "../ErrorPage";
import moment from "moment-timezone";
import "moment/locale/en-in";
import Input from "../../components/Input/Input";
import TeamCrewTable from "../../components/Table/TeamCrewTable";
import Select from "../../components/Input/Select";
import { TbFilterOff } from "react-icons/tb";
function TeamCrewAccount() {
  const [crews, setCrews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [years, setYears] = useState([]);
  const [projects, setProjects] = useState([]);
  const [year, setYear] = useState("");
  const [crew, setCrew] = useState("");
  const [project, setProject] = useState("");

  const getCrew = () => {
    axios
      .get(`api/v1/users/crews_summary`)
      .then((res) => {
        setLoading(true);
        setCrews(res.data.crews);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setError(true);
      });
  };
  const fetchAllProjects = () => {
    axios
      .get(`api/v1/projects`)
      .then((response) => {
        const project = response.data.projects;
        setProjects(project);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchYears = () => {
    const years = Array.from({ length: 21 }, (_, i) => ({
      year: 2020 + i,
      year_name: 2020 + i,
    }));
    setYears(years);
  };

  useEffect(() => {
    getCrew();
    fetchAllProjects();
    fetchYears();
  }, []);
  const convertToIST = (utcDateStr) => {
    return moment.utc(utcDateStr).tz("Asia/Kolkata").format("YYYY");
  };
  const filterProjectOnBaseOfYear = projects.filter((data) =>
    convertToIST(data.created_at).includes(year)
  );

  const filterOnBaseOfProject = crews.filter((crew) =>
    crew.project.title.toLowerCase().includes(project.toLowerCase())
  );

  const filterOnBaseOfCrewName = filterOnBaseOfProject.filter((data) =>
    data.crew.full_name.toLowerCase().includes(crew.toLowerCase())
  );

  return (
    <>
      {error ? (
        <ErrorPage />
      ) : (
        <div>
          {!loading ? (
            <Loader />
          ) : (
            <div className="flex flex-col sm:flex sm:flex-col p-5">
              <div className="flex sm:flex-row flex-col items-center justify-between">
                <div className="grid sm:grid-cols-4 grid-cols-2 gap-2 sm:gap-4 w-full">
                  <div>
                    <Select
                      label={"Year"}
                      optionData={years}
                      selectedOption={"Year"}
                      value={year}
                      onChangeFunction={(e) => setYear(e.target.value)}
                      propertyLabel={"year_name"}
                      propertyValue={"year"}
                    />
                  </div>
                  <div>
                    <Select
                      label={"Project"}
                      optionData={filterProjectOnBaseOfYear}
                      selectedOption={"Project"}
                      value={project}
                      onChangeFunction={(e) => setProject(e.target.value)}
                      propertyLabel={"title"}
                      propertyValue={"title"}
                    />
                  </div>
                  <div>
                    <Input
                      label={"Crew"}
                      type={"text"}
                      inputValue={crew}
                      placeholderValue={"name"}
                      onChangeFunction={(e) => setCrew(e.target.value)}
                    />
                  </div>
                  <div className="ml-5 mt-7">
                    <button
                      onClick={() => {
                        setCrew("");
                        setProject("");
                        setYear("");
                      }}
                      className="bg-[#1d5b79] p-2 text-xl text-white rounded-full"
                    >
                      <TbFilterOff />
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center">
                <div className="w-full mt-5">
                  <TeamCrewTable detail={filterOnBaseOfCrewName} />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default TeamCrewAccount;

import { FieldArray, Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import { RxCross1 } from "react-icons/rx";
import FormikInput from "../Input/FormikInput";
import FormikSelect from "../Input/FormikSelect";
import File from "../Input/File";
import Button from "../Button/Button";
import Select from "react-select";
import { FaTrash } from "react-icons/fa6";

function ProjectUpdateForm({
  selectedProject,
  setUpdateView,
  stopClose,
  setImage,
  handleUpdateProject,
  partners,
}) {
  const [selectedPartners, setSelectedPartners] = useState([]);

  // Filter partners to remove already selected ones from options
  useEffect(() => {
    const alreadySelected = selectedProject.partners.map(
      (partner) => partner.id
    );
    const selectedPartnersList = partners.filter((partner) =>
      alreadySelected.includes(partner.id)
    );
    setSelectedPartners(selectedPartnersList);
  }, [selectedProject, partners]);

  const Options = [
    { id: 1, name: "Movie" },
    { id: 1, name: "WebSeries" },
    { id: 1, name: "Short Film" },
    { id: 1, name: "Documentary" },
  ];

  const handleCategoryChange = (value, setFieldValue) => {
    if (value) {
      setFieldValue("cinema_type", value);
    } else {
      setFieldValue("cinema_type", "");
    }
  };

  const handlePartnerChange = (selectedOptions, push, remove, values) => {
    setSelectedPartners(selectedOptions);

    const selectedPartnerIds = selectedOptions.map((option) => option.id);

    selectedPartnerIds.forEach((partnerId) => {
      const partnerAlreadyAdded = values.partner_attributes.some(
        (partner) => partner.partner_id === partnerId
      );

      if (!partnerAlreadyAdded) {
        const partner = selectedOptions.find(
          (option) => option.id === partnerId
        );
        push({
          partner_id: partner.id,
          partner_name: partner.name,
          amount: "",
          percentage: "",
          old: false,
        });
      }
    });

    values.partner_attributes.forEach((partner, index) => {
      if (!selectedPartnerIds.includes(partner.partner_id)) {
        remove(index);
      }
    });
  };

  const handleRemovePartner = (index, remove, values, isOldPartner) => {
    if (isOldPartner) {
      // For old partners, mark the partner as deleted by setting _destroy to true
      values.partner_attributes[index]._destroy = true;
    } else {
      // For new partners, directly remove them from the array
      remove(index);
    }

    // After marking or removing, filter out the partners that are marked for deletion (_destroy: true)
    const updatedSelectedPartners = values.partner_attributes.filter(
      (partner) => !partner._destroy // Exclude partners marked for deletion
    );

    // Update selectedPartners with the filtered list
    setSelectedPartners(updatedSelectedPartners);
  };

  const newPartnerArray = selectedProject.partners.map((data) => {
    return {
      project_partner_id: data.project_partner_id,
      partner_id: data.id,
      partner_name: data.name,
      amount: data.invested_amount,
      percentage: data.percentage,
      _destroy: false,
      old: true,
    };
  });

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
      onClick={() => setUpdateView(false)}
    >
      <div
        className="flex items-center justify-center z-50"
        onClick={stopClose}
      >
        <div className="bg-white p-8 rounded shadow-md max-w-lg w-full mx-auto">
          <h2 className="text-2xl font-semibold mb-4 text-[#1d5b79] ">
            {selectedProject.title}'s details
          </h2>
          <div>
            <Formik
              initialValues={{
                project_name: selectedProject.title || "",
                cinema_type: selectedProject.cinema_type || "",
                image: selectedProject.poster || "",
                partner_attributes: newPartnerArray || [],
              }}
              onSubmit={(value, { resetForm }) => {
                handleUpdateProject(selectedProject.id, value, { resetForm });
              }}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="grid sm:grid-cols-2 grid-cols-1 gap-4">
                    <div>
                      <FormikInput
                        name={"project_name"}
                        type={"text"}
                        label={"Project Name"}
                        placeholder={selectedProject.title}
                      />
                    </div>

                    <div>
                      <FormikSelect
                        name={"cinema_type"}
                        label={"Category"}
                        optionData={Options}
                        onChange={(e) =>
                          handleCategoryChange(e.target.value, setFieldValue)
                        }
                        selectedOption={"Category"}
                      />
                    </div>
                    <div>
                      <File
                        label={"Poster"}
                        accept={"image/*"}
                        onChangeFunction={(e) => setImage(e.target.files[0])}
                      />
                    </div>
                    <FieldArray name="partner_attributes">
                      {({ push, remove }) => (
                        <div>
                          <label className="block mb-2 text-sm text-gray-600 ">
                            Partners
                          </label>
                          <Select
                            name="partner_attributes"
                            options={partners.filter(
                              (partner) =>
                                !values.partner_attributes.some(
                                  (selected) =>
                                    selected.partner_id === partner.id
                                )
                            )}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            isMulti
                            isClearable={false}
                            value={selectedPartners}
                            onChange={(selectedOption) =>
                              handlePartnerChange(
                                selectedOption,
                                push,
                                remove,
                                values
                              )
                            }
                          />
                        </div>
                      )}
                    </FieldArray>
                  </div>
                  <FieldArray name="partner_attributes">
                    {({ remove }) => (
                      <div className="grid grid-cols-1 gap-4 mt-4">
                        {values.partner_attributes.map((data, index) => (
                          <div>
                            {data._destroy === true ? (
                              <div></div>
                            ) : (
                              <div
                                key={index}
                                className="flex flex-col items-start justify-start w-full"
                              >
                                <div className="flex justify-between w-full">
                                  <h2 className="mt-2">{data.partner_name}</h2>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleRemovePartner(
                                        index,
                                        remove,
                                        values,
                                        data.old
                                      )
                                    }
                                    className="bg-red-100 text-red-500 p-2 rounded-full outline-none"
                                  >
                                    <FaTrash />
                                  </button>
                                </div>
                                <div className="grid grid-cols-2 gap-4">
                                  <div>
                                    <FormikInput
                                      name={`partner_attributes[${index}].amount`}
                                      type={"number"}
                                      placeholder={"Invested Amount"}
                                    />
                                  </div>
                                  <div>
                                    <FormikInput
                                      name={`partner_attributes[${index}].percentage`}
                                      type={"number"}
                                      placeholder={"Percentage"}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </FieldArray>
                  <div className="mt-4">
                    <Button name={"Update"} type={"submit"} />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectUpdateForm;

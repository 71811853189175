import React from "react";

function Input({
  label,
  type,
  onChangeFunction,
  placeholderValue,
  inputValue,
  readOnly
}) 
{
  return (
    <div>
      <label className="block mb-2 text-sm text-gray-600 ">{label}</label>
      <input
        type={type}
        onChange={onChangeFunction}
        value={inputValue}
        readOnly={readOnly}
        placeholder={placeholderValue}
        className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#1d5b79]  focus:ring-[#1d5b79] focus:outline-none focus:ring focus:ring-opacity-40"
      />
    </div>
  );
}

export default Input;

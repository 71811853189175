import React, { useEffect, useState } from "react";
import AccountSummaryTable from "../../components/AccountComponents/AccountSummaryTable";
import { useParams } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import Loader from "../../Loader/loader";
import ErrorPage from "../ErrorPage";

function AccountSummary() {
  const [summary, setSummary] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { projectId } = useParams();

  const getSummaryData = () => {
    axios
      .get(`api/v1/users/accounts_summary/${projectId}`)
      .then((response) => {
        setLoading(true);
        setSummary(response.data);
      })
      .catch((error) => {
        setError(true);
        console.log(error);
        toast.error(error.message);
      });
  };

  useEffect(() => {
    getSummaryData();
  }, []);

  return (
    <>
      {error ? (
        <ErrorPage />
      ) : (
        <div>
          {!loading ? (
            <Loader />
          ) : (
            <div className="flex flex-col sm:flex sm:flex-col p-5">
              <div className="flex sm:items-start items-center justify-center sm:justify-start xl:ml-20 2xl:ml-20 md:ml-20 sm:ml-20 ml-0 sm:mt-0 mt-8">
                <h1 className="text-3xl font-semibold sm:tracking-widest tracking-tight">
                  Account Summary
                </h1>
              </div>
              <div className="flex items-center justify-center  xl:ml-20 2xl:ml-20 lg:ml-20 md:ml-20 sm:ml-20 ml-0">
                <div className="w-full mt-5">
                  <AccountSummaryTable summary={summary} />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default AccountSummary;

import React, { useEffect, useState } from "react";
import ExpensesForm from "../../components/Forms/ExpensesForm";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";
import ExpenseTable from "../../components/Table/ExpenseTable";
import Button from "../../components/Button/Button";
import Loader from "../../Loader/loader";
import Lock from "../../assets/image/Lock.png";
import ErrorPage from "../ErrorPage";
import SecureLS from "secure-ls";

function Expenses() {
  const [view, setView] = useState(false);
  const [viewCatering, setViewCatering] = useState(false);
  const [bill, setBill] = useState(null);
  const [visible, setVisible] = useState(false);
  const [spendingAmount, setSpendingAmount] = useState();
  const [expenseTypeData, setExpenseTypeData] = useState([]);
  const [expenseData, setExpenseData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(false);
  const role = localStorage.getItem("role");
  const ls = new SecureLS();
  const project_crew_id = ls.get("project_crew_id");

  const { projectId } = useParams();

  const viewHandler = () => {
    setView(true);
  };
  const onClose = () => {
    setVisible(false);
    setView(false);
  };
  const stopClose = (e) => {
    e.stopPropagation();
  };

  const handleViewBill = (billUrl) => {
    window.open(billUrl);
  };

  const handleBillHandler = (e) => {
    setBill(e.target.files[0]);
  };

  const handleAddExpense = (values, { resetForm }) => {
    const loaderToast = toast.loading("Adding Expense...", {
      autoClose: false,
    });

    const formData = new FormData();
    formData.append("expense[description]", values.description);
    formData.append("expense[duration]", values.duration);
    formData.append("expense[date]", values.date);
    formData.append("expense[amount]", values.amount);
    formData.append("expense[no_bill_reason]", values.noBillReason);
    formData.append("document_expense", bill ? bill : null);

    axios
      .post(
        `/api/v1/projects/${projectId}/expense_types/${values.type_id}/expenses`,
        formData
      )
      .then((response) => {
        if (response.status === 200) {
          toast.dismiss(loaderToast);
          resetForm();
          toast.success(response.data.message);
          setView(false);
          getExpensesData();
        }
      })
      .catch((error) => {
        toast.dismiss(loaderToast);
        setView(false);
        toast.error(error.response.data.error);
      });
  };

  const getExpenseType = () => {
    axios
      .get(`api/v1/projects/${projectId}/expense_types`)
      .then((response) => {
        setExpenseTypeData(response.data.expense_types);
      })
      .catch((error) => console.log(error.message));
  };

  const getExpensesData = () => {
    axios
      .get(
        `api/v1/projects/${projectId}/expenses/project_expenses?project_crew_id=${project_crew_id}`
      )
      .then((response) => {
        setLoading(true);
        setSpendingAmount(response.data.total_amount_assigned_by_admin);
        setExpenseData(response.data.expenses);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 422) {
            setLoading(true);
            setShowError(true);
          } else {
            setError(true);
          }
        } else {
          setError(true);
          console.log(error);
        }
      });
  };

  useEffect(() => {
    getExpensesData();
    getExpenseType();
  }, []);

  return (
    <div>
      {error ? (
        <ErrorPage />
      ) : (
        <div className="overflow-x-hidden">
          {!loading ? (
            <Loader />
          ) : (
            <>
              {showError ? (
                <div className="flex flex-col items-center justify-center mt-5  xl:ml-20 2xl:ml-20 lg:ml-20 md:ml-20 sm:ml-20 ml-0">
                  <img src={Lock} />
                  <h1 className="text-3xl text-[#1d5b79] font-semibold">
                    You don't have access to this page
                  </h1>
                </div>
              ) : (
                <div className="flex flex-col sm:flex sm:flex-col p-5">
                  <div className="flex sm:flex-row flex-col items-center justify-between w-full xl:ml-20 2xl:ml-20 md:ml-20 sm:ml-20 ml-0 sm:mt-2 mt-8">
                    <div className="flex items-start justify-start ">
                      <div>
                        <Button
                          clickFunction={viewHandler}
                          name={"Add Expense"}
                        />
                      </div>
                    </div>
                    {role === "crew" ? (
                      <div className="flex items-end justify-end sm:mr-32 mr-0 sm:mt-0 mt-3">
                        <div className="bg-[#a9c8e2] shadow-md px-4 py-1 rounded-lg">
                          <h1 className="text-lg font-semibold text-[#1d5b79]">
                            Amount: ₹ {spendingAmount}
                          </h1>
                        </div>
                      </div>
                    ) : (
                      <div className="flex items-end justify-end"></div>
                    )}
                  </div>
                  <div className="flex items-center justify-center  xl:ml-20 2xl:ml-20 md:ml-20 lg:ml-20 sm:ml-20 ml-0">
                    <div className="w-full mt-5">
                      <ExpenseTable
                        data={expenseData}
                        handleViewBill={handleViewBill}
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          {view && (
            <ExpensesForm
              handleBackdropClick={onClose}
              stopClose={stopClose}
              expenseTypeData={expenseTypeData}
              visible={visible}
              handleBillHandler={handleBillHandler}
              handleAddExpense={handleAddExpense}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default Expenses;

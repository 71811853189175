import React, { useContext, useEffect, useState } from "react";
import Button from "../Button/Button";
import { RxCross1 } from "react-icons/rx";
import { ErrorMessage, Field, Form, Formik } from "formik";
import FormikInput from "../Input/FormikInput";
import FormikSelect from "../Input/FormikSelect";
import axios from "axios";
import SubRoleContext from "../../SubRoleContext";
import FormikRadio from "../Input/FormikRadio";
import * as Yup from "yup";
import { amountTypeOption } from "../Data";
import Select from "react-select";

function CrewDetailForm({
  handleBackdropClick,
  addCrewHandler,
  department,
  subCategories,
  crews,
}) {
  const [crew, setCrew] = useState("");
  const subRole = useContext(SubRoleContext);
  const validationSchema =
    subRole === "manager"
      ? Yup.object().shape({
          crew_id: Yup.string().required("Please select crew member."),
          category: Yup.string().required("Category is required"),
          sub_category: Yup.string().required("Subcategory is required"),
          number_of_days: Yup.number().required("Number of days is required"),
          email: Yup.string()
            .required("Please enter a valid email")
            .email("Invalid email format"),
        })
      : Yup.object().shape({
          crew_id: Yup.string().required("Please select crew member."),
          category: Yup.string().required("Category is required"),
          sub_category: Yup.string().required("Subcategory is required"),
          amount_type: Yup.string().required("Amount type is required"),
          amount: Yup.number().required("Amount is required"),
          number_of_days: Yup.number().required("Number of days is required"),
          gst_required: Yup.boolean().required("Select if GST is required"),
          gst_number: Yup.string().when("gst_required", {
            is: true,
            then: (schema) =>
              schema
                .matches(
                  /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Z]{1}[0-9A-Za-z]{1}$/,
                  "Invalid GST number"
                )
                .required("GST number is required"),
            otherwise: (schema) => schema.notRequired(),
          }),
          tds_required: Yup.boolean().required("Select if TDS is required"),
          tds: Yup.number().when("tds_required", {
            is: true,
            then: (schema) =>
              schema
                .typeError("TDS must be a number")
                .positive("TDS must be a positive number")
                .required("TDS is required"),
            otherwise: (schema) => schema.notRequired(),
          }),
        });

  const [subDepartment, setSubDepartment] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");

  const getSubDepartment = (value) => {
    const selected = department.find((detail) => detail.name === value);
    setSelectedDepartment(selected);

    if (selected) {
      axios
        .get(`api/v1/departments/${selected.id}/subdepartments`)
        .then((response) => setSubDepartment(response.data.subdepartments))
        .catch((error) => console.log(error.message));
    }
  };

  useEffect(() => {
    getSubDepartment(selectedDepartment.name);
  }, []);

  const handleDepartmentChange = (value, setFieldValue) => {
    getSubDepartment(value);
    setFieldValue("category", value);
  };

  const handleSubDepartmentChange = (value, setFieldValue) => {
    if (value) {
      setFieldValue("sub_category", value);
    } else {
      setFieldValue("sub_category", value);
    }
  };
  const handlePackageChange = (response, setFieldValue, values) => {
    if (response) {
      setFieldValue("amount_type", response);
    } else {
      setFieldValue("amount_type", response);
    }
    if (values.gst_required === true) {
      if (response === "Per Day") {
        const amount =
          parseInt(values.amount) * parseInt(values.number_of_days);
        const gstAmount = (parseInt(amount) * 18) / 100;
        setFieldValue("gst_amount", gstAmount);
      } else {
        const amount = parseInt(values.amount);
        const gstAmount = (amount * 18) / 100;
        setFieldValue("gst_amount", gstAmount);
      }
    } else {
      setFieldValue("gst_amount", 0);
    }
    if (values.tds_required === true) {
      if (response === "Per Day") {
        const amount =
          parseInt(values.amount) * parseInt(values.number_of_days);
        const tdsAmount = (parseInt(amount) * parseInt(values.tds)) / 100;
        setFieldValue("tds_amount", tdsAmount);
      } else {
        const amount = parseInt(values.amount);
        const tdsAmount = (parseInt(amount) * parseInt(values.tds)) / 100;
        setFieldValue("tds_amount", tdsAmount);
      }
    } else {
      setFieldValue("tds_amount", 0);
      setFieldValue("tds", 0);
    }
  };

  const handleGst = (setFieldValue, response, values) => {
    setFieldValue("gst_required", response);
    if (response === true) {
      if (values.amount_type === "Per Day") {
        const amount =
          parseInt(values.amount) * parseInt(values.number_of_days);
        const gstAmount = (parseInt(amount) * 18) / 100;
        setFieldValue("gst_amount", gstAmount);
      } else {
        const amount = parseInt(values.amount);
        const gstAmount = (amount * 18) / 100;
        setFieldValue("gst_amount", gstAmount);
      }
    } else {
      setFieldValue("gst_amount", 0);
    }
  };

  const handleTDSChange = (setFieldValue, response, values) => {
    setFieldValue("tds", response);
    if (values.tds_required === true) {
      if (values.amount_type === "Per Day") {
        const amount =
          parseInt(values.amount) * parseInt(values.number_of_days);
        const tdsAmount = (parseInt(amount) * parseInt(response)) / 100;
        setFieldValue("tds_amount", tdsAmount);
      } else {
        const amount = parseInt(values.amount);
        const tdsAmount = (parseInt(amount) * parseInt(response)) / 100;
        setFieldValue("tds_amount", tdsAmount);
      }
    } else {
      setFieldValue("tds_amount", 0);
      setFieldValue("tds", 0);
    }
  };

  const handleAmountChange = (setFieldValue, response, values) => {
    setFieldValue("amount", response);
    if (values.gst_required === true) {
      if (values.amount_type === "Per Day") {
        const amount = parseInt(response) * parseInt(values.number_of_days);
        const gstAmount = (parseInt(amount) * 18) / 100;
        setFieldValue("gst_amount", gstAmount);
      } else {
        const amount = parseInt(response);
        const gstAmount = (amount * 18) / 100;
        setFieldValue("gst_amount", gstAmount);
      }
    } else {
      setFieldValue("gst_amount", 0);
    }
    if (values.tds_required === true) {
      if (values.amount_type === "Per Day") {
        const amount = parseInt(response) * parseInt(values.number_of_days);
        const tdsAmount = (parseInt(amount) * parseInt(values.tds)) / 100;
        setFieldValue("tds_amount", tdsAmount);
      } else {
        const amount = parseInt(response);
        const tdsAmount = (parseInt(amount) * parseInt(values.tds)) / 100;
        setFieldValue("tds_amount", tdsAmount);
      }
    } else {
      setFieldValue("tds_amount", 0);
      setFieldValue("tds", 0);
    }
  };

  const handleNumberOfDaysChange = (setFieldValue, response, values) => {
    setFieldValue("number_of_days", response);
    if (values.gst_required === true) {
      if (values.amount_type === "Per Day") {
        const amount = parseInt(values.amount) * parseInt(response);
        console.log(amount);
        const gstAmount = (parseInt(amount) * 18) / 100;
        setFieldValue("gst_amount", gstAmount);
      } else {
        const amount = parseInt(values.amount);
        const gstAmount = (amount * 18) / 100;
        setFieldValue("gst_amount", gstAmount);
      }
    } else {
      setFieldValue("gst_amount", 0);
    }
    if (values.tds_required === true) {
      if (values.amount_type === "Per Day") {
        const amount = parseInt(values.amount) * parseInt(response);
        const tdsAmount = (parseInt(amount) * parseInt(values.tds)) / 100;
        setFieldValue("tds_amount", tdsAmount);
      } else {
        const amount = parseInt(values.amount);
        const tdsAmount = (parseInt(amount) * parseInt(values.tds)) / 100;
        setFieldValue("tds_amount", tdsAmount);
      }
    } else {
      setFieldValue("tds_amount", 0);
      setFieldValue("tds", 0);
    }
  };

  const handleCrewChange = (value, setFieldValue) => {
    setCrew({ ...value });
    setFieldValue("full_name", value.id);
  };

  return (
    <div>
      <div className="fixed inset-0 flex items-center justify-center overflow-auto z-50 bg-black bg-opacity-50 ">
        <div className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto ">
          <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-5xl w-full mx-auto ">
            <div className="flex items-end justify-end">
              <button
                className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full "
                onClick={handleBackdropClick}
              >
                <RxCross1 />
              </button>
            </div>
            <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
              Crew
            </h2>
            <div>
              <Formik
                initialValues={{
                  crew_id: "" || crew.id,
                  category: "",
                  sub_category: "",
                  amount_type: "",
                  amount: "",
                  number_of_days: "",
                  gst_required: false,
                  gst_number: "" || crew.gst_number,
                  gst_per: 18,
                  tds_required: false,
                  tds: "",
                  gst_amount: 0,
                  tds_amount: 0,
                  nda_doc: "",
                  agreement_doc: "",
                }}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={addCrewHandler}
              >
                {({ values, setFieldValue, errors }) => (
                  <Form>
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 sm:gap-4 mt-2 sm:mt-4">
                      <div>
                        <label className="block mb-2 text-sm text-gray-600 ">
                          Crew
                        </label>
                        <Select
                          options={crews}
                          name="crew_id"
                          getOptionLabel={(option) => option.full_name}
                          getOptionValue={(option) => option.id}
                          onChange={(selectedOption) =>
                            handleCrewChange(selectedOption, setFieldValue)
                          }
                        />
                        <ErrorMessage
                          component={"div"}
                          name="crew_id"
                          className="text-red-500 text-sm mt-1"
                        />
                      </div>
                      <div>
                        <FormikSelect
                          name={"category"}
                          selectedOption={"Department"}
                          onChange={(e) =>
                            handleDepartmentChange(
                              e.target.value,
                              setFieldValue
                            )
                          }
                          label={"Department"}
                          optionData={department}
                        />
                      </div>
                      <div>
                        <FormikSelect
                          name={"sub_category"}
                          selectedOption={"Position"}
                          onChange={(e) =>
                            handleSubDepartmentChange(
                              e.target.value,
                              setFieldValue
                            )
                          }
                          label={"Sub Department"}
                          optionData={subDepartment}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                      <div>
                        <label className="block mb-2 text-sm text-gray-600 ">
                          Number of days
                        </label>
                        <Field
                          name="number_of_days"
                          placeholder="55"
                          type="number"
                          onChange={(e) =>
                            handleNumberOfDaysChange(
                              setFieldValue,
                              e.target.value,
                              values
                            )
                          }
                          className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#1d5b79]  focus:ring-[#1d5b79] focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        <ErrorMessage
                          name="number_of_days"
                          className="text-red-500 text-sm mt-1"
                          component="div"
                        />
                      </div>
                      {subRole === "manager" || subRole === "user" ? (
                        <></>
                      ) : (
                        <>
                          <div>
                            <FormikSelect
                              name={"amount_type"}
                              label={"Payment Type"}
                              onChange={(e) =>
                                handlePackageChange(
                                  e.target.value,
                                  setFieldValue,
                                  values
                                )
                              }
                              selectedOption={"Payement Type"}
                              optionData={amountTypeOption}
                            />
                          </div>
                          <div>
                            <label className="block mb-2 text-sm text-gray-600 ">
                              Amount
                            </label>
                            <Field
                              name="amount"
                              placeholder="125****"
                              type="number"
                              onChange={(e) =>
                                handleAmountChange(
                                  setFieldValue,
                                  e.target.value,
                                  values
                                )
                              }
                              className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#1d5b79]  focus:ring-[#1d5b79] focus:outline-none focus:ring focus:ring-opacity-40"
                            />
                            <ErrorMessage
                              name="amount"
                              className="text-red-500 text-sm mt-1"
                              component="div"
                            />
                          </div>
                          <div>
                            <label className="block mb-2 text-sm text-gray-600 ">
                              NDA
                            </label>
                            <input
                              name="nda_doc"
                              type="file"
                              accept=".pdf,image/*"
                              onChange={(event) => {
                                setFieldValue(
                                  "nda_doc",
                                  event.currentTarget.files[0]
                                );
                              }}
                              className="cursor-pointer block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#24b7aa]  focus:ring-[#24b7aa] focus:outline-none focus:ring focus:ring-opacity-40"
                            />
                          </div>
                          <div>
                            <label className="block mb-2 text-sm text-gray-600 ">
                              Agreement
                            </label>
                            <input
                              name="agreement_doc"
                              type="file"
                              accept=".pdf,image/*"
                              onChange={(event) => {
                                setFieldValue(
                                  "agreement_doc",
                                  event.currentTarget.files[0]
                                );
                              }}
                              className="cursor-pointer block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#24b7aa]  focus:ring-[#24b7aa] focus:outline-none focus:ring focus:ring-opacity-40"
                            />
                          </div>
                        </>
                      )}
                    </div>
                    {subRole === "manager" || subRole === "user" ? (
                      <></>
                    ) : (
                      <>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                          <div className="flex flex-col">
                            <div>
                              <label className="block mb-2 text-sm text-gray-600 ">
                                Does this member charge GST ?
                              </label>
                              <div className="flex flex-row">
                                <div>
                                  <FormikRadio
                                    id={"yes"}
                                    label={"Yes"}
                                    value={true}
                                    onChange={() =>
                                      handleGst(setFieldValue, true, values)
                                    }
                                    name={"gst_required"}
                                  />
                                </div>
                                <div className="ml-2">
                                  <FormikRadio
                                    id={"no"}
                                    value={false}
                                    onChange={() =>
                                      handleGst(setFieldValue, false, values)
                                    }
                                    label={"No"}
                                    name={"gst_required"}
                                  />
                                </div>
                              </div>
                            </div>
                            {values.gst_required ? (
                              <>
                                <div className="mt-2">
                                  <FormikInput
                                    name={"gst_number"}
                                    label={"GST number"}
                                    placeholder={"22AAAAA0000A1Z5"}
                                    type={"text"}
                                  />
                                </div>
                                <div className="mt-2">
                                  <FormikInput
                                    label={"GST Amount"}
                                    name={"gst_amount"}
                                    type={"number"}
                                    readOnly={true}
                                  />
                                </div>
                              </>
                            ) : (
                              <div></div>
                            )}
                          </div>

                          <div className="flex flex-col">
                            <label className="block mb-2 text-sm text-gray-600 ">
                              TDS ?
                            </label>
                            <div className="flex flex-row">
                              <div>
                                <FormikRadio
                                  id={"yes"}
                                  label={"Yes"}
                                  value={true}
                                  onChange={() =>
                                    setFieldValue("tds_required", true)
                                  }
                                  name={"tds_required"}
                                />
                              </div>
                              <div className="ml-2">
                                <FormikRadio
                                  id={"no"}
                                  value={false}
                                  onChange={() => {
                                    setFieldValue("tds_required", false);
                                    setFieldValue("tds", 0);
                                    setFieldValue("tds_amount", 0);
                                  }}
                                  label={"No"}
                                  name={"tds_required"}
                                />
                              </div>
                            </div>
                            {values.tds_required ? (
                              <>
                                <div className="mt-2">
                                  <label className="block mb-2 text-sm text-gray-600 ">
                                    TDS(%)
                                  </label>
                                  <Field
                                    name="tds"
                                    placeholder={"TDS"}
                                    type="number"
                                    onChange={(e) =>
                                      handleTDSChange(
                                        setFieldValue,
                                        e.target.value,
                                        values
                                      )
                                    }
                                    className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#1d5b79]  focus:ring-[#1d5b79] focus:outline-none focus:ring focus:ring-opacity-40"
                                  />
                                  <ErrorMessage
                                    name="tds"
                                    className="text-red-500 text-sm mt-1"
                                    component="div"
                                  />
                                </div>
                                <div className="mt-2">
                                  <FormikInput
                                    label={"TDS amount"}
                                    name={"tds_amount"}
                                    type={"number"}
                                    readOnly={true}
                                  />
                                </div>
                              </>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        </div>
                      </>
                    )}

                    <div className="mt-2 sm:mt-4">
                      <Button name={"Add Crew"} type={"submit"}></Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CrewDetailForm;

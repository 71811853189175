// SidebarContext.js
import React, { createContext, useState } from 'react';

export const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const [view, setView] = useState(false);

  const toggleView = () => {
    setView(!view);
  };

  return (
    <SidebarContext.Provider value={{ view, toggleView }}>
      {children}
    </SidebarContext.Provider>
  );
};

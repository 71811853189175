import { ErrorMessage, Field } from "formik";
import React from "react";
import { RiEyeCloseLine, RiEyeLine } from "react-icons/ri";

function Password({
  label,
  name,
  placeholder,
  readOnly,
  length,
  disable,
  secure,
  setSecure,
}) {
  return (
    <div>
      <label className="block mb-2 text-sm text-gray-600">{label}</label>
      <div className="relative">
        <Field
          name={name}
          placeholder={placeholder}
          readOnly={readOnly}
          type={secure ? "password" : "text"}
          maxLength={length}
          disabled={disable}
          className="block w-full px-4 py-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md focus:border-[#1d5b79] focus:ring-[#1d5b79] focus:outline-none focus:ring focus:ring-opacity-40"
        />
        <button
          type="button"
          onClick={() => setSecure(!secure)}
          className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-600"
        >
          {secure ? <RiEyeCloseLine /> : <RiEyeLine />}
        </button>
      </div>
      <ErrorMessage
        name={name}
        className="text-red-500 text-sm mt-1"
        component="div"
      />
    </div>
  );
}

export default Password;

import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import DeleteModal from "../../Modal/DeleteModal";
import ArtistDetailTable from "../../components/Table/ArtistDetailTable";
import ArtistDetailForm from "../../components/Forms/ArtistDetailForm";
import ArtistDetailUpdateForm from "../../components/UpdateForm/ArtistDetailUpdateForm";
import Button from "../../components/Button/Button";
import toast from "react-hot-toast";
import SubRoleContext from "../../SubRoleContext";
import Loader from "../../Loader/loader";
import ErrorPage from "../ErrorPage";
import DownloadButton from "../../components/Button/DownloadButton";
import { useReactToPrint } from "react-to-print";
import AttendanceModal from "../../Modal/AttendanceModal";

function ArtistDetail() {
  const subRole = useContext(SubRoleContext);
  const contentToPrint = useRef();
  const [view, setView] = useState(true);
  const [isModal, setIsModal] = useState(false);
  const [calendarView, setCalendarView] = useState(false);
  const [updateView, setUpdateView] = useState(true);
  const [detail, setDetail] = useState([]);
  const [artists, setArtists] = useState([]);
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [loading, setLoading] = useState(false);
  const [artistToDelete, setArtistToDelete] = useState(null);
  const [error, setError] = useState(false);
  const { projectId } = useParams();

  const openModal = (id) => {
    const artistDetail = detail.find(
      (artist) => artist.project_artist_id === id
    );
    setArtistToDelete(artistDetail);
    setIsModal(true);
  };

  const closeModal = () => {
    setIsModal(false);
  };

  const viewHandler = () => {
    setView(false);
  };

  const onClose = () => {
    setView(true);
  };

  const handleBackdropClick = () => {
    onClose();
  };

  const updatViewHandler = (artist) => {
    setSelectedArtist(artist);
    setUpdateView(false);
  };
  const handleAttendanceView = (artist) => {
    setSelectedArtist({ ...artist });
    setCalendarView(true);
  };
  const onUpdateClose = () => {
    setUpdateView(true);
  };
  const handleUpdateBackdropClick = () => {
    onUpdateClose();
  };

  const fetchProductionArtists = () => {
    axios
      .get(`api/v1/artists`)
      .then((res) => {
        setArtists(res.data.artists);
      })
      .catch((err) => console.log(err));
  };

  const fetchArtistDetails = () => {
    axios
      .get(`api/v1/projects/${projectId}/project_artists`)
      .then((response) => {
        setLoading(true);
        setDetail(response.data.project_artists);
      })
      .catch((err) => {
        setError(true);
        console.log(err);
      });
  };
  useEffect(() => {
    fetchProductionArtists();
    fetchArtistDetails();
  }, []);

  const addArtistHandler = (value, { setSubmitting, resetForm }) => {
    const loadingToastId = toast.loading("Adding artist...", {
      autoClose: false,
    });
    let total_amount;
    if (value.amount_type === "Per Day") {
      const amount = parseInt(value.amount) * parseInt(value.number_of_days);
      if (value.gst_required || value.tds_required) {
        total_amount =
          parseInt(amount) +
          parseInt(value.gst_amount) -
          parseInt(value.tds_amount);
      } else {
        total_amount = parseInt(amount);
      }
    } else {
      const amount = parseInt(value.amount);
      if (value.gst_required || value.tds_required) {
        total_amount =
          parseInt(amount) +
          parseInt(value.gst_amount) -
          parseInt(value.tds_amount);
      } else {
        total_amount = parseInt(amount);
      }
    }
    const formData = new FormData();

    formData.append("artist_id", value.artist_id);
    formData.append("project_artist[artist_type]", value.artist_type);
    formData.append("project_artist[number_of_days]", value.number_of_days);
    formData.append("project_artist[amount_type", value.amount_type);
    formData.append("project_artist[character_name]", value.character_name);
    formData.append("project_artist[amount]", value.amount);
    formData.append("project_artist[gst_allowed]", value.gst_required);
    formData.append("project_artist[tds_allowed]", value.tds_required);
    formData.append("project_artist[calculated_amount]", total_amount);
    if (value.gst_required === true) {
      formData.append("project_artist[gst_amount]", value.gst_amount);
      formData.append("project_artist[gst_number]", value.gst_number);
      formData.append("project_artist[gst_percentage]", value.gst_per);
    }
    if (value.tds_required === true) {
      formData.append("project_artist[tds_amount]", value.tds_amount);
      formData.append("project_artist[tds_percentage]", value.tds);
    }
    if (value.nda_doc) {
      formData.append("project_artist[nda_doc]", value.nda_doc);
    }
    if (value.agreement_doc) {
      formData.append("project_artist[agreement_doc]", value.agreement_doc);
    }

    axios
      .post(`api/v1/projects/${projectId}/project_artists`, formData)
      .then((response) => {
        handleBackdropClick();
        if (response.status === 200) {
          toast.dismiss(loadingToastId);
          toast.success("Artist added successfully!!!");
          fetchArtistDetails();
          resetForm();
        }
      })
      .catch((err) => {
        toast.dismiss(loadingToastId);
        toast.error(err.response.data.message);
        console.log(err);
        handleBackdropClick();
      });
    setSubmitting(false);
  };

  const updateArtistHandler = (id, value, { resetForm }) => {
    const loadingToastId = toast.loading("Updating artist...", {
      autoClose: false,
    });
    let total_amount;
    if (value.amount_type === "Per Day") {
      const amount = parseInt(value.amount) * parseInt(value.number_of_days);
      if (value.gst_required || value.tds_required) {
        total_amount =
          parseInt(amount) +
          parseInt(value.gst_amount) -
          parseInt(value.tds_amount);
      } else {
        total_amount = parseInt(amount);
      }
    } else {
      const amount = parseInt(value.amount);
      if (value.gst_required || value.tds_required) {
        total_amount =
          parseInt(amount) +
          parseInt(value.gst_amount) -
          parseInt(value.tds_amount);
      } else {
        total_amount = parseInt(amount);
      }
    }
    const formData = new FormData();

    formData.append("artist_id", value.artist_id);
    formData.append("project_artist[artist_type]", value.artist_type);
    formData.append("project_artist[number_of_days]", value.number_of_days);
    formData.append("project_artist[amount_type", value.amount_type);
    formData.append("project_artist[character_name]", value.character_name);
    formData.append("project_artist[amount]", value.amount);
    formData.append("project_artist[gst_allowed]", value.gst_required);
    formData.append("project_artist[tds_allowed]", value.tds_required);
    formData.append("project_artist[calculated_amount]", total_amount);
    if (value.gst_required === true) {
      formData.append("project_artist[gst_amount]", value.gst_amount);
      formData.append("project_artist[gst_number]", value.gst_number);
      formData.append("project_artist[gst_percentage]", value.gst_per);
    }
    if (value.tds_required === true) {
      formData.append("project_artist[tds_amount]", value.tds_amount);
      formData.append("project_artist[tds_percentage]", value.tds);
    }
    if (value.nda_doc) {
      formData.append("project_artist[nda_doc]", value.nda_doc);
    }
    if (value.agreement_doc) {
      formData.append("project_artist[agreement_doc]", value.agreement_doc);
    }

    axios
      .put(`api/v1/projects/${projectId}/project_artists/${id}`, formData)
      .then((response) => {
        handleUpdateBackdropClick();
        toast.dismiss(loadingToastId);
        toast.success(`Artist updated succesfully!!`);
        resetForm();
        fetchArtistDetails();
      })
      .catch((error) => {
        console.log("Error", error);
        toast.dismiss(loadingToastId);
        toast.error(error.message);
      });
  };

  const deleteArtist = (id) => {
    const loadingToastId = toast.loading("Deleting artist...", {
      autoClose: false,
    });
    axios
      .delete(`api/v1/projects/${projectId}/project_artists/${id}`)
      .then((response) => {
        toast.dismiss(loadingToastId);
        toast.success(response.data.message);
        setArtistToDelete(null);
        fetchArtistDetails();
      })
      .catch((err) => {
        toast.error(
          err.response.data.message || err.message || err.response.data.error
        );
        console.error(err);
      });
  };

  const handleAddAttendance = (value, { resetForm }) => {
    const loader = toast.loading("Adding attendance...");
    axios
      .post(
        `api/v1/projects/${projectId}/attendances?project_artist_id=${selectedArtist.project_artist_id}`,
        {
          attendance: {
            ...(selectedArtist.amount_type === "Per Day" && {
              number_of_dates: value.date,
            }),
            ...(selectedArtist.amount_type === "Package" && {
              start_date: value.date[0],
              end_date: value.date[1],
            }),
            ...(value.extra === true && {
              extra_shifts_attributes: value.extra_shift_info,
            }),
          },
        }
      )
      .then((res) => {
        if (res.status === 201) {
          resetForm();
          toast.dismiss(loader);
          toast.success(res.data.message);
          setCalendarView(false);
          fetchArtistDetails();
        }
      })
      .catch((err) => {
        toast.dismiss(loader);
        toast.error(err.message || err.response.data.error);
        console.error(err);
      });
  };
  const handleUpdateAttendance = (value, { resetForm }) => {
    const loader = toast.loading("Adding attendance...");
    axios
      .put(
        `api/v1/projects/${projectId}/attendances/${selectedArtist.attendance[0].id}`,
        {
          attendance: {
            ...(selectedArtist.amount_type === "Per Day" && {
              number_of_dates: value.date,
            }),
            ...(selectedArtist.amount_type === "Package" && {
              start_date: value.date[0],
              end_date: value.date[1],
            }),
            ...(value.extra === true && {
              extra_shifts_attributes: value.extra_shift_info,
            }),
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          resetForm();
          toast.dismiss(loader);
          toast.success(res.data.message);
          setCalendarView(false);
          fetchArtistDetails();
        }
      })
      .catch((err) => {
        toast.dismiss(loader);
        toast.error(err.message || err.response.data.error);
        console.error(err);
      });
  };

  const downloadPdf = () => {
    handlePrint();
  };
  const handlePrint = useReactToPrint({
    documentTitle: "Print This Document",
    content: () => contentToPrint.current,
  });

  return (
    <div>
      {error ? (
        <ErrorPage />
      ) : (
        <div>
          {!loading ? (
            <Loader />
          ) : (
            <div className="flex flex-col sm:flex sm:flex-col p-5">
              <div className="flex sm:flex-row flex-col items-center justify-between sm:mt-8 mt-0">
                <div className="flex sm:flex-row flex-col sm:items-start items-center justify-center sm:justify-start xl:ml-20 2xl:ml-20 md:ml-20 sm:ml-20 ml-0 sm:mt-0 mt-8">
                  <div>
                    <Button clickFunction={viewHandler} name={"Add Artist"} />
                  </div>
                </div>
                <div className="flex flex-row items-end justify-end font-sans sm:mt-0 mt-2">
                  <DownloadButton onClick={downloadPdf} name={"Download Pdf"} />
                </div>
              </div>
              <div className="flex items-center justify-center  xl:ml-20 2xl:ml-20 lg:ml-20 md:ml-20 sm:ml-20 ml-0">
                <div className="w-full mt-5">
                  <ArtistDetailTable
                    detail={detail}
                    updatViewHandler={updatViewHandler}
                    openModal={openModal}
                    contentToPrint={contentToPrint}
                    viewCalendar={handleAttendanceView}
                  />
                </div>
              </div>
            </div>
          )}
          {!view && (
            <ArtistDetailForm
              handleBackdropClick={handleBackdropClick}
              addArtistHandler={addArtistHandler}
              artists={artists}
            />
          )}
          {!updateView && selectedArtist && (
            <ArtistDetailUpdateForm
              handleUpdateBackdropClick={handleUpdateBackdropClick}
              selectedArtist={selectedArtist}
              updateArtistHandler={updateArtistHandler}
            />
          )}
          {isModal && (
            <DeleteModal
              title={`Remove Artist`}
              message={`Do you want to remove ${artistToDelete.artist_name}`}
              onDelete={() => {
                deleteArtist(artistToDelete.project_artist_id);
                closeModal();
              }}
              onClose={closeModal}
              icon={true}
            />
          )}
          {calendarView && (
            <AttendanceModal
              name={selectedArtist.artist_name}
              onClose={() => setCalendarView(false)}
              data={selectedArtist}
              onSubmit={
                selectedArtist.attendance.length > 0
                  ? handleUpdateAttendance
                  : handleAddAttendance
              }
            />
          )}
        </div>
      )}
    </div>
  );
}

export default ArtistDetail;

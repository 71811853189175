import React, { useState } from "react";
import { BiSolidTrashAlt } from "react-icons/bi";
import {  FaEye } from "react-icons/fa6";
import { MdModeEdit } from "react-icons/md";
import { AnimatePresence, motion } from "framer-motion";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import usePagination from "../Pagination/usePagination";
import Pagination from "../Pagination/Pagination";

function CrewSettingTable({ detail, updateView, deleteView }) {
  const [expandedCrew, setExpandedCrew] = useState({});

  const { currentItems, currentPage, handlePageChange, totalPages } =
    usePagination(detail, 10);

  const itemVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
  };
  const toggleView = (id) => {
    setExpandedCrew((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <div className="flex flex-col h-full">
      <div className="w-full mx-auto bg-white  rounded-sm border border-gray-200">
        <header className="flex sm:flex-row flex-col  justify-between px-5 py-4 border-b border-gray-100">
          <h2 className="font-semibold text-gray-800">Crews</h2>
          <div className="flex items-start justify-start sm:justify-end sm:items-center sm:mt-0 mt-2 relative">
            <Pagination
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              totalPages={totalPages}
            />
          </div>
        </header>
        <div className="p-3">
          <div className="overflow-x-auto">
            <table className="table-auto w-full">
              <thead className="text-xs font-semibold uppercase text-gray-600 bg-[#eceff1]">
                <tr>
                  <th className="px-4 py-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Name</div>
                  </th>
                  <th className="px-4 py-2 whitespace-nowrap">
                    <div className="font-semibold text-left">
                      Contact number
                    </div>
                  </th>
                  <th className="px-4 py-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Email</div>
                  </th>
                  <th className="px-4 py-2 whitespace-nowrap">
                    <div className="font-semibold text-left">More details</div>
                  </th>
                  <th className="px-4 py-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Action</div>
                  </th>
                </tr>
              </thead>
              <tbody className="text-sm divide-y divide-gray-100">
                {currentItems.map((crew, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td className="p-2 whitespace-nowrap">
                        {crew.full_name}
                      </td>
                      <td className="p-2">
                        <div className="text-left">{crew.phone_number}</div>
                      </td>

                      <td className="p-2">
                        <div className="text-left">{crew.email}</div>
                      </td>
                      <td className="p-2">
                        <button
                          onClick={() => toggleView(crew.id)}
                          className="text-xl border-none outline-none text-[#fbffff] bg-[#1d5b79] rounded-full p-1"
                        >
                          {expandedCrew[crew.id] ? (
                            <FaChevronUp size={15} />
                          ) : (
                            <FaChevronDown size={15} />
                          )}
                        </button>
                      </td>
                      <td className="p-2 whitespace-nowrap flex">
                        <div className="text-center">
                          <button
                            onClick={() => updateView(crew)}
                            className="p-4 text-xl text-green-600 border-none hover:shadow-none rounded-full outline-none bg-green-50  duration-300 ease-in"
                          >
                            <MdModeEdit />
                          </button>
                          <button
                            onClick={() => deleteView(crew)}
                            className="ml-4 p-4 text-xl text-red-600 bg-red-50 hover:shadow-none rounded-full  duration-300 ease-in outline-none"
                          >
                            <BiSolidTrashAlt />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <AnimatePresence>
                      {expandedCrew[crew.id] && (
                        <motion.tr
                          key={crew.id}
                          initial="hidden"
                          animate="visible"
                          exit="exit"
                          variants={itemVariants}
                          className="bg-gray-50"
                          transition={{ duration: 0.3, delay: 0.1 }}
                        >
                          <td colSpan={6} className="p-4">
                            <div className="grid sm:grid-cols-4 grid-cols-1 gap-2 sm:gap-4">
                              <div className="flex flex-row">
                                <h2 className="text-sm font-semibold text-[#1d5b79]">
                                  PAN number:
                                </h2>
                                <h2 className="text-sm ml-1">
                                  {crew.pan_card_number}
                                </h2>
                              </div>
                              <div className="flex flex-row">
                                <h2 className="text-sm font-semibold text-[#1d5b79]">
                                  Bank a/c number:
                                </h2>
                                <h2 className="text-sm ml-1">
                                  {crew.bank_account_number}
                                </h2>
                              </div>
                              <div className="flex flex-row">
                                <h2 className="text-sm font-semibold text-[#1d5b79]">
                                  Bank name:
                                </h2>
                                <h2 className="text-sm ml-1">
                                  {crew.bank_name}
                                </h2>
                              </div>
                              <div className="flex flex-row">
                                <h2 className="text-sm font-semibold text-[#1d5b79]">
                                  Bank branch:
                                </h2>
                                <h2 className="text-sm ml-1">
                                  {crew.bank_branch}
                                </h2>
                              </div>
                              <div className="flex flex-row">
                                <h2 className="text-sm font-semibold text-[#1d5b79]">
                                  IFSC code:
                                </h2>
                                <h2 className="text-sm ml-1">{crew.ifsc}</h2>
                              </div>
                              {crew.gst_number && (
                                <div className="flex flex-row">
                                  <h2 className="text-sm font-semibold text-[#1d5b79]">
                                    GST:
                                  </h2>
                                  <h2 className="text-sm ml-1">
                                    {crew.gst_number}
                                  </h2>
                                </div>
                              )}
                              <div className="flex flex-row">
                                <h2 className="mt-1 text-sm font-semibold text-[#1d5b79]">
                                  Aadhar card:
                                </h2>
                                <button
                                  onClick={() =>
                                    window.open(
                                      crew.kyc_details[0].aadhar_card_url
                                    )
                                  }
                                  className="ml-1  text-lg bg-blue-100 text-blue-600 p-2 rounded-full cursor-pointer"
                                >
                                  <FaEye />
                                </button>
                              </div>
                              <div className="flex flex-row">
                                <h2 className="mt-1 text-sm font-semibold text-[#1d5b79]">
                                  PAN card:
                                </h2>
                                <button
                                  onClick={() =>
                                    window.open(
                                      crew.kyc_details[0].pan_card_url
                                    )
                                  }
                                  className="ml-1  text-lg bg-blue-100 text-blue-600 p-2 rounded-full cursor-pointer"
                                >
                                  <FaEye />
                                </button>
                              </div>
                              <div className="flex flex-row">
                                <h2 className="mt-1 text-sm font-semibold text-[#1d5b79]">
                                  Cancel cheque:
                                </h2>
                                <button
                                  onClick={() =>
                                    window.open(
                                      crew.kyc_details[0].cancel_cheque_url
                                    )
                                  }
                                  className="ml-1  text-lg bg-blue-100 text-blue-600 p-2 rounded-full cursor-pointer"
                                >
                                  <FaEye />
                                </button>
                              </div>
                            </div>
                          </td>
                        </motion.tr>
                      )}
                    </AnimatePresence>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CrewSettingTable;

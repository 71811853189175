import axios from "axios";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import FormikSelect from "../components/Input/FormikSelect";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import FormikRadio from "../components/Input/FormikRadio";
import Button from "../components/Button/Button";
import CallSheetContactTable from "../components/Table/CallSheetContact";

function ContactModal({ onClose }) {
  const [department, setDepartment] = useState([]);
  const [subDepartment, setSubDepartment] = useState([]);
  const [CallSheetContact, setCallSheetContact] = useState([]);
  const [crew, setCrew] = useState([]);
  const { projectId } = useParams();
  const getDepartmentData = () => {
    axios
      .get(`api/v1/departments`)
      .then((response) => {
        setDepartment(response.data.departments);
      })
      .catch((error) => console.log(error.message));
  };

  const getAsscociatedCrew = (departmentName, subDepartment) => {
    axios
      .get(`api/v1/crews/filter_crew`, {
        params: {
          project_id: projectId,
          department: departmentName,
          subdepartment: subDepartment,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setCrew(response.data.crews);
        }
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(error.response.data.error);
      });
  };
  const getSubDepartment = (value) => {
    const selected = department.find((detail) => detail.name === value);

    axios
      .get(`api/v1/departments/${selected.id}/subdepartments`)
      .then((response) => setSubDepartment(response.data.subdepartments))
      .catch((error) => console.log(error.message));
  };

  const handleSubDepartmentChange = (value, setFieldValue, values) => {
    setFieldValue("sub_department", value);
    getAsscociatedCrew(values.department, value);
  };

  const handleDepartmentChange = (value, setFieldValue) => {
    getSubDepartment(value);
    setFieldValue("department", value);
  };

  const handleAddContact = (value, { resetForm }) => {
    axios
      .put(
        `api/v1/projects/${projectId}/project_crews/${value.crew}/add_to_callsheet`,
        {
          is_contact: value.is_mobile,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          resetForm();
          fetchContactDetails();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const revokeAccess = (id) => {
    axios
      .put(`api/v1/projects/${projectId}/project_crews/${id}/add_to_callsheet`)
      .then((res) => {
        if (res.status === 200) {
          fetchContactDetails();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchContactDetails = () => {
    axios
      .get(`api/v1/projects/${projectId}/project_crews/callsheet_members`)
      .then((res) => {
        if (res.status === 200) {
          setCallSheetContact(res.data.crews);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getDepartmentData();
    fetchContactDetails();
  }, []);
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 overflow-auto bg-black bg-opacity-50 ">
      <div className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto ">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-5xl w-full mx-auto ">
          <div className="flex items-end justify-end">
            <button
              className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full "
              onClick={onClose}
            >
              <RxCross1 />
            </button>
          </div>
          <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
            Sheet Contact
          </h2>
          <Formik
            initialValues={{
              department: "",
              sub_department: "",
              crew: "",
              is_mobile: false,
            }}
            onSubmit={handleAddContact}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className="grid grid-cols-2 sm:grid-cols-4 gap-2 sm:gap-4 mt-2 sm:mt-4">
                  <div>
                    <FormikSelect
                      name={"department"}
                      label={"Department"}
                      selectedOption={"Department"}
                      optionData={department}
                      onChange={(e) =>
                        handleDepartmentChange(e.target.value, setFieldValue)
                      }
                    />
                  </div>
                  <div>
                    <FormikSelect
                      name={"sub_department"}
                      label={"Sub Department"}
                      selectedOption={"Sub Department"}
                      optionData={subDepartment}
                      onChange={(e) =>
                        handleSubDepartmentChange(
                          e.target.value,
                          setFieldValue,
                          values
                        )
                      }
                    />
                  </div>
                  <div>
                    <FormikSelect
                      name={"Crew"}
                      label={"Crew"}
                      selectedOption={"Crew"}
                      optionData={crew}
                      labelProperty="full_name"
                      valueProperty="project_crew_id"
                      onChange={(e) => setFieldValue("crew", e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="block mb-2 text-sm text-gray-600 ">
                      Contact number required?
                    </label>
                    <div className="flex flex-row">
                      <div>
                        <FormikRadio
                          id={"yes"}
                          label={"Yes"}
                          value={true}
                          onChange={() => setFieldValue("is_mobile", true)}
                          name={"is_mobile"}
                        />
                      </div>
                      <div className="ml-2">
                        <FormikRadio
                          id={"no"}
                          value={false}
                          onChange={() => setFieldValue("is_mobile", false)}
                          label={"No"}
                          name={"is_mobile"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    <Button type={"submit"} name={"Add"} />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          <div className="w-full mt-5">
            <CallSheetContactTable
              data={CallSheetContact}
              revokeAccess={revokeAccess}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactModal;

import { FieldArray, Form, Formik } from "formik";
import React from "react";
import { RxCross1 } from "react-icons/rx";
import FormikSelect from "../Input/FormikSelect";
import IconButton from "../Button/IconButton";
import { FaPlus, FaTrash } from "react-icons/fa";
import { vehicleType } from "../Data";
import FormikInput from "../Input/FormikInput";
import Button from "../Button/Button";
import { MdDelete } from "react-icons/md";
import * as Yup from "yup";
import { useParams } from "react-router-dom";

function TravelForm({ onClose, onSubmit, vendors }) {
  const { projectId } = useParams();
  const validationSchema = Yup.object().shape({
    vendor_id: Yup.string().required("Vendor is required"),
    vehicles: Yup.array().of(
      Yup.object().shape({
        vehicle_type: Yup.string().required("Vehicle type is required"),
        capacity: Yup.number()
          .required("Vehicle capacity is required")
          .positive("Capacity must be greater than 0")
          .integer("Capacity must be a whole number"),
        number_plate: Yup.string()
          .required("Number plate is required")
          .matches(
            /^[A-Z]{2}[0-9]{2}[A-Z]{1,2}[0-9]{4}$/,
            "Number plate format XX00XX0000"
          ),
        driver_name: Yup.string().required("Driver name is required"),
        driver_number: Yup.string()
          .required("Driver contact number is required")
          .matches(/^[0-9]{10}$/, "Contact number must be 10 digits"),
        name: Yup.string().required("Vehicle name is required"),
        rate: Yup.number()
          .required("Rate is required")
          .positive("Rate must be greater than 0"),
      })
    ),
  });
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 overflow-auto">
      <div className="w-full max-w-3xl bg-white rounded shadow-md p-4 sm:p-8 mx-auto mt-auto sm:mt-auto sm:mb-auto mb-auto ">
        <div className="flex justify-end">
          <button
            className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full"
            onClick={onClose}
          >
            <RxCross1 />
          </button>
        </div>

        <h2 className="text-lg sm:text-2xl font-semibold mb-4 text-[#1d5b79]">
          Travel
        </h2>

        <Formik
          initialValues={{
            vendor_id: "",
            vehicles: [],
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <div className="w-full mb-4">
                <FormikSelect
                  name="vendor_id"
                  label="Vendor"
                  optionData={vendors}
                  selectedOption="Travel agency"
                  labelProperty="name"
                  valueProperty="id"
                  onChange={(e) => setFieldValue("vendor_id", e.target.value)}
                />
              </div>

              <FieldArray name="vehicles">
                {({ push, remove }) => (
                  <div>
                    <div className="flex items-center space-x-4 mt-4">
                      <h2 className="text-[#1d5b79] font-semibold text-lg">
                        Add Vehicle:
                      </h2>
                      <IconButton
                        icon={<FaPlus />}
                        type="button"
                        clickFunction={() =>
                          push({
                            vehicle_type: "",
                            name: "",
                            capacity: 1,
                            number_plate: "",
                            driver_name: "",
                            driver_number: "",
                            rate: 1,
                            project_id: parseInt(projectId),
                            vendor_id: parseInt(values.vendor_id),
                          })
                        }
                      />
                    </div>

                    {values.vehicles.map((_, index) => (
                      <div key={index} className="border-t mt-5 pt-4">
                        <div className="flex justify-between items-center">
                          <h3 className="text-xl font-semibold text-[#020002]">
                            Vehicle {index + 1}
                          </h3>
                          <button
                            type="button"
                            onClick={() => remove(index)}
                            className="bg-[#e70a18] p-2 text-lg text-[#fbffff] rounded-full"
                          >
                            <MdDelete />
                          </button>
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
                          <FormikSelect
                            name={`vehicles.${index}.vehicle_type`}
                            optionData={vehicleType}
                            selectedOption="Vehicle type"
                            onChange={(e) =>
                              setFieldValue(
                                `vehicles.${index}.vehicle_type`,
                                e.target.value
                              )
                            }
                            label="Vehicle type"
                            labelProperty="type"
                            valueProperty="value"
                          />

                          <FormikInput
                            name={`vehicles.${index}.name`}
                            type="text"
                            label="Vehicle name"
                            placeholder="Roadrunner"
                          />

                          <FormikInput
                            name={`vehicles.${index}.capacity`}
                            type="number"
                            label="Vehicle capacity"
                            min={1}
                            placeholder="3"
                          />

                          <FormikInput
                            name={`vehicles.${index}.number_plate`}
                            type="text"
                            label="Number plate"
                            placeholder="GJ XX AA XXX"
                          />

                          <FormikInput
                            name={`vehicles.${index}.driver_name`}
                            type="text"
                            label="Driver name"
                            placeholder="John Doe"
                          />

                          <FormikInput
                            name={`vehicles.${index}.driver_number`}
                            type="tel"
                            label="Driver contact number"
                            placeholder="98454*****"
                          />
                          <FormikInput
                            name={`vehicles.${index}.rate`}
                            type="number"
                            label="Rate"
                            placeholder="100"
                            min={1}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </FieldArray>

              <div className="mt-4">
                <Button type="submit" name="Add" />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default TravelForm;

import React from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { SlOptionsVertical } from "react-icons/sl";

function ProjectCard({
  project,
  viewOption,
  projectViewHandler,
  projectImages,
  handleOpenViewSettings,
  openUpdateProjectModal,
  openDeleteModal,
}) {
  return (
    <div
      onClick={() =>
        projectViewHandler(project.id, project.title, project.cinema_type)
      }
      className="relative w-full flex flex-col bg-white shadow-lg hover:drop-shadow-xl rounded-3xl overflow-hidden transform hover:-translate-y-2 cursor-pointer transition-all duration-300"
    >
      <div className="relative h-52 w-full bg-gray-100">
        <img
          src={project.poster_url || projectImages[project.id]}
          alt={project.title}
          className="absolute inset-0 h-full w-full object-cover object-center"
        />
      </div>

      <div className="p-4">
        <h2 className="text-sm font-bold text-gray-800 mb-2">
          {project.title}
        </h2>
        <h3 className="text-xs text-blue-600 font-medium">
          {project.cinema_type}
        </h3>
      </div>

      <div className="absolute top-4 right-4 flex items-center justify-end">
        <button
          onClick={(e) => handleOpenViewSettings(e, project.id)}
          className="text-gray-700 p-2 rounded-full bg-white bg-opacity-50 hover:bg-opacity-90 transition-all shadow-sm outline-none"
        >
          <SlOptionsVertical />
        </button>
      </div>

      {viewOption[project.id] && (
        <div
          onClick={(e) => handleOpenViewSettings(e, project.id)}
          className="absolute inset-0 bg-black bg-opacity-70 flex items-center justify-center transition-all duration-200"
        >
          <div className="flex flex-col w-full h-full items-center justify-center">
            <button
              onClick={(e) => openUpdateProjectModal(e, project.id)}
              className="w-full group py-2  text-green-600 font-semibold hover:bg-green-500 hover:bg-opacity-20 transition-all outline-none"
            >
              <MdEdit className="inline mr-2" />
              Update
            </button>
            <button
              onClick={(e) => openDeleteModal(e, project.id)}
              className="w-full group py-2 text-red-600 font-semibold hover:bg-red-500 hover:bg-opacity-20 transition-all outline-none"
            >
              <MdDelete className="inline mr-2" />
              Delete
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProjectCard;

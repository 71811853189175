import React, { useContext, useEffect, useState } from "react";
import Sidebar from "./superAdminComponent/Sidebar";
import Button from "../../components/Button/Button";
import PackageModal from "./superAdminComponent/Modal/PackageModal";
import * as Yup from "yup";
import axios from "axios";
import PackageTable from "./superAdminComponent/Table/PackageTable";
import UpdatePackage from "./superAdminComponent/Modal/UpdatePackage";
import toast from "react-hot-toast";
import DeleteModal from "../../Modal/DeleteModal";
import { SidebarContext } from "./SidebarContext";

function Packages() {
  const { view } = useContext(SidebarContext);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Package name is required"),
    price: Yup.number().required("Price is required"),
    package_details: Yup.object().shape({
      details: Yup.array().of(Yup.string()),
    }),
    number_of_projects: Yup.number(),
    number_of_locations: Yup.number(),
    number_of_crews: Yup.number(),
    number_of_artists: Yup.number(),
    number_of_callsheet: Yup.number(),
    expense_allowed: Yup.boolean().required("Select an option"),
    shot_division: Yup.boolean().required("Select an option"),
    continuity: Yup.boolean().required("Select an option"),
    accounts: Yup.boolean().required("Select an option"),
    assign_role: Yup.boolean().required("Select an option"),
  });

  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [packageData, setPackageData] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [Package, setPackage] = useState(null);
  const openModal = () => {
    setOpen(!open);
  };

  const openUpdateModal = (id) => {
    const updatePackage = packageData.find((Package) => Package.id === id);
    setOpenUpdate(true);
    setPackage(updatePackage);
  };

  const closeUpdateModal = () => {
    setOpenUpdate(false);
    setPackage(null);
  };

  const OpenDeleteHandler = (id) => {
    const deletePackage = packageData.find((Package) => Package.id === id);
    setOpenDelete(true);
    setPackage(deletePackage);
  };

  const closeDeleteModal = () => {
    setOpenDelete(false);
    setPackage(null);
  };

  const getPackageData = () => {
    axios
      .get(`api/v1/packages/`)
      .then((response) => {
        setPackageData(response.data.packages);
      })
      .catch((err) => console.log(err.message));
  };

  const addPackageHandler = (value, { setSubmitting, resetForm }) => {
    axios
      .post(`api/v1/packages`, {
        package: {
          name: value.name,
          price: value.price,
          number_of_projects: value.number_of_projects,
          number_of_locations: value.number_of_locations,
          number_of_crews: value.number_of_crews,
          number_of_artists: value.number_of_artists,
          number_of_scenes: value.number_of_scenes,
          number_of_callsheet: value.number_of_callsheet,
          expense_allowed: value.expense_allowed,
          shot_division: value.shot_division,
          continuity: value.continuity,
          accounts: value.accounts,
          aasign_role_allowed: value.assign_role,
          package_details: value.package_details,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
          setOpen(false);
          resetForm();
          getPackageData();
          toast.success("Package added successfully");
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
    setSubmitting(false);
  };

  const deletePackage = (id) => {
    axios
      .delete(`api/v1/packages/${id}`)
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.message);
          getPackageData();
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const updatePackageHandler = async (
    id,
    values,
    { setSubmitting, resetForm }
  ) => {
    try {
      const packageData = {
        name: values.name,
        price: values.price,
        number_of_projects: values.number_of_projects,
        number_of_locations: values.number_of_locations,
        number_of_crews: values.number_of_crews,
        number_of_artists: values.number_of_artists,
        number_of_scenes: values.number_of_scenes,
        number_of_callsheet: values.number_of_callsheet,
        expense_allowed: values.expense_allowed,
        shot_division: values.shot_division,
        continuity: values.continuity,
        accounts: values.accounts,
        aasign_role_allowed: values.assign_role,
        package_details: values.package_details,
      };

      const response = await axios.put(`api/v1/packages/${id}`, {
        package: packageData,
      });

      if (response.status === 200) {
        setOpenUpdate(false);
        resetForm();
        getPackageData();
        toast.success("Package updated successfully");
      }
    } catch (error) {
      console.error("Failed to update package: ", error.message);
      toast.error("Failed to update package");
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    getPackageData();
  }, []);

  return (
    <div>
      <Sidebar />
      <div
        className={`h-screen p-7 ${
          !view ? "ml-40 duration-100 ease-in" : "ml-0 duration-100 ease-in"
        }`}
      >
        <div className="flex flex-col sm:flex sm:flex-col p-5">
          <div className="flex items-start justify-start xl:ml-20 2xl:ml-20 sm:ml-20 ml-24">
            <Button name={"Add Package"} clickFunction={openModal} />
          </div>
        </div>
        <div className="flex items-center justify-center xl:ml-20 2xl:ml-20 lg:ml-20 sm:ml-20 ml-0">
          <div className="w-full mt-5">
            <PackageTable
              packageData={packageData}
              openUpdateModal={openUpdateModal}
              OpenDeleteHandler={OpenDeleteHandler}
            />
          </div>
        </div>
      </div>
      {open && (
        <PackageModal
          openModal={openModal}
          validationSchema={validationSchema}
          addPackageHandler={addPackageHandler}
        />
      )}
      {openUpdate && (
        <UpdatePackage
          updatePackage={Package}
          closeUpdateModal={closeUpdateModal}
          updatePackageHandler={updatePackageHandler}
        />
      )}
      {openDelete && (
        <DeleteModal
          title={"Remove Package"}
          message={`Do you want to remove ${Package.name}`}
          onDelete={() => {
            deletePackage(Package.id);
            closeDeleteModal();
          }}
          onClose={closeDeleteModal}
        />
      )}
    </div>
  );
}

export default Packages;

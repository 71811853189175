import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import DeleteModal from "../../Modal/DeleteModal";
import toast from "react-hot-toast";
import CrewDetailTable from "../../components/Table/CrewDetailTable";
import CrewDetailForm from "../../components/Forms/CrewDetailForm";
import CrewDetailUpdateForm from "../../components/UpdateForm/CrewDetailUpdateForm";
import Button from "../../components/Button/Button";
import * as Yup from "yup";
import SubRoleContext from "../../SubRoleContext";
import Loader from "../../Loader/loader";
import ErrorPage from "../ErrorPage";
import { useReactToPrint } from "react-to-print";
import DownloadButton from "../../components/Button/DownloadButton";
import AttendanceModal from "../../Modal/AttendanceModal";

function CrewDetails() {
  const subRole = useContext(SubRoleContext);
  const contentToPrint = useRef();
  const [view, setView] = useState(true);
  const [isModal, setIsModal] = useState(false);
  const [updateView, setUpdateView] = useState(true);
  const [calendarView, setCalendarView] = useState(false);
  const [selectedCrew, setSelectedCrew] = useState(null);
  const [crews, setCrews] = useState([]);
  const [CrewDetails, setCrewDetails] = useState([]);
  const [crewToDelete, setCrewToDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { projectId } = useParams();
  const [department, setDepartment] = useState([]);

  const getDepartmentData = () => {
    axios
      .get(`api/v1/departments`)
      .then((response) => {
        setDepartment(response.data.departments);
      })
      .catch((error) => console.log(error.message));
  };

  const openModal = (id) => {
    const crewDetails = CrewDetails.find((crew) => crew.project_crew_id === id);
    setCrewToDelete(crewDetails);
    setIsModal(true);
  };

  const closeModal = () => {
    setIsModal(false);
  };

  const viewHandler = () => {
    setView(false);
  };

  const onClose = () => {
    setView(true);
  };
  const stopClose = (e) => {
    e.stopPropagation();
  };

  const handleBackdropClick = () => {
    onClose();
  };

  const handleAttendanceView = (crew) => {
    setSelectedCrew({ ...crew });
    setCalendarView(true);
  };

  const viewUpdateHandler = (crew) => {
    setSelectedCrew(crew);

    setUpdateView(false);
  };

  const onUpdateClose = () => {
    setUpdateView(true);
  };
  const handleUpdateBackdropClick = () => {
    onUpdateClose();
  };

  const fetchCrewDetails = () => {
    axios
      .get(`api/v1/projects/${projectId}/project_crews`)
      .then((response) => {
        setLoading(true);
        setCrewDetails(response.data.project_crews);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      });
  };

  const fetchCrew = () => {
    axios
      .get(`api/v1/crews`)
      .then((res) => {
        setCrews(res.data.crews);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchCrewDetails();
    fetchCrew();
    getDepartmentData();
  }, []);

  const addCrewHandler = (value, { setSubmitting, resetForm }) => {
    const loadingToastId = toast.loading("Adding crew...", {
      autoClose: false,
    });
    let total_amount;
    if (value.amount_type === "Per Day") {
      const amount = parseInt(value.amount) * parseInt(value.number_of_days);
      if (value.gst_required || value.tds_required) {
        total_amount =
          parseInt(amount) +
          parseInt(value.gst_amount) -
          parseInt(value.tds_amount);
      } else {
        total_amount = parseInt(amount);
      }
    } else {
      const amount = parseInt(value.amount);
      if (value.gst_required || value.tds_required) {
        total_amount =
          parseInt(amount) +
          parseInt(value.gst_amount) -
          parseInt(value.tds_amount);
      } else {
        total_amount = parseInt(amount);
      }
    }

    const formData = new FormData();
    formData.append("crew_id", value.crew_id);
    formData.append("project_crew[category]", value.category);
    formData.append("project_crew[sub_category]", value.sub_category);
    formData.append("project_crew[amount_type]", value.amount_type);
    formData.append("project_crew[number_of_days]", value.number_of_days);
    formData.append("project_crew[amount]", value.amount);
    formData.append("project_crew[gst_allowed]", value.gst_required);
    formData.append("project_crew[tds_allowed]", value.tds_required);
    if (value.gst_required === true) {
      formData.append("project_crew[gst_amount]", value.gst_amount);
      formData.append("project_crew[gst_number]", value.gst_number);
      formData.append("project_crew[gst_percentage]", value.gst_per);
    }
    if (value.tds_required === true) {
      formData.append("project_crew[tds_amount]", value.tds_amount);
      formData.append("project_crew[tds_percentage]", value.tds);
    }
    if (value.nda_doc) {
      formData.append("project_crew[nda_doc]", value.nda_doc);
    }
    if (value.agreement_doc) {
      formData.append("project_crew[agreement_doc]", value.agreement_doc);
    }
    formData.append("project_crew[calculated_amount]", total_amount);

    axios
      .post(`api/v1/projects/${projectId}/project_crews`, formData)
      .then((response) => {
        handleBackdropClick();
        if (response.status === 200) {
          toast.dismiss(loadingToastId);
          toast.success("Added successfully!!!");
          fetchCrewDetails();
          resetForm();
        }
      })
      .catch((err) => {
        console.log(err);
        toast.dismiss(loadingToastId);
        toast.error(
          err.response.data.message || err.message || err.response.data.error
        );
        handleBackdropClick();
      });
    setSubmitting(false);
  };

  const updateCrewHandler = (id, value, { resetForm }) => {
    const loadingToastId = toast.loading("Upadting crew...", {
      autoClose: false,
    });
    let total_amount;
    if (value.amount_type === "Per Day") {
      const amount = parseInt(value.amount) * parseInt(value.number_of_days);
      if (value.gst_required || value.tds_required) {
        total_amount =
          parseInt(amount) +
          parseInt(value.gst_amount) -
          parseInt(value.tds_amount);
      } else {
        total_amount = parseInt(amount);
      }
    } else {
      const amount = parseInt(value.amount);
      if (value.gst_required || value.tds_required) {
        total_amount =
          parseInt(amount) +
          parseInt(value.gst_amount) -
          parseInt(value.tds_amount);
      } else {
        total_amount = parseInt(amount);
      }
    }

    const formData = new FormData();
    formData.append("crew_id", value.crew_id);
    formData.append("project_crew[category]", value.category);
    formData.append("project_crew[sub_category]", value.sub_category);
    formData.append("project_crew[amount_type]", value.amount_type);
    formData.append("project_crew[number_of_days]", value.number_of_days);
    formData.append("project_crew[amount]", value.amount);
    formData.append("project_crew[gst_allowed]", value.gst_required);
    formData.append("project_crew[tds_allowed]", value.tds_required);
    if (value.gst_required === true) {
      formData.append("project_crew[gst_amount]", value.gst_amount);
      formData.append("project_crew[gst_number]", value.gst_number);
      formData.append("project_crew[gst_percentage]", value.gst_per);
    }
    if (value.tds_required === true) {
      formData.append("project_crew[tds_amount]", value.tds_amount);
      formData.append("project_crew[tds_percentage]", value.tds);
    }
    if (value.nda_doc) {
      formData.append("project_crew[nda_doc]", value.nda_doc);
    }
    if (value.agreement_doc) {
      formData.append("project_crew[agreement_doc]", value.agreement_doc);
    }
    formData.append("project_crew[calculated_amount]", total_amount);
    formData.append("incoming_amount", selectedCrew.total_amount);
    axios
      .put(`api/v1/projects/${projectId}/project_crews/${id}`, formData)
      .then((reposne) => {
        toast.success(`Crew updated successfully!!`);
        toast.dismiss(loadingToastId);
        handleUpdateBackdropClick();
        resetForm();
        fetchCrewDetails();
      })
      .catch((err) => {
        toast.dismiss(loadingToastId);
        toast.error(
          err.response.data.message || err.message || err.response.data.error
        );
        console.log(err);
      });
  };

  const deleteCrew = (id) => {
    const loadingToastId = toast.loading("Deleting crew...", {
      autoClose: false,
    });
    axios
      .delete(`api/v1/projects/${projectId}/project_crews/${id}`)
      .then((response) => {
        toast.dismiss(loadingToastId);
        fetchCrewDetails();
        toast.success(response.data.message);
      })
      .catch((error) => {
        toast.error(error.message);
        toast.dismiss(loadingToastId);
        console.log(error);
      });
  };

  const downloadPdf = () => {
    handlePrint();
  };
  const handlePrint = useReactToPrint({
    documentTitle: "Print This Document",
    content: () => contentToPrint.current,
  });

  const handleAddAttendance = (value, { resetForm }) => {
    const loader = toast.loading("Adding attendance...");
    axios
      .post(
        `api/v1/projects/${projectId}/attendances?project_crew_id=${selectedCrew.project_crew_id}`,
        {
          attendance: {
            ...(selectedCrew.amount_type === "Per Day" && {
              number_of_dates: value.date,
            }),
            ...(selectedCrew.amount_type === "Package" && {
              start_date: value.date[0],
              end_date: value.date[1],
            }),
            ...(value.extra === true && {
              extra_shifts_attributes: value.extra_shift_info,
            }),
          },
        }
      )
      .then((res) => {
        if (res.status === 201) {
          resetForm();
          toast.dismiss(loader);
          toast.success(res.data.message);
          setCalendarView(false);
          fetchCrewDetails();
        }
      })
      .catch((err) => {
        toast.dismiss(loader);
        toast.error(err.message || err.response.data.error);
        console.error(err);
      });
  };
  const handleUpdateAttendance = (value, { resetForm }) => {
    const loader = toast.loading("Adding attendance...");
    axios
      .put(
        `api/v1/projects/${projectId}/attendances/${selectedCrew.attendance[0].id}`,
        {
          attendance: {
            ...(selectedCrew.amount_type === "Per Day" && {
              number_of_dates: value.date,
            }),
            ...(selectedCrew.amount_type === "Package" && {
              start_date: value.date[0],
              end_date: value.date[1],
            }),
            ...(value.extra === true && {
              extra_shifts_attributes: value.extra_shift_info,
            }),
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          resetForm();
          toast.dismiss(loader);
          toast.success(res.data.message);
          setCalendarView(false);
          fetchCrewDetails();
        }
      })
      .catch((err) => {
        toast.dismiss(loader);
        toast.error(err.message || err.response.data.error);
        console.error(err);
      });
  };

  return (
    <div>
      {error ? (
        <ErrorPage />
      ) : (
        <div>
          {!loading ? (
            <Loader />
          ) : (
            <div className="flex flex-col sm:flex sm:flex-col p-5">
              <div className="flex sm:flex-row flex-col items-center justify-between sm:mt-8 mt-0">
                <div className="flex sm:flex-row flex-col sm:items-start items-center justify-center sm:justify-start xl:ml-20 2xl:ml-20 md:ml-20 sm:ml-20 ml-0 sm:mt-0 mt-8">
                  <div>
                    <Button clickFunction={viewHandler} name={"Add Crew"} />
                  </div>
                </div>
                <div className="flex flex-row items-end justify-end sm:mt-0 mt-2">
                  <DownloadButton onClick={downloadPdf} name={"Download Pdf"} />
                </div>
              </div>
              <div className="flex items-center justify-center xl:ml-20 2xl:ml-20 md:ml-20 lg:ml-20 sm:ml-20 ml-0">
                <div className="w-full mt-5">
                  <CrewDetailTable
                    CrewDetails={CrewDetails}
                    viewUpdateHandler={viewUpdateHandler}
                    openModal={openModal}
                    department={department}
                    contentToPrint={contentToPrint}
                    viewCalendar={handleAttendanceView}
                  />
                </div>
              </div>
            </div>
          )}
          {!view && (
            <CrewDetailForm
              handleBackdropClick={handleBackdropClick}
              stopClose={stopClose}
              addCrewHandler={addCrewHandler}
              crews={crews}
              department={department}
            />
          )}
          {!updateView && selectedCrew && (
            <CrewDetailUpdateForm
              department={department}
              handleUpdateBackdropClick={handleUpdateBackdropClick}
              stopClose={stopClose}
              updateCrewHandler={updateCrewHandler}
              selectedCrew={selectedCrew}
            />
          )}
          {isModal && (
            <DeleteModal
              title={`Remove Crew`}
              message={`Do you want to remove ${crewToDelete.full_name}`}
              onDelete={() => {
                deleteCrew(crewToDelete.project_crew_id);
                closeModal();
              }}
              onClose={closeModal}
              icon={true}
            />
          )}
          {calendarView && (
            <AttendanceModal
              name={selectedCrew.full_name}
              onClose={() => setCalendarView(false)}
              data={selectedCrew}
              onSubmit={
                selectedCrew.attendance.length > 0
                  ? handleUpdateAttendance
                  : handleAddAttendance
              }
            />
          )}
        </div>
      )}
    </div>
  );
}

export default CrewDetails;

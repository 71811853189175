import React, { useContext, useEffect, useRef, useState } from "react";
import ExpenseAccount from "./AccountPages/ExpenseAccount";
import ArtistAccount from "./AccountPages/ArtistAccount";
import CrewAccount from "./AccountPages/CrewAccount";
import VendorAccount from "./AccountPages/VendorAccount";
import LocationAccounts from "./AccountPages/LocationAccounts";
import CateringAccount from "./AccountPages/CateringAccount";
import BudgetPlan from "./AccountPages/BudgetPlan";
import Spender from "./AccountPages/Spender";
import TaxSummary from "./AccountPages/TaxSummary";
import AccountSummary from "./AccountPages/AccountSummary";
import SecureLS from "secure-ls";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { SidebarContext } from "./SuperAdmin/SidebarContext";
import Sidebar from "../components/Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import { HiddenProvider } from "../Context/HiddenContent";
import moment from "moment-timezone";
import "moment/locale/en-in";

function ProductionAccounts() {
  const { view } = useContext(SidebarContext);
  const { projectId, title } = useParams();
  const [projects, setProjects] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [years, setYears] = useState([]);
  const [year, setYear] = useState("");
  const tabRef = useRef(null);
  const ls = new SecureLS();
  const navigate = useNavigate();
  const scrollLeft = () => {
    if (tabRef.current) {
      tabRef.current.scrollBy({ left: -100, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (tabRef.current) {
      tabRef.current.scrollBy({ left: 100, behavior: "smooth" });
    }
  };

  const fetchAllProjects = () => {
    axios
      .get(`api/v1/projects`)
      .then((response) => {
        const project = response.data.projects;
        setProjects(project);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchYears = () => {
    const Years = Array.from({ length: 21 }, (_, i) => ({
      year: 2020 + i,
      year_name: 2020 + i,
    }));
    setYears(Years);
  };

  const tabs = [
    { id: 1, label: "Expense Accounts" },
    { id: 2, label: "Artist Accounts" },
    { id: 3, label: "Crew Accounts" },
    { id: 4, label: "Vendor Accounts" },
    { id: 5, label: "Location Accounts" },
    { id: 6, label: "Catering Accounts" },
    { id: 7, label: "Budget Plan" },
    { id: 8, label: "Spender Accounts" },
    { id: 9, label: "Tax Summary" },
    { id: 10, label: "Accounts Summary" },
  ];

  const renderContent = () => {
    switch (activeTab) {
      case 1:
        return (
          <HiddenProvider>
            <ExpenseAccount />
          </HiddenProvider>
        );
      case 2:
        return (
          <HiddenProvider>
            <ArtistAccount />
          </HiddenProvider>
        );
      case 3:
        return (
          <HiddenProvider>
            <CrewAccount />
          </HiddenProvider>
        );
      case 4:
        return (
          <HiddenProvider>
            <VendorAccount />
          </HiddenProvider>
        );
      case 5:
        return (
          <HiddenProvider>
            <LocationAccounts />
          </HiddenProvider>
        );
      case 6:
        return (
          <HiddenProvider>
            <CateringAccount />
          </HiddenProvider>
        );
      case 7:
        return (
          <HiddenProvider>
            <BudgetPlan />
          </HiddenProvider>
        );
      case 8:
        return (
          <HiddenProvider>
            <Spender />
          </HiddenProvider>
        );
      case 9:
        return (
          <HiddenProvider>
            <TaxSummary />
          </HiddenProvider>
        );
      case 10:
        return (
          <HiddenProvider>
            <AccountSummary />
          </HiddenProvider>
        );
      default:
        return (
          <HiddenProvider>
            <AccountSummary />
          </HiddenProvider>
        );
    }
  };

  useEffect(() => {
    fetchYears();
    fetchAllProjects();
    const storedActiveTab = localStorage.getItem("AccountActiveTab");

    if (storedActiveTab) {
      setActiveTab(parseInt(storedActiveTab));
    } else {
      setActiveTab(1);
    }
  }, []);

  const convertToIST = (utcDateStr) => {
    return moment.utc(utcDateStr).tz("Asia/Kolkata").format("YYYY");
  };

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    localStorage.setItem("AccountActiveTab", tabId);
  };

  const filterProjectOnBaseOfYear = projects.filter((data) =>
    convertToIST(data.created_at).includes(year)
  );

  return (
    <div>
      <Sidebar />
      <div
        className={`h-screen p-7 ${
          !view ? "ml-40 duration-100 ease-in" : "ml-0 duration-100 ease-in"
        }`}
      >
        <div className="flex md:flex-row flex-col items-center justify-between sm:ml-20 ml-0">
          <div className="grid sm:grid-cols-3 grid-cols-2 gap-2 sm:gap-4 w-full">
            <div>
              <label className="block mb-2 text-sm text-gray-600 ">Year</label>
              <Select
                options={years}
                getOptionLabel={(option) => option.year_name}
                getOptionValue={(option) => option.year}
                onChange={(selectedOption) => setYear(selectedOption.year)}
              />
            </div>
            <div>
              <label className="block mb-2 text-sm text-gray-600">
                Project
              </label>
              <Select
                options={filterProjectOnBaseOfYear}
                getOptionLabel={(option) => option.title}
                getOptionValue={(option) => option.id}
                onChange={(selectedOption) => {
                  navigate(
                    `/Accounts/${selectedOption.id}/${selectedOption.title}`
                  );
                  window.location.reload();
                }}
              />
            </div>
          </div>
          {title != "undefined" && (
            <div>
              <h2 className="text-lg font-semibold text-gray-800 mt-4">
                {title}
              </h2>
            </div>
          )}
        </div>

        <div>
          {projectId === "undefined" ? (
            <div>
              <div className="flex items-center justify-center h-96">
                <h2 className="text-3xl text-[#1d5b79] font-bold">
                  SELECT PROJECT
                </h2>
              </div>
            </div>
          ) : (
            <>
              <nav
                ref={tabRef}
                className="bg-white w-auto border-b border-gray-300 flex flex-row items-start justify-start xl:ml-24 2xl:ml-24 md:ml-24 sm:ml-24 ml-0 xl:mt-10 mt-14 overflow-hidden overflow-x-auto no-scrollbar"
              >
                {tabs.map((tab, index) => (
                  <h1
                    key={index}
                    onClick={() => handleTabClick(tab.id)}
                    className={`mx-3 py-2 px-3 cursor-pointer group relative ${
                      activeTab === tab.id
                        ? "border-[#1d5b79] border-b-[3px]"
                        : ""
                    }`}
                  >
                    {tab.label}
                    <span className="absolute -bottom-1 left-0 w-0 h-[6px] bg-[#1d5b79] transition-all duration-300 group-hover:w-full" />
                  </h1>
                ))}
                <div className="absolute opacity-100 sm:left-24 left-5 top-36">
                  <button
                    onClick={scrollLeft}
                    className="bg-white bg-opacity-20 text-black p-2 rounded-full backdrop-blur-md shadow-lg"
                  >
                    <FaChevronLeft />
                  </button>
                </div>
                <div className="absolute opacity-100 right-5 top-36">
                  <button
                    onClick={scrollRight}
                    className="bg-white bg-opacity-20 text-black p-2 rounded-full backdrop-blur-md shadow-lg"
                  >
                    <FaChevronRight />
                  </button>
                </div>
              </nav>
              <div>
                <div>{renderContent()}</div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductionAccounts;

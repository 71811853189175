import { Field, Formik, Form, FieldArray, ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import FormikInput from "../Input/FormikInput";
import axios from "axios";
import Button from "../Button/Button";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import FormikSelect from "../Input/FormikSelect";

function ShotDivisionForm({
  scenes,
  validationSchema,
  setView,
  View,
  shotDetails,
  fetchShotDetails,
}) {
  const [Shotangle, setShotangle] = useState([]);
  const [Shotfocus, setShotfocus] = useState([]);
  const [Shottype, setShottype] = useState([]);
  const [Shotframe, setShotframe] = useState([]);
  const [Shotmechanism, setShotmechanism] = useState([]);
  const [Shotmovements, setShotmovements] = useState([]);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [displaybutton, setDisplaybutton] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const { projectId } = useParams();
  const [next, setNext] = useState(false);
  const [filter_scene_numbers, setFilter_scene_numbers] = useState([]);
  useEffect(() => {
    const scene_numbers = shotDetails.map((e) => {
      const scene = e.scene.scene_number + e.scene.sub_scene;
      return scene;
    });

    const filterdata = scenes.filter(
      (data) => !scene_numbers.includes(data.scene_number + data.sub_scene)
    );

    setFilter_scene_numbers(filterdata);
  }, [shotDetails, scenes]);

  const getShotDivisionData = () => {
    axios
      .get(`api/v1/shots`)
      .then((response) => {
        const angles = response.data
          .map((e) => {
            return e?.add_shot_angle;
          })
          .flat();
        setShotangle(angles);
        const focuses = response.data
          .map((e) => {
            return e?.add_shot_focus;
          })
          .flat();
        setShotfocus(focuses);
        const types = response.data
          .map((e) => {
            return e?.add_shot_type;
          })
          .flat();

        setShottype(types);
        const frames = response.data
          .map((e) => {
            return e?.add_shot_frame;
          })
          .flat();
        setShotframe(frames);
        const mechanisms = response.data
          .map((e) => {
            return e?.add_shot_mechanism;
          })
          .flat();
        setShotmechanism(mechanisms);
        const movements = response.data
          .map((e) => {
            return e?.add_shot_movements;
          })
          .flat();
        setShotmovements(movements);
      })
      .catch((error) => console.log(error.message));
  };
  useEffect(() => {
    getShotDivisionData();
  }, []);
  const handleBackdropClick = () => {
    setView(!View);
  };
  const submitHandler = (value, { resetForm }) => {
    setDisplaybutton(false);
    const loaderToast = toast.loading("Adding...", {
      autoClose: false,
    });

    Promise.all(
      value.shot_details.details.map((detail, index) => {
        return axios.post(
          `api/v1/projects/${projectId}/shot_details?scene_id=${value.scene_number}`,
          {
            shot_detail: {
              shot_number: index + 1,
              shot_type: detail.shottype,
              shot_focus: detail.shotfocus,
              shot_angle: detail.shotangle,
              shot_frame: detail.shotframe,
              shot_mechanism: detail.shotmechanism,
              shot_movements: detail.shotmovements,
              brief: detail.brief,
              remark: detail.remark,
            },
          }
        );
      })
    )
      .then((responses) => {
        toast.dismiss(loaderToast);
        toast.success("Shot added successfully");
        responses.forEach((res) => {
          if (res.status === 201) {
            resetForm();
            setView(true);
            fetchShotDetails();
            setButtonClicked(false);
          }
        });
      })
      .catch((err) => {
        toast.dismiss(loaderToast);
        toast.error(
          err.response.data.message || err.message || err.response.data.error
        );
        setView(true);
      })
      .finally(() => {
        toast.dismiss(loaderToast);
      });
  };

  const numberOfShots = [
    { name: 1 },
    { name: 2 },
    { name: 3 },
    { name: 4 },
    { name: 5 },
    { name: 6 },
    { name: 7 },
    { name: 8 },
    { name: 9 },
    { name: 10 },
  ];

  const initialValues = {
    scene_number: "",
    number_of_shot: "",
    shot_details: {
      details: [], // Will be populated dynamically
    },
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 overflow-y-auto sm:overflow-auto overflow-x-hidden">
      <div className="flex items-center justify-center z-50 mt-auto mb-auto sm:mt-auto sm:mb-auto mx-96">
        <div className="w-full max-w-5xl p-4 sm:p-8 bg-white rounded shadow-md  mx-96">
          <div className="flex items-end justify-end">
            <button
              className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full "
              onClick={handleBackdropClick}
            >
              <RxCross1 />
            </button>
          </div>
          <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
            Shot Divison
          </h2>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submitHandler}
          >
            {(formik) => {
              return (
                <Form>
                  {!next ? (
                    <div className="grid grid-cols-2 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                      <div>
                        <FormikSelect
                          label={"Select scene"}
                          onChange={(e) =>
                            formik.setFieldValue("scene_number", e.target.value)
                          }
                          selectedOption={"Scene"}
                          optionData={filter_scene_numbers.sort(
                            (a, b) => a.scene_number - b.scene_number
                          )}
                          labelProperty="scene_number"
                          label2propery={"sub_scene"}
                          valueProperty="id"
                          name={"scene_number"}
                        />
                      </div>
                      <div className="">
                        <FormikSelect
                          label={"Number of shots"}
                          name={"number_of_shot"}
                          selectedOption={"Number of shots"}
                          optionData={numberOfShots}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "number_of_shot",
                              e.target.value
                            );

                            formik.setFieldValue(
                              "shot_details.details",
                              Array.from(
                                { length: e.target.value },
                                (_, index) => ({
                                  shotnumber: index + 1,
                                  shottype: "",
                                })
                              )
                            );
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div>
                      <FieldArray name="shot_details.details">
                        {({ push, remove, form, index }) => (
                          <div>
                            {form.values.number_of_shot &&
                              [
                                ...Array(parseInt(form.values.number_of_shot)),
                              ].map((_, index) => (
                                <div>
                                  <h2 className="mt-2 text-base sm:text-xl font-semibold mb-2 sm:mb-4 text-[#020002]">
                                    Shot{" "}
                                    {
                                      form.values.shot_details.details[index]
                                        .shotnumber
                                    }
                                  </h2>
                                  <div
                                    key={index}
                                    className="grid grid-cols-2 sm:grid-cols-3 gap-2 sm:gap-4 mt-2 sm:mt-4"
                                  >
                                    <div>
                                      <FormikInput
                                        name={`shot_details.details[${index}].shotnumber`}
                                        type={"number"}
                                        readOnly={true}
                                        label={"Shot number"}
                                      />
                                    </div>
                                    <div>
                                      <label className="block mb-2 text-sm text-gray-600 ">
                                        Shot type
                                      </label>
                                      <Field
                                        name={`shot_details.details[${index}].shottype`}
                                        as="select"
                                        className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md focus:border-[#1d5b79] focus:ring-[#1d5b79] focus:outline-none focus:ring focus:ring-opacity-40"
                                      >
                                        <option value="" selected>
                                          Shot Type
                                        </option>
                                        {Shottype.sort().map(
                                          (shotType, index) => (
                                            <option
                                              key={index}
                                              value={shotType}
                                            >
                                              {shotType}
                                            </option>
                                          )
                                        )}
                                      </Field>
                                      <ErrorMessage
                                        name={`shot_details.details[${index}].shottype`}
                                        component="div"
                                        className="text-red-500"
                                      />
                                    </div>
                                    <div>
                                      <label className="block mb-2 text-sm text-gray-600 ">
                                        Shot Focus
                                      </label>
                                      <Field
                                        name={`shot_details.details[${index}].shotfocus`}
                                        as="select"
                                        className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#1d5b79]  focus:ring-[#1d5b79] focus:outline-none focus:ring focus:ring-opacity-40"
                                      >
                                        <option selected>Shot Focus</option>
                                        {Shotfocus.sort().map(
                                          (angles, index) => (
                                            <option key={index}>
                                              {angles}
                                            </option>
                                          )
                                        )}
                                      </Field>
                                    </div>
                                    <div>
                                      <label className="block mb-2 text-sm text-gray-600 ">
                                        Shot Angle
                                      </label>
                                      <Field
                                        name={`shot_details.details[${index}].shotangle`}
                                        as="select"
                                        className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#1d5b79]  focus:ring-[#1d5b79] focus:outline-none focus:ring focus:ring-opacity-40"
                                      >
                                        <option selected>Shot Angle</option>
                                        {Shotangle.sort().map(
                                          (angles, index) => (
                                            <option key={index}>
                                              {angles}
                                            </option>
                                          )
                                        )}
                                      </Field>
                                    </div>
                                    <div>
                                      <label className="block mb-2 text-sm text-gray-600 ">
                                        Shot Frame
                                      </label>
                                      <Field
                                        name={`shot_details.details[${index}].shotframe`}
                                        as="select"
                                        className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#1d5b79]  focus:ring-[#1d5b79] focus:outline-none focus:ring focus:ring-opacity-40"
                                      >
                                        <option selected>Shot Frame</option>
                                        {Shotframe.sort().map(
                                          (frames, index) => (
                                            <option key={index}>
                                              {frames}
                                            </option>
                                          )
                                        )}
                                      </Field>
                                    </div>
                                    <div>
                                      <label className="block mb-2 text-sm text-gray-600 ">
                                        Shot Mechanism
                                      </label>
                                      <Field
                                        name={`shot_details.details[${index}].shotmechanism`}
                                        as="select"
                                        className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#1d5b79]  focus:ring-[#1d5b79] focus:outline-none focus:ring focus:ring-opacity-40"
                                      >
                                        <option selected>Shot Mechanism</option>
                                        {Shotmechanism.sort().map(
                                          (mechanisms, index) => (
                                            <option key={index}>
                                              {mechanisms}
                                            </option>
                                          )
                                        )}
                                      </Field>
                                    </div>
                                    <div>
                                      <label className="block mb-2 text-sm text-gray-600 ">
                                        Shot Movements
                                      </label>
                                      <Field
                                        name={`shot_details.details[${index}].shotmovements`}
                                        as="select"
                                        className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#1d5b79]  focus:ring-[#1d5b79] focus:outline-none focus:ring focus:ring-opacity-40"
                                      >
                                        <option selected>Shot Movement</option>
                                        {Shotmovements.sort().map(
                                          (movements, index) => (
                                            <option key={index}>
                                              {movements}
                                            </option>
                                          )
                                        )}
                                      </Field>
                                    </div>
                                    <div>
                                      <FormikInput
                                        label={"Remarks"}
                                        name={`shot_details.details[${index}].remark`}
                                        placeholder={"remark"}
                                        type={"text"}
                                      />
                                    </div>
                                    <div>
                                      <FormikInput
                                        name={`shot_details.details[${index}].brief`}
                                        placeholder={"brief"}
                                        type={"text"}
                                        label={"Brief"}
                                      />
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        )}
                      </FieldArray>
                    </div>
                  )}
                  <div className="flex w-full items-end justify-end mt-2 sm:mt-4">
                    {!next ? (
                      <Button
                        type={"button"}
                        name={"Next"}
                        disable={
                          formik.values.number_of_shot === "" ? true : false
                        }
                        clickFunction={() => setNext(true)}
                      />
                    ) : (
                      <div className="flex w-full items-end justify-end mt-2 sm:mt-4">
                        <div>
                          <Button
                            type={"button"}
                            name={"Previous"}
                            clickFunction={() => setNext(false)}
                          />
                        </div>
                        <div className="ml-4">
                          <Button type={"submit"} name={"Add"} />
                        </div>
                      </div>
                    )}
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default ShotDivisionForm;

import React from "react";
import Navbar from "./Components/Navbar";
import { TfiNotepad } from "react-icons/tfi";
import mockUp from "../../assets/image/Mock-up.png";
import { TiTick } from "react-icons/ti";
import { FaMoneyBillWave } from "react-icons/fa6";
import { AiFillSchedule } from "react-icons/ai";
import Footer from "./Components/Footer";
import { FaUser } from "react-icons/fa";
import { MdTimeline } from "react-icons/md";

function Features() {
  return (
    <div>
      <Navbar />
      <section className="bg-[#1d5b79] py-20 text-white">
        <div className="container mx-auto px-4 md:px-8">
          <div className="max-w-3xl text-center">
            <h1 className="text-4xl font-bold mb-4">CinemaPro Features</h1>
          </div>
        </div>
      </section>
      <section className="py-16">
        <div className="container mx-auto px-4 md:px-8">
          <div className="space-y-12">
            <div className="flex flex-col md:flex-row items-center gap-8 mt-5">
              <div className="flex-1">
                <TfiNotepad className="text-[#1d5b79] h-12 w-12 mb-4" />
                <h3 className="text-2xl font-bold mb-2">Call Sheet Generate</h3>
                <p className="text-gray-600">
                  Easily create detailed call sheets for each day of production.
                  Include essential information such as shooting schedule,
                  location, scene breakdown, cast, crew, and special
                  instructions
                </p>
                <ul className="text-gray-600 space-y-2 mt-4">
                  <li className="flex">
                    <TiTick className="mt-1 text-[#1d5b79]" /> Version control
                    for script revisions
                  </li>
                  <li className="flex">
                    <TiTick className="mt-1 text-[#1d5b79]" /> Collaborative
                    editing and commenting
                  </li>
                  <li className="flex">
                    <TiTick className="mt-1 text-[#1d5b79]" /> Automatic change
                    tracking and notifications
                  </li>
                  <li className="flex">
                    <TiTick className="mt-1 text-[#1d5b79]" />
                    Secure cloud storage for your scripts
                  </li>
                </ul>
              </div>
              <div className="flex-1">
                <img
                  alt="Hero Image"
                  className="rounded-lg"
                  height="400"
                  src={mockUp}
                  width="600"
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row-reverse items-center gap-8 mt-5">
              <div className="flex-1">
                <FaMoneyBillWave className="text-[#1d5b79] h-12 w-12 mb-4" />
                <h3 className="text-2xl font-bold mb-2">Tracking Expenses</h3>
                <p className="text-gray-600">
                  Monitor expenses meticulously. This feature allows you to keep
                  a record of all financial transactions related to your film
                  projects. It covers everything from payments received (income)
                  to costs incurred (expenses) during production,
                  post-production, marketing, and distribution.
                </p>
                <ul className="text-gray-600 mt-4 grid grid-cols-1 sm:grid-cols-2">
                  <li className="flex">
                    <TiTick className="mt-1 text-[#1d5b79]" /> Transaction
                    Receipts
                  </li>
                  <li className="flex">
                    <TiTick className="mt-1 text-[#1d5b79]" /> Expense
                    Management
                  </li>
                  <li className="flex">
                    <TiTick className="mt-1 text-[#1d5b79]" /> Receipt Image
                    Storage
                  </li>
                  <li className="flex">
                    <TiTick className="mt-1 text-[#1d5b79]" />
                    Integration with Accounting Systems
                  </li>
                  <li className="flex">
                    <TiTick className="mt-1 text-[#1d5b79]" />
                    Real-Time Reporting and Analytics
                  </li>
                  <li className="flex">
                    <TiTick className="mt-1 text-[#1d5b79]" />
                    Multi-Currency Support
                  </li>
                  <li className="flex">
                    <TiTick className="mt-1 text-[#1d5b79]" />
                    Approval Workflows
                  </li>
                  <li className="flex">
                    <TiTick className="mt-1 text-[#1d5b79]" />
                    Mobile Accessibility
                  </li>
                </ul>
              </div>
              <div className="flex-1">
                <img
                  alt="Hero Image"
                  className="rounded-lg"
                  height="400"
                  src={mockUp}
                  width="600"
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row items-center gap-8 mt-5">
              <div className="flex-1">
                <AiFillSchedule className="h-12 w-12 text-[#1d5b79] mb-4" />
                <h3 className="text-2xl font-bold mb-2">Scene Scheduling</h3>
                <ul className="text-gray-600 space-y-2 mt-4">
                  <li className="flex">
                    <TiTick className="mt-1 text-[#1d5b79]" /> Auto-Scheduling
                    Scenes
                  </li>
                  <p className="text-gray-600">
                    Automatically order scenes based on factors like location,
                    time of day, and other criteria. Customize scheduling
                    preferences while the software auto-generates shooting days
                  </p>
                  <li className="flex">
                    <TiTick className="mt-1 text-[#1d5b79]" />
                    Collaboration and Customization
                  </li>
                  <p className="text-gray-600">
                    Automatically order scenes based on factors like location,
                    time of day, and other criteria. Customize scheduling
                    preferences while the software auto-generates shooting days
                  </p>
                </ul>
              </div>
              <div className="flex-1">
                <img
                  alt="Hero Image"
                  className="rounded-lg"
                  height="400"
                  src={mockUp}
                  width="600"
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row-reverse items-center gap-8 mt-5">
              <div className="flex-1">
                <FaMoneyBillWave className="text-[#1d5b79] h-12 w-12 mb-4" />
                <h3 className="text-2xl font-bold mb-2">Script Breakdown</h3>
                <p className="text-gray-600">
                  Introducing the revolutionary "Script Breakdown" feature in
                  our Film Management software! Get ready to streamline your
                  production process like never before. With this cutting-edge
                  tool, you can easily analyze and break down your script into
                  key elements such as scenes, characters, props, and locations.
                  This feature empowers you to efficiently organize and manage
                  every aspect of your film production. Say goodbye to tedious
                  manual breakdowns and hello to automation at its finest. With
                  just a few clicks, you can generate comprehensive reports and
                  share them with your team, ensuring everyone is on the same
                  page. The Script Breakdown feature is a game-changer for
                  filmmakers, saving you time, effort, and headaches. Start
                  maximizing your productivity and creativity with this powerful
                  tool today!
                </p>
              </div>
              <div className="flex-1">
                <img
                  alt="Hero Image"
                  className="rounded-lg"
                  height="400"
                  src={mockUp}
                  width="600"
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row items-center gap-8 mt-5">
              <div className="flex-1">
                <FaUser className="h-12 w-12 text-[#1d5b79] mb-4" />
                <h3 className="text-2xl font-bold mb-2">
                  Film Crew Management
                </h3>
                <ul className="text-gray-600 space-y-2 mt-4">
                  <li className="flex">
                    <TiTick className="mt-1 text-[#1d5b79]" /> Crew Contact
                    Management
                  </li>
                  <p className="text-gray-600">
                    Contact management software organizes your production’s
                    contacts in one easy-to-use interface. From the executive
                    producers to the latest day players, it’s all securely
                    stored here
                  </p>
                  <li className="flex">
                    <TiTick className="mt-1 text-[#1d5b79]" />
                    Role Management
                  </li>
                  <p className="text-gray-600">
                    Filter contacts by standard film production roles and skill
                    sets for quicker discovery. No more wasting time trying to
                    find someone’s contact info alphabetically with misspelled
                    last names or awkward email addresses
                  </p>
                </ul>
              </div>
              <div className="flex-1">
                <img
                  alt="Hero Image"
                  className="rounded-lg"
                  height="400"
                  src={mockUp}
                  width="600"
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row-reverse items-center gap-8 mt-5">
              <div className="flex-1">
                <MdTimeline className="text-[#1d5b79] h-12 w-12 mb-4" />
                <h3 className="text-2xl font-bold mb-2">Script Breakdown</h3>
                <p className="text-gray-600">
                  Create and manage shooting schedules, rehearsals, and
                  post-production timelines. Avoid conflicts and optimize
                  resource allocation.
                </p>
                <ul className="text-gray-600 space-y-2 mt-4">
                  <li className="flex">
                    <TiTick className="mt-1 text-[#1d5b79]" /> Visual Overview
                  </li>
                  <li className="flex">
                    <TiTick className="mt-1 text-[#1d5b79]" />
                    Task Organization
                  </li>
                  <li className="flex">
                    <TiTick className="mt-1 text-[#1d5b79]" /> Task Assignment
                    and Collaboration
                  </li>
                </ul>
              </div>
              <div className="flex-1">
                <img
                  alt="Hero Image"
                  className="rounded-lg"
                  height="400"
                  src={mockUp}
                  width="600"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Features;

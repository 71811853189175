import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { useParams } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import ActorSheet from "./actorComponent/ActorSheet";

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);
function ActorSchedule() {
  const [artistScheduleData, setArtistScheduleData] = useState([]);
  const [artistScene, setArtistScene] = useState([]);
  const [artistSheet, setArtistSheet] = useState([]);
  const [sheetData, setSheetData] = useState(null);
  const [open, setOpen] = useState(false);
  const { artistId } = useParams();

  const openSheet = (sheetId) => {
    const sheet_Data = artistScheduleData.find((sheet) => sheet.id === sheetId);
    setSheetData(sheet_Data);
    setOpen(!open);
  };

  const views = { month: true, day: false, week: false };

  const fetchArtistScheduleData = () => {
    axios
      .get(`api/v1/projects/${artistId}/artists/get_artist_schedules`)
      .then((response) => {
        setArtistScheduleData(response.data.artist_data.schedules);
        setArtistScene(response.data.artist_data.scenes);
      })
      .catch((err) => {
        toast.error(err.response.data.message || err.message || err.response.data.error);
      });
  };

  useEffect(() => {
    fetchArtistScheduleData();
  }, []);

  const eventStyleGetter = (event) => {
    return {
      style: {
        backgroundColor: "#9ADE7B",
        fontSize: "20px",
        borderRadius: "15px",
        fontWeight: "bold",
        border: "none",
      },
    };
  };

  const stopClose = (e) => {
    e.stopPropagation();
  };

  const onClose = () => {
    setOpen(!open);
  };

  return (
    <div>
      <div className="flex items-center justify-center xl:ml-20 2xl:ml-20 md:ml-20 lg:ml-20 sm:ml-0 ml-5">
        <div className="sm:mt-5 mt-20  bg-[#fbffff] p-2 shadow-lg rounded-lg">
          <div className="calendar-container">
            <DnDCalendar
              localizer={localizer}
              startAccessor="schedule_date"
              events={artistScheduleData}
              endAccessor="schedule_date"
              style={{ height: 602, width: "100%" }}
              showMultiDayTimes
              views={views}
              eventPropGetter={eventStyleGetter}
              draggableAccessor={(event) => true}
              onSelectEvent={(event) => openSheet(event.id)}
            />
          </div>
        </div>
      </div>
      {open && (
        <ActorSheet
          sheetData={sheetData}
          onClose={onClose}
          stopClose={stopClose}
          artistScene={artistScene}
        />
      )}
    </div>
  );
}

export default ActorSchedule;

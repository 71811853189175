import React from "react";
import { RxCross1 } from "react-icons/rx";

function ModalExtras({ detail, onClose, stopClose }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black overflow-auto bg-opacity-50">
      <div className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto ">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-sm sm:max-w-7xl w-full mx-auto">
          <div className="flex items-end justify-end">
            <button
              className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full "
              onClick={onClose}
            >
              <RxCross1 />
            </button>
          </div>
          <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
            Extras
          </h2>
          <div className="grid grid-cols-1 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4 ">
            <div className="w-80">
              <div className="bg-[#1d5b79] shadow-md p-5 rounded-md text-[#fbffff] m-2 sm:m-5 h-52">
                <h1 className="font-semibold tracking-wide text-lg">
                  Action Director: {detail.action_director}
                </h1>
                <h1 className="font-semibold tracking-wide text-lg">
                  Nmber of action man: {detail.no_of_action_man}
                </h1>
                <h1 className="font-semibold tracking-wide text-lg">
                  Action man Costume: {detail.action_man_costume}
                </h1>
              </div>
            </div>
            <div className="w-80 h-40">
              <div className="bg-[#1d5b79] shadow-md p-5 rounded-md text-[#fbffff] m-2 sm:m-5 h-52">
                <h1 className="font-semibold tracking-wide text-lg">
                  Choregrapher: {detail.dance_choreographer}
                </h1>
                <h1 className="font-semibold tracking-wide text-lg">
                  Number of dancers: {detail.no_of_dancers}
                </h1>
                <h1 className="font-semibold tracking-wide text-lg">
                  Dancer costume: {detail.dancers_costume}
                </h1>
              </div>
            </div>
            <div className="w-80">
              <div className="bg-[#1d5b79] shadow-md p-5 rounded-md text-[#fbffff] m-2 mt-20 sm:m-5 h-52">
                <h1 className="font-semibold tracking-wide text-lg">
                  VFX: {detail.vfx}
                </h1>
              </div>
            </div>

            <div className="w-80">
              <div className="bg-[#1d5b79] shadow-md p-5 rounded-md text-[#fbffff] m-2 sm:m-5 h-52">
                <h1 className="font-semibold tracking-wide text-lg">
                  Additional lights: {detail.type_of_light}
                </h1>
              </div>
            </div>
          </div>
            <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
              Crowd
            </h2>

          <div className="grid grid-cols-1 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4 ">
            {detail.crowd_details &&
              detail.crowd_details.details.map((crowd, index) => (
                <div className="w-80">
                  <div className="bg-[#1d5b79] shadow-md p-5 rounded-md text-[#fbffff] m-2 sm:m-5">
                    <h1 className="font-bold text-xl tracking-wider">
                      Crowd {index + 1}
                    </h1>
                    <h1 className="font-semibold tracking-wide text-lg">
                      Gender: {crowd.crowdType}
                    </h1>
                    <h1 className="font-semibold tracking-wide text-lg">
                      Age Group : {crowd.crowdAge}
                    </h1>
                    <h1 className="font-semibold tracking-wide text-lg">
                      Number of People: {crowd.crowdNumber}
                    </h1>
                    <h1 className="font-semibold tracking-wide text-lg">
                      Costume: {crowd.crowdCostume}
                    </h1>
                    <h1 className="font-semibold tracking-wide text-lg">
                      Description: {crowd.description}
                    </h1>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalExtras;

import { createContext, useState } from "react";
import SecureLS from "secure-ls";

const SubRoleContext = createContext();

export const SubRoleProvider = ({ children }) => {
  const ls = new SecureLS();

  const subRole = ls.get("sub_role");

  return (
    <SubRoleContext.Provider value={subRole}>
      {children}
    </SubRoleContext.Provider>
  );
};

export default SubRoleContext;

import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import SpenderTable from "../../components/Table/SpenderTable";
import Button from "../../components/Button/Button";
import SpenderModal from "../../Modal/SpenderModal";
import AddSpenderForm from "../../components/Forms/AddSpenderForm";
import Loader from "../../Loader/loader";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import ErrorPage from "../ErrorPage";
import { HiddenContext } from "../../Context/HiddenContent";

function Spender() {
  const [loading, setLoading] = useState(false);
  const [spenderList, setSpenderList] = useState([]);
  const [allocatedSpenders, setAllocatedSpenders] = useState([]);
  const [selectedSpender, setSelectedSpender] = useState("");
  const [open, setOpen] = useState(false);
  const [view, setView] = useState(false);
  const [error, setError] = useState(false);
  const { projectId } = useParams();
  const { hidden } = useContext(HiddenContext);

  const allocatedSpendersData = () => {
    axios
      .get(
        `api/v1/projects/${projectId}/project_crews/list_of_spenders?allocated_spenders=true`
      )
      .then((res) => {
        setLoading(true);
        setAllocatedSpenders(res.data.spenders);
      })
      .catch((err) => {
        setError(true);
        console.log(err);
      });
  };

  const getSpendersList = () => {
    axios
      .get(`api/v1/projects/${projectId}/project_crews/list_of_spenders`)
      .then((res) => {
        setSpenderList(res.data.spenders);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAddAssingSpender = (value, { resetForm }) => {
    const loader = toast.loading("Assigning amount", { autoClose: false });
    axios
      .post(`api/v1/projects/${projectId}/expenses/assign_expense`, {
        project_crew_id: value.crew,
        amount: value.amount,
      })
      .then((res) => {
        if (res.status === 200) {
          toast.dismiss(loader);
          toast.success(res.data.message);
          setView(false);
          allocatedSpendersData();
        }
      })
      .catch((err) => {
        toast.error(
          err.response.data.message || err.message || err.response.data.error
        );
        toast.dismiss(loader);
        console.log(err);
      });
  };

  useEffect(() => {
    allocatedSpendersData();
    getSpendersList();
  }, []);

  const handleViewSpender = (spender) => {
    setSelectedSpender({ ...spender });
    setOpen(true);
  };

  return (
    <div>
      {error ? (
        <ErrorPage />
      ) : (
        <div>
          {!loading ? (
            <Loader />
          ) : (
            <div className="flex flex-col sm:flex sm:flex-col p-5">
              {hidden ? (
                <></>
              ) : (
                <div className="flex sm:items-start items-center justify-center sm:justify-start xl:ml-20 2xl:ml-20 md:ml-20 sm:ml-20 ml-0 sm:mt-5 mt-8">
                  <Button
                    clickFunction={() => setView(true)}
                    name={"Add Spender"}
                  />
                </div>
              )}
              <div className="flex items-center justify-center  xl:ml-20 2xl:ml-20 lg:ml-20 md:ml-20 sm:ml-20 ml-0">
                <div className="w-full mt-5">
                  <SpenderTable
                    detail={allocatedSpenders}
                    handleViewSpender={handleViewSpender}
                  />
                </div>
              </div>
            </div>
          )}

          {view && (
            <AddSpenderForm
              setView={setView}
              spender={spenderList}
              onSubmit={handleAddAssingSpender}
            />
          )}
          {open && <SpenderModal setOpen={setOpen} data={selectedSpender} />}
        </div>
      )}
    </div>
  );
}

export default Spender;

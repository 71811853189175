import React from "react";
import { RxCross1 } from "react-icons/rx";
import { FaArrowRight } from "react-icons/fa6";
import { MdOutlinePayment } from "react-icons/md";
import PricingComponent from "../Pages/LandingPages/Components/PricingComponent";

function UpgradePackage({
  user,
  setView,
  packageData,
  handlePayment,
  ContactSales,
  selectPlan,
  togglePlan,
  Visible,
  setSelectPlan,
  setVisible,
}) {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 overflow-auto no-scrollbar bg-black bg-opacity-50">
      <div className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto ">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-7xl sm:max-w-7xl w-full sm:my-auto  my-auto">
          <div className="flex items-end justify-end">
            <button
              className="bg-[#1d5b79] p-2 text-lg text-[#fbffff] rounded-full "
              onClick={() => {
                setView(false);
                setVisible(false);
                setSelectPlan("");
              }}
            >
              <RxCross1 />
            </button>
          </div>
          <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
            Upgrade your current package
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3  gap-4 mt-5">
            {packageData
              .sort((a, b) => a.price - b.price)
              .map((Package) => (
                <div key={Package.id}>
                  <button
                    className={`${
                      selectPlan === Package.name
                        ? "border-4 border-[#a9c8e2] rounded-2xl "
                        : ""
                    }`}
                    onClick={() => togglePlan(Package.name)}
                    disabled={user && Package.price <= user.package.price}
                  >
                    <PricingComponent
                      name={Package.name}
                      price={Package.price}
                      details={Package.package_details.details}
                      userPackage={user && user.package.name}
                      selectPlan={selectPlan}
                    />
                  </button>
                </div>
              ))}
          </div>
          {Visible && (
            <div className="flex mt-16">
              <button
                onClick={() => handlePayment(selectPlan)}
                className="inline-flex items-center px-8 py-3 text-white transition bg-[#1d5b79] rounded-full shadow-lg focus:outline-none   hover:bg-[#a9c8e2]"
              >
                <span className="text-sm font-medium mr-2">Pay</span>
                <MdOutlinePayment />
              </button>
              <button
                onClick={() => ContactSales(selectPlan)}
                className="ml-5 inline-flex items-center px-8 py-3 text-white transition bg-[#1d5b79] rounded-full shadow-lg focus:outline-none   hover:bg-[#a9c8e2]"
              >
                <span className="text-sm font-medium mr-2">Contact Sales</span>
                <FaArrowRight />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default UpgradePackage;

import React, { useContext, useEffect, useRef, useState } from "react";
import Sidebar from "./superAdminComponent/Sidebar";
import axios from "axios";
import RequestTable from "./superAdminComponent/Table/RequestTable";
import { SidebarContext } from "./SidebarContext";
import Button from "../../components/Button/Button";
import PremiumUserForm from "./superAdminComponent/Modal/PremiumUserForm";
import toast from "react-hot-toast";
import { DownloadTableExcel } from "react-export-table-to-excel";

function SuperAdmin() {
  const { view } = useContext(SidebarContext);
  const tableRef = useRef(null);
  const [userData, setUserData] = useState([]);

  const [open, setOpen] = useState(false);

  const getUserData = () => {
    axios
      .get(`api/v1/users`)
      .then((response) => {
        setUserData(response.data.users);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  useEffect(() => {
    getUserData();
  }, []);
  const openModal = () => {
    setOpen(!open);
  };

  const handleCreatePremiumUser = (value, { resetForm }) => {
    const loader = toast.loading("Registering ....", { autoClose: false });
    axios
      .post(`api/v1/users/sign_up?premium_request=true`, {
        user: {
          email: value.email,
          first_name: value.first_name,
          last_name: value.last_name,
          company_name: value.company_name,
          phone_number: value.phone_number,
          password: value.password,

          domain_name: value.domain,
          database_url: value.database_url,
          pan_number: value.pan_number,
          tan_number: value.tan_number,
          gst_number: value.gst_number,
          is_premium_user: value.is_premium_user,
        },
      })
      .then((response) => {
        if (response.status === 201) {
          toast.dismiss(loader);
          toast.success("User created successfully 🎉");
          setOpen(false);
          getUserData();
        }
      })
      .catch((err) => {
        toast.dismiss(loader);
        toast.error(
          err.response.data.message || err.message || err.response.data.error
        );
        console.log(err);
      });
  };

  return (
    <div>
      <Sidebar />
      <div
        className={`h-screen p-7 ${
          !view ? "ml-40 duration-100 ease-in" : "ml-0 duration-100 ease-in"
        }`}
      >
        <div className="flex flex-col sm:flex sm:flex-col p-5">
          <div className="flex sm:items-start items-center justify-center sm:justify-start xl:ml-20 2xl:ml-20 md:ml-20 sm:ml-20 ml-0 sm:mt-0 mt-8">
            <Button name={"Add Premium User"} clickFunction={openModal} />
          </div>
         
          <div className="flex items-center justify-center  xl:ml-20 2xl:ml-20 lg:ml-20 sm:ml-20 ml-0">
            <div className="w-full mt-5">
              <RequestTable
                userData={userData}
                getUserData={getUserData}
                ref={tableRef}
              />
            </div>
          </div>
        </div>
      </div>
      {open && (
        <PremiumUserForm setOpen={setOpen} onSubmit={handleCreatePremiumUser} />
      )}
    </div>
  );
}

export default SuperAdmin;

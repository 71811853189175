import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ErrorPage from "../ErrorPage";
import Loader from "../../Loader/loader";
import CateringAccountTable from "../../components/Table/CateringAccountTable";
import toast from "react-hot-toast";

function CateringAccount() {
  const [loading, setLoading] = useState(false);
  const [caterers, setCaterers] = useState([]);
  const [error, setError] = useState(false);
  const { projectId } = useParams();
  const getCaterer = () => {
    axios
      .get(`api/v1/projects/${projectId}/catering_details`)
      .then((res) => {
        setCaterers(res.data.catering_details);
        setLoading(true);
      })
      .catch((err) => {
        setError(true);
        console.log(err);
      });
  };

  const handleApproveDishes = (dish) => {
    const loader = toast.loading("Approving ...");
    axios
      .put(
        `api/v1/projects/${projectId}/catering_details/${dish.id}/dishes/${dish.dishDetails.id}/approve_dishes`
      )
      .then((res) => {
        toast.dismiss(loader);
        toast.success("Dish approved successfully");
        getCaterer();
      })
      .catch((err) => {
        toast.dismiss(loader);
        toast.error(err.message);
        console.log(err);
      });
  };

  const handleRejectDish = (dish) => {
    const loader = toast.loading("Rejecting ...");
    axios
      .put(
        `api/v1/projects/${projectId}/catering_details/${dish.id}/dishes/${dish.dishDetails.id}/reject_dishes`
      )
      .then((res) => {
        toast.dismiss(loader);
        toast.success("Dish rejected successfully");
        getCaterer();
      })
      .catch((err) => {
        toast.dismiss(loader);
        toast.error(err.message);
        console.log(err);
      });
  };

  useEffect(() => {
    getCaterer();
  }, []);
  return (
    <div>
      {error ? (
        <ErrorPage />
      ) : (
        <div>
          {!loading ? (
            <Loader />
          ) : (
            <div className="flex flex-col sm:flex sm:flex-col p-5">
              <div className="flex sm:flex-row flex-col items-center justify-between">
                <div className="flex sm:items-start items-center justify-center sm:justify-start xl:ml-20 2xl:ml-20 md:ml-20 sm:ml-20 ml-0 sm:mt-0 mt-8">
                  <h1 className="text-3xl font-semibold sm:tracking-widest tracking-tight">
                    Catering Account
                  </h1>
                </div>
              </div>
              <div className="flex items-center justify-center  xl:ml-20 2xl:ml-20 lg:ml-20 md:ml-20 sm:ml-20 ml-0">
                <div className="w-full mt-5">
                  <CateringAccountTable
                    caterer={caterers}
                    approveDish={handleApproveDishes}
                    rejectDish={handleRejectDish}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default CateringAccount;

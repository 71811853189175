import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import FormikInput from "../Input/FormikInput";
import IconButton from "../Button/IconButton";
import { CiLocationArrow1 } from "react-icons/ci";

function VerifyOtp({ onSubmit, Timer, isTimerRunning, resendOtp }) {
  const validationSchema = Yup.object().shape({
    otp: Yup.number().required("Enter OTP you've received in email"),
  });

  return (
    <div>
      <Formik
        initialValues={{
          otp: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <div>
            <FormikInput
              name={"otp"}
              label={"OTP"}
              type={"text"}
              max={9999}
              length={4}
              placeholder={"xxxx"}
            />
            {isTimerRunning ? (
              <div className="w-full flex items-end justify-end">
                <p className="flex flex-row font-bold text-[#1d5b79] mt-1 cursor-pointer">
                  {Timer} seconds
                </p>
              </div>
            ) : (
              <div className="w-full flex items-end justify-end">
                <button
                  type="button"
                  onClick={resendOtp}
                  className="flex flex-row font-bold text-[#1d5b79] mt-1"
                >
                  Re-send OTP
                </button>
              </div>
            )}
          </div>
          <div className="mt-4">
            <IconButton
              name={"Verify Otp"}
              icon={<CiLocationArrow1 />}
              type={"submit"}
            />
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default VerifyOtp;

import React from "react";
import FormikInput from "../Input/FormikInput";
import { FieldArray, Form, Formik, Field } from "formik";
import Button from "../Button/Button";
import FormikSelect from "../Input/FormikSelect";
import { MdDelete } from "react-icons/md";

function UpdateDishForm({ onSubmit, setUpdate, data }) {
  console.log(data);

  return (
    <div>
      <div
        onClick={() => setUpdate(false)}
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center overflow-auto z-50"
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="flex items-center justify-center z-50 mt-auto sm:mt-auto sm:mb-auto mb-auto"
        >
          <div className="bg-white p-4 sm:p-8 shadow-md max-w-5xl w-full mx-auto rounded">
            <h2 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#1d5b79]">
              Dishes
            </h2>
            <Formik
              initialValues={{
                date: "" || data.dishDetails.c_date,
                no_of_dishes: "" || data.dishDetails.number_of_dishes,
                breakfast: "" || data.dishDetails.breakfast_dishes,
                lunch: "" || data.dishDetails.lunch_dishes,
                dinner: "" || data.dishDetails.dinner_dishes,
                hightea: "" || data.dishDetails.high_tea_dishes,
                extra: "" || data.dishDetails.extra_dishes,
              }}
              onSubmit={onSubmit}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="grid sm:grid-cols-2 grid-cols-1 gap-2 sm:gap-4 sm:mt-4 mt-2">
                    <div>
                      <FormikInput name={"date"} type={"date"} label={"Date"} />
                    </div>
                    {data.c_type === "Package" && (
                      <div>
                        <FormikInput
                          name={"no_of_dishes"}
                          type={"number"}
                          label={"No of Dishes"}
                          min={1}
                          placeholder={"60"}
                        />
                      </div>
                    )}
                    {data.c_type === "Individual" && (
                      <>
                        <div>
                          <FormikInput
                            name={"breakfast"}
                            type={"number"}
                            label={"Breakfast dish"}
                            placeholder={"60"}
                            min={1}
                          />
                        </div>
                        <div>
                          <FormikInput
                            name={"lunch"}
                            type={"number"}
                            label={"Lunch dish"}
                            placeholder={"60"}
                            min={1}
                          />
                        </div>
                        <div>
                          <FormikInput
                            name={"hightea"}
                            type={"number"}
                            label={"High tea dish"}
                            placeholder={"60"}
                            min={1}
                          />
                        </div>
                        <div>
                          <FormikInput
                            name={"dinner"}
                            type={"number"}
                            label={"Dinner dish"}
                            placeholder={"60"}
                            min={1}
                          />
                        </div>
                        <div>
                          <FormikInput
                            name={"extra"}
                            type={"number"}
                            label={"Extra dish"}
                            placeholder={"60"}
                            min={1}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  {/* <FieldArray name="newData">
                      {({ push, remove }) => (
                        <>
                          <div>
                            <FormikSelect
                              name={"dish_type"}
                              label={"Dish type"}
                              selectedOption={"Dish type"}
                              optionData={dishType}
                              onChange={(e) =>
                                handleDishChange(
                                  e.target.value,
                                  push,
                                  remove,
                                  values,
                                  setFieldValue
                                )
                              }
                            />
                          </div>

                          {values.newData.map((item, index) => (
                            <div key={index} className="mt-2 flex flex-row">
                              <FormikInput
                                name={`newData[${index}].new_value`}
                                label={item.d_type
                                  .replace("_", " ")
                                  .toUpperCase()}
                                placeholder={"120"}
                                type={"number"}
                              />
                              <div className="ml-2 mt-4">
                                <button
                                  type="button"
                                  onClick={() => remove(index)}
                                  className="bg-[#e70a18] p-2 text-lg text-[#fbffff] rounded-full mt-2"
                                >
                                  <MdDelete />
                                </button>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </FieldArray> */}

                  <div className="sm:mt-4 mt-2">
                    <Button name={"Update"} type={"submit"} />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateDishForm;

import React, { useEffect, useState } from "react";
import Loader from "../../Loader/loader";
import Select from "../../components/Input/Select";
import { month, role } from "../../components/Data";
import axios from "axios";
import { useParams } from "react-router-dom";
import TaxSummaryTable from "../../components/Table/TaxSummaryTable";
import ErrorPage from "../ErrorPage";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { uid } from "uid";
import DownloadButton from "../../components/Button/DownloadButton";

function TaxSummary() {
  const [loading, setLoading] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [projectCreated, setProjectCreated] = useState("");
  const [ArtistData, setArtistData] = useState([]);
  const [CrewData, setCrewData] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [roleFilter, setRoleFilter] = useState("");
  const [years, setYears] = useState([]);
  const [year, setYear] = useState("");
  const [error, setError] = useState(false);
  const UID = uid();
  const { projectId } = useParams();

  const handleFetchProject = () => {
    axios
      .get(`api/v1/projects/${projectId}`)
      .then((res) => {
        setProjectCreated(res.data.project.created_at);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFetchTaxSummary = (year, month) => {
    const YEAR = year === undefined || year === "" ? "na" : year;
    const MONTH = month === undefined || month === "" ? "na" : month;

    axios
      .get(
        `api/v1/projects/${projectId}/expenses/tax_summary?year=${YEAR}&month=${MONTH}`,
        {
          year: year,
          month: month,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setLoading(true);
          const newArtistData = res.data.project_artists_installments.map(
            (artist) => ({
              name: artist.artist_name,
              gst: artist.total_gst_amount,
              tds: artist.total_tds_amount,
              role: "Artist",
            })
          );
          const newCrewData = res.data.project_crews_installments.map(
            (crew) => ({
              name: crew.full_name,
              gst: crew.total_gst_amount,
              tds: crew.total_tds_amount,
              role: "Crew",
            })
          );

          const newVendorData = res.data.project_vendors_installments.map(
            (vendor) => ({
              name: vendor.name,
              gst: vendor.total_gst_amount,
              tds: vendor.total_tds_amount,
              role: "Vendor",
            })
          );

          const newLocationData = res.data.location_installments.map(
            (location) => ({
              name: location.owner_name,
              gst: location.total_gst_amount,
              tds: location.total_tds_amount,
              role: "Location owner",
            })
          );
          setLocationData(newLocationData);
          setVendorData(newVendorData);
          setArtistData(newArtistData);
          setCrewData(newCrewData);
        }
      })
      .catch((err) => {
        setError(true);
        console.log(err);
      });
  };
  const handleMonthChange = (e) => {
    const Month = e.target.value;
    const selectedMonth = month.find((data) => data.name === Month);
    setSelectedMonth(selectedMonth.id);
    handleFetchTaxSummary(year, selectedMonth.id);
  };

  const handleYearCalculation = () => {
    const fullDate = projectCreated;
    const date = new Date(fullDate);
    const startYear = date.getFullYear();
    const currentYear = new Date().getFullYear();

    const years = Array.from(
      { length: currentYear - startYear + 1 },
      (_, i) => startYear + i
    );

    const newYearArray = years.map((year) => ({
      id: year,
      name: year,
    }));

    setYears(newYearArray);
  };

  useEffect(() => {
    handleYearCalculation();
  }, [projectCreated]);

  useEffect(() => {
    handleFetchProject();
    handleFetchTaxSummary();
  }, []);

  const combinedArray = [...ArtistData, ...CrewData, ...vendorData,...locationData].filter(
    (data) => data.role.toLowerCase().includes(roleFilter.toLocaleLowerCase())
  );

  const handleDownloadExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(
      combinedArray.map((data) => {
        return {
          Name: data.name,
          Role: data.role,
          GST: data.gst,
          TDS: data.tds,
        };
      })
    );
    const colWidths = [{ wpx: 85 }, { wpx: 85 }, { wpx: 50 }, { wpx: 50 }];
    worksheet["!cols"] = colWidths;

    const rowHeights = Array(combinedArray.length + 1).fill({ hpx: 20 });
    worksheet["!rows"] = rowHeights;

    XLSX.utils.book_append_sheet(workbook, worksheet, "Requests");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blobData = new Blob([excelBuffer], {
      type: "application/octa-stream",
    });
    saveAs(blobData, `Tax_Summary_${UID}.xlsx`);
  };

  return (
    <div>
      {error ? (
        <ErrorPage />
      ) : (
        <div>
          {!loading ? (
            <Loader />
          ) : (
            <div className="flex flex-col sm:flex sm:flex-col p-5">
              <div className="flex md:flex-row flex-col items-center justify-between">
                <div className="flex sm:flex-row flex-col sm:items-start items-center justify-center sm:justify-start xl:ml-20 2xl:ml-20 md:ml-20 sm:ml-20 ml-0 sm:mt-5 mt-8">
                  <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 sm:gap-4 mt-2 sm:mt-4">
                    <div>
                      <Select
                        label={"Role"}
                        optionData={role}
                        selectedOption={"Role"}
                        onChangeFunction={(e) => setRoleFilter(e.target.value)}
                      />
                    </div>
                    <div>
                      <Select
                        label={"Year"}
                        selectedOption={"Year"}
                        optionData={years}
                        onChangeFunction={(e) => {
                          setYear(e.target.value);
                          handleFetchTaxSummary(e.target.value, selectedMonth);
                        }}
                      />
                    </div>
                    <div>
                      <Select
                        label={"Month"}
                        selectedOption={"Month"}
                        optionData={month}
                        onChangeFunction={(e) => handleMonthChange(e)}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-row items-end justify-end sm:mt-0 mt-2">
                  <div>
                    <DownloadButton
                      onClick={handleDownloadExcel}
                      name={"Download excel"}
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center  xl:ml-20 2xl:ml-20 md:ml-20 lg:ml-20 sm:ml-20 ml-0">
                <div className="w-full mt-5">
                  <TaxSummaryTable data={combinedArray} />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default TaxSummary;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../../components/Button/Button";
import AssignAccommodationForm from "../../components/Forms/AssignAccommodationForm";
import toast from "react-hot-toast";
import AssignedAccomodationTable from "../../components/Table/AssignedAccomodationTable";
import ErrorPage from "../ErrorPage";
import Loader from "../../Loader/loader";
import DeleteModal from "../../Modal/DeleteModal";
import UpdateAssignAccommodation from "../../components/UpdateForm/UpdateAssignAccommodation";

function AssignAccommodation() {
  const [view, setView] = useState(false);
  const [accommodations, setAccomodations] = useState([]);
  const [team, setTeam] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [selectedAccommodation, setSelectedAccommodation] = useState({});
  const [Delete, setDelete] = useState(false);
  const [update, setUpdate] = useState(false);
  const [assignedAccommodation, setAssignedAccommodation] = useState([]);
  const { projectId } = useParams();

  const fetchAccommodationDetails = () => {
    axios
      .get(`api/v1/projects/${projectId}/accommodations`)
      .then((res) => {
        setAccomodations(res.data.accommodations);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchTeam = () => {
    axios
      .get(`api/v1/projects/${projectId}/get_all_artists_crews`)
      .then((res) => {
        const team = res.data.team.flatMap((data) => data);
        const newTeamArray = team.map((data, index) => ({
          uid: index + 1,
          id: data.id,
          name: data.name,
          team_type: data.team_type,
        }));
        setTeam(newTeamArray);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAssignAccommodation = (values, { resetForm }) => {
    const loader = toast.loading("Assigning ...");
    const roomMate = values.room_mates.reduce((acc, mate) => {
      acc[mate.uid] = mate;
      return acc;
    }, {});
    axios
      .post(`api/v1/accommodation_assignments`, {
        accommodation_assignment: {
          accommodation_id: values.accommodation_id,
          room_id: values.room_id,
          start_date: values.start_date,
          end_date: values.end_date,
          total_members_count: values.room_mates.length,
          assigned_details: roomMate,
        },
      })
      .then((res) => {
        if (res.status === 201) {
          setView(false);
          resetForm();
          toast.success(res.data.message);
          fetchAssignedAccomodation();
        }
      })
      .catch((err) => {
        toast.error(
          err.response.data.errors || err.response.data.message || err.message
        );
        console.log(err);
      })
      .finally(() => {
        toast.dismiss(loader);
      });
  };

  const fetchAssignedAccomodation = () => {
    axios
      .get(`api/v1/accommodation_assignments`, {
        params: {
          project_id: projectId,
        },
      })
      .then((res) => {
        setAssignedAccommodation(res.data.assigned_accommodations);
        setLoading(true);
      })
      .catch((Err) => {
        setError(true);
        console.log(Err);
      });
  };

  const handleDeleteView = (data) => {
    setDelete(true);
    setSelectedAccommodation({ ...data });
  };
  const handleUpdateView = (data) => {
    setUpdate(true);
    setSelectedAccommodation({ ...data });
  };

  const handleDeleteAssignedAccommodation = () => {
    const loader = toast.loading("Reallocating ...");
    axios
      .delete(`api/v1/accommodation_assignments/${selectedAccommodation.id}`)
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message);
          fetchAssignedAccomodation();
          setDelete(false);
        }
      })
      .catch((err) => {
        toast.error(
          err.response.data.error || err.response.data.message || err.message
        );
        console.log(err);
      })
      .finally(() => {
        toast.dismiss(loader);
      });
  };
  const handleUpdateAssignedAccommodation = (values, { resetForm }) => {
    const loader = toast.loading("Re-assigning ...");
    const roomMate = values.room_mates.reduce((acc, mate) => {
      acc[mate.uid] = mate;
      return acc;
    }, {});
    axios
      .put(`api/v1/accommodation_assignments/${selectedAccommodation.id}`, {
        accommodation_assignment: {
          accommodation_id: selectedAccommodation.accommodation.id,
          room_id: selectedAccommodation.room.id,
          start_date: values.start_date,
          end_date: values.end_date,
          total_members_count: values.room_mates.length,
          assigned_details: roomMate,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message);
          fetchAssignedAccomodation();
          setUpdate(false);
        }
      })
      .catch((err) => {
        toast.error(
          err.response.data.errors || err.response.data.message || err.message
        );
        console.log(err);
      })
      .finally(() => {
        toast.dismiss(loader);
      });
  };

  useEffect(() => {
    fetchTeam();
    fetchAccommodationDetails();
    fetchAssignedAccomodation();
  }, []);

  return (
    <div>
      {error ? (
        <ErrorPage />
      ) : (
        <div>
          {!loading ? (
            <Loader />
          ) : (
            <div className="flex flex-col sm:flex sm:flex-col p-5">
              <div className="flex sm:items-start items-center justify-center sm:justify-start xl:ml-20 2xl:ml-20 sm:ml-20 ml-0 sm:mt-0 mt-5">
                <Button
                  name={"Assign Accommodation"}
                  clickFunction={() => setView(true)}
                />
              </div>
              <div className="flex items-center justify-center  xl:ml-20 2xl:ml-20 lg:ml-20 md:ml-20 sm:ml-20 ml-0">
                <div className="w-full mt-5">
                  <AssignedAccomodationTable
                    data={assignedAccommodation}
                    deleteView={handleDeleteView}
                    updateView={handleUpdateView}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {view && (
        <AssignAccommodationForm
          onClose={() => setView(false)}
          accommodation={accommodations}
          onSubmit={handleAssignAccommodation}
          team={team}
        />
      )}
      {Delete && (
        <DeleteModal
          title={"Deallocate Hotel"}
          message={`Do you want to deallocate ${selectedAccommodation.accommodation.name} ?`}
          icon={true}
          onClose={() => setDelete(false)}
          onDelete={handleDeleteAssignedAccommodation}
        />
      )}
      {update && (
        <UpdateAssignAccommodation
          onClose={() => setUpdate(false)}
          onSubmit={handleUpdateAssignedAccommodation}
          selectedAccommodation={selectedAccommodation}
          team={team}
        />
      )}
    </div>
  );
}

export default AssignAccommodation;

import React, { useEffect, useState } from "react";
import Select from "../../components/Input/Select";
import axios from "axios";
import toast from "react-hot-toast";
import Button from "../../components/Button/Button";
import RoleTable from "../../components/Table/RoleTable";
import { useParams } from "react-router-dom";
import Loader from "../../Loader/loader";
import ErrorPage from "../ErrorPage";
function Roles() {
  const [department, setDepartment] = useState([]);
  const [subDepartment, setSubDepartment] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [departmentName, setDepartmentName] = useState("");
  const [subDepartmentName, setSubDepartmentName] = useState("");
  const [role, setRole] = useState("");
  const [crew, setCrew] = useState([]);
  const [assignedCrew, setAssignedCrew] = useState([]);
  const [loading, setLoading] = useState(false);
  const [crewId, setCrewId] = useState("");
  const [error, setError] = useState(false);
  const { projectId } = useParams();

  const getDepartmentData = () => {
    axios
      .get(`api/v1/departments`)
      .then((response) => {
        setDepartment(response.data.departments);
      })
      .catch((error) => console.log(error.message));
  };
  const getSubDepartment = (value) => {
    const selected = department.find((detail) => detail.name === value);
    setSelectedDepartment(selected);
    if (selected) {
      axios
        .get(`api/v1/departments/${selected.id}/subdepartments`)
        .then((response) => setSubDepartment(response.data.subdepartments))
        .catch((error) => console.log(error.message));
    }
  };
  const getAsscociatedCrew = (subDepartment) => {
    axios
      .get(`api/v1/crews/filter_crew`, {
        params: {
          project_id: projectId,
          department: departmentName,
          subdepartment: subDepartment,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setCrew(response.data.crews);
        }
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(error.response.data.error);
      });
  };
  const getAssignedCrew = () => {
    axios
      .get(`api/v1/roles/roles_crews`, {
        params: {
          project_id: projectId,
        },
      })
      .then((response) => {
        setLoading(true);
        setAssignedCrew(response.data.roles_with_crew);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 422) {
            setLoading(true);
          } else {
            setError(true);
          }
        } else {
          setError(true);
          console.log(error);
        }
      });
  };
  const handleDepartmentChange = (event) => {
    const value = event.target.value;
    getSubDepartment(value);
    setDepartmentName(value);
  };
  const handleSubDepartmentChange = (event) => {
    const value = event.target.value;
    setSubDepartmentName(value);
    getAsscociatedCrew(value);
  };
  const handleCrewChange = (event) => {
    setCrewId(event.target.value);
  };
  useEffect(() => {
    getAssignedCrew();
    getDepartmentData();
    {
      selectedDepartment && getSubDepartment(selectedDepartment.name);
    }
  }, []);
  const assignRole = () => {
    const loaderToast = toast.loading("Assigning role...", {
      autoClose: false,
    });
    axios
      .post(`api/v1/projects/${projectId}/crews/${crewId}/add_role`, {
        role: role,
      })
      .then((response) => {
        if (response.status === 200) {
          toast.dismiss(loaderToast);
          toast.success(response.data.message);
          getAssignedCrew();
          setCrew([]);
          setSubDepartment([]);
          setRole("");
          setDepartmentName("");
          setSubDepartmentName("");
        } else {
          toast.dismiss(loaderToast);
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        toast.dismiss(loaderToast);
        toast.error(error.response.data.error);
        setCrew([]);
        setSubDepartment([]);
        setRole("");
        setDepartmentName("");
        setSubDepartmentName("");
        console.log(error);
      });
  };
  const roleData = [
    { id: 1, name: "Admin" },
    { id: 2, name: "Manager" },
    { id: 3, name: "Accountant" },
  ];
  return (
    <div>
      {error ? (
        <ErrorPage />
      ) : (
        <div>
          {!loading ? (
            <Loader />
          ) : (
            <div className="flex flex-col sm:flex sm:flex-col p-5">
              <div className="flex items-center justify-center  sm:items-start sm:justify-start ml-0 sm:ml-20">
                <div className="grid grid-cols-2 sm:grid-cols-5 sm:mt-14 mt-0">
                  <div>
                    <Select
                      label={"Department"}
                      selectedOption={"Department"}
                      optionData={department}
                      onChangeFunction={handleDepartmentChange}
                    />
                  </div>
                  {departmentName && (
                    <div className="ml-2">
                      <Select
                        label={"Sub department"}
                        selectedOption={"Sub department"}
                        optionData={subDepartment}
                        onChangeFunction={handleSubDepartmentChange}
                      />
                    </div>
                  )}
                  {departmentName && subDepartmentName && crew.length > 0 && (
                    <>
                      <div className="ml-2">
                        <label className="block mb-2 text-sm text-gray-600 ">
                          Crew
                        </label>
                        <select
                          onChange={handleCrewChange}
                          className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#1d5b79]  focus:ring-[#1d5b79] focus:outline-none focus:ring focus:ring-opacity-40"
                        >
                          <option selected>Crew name</option>
                          {crew.map((option, index) => (
                            <option key={index} value={option.crew_id}>
                              {option.full_name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="ml-2">
                        <Select
                          label={"Role"}
                          selectedOption={"Role"}
                          optionData={roleData}
                          onChangeFunction={(e) => setRole(e.target.value)}
                        />
                      </div>
                    </>
                  )}
                  {departmentName && subDepartmentName && crew.length > 0 && (
                    <div className="ml-4 mt-7">
                      <Button name={"Assign Role"} clickFunction={assignRole} />
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-center justify-center  xl:ml-20 2xl:ml-20 md:ml-20 lg:ml-20 sm:ml-20 ml-0">
                <div className="w-full mt-5">
                  <RoleTable
                    assignedCrew={assignedCrew}
                    getAssignedCrew={getAssignedCrew}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
export default Roles;

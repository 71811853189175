import React, { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import axios from "axios";
import { useParams } from "react-router-dom";
import AssignVehicleForm from "../../components/Forms/AssignVehicleForm";
import toast from "react-hot-toast";
import ErrorPage from "../ErrorPage";
import Loader from "../../Loader/loader";
import AssignedVehicleTable from "../../components/Table/AssignedVehicleTable";
import UpdateAssignedVehicle from "../../components/UpdateForm/UpdateAssignedVehicle";
import DeleteModal from "../../Modal/DeleteModal";

function AssignVehicle() {
  const [view, setView] = useState(false);
  const [update, setUpdate] = useState(false);
  const [Delete, setDelete] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [locations, setLocations] = useState([]);
  const [assignedVehicles, setAssignedVehicles] = useState([]);
  const [selectedAssignedVehicle, setSelectedAssignedVehicle] = useState({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [team, setTeam] = useState([]);
  const { projectId } = useParams();

  const fetchVehicles = () => {
    axios
      .get(`api/v1/projects/${projectId}/vehicles`)
      .then((res) => {
        setVehicles(res.data.vehicles);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchTeam = () => {
    axios
      .get(`api/v1/projects/${projectId}/get_all_artists_crews`)
      .then((res) => {
        const team = res.data.team.flatMap((data) => data);
        const newTeamArray = team.map((data, index) => ({
          uid: index + 1,
          id: data.id,
          name: data.name,
          team_type: data.team_type,
        }));
        setTeam(newTeamArray);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchLocation = () => {
    axios
      .get(`api/v1/projects/${projectId}/locations`)
      .then((res) => {
        setLocations(res.data.locations);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchTeam();
    fetchVehicles();
    fetchLocation();
  }, []);

  const handleAssignVehicle = (values, { resetForm }) => {
    const loader = toast.loading("Assigning ...");
    const passengersByUid = values.passengers.reduce((acc, passenger) => {
      acc[passenger.uid] = passenger;
      return acc;
    }, {});

    axios
      .post(`api/v1/vehicle_assignments`, {
        vehicle_assignment: {
          vehicle_id: values.vehicle_id,
          start_date: values.start_date,
          end_date: values.end_date,
          total_members_count: values.passengers.length,
          assigned_details: passengersByUid,
          duration: values.duration,
          drop_address: values.drop_address,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.status === 201) {
          setView(false);
          resetForm();
          toast.success(res.data.message);
          fetchAssignedVehicle();
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.errors || err.message);
      })
      .finally(toast.dismiss(loader));
  };

  const fetchAssignedVehicle = () => {
    axios
      .get(`api/v1/vehicle_assignments`, {
        params: {
          project_id: projectId,
        },
      })
      .then((res) => {
        setLoading(true);

        setAssignedVehicles(res.data.assigned_vehicles);
      })
      .catch((err) => {
        setError(true);
        console.log(err);
      });
  };

  const handleUpdateAssignedVehicleView = (vehicle) => {
    setSelectedAssignedVehicle({ ...vehicle });
    setUpdate(true);
  };

  const handleUpdateAssignedVehicle = (values, { resetForm }) => {
    const loader = toast.loading("Re assigning ...");
    const passengersByUid = values.passengers.reduce((acc, passenger) => {
      acc[passenger.uid] = passenger;
      return acc;
    }, {});
    axios
      .put(`api/v1/vehicle_assignments/${selectedAssignedVehicle.id}`, {
        vehicle_assignment: {
          vehicle_id: selectedAssignedVehicle.vehicle.id,
          start_date: values.start_date,
          end_date: values.end_date,
          total_members_count: values.passengers.length,
          duration: values.duration,
          drop_address: values.drop_address,
          assigned_details: passengersByUid,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setUpdate(false);
          fetchAssignedVehicle();
          resetForm();
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.errors || err.message);
      })
      .finally(toast.dismiss(loader));
  };

  const handelDeleteAssignedVehicleView = (vehicle) => {
    setSelectedAssignedVehicle(vehicle);
    setDelete(true);
  };

  const handleDeleteAssignedVehicle = () => {
    const loader = toast.loading("Deleting ...");
    axios
      .delete(`api/v1/vehicle_assignments/${selectedAssignedVehicle.id}`)
      .then((res) => {
        if (res.status === 200) {
          setDelete(false);
          fetchAssignedVehicle();
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.errors || err.message);
      })
      .finally(toast.dismiss(loader));
  };

  useEffect(() => {
    fetchAssignedVehicle();
  }, []);

  return (
    <div>
      {error ? (
        <ErrorPage />
      ) : (
        <div>
          {!loading ? (
            <Loader />
          ) : (
            <div className="flex flex-col sm:flex sm:flex-col p-5">
              <div className="flex sm:items-start items-center justify-center sm:justify-start xl:ml-20 2xl:ml-20 sm:ml-20 ml-0 sm:mt-0 mt-5">
                <Button
                  name={"Assign Vehicle"}
                  clickFunction={() => setView(true)}
                />
              </div>
              <div className="flex items-center justify-center  xl:ml-20 2xl:ml-20 lg:ml-20 md:ml-20 sm:ml-20 ml-0">
                <div className="w-full mt-5">
                  <AssignedVehicleTable
                    data={assignedVehicles}
                    updateView={handleUpdateAssignedVehicleView}
                    deleteView={handelDeleteAssignedVehicleView}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {view && (
        <AssignVehicleForm
          onClose={() => setView(false)}
          vehicles={vehicles}
          team={team}
          locations={locations}
          onSubmit={handleAssignVehicle}
        />
      )}
      {update && (
        <UpdateAssignedVehicle
          locations={locations}
          onClose={() => setUpdate(false)}
          selectedAssignedVehicle={selectedAssignedVehicle}
          team={team}
          onSubmit={handleUpdateAssignedVehicle}
        />
      )}
      {Delete && (
        <DeleteModal
          onClose={() => setDelete(false)}
          title={"Deallocate vehicle"}
          message={`Do you want to deallocate ${selectedAssignedVehicle.vehicle.name} ?`}
          icon={true}
          onDelete={handleDeleteAssignedVehicle}
        />
      )}
    </div>
  );
}

export default AssignVehicle;
